import styled from "styled-components";

const InfoWrapper = styled.div`
  img {
    display: block;
    width: 232px;
    margin: 0 auto;
    border-radius: 12px;
  }
`;

const InfoList = styled.ul`
  margin-top: 32px;
  padding: 24px;

  list-style: none;
  border-radius: 1.3rem;
  background-color: #e4aabb;
`;

const InfoItem = styled.li`
  font-size: 18px;
  text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "")};

  span {
    text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "")};
  }

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const ActionButtonsWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const WithdrawnBadge = styled.p`
  width: 200px;
  margin: 12px auto 0 auto;
  padding: 6px;

  text-align: center;
  font-size: 18px;
  font-weight: 600;

  background-color: #fff00055;
  border-radius: 12px;
`;

export {
  InfoWrapper,
  InfoList,
  InfoItem,
  ActionButtonsWrapper,
  WithdrawnBadge,
};
