import {
  getAdminChartColor,
  getTeacherChartColor,
} from "../../PaymentInfoChart/helpers";
import { List, Item } from "./styled";

const TotalPaymentsList = ({ payments, userType }) => {
  return (
    <List>
      {payments?.map(({ name, value, type }, index) => {
        return (
          value !== 0 && (
            <Item
              key={index}
              backgroundcolor={
                userType === "teacher"
                  ? getTeacherChartColor(name)
                  : getAdminChartColor(type)
              }
            >
              <span />
              <p>
                <span>{name}:</span>
                <span style={{ color: "#269900" }}>
                  ${value?.toLocaleString("en-US")}
                </span>
              </p>
            </Item>
          )
        );
      })}
    </List>
  );
};

export default TotalPaymentsList;
