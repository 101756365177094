import React from "react";
import LabeledInput from "../../common/LabeledInput";
import LabeledRadioInput from "../../common/LabeledRadioInput";
import { SectionContainer } from "../../../styled";
import {
  CustomCheckboxContainer,
  CustomInput,
  ModalButton,
} from "src/utils/shared/styled";
import { v4 as uuidv4 } from "uuid";

const EquipmentSection = ({ concertData, handleConcertDataChange }) => {
  function onAddMoreEquipmentClick() {
    const updatedEquipmentList = [
      ...concertData.equipmentList,
      {
        id: uuidv4(),
        name: "",
        isAvailable: true,
        isNew: true,
      },
    ];
    handleConcertDataChange("equipmentList", updatedEquipmentList);
  }
  function onNewEquipmentNameChange(newName, equipmentId) {
    const updatedEquipmentList = concertData.equipmentList.map((eq) =>
      eq.id === equipmentId ? { ...eq, name: newName } : eq
    );
    handleConcertDataChange("equipmentList", updatedEquipmentList);
  }
  return (
    <div>
      <h5 className="mb-3">Equipment:</h5>
      <SectionContainer>
        <div className="d-flex flex-wrap" style={{ gap: 10 }}>
          {concertData.equipmentList.map((equipment) => {
            return (
              <div className="mb-2" style={{ width: "48%" }}>
                <CustomCheckboxContainer>
                  <input
                    checked={
                      concertData.equipmentList.find(
                        ({ id }) => id === equipment.id
                      )?.isAvailable
                    }
                    onChange={(e) => {
                      const newVal = concertData.equipmentList.map((eq) =>
                        eq.id === equipment.id
                          ? { ...eq, isAvailable: e.target.checked }
                          : eq
                      );
                      handleConcertDataChange("equipmentList", newVal);
                    }}
                    type="checkbox"
                    id={equipment.id}
                    name="checkboxOption"
                  />
                  {equipment.isNew ? (
                    <CustomInput
                      padding="6px 10px"
                      className="ms-2"
                      display="inline-block"
                      width="165px"
                      placeholder="Equipment Name..."
                      type="text"
                      value={
                        concertData.equipmentList.find(
                          ({ id }) => id === equipment.id
                        )?.name
                      }
                      onChange={(e) =>
                        onNewEquipmentNameChange(e.target.value, equipment.id)
                      }
                    />
                  ) : (
                    <label className="ms-2" for={equipment.id}>
                      {equipment.name}
                    </label>
                  )}
                </CustomCheckboxContainer>
              </div>
            );
          })}
        </div>
        <div className="mt-3 text-end">
          <ModalButton
            fontWeight="bold"
            primary
            onClick={onAddMoreEquipmentClick}
          >
            Add More
          </ModalButton>
        </div>
      </SectionContainer>
    </div>
  );
};

export default EquipmentSection;
