import { useEffect, useState } from "react";
import { useApplications } from "../../hooks";
import store from "src/stores/UserStore";

import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { Input, Form } from "antd";
import { ActionSubmitButton } from "../../styled";

const SendEmailModal = ({ isOpen, toggle, applicant, onConfirm }) => {
  const { user } = store;
  const { sendEmail } = useApplications();
  const [applicantName, setApplicantName] = useState(null);
  const [fromNameValue, setFromNameValue] = useState(null);

  useEffect(() => {
    setApplicantName(applicant?.name);
    setFromNameValue(user?.fullName);
  }, [applicant?.name, user?.fullName]);

  const handleFinish = () => {
    sendEmail("#send-email-form");
    onConfirm();
  };

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>Send email</StyledModalHeader>
      <ModalBody>
        <Form id="send-email-form" onFinish={handleFinish} layout="vertical">
          <Form.Item label="Recipient's name" required={!applicantName}>
            <Input
              name="to_name"
              value={applicantName}
              onChange={({ target }) => setApplicantName(target.value)}
            />
          </Form.Item>
          <Form.Item label="Recipient's email">
            <Input name="to_email" value={applicant?.email} readOnly />
          </Form.Item>
          <Form.Item label="Your name" required>
            <Input
              name="from_name"
              value={fromNameValue}
              onChange={({ target }) => setFromNameValue(target.value)}
            />
          </Form.Item>
          <Form.Item label="Subject" required>
            <Input name="subject" />
          </Form.Item>
          <Form.Item label="Message" required>
            <Input.TextArea name="message" />
          </Form.Item>
          <Form.Item>
            <ActionSubmitButton type="primary" htmlType="submit" size="large">
              Send
            </ActionSubmitButton>
          </Form.Item>
        </Form>
      </ModalBody>
    </StyledModal>
  );
};

export default SendEmailModal;
