import { PiBabyLight } from "react-icons/pi";
import "./style.css";
import React from "react";
import { FcAlarmClock } from "react-icons/fc";
import { PiStudent } from "react-icons/pi";
import { PiStudentFill } from "react-icons/pi";
import { FcGraduationCap } from "react-icons/fc";
import { IoPeopleOutline } from "react-icons/io5";

function CoursesTable() {
  return (
    <table className="courses-table">
      <thead className="theadCoursesTable">
        <tr>
          <th>Student Levels</th>
          <th>
            <IoPeopleOutline className="privateInstructionsAgeIcons" /> Age
          </th>
          <th className="textAlignCenter">
            <FcAlarmClock className="privateInstructionsGeneralIcons" />
            <span> </span>
          </th>
          <th>Learning Achievements</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Young Children</strong>
            <br /> <br />{" "}
            <PiBabyLight className="privateInstructionsGeneralIcons" />
          </td>
          <td className="ageColumn">
            <div className="ageColumnDigit">5-7</div>
          </td>
          <td className="ageColumn">
            <div className="ageColumnDigit">30</div> min.
          </td>
          <td>
            Music fundamental learning, song playing, music notes reading,
            rhythm training, listening and practice skills building
          </td>
        </tr>
        <tr>
          <td>
            <strong>Junior Students </strong>
            <br /> <br />{" "}
            <PiStudent className="privateInstructionsGeneralIcons" />
          </td>
          <td className="ageColumn">
            <div className="ageColumnDigit">8-12</div>
          </td>
          <td className="ageColumn">
            <div className="ageColumnDigit">45</div> 
          </td>
          <td>
            Music theory, listening, performance skills Training, extended
            repertories
          </td>
        </tr>
        <tr>
          <td>
            <strong>Teen Students Advanced Level </strong>
            <br /> <br />{" "}
            <PiStudentFill className="privateInstructionsGeneralIcons" />
          </td>
          <td className="ageColumn">
            <div className="ageColumnDigit">13</div>and up
          </td>

          <td className="ageColumn">
            <div className="ageColumnDigit">60</div> 
          </td>
          <td>
            Music history discovery, music composer explores, solo skills
            training and music performance tour opportunities
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <strong>Music Honor Program</strong>
            <br />
            <FcGraduationCap className="privateInstructionsGeneralIcons" />
            <br />
            Based on student learning experience and audition result
          </td>

          <td className="ageColumn">
            <div className="ageColumnDigit">60 - 90</div> 
          </td>
          <td>
            International Music Examination, states and national music
            competitions, chamber music group training, music solo performance
            tour opportunities
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default CoursesTable;
