import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModeEdit } from "@styled-icons/material/ModeEdit";
import { TrashFill } from "@styled-icons/bootstrap/TrashFill";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import { deleteDoc, updateDoc } from "firebase/firestore";
import {
  CustomInput,
  CustomLabel,
  PrimaryButton,
} from "src/utils/shared/styled";
import { SubmitSpinner } from "src/components/common";
import moment from "moment";
import RecurringLabelDeleteOptionsModal from "./modals/RecurringLabelDeleteOptionsModal";
import useModal from "src/hooks/useModal";
import {
  isSingleTimeCalendarLabelEvent,
  isRecurringCalendarLabelEvent,
} from "src/constants/eventsEnum";

const InfoText = styled.p`
  margin-bottom: 2px;
`;
const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-right: 35px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
const CalendarLabelEventContent = ({
  event,
  closeEventDetailsModal,
  refreshData,
}) => {
  const { date, title, id } = event;

  const labelNote = event.note || event.currentTimeline?.note;

  const firebase = useContext(FirebaseContext);
  // label delete options modal
  const labelDeleteModalData = useModal();

  const isRecurringLabel = isRecurringCalendarLabelEvent(event.eventCode);

  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const handleToggleEdit = () => {
    setIsEditEnabled((oldVal) => !oldVal);
  };
  const [eventFormFields, setEventFormFields] = useState({
    title: "",
    date: "",
    note: "",
    eventColor: "",
  });
  const handleFormChange = (e) => {
    setEventFormFields((oldVal) => ({
      ...oldVal,
      [e.target.name]: e.target.value,
    }));
  };

  // for when deleting/modifying the label
  const [loading, setLoading] = useState(false);

  // setting initial state
  useEffect(() => {
    setEventFormFields(() => ({
      title: event.title,
      date: moment(event.date).format("YYYY-MM-DD"),
      note: event.note,
      eventColor: event.eventColor,
    }));
  }, []);

  const handleDeleteLabel = async () => {
    try {
      setLoading(true);
      await deleteDoc(firebase.calendarLabel(id));
      toast.success("Deleted Successfully");
      closeEventDetailsModal();
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveModifications = async () => {
    try {
      setLoading(true);
      if (!title || !date) {
        toast.warn("Please fill all required fields");
        return;
      }

      const modifiedObj = {
        title: eventFormFields.title,
        date: moment(eventFormFields.date).startOf("day").toDate(),
        note: eventFormFields.note,
        eventColor: eventFormFields.eventColor,
      };
      await updateDoc(firebase.calendarLabel(id), modifiedObj);
      toast.success("Modified Successfully");
      closeEventDetailsModal();
      refreshData();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTrashIconClick = () => {
    if (isRecurringLabel) {
      labelDeleteModalData.openModal();
    } else {
      handleDeleteLabel();
    }
  };
  return (
    <div className="pb-4 m-5 mt-0 pt-1">
      <ActionButtonsContainer>
        {!isRecurringLabel && (
          <ModeEdit
            onClick={handleToggleEdit}
            style={{ cursor: "pointer" }}
            size={30}
          />
        )}

        <TrashFill
          onClick={handleTrashIconClick}
          style={{ cursor: "pointer" }}
          size={30}
        />
      </ActionButtonsContainer>
      <div className="d-flex mt-1 mb-3">
        {isEditEnabled ? (
          <div>
            <FormContainer>
              <div className="mb-3">
                <CustomLabel>Title</CustomLabel>
                <CustomInput
                  width="250px"
                  type="text"
                  name="title"
                  value={eventFormFields.title}
                  onChange={handleFormChange}
                />
              </div>
              <div className="mb-3">
                <CustomLabel>date</CustomLabel>
                <CustomInput
                  width="250px"
                  type="date"
                  name="date"
                  value={eventFormFields.date}
                  onChange={handleFormChange}
                />
              </div>
              <div className="mb-3">
                <CustomLabel>Note</CustomLabel>
                <CustomInput
                  width="250px"
                  type="text"
                  name="note"
                  value={eventFormFields.note}
                  onChange={handleFormChange}
                />
              </div>
              <div className="mb-3">
                <CustomLabel>Color</CustomLabel>
                <CustomInput
                  width="250px"
                  type="color"
                  name="eventColor"
                  value={eventFormFields.eventColor}
                  onChange={handleFormChange}
                />
              </div>
            </FormContainer>
            <div className="text-center mt-4">
              <PrimaryButton onClick={handleSaveModifications}>
                {loading ? (
                  <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                ) : (
                  "Save Changes"
                )}
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <div>
            <InfoText>{labelNote}</InfoText>
          </div>
        )}
      </div>
      <RecurringLabelDeleteOptionsModal
        modalData={labelDeleteModalData}
        closeEventDetailsModal={closeEventDetailsModal}
        event={event}
        refreshData={refreshData}
      />
    </div>
  );
};

export default CalendarLabelEventContent;
