export const concertPaymentOptions = {
  FREE: 1,
  PAID: 2,
};

export const concertPaymentRates = {
  solo: {
    name: "solo",
    hasOptions: false,
  },
  pianoAccompaniment: {
    name: "piano_accompaniment",
    hasOptions: true,
  },
  teacherDuet: {
    name: "teacher_duet",
    hasOptions: true,
  },
};
export const concertPianoAccompanimentLevels = {
  LEVEL_1: "level_1",
  LEVEL_2: "level_2",
  LEVEL_3: "level_3",
  PROFESSIONAL: "professional",
};
export const concertTeacherDuetLevels = {
  LEVEL_1: "level_1",
  LEVEL_2: "level_2",
  LEVEL_3: "level_3",
};
