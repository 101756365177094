import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import React from "react";
import { ScreenSize } from "../constants/ScreenSizeEnum";
import { LabelInput } from "../components/common";
import {
  Button as RSButton,
  DropdownMenu,
  DropdownToggle,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NewRadiusInput } from "../components/common/Input";
import { Close } from "@styled-icons/evaicons-solid/Close";

export const PrimaryBackground = css`
  background-color: #681e46;
`;
export const SecondaryBackground = css`
  background-color: white;
`;
export const PrimaryColor = css`
  color: #681e46;
`;
export const SecondaryColor = css`
  color: #ddd;
`;
export const StyledForm = styled.div`
  min-height: 480px;
  width: 320px;
  border-radius: 50px;
  margin-top: 24px;
  color: white;
  padding: 24px 30px;
  ${PrimaryBackground}
`;

export const buttonHoverAndFocus = css`
  :hover {
    color: #fff;
    background-color: #ffffa0 !important;
    border-color: #e6e691 !important;
    cursor: pointer;
  }

  :focus {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2) !important;
  }
`;

export const Button = styled.button`
  background-color: #fffde3;
  color: #822491;
  border-color: #fffde3 !important;
  font-size: 0.8rem !important;
  padding: 8px 32px !important;
  font-weight: 700 !important;
  border-radius: ${({ notRound }) => !notRound && "20px !important"};
  max-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ withHover = true }) => (!withHover ? "" : `${buttonHoverAndFocus};`)};
`;
export const SecondaryButton = styled(RSButton)`
  ${SecondaryBackground}
  color: black;
  border: none;
  box-shadow: 4px 4px 12px 6px #e3e3e3ab;
`;

export const linkStyle = css`
  color: white;

  :hover {
    color: white;
  }
`;

export const Pointer = styled.div`
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  ${linkStyle}
`;

export const InfoDiv = styled.div`
  background-color: #fddeff;
  color: black;
  padding: 16px;
  border-radius: 16px;
`;

export const InfoText = styled.p`
  color: #151515;
  font-weight: ${({ weight }) => (!weight ? 700 : weight)};
`;
export const InfoPrimaryText = styled.p`
  ${PrimaryColor}
  font-weight: ${({ weight }) => (!weight ? 600 : weight)};
`;
export const InfoSecondaryText = styled.p`
  ${SecondaryColor}
  font-weight: ${({ weight }) => (!weight ? 600 : weight)};
`;
export const PrimaryH3 = styled.h3`
  color: #151515;
  font-size: 26px;
  font-weight: ${({ weight }) => (!weight ? 800 : weight)};
  opacity: 1;
`;

export const NoDecoration = css`
  :hover {
    text-decoration: none;
  }
`;

export const CustomLink = styled(({ isNav, ...rest }) => <NavLink {...rest} />)`
  & {
    color: #7386d5;
    background: #fff;
  }

  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  :hover {
    color: #7386d5;
    background: #fff;
  }

  ${NoDecoration}
`;
/**
 const activeClassName = "nav-item-active";
 * export const CustomLink = styled(({ isNav, ...rest }) => (
 <NavLink {...rest} activeClassName={isNav ? activeClassName : ""} />
 ))`
 &.${activeClassName} {
    color: #7386d5;
    background: #fff;
  }
 font-size: 1.5rem;
 font-weight: bold;
 display: flex;
 align-items: center;
 justify-content: center;
 text-decoration: none;
 :hover {
    color: #7386d5;
    background: #fff;
  }
 ${NoDecoration}
 `;
 */

export const TextLink = styled.a`
  text-decoration: underline;

  :hover {
    font-weight: bold;
    text-decoration: none;
    color: white;
  }
`;

export const Divider = styled.div`
  border-top: 2px solid black;
  margin-bottom: 0.5rem;
  @media (max-width: ${ScreenSize.XL}px) {
    margin-bottom: 0.25rem;
  }
`;

export const BackgroundImage = css`
  position: relative;

  :after {
    content: "";
    height: 100%;
    background: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.85;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    filter: ${(props) => (props.noBlur ? "" : "blur(6px)")};
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 510px;
  padding: 0 20rem;
  @media (max-width: 1350px) {
    padding: 0 10rem;
  }
  max-width: 100%;
  ${(props) =>
    props.image
      ? ""
      : `background-color: ${props.color ? props.color : "#681e46"};`};
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  ${PrimaryBackground}
  flex: 1;
`;

export const CustomModal = styled(Modal)`
  & .modal-content {
    border-radius: 1.3rem;
    border: none !important;
    padding: ${({ padding }) => padding || "21px"} !important;

    position: relative;
    z-index: 999999;
  }
  ${({ minWidthOnLargeScreen }) => css`
    @media (min-width: 992px) {
      &.modal-dialog {
        max-width: ${minWidthOnLargeScreen};
      }
    }
  `}
`;
export const CustomModalHeader = styled(ModalHeader)`
  border: none !important;
  padding: ${({ padding }) => padding || "25px 0 "} !important;
  width: 100% !important;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
export const CustomModalHeaderCreatePrivateLesson = styled(ModalHeader)`
  border: none !important;
  padding-bottom: 10px !important;
  padding-top: 25px !important;
`;
export const CustomModalBody = styled(ModalBody)`
  padding: ${({ padding }) => padding || "10px"} !important;
  // padding-top: 0px !important;
  // padding: 0 50px;
`;
export const CustomModalFooter = styled(ModalFooter)`
  border: none !important;
`;
const InputStyle = css`
  height: ${(props) => props.height && props.height} !important;
  border: none;
  box-shadow: 3px 2px 9px 4px #f5f5f5;
`;
export const CustomLabelInput = styled((props) => (
  <NewRadiusInput
    {...props}
    labelColor="#151515"
    withFocus={false}
    backgroundColor={"white"}
  />
))`
  ${InputStyle}
`;

export const DashboardLabelInput = styled((props) => (
  <LabelInput
    {...props}
    labelColor="#151515"
    withFocus={false}
    backgroundColor={"#fff"}
  />
))`
  & .form-control {
    ${InputStyle}
  }

  label {
    margin-left: 5px !important;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const CustomLabel = styled(Label)`
  color: black;
  display: ${({ displayBlock }) => (displayBlock ? "block" : "inline-block")};
  text-wrap: ${({ textWrap }) => textWrap || "wrap"}; ;
`;
//f5f5f55e

export const CustomDropdownToggle = styled(DropdownToggle)`
  background-color: #681e460d !important;
  // min-width: 7.18rem!important;
  min-width: 0rem !important;
  outline: none;
  color: black !important;
  border: 1px solid #f5f5f55e !important;
  // margin-top: 10px;
  // box-shadow: 0px -2px 4px -1px #e3e3e3ab;
  ${({ isOpen }) =>
    isOpen
      ? `
      box-shadow:none !important;
      outline:none;
  border-top-right-radius:7px!important
  border-top-left-radius:7px!important
`
      : `border-radius:7px!important`}
`;
export const CustomDropDownMenu = styled(DropdownMenu)`
  border: 1px solid #f5f5f55e !important;
  // top: 27px!important;
  // min-width: 7.18rem!important;
  background-color: "#681E460D";
  box-shadow: none !important;
  outline: none;
  min-width: 0rem !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top: 0px !important;
  overflow: auto;
  overflow-x: hidden;
  max-height: 250px;
`;

export const Card = styled.div`
  box-shadow: 4px 4px 9px 5px #e3e3e3ab;
  background-color: ${(props) => props.backgroundColor || "white"};
  border-radius: ${(props) => props.borderRadius || "20px"};
`;
export const UserProfile = styled((props) => (
  <div {...props}>
    <img className={"rounded-circle"} src={props.src} />
    {props.children}
  </div>
))`
  width: ${(props) => props.width || "90px"};
  height: ${(props) => props.height || "90px"};
  background-color: #ddd;
  border: none;

  img {
    position: ${(props) => props.imgPosition || "relative"};
    width: ${(props) => props.width || "90px"};
    height: ${(props) => props.height || "90px"};
    opacity: ${(props) => props.imageOpacity};
    object-fit: cover;
  }
`;

export const AdminUserSelectContainer = styled.div``;
