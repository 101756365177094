import React from "react";
import RouteContent from "src/components/common/RouteContent";
import CreatePayment from "./components/CreatePayment";
import PageNavSection from "./components/PageNavSection";
import PaymentList from "./components/PaymentList";
import { adminsPaymentPageTabs } from "./constants";
import useAdminsPayment from "./hooks/useAdminsPayment";
import Report from "./components/Report";

const AdminsPayment = () => {
  const adminsPaymentPageData = useAdminsPayment();
  const { currentPage, setCurrentPage } = adminsPaymentPageData;

  const renderCurrentPageComponent = () => {
    if (currentPage === adminsPaymentPageTabs.CREATE_PAYMENT.value) {
      return <CreatePayment />;
    } else if (currentPage === adminsPaymentPageTabs.PAYMENT_LIST.value) {
      return <PaymentList />;
    } else if (currentPage === adminsPaymentPageTabs.REPORT.value) {
      return <Report />;
    }
  };

  return (
    <RouteContent title="Payment">
      <div>
        <PageNavSection
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className="mt-4">{renderCurrentPageComponent()}</div>
    </RouteContent>
  );
};

export default AdminsPayment;
