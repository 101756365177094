import React from "react";
import { useState } from "react";
import { useContext } from "react";
import useModal from "src/hooks/useModal";
import store from "src/stores/UserStore";
import { isSuperAdmin } from "src/constants/UserRoleEnum";

import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ChangeTimezoneModal from "src/components/Dashboard/common/components/ChangeTimezoneModal";
import { FirebaseContext } from "src/components/Firebase";
import { MdOutlineFeedback } from "react-icons/md";
import FeedbackModal from "../FeedbackModal";

const SettingsMenu = ({
  dropdownToggleContainer: DropdownToggleContainer,
  dropdownToggleIcon: DropdownToggleIcon,
}) => {
  const changeTimezoneModalData = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const firebase = useContext(FirebaseContext);
  const { user } = store;

  const dropdownToggleMarkup = () => (
    <DropdownToggleContainer>
      <DropdownToggleIcon />
    </DropdownToggleContainer>
  );

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle tag="span">{dropdownToggleMarkup()}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={changeTimezoneModalData.openModal}>
            <div className="p-2 text-start">
              <i className="fas fa-clock" />
              <span className="ms-2">Change Timezone</span>
            </div>
          </DropdownItem>
          {!isSuperAdmin(user?.role) && (
            <DropdownItem>
              <div
                className="p-2 text-start"
                onClick={() => setFeedbackModalOpen(true)}
              >
                <MdOutlineFeedback size={18} />
                <span className="ms-2">Give feedback</span>
              </div>
            </DropdownItem>
          )}
          <DropdownItem>
            <Link
              to="/"
              className="text-dark text-decoration-none"
              onClick={firebase.signOut}
            >
              <div className="p-2 text-start">
                <i className="fas fa-power-off" />
                <span className="ms-2">Sign Out</span>
              </div>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <ChangeTimezoneModal modalData={changeTimezoneModalData} />
      {!isSuperAdmin(user?.role) && (
        <FeedbackModal
          isOpen={feedbackModalOpen}
          toggle={() => setFeedbackModalOpen((prevState) => !prevState)}
          onConfirm={() => setFeedbackModalOpen(false)}
          onScreenshot={() => setFeedbackModalOpen(false)}
          afterScreenshot={() => setFeedbackModalOpen(true)}
        />
      )}
    </>
  );
};

export default SettingsMenu;
