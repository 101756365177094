import React from "react";
import moment from "moment";
import { PrimaryButton } from "../../../../../../utils/shared/styled";

const AbsenceConfirmation = ({
  selectedEvent,
  isSDC,
  absenceModalData,
  makeupModalData,
}) => {
  const { start, end, instrument } = selectedEvent || {};
  const formattedDate =
    moment(start).format("dddd, MMMM D: h:mm a - ") +
    moment(end).format("h:mm a");

  const handleYesClick = () => {
    absenceModalData.closeModal();
    makeupModalData.openModal();
  };
  const handleNoClick = () => {
    absenceModalData.closeModal();
  };
  return (
    <div>
      <div>
        <h5>{instrument?.name} lesson Cancelation</h5>
        <p>{formattedDate}</p>
      </div>
      <hr />
      {!isSDC && (
        <div>
          <h5>SCHEDULE A MAKE UP NOW ?</h5>
          <div className="w-50 m-auto mt-4 d-flex justify-content-between align-items-center">
            <PrimaryButton onClick={handleNoClick} width="120px" padding="5px">
              No
            </PrimaryButton>
            <PrimaryButton onClick={handleYesClick} width="120px" padding="5px">
              Yes
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default AbsenceConfirmation;
