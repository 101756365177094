import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../../../../../../../utils/shared/styled";
import UnknownProfilePhoto from "../../../../../../../../assets/images/default-profile.jpg";
import { modalMap } from "../../utils/constants";
import ChangeProfileImage from "./ChangeProfileImage";
import {
  haveSuperAdminPermission,
  injectUserStore,
  isActiveTeacher,
} from "src/utils/helpers";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";

const BodyContainer = styled.div`
  background-color: #fff;
  display: block;
  box-sizing: border-box;
  margin-top: 75px;
  border-radius: 20px;
`;
const CurveContainer = styled.div`
  background: #681e46;
`;
const Curve = styled.div`
  display: block;
  box-sizing: border-box;
  height: ${({ height }) => height || "145px"};
  background-color: white;
  clip-path: ellipse(65% 100% at 50% 100%);
`;
const ImageAndText = styled.div`
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
`;
const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  margin: auto;
  margin-bottom: 10px;
`;
const TeacherImage = styled.img`
  width: 100%;
  height: 100%;
  border: 12px solid white;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 0 10px #f3f5f6 inset;
`;
const TeacherName = styled.h2`
  text-align: center;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  padding: 40px;
  border-radius: 20px;
`;
const TeacherCardBody = ({ teacher, modalData, refreshData, UserStore }) => {
  const { openModalWithName } = modalData;
  const { fullName, imageUrl } = teacher || {};

  const isActive = isActiveTeacher(teacher);

  const user = UserStore.user;

  const hasPermissionToCreateBreak = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.createTeachers
  );
  return (
    <BodyContainer>
      <CurveContainer>
        <Curve height="180px"></Curve>
      </CurveContainer>
      <ImageAndText className="mb-4">
        <ImageContainer>
          <TeacherImage src={imageUrl || UnknownProfilePhoto} alt="profile" />
        </ImageContainer>
        <TeacherName>{fullName}</TeacherName>
        <div className="text-center">
          <ChangeProfileImage
            userId={teacher?.id}
            refreshData={refreshData}
            closeModal={modalData.closeModal}
          />
        </div>
      </ImageAndText>
      <hr />
      <ButtonsContainer>
        <PrimaryButton
          onClick={() => openModalWithName(modalMap.teacherInfo.name)}
          width="180px"
          height="45px"
          padding="0"
        >
          Teacher Info
        </PrimaryButton>
        {isActive ? (
          <>
            <PrimaryButton
              onClick={() => openModalWithName(modalMap.lessonsInfo.name)}
              width="180px"
              height="45px"
              padding="0"
            >
              Lessons Info
            </PrimaryButton>
            {hasPermissionToCreateBreak && (
              <PrimaryButton
                onClick={() => openModalWithName(modalMap.teacherBreaks.name)}
                width="180px"
                height="45px"
                padding="0"
              >
                Create Break
              </PrimaryButton>
            )}
          </>
        ) : (
          <>
            <PrimaryButton
              backgroundColor="#e357a2"
              onClick={() =>
                openModalWithName(modalMap.deleteTeacherLibraryItems.name)
              }
              width="180px"
              height="45px"
              padding="0"
            >
              Delete Library Items
            </PrimaryButton>
            <PrimaryButton
              backgroundColor="#e357a2"
              onClick={() =>
                openModalWithName(modalMap.teacherReactivation.name)
              }
              width="180px"
              height="45px"
              padding="0"
            >
              Reactivate Teacher
            </PrimaryButton>
          </>
        )}
      </ButtonsContainer>
    </BodyContainer>
  );
};
export default injectUserStore(TeacherCardBody);
