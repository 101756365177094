import React from "react";
import PaymentInfoRow from "src/components/common/PaymentInfoRow";
import ViewPaymentInfoModal from "src/components/Dashboard/common/components/ViewPaymentInfoModal";
import useModal from "src/hooks/useModal";

const UserPayments = ({
  userPaymentsList,
  currentPaymentObj,
  setCurrentPaymentId,
  refreshUserData,
}) => {
  const paymentInfoModaltData = useModal();
  const colorsPallete = ["#FCFFE2", "#EBEEFF", "#FFF9E5", "#E2FCFF"];

  return (
    <div>
      {userPaymentsList.map((paymentObj) => {
        const currentColor = colorsPallete[0];
        colorsPallete.shift();
        colorsPallete.push(currentColor);

        return (
          <div key={paymentObj.id} className="mb-4">
            <PaymentInfoRow
              openPaymentInfoModal={paymentInfoModaltData.openModal}
              paymentObj={paymentObj}
              bgColor={currentColor}
              setCurrentPaymentId={setCurrentPaymentId}
            />
          </div>
        );
      })}
      {paymentInfoModaltData.isModalOpen && currentPaymentObj && (
        <ViewPaymentInfoModal
          modalData={paymentInfoModaltData}
          paymentObj={currentPaymentObj}
          onPaymentDeleteSuccess={refreshUserData}
        />
      )}
    </div>
  );
};

export default UserPayments;
