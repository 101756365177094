import {
  addDoc,
  getDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { indexOf } from "lodash";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { SubmitSpinner } from "src/components/common";
import CustomDropDown from "src/components/common/DropDown";

import { FirebaseContext } from "src/components/Firebase";

const AddSchoolYearLocation = () => {
  const firebase = useContext(FirebaseContext);
  const { schoolYearId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [schoolYearBreaks, setSchoolYearBreaks] = useState([
    { from: "", to: "" },
  ]);
  const [selectedLocation, setSelectedLocation] = useState({
    label: "",
    value: "",
  });
  const [locations, setLocations] = useState([]);
  const [schoolYearMakeupDeadlines, setSchoolMakeupDeadlines] = useState([
    {
      start_date: "",
      end_date: "",
      deadline: "",
    },
  ]);
  const [selectedSchoolYearInputs, setSelectedSchoolYearInputs] = useState({
    // title: "",
    start_date: "",
    end_date: "",
    learning_year_start: "",
    learning_year_end: "",
    summer_session_start: "",
    summer_session_end: "",
  });
  const [currentSchoolYear, setCurrentSchoolYear] = useState({
    locations: {},
  });
  const locationsOptions = useMemo(() => {
    if (currentSchoolYear.id && locations.length > 0) {
      const locationsIds = Object.keys(currentSchoolYear.locations);
      return locations
        .filter(({ id }) => locationsIds.indexOf(id) === -1)
        .map(({ id, name }) => ({ label: name, value: id }));
    }
    return [];
  }, [locations, currentSchoolYear]);
  const fetchLocations = async () => {
    const locationsDocs = await getDocs(firebase.getLocations());
    const addedLocations = locationsDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setLocations(addedLocations);
  };
  const fetchSchoolYear = async () => {
    if (schoolYearId) {
      const schoolYearDoc = await getDoc(
        firebase.getSchoolYearDoc(schoolYearId)
      );
      setCurrentSchoolYear({
        id: schoolYearId,
        ...schoolYearDoc.data(),
      });
    }
  };
  useEffect(() => {
    if (locationsOptions.length > 0) {
      setSelectedLocation({ ...locationsOptions[0] });
    }
  }, [locationsOptions]);
  useEffect(() => {
    (async () => {
      await Promise.all([fetchLocations(), fetchSchoolYear()]);
      setIsLoading(false);
    })();
  }, []);
  const addNewYear = async () => {
    toast.info("Adding a new year");
    setIsLoading(true);
    // const locationId = selectedLocation.value;
    const locationDates = {
      start_date: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.start_date, "YYYY-MM-DD")
          .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      end_date: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.end_date, "YYYY-MM-DD")
          .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      learning_year_start: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.learning_year_start, "YYYY-MM-DD")
          .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      learning_year_end: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.learning_year_end, "YYYY-MM-DD")
          .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      summer_session_start: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.summer_session_start, "YYYY-MM-DD")
          .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
      summer_session_end: Timestamp.fromDate(
        moment(selectedSchoolYearInputs.summer_session_end, "YYYY-MM-DD")
          .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
          .toDate()
      ),
    };
    const newBreaksData = schoolYearBreaks
      .filter((schoolBreak) => {
        return schoolBreak.from !== "" && schoolBreak.to !== "";
      })
      .map((schoolBreak) => ({
        from: Timestamp.fromDate(
          moment(schoolBreak.from, "YYYY-MM-DD")
            .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
        to: Timestamp.fromDate(
          moment(schoolBreak.to, "YYYY-MM-DD")
            .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
      }));
    const newMakeupsData = schoolYearMakeupDeadlines
      .filter((makeup) => {
        return (
          makeup.start_date !== "" &&
          makeup.end_date !== "" &&
          makeup.deadline !== ""
        );
      })
      .map((makeup) => ({
        start_date: Timestamp.fromDate(
          moment(makeup.start_date, "YYYY-MM-DD")
            .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
        end_date: Timestamp.fromDate(
          moment(makeup.end_date, "YYYY-MM-DD")
            .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
        deadline: Timestamp.fromDate(
          moment(makeup.deadline, "YYYY-MM-DD")
            .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
      }));
    if (newMakeupsData.length === 0) {
      toast.error("You must have at least one section for makeup deadlines");
      return;
    }
    const title = `${moment(
      locationDates.start_date.toDate()
    ).year()} - ${moment(locationDates.end_date.toDate()).year()}`;
    const newDocumentData = {
      title,
      // title: selectedSchoolYearInputs.title,
      isLocked: true,
      locations: {
        ...currentSchoolYear.locations,
        [selectedLocation.value]: {
          dates: {
            ...locationDates,
          },
          breaks: {
            ...newBreaksData,
          },
          makeups: {
            ...newMakeupsData,
          },
        },
      },
    };
    // for (let location of locations) {
    //   newDocumentData.locations[selectedLocation.value] = {
    //     dates: {
    //       ...locationDates
    //     },
    //     breaks: {
    //       ...newBreaksData
    //     },
    //     makeups: {
    //       ...newMakeupsData
    //     }
    //   };
    // }
    await updateDoc(
      firebase.getSchoolYearDoc(currentSchoolYear.id),
      newDocumentData
    );
    toast.success("Successfully added.");
    navigate("/dashboard/school-dates");
  };
  const isDisabled = () => {
    const dataObject = {
      ...schoolYearMakeupDeadlines,
      ...selectedSchoolYearInputs,
    };
    let isEmpty = false;
    let isEmptyDeadlines = true;

    for (let key in dataObject) {
      if (dataObject[key] === "") {
        isEmpty = true;
        break;
      }
    }
    if (!isEmpty) {
      isEmpty = true;
      for (let schoolBreak of schoolYearBreaks) {
        if (schoolBreak.from !== "" && schoolBreak.to !== "") {
          isEmpty = false;
          break;
        }
      }
      if (isEmpty) {
        return true;
      }
      for (let makeupDeadline of schoolYearMakeupDeadlines) {
        if (
          makeupDeadline.start_date !== "" &&
          makeupDeadline.end_date !== "" &&
          makeupDeadline.deadline !== ""
        ) {
          isEmptyDeadlines = false;
          break;
        } else {
          isEmptyDeadlines = true;
        }
      }
    }
    return isEmpty || isEmptyDeadlines;
  };
  const updateSchoolYear = (key, value) => {
    setSelectedSchoolYearInputs((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  const updateSchoolYearMakeups = (index, key, value) => {
    setSchoolMakeupDeadlines((previous) => {
      previous[index][key] = value;
      return [].concat(previous);
    });
  };

  const updateSchoolYearBreaks = (index, key, value) => {
    setSchoolYearBreaks((previous) => {
      previous[index][key] = value;
      return [].concat(previous);
    });
  };
  const addAnotherSchoolBreak = () => {
    setSchoolYearBreaks((previous) => {
      const tempArray = [].concat(previous);
      tempArray.push({
        from: "",
        to: "",
      });
      return tempArray;
    });
  };
  const removeSchoolBreak = (index) => {
    setSchoolYearBreaks((previous) => {
      const tempArray = [].concat(previous);
      tempArray.splice(index, 1);
      return tempArray;
    });
  };
  const addAnotherMakeup = () => {
    // if (schoolYearMakeupDeadlines.length < 3) {
    setSchoolMakeupDeadlines((previous) => {
      const tempArray = [].concat(previous);
      tempArray.push({
        start_date: "",
        end_date: "",
        deadline: "",
      });
      return tempArray;
    });
    // }
  };
  const removeMakeup = (index) => {
    setSchoolMakeupDeadlines((previous) => {
      const tempArray = [].concat(previous);
      tempArray.splice(index, 1);
      return tempArray;
    });
  };
  return (
    <div className="h-100 w-100 p-4">
      {isLoading ? (
        <div className="p-5 d-flex align-items-center justify-content-center">
          <SubmitSpinner style={{ color: "#681E46" }} />
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-center mb-2">
            <h2>Add a new Year location</h2>
          </div>
          <div className="d-flex align-items-end mb-4">
            {/*<div className="inputContainer col-4 m-0 me-2">*/}
            {/*  <label className="h5 mb-1" htmlFor="title">*/}
            {/*    Title*/}
            {/*  </label>*/}
            {/*  <input*/}
            {/*    className="InputField w-100 m-0"*/}
            {/*    type="text"*/}
            {/*    id="title"*/}
            {/*    name="title"*/}
            {/*    value={selectedSchoolYearInputs.title}*/}
            {/*    onChange={(e) => {*/}
            {/*      updateSchoolYear("title", e.target.value);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            <span className="col-1 px-3" />
            <div className="inputContainer col-4 ms-2">
              <div className="h5 mb-1">Location</div>
              <CustomDropDown
                label={selectedLocation.label}
                options={locationsOptions}
                keyName={"label"}
                value={selectedLocation.label}
                onOptionClick={setSelectedLocation}
              />
            </div>
          </div>
          <div className="d-flex align-items-end mb-4">
            <div className="inputContainer col-4 m-0 me-2">
              <label className="h5 mb-1" htmlFor="start_date">
                First Date
              </label>
              <input
                className="InputField w-100 m-0"
                type="date"
                id="start_date"
                name="start_date"
                value={selectedSchoolYearInputs.start_date}
                onChange={(e) => {
                  updateSchoolYear("start_date", e.target.value);
                }}
              />
            </div>
            <span className="px-3 col-1 text-center pb-2">TO</span>
            <div className="inputContainer col-4 m-0 me-2">
              <label className="h5 mb-1" htmlFor="end_date">
                End Date
              </label>
              <input
                className="InputField w-100 m-0"
                type="date"
                id="end_date"
                name="end_date"
                value={selectedSchoolYearInputs.end_date}
                onChange={(e) => {
                  updateSchoolYear("end_date", e.target.value);
                }}
              />
            </div>
          </div>
          <h5>Learning Year Dates:</h5>
          <div className="d-flex align-items-center mb-4">
            <div className="inputContainer col-4 m-0 me-2">
              <input
                className="InputField w-100 m-0"
                type="date"
                name="learning_year_start"
                value={selectedSchoolYearInputs.learning_year_start}
                onChange={(e) => {
                  updateSchoolYear("learning_year_start", e.target.value);
                }}
              />
            </div>
            <span className="px-3 col-1 text-center">TO</span>
            <div className="inputContainer col-4 m-0">
              <input
                className="InputField w-100 m-0"
                type="date"
                name="learning_year_end"
                value={selectedSchoolYearInputs.learning_year_end}
                onChange={(e) => {
                  updateSchoolYear("learning_year_end", e.target.value);
                }}
              />
            </div>
          </div>
          <h5>Summer Session Dates:</h5>
          <div className="d-flex align-items-center mb-4">
            <div className="inputContainer col-4 m-0 me-2">
              <input
                className="InputField w-100 m-0"
                type="date"
                name="summer_session_start"
                value={selectedSchoolYearInputs.summer_session_start}
                onChange={(e) => {
                  updateSchoolYear("summer_session_start", e.target.value);
                }}
              />
            </div>
            <span className="px-3 col-1 text-center">TO</span>
            <div className="inputContainer col-4 m-0">
              <input
                className="InputField w-100 m-0"
                type="date"
                name="summer_session_end"
                value={selectedSchoolYearInputs.summer_session_end}
                onChange={(e) => {
                  updateSchoolYear("summer_session_end", e.target.value);
                }}
              />
            </div>
          </div>
          <hr className={"py-1"} />
          <h5>School Breaks</h5>
          {schoolYearBreaks.map((schoolBreak, index) => (
            <div key={index} className="d-flex align-items-center mb-4">
              <span className="h5 me-2">{index + 1}</span>
              <div className="inputContainer col-4 m-0 me-2">
                <input
                  className="InputField w-100 m-0"
                  type="date"
                  name={`school_break_${index}_from`}
                  value={schoolBreak.from}
                  onChange={(e) => {
                    updateSchoolYearBreaks(index, "from", e.target.value);
                  }}
                />
              </div>
              <span className="px-3">TO</span>
              <div className="inputContainer col-4 m-0">
                <input
                  className="InputField w-100 m-0"
                  type="date"
                  name={`school_break_${index}_to`}
                  value={schoolBreak.to}
                  onChange={(e) => {
                    updateSchoolYearBreaks(index, "to", e.target.value);
                  }}
                />
              </div>
              {index > 0 ? (
                <Button
                  className="ms-2"
                  color="danger"
                  onClick={() => removeSchoolBreak(index)}
                >
                  <i className="fa fa-times" />
                </Button>
              ) : null}
            </div>
          ))}
          <div className="d-flex justify-content-center">
            <button
              onClick={addAnotherSchoolBreak}
              className="submitbutton h5 px-5"
              style={{ width: 200 }}
            >
              Add More
            </button>
          </div>
          <hr className={"py-1"} />
          <h5>Makeup Deadlines</h5>
          {schoolYearMakeupDeadlines.map((makeup, index) => (
            <div key={index}>
              <div className="d-flex align-items-center mb-4">
                <span className="h5 me-2">Section {index + 1}</span>
                <div className="inputContainer col-4 m-0 me-2">
                  <input
                    className="InputField w-100 m-0"
                    type="date"
                    name={`start_date_${index}`}
                    value={makeup.start_date}
                    onChange={(e) => {
                      updateSchoolYearMakeups(
                        index,
                        "start_date",
                        e.target.value
                      );
                    }}
                  />
                </div>
                <span className="px-3">TO</span>
                <div className="inputContainer col-4 m-0">
                  <input
                    className="InputField w-100 m-0"
                    type="date"
                    name={`end_date_${index}`}
                    value={makeup.end_date}
                    onChange={(e) => {
                      updateSchoolYearMakeups(
                        index,
                        "end_date",
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                <span className="h5 me-2">Deadline</span>
                <div className="inputContainer col-4 m-0 me-2">
                  <input
                    className="InputField w-100 m-0"
                    type="date"
                    name={`deadline_${index}`}
                    value={makeup.deadline}
                    onChange={(e) => {
                      updateSchoolYearMakeups(
                        index,
                        "deadline",
                        e.target.value
                      );
                    }}
                  />
                </div>
                {schoolYearMakeupDeadlines.length > 1 ? (
                  <Button
                    className="ms-5"
                    color="danger"
                    onClick={() => removeMakeup(index)}
                  >
                    Remove this section
                    <i className="ms-2 fa fa-times" />
                  </Button>
                ) : null}
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-center align-items-center flex-column">
            <button
              onClick={addAnotherMakeup}
              className="submitbutton h5 px-5"
              style={{ width: 200 }}
            >
              Add More
            </button>

            <div className="d-flex justify-content-center align-items-center flex-column">
              {isDisabled() ? (
                <div className="alert alert-warning mb-2">
                  You have to fill all the previous information before adding
                </div>
              ) : null}
              <button
                disabled={isDisabled()}
                onClick={addNewYear}
                className={`${
                  isDisabled() ? "text-white border-none bg-secondary" : ""
                } submitbutton h5 px-5 mb-2`}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AddSchoolYearLocation;
