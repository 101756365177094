import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FcIdea } from "react-icons/fc";
import TeamProfileModal from "./TeamProfileModal";
import TeamMemberHeaderWrapper from "./TeamMemberHeaderWrapper";
import TeamMemberCardIconsWrapper from "./TeamMemberCardIconsWrapper";
import { useNavigate } from "react-router-dom";
import "./style.css";

function TeamProfile(props) {
  const { specialization, name, raisingTalent } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [nameForModal, setNameForModal] = useState(
    searchParams.get("name") === name
  );

  useEffect(() => {
    if (!Boolean(searchParams.get("name"))) setNameForModal(false);
  }, [searchParams]);

  const profileButtonHandler = (e) => {
    e.preventDefault();
    setNameForModal(name);
    navigate(`/our-team?name=${name}`);
  };
  return (
    <>
      {Boolean(nameForModal) && (
        <TeamProfileModal {...props} setIsModalActive={setNameForModal} />
      )}
      <figure className="teamMemberCard">
        {raisingTalent && (
          <span className="raisingTalentsSpan">
            raising talent{" "}
            <FcIdea className="raisingTalentIcon" title="raising talents" />
          </span>
        )}
        <TeamMemberHeaderWrapper {...props} />
        <TeamMemberCardIconsWrapper specialization={specialization} />
        <button
          onClick={profileButtonHandler}
          className="extendedContentButton"
        >
          Profile
        </button>
      </figure>
    </>
  );
}

export default TeamProfile;
