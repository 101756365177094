import { useEffect, useState } from "react";
import { statusEnum } from "../../utils";

import { Button, Skeleton, Switch, Checkbox } from "antd";
import { FeedbackStatusWrapper, StatusField } from "./styled";

const FeedbackStatus = ({
  feedback,
  onChange,
  isLoading,
  onSendEmailChange,
}) => {
  const [statusValue, setStatusValue] = useState(null);

  useEffect(() => {
    setStatusValue(feedback?.status);
  }, [feedback?.status]);

  return (
    <FeedbackStatusWrapper>
      <h4>Status</h4>
      {feedback ? (
        <>
          <StatusField>
            <span>On review</span>
            <Switch
              checked={statusValue === statusEnum.ON_REVIEW}
              onChange={() => setStatusValue(statusEnum.ON_REVIEW)}
              loading={isLoading}
            />
          </StatusField>
          <StatusField>
            <span>In progress</span>
            <Switch
              checked={statusValue === statusEnum.IN_PROGRESS}
              onChange={() => setStatusValue(statusEnum.IN_PROGRESS)}
              loading={isLoading}
            />
          </StatusField>
          <StatusField>
            <span>Completed</span>
            <Switch
              checked={statusValue === statusEnum.COMPLETED}
              onChange={() => setStatusValue(statusEnum.COMPLETED)}
              loading={isLoading}
            />
          </StatusField>
          <StatusField>
            <Checkbox
              onChange={() => onSendEmailChange((prevState) => !prevState)}
            >
              Send email notification to user
            </Checkbox>
          </StatusField>
          <StatusField>
            <Button
              type="primary"
              onClick={() => onChange(feedback, statusValue)}
              loading={isLoading}
            >
              Confirm
            </Button>
          </StatusField>
        </>
      ) : (
        <Skeleton />
      )}
    </FeedbackStatusWrapper>
  );
};

export default FeedbackStatus;
