import {
  addDoc,
  deleteDoc,
  documentId,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import { trialLessonStatuses } from "src/constants/trialLessonStatuses";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import { chunkArrayInGroups } from "src/utils/helpers";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getPrivateLessonsByIds = async (privateLessonsIds) => {
    try {
      if (!privateLessonsIds?.length) return [];

      const privateLessonsIdsChunks = chunkArrayInGroups(privateLessonsIds, 10);
      let requests = [];
      privateLessonsIdsChunks.forEach((privateLessonsIdsArray) => {
        const q = query(
          firebase.PrivateLessons(),
          where(documentId(), "in", privateLessonsIdsArray)
        );
        const req = getDocs(q);
        requests.push(req);
      });
      const lessonsSnapshotsChunks = await Promise.all(requests);
      const lessonsChunks = lessonsSnapshotsChunks.map((lessonsSnapshot) =>
        parseFirebaseDoc(lessonsSnapshot.docs)
      );
      const privateLessons = lessonsChunks.flat();
      return privateLessons;
    } catch (err) {
      console.log(err);
    }
  };

  const getTrialLessonsByIds = async (trialLessonsIds) => {
    try {
      if (!trialLessonsIds?.length) return [];

      const trialLessonsIdsChunks = chunkArrayInGroups(trialLessonsIds, 10);
      let requests = [];
      trialLessonsIdsChunks.forEach((trialLessonsIdsArray) => {
        const q = query(
          firebase.trialLessons(),
          where(documentId(), "in", trialLessonsIdsArray)
        );
        const req = getDocs(q);
        requests.push(req);
      });
      const lessonsSnapshotsChunks = await Promise.all(requests);
      const lessonsChunks = lessonsSnapshotsChunks.map((lessonsSnapshot) =>
        parseFirebaseDoc(lessonsSnapshot.docs)
      );
      const trialLessons = lessonsChunks.flat();
      return trialLessons;
    } catch (err) {
      console.log(err);
    }
  };

  const getMakeupLessonsByIds = async (makeupLessonsIds) => {
    try {
      if (!makeupLessonsIds?.length) return [];

      const makeupLessonsIdsChunks = chunkArrayInGroups(makeupLessonsIds, 10);
      let requests = [];
      makeupLessonsIdsChunks.forEach((makeupLessonsIdsArray) => {
        const q = query(
          firebase.makeupLessons(),
          where(documentId(), "in", makeupLessonsIdsArray)
        );
        const req = getDocs(q);
        requests.push(req);
      });
      const lessonsSnapshotsChunks = await Promise.all(requests);
      const lessonsChunks = lessonsSnapshotsChunks.map((lessonsSnapshot) =>
        parseFirebaseDoc(lessonsSnapshot.docs)
      );
      const makeupLessons = lessonsChunks.flat();
      return makeupLessons;
    } catch (err) {
      console.log(err);
    }
  };

  const getPrivateLessonById = async (lessonId) => {
    try {
      if (!lessonId) return;
      const lessonSnap = await getDoc(firebase.PrivateLesson(lessonId));

      let lessonObj;
      if (lessonSnap.exists()) {
        lessonObj = {
          id: lessonId,
          ...lessonSnap.data(),
        };
      }
      return lessonObj;
    } catch (err) {
      console.log(err);
    }
  };

  const updateAbsence = async (absenceId, updateObj) => {
    try {
      if (!absenceId || !updateObj) return;

      await updateDoc(firebase.absence(absenceId), updateObj);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelTrialLesson = async (lessonId) => {
    try {
      if (!lessonId) return;

      const updateObj = {
        status: trialLessonStatuses.canceled.code,
      };

      await updateDoc(firebase.trialLesson(lessonId), updateObj);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMakeupLessonAndAddAbsenceComment = async (
    lessonId,
    lessonAbsenceId,
    commentBody
  ) => {
    try {
      if (!lessonId) return;

      const absence = (await getDoc(firebase.absence(lessonAbsenceId))).data();
      const absenceOriginalComment = absence?.comment || "";

      const combinedComment = absenceOriginalComment + "\n" + commentBody;

      await Promise.all([
        deleteDoc(firebase.makeupLesson(lessonId)),
        updateDoc(firebase.absence(lessonAbsenceId), {
          comment: combinedComment,
        }),
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  const createMakeupOpening = async (makeupOpeningObj) => {
    try {
      if (!makeupOpeningObj) return;

      const res = await addDoc(firebase.makeupOpenings(), makeupOpeningObj);
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getPrivateLessonsByIds,
    getTrialLessonsByIds,
    getMakeupLessonsByIds,
    getPrivateLessonById,
    updateAbsence,
    cancelTrialLesson,
    deleteMakeupLessonAndAddAbsenceComment,
    createMakeupOpening,
  };
};

export default useFirebaseFns;
