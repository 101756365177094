export const modalMap = {
  registration: {
    name: "registration",
  },
  teacherCard: {
    name: "teacherCard",
  },
  teacherInfo: {
    name: "teacherInfo",
  },
  lessonsInfo: {
    name: "lessonsInfo",
  },
  teacherBreaks: {
    name: "teacherBreaks",
  },
  teacherReactivation: {
    name: "teacherReactivation",
  },
  deleteTeacherLibraryItems: {
    name: "deleteTeacherLibraryItems",
  },
};
