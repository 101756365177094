import { useEffect, useState } from "react";
import { useFeedbacks } from "../../../hooks";
import store from "src/stores/UserStore";

import { Form, Input } from "antd";
import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { ActionSubmitButton } from "src/components/Dashboard/Applications/styled";

const ForwardFeedbackModal = ({ isOpen, toggle, feedback, onConfirm }) => {
  const { user } = store;
  const { forwardFeedbackToDeveloper } = useFeedbacks();

  const [fromNameValue, setFromNameValue] = useState(null);
  const [subjectValue, setSubjectValue] = useState(null);
  const [messageValue, setMessageValue] = useState(null);

  useEffect(() => {
    setFromNameValue(user?.fullName);
    setSubjectValue(
      `Forwarded feedback from ${feedback?.createdBy?.role?.replace(
        /_/g,
        " "
      )} user`
    );
    setMessageValue(`Feedback message: ${feedback?.message}`);
  }, [feedback?.createdBy?.role, feedback?.message, user?.fullName]);

  const handleFinish = () => {
    forwardFeedbackToDeveloper(feedback, subjectValue, messageValue);
    onConfirm();
  };

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>
        Forward feedback to developer
      </StyledModalHeader>
      <ModalBody>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item label="Developer's name" required>
            <Input name="to_name" />
          </Form.Item>
          <Form.Item label="Developer's email" required>
            <Input name="to_email" type="email" />
          </Form.Item>
          <Form.Item label="Your name" required>
            <Input
              name="from_name"
              value={fromNameValue}
              onChange={({ target }) => setFromNameValue(target?.value)}
            />
          </Form.Item>
          <Form.Item label="Subject" required>
            <Input
              name="subject"
              value={subjectValue}
              onChange={({ target }) => setSubjectValue(target?.value)}
            />
          </Form.Item>
          <Form.Item label="Message" required>
            <Input.TextArea
              name="message"
              value={messageValue}
              onChange={({ target }) => setMessageValue(target?.value)}
            />
          </Form.Item>
          <Form.Item>
            <ActionSubmitButton type="primary" htmlType="submit" size="large">
              Send
            </ActionSubmitButton>
          </Form.Item>
        </Form>
      </ModalBody>
    </StyledModal>
  );
};

export default ForwardFeedbackModal;
