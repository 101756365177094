import React from "react";
import { groupClassPaymentTypes } from "src/constants/groupClassPaymentsConstants";
import DirectPaymentButton from "./DirectPaymentButton";
import ScheduledPaymentButton from "./ScheduledPaymentButton";

const PaymentButtonsSection = ({
  directPaymentOption,
  scheduledPaymentOption,
  selectedPaymentOption,
  subscriptionSuccessCallback,
  studentId,
  studentType,
  groupClassId,
}) => {
  const renderPaymentButton = () => {
    if (selectedPaymentOption === groupClassPaymentTypes.ONE_TIME) {
      return (
        <DirectPaymentButton
          paymentOption={directPaymentOption}
          onPaymentSuccess={() => {
            window.location.reload();
          }}
          studentId={studentId}
          studentType={studentType}
          groupClassId={groupClassId}
        />
      );
    } else if (selectedPaymentOption === groupClassPaymentTypes.SCHEDULED) {
      return (
        <ScheduledPaymentButton
          paymentOption={scheduledPaymentOption}
          onSubscriptionSuccess={(subscriptionID) =>
            subscriptionSuccessCallback(subscriptionID)
          }
        />
      );
    } else {
      return <div></div>;
    }
  };

  return <div>{renderPaymentButton()}</div>;
};

export default PaymentButtonsSection;
