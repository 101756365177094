import React, { useState, useEffect, useContext, useMemo } from "react";
import { getDocs } from "firebase/firestore";
import { FirebaseContext } from "../../../../Firebase";
import { getFirebaseDoc } from "../../../../../utils/getFirebaseDoc";
import useFirebaseFns from "./useFirebaseFns";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import {
  addInstrumentsToTeachers,
  attachAvailableDaysToTeachers,
  filterTeachersByLocation,
} from "../helperFns";
import { studentTypes } from "../../constants";
import {
  getCombinedSchoolBreaks,
  getCombinedSchoolYears,
  getCurrentSchoolYear,
  getNextSchoolYear,
  updatedMomentNow,
} from "src/utils/helpers";
import {
  parseAbsenceObjDates,
  parseBreakDates,
  parseCalendarLabelDates,
  parseMakeupLessonDates,
  parsePrivateLessonDates,
  parseTrialLessonDates,
} from "src/utils/firebaseDatesParserFns";
import moment from "moment";

const selectedTeachersDataInitialValues = {
  selectedTeachersPLs: [],
  selectedTeachersAbsences: [],
  selectedTeachersTLs: [],
  selectedTeachersMakeupLessons: [],
  selectedTeachersAppointments: [],
  selectedTeachersLabels: [],
  selectedTeachersBreaks: [],
  selectedTeachersStudioUsages: [],
  selectedTeachersGroupClasses: [],
};

const useScheduleData = () => {
  const firebase = useContext(FirebaseContext);
  const {
    getTeachers,
    getStudentsByIds,
    getPrivateLessonsByTeacherIds,
    getTrialLessonsByTeacherIds,
    getSchoolYears,
    getMakeupLessonsByTeacherIds,
    getAppointmentsByTeacherIds,
    getAbsencesByTeacherIds,
    getCalendarLabelsByTeacherIds,
    getBreaksByTeacherIds,
  } = useFirebaseFns();

  const {
    getTeachersAvailableDays,
    getStudioUsage,
    getGroupClassesByTeachersIds,
    getGroupClassesByIds,
    getGroupClassAbsences,
  } = useGlobalFirebaseFns();

  // const [currentLocationsIds, setCurrentLocationsIds] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");

  // initial page load state
  const [initialData, setInitialData] = useState({
    teachers: {},
    instruments: {},
    programs: {},
    locations: {},
    studios: {},
    schoolYears: [],
    groupClassesAbsences: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);

  // selectedTeachers is an object with the teachers ids representing the selected teachers in the checkboxes
  const [selectedTeachers, setSelectedTeachers] = useState({});
  const [selectedTeachersData, setSelectedTeachersData] = useState(
    selectedTeachersDataInitialValues
  );

  const [loadingTeachersLessons, setLoadingTeachersLessons] = useState(false);
  //a counter that increments when we refresh the selected teachers events (ex when adding an appointment)
  const [refreshEvents, setRefreshEvents] = useState(0);
  useState(0);
  const refreshSelectedTeachersEvents = () => {
    setRefreshEvents((oldVal) => oldVal + 1);
  };

  // selectedEvent is when you click on an event on the calendar
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleSelectedTeachersChange = (id) => {
    setSelectedTeachers((prev) => {
      const newPrev = { ...prev };
      if (prev[id]) {
        const { [id]: x, ...rest } = newPrev;
        return { ...rest };
      } else {
        newPrev[id] = id;
        return { ...newPrev };
      }
    });
  };
  const handleClearSelectedTeachers = () => {
    setSelectedTeachers({});
  };
  const handleSelectedLocationIdChange = (locationId) => {
    if (locationId) {
      setSelectedTeachers({});
      setSelectedLocationId(locationId);
    }
  };

  const fetchInitialPageLoadData = async () => {
    try {
      setLoadingInitialData(true);
      // fetching all data we need on the page load
      const firebaseRequests = [
        // getTeachersWithAvailableDays(),
        getTeachers(),
        getTeachersAvailableDays(),
        getDocs(firebase.instruments()),
        getDocs(firebase.programs()),
        getDocs(firebase.locations()),
        getDocs(firebase.studios()),
        getSchoolYears(),
        getGroupClassAbsences(),
      ];
      const [
        teachers,
        teachersAvailableDays,
        instrumentsSnapshot,
        programsSnapshot,
        locationsSnapshot,
        studiosSnapshot,
        schoolYears,
        groupClassesAbsences,
      ] = await Promise.all(firebaseRequests);
      const instruments = getFirebaseDoc(instrumentsSnapshot);
      const programs = getFirebaseDoc(programsSnapshot);
      const locations = getFirebaseDoc(locationsSnapshot);
      const studios = getFirebaseDoc(studiosSnapshot);

      const teachersWithAvailableDays = attachAvailableDaysToTeachers(
        teachers,
        teachersAvailableDays
      );
      // const teachersWithinLocations = filterTeachersByLocation(
      //   teachersWithAvailableDays,
      //   selectedLocationId
      // );
      const teachersWithInstruments = addInstrumentsToTeachers(
        teachersWithAvailableDays,
        instruments,
        programs
      );

      // Setting the state for teachers, instruments, programs and locations
      setInitialData((oldVal) => ({
        ...oldVal,
        teachers: teachersWithInstruments,
        instruments,
        programs,
        locations,
        studios,
        schoolYears,
        groupClassesAbsences,
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingInitialData(false);
    }
  };

  // Fetches the lessons for the selected teachers (Supports up to 10 teachers) and adds extra data to the lessons
  const fetchSelectedTeachersInfo = async (selectedTeachersIds) => {
    if (!selectedTeachersIds.length) {
      setSelectedTeachersData(selectedTeachersDataInitialValues);

      return;
    }
    try {
      setLoadingTeachersLessons(true);
      // Gets lessons that the selected teachers teach, within the locations selected
      const privateLessonsReq = getPrivateLessonsByTeacherIds(
        selectedTeachersIds,
        selectedLocationId
      );
      const trialLessonsReq = getTrialLessonsByTeacherIds(
        selectedTeachersIds,
        selectedLocationId
      );
      const makeupLessonsReq = getMakeupLessonsByTeacherIds(
        selectedTeachersIds,
        selectedLocationId
      );
      const appointmentsReq = getAppointmentsByTeacherIds(
        selectedTeachersIds,
        selectedLocationId
      );

      const groupClassesReqs =
        getGroupClassesByTeachersIds(selectedTeachersIds);

      // only gets the studios usages for the current year with the selected teachers
      const studioUsageReq = getStudioUsage(
        null,
        updatedMomentNow().year(),
        null,
        selectedTeachersIds
      );

      const calendarLabelsReq =
        getCalendarLabelsByTeacherIds(selectedTeachersIds);

      const absencesReq = getAbsencesByTeacherIds(selectedTeachersIds);

      const breaksReq = getBreaksByTeacherIds(selectedTeachersIds);

      const [
        privateLessons,
        trialLessons,
        makeupLessons,
        appointments,
        calendarLabels,
        absences,
        breaks,
        selectedTeachersStudioUsages,
        groupClasses,
      ] = await Promise.all([
        privateLessonsReq,
        trialLessonsReq,
        makeupLessonsReq,
        appointmentsReq,
        calendarLabelsReq,
        absencesReq,
        breaksReq,
        studioUsageReq,
        groupClassesReqs,
      ]);
      const privateLessonsStudentIds = Object.values(privateLessons).map(
        ({ studentId }) => studentId
      );
      const trialLessonsStudentIds = Object.values(trialLessons).map(
        ({ studentId }) => studentId
      );
      const makeupLessonsStudentIds = Object.values(makeupLessons).map(
        ({ studentId }) => studentId
      );
      // Getting student Ids of all students that are in a trial or private lesson
      const uniquePrivateStudentIds = [
        ...new Set([...privateLessonsStudentIds, ...makeupLessonsStudentIds]),
      ];
      const uniqueTrialStudentIds = [...new Set(trialLessonsStudentIds)];
      let allStudentsReqs = [];
      if (uniquePrivateStudentIds.length) {
        allStudentsReqs[0] = getStudentsByIds(
          uniquePrivateStudentIds,
          studentTypes.privateStudent.name
        );
      }
      if (uniqueTrialStudentIds.length) {
        allStudentsReqs[1] = getStudentsByIds(
          uniqueTrialStudentIds,
          studentTypes.trialStudent.name
        );
      }
      const [privateStudents = [], trialStudents = []] = await Promise.all(
        allStudentsReqs
      );
      const privateLessonsWithAllData = Object.values(privateLessons).map(
        (lesson) => {
          const lessonWithDates = parsePrivateLessonDates(lesson);
          const { studentId, timeline, packageSets } = lessonWithDates || {};

          const lessonStudent = privateStudents[studentId];

          const timelineWithTeachers = timeline?.map((timelineObj) => {
            const teacher = initialData?.teachers[timelineObj.teacherId];

            return {
              ...timelineObj,
              teacher,
            };
          });

          const packageSetsWithTeachers = packageSets?.map((setObj) => {
            const { setItems } = setObj;
            const updatedSetItems = setItems?.map((setItem) => {
              const teacher = initialData?.teachers[setItem.teacherId];
              return {
                ...setItem,
                teacher,
              };
            });
            return {
              ...setObj,
              setItems: updatedSetItems,
            };
          });

          return {
            ...lessonWithDates,
            student: lessonStudent,
            ...(timeline && { timeline: timelineWithTeachers }),
            ...(packageSets && { packageSets: packageSetsWithTeachers }),
          };
        }
      );
      const trialLessonsWithAllData = Object.values(trialLessons).map(
        (lesson) => {
          const { teacherId, studentId, instrument, locations } = lesson || {};
          const lessonWithDates = parseTrialLessonDates(lesson);

          const lessonStudent = trialStudents[studentId];
          // const lessonProgram = initialData?.programs[programId];
          const lessonInstrument = initialData?.instruments[instrument];
          const lessonLocations = locations?.map(
            (locationId) => initialData?.locations[locationId]
          );
          const lessonTeacher = initialData?.teachers[teacherId];
          return {
            ...lessonWithDates,
            teacher: lessonTeacher,
            student: lessonStudent,
            // program: lessonProgram,
            // instrument: lessonInstrument,
            // locations: lessonLocations,
          };
        }
      );

      const makeupLessonsWithAllData = Object.values(makeupLessons).map(
        (lesson) => {
          const {
            forLessonId,
            forLessonType,
            programId,
            teacherId,
            studentId,
            instrumentId,
            locationId,
          } = lesson || {};

          const lessonWithDates = parseMakeupLessonDates(lesson);

          const lessonStudent = privateStudents[studentId];
          const lessonInstrument = initialData?.instruments[instrumentId];
          const lessonProgram = initialData?.programs[programId];
          const lessonLocation = initialData?.locations[locationId];
          const lessonTeacher = initialData?.teachers[teacherId];

          return {
            ...lessonWithDates,
            teacher: lessonTeacher,
            student: lessonStudent,
            // program: lessonProgram,
            // instrument: lessonInstrument,
            // location: lessonLocation,
          };
        }
      );

      const appointmentsWithAllData = Object.values(appointments).map(
        (appointment) => {
          const teacher = initialData?.teachers[appointment.teacherId];
          return {
            ...appointment,
            startDate: new Date(
              appointment.startDate?.toDate()?.setSeconds(0, 0)
            ),
            endDate: new Date(appointment.endDate?.toDate()?.setSeconds(0, 0)),
            teacher,
          };
        }
      );

      const groupClassesWithAllData = Object.values(groupClasses).map(
        (groupClass) => {
          const { classes } = groupClass || {};

          const classesWithTeachers = classes?.map((classObj) => {
            const { teachersIds } = classObj;
            const classTeachers = teachersIds.map(
              (teacherId) => initialData?.teachers[teacherId]
            );

            return {
              ...classObj,
              teachers: classTeachers,
            };
          });

          return {
            ...groupClass,
            classes: classesWithTeachers,
          };
        }
      );
      //Absences Section
      const absencesWithDates = absences.map((absence) => {
        return parseAbsenceObjDates(absence);
      });

      // Calendar Labels
      const labelsWithDates = Object.values(calendarLabels).map((label) =>
        parseCalendarLabelDates(label)
      );

      const breaksWithAllData = breaks.map((userBreak) => {
        const { userId } = userBreak || {};

        const breakWithDates = parseBreakDates(userBreak);

        const breakTeacher = initialData?.teachers[userId];

        return {
          ...breakWithDates,
          teacher: breakTeacher,
        };
      });
      setSelectedTeachersData((oldVal) => ({
        ...oldVal,
        selectedTeachersPLs: privateLessonsWithAllData,
        selectedTeachersAbsences: absencesWithDates,
        selectedTeachersTLs: trialLessonsWithAllData,
        selectedTeachersMakeupLessons: makeupLessonsWithAllData,
        selectedTeachersAppointments: appointmentsWithAllData,
        selectedTeachersLabels: labelsWithDates,
        selectedTeachersBreaks: breaksWithAllData,
        selectedTeachersStudioUsages: selectedTeachersStudioUsages,
        selectedTeachersGroupClasses: groupClassesWithAllData,
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTeachersLessons(false);
    }
  };

  useEffect(() => {
    const getTeachers = async () => {
      await fetchInitialPageLoadData();
    };
    getTeachers();
  }, [selectedLocationId]);

  useEffect(() => {
    if (!loadingInitialData) {
      const getTeachersLessons = async () => {
        await fetchSelectedTeachersInfo(Object.keys(selectedTeachers));
      };
      getTeachersLessons();
    }
  }, [loadingInitialData, selectedTeachers, refreshEvents]);

  // set the first location as default value for the locations select
  useEffect(() => {
    const locationsArr = Object.values(initialData.locations || {});
    if (locationsArr.length && !selectedLocationId) {
      setSelectedLocationId(locationsArr[0]?.id);
    }
  }, [initialData.locations]);

  // // current school year based on the selectedEvent locationId
  // const currentSchoolYearInLocation = useMemo(
  //   () => getCurrentSchoolYear(initialData.schoolYears, selectedLocationId),
  //   [initialData, selectedLocationId]
  // );

  // // next school year based on the selectedEvent locationId
  // const nextSchoolYearInLocation = useMemo(
  //   () =>
  //     getNextSchoolYear(
  //       initialData.schoolYears,
  //       selectedLocationId,
  //       currentSchoolYearInLocation
  //     ),
  //   [initialData, selectedLocationId, currentSchoolYearInLocation]
  // );

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears.length && !selectedLocationId) return [];

    const combinedSchoolBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      selectedLocationId,
      true
    );
    return combinedSchoolBreaks;
  }, [initialData, selectedLocationId]);

  return {
    initialData,
    loadingInitialData,
    selectedTeachers,
    loadingTeachersLessons,
    selectedEvent,
    handleSelectedTeachersChange,
    setSelectedEvent,
    // nextSchoolYearInLocation,
    refreshSelectedTeachersEvents,
    selectedLocationId,
    handleSelectedLocationIdChange,
    // currentSchoolYearInLocation,
    selectedTeachersData,
    combinedSchoolBreaks,
    handleClearSelectedTeachers,
  };
};

export default useScheduleData;
