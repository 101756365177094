import React from "react";

const WeekHeader = ({ date, localizer }) => (
  <div className="mt-3">
    <p>{localizer.format(date, "ddd")}</p>
    <h2>{localizer.format(date, "DD")}</h2>
  </div>
);

export default WeekHeader;
