import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import { injectUserStore } from "src/utils/helpers";
import CreateConcertSoloProgramBody from "./CreateConcertSoloProgramBody";
import useCreateConcertSoloProgram from "./hooks/useCreateConcertSoloProgram";

const CreateConcertSoloProgramModal = ({
  onActionSuccess = () => {},
  modalData,
  hasAlreadySignedUp = true,
  signupId,
  UserStore,
  isEditMode = false,
  programId,
  // if not hasAlreadySignedUp
  concertId,
}) => {
  const user = UserStore.user;

  const hookData = useCreateConcertSoloProgram({
    hasAlreadySignedUp,
    concertId,
    onActionSuccess,
    user,
    signupId,
    isEditMode,
    programId,
  });

  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        // padding={"50px"}
        size="lg"
      >
        <CustomModalHeader
          tag="div"
          close={
            <CloseIcon
              onClick={() => {
                modalData.closeModal();
              }}
            />
          }
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
          padding="25px"
        >
          <h2>{isEditMode ? "Edit" : "Create"} Solo Program</h2>
        </CustomModalHeader>
        <CustomModalBody padding="25px">
          <CreateConcertSoloProgramBody
            isEditMode={isEditMode}
            hookData={hookData}
          />
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default injectUserStore(CreateConcertSoloProgramModal);
