import { useMemo } from "react";
import useModal from "src/hooks/useModal";
import usePayments from "./hooks/usePayments";
import { injectUserStore, updatedMomentNow } from "src/utils/helpers";

import RouteContent from "src/components/common/RouteContent";
import { PrimaryButton } from "src/utils/shared/styled";
import PaymentHistoryModal from "../common/components/PaymentHistoryModal";
import PaymentChart from "./PaymentChart";
import PaymentsList from "./PaymentsList";
import { LayoutContainer } from "./styled";
import { Divider } from "antd";
import { isTeacher } from "src/constants/UserRoleEnum";

const MyPayments = ({ UserStore }) => {
  const user = UserStore.user;

  const historyModalData = useModal();

  const paymentsData = usePayments({ userId: user.uid });
  const {
    currentPaymentObj,
    filteredPaymentsList,
    setCurrentPaymentId,
    dataSetList,
    selectedFilter,
    setSelectedFilter,
    selectedMonth,
    handleSelectedMonthChange,
    customStart,
    setCustomStart,
    customEnd,
    setCustomEnd,
    initialData,
  } = paymentsData;

  const annualIncome = useMemo(() => {
    const currentYear = new Date().getFullYear();

    const currentYearPayments = initialData?.userPaymentsList
      ?.filter((item) => {
        const itemYear = new Date(item?.endDate)?.getFullYear();
        return itemYear === currentYear;
      })
      ?.flatMap((item) => item?.payments);

    const total = currentYearPayments?.reduce(
      (acc, item) => acc + item?.totalPaid,
      0
    );

    return total;
  }, [initialData?.userPaymentsList]);

  const annualHours = dataSetList?.reduce((acc, item) => acc + item.value, 0);

  const paymentChartMarkup = dataSetList?.length ? (
    <>
      <PaymentChart
        seriesDataList={dataSetList}
        seriesName={isTeacher(user?.role) ? "Activity in hours" : "Activity"}
        seriesSubtitle={`For the year ${updatedMomentNow().format("YYYY")}`}
      />
      <Divider />
      <p>
        Annual gross income:{" "}
        <span>${annualIncome?.toLocaleString("en-US")}</span>
      </p>
      <Divider />
      {isTeacher(user?.role) && (
        <p>
          Annual hours: <span>{annualHours?.toLocaleString("en-US")}</span>
        </p>
      )}
    </>
  ) : (
    <h3 className="text-center">No activity this year</h3>
  );

  return (
    <RouteContent title="Artis Payment">
      <LayoutContainer>
        <div className="chart-mobile">{paymentChartMarkup}</div>
        <div>
          <div style={{ height: "75vh", overflow: "auto" }}>
            {filteredPaymentsList.length ? (
              <PaymentsList
                currentPaymentObj={currentPaymentObj}
                setCurrentPaymentId={setCurrentPaymentId}
                paymentsList={initialData?.userPaymentsList}
              />
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <h3>No Payments Found</h3>
              </div>
            )}
          </div>
          <div className="text-end mt-4">
            <PrimaryButton onClick={historyModalData.openModal}>
              Report
            </PrimaryButton>
          </div>
        </div>
        <div className="overflow-hidden chart-desktop">
          {paymentChartMarkup}
        </div>
      </LayoutContainer>
      {/* MODALS */}
      {historyModalData && (
        <PaymentHistoryModal
          modalData={historyModalData}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedMonth={selectedMonth}
          handleSelectedMonthChange={handleSelectedMonthChange}
          customStart={customStart}
          setCustomStart={setCustomStart}
          customEnd={customEnd}
          setCustomEnd={setCustomEnd}
        />
      )}
    </RouteContent>
  );
};

export default injectUserStore(MyPayments);
