import moment from "moment";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const CustomRangePicker = ({
  picker = null,
  onChange,
  format = "MM/DD/YYYY",
}) => {
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <RangePicker
      style={{
        marginTop: 24,
        width: "100%",
      }}
      picker={picker}
      onChange={onChange}
      format={format}
      disabledDate={disabledDate}
      inputReadOnly
    />
  );
};

export default CustomRangePicker;
