import React, { useEffect, useMemo, useState } from "react";
import {
  parseAbsenceObjDates,
  parseBreakDates,
  parseMakeupLessonDates,
  parsePrivateLessonDates,
  parseTrialLessonDates,
} from "src/utils/firebaseDatesParserFns";
import {
  getCombinedPackageSetItems,
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getNextSchoolYear,
  updatedMomentNow,
} from "src/utils/helpers";
import { studentTypes } from "../../constants";
import {
  addInstrumentsToTeacher,
  attachAvailableDaysToTeacher,
  filterInitialDataByLocation,
} from "../helperFns";
import useFirebaseFns from "./useFirebaseFns";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import moment from "moment";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";

const useScheduleData = (user) => {
  const {
    getTeacherAvailableDays,
    getTeacherPLs,
    getTeacherTLs,
    getInstruments,
    getPrograms,
    getStudios,
    getLocations,
    getStudentsByIds,
    getTeacherMakeupLessons,
    getTeacherAppointments,
    getTeacherAbsences,
    getSchoolYears,
    getBreaksByTeacherId,
  } = useFirebaseFns();
  const { getStudioUsage, getGroupClassesByTeachersIds, getUsersByIds } =
    useGlobalFirebaseFns();

  // contains all locations for all events for that student
  const [allEventsLocationsIds, setAllEventsLocationsIds] = useState([]);
  // the selected location for displaying events on calendar
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const handleSelectedLocationIdChange = (locationId) => {
    if (locationId) {
      setSelectedLocationId(locationId);
    }
  };

  // initial page load state
  const [initialData, setInitialData] = useState({
    instruments: {},
    programs: {},
    locations: {},
    studios: {},
    teacher: {},
    teacherPLs: [],
    teacherTLs: [],
    schoolYears: [],
    teacherMakeupLessons: [],
    teacherAppointments: [],
    absences: [],
    breaks: [],
    studioUsages: [],
    groupClasses: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);

  // selectedEvent is when you click on an event on the calendar
  const [selectedEvent, setSelectedEvent] = useState(null);

  const fetchInitialPageLoadData = async () => {
    try {
      setLoadingInitialData(true);
      // fetching all data we need on the page load
      const firebaseRequests = [
        getTeacherAvailableDays(user.uid),
        getTeacherPLs(user.uid),
        getTeacherTLs(user.uid),
        getInstruments(),
        getPrograms(),
        getLocations(),
        getStudios(),
        getSchoolYears(),
        getTeacherMakeupLessons(user.uid),
        getTeacherAppointments(user.uid),
        getTeacherAbsences(user?.uid),
        getBreaksByTeacherId(user?.uid),
        getStudioUsage(null, updatedMomentNow().year(), null, [user?.uid]),
        getGroupClassesByTeachersIds([user?.uid]),
      ];
      const [
        teacherAvailableDays,
        teacherPLs,
        teacherTLs,
        instruments,
        programs,
        locations,
        studios,
        schoolYears,
        teacherMakeupLessons,
        teacherAppointments,
        absences,
        breaks,
        studioUsages,
        groupClasses,
      ] = await Promise.all(firebaseRequests);

      const teacherWithAvailableDays = attachAvailableDaysToTeacher(
        user,
        teacherAvailableDays
      );
      const teacherWithInstruments = addInstrumentsToTeacher(
        teacherWithAvailableDays,
        instruments,
        programs
      );

      // LESSONS SECTION
      const privateLessonsStudentIds = Object.values(teacherPLs).map(
        ({ studentId }) => studentId
      );
      const trialLessonsStudentIds = Object.values(teacherTLs).map(
        ({ studentId }) => studentId
      );
      const makeupLessonsStudentIds = Object.values(teacherMakeupLessons).map(
        ({ studentId }) => studentId
      );
      // Getting student Ids of all students that are in a trial or private or makeup lesson
      // Assuming the makeup students are private students
      const uniquePrivateStudentIds = [
        ...new Set([...privateLessonsStudentIds, ...makeupLessonsStudentIds]),
      ];
      const uniqueTrialStudentIds = [...new Set(trialLessonsStudentIds)];

      let allStudentsReqs = [];
      if (uniquePrivateStudentIds.length) {
        allStudentsReqs[0] = getStudentsByIds(
          uniquePrivateStudentIds,
          studentTypes.privateStudent.name
        );
      }
      if (uniqueTrialStudentIds.length) {
        allStudentsReqs[1] = getStudentsByIds(
          uniqueTrialStudentIds,
          studentTypes.trialStudent.name
        );
      }

      const groupClassTeachersIds = [
        ...new Set(groupClasses.map(({ teachersIds }) => teachersIds).flat()),
      ];
      const [privateStudents = [], trialStudents = []] = await Promise.all([
        ...allStudentsReqs,
      ]);
      const groupClassTeachers = await getUsersByIds(groupClassTeachersIds);

      const groupClassesWithAllData = groupClasses.map((groupClass) => {
        const { classes } = groupClass || {};

        const classesWithTeacher = classes?.map((classObj) => {
          const teachers = classObj.teachersIds.map((id) =>
            groupClassTeachers.find((teacher) => teacher.id === id)
          );
          return {
            ...classObj,
            teachers,
          };
        });
        return {
          ...groupClass,
          classes: classesWithTeacher,
        };
      });

      const privateLessonsWithAllData = Object.values(teacherPLs).map(
        (lesson) => {
          const lessonWithDates = parsePrivateLessonDates(lesson);
          const { studentId, timeline, packageSets } = lessonWithDates || {};

          const lessonStudent = privateStudents[studentId];
          const timelineWithTeacher = timeline?.map((timelineObj) => {
            return {
              ...timelineObj,
              ...(timelineObj.teacherId === user?.uid && {
                teacher: teacherWithInstruments,
              }),
            };
          });

          const packageSetsWithTeachers = packageSets?.map((setObj) => {
            const { setItems } = setObj;
            const updatedSetItems = setItems?.map((setItem) => {
              return {
                ...setItem,
                ...(setItem.teacherId === user?.uid && {
                  teacher: teacherWithInstruments,
                }),
              };
            });
            return {
              ...setObj,
              setItems: updatedSetItems,
            };
          });
          return {
            ...lessonWithDates,
            student: lessonStudent,
            ...(timeline && { timeline: timelineWithTeacher }),
            ...(packageSets && { packageSets: packageSetsWithTeachers }),
          };
        }
      );
      const trialLessonsWithAllData = Object.values(teacherTLs).map(
        (lesson) => {
          const {
            teacherId,
            studentId,
            instrument,
            locations: lessonLocationsIds,
          } = lesson || {};

          const lessonWithDates = parseTrialLessonDates(lesson);

          const lessonStudent = trialStudents[studentId];
          // const lessonProgram = programs[programId];
          const lessonInstrument = instruments[instrument];
          const lessonLocations = lessonLocationsIds?.map(
            (locationId) => locations[locationId]
          );
          const lessonTeacher = teacherWithInstruments;
          return {
            ...lessonWithDates,
            teacher: lessonTeacher,
            student: lessonStudent,
            // program: lessonProgram,
            // instrument: lessonInstrument,
            // locations: lessonLocations,
          };
        }
      );

      const makeupLessonsWithAllData = Object.values(teacherMakeupLessons).map(
        (lesson) => {
          const {
            forLessonId,
            forLessonType,
            programId,
            teacherId,
            studentId,
            instrumentId,
            locationId,
          } = lesson || {};
          const lessonWithDates = parseMakeupLessonDates(lesson);

          const lessonStudent = privateStudents[studentId];
          const lessonInstrument = instruments[instrumentId];
          const lessonProgram = programs[programId];
          const lessonLocation = locations[locationId];
          const lessonTeacher = teacherWithInstruments;

          return {
            ...lessonWithDates,
            teacher: lessonTeacher,
            student: lessonStudent,
            // program: lessonProgram,
            // instrument: lessonInstrument,
            // location: lessonLocation,
          };
        }
      );

      const appointmentsWithAllData = Object.values(teacherAppointments).map(
        (appointment) => {
          return {
            ...appointment,
            startDate: new Date(
              appointment.startDate?.toDate()?.setSeconds(0, 0)
            ),
            endDate: new Date(appointment.endDate?.toDate()?.setSeconds(0, 0)),
            teacher: teacherWithInstruments,
          };
        }
      );

      //Absences Section
      const absencesWithDates = absences.map((absence) => {
        return parseAbsenceObjDates(absence);
      });

      // Teacher breaks
      const breaksWithAllData = breaks.map((userBreak) => {
        const { userId } = userBreak;

        const userBreakWithDates = parseBreakDates(userBreak);

        const breakTeacher = teacherWithInstruments;
        return {
          ...userBreakWithDates,
          teacher: breakTeacher,
        };
      });

      // GETTING ALL UNIQUE LOCATIONS FOR THE EVENTS
      const teacherPLsLocationsChunks = Object.values(
        privateLessonsWithAllData
      ).map(({ timeline, packageSets, type }) => {
        return isPackagePrivateLesson(type)
          ? getCombinedPackageSetItems(packageSets)?.map(
              ({ locationId }) => locationId
            )
          : timeline?.map(({ locationId }) => locationId);
      });
      const teacherPLsLocations = teacherPLsLocationsChunks.flat();
      const teacherTLsLocations = Object.values(teacherTLs)
        .map(({ locations }) => locations)
        .flat();
      const teacherMakeupLessonsLocations = Object.values(
        teacherMakeupLessons
      ).map(({ locationId }) => locationId);

      const teacherAppointmentsLocations = Object.values(
        teacherAppointments
      ).map(({ locationId }) => locationId);

      const uniqueLocationsIds = [
        ...new Set([
          ...teacherPLsLocations,
          ...teacherTLsLocations,
          ...teacherMakeupLessonsLocations,
          ...teacherAppointmentsLocations,
        ]),
      ];

      // Setting the state for teachers, instruments, programs and locations
      setInitialData((oldVal) => ({
        ...oldVal,
        instruments,
        programs,
        locations,
        studios,
        teacher: teacherWithInstruments,
        teacherPLs: privateLessonsWithAllData,
        teacherTLs: trialLessonsWithAllData,
        schoolYears,
        teacherMakeupLessons: makeupLessonsWithAllData,
        teacherAppointments: appointmentsWithAllData,
        absences: absencesWithDates,
        breaks: breaksWithAllData,
        studioUsages,
        groupClasses: groupClassesWithAllData,
      }));

      // setting the state for the locations
      setAllEventsLocationsIds(uniqueLocationsIds);
      setSelectedLocationId(uniqueLocationsIds[0]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingInitialData(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchInitialPageLoadData();
    };
    fetchInitialData();
  }, []);

  // sets the initial selectedLocationId
  useEffect(() => {
    if (allEventsLocationsIds.length && !selectedLocationId) {
      setSelectedLocationId(allEventsLocationsIds[0]);
    }
  }, [allEventsLocationsIds]);

  // // current school year based on the selectedEvent locationId
  // const currentSchoolYearInLocation = useMemo(
  //   () => getCurrentSchoolYear(initialData.schoolYears, selectedLocationId),
  //   [initialData, selectedLocationId]
  // );

  // // next school year based on the selectedEvent locationId
  // const nextSchoolYearInLocation = useMemo(
  //   () =>
  //     getNextSchoolYear(
  //       initialData.schoolYears,
  //       selectedLocationId,
  //       currentSchoolYearInLocation
  //     ),
  //   [initialData, selectedLocationId, currentSchoolYearInLocation]
  // );

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears.length && !selectedLocationId) return [];

    const combinedSchoolBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      selectedLocationId,
      true
    );
    return combinedSchoolBreaks;
  }, [initialData, selectedLocationId]);

  const filteredInitialData = useMemo(
    () => filterInitialDataByLocation(initialData, selectedLocationId),
    [initialData, selectedLocationId]
  );

  return {
    initialData: filteredInitialData,
    loadingInitialData,
    selectedEvent,
    setSelectedEvent,
    allEventsLocationsIds,
    selectedLocationId,
    handleSelectedLocationIdChange,
    // currentSchoolYearInLocation,
    // nextSchoolYearInLocation,
    combinedSchoolBreaks,
  };
};

export default useScheduleData;
