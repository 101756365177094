import { isGuestStudent, isPartnerApplicant } from "src/constants/UserRoleEnum";
import DefaultSummary from "./DefaultSummary";
import { ProfileSummaryCard, SummaryList } from "./styled";
import StudentSummary from "./StudentSummary";
import PartnerSummary from "./PartnerSummary";

const ProfileSummary = ({ user, teacher }) => {
  return (
    <ProfileSummaryCard title="Summary">
      <SummaryList>
        <DefaultSummary user={user} />
        {isGuestStudent(user?.role) && (
          <StudentSummary user={user} teacher={teacher} />
        )}
        {isPartnerApplicant(user?.role) && <PartnerSummary user={user} />}
      </SummaryList>
    </ProfileSummaryCard>
  );
};

export default ProfileSummary;
