import moment from "moment";
import React from "react";
import { SubmitSpinner } from "src/components/common";
import {
  absenceBehaviours,
  absenceMakeUpOptions,
  isPerDurationAbsence,
  isPerLessonAbsence,
} from "src/constants/absenceTypes";
import { getPrivateLessonInfoOnSpecificDate } from "src/utils/helpers";
import {
  CustomLabel,
  CustomTextarea,
  PrimaryButton,
} from "src/utils/shared/styled";
import styled from "styled-components";
import { InfoContainer, InfoText } from "../../styled";
import AdminActionsSection from "./AdminActionsSection";
import MakeUpOptionsSection from "./MakeUpOptionsSection";

const AbsenceRequestDetails = ({
  absencesData,
  absenceDetailsData,
  readOnly,
}) => {
  // absencesData comes from the absences page hook (when the page loads)
  // absenceDetailsData comes from when opening the absence details modal hook

  const {
    currentAbsence,
    initialData: { locations, instruments, combinedStudents },
  } = absencesData;
  const {
    initialData: {
      absencePrivateLesson,
      affectedPLs,
      affectedTLs,
      affectedMakeupLessons,
    },
    submitAbsenceRequest,
    loadingSubmitAbsence,
    comment,
    setComment,
  } = absenceDetailsData;

  const {
    request,
    absenceType,
    makeupOption,
    makeupOpening,
    status,
    teacherNote,
  } = currentAbsence || {};
  const {
    date,
    startDate,
    endDate,
    absenceBehaviour,
    affectedPrivateLessonsIds,
    affectedTrialLessonsIds,
    affectedMakeupLessonsIds,
    lessonId,
  } = request || {};
  const absenceDate =
    currentAbsence.date ||
    currentAbsence.startDate ||
    currentAbsence.request?.date ||
    currentAbsence.request?.startDate;

  // all of the affected lessons (Only used to check the length of this array , if there's length then we display the UI for the affected lsns)
  const combinedAffectedLessonsIds = [
    ...(lessonId ? [lessonId] : []),
    ...(affectedPrivateLessonsIds?.length ? affectedPrivateLessonsIds : []),
    ...(affectedTrialLessonsIds?.length ? affectedTrialLessonsIds : []),
    ...(affectedMakeupLessonsIds?.length ? affectedMakeupLessonsIds : []),
  ];

  const isPerDuration = isPerDurationAbsence(absenceBehaviour);
  const isPerLesson = isPerLessonAbsence(absenceBehaviour);

  const { startDate: absencePrivateLessonStart, duration } =
    absencePrivateLesson
      ? getPrivateLessonInfoOnSpecificDate({
          privateLesson: absencePrivateLesson,
          date: absenceDate,
          withTimelineApproximation: true,
        })
      : {};

  const absencePrivateLessonEnd = absencePrivateLessonStart
    ? moment(absencePrivateLessonStart).add(parseInt(duration), "minutes")
    : undefined;

  const absenceBehaviourMap = {
    [absenceBehaviours.PER_DURATION]: {
      behaviourStr: "Duration Absence",
      durationStr: `From: ${moment(startDate).format(
        "MM/DD, hh:mm A"
      )} To: ${moment(endDate).format("MM/DD, hh:mm A")}`,
    },
    [absenceBehaviours.PER_LESSON]: {
      behaviourStr: "Lesson Absence",
      durationStr: absencePrivateLessonStart
        ? `${moment(absencePrivateLessonStart).format("hh:mm A")} - ${moment(
            absencePrivateLessonEnd
          ).format("hh:mm A")}`
        : "N/A",
    },
  };

  const formattedAbsenceDate = moment(date || startDate).format("MM-DD-YYYY");

  const absenceBehaviourStr =
    absenceBehaviourMap[absenceBehaviour]?.behaviourStr || "N/A";

  const absenceDurationStr =
    absenceBehaviourMap[absenceBehaviour]?.durationStr || "N/A";

  // for per lesson absence
  // const { id, studentId, instrumentId, date } = absencePrivateLesson || {};
  const student = combinedStudents?.find(
    ({ id }) => id === absencePrivateLesson?.studentId
  );

  // either fullName or name based on whether the student is private or trial student
  const studentName = student?.fullName || student?.name;

  const instrument = instruments?.find(
    ({ id }) => id === absencePrivateLesson?.instrumentId
  );

  // for the per lesson abs
  const formattedStartTime = moment(absencePrivateLessonStart).format(
    "hh:mm A"
  );
  const formattedEndTime = moment(absencePrivateLessonEnd).format("hh:mm A");
  const absenceLessonTitle = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Private Lesson*`;

  return (
    <div>
      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Absence Date:
        </InfoText>
        <InfoText>{formattedAbsenceDate}</InfoText>
      </InfoContainer>
      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Absence Type:
        </InfoText>
        <InfoText>{absenceBehaviourStr}</InfoText>
      </InfoContainer>
      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Absence Duration:
        </InfoText>
        <InfoText>{absenceDurationStr}</InfoText>
      </InfoContainer>
      <InfoContainer className="mb-3 ">
        <InfoText bold className="me-2 mb-2">
          Affected Lessons:
        </InfoText>
        {combinedAffectedLessonsIds.length ? (
          <ul>
            {/* If per lesson absence */}
            {lessonId && <li className="mb-1">{absenceLessonTitle}</li>}

            {/* Duration absences */}
            {/* affected private lessons  */}
            {affectedPLs?.map((lesson) => {
              const { studentId, instrumentId } = lesson;
              const { startDate: lessonStart, duration } =
                getPrivateLessonInfoOnSpecificDate({
                  privateLesson: lesson,
                  date: absenceDate,
                  withTimelineApproximation: true,
                });

              const lessonEnd = lessonStart
                ? moment(lessonStart).add(parseInt(duration), "minutes")
                : undefined;

              const student = combinedStudents?.find(
                ({ id }) => id === studentId
              );
              const studentName = student?.fullName || student?.name;

              const instrument = instruments?.find(
                ({ id }) => id === instrumentId
              );
              const formattedStartTime = moment(lessonStart).format("hh:mm A");
              const formattedEndTime = moment(lessonEnd).format("hh:mm A");
              const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Private Lesson*`;
              return <li className="mb-1">{title}</li>;
            })}

            {/* Affected Trial Lessons */}
            {affectedTLs?.map((lesson) => {
              const {
                id,
                studentId,
                instrument: instrumentId,
                date,
                lessonLength,
              } = lesson;

              const student = combinedStudents?.find(
                ({ id }) => id === studentId
              );
              const studentName = student?.fullName || student?.name;
              const instrument = instruments?.find(
                ({ id }) => id === instrumentId
              );
              const formattedStartTime = moment(date).format("hh:mm A");
              const formattedEndTime = moment(date)
                .add(parseInt(lessonLength), "minutes")
                .format("hh:mm A");

              const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Trial Lesson*`;
              return <li className="mb-1">{title}</li>;
            })}

            {/* Affected Make Up Lessons */}
            {affectedMakeupLessons?.map((lesson) => {
              const { id, studentId, instrumentId, date } = lesson;
              const { startDate, lessonLength } = date || {};

              const student = combinedStudents?.find(
                ({ id }) => id === studentId
              );
              const studentName = student?.fullName || student?.name;
              const instrument = instruments?.find(
                ({ id }) => id === instrumentId
              );
              const formattedStartTime = moment(startDate).format("hh:mm A");
              const formattedEndTime = moment(startDate)
                .add(parseInt(lessonLength), "minutes")
                .format("hh:mm A");

              const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Make Up Lesson*`;
              return <li className="mb-1">{title}</li>;
            })}
          </ul>
        ) : (
          <InfoText>No Affected Lessons</InfoText>
        )}
      </InfoContainer>

      <InfoContainer className="d-flex mb-3 ">
        <InfoText bold className="me-2">
          Teacher Note:
        </InfoText>
        <InfoText>{teacherNote || "N/A"}</InfoText>
      </InfoContainer>

      {/* Make Up Options section */}
      <div>
        <MakeUpOptionsSection
          makeupOption={makeupOption}
          makeupOpening={makeupOpening}
          locations={locations}
        />
      </div>

      {!readOnly && (
        <>
          <div>
            <CustomLabel>Comment For Admins:</CustomLabel>
            <CustomTextarea
              width="100%"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          {/* Admin Actions section */}
          <div className="mt-5">
            <AdminActionsSection
              absencesData={absencesData}
              absenceDetailsData={absenceDetailsData}
            />
          </div>
          <div className="text-center mt-5">
            <PrimaryButton onClick={submitAbsenceRequest}>
              {loadingSubmitAbsence ? (
                <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
              ) : (
                "Confirm"
              )}
            </PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
};

export default AbsenceRequestDetails;
