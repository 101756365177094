import React from "react";
import { useDrag } from "react-dnd";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import { haveSuperAdminPermission, injectUserStore } from "src/utils/helpers";
import styled from "styled-components";
import { toJS } from "mobx";
import { isSuperAdmin } from "src/constants/UserRoleEnum";

const Dragable = styled.div`
  background-color: #f6f2f4;
  border-radius: 30px;
  padding: 15px 20px 15px 20px;
  border: 0px;
  box-shadow: 0px 2px 10px #0000000a;
  text-align: center;
  white-space: nowrap;
`;

const DragableTeacher = ({ name, id, UserStore }) => {
  const [dragProps, drag] = useDrag(() => ({
    type: "teacher",
    item: {
      teacher_id: id,
      teacher_name: name,
    },
  }));

  const formatName = (name) => {
    let nameArray = name.split(" ");
    let firstName = nameArray[0];
    let lastNameInitial = nameArray[1] ? nameArray[1].charAt(0) + "." : "";
    return firstName + " " + lastNameInitial;
  };

  const user = toJS(UserStore)?.user;
  const hasPermisionToDrag = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.manageTeachersStudios
  );
  const isSuperAdminUser = isSuperAdmin(UserStore.user.role);
  return (
    <div ref={hasPermisionToDrag || isSuperAdminUser ? drag : null} key={id}>
      <Dragable>{formatName(name)}</Dragable>
    </div>
  );
};

export default injectUserStore(DragableTeacher);
