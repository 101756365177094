import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import PauseTeacherDayBody from "./PauseTeacherDayBody";

const PauseTeacherDayModal = ({
  modalData,
  onSuccess = () => {},
  scheduleDayId,
  teacherId,
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } = modalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding={"0px"}
    >
      <CustomModalHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        padding="20px"
        className="border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Pause Schedule</h2>
      </CustomModalHeader>
      <CustomModalBody padding={"0px"}>
        <PauseTeacherDayBody
          scheduleDayId={scheduleDayId}
          teacherId={teacherId}
          modalData={modalData}
          onSuccess={onSuccess}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default PauseTeacherDayModal;
