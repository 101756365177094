import { Check2Square } from "styled-icons/bootstrap";
import { Edit } from "styled-icons/entypo";
import styled from "styled-components";

export const TableRow = styled.tr`
  height: 70px;
  & td {
    color: #151515;
    font-weight: 500;
    opacity: 0.9;
  }
  & td,
  & th {
    height: 100%;
    border: 1px solid #ddd;
    padding: 7px;
    text-align: center;
  }
  & td:first-child:hover {
    color: #ff008a;
    cursor: pointer;
  }
`;
// export const HeadContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
// `;
export const EditIcon = styled(Edit)`
  color: #151515;
  width: 17px;
`;
export const CheckIcon = styled(Check2Square)`
  color: #151515;
  width: 17px;
`;
export const ActionBtnsContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const IconWrapper = styled.div`
  padding: 5px;
  cursor: pointer;

  &:hover > * {
    color: #ff008a;
  }
`;
