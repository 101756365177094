import React from "react";
import { NavButton } from "src/utils/shared/styled";
import { groupClassesListViews } from "../../constants";

const Navbar = ({ groupClassView, setGroupClassView }) => {
  return (
    <div className="d-flex align-items-center">
      {Object.values(groupClassesListViews).map((view, index) => {
        const { value, title } = view;
        return (
          <div key={index}>
            <NavButton
              className={groupClassView === value ? "active" : undefined}
              onClick={() => setGroupClassView(value)}
              withRedBar
            >
              {title}
            </NavButton>
          </div>
        );
      })}
    </div>
  );
};

export default Navbar;
