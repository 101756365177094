import React from "react";
import MainNav from "./MainNav";
import SecondaryNav from "./SecondaryNav";

const NavSection = () => {
  return (
    <div>
      <div>
        <MainNav />
      </div>
      <hr />
      <div>
        <SecondaryNav />
      </div>
    </div>
  );
};

export default NavSection;
