import React from "react";
import {
  ConcertListItemContainer,
  ConcertListItemImage,
  ConcertListItemInfoContainer,
  ConcertListItemItemHeader,
  ConcertListItemSpan,
} from "../styled";
import moment from "moment";

const ConcertItem = ({
  setCurrentConcertId,
  concert,
  index,
  setCurrentConcertColor,
}) => {
  const { attachments, title, date, id } = concert;
  const { url } = attachments?.find(({ type }) => type.includes("image")) || {};

  const formattedDate = moment(date).format("MM/DD/YYYY");

  const bgColor = index % 2 === 0 ? "#ebeeff" : "#fcffe2";
  return (
    <ConcertListItemContainer
      bgColor={bgColor}
      onClick={() => {
        setCurrentConcertId(id);
        setCurrentConcertColor(bgColor);
      }}
      index={index}
    >
      <div>{url && <ConcertListItemImage src={url} alt="concert-image" />}</div>
      <ConcertListItemInfoContainer>
        <ConcertListItemItemHeader>
          {title || "Upcoming Concert"}
        </ConcertListItemItemHeader>
        <ConcertListItemSpan>{formattedDate}</ConcertListItemSpan>
      </ConcertListItemInfoContainer>
    </ConcertListItemContainer>
  );
};

export default ConcertItem;
