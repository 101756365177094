import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import DisableUserBody from "./DisableUserBody";

const DisableUserModal = ({ modalData, userId, onSuccess = () => {} }) => {
  const { isModalOpen, toggleModal, closeModal, openModal } = modalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding={"0px"}
    >
      <CustomModalHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        padding="20px"
        className="border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Delete User</h2>
      </CustomModalHeader>
      <CustomModalBody padding={"0px"}>
        <DisableUserBody
          modalData={modalData}
          userId={userId}
          onSuccess={onSuccess}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default DisableUserModal;
