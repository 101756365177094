import { Modal, ModalHeader } from "reactstrap";
import styled from "styled-components";

const StyledFeedbackModal = styled(Modal)`
  .modal-content {
    border-radius: 1.3rem;
    background-color: #f3f3f3;
  }

  .modal-body {
    padding-top: 32px !important;

    @media screen and (max-width: 420px) {
      padding: 16px !important;
    }
  }

  top: 15%;

  @media screen and (min-width: 576px) {
    top: 10%;
    left: 10%;
  }

  @media screen and (min-width: 768px) {
    left: 20%;
  }

  @media screen and (min-width: 992px) {
    top: 5%;
    left: 25%;
  }

  @media screen and (min-width: 1300px) {
    left: 30%;
  }

  @media screen and (min-width: 1466px) {
    left: 33%;
  }
`;

const FeedbackModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

export { StyledFeedbackModal, FeedbackModalHeader };
