import React from "react";
import {
  groupClassPaymentOptionsMap,
  groupClassPaymentStudentTypes,
  groupClassScheduledPaymentIntervals,
  paymentOptionMethods,
  scheduledPaymentTimesToRun,
} from "src/constants/groupClassPaymentsConstants";
import { SectionContainer } from "../../styled";
import { getGroupClassPaymentOptionsTotal } from "src/utils/helpers";

const planNamesMap = [
  {
    name: "New Students Plan",
    value: groupClassPaymentStudentTypes.NEW_STUDENT,
  },
  {
    name: "Current Students Plan",
    value: groupClassPaymentStudentTypes.CURRENT_STUDENT,
  },
];
const intervalsMap = [
  {
    value: groupClassScheduledPaymentIntervals.WEEK,
    name: "Week",
  },
  {
    value: groupClassScheduledPaymentIntervals.MONTH,
    name: "Month",
  },
  {
    value: groupClassScheduledPaymentIntervals.YEAR,
    name: "Year",
  },
];
const ScheduledPayment = ({ scheduledPaymentOptions }) => {
  return (
    <SectionContainer className="mt-3">
      <h6>Installments</h6>
      <hr />

      {scheduledPaymentOptions.map((option) => {
        const {
          paymentType,
          optionPayments,
          forStudentType,
          paypalPlanId,
          interval,
          intervalCount,
          timesToRun,
        } = option;

        const setupOptions = optionPayments?.filter(({ type }) => {
          const map = Object.values(groupClassPaymentOptionsMap).find(
            (option) => type === option.value
          );
          const isSetup = map.method === paymentOptionMethods.SETUP;
          return isSetup;
        });
        const setupTotal = getGroupClassPaymentOptionsTotal(setupOptions);

        const scheduledOptions = optionPayments?.filter(({ type }) => {
          const map = Object.values(groupClassPaymentOptionsMap).find(
            (option) => type === option.value
          );
          const isScheduled = map.method === paymentOptionMethods.SCHEDULED;
          return isScheduled;
        });
        const scheduledTotal =
          getGroupClassPaymentOptionsTotal(scheduledOptions);

        const header =
          forStudentType === groupClassPaymentStudentTypes.CURRENT_STUDENT
            ? "Current Students"
            : groupClassPaymentStudentTypes.NEW_STUDENT
            ? "New Students"
            : "";

        const planName = planNamesMap.find(
          ({ value }) => value === forStudentType
        )?.name;

        const intervalName = intervalsMap.find(
          ({ value }) => value === interval
        )?.name;

        const runType =
          timesToRun === scheduledPaymentTimesToRun.CONTINOUS
            ? "Continous"
            : `${timesToRun.amount} Times`;
        return (
          <SectionContainer key={option.id}>
            <h6>{header}</h6>
            <hr />
            <p>
              Plan Type: <span className="ms-2">{planName}</span>
            </p>
            <p>
              Interval: <span className="ms-2">{intervalName}</span>
            </p>
            <p>
              Interval Count: <span className="ms-2">{intervalCount}</span>
            </p>
            <p>
              Run Type: <span className="ms-2">{runType}</span>
            </p>
            <p>
              Setup Amount: <span className="ms-2">${setupTotal}</span>
            </p>
            <p>
              Installment Amount:
              <span className="ms-2">${scheduledTotal}</span>
            </p>
          </SectionContainer>
        );
      })}
    </SectionContainer>
  );
};

export default ScheduledPayment;
