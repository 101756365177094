export const getNewMakeUpOpenings = (editedMakeUpOpenings) => {
  const newMakeUpOpenings = editedMakeUpOpenings.filter(
    (opening) => opening.isNew
  );
  if (newMakeUpOpenings.length) {
    return { areNewMakeUpOpeningsDetected: true, newMakeUpOpenings };
  } else {
    return { areNewMakeUpOpeningsDetected: false, newMakeUpOpenings: [] };
  }
};

export const getEditedMakeUpOpenings = (editedOpenings) => {
  const editedMakeUpOpenings = editedOpenings.filter(
    (opening) => opening.isEdited
  );
  if (editedMakeUpOpenings.length) {
    return { areEditedMakeUpOpeningsDetected: true, editedMakeUpOpenings };
  } else {
    return { areEditedMakeUpOpeningsDetected: false, editedMakeUpOpenings: [] };
  }
};
export const getDeletedMakeUpOpenings = (initialOpenings, editedOpenings) => {
  const editedOpeningsIds = editedOpenings
    .map((opening) => opening.id)
    .filter((id) => id);
  const deletedMakeUpOpenings = initialOpenings.filter(
    (opening) => !editedOpeningsIds.includes(opening.id)
  );
  if (deletedMakeUpOpenings.length) {
    return { areDeletedMakeUpOpeningsDetected: true, deletedMakeUpOpenings };
  } else {
    return {
      areDeletedMakeUpOpeningsDetected: false,
      deletedMakeUpOpenings: [],
    };
  }
};
