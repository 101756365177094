import styled from "styled-components";

export const MainHeader = styled.h4`
  padding: 20px;
  font-size: 1rem;
  border-radius: 20px;
  background-color: #fff;
`;
export const InfoText1 = styled.p`
  margin: 0;
  color: ${({ color }) => color || "#000"};
  font-weight: ${({ bold }) => (bold ? "bold" : "unset")};
`;
export const ContentContainer = styled.div`
  padding: 30px;
`;
export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const RatesList = styled.div`
  p {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;

    color: #151515;
    font-size: 14px;

    &:not(:first-child) {
      margin-top: 12px;
    }

    span {
      font-weight: 700;
    }

    @media screen and (min-width: 576px) {
      font-size: 16px;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 15px;

  & svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;
