import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { defaultPackageLessonsColor } from "src/components/Dashboard/Schedule/constants";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const formDataInitialValues = {
  title: "",
  color: "",
};
const usePackageLessonEditData = ({
  packageLessonEvent,
  refreshData,
  closeDetailsModal,
}) => {
  const { updatePackageSetsAndActivity, getPrivateLessonsByIds } =
    useFirebaseFns();
  const [formData, setFormData] = useState(formDataInitialValues);
  const [savingChanges, setSavingChanges] = useState(false);

  function handleFormDataChange(name, value) {
    setFormData((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  }

  async function saveChanges() {
    try {
      if (!packageLessonEvent) {
        toast.error("Couldnt save package lesson changes");
        return;
      }
      setSavingChanges(true);

      const fetchedPackageLesson = (
        await getPrivateLessonsByIds([packageLessonEvent.mainLessonId])
      )?.[0];

      if (!fetchedPackageLesson) {
        toast.error("Couldnt save package lesson changes");
        return;
      }

      const currentPackageItemSet = fetchedPackageLesson.packageSets?.find(
        ({ setItems }) =>
          !!setItems.find(({ id }) => id === packageLessonEvent.id)
      );

      const currentPackageItem = currentPackageItemSet?.setItems?.find(
        ({ id }) => id === packageLessonEvent.id
      );
      const updatedPackageItem = {
        ...currentPackageItem,
        calendarTitle: formData.title,
        eventColor: formData.color,
      };

      const updatedPackageSets = fetchedPackageLesson.packageSets.map(
        (setObj) => {
          if (setObj.setNumber === currentPackageItemSet?.setNumber) {
            const updatedSetItems = setObj.setItems.map((setItem) =>
              setItem.id === updatedPackageItem.id
                ? updatedPackageItem
                : setItem
            );
            return {
              ...setObj,
              setItems: updatedSetItems,
            };
          } else {
            return setObj;
          }
        }
      );

      await updatePackageSetsAndActivity(
        updatedPackageSets,
        fetchedPackageLesson.id
      );
      closeDetailsModal();
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setSavingChanges(false);
    }
  }

  async function resetChanges() {
    try {
      if (!packageLessonEvent) {
        toast.error("Couldnt reset package lesson changes");
        return;
      }
      setSavingChanges(true);

      const fetchedPackageLesson = (
        await getPrivateLessonsByIds([packageLessonEvent.mainLessonId])
      )?.[0];

      if (!fetchedPackageLesson) {
        toast.error("Couldnt save package lesson changes");
        return;
      }

      const { calendarTitle, eventColor, ...clearedPackageItem } =
        currentPackageItem;

      const currentPackageItemSet = fetchedPackageLesson.packageSets?.find(
        ({ setItems }) =>
          !!setItems.find(({ id }) => id === packageLessonEvent.id)
      );

      const currentPackageItem = currentPackageItemSet?.setItems?.find(
        ({ id }) => id === packageLessonEvent.id
      );

      const updatedPackageSets = fetchedPackageLesson.packageSets.map(
        (setObj) => {
          if (setObj.setNumber === currentPackageItemSet?.setNumber) {
            const updatedSetItems = setObj.setItems.map((setItem) =>
              setItem.id === clearedPackageItem.id
                ? clearedPackageItem
                : setItem
            );
            return {
              ...setObj,
              setItems: updatedSetItems,
            };
          } else {
            return setObj;
          }
        }
      );

      await updatePackageSetsAndActivity(
        updatedPackageSets,
        fetchedPackageLesson.id
      );
      closeDetailsModal();
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setSavingChanges(false);
    }
  }

  useEffect(() => {
    setFormData((oldVal) => ({
      ...oldVal,
      ...(packageLessonEvent.calendarTitle && {
        title: packageLessonEvent.calendarTitle,
      }),

      color: packageLessonEvent.eventColor || defaultPackageLessonsColor,
    }));
  }, []);

  return {
    formData,
    handleFormDataChange,
    saveChanges,
    savingChanges,
    resetChanges,
  };
};

export default usePackageLessonEditData;
