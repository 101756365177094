import { addDoc, getDoc, getDocs, Timestamp } from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useRef, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Input, Spinner } from "reactstrap";
import { CheckInModalContext } from "../../common/contexts/AdminAttendanceContext";
import { FirebaseContext } from "../../Firebase";
import CheckInModal from "./CheckInModal";
import logo from "../../../assets/images/logo.png";
import { SubmitSpinner } from "src/components/common";
import SearchSelect from "src/components/common/Library/common/SearchSelect";
import { updatedMomentNow } from "src/utils/helpers";
const checkInStatuses = {
  NONE: "NONE",
  CHECKING_IN: "CHECKING_IN",
  DONE: "DONE",
};
const CheckStudentIn = () => {
  const [inputValue, setInputValue] = useState("");
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState({
    label: "",
    value: "",
  });
  const [checkInStatus, setCheckInStatus] = useState(checkInStatuses.NONE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentDetails, setStudentDetails] = useState({});
  const [attendance, setAttendance] = useState({});
  const [duplicateDate, setDuplicateDate] = useState("");
  const [scanError, setScanError] = useState(false);
  let inputRef = useRef(null);
  const firebase = useContext(FirebaseContext);

  const locationsOptions = useMemo(() => {
    return locations.map(({ id, name }) => ({ label: name, value: id }));
  }, [locations]);
  const getLessonAttendance = async () => {
    const [systemId, lessonId] = inputValue.split("&");
    if (systemId && lessonId) {
      const studentDoc = await getDocs(firebase.getStudentBySystemId(systemId));
      const lessonDoc = await getDoc(firebase.PrivateLesson(lessonId));
      let currentStudent;
      studentDoc.forEach((student) => {
        setStudentDetails({
          id: student.id,
          ...student.data(),
        });
        currentStudent = student.id;
      });
      if (currentStudent && lessonDoc.data()) {
        const docs = await getDocs(
          firebase.getLessonAttendanceList(lessonId, currentStudent)
        );
        const attendanceObject = {
          extra: [],
        };
        docs.forEach((doc) => {
          const documentObject = {
            id: doc.id,
            ...doc.data(),
          };
          if (
            duplicateDate === "" &&
            moment(documentObject.date.toDate()).format("MM-DD-YYYY") ===
              updatedMomentNow().format("MM-DD-YYYY")
          ) {
            setDuplicateDate(
              moment(documentObject.date.toDate()).format("hh:mmA")
            );
          }
          if (documentObject?.title) {
            attendanceObject.extra.push(documentObject);
          } else {
            attendanceObject[documentObject.weekNumber] = documentObject;
          }
        });
        setAttendance(attendanceObject);
        setIsModalOpen(true);
      } else {
        setCheckInStatus(checkInStatuses.NONE);
        setScanError(true);
      }
    } else {
      setCheckInStatus(checkInStatuses.NONE);
      setScanError(true);
    }
  };
  const getLocations = async () => {
    const locationsDocs = await getDocs(firebase.locations());
    const locationsArray = [];
    locationsDocs.forEach((location) =>
      locationsArray.push({ ...location.data(), id: location.id })
    );
    setLocations(locationsArray);
  };
  useEffect(() => {
    getLocations().then(() => {
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    (async () => {
      setScanError(false);
      setDuplicateDate("");
      if (inputValue === "") {
        setCheckInStatus(checkInStatuses.NONE);
      } else {
        setCheckInStatus(checkInStatuses.CHECKING_IN);
        await getLessonAttendance();
      }
    })();
  }, [inputValue]);
  const checkInModalContextValue = {
    isOpen: isModalOpen,
    close: () => {
      setIsModalOpen(false);
      setCheckInStatus(checkInStatuses.NONE);
      setInputValue("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    submit: async (date) => {
      toast.info("Checking in...");
      setInputValue("");
      if (inputRef.current) {
        inputRef.current.focus();
      }
      setIsModalOpen(false);
      const [_, lessonId] = inputValue.split("&");
      await addDoc(firebase.getLessonAttendanceCollection(), {
        studentId: studentDetails.id,
        lessonId,
        weekNumber: Object.keys(attendance).length,
        date: Timestamp.fromDate(date.toDate()),
        locationId: selectedLocation.value,
      });

      setCheckInStatus(checkInStatuses.DONE);
      toast.success("Checked In successfully");
    },
  };
  if (isLoading) {
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <SubmitSpinner />
      </div>
    );
  }
  return (
    <div className="col-8 pb-5 pt-4">
      <div className="d-flex align-items-center justify-content-center pb-4 mb-4">
        <img src={logo} alt="Logo" className={"logoImage"} />
      </div>
      <div className="mb-4 d-flex align-items-center">
        <span className="me-2">Check-In Location</span>
        <SearchSelect
          name="locations"
          placeholder={"Location"}
          options={locationsOptions}
          onOptionSelect={(e) => {
            setSelectedLocation(e);
          }}
          value={selectedLocation}
          isMulti={false}
        />
      </div>
      {selectedLocation.value === "" ? (
        <div className="alert alert-warning">
          You must select a location first
        </div>
      ) : null}
      <Input
        autoFocus={true}
        innerRef={inputRef}
        className="mb-2"
        value={inputValue}
        disabled={
          checkInStatus === checkInStatuses.CHECKING_IN ||
          selectedLocation.value === ""
        }
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <p className="lead mb-2">
        Focus in the input field above and scan the QR code{" "}
      </p>
      <div className="d-flex mb-2 align-items-center justify-content-center p-5">
        {checkInStatus === checkInStatuses.CHECKING_IN ? (
          <Spinner />
        ) : checkInStatus === checkInStatuses.DONE ? (
          <p className="lead">
            Checked in <i className="fa fa-check text-purple" />
          </p>
        ) : null}
      </div>
      {scanError ? (
        <div className="alert alert-danger lead">
          Error scanning the QR code
        </div>
      ) : null}
      <CheckInModalContext.Provider value={checkInModalContextValue}>
        {isModalOpen ? (
          <CheckInModal
            studentName={studentDetails.fullName}
            duplicateDate={duplicateDate}
          />
        ) : null}
      </CheckInModalContext.Provider>
    </div>
  );
};
export default CheckStudentIn;
