import { getDoc } from "firebase/firestore";
import store from "../../../stores/UserStore";
const updateStore = async (firebase) => {
  const doc = await getDoc(firebase.user(store.user.uid));
  const authUser = await firebase.auth.currentUser;
  if (doc.exists()) {
    const dbUser = doc.data();
    const user = {
      uid: authUser.uid,
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      providerData: authUser.providerData,
      ...dbUser,
    };
    store.setUser(user);
  }
};

export default updateStore;
