import React from "react";
import { SubmitSpinner } from "src/components/common";
import {
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";

const TableSection = ({
  filteredAbsencesWithAdditionalData,
  loadingFilteredStudentsData,
}) => {
  // Loading display
  if (loadingFilteredStudentsData) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <div>
      <CustomTableContainer>
        <CustomTable className="w-100 text-dark">
          <thead>
            <TableRow>
              <th>
                <div>Student Name</div>
              </th>
              <th>
                <div>Absence Date</div>
              </th>
              <th>
                <div>Absence Type</div>
              </th>
              <th>
                <div>Status</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {filteredAbsencesWithAdditionalData.map((absence, i) => {
              const {
                makeupStatus,
                makeupStatusStr,
                requestedByName,
                studentName,
                formattedAbsenceDate,
                absenceTypeStr,
                studentId,
              } = absence;

              return (
                <TableRow key={i}>
                  <td>{studentName || "N/A"}</td>
                  <td>{formattedAbsenceDate}</td>
                  <td>{absenceTypeStr}</td>
                  <td>{makeupStatusStr}</td>
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>
    </div>
  );
};

export default TableSection;
