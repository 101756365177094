import { concertProgramSoloPerformanceTypes } from "src/constants/concertEnum";

export function validateProgramData(programData, concertMinsPerProgram = 0) {
  const { performanceType, repertoires, equipmentIds, additionalEquipment } =
    programData;

  if (
    performanceType === concertProgramSoloPerformanceTypes.OTHER &&
    !performanceType.value
  ) {
    return {
      isValid: false,
      errorMsg: "Please provide a description for the performance type",
    };
  }

  if (!repertoires.length) {
    return {
      isValid: false,
      errorMsg: "Please add at least one repertoire",
    };
  }

  let totalRepsDuration = 0;
  for (const rep of repertoires) {
    if (!rep.description || !rep.duration) {
      return {
        isValid: false,
        errorMsg: "Please enter duration + description for each repertoire",
      };
    }
    const repDuration = parseInt(rep.duration);
    totalRepsDuration += repDuration;
  }

  if (totalRepsDuration > concertMinsPerProgram) {
    return {
      isValid: false,
      errorMsg: `Total repertoires duration must not exceed the maximum minutes per program: ${concertMinsPerProgram} minutes`,
    };
  }

  return { isValid: true };
}
