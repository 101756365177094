import { useContext, useMemo } from "react";
import { Button } from "reactstrap";
import {
  AdminFilterContext,
  StudentsContext,
  TeachersContext,
} from "../../../common/Library/common/libraryContext";
import SearchSelect from "../../../common/Library/common/SearchSelect";
import { isActiveTeacher } from "src/utils/helpers";

const AdminFilter = () => {
  const { teachers } = useContext(TeachersContext);
  const { students } = useContext(StudentsContext);
  const {
    setSelectedLocation,
    setSelectedStudent,
    setSelectedTeacher,
    selectedLocation,
    selectedTeacher,
    selectedStudent,
    onFilter,
    locations,
  } = useContext(AdminFilterContext);

  const locationsOptions = useMemo(() => {
    return locations.map(({ id, name }) => ({ label: name, value: id }));
  }, [locations]);
  const teachersOptions = useMemo(() => {
    return teachers
      .filter((teacher) => isActiveTeacher(teacher))
      .map(({ id, fullName }) => ({ label: fullName, value: id }));
  }, [selectedLocation]);
  const studentsOptions = useMemo(() => {
    if (selectedTeacher.value === "") {
      return [];
    }
    const currentTeacher = teachers.find(
      ({ id }) => selectedTeacher.value === id
    );
    if (currentTeacher) {
      return students
        .filter(({ id }) => currentTeacher.studentsIds.includes(id))
        .map(({ id, fullName }) => ({ label: fullName, value: id }));
    }
    return [];
  }, [selectedTeacher]);
  return (
    <div>
      <h4>Location</h4>
      <SearchSelect
        name="locations"
        placeholder={"Location"}
        options={locationsOptions}
        onOptionSelect={(e) => {
          setSelectedLocation(e);
          setSelectedTeacher({ label: "", value: "" });
          setSelectedStudent({ label: "", value: "" });
        }}
        value={selectedLocation}
        isMulti={false}
      />
      <h4 className="pt-3">Teacher</h4>
      <SearchSelect
        options={teachersOptions}
        name={"user-type-teacher"}
        onOptionSelect={(e) => {
          setSelectedTeacher(e);
          setSelectedStudent({ label: "", value: "" });
        }}
        value={selectedTeacher}
        placeholder={"Teacher"}
      />
      <h4 className="pt-3">Student</h4>
      <SearchSelect
        options={studentsOptions}
        name={"user-type-student"}
        onOptionSelect={(e) => {
          setSelectedStudent(e);
        }}
        value={selectedStudent}
        placeholder={"Student"}
      />
      <div className="d-flex justify-content-center pt-3">
        <Button
          className={"rounded-pill ms-2 flex-grow-1 bg-purple"}
          color={"secondary"}
          disabled={
            // selectedStudent.value === "" ||
            selectedTeacher.value === "" || selectedLocation.value === ""
          }
          onClick={onFilter}
        >
          Filter
        </Button>
      </div>
    </div>
  );
};
export default AdminFilter;
