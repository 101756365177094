import styled from "styled-components";

export const Container = styled.div`
  background: #f6f6f69e;
  display: flex;
  /* margin-left: -30px; */
  width: 100%;
`;
export const InfoContainer = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 15px;
`;
export const InfoText = styled.p`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : "auto")};
`;
