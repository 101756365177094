import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const initialDataInitialValues = {
  student: null,
};
const useGroupClassStudent = ({ studentId, user }) => {
  const { getUserById } = useFirebaseFns();
  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  useEffect(() => {
    if (!studentId) return;

    async function fetchData() {
      try {
        setLoadingInitialData(true);

        const [student] = await Promise.all([getUserById(studentId)]);

        setInitialData({
          student,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    }
    fetchData();
  }, [studentId]);

  return { initialData, loadingInitialData };
};

export default useGroupClassStudent;
