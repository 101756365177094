import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { compose } from "recompose";
import { UserRole } from "src/constants/UserRoleEnum";
import {
  UserImage,
  UserImageContainer,
  UserInfoContainer,
  UserPrimaryText,
  UserSecondaryText,
} from "../styled";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import ParentUserStudentSelect from "./ParentUserStudentSelect";

const UserInfoBar = ({ UserStore }) => {
  const user = toJS(UserStore.user);

  const userImage = user?.imageUrl;
  const userName = user?.fullName;
  const userRole = user?.role;

  return (
    <UserInfoContainer>
      <UserImageContainer>
        <UserImage src={userImage || UnknownProfilePhoto} alt="user" />
      </UserImageContainer>
      <div>
        <UserPrimaryText>{userName}</UserPrimaryText>
        <UserSecondaryText>{userRole}</UserSecondaryText>
      </div>
      {userRole === UserRole.PARENT ? (
        <div className="ms-3">
          <ParentUserStudentSelect />
        </div>
      ) : null}
    </UserInfoContainer>
  );
};

export default compose(inject("UserStore"), observer)(UserInfoBar);
