import React from "react";
import { InputContainer } from "../../styled";
import { CustomLabel } from "src/utils/shared/styled";
import CustomReactSelect from "src/components/common/CustomReactSelect";

const LabeledSelectField = ({ withLabel = true, label, ...restProps }) => {
  return (
    <InputContainer>
      {withLabel && <CustomLabel>{label}:</CustomLabel>}
      <CustomReactSelect
        disabledBackgroundColor="#e2e2e2"
        // isDisabled={!formInputs.locationId}

        width="100%"
        wrapOptions={false}
        bgColor="#fff"
        margin="auto"
        {...restProps}
      />
    </InputContainer>
  );
};

export default LabeledSelectField;
