import "./style.css";
import React from "react";

function BookClassToday() {
  return (
    <figure className="book-class">
      <h2>Book A Class Today</h2>
      <a href="./contactus">Register</a>
    </figure>
  );
}

export default BookClassToday;
