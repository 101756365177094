import React, { useContext } from "react";
import { emailValidationSchema } from "../../../utils/validations";
import { FirebaseContext } from "src/components/Firebase";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import InputWithLabel from "../shared/InputWithLabel";
import { SubmitSpinner } from "../../common";
import InputWrapper from "../shared/InputWrapper";
import { getDocs, query, where } from "firebase/firestore";
import * as yup from "yup";
import { useNavigate } from "react-router";
import { signInViews, USER_NOT_FOUND, WRONG_PASSWORD } from "../constants";
import { AuthButtonsWrapper, AuthSubmitButton, SideButton } from "../styled";

const ForgotPasswordValidationSchema = yup.object().shape({
  email: emailValidationSchema,
});
const ForgotPassword = ({ handleViewChange }) => {
  const Navigate = useNavigate();
  const firebase = useContext(FirebaseContext);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ForgotPasswordValidationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          const { email } = values;
          const lowerCaseEmail = email.toLowerCase();

          setSubmitting(true);
          const usersQuery = query(
            firebase.users(),
            where("primaryEmail", "==", lowerCaseEmail)
          );
          const usersSnapshot = await getDocs(usersQuery);
          // Checks if we have the user in firestore before logging in
          if (usersSnapshot.docs?.length) {
            await firebase
              .passwordReset(lowerCaseEmail)
              .then(() => {
                toast.success(
                  "Sent password reset email, you may check the spam folder"
                );
                handleViewChange(signInViews.SIGN_IN);
              })
              .catch((error) => {
                if (error.code === USER_NOT_FOUND) {
                  setErrors({ email: "User not found" });
                } else {
                  console.log(error);
                  toast.error(error.message);
                }
              });
          } else {
            setErrors({ email: "User not found" });
          }
        } catch (err) {
          console.log(err);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form className="mt-5">
          <InputWrapper>
            <InputWithLabel
              name="email"
              error={errors.email}
              touched={touched.email}
              label="Email Address"
              placeholder="Email Address"
              type="email"
            />
          </InputWrapper>

          <div className="w-75 m-auto">
            <AuthButtonsWrapper>
              <AuthSubmitButton type="submit" width="245px">
                {isSubmitting ? (
                  <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                ) : (
                  "Send Password Reset Email"
                )}
              </AuthSubmitButton>
              <SideButton onClick={() => handleViewChange(signInViews.SIGN_IN)}>
                Sign In
              </SideButton>
            </AuthButtonsWrapper>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
