import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import "./style.css";
import store from "src/stores/UserStore";

import { Menu } from "antd";
import VideoModal from "../VideoModal";
import { GuestDropdownMenu } from "./styled";
import SubMenu from "antd/es/menu/SubMenu";
import SubscribeModal from "../SubscribeModal";
import CareerDropdown from "./CareerDropdown";
import { FirebaseContext } from "src/components/Firebase";
import SignUpModal from "../SignUpModal";
import SignInModal from "../SignInModal";
import { BiSolidUserDetail } from "react-icons/bi";

const GuestDropdown = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const navigate = useNavigate();
  const firebase = useContext(FirebaseContext);

  const videoModalToggle = () => setVideoModalOpen((prevState) => !prevState);

  const subscribeModalToggle = () =>
    setSubscribeModalOpen((prevState) => !prevState);

  const studentModalToggle = () =>
    setStudentModalOpen((prevState) => !prevState);

  const signInModalToggle = () => setSignInModalOpen((prevState) => !prevState);

  return (
    <>
      <GuestDropdownMenu
        mode="horizontal"
        triggerSubMenuAction="click"
        subMenuCloseDelay={0}
        overflowedIndicator={
          <span className="guest-menu-title">
            Guest <BiSolidUserDetail size={24} />
          </span>
        }
      >
        <SubMenu
          key="options-menu"
          title={
            <span className="guest-menu-title">
              Guest <BiSolidUserDetail size={24} />
            </span>
          }
          popupClassName="guest-menu"
        >
          {store.isLoggedIn ? (
            <Menu.Item
              key="dashboard-link"
              onClick={() => navigate("/dashboard/guest-profile")}
            >
              Dashboard
            </Menu.Item>
          ) : (
            <>
              <CareerDropdown />
              <Menu.Divider />
              <Menu.Item key="student-signup" onClick={studentModalToggle}>
                New Student
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="sign-in" onClick={signInModalToggle}>
                Sign in
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item key="video" onClick={videoModalToggle}>
            Video
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="subscribe" onClick={subscribeModalToggle}>
            Subscribe
          </Menu.Item>
          {store.isLoggedIn && (
            <Menu.Item
              key="logout"
              onClick={async () => {
                await firebase.signOut();
              }}
            >
              Logout
            </Menu.Item>
          )}
        </SubMenu>
      </GuestDropdownMenu>
      <VideoModal isOpen={videoModalOpen} toggle={videoModalToggle} />
      <SubscribeModal
        isOpen={subscribeModalOpen}
        toggle={subscribeModalToggle}
      />
      <SignUpModal
        type="student"
        isOpen={studentModalOpen}
        toggle={studentModalToggle}
      />
      <SignInModal isOpen={signInModalOpen} toggle={signInModalToggle} />
    </>
  );
};

export default GuestDropdown;
