import { Button } from "reactstrap";
import styled from "styled-components";

const ReminderButton = styled(Button)`
  border: none;
  margin-left: 12px;

  font-size: 20px;
  background-color: #cd4a2c;
  border-radius: 12px;

  &:is(:hover, :focus) {
    background-color: #cd4a2c;
  }
`;

export { ReminderButton };
