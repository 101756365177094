import moment from "moment";
import { useContext } from "react";
import { Button } from "reactstrap";
import {
  AttendanceContext,
  AttendanceModalContext,
} from "../../common/contexts/AdminAttendanceContext";
import { AttendanceBlockContainer } from "../../common/styled-components/attendanceStylesComponents";
import { TextButton } from "../../common/styled-components/teacherLibraryStyledComponents";

const AttendanceBlock = ({ attendance }) => {
  const { isReadOnly, locations } = useContext(AttendanceContext);
  const { open } = useContext(AttendanceModalContext);
  const currentLocation =
    locations.find(({ id }) => id === attendance.locationId) || {};
  const locationName = currentLocation.name;
  return (
    <AttendanceBlockContainer
      className={`fw-bold attendance-block d-flex justify-content-end m-2 ${
        attendance?.isAttended ? "border border-purple" : ""
      }`}
    >
      <div className="text-container rounded-0 text-start border p-3 d-flex align-items-start justify-content-center flex-column">
        <span>
          {attendance?.title
            ? attendance.title
            : `Lesson ${attendance.weekNumber}`}
        </span>
        {attendance?.isAttended ? (
          <>
            <span className="fw-light small">{attendance.attendanceDate}</span>
            <span className="fw-light">{locationName}</span>
          </>
        ) : null}
      </div>
      {attendance?.isAttended ? (
        // !isReadOnly ?
        <Button
          disabled={isReadOnly}
          className="p-3 rounded-0 button-container d-flex align-items-center justify-content-center text-white bg-purple"
          color={"secondary"}
          onClick={() => {
            open(attendance, true);
          }}
        >
          <span>Edit</span>
          <i className="fa fa-edit ms-1" />
        </Button>
      ) : (
        // : null
        <Button
          disabled={isReadOnly}
          className="p-3 rounded-0 button-container d-flex align-items-center justify-content-center text-white bg-purple"
          color={"secondary"}
          onClick={() => {
            open(attendance, false);
          }}
        >
          Make Attendance
        </Button>
      )}
    </AttendanceBlockContainer>
  );
  // return (
  //   <AttendanceBlockContainer className="attendance-block d-flex flex-column align-items-center justify-content-end mb-2">
  //     <h4>{attendance?.title ? attendance.title : `Lesson ${attendance.weekNumber}`}</h4>
  //     {attendance?.isAttended ? (
  //       <div className="date-container d-flex align-items-center">
  //         <div className="d-flex flex-column">
  //           <span>{attendance.attendanceDate}</span>
  //           <span>{locationName}</span>
  //         </div>
  //         {!isReadOnly ?
  //           <TextButton className="ms-1 p-1 text-purple" onClick={() => {
  //             open(attendance, true);
  //           }}>
  //             <i className="fa fa-edit" />
  //           </TextButton>
  //           : null}
  //       </div>
  //     ) : (
  //       <Button
  //         disabled={isReadOnly || moment().add(1, 'hour').isBefore(attendance.lessonDate)}
  //         className={"rounded-pill bg-purple"}
  //         color={"secondary"}
  //         onClick={() => {
  //           open(attendance, false);
  //         }} >
  //         Make Attendance
  //       </Button>
  //     )}
  //   </AttendanceBlockContainer>
  // )
};
export default AttendanceBlock;
