import moment from "moment";
import React from "react";
import { absenceMakeUpOptions } from "src/constants/absenceTypes";
import styled from "styled-components";
import { InfoText } from "../../styled";

const MakeUpOptionContainer = styled.div`
  min-height: 100px;
  border: 1px solid #b6b6b6;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 20px;
`;
const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    width: 22px;
    height: 22px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    accent-color: #681e46;
  }
`;
const MakeUpOptionsSection = ({ makeupOption, makeupOpening, locations }) => {
  // const {startDate,endDate,isVirtualOnly,location} = makeupOpening;
  const isMakeupOpeningOption = makeupOption === absenceMakeUpOptions.MAKEUP;
  const isSubstituteOption = makeupOption === absenceMakeUpOptions.SUBSTITUTE;
  const openingDateStr = makeupOpening
    ? moment(makeupOpening?.startDate).format("MM-DD-YYYY")
    : "N/A";
  const openingStartTimeStr = makeupOpening
    ? moment(makeupOpening?.startDate).format("hh:mm A")
    : "N/A";
  const openingEndTimeStr = makeupOpening
    ? moment(makeupOpening?.endDate).format("hh:mm A")
    : "N/A";
  const openingLocationName = makeupOpening
    ? locations?.find(({ id }) => id === makeupOpening.location)?.name
    : "N/A";

  return (
    <div>
      <MakeUpOptionContainer>
        <div className="d-flex justify-content-between mb-5">
          <h4>Make Up</h4>
          <CheckboxContainer>
            <input disabled checked={isMakeupOpeningOption} type="checkbox" />
          </CheckboxContainer>
        </div>
        <div>
          <div className="d-flex align-items-center mb-4" style={{ gap: 15 }}>
            <InfoText>Date:</InfoText>
            <InfoText>{openingDateStr}</InfoText>
          </div>
          <div className="d-flex align-items-center mb-4" style={{ gap: 15 }}>
            <InfoText>Time:</InfoText>
            <InfoText>{openingStartTimeStr}</InfoText>
            <span>TO:</span>
            <InfoText>{openingEndTimeStr}</InfoText>
          </div>
          <div className="d-flex align-items-center mb-4" style={{ gap: 15 }}>
            <InfoText>Location:</InfoText>
            <InfoText>{openingLocationName}</InfoText>
          </div>

          <div className="mt-3 ">
            <label>
              <input
                disabled
                type="checkbox"
                className="me-1"
                checked={makeupOpening?.isVirtualOnly}
              />
              Virtual Only
            </label>
          </div>
        </div>
      </MakeUpOptionContainer>
      <MakeUpOptionContainer>
        <div className="d-flex justify-content-between mb-5">
          <h4>Substitute</h4>
          <CheckboxContainer>
            <input type="checkbox" disabled checked={isSubstituteOption} />
          </CheckboxContainer>
        </div>
      </MakeUpOptionContainer>
    </div>
  );
};

export default MakeUpOptionsSection;
