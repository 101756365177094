import React from "react";
import { SubmitSpinner } from "src/components/common";
import { CustomInput, PrimaryButton } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";

const EditBody = ({ editData }) => {
  const {
    formData,
    handleFormDataChange,
    saveChanges,
    savingChanges,
    resetChanges,
  } = editData;
  return (
    <div className="mt-3">
      {/* Title */}
      <div className="d-flex justify-content-evenly align-items-end mb-4">
        <div>
          <CustomLabel htmlFor="title">Title:</CustomLabel>
          <CustomInput
            className="form-control"
            placeholder="Title"
            name="title"
            type="text"
            value={formData.title}
            onChange={(e) => handleFormDataChange("title", e.target.value)}
            width="250px"
          />
        </div>
      </div>
      <div className="d-flex justify-content-evenly align-items-end mb-4">
        <div>
          <CustomLabel htmlFor="color">Color:</CustomLabel>
          <CustomInput
            className="form-control"
            placeholder="Color"
            name="color"
            type="color"
            value={formData.color}
            onChange={(e) => handleFormDataChange("color", e.target.value)}
            width="250px"
          />
        </div>
      </div>

      <div className="mt-5">
        {savingChanges ? (
          <div className="text-center">
            <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <PrimaryButton
              onClick={saveChanges}
              width="180px"
              isLoading={savingChanges}
            >
              Save Changes
            </PrimaryButton>
            <PrimaryButton
              onClick={resetChanges}
              width="180px"
              backgroundColor="#28348e"
              isLoading={savingChanges}
            >
              Reset Changes
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditBody;
