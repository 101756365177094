import React from "react";
import RedbarModal from "../RedbarModal";
import ViewGroupClassInfoBody from "./ViewGroupClassInfoBody";
import useGroupClassInfo from "./hooks/useGroupClassInfo";

const ViewGroupClassInfoModal = ({ groupClassId, modalData }) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;
  const hookData = useGroupClassInfo({ groupClassId });

  return (
    <RedbarModal
      modalData={modalData}
      modalHeader={"Group Class"}
      redbarTitle={"Group Class Info"}
      overflowRedbarContent={true}
      minWidthOnLargeScreen="1000px"
    >
      <ViewGroupClassInfoBody groupClassId={groupClassId} hookData={hookData} />
    </RedbarModal>
  );
};

export default ViewGroupClassInfoModal;
