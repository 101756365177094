import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  paymentCalculationTypes,
  paymentsMap,
  SALARY_BY_FIXED_AMOUNT,
} from "src/constants/paymentsEnum";
import { UserRole } from "src/constants/UserRoleEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  calculateTotalFromPaymentForm,
  convertPaymentDateStrToDate,
  getUserPaymentProps,
  roundToNearestDecimals,
} from "src/utils/helpers";
import { v4 as uuidv4 } from "uuid";

// const paymentFormDataInitialValues = {
//   [REGULAR_CLASS_30_MINS_BY_NUMBER]: "",
//   [REGULAR_CLASS_45_MINS_BY_NUMBER]: "",
//   [REGULAR_CLASS_60_MINS_BY_NUMBER]: "",
//   [SDC_BY_HRS]: "",
//   [GROUP_CLASS_BY_NUMBER]: "",
//   [TRAINING_HOURS_BY_HRS]: "",
//   [RESERVATION_HOURS_BY_HRS]: "",
//   [ADMIN_HOURS_BY_HRS]: "",
//   [BONUS_BY_CUSTOM_AMOUNT]: "",
//   [EA_BY_HRS]: "",
// };
const paymentFormDataInitialValues = paymentsMap.reduce(
  (acc, { propName }) => ({ ...acc, [propName]: "" }),
  {}
);

const usePaymentData = ({
  userCurrentPaymentRates,
  user,
  selectedUser,
  selectedDateRange,
  refreshUserData,
}) => {
  const { addUserPayment } = useFirebaseFns();

  const [paymentFormData, setPaymentFormData] = useState(
    paymentFormDataInitialValues
  );
  const [paymentNote, setPaymentNote] = useState("");

  const [loadingPayUser, setLoadingPayUser] = useState(false);

  function handlePaymentFormDataChange(name, value) {
    const acceptsNegativeValue = paymentsMap.find(
      ({ propName }) => propName === name
    )?.acceptsNegativeValue;

    let modifiedValue;
    if (acceptsNegativeValue) {
      modifiedValue = !value ? "" : parseFloat(value);
    } else {
      modifiedValue = !value || parseFloat(value) <= 0 ? "" : parseFloat(value);
    }
    setPaymentFormData((oldVal) => ({
      ...oldVal,
      [name]: modifiedValue,
    }));
  }

  async function payUser() {
    try {
      if (calculatedTotalObj.total <= 0 || !selectedDateRange) {
        toast.error("Error while paying user");
        return;
      }
      setLoadingPayUser(true);

      const { start, end } = selectedDateRange;
      const startDate = convertPaymentDateStrToDate(start);
      const endDate = convertPaymentDateStrToDate(end);

      const paymentsArr = [];

      for (const propName of Object.keys(paymentFormData)) {
        const currentPaymentMap = paymentsMap.find(
          (map) => propName === map.propName
        );
        if (!currentPaymentMap) {
          toast.error("Couldnt find currentPaymentMap ( payUser() )");
          continue;
        }

        paymentFormData[propName] &&
          paymentsArr.push({
            id: uuidv4(),
            type: currentPaymentMap.paymentType,
            ...(currentPaymentMap.fractionPerUnit && {
              fractionPerUnit: currentPaymentMap.fractionPerUnit,
            }),
            calculatedBy: currentPaymentMap.paymentCalculationType,
            calculatedByAmount: paymentFormData[propName],
            totalPaid:
              calculatedTotalObj.individualPaymentsTotal[propName] || 0,
          });
      }

      const paymentObj = {
        createdAt: new Date(),
        createdBy: user.uid,

        userId: selectedUser.id,
        userRole: selectedUser.role,
        paymentMethod: selectedUser.workCondition || "",

        ...(paymentNote && { note: paymentNote }),

        // a reference for the rates object we used for this payment
        paymentRatesId: userCurrentPaymentRates?.id,

        startDate,
        endDate,

        payments: paymentsArr,
      };

      console.log(paymentObj);
      const res = await addUserPayment(paymentObj);
      if (res.id) {
        toast.success("Paid Successfully");
        setPaymentFormData(paymentFormDataInitialValues);
        setPaymentNote("");
        refreshUserData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingPayUser(false);
    }
  }

  useEffect(() => {
    if (!selectedUser) return;

    const userPaymentProps = getUserPaymentProps(selectedUser);

    if (userPaymentProps.includes(SALARY_BY_FIXED_AMOUNT)) {
      const map = paymentsMap.find(
        (curr) => curr.propName === SALARY_BY_FIXED_AMOUNT
      );
      const yearlySalary = userCurrentPaymentRates?.rates?.find(
        (rate) => rate.title === map.rateTitle && rate.type === map.rateType
      );
      if (yearlySalary) {
        const twoWeeksSalary = roundToNearestDecimals(
          yearlySalary.value / 24,
          2
        );

        setPaymentFormData((oldVal) => ({
          ...oldVal,
          [SALARY_BY_FIXED_AMOUNT]: twoWeeksSalary,
        }));
      }
    } else {
      setPaymentFormData((oldVal) => {
        const { [SALARY_BY_FIXED_AMOUNT]: x, ...rest } = oldVal;
        return rest;
      });
    }
  }, [selectedUser?.id, userCurrentPaymentRates?.rates]);

  const calculatedTotalObj = useMemo(() => {
    return calculateTotalFromPaymentForm({
      paymentFormData,
      userRates: userCurrentPaymentRates?.rates,
    });
  }, [paymentFormData, userCurrentPaymentRates?.rates]);

  useEffect(() => {
    setPaymentFormData(paymentFormDataInitialValues);
  }, [selectedUser?.id]);
  return {
    paymentFormData,
    handlePaymentFormDataChange,
    calculatedTotalObj,
    loadingPayUser,
    payUser,
    paymentNote,
    setPaymentNote,
  };
};

export default usePaymentData;
