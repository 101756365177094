import React from "react";
import LabeledInput from "../../common/LabeledInput";
import LabeledRadioInput from "../../common/LabeledRadioInput";
import { SectionContainer } from "../../../styled";

const MinutesPerProgramSection = ({
  maxNumberOfEnrollments,
  concertData,
  handleConcertDataChange,
}) => {
  return (
    <div>
      <h5 className="mb-3">How Many Minutes For Each Program:</h5>
      <SectionContainer>
        <div className="mb-3">
          <LabeledInput
            label="Minutes"
            type="number"
            value={concertData.minsPerProgram.startTime}
            onChange={(e) =>
              handleConcertDataChange("minsPerProgram", e.target.value)
            }
            width="165px"
          />
          {maxNumberOfEnrollments ? (
            <p className="mt-2 mb-2 text-secondary ">
              Can Enroll Up To {maxNumberOfEnrollments} Programs
            </p>
          ) : null}
        </div>
        <div className="mb-3">
          <LabeledRadioInput
            label="Waiting List"
            options={[
              { title: "Yes", value: true },
              {
                title: "No",
                value: false,
              },
            ]}
            onChange={(currVal) =>
              handleConcertDataChange("waitingList.isEnabled", currVal)
            }
            value={concertData.waitingList.isEnabled}
          />
        </div>
        <div>
          <LabeledInput
            withLabel={false}
            placeholder="How Many"
            disabled={!concertData.waitingList.isEnabled}
            type="number"
            value={concertData.waitingList.value}
            onChange={(e) =>
              handleConcertDataChange("waitingList.value", e.target.value)
            }
            width="165px"
          />
        </div>
      </SectionContainer>
    </div>
  );
};

export default MinutesPerProgramSection;
