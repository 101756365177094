import React from "react";
import { Container, RedBarContainer, RedBarText } from "./styled";

const StyledBarContent = ({ title, overFlowContent, children }) => {
  return (
    <Container className="w-100">
      {/* Red Bar */}
      <RedBarContainer>
        <RedBarText>{title}</RedBarText>
      </RedBarContainer>
      <div
        style={{
          flex: 1,
          overflow: overFlowContent || "hidden",
          minWidth: "0px",
        }}
      >
        {children}
      </div>
    </Container>
  );
};

export default StyledBarContent;
