import styled from "styled-components";
import { Plus } from "@styled-icons/bootstrap/Plus";
import { useState } from "react";
import { feedItems } from "src/constants/feedItemsEnum";
import AddNewFeedItemModal from "src/components/Dashboard/common/components/AddFeedItemModal";
import useModal from "src/hooks/useModal";
import { injectUserStore } from "src/utils/helpers";

const AddButton = styled.div`
  cursor: pointer;
  background-color: #ffe6f3;
  border-radius: 15px;
  display: flex;
  margin-bottom: 20px;
  padding: 6px;
`;
const CreateFeedItemsSection = ({ UserStore }) => {
  const user = UserStore.user;
  const addFeedItemModalData = useModal();
  const { isModalOpen, openModal } = addFeedItemModalData;
  const [modalType, setModalType] = useState(feedItems.announcements.type);

  const handleOpenForm = (type) => {
    openModal();
    setModalType(type);
  };
  return (
    <div>
      <AddButton onClick={() => handleOpenForm(feedItems.workUpdates.type)}>
        <Plus width={40} height={40} />
        <p
          style={{ fontWeight: "500" }}
          className="ms-2 d-flex align-items-center mb-0 "
        >
          Add Work Update
        </p>
      </AddButton>
      <AddButton onClick={() => handleOpenForm(feedItems.announcements.type)}>
        <Plus width={40} height={40} />
        <p
          style={{ fontWeight: "500" }}
          className="ms-2 d-flex align-items-center mb-0 "
        >
          Add Announcement
        </p>
      </AddButton>
      <AddButton onClick={() => handleOpenForm(feedItems.news.type)}>
        <Plus width={40} height={40} />
        <p
          style={{ fontWeight: "500" }}
          className="ms-2 d-flex align-items-center mb-0 "
        >
          Add News
        </p>
      </AddButton>
      <AddButton onClick={() => handleOpenForm(feedItems.otherTopics.type)}>
        <Plus width={40} height={40} />
        <p
          style={{ fontWeight: "500" }}
          className="ms-2 d-flex align-items-center mb-0 "
        >
          Create Fun Things
        </p>
      </AddButton>
      {isModalOpen && (
        <AddNewFeedItemModal
          feedItemType={modalType}
          modalData={addFeedItemModalData}
        />
      )}
    </div>
  );
};
export default injectUserStore(CreateFeedItemsSection);
