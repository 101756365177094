import "./style.css";
import Attachment from "./Attachment";
import { Interweave } from "interweave";

function NewElement(props) {
  // console.log({ type, url, name, small: firstWord });

  let publishDate = props.publishDate;
  publishDate = publishDate.toDate().toDateString();
  publishDate = [publishDate.slice(4, 10), ",", publishDate.slice(11)].join("");
  return (
    <figure className="news-element">
      <Attachment attachments={props.attachments} />
      <h2>{props.title}</h2>
      <p className="author">
        By {props.userName} | {publishDate}
      </p>
      <p className="summary">
        <Interweave content={props.body} />
      </p>
    </figure>
  );
}

export default NewElement;
