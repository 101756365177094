import { schoolPolicies } from "src/constants/schoolPolicies";
import { NavButton } from "src/utils/shared/styled";

const NavigationBar = ({ currentTab, setCurrentTab }) => {
  const tabs = Object.values(schoolPolicies);
  return (
    <div className="d-flex align-items-center" style={{ overflow: "auto" }}>
      {tabs.map(({ title, key }) => (
        <div>
          <NavButton
            fontSize="1.1rem"
            className={currentTab === key ? "active" : undefined}
            onClick={() => setCurrentTab(key)}
            withRedBar
          >
            {title}
          </NavButton>
        </div>
      ))}
    </div>
  );
};

export default NavigationBar;
