import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useContext, createContext } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import { isRecurring10PackageSet } from "src/constants/packageLessonsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useModal from "src/hooks/useModal";
import { getCombinedPackageSetItems } from "src/utils/helpers";

const PackageLessonContext = createContext({
  packageTeachers: [],
  locations: [],
  isLoading: false,
  packageLesson: undefined,
  currentSet: undefined,
  setCurrentSet: () => {},
  deleteCurrentPackageItem: async () => {},
  currentPackageItemId: "",
  isSubmitting: false,
  setCurrentPackageItemId: () => {},
  deletePackageItemModalData: undefined,
  onDeletePackagItemClick: () => {},
  isAbleToDeletePackageItems: false,
  deletePackageSetModalData: undefined,
  deleteCurrentPackageSet: async () => {},
  onDeletePackagSetClick: () => {},
});

export const PackageLessonContextProvider = ({
  children,
  packageLesson,
  providedInitialData = {
    locations: undefined,
  },
  ...props
}) => {
  const { getTeachersByIds, getLocations, updatePackageSetsAndActivity } =
    useFirebaseFns();

  const packageTeachersIds = packageLesson?.teachersIds;

  const deletePackageItemModalData = useModal();
  const deletePackageSetModalData = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    packageTeachers: [],
    locations: [],
  });
  const [currentSet, setCurrentSet] = useState();
  const [currentPackageItemId, setCurrentPackageItemId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDeletePackagItemClick = (packageItemId) => {
    setCurrentPackageItemId(packageItemId);
    deletePackageItemModalData.openModal();
  };

  const onDeletePackagSetClick = (setNumber) => {
    setCurrentSet(setNumber);
    deletePackageSetModalData.openModal();
  };

  const deleteCurrentPackageSet = async () => {
    try {
      if (!currentSet) {
        return;
      }

      setIsSubmitting(true);

      const updatedPackageSets = packageLesson.packageSets.filter(
        ({ setNumber }) => {
          return setNumber !== currentSet;
        }
      );

      await updatePackageSetsAndActivity(updatedPackageSets, packageLesson?.id);

      toast.success("Set Deleted Successfully");
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteCurrentPackageItem = async () => {
    try {
      if (!currentPackageItemId) {
        return;
      }

      setIsSubmitting(true);

      const deletedItemSet = packageLesson.packageSets.find((set) => {
        const isFound = !!set.setItems.find(
          ({ id }) => id === currentPackageItemId
        );
        return isFound;
      });

      const updatedPackageSets = packageLesson.packageSets.map((set) => {
        if (set.setNumber === deletedItemSet?.setNumber) {
          const updatedSet = {
            ...set,
            setItems: set?.setItems.filter(
              ({ id }) => id !== currentPackageItemId
            ),
          };
          return updatedSet;
        } else {
          return set;
        }
      });

      await updatePackageSetsAndActivity(updatedPackageSets, packageLesson?.id);

      toast.success("Deleted Successfully");
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!packageLesson) return;
        setIsLoading(true);

        const [teachers, locations] = await Promise.all([
          getTeachersByIds(packageTeachersIds),
          getLocations(),
        ]);
        setData({
          packageTeachers: teachers,
          locations,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const isAbleToDeletePackageItems =
    getCombinedPackageSetItems(packageLesson.packageSets)?.length > 1;

  const value = useMemo(
    () => ({
      isLoading,
      packageLesson,
      currentSet,
      setCurrentSet,
      deleteCurrentPackageItem,
      isSubmitting,
      currentPackageItemId,
      setCurrentPackageItemId,
      deletePackageItemModalData,
      onDeletePackagItemClick,
      isAbleToDeletePackageItems,
      deleteCurrentPackageSet,
      onDeletePackagSetClick,
      deletePackageSetModalData,
      ...data,
    }),
    [
      isLoading,
      data,
      packageLesson,
      currentSet,
      setCurrentSet,
      deleteCurrentPackageItem,
      isSubmitting,
      currentPackageItemId,
      setCurrentPackageItemId,
      deletePackageItemModalData,
      onDeletePackagItemClick,
      isAbleToDeletePackageItems,
      deleteCurrentPackageSet,
      onDeletePackagSetClick,
      deletePackageSetModalData,
    ]
  );

  return (
    <PackageLessonContext.Provider value={value} {...props}>
      {children}
    </PackageLessonContext.Provider>
  );
};

export const usePackageLessonContext = () => {
  return useContext(PackageLessonContext);
};

export default PackageLessonContext;
