import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 30px;
`;
export const RatesContainer = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 15px;
  border-top: ${({ borderTop }) => borderTop || "none"};
  width: 100%;
`;
export const InfoContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const InfoText = styled.p`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : "auto")};
`;
