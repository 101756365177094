import { action, observable, computed, makeObservable } from "mobx";

class UserStore {
  user = "";
  isLoaded = false;
  isLoading = true;

  setUser = (user) => {
    this.user = user;
    this.isLoaded = true;
  };
  setLoadingUser = () => {
    this.isLoading = true;
  };
  stopLoadingUser = () => {
    this.isLoading = false;
  };

  setEmailVerified = (value) => {
    this.user.emailVerified = value;
  };

  setStatus = (value) => {
    this.user.status = value;
  };

  constructor() {
    makeObservable(this, {
      user: observable,
      isLoaded: observable,
      isLoading: observable,
      setUser: action,
      setLoadingUser: action,
      stopLoadingUser: action,
      isLoggedIn: computed,
      setEmailVerified: action,
      setStatus: action,
    });
  }

  get isLoggedIn() {
    return !!this.user;
  }
}

const store = new UserStore();
export default store;
