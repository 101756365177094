import React from "react";
import { usePackageLessonContext } from "../../../contexts/PackageLessonContext";
import PackageItemInfo from "./PackageItemInfo";

const PackageSetInfo = ({ setNumber }) => {
  const { packageLesson } = usePackageLessonContext();

  const setObj = packageLesson.packageSets.find(
    (set) => set.setNumber === setNumber
  );
  const setItems = setObj.setItems || [];

  return setItems.map((setItem, i) => (
    <PackageItemInfo
      key={setItem.id}
      packageItem={setItem}
      setNumber={setNumber}
      index={i}
    />
  ));
};

export default PackageSetInfo;
