import moment from "moment";
import React from "react";
import { weekDays } from "src/constants/weekDays";
import {
  AvailabilitiesContainer,
  CustomLabel,
  CustomSelectField,
} from "../styled";

const AvailabilitiesSection = ({
  plCreationData,

  handleLessonTimeChange,
}) => {
  const {
    formInputs,
    handleFormInputsChange,
    selectedTeacherObj,
    selectedTeacherDays,
    initialData,
  } = plCreationData;
  const { fullName } = selectedTeacherObj || {};

  // if virtual lsn, show all avails, else only show in person avails
  const filteredTeacherDays = formInputs.isVirtual
    ? selectedTeacherDays
    : selectedTeacherDays?.filter(({ isVirtualOnly }) => !isVirtualOnly);
  // flatted time chunks with location
  const timeChunks =
    filteredTeacherDays
      ?.map(({ availableSlotsChunks, location, isVirtualOnly }) =>
        availableSlotsChunks.map((slotArr) =>
          slotArr.map((slot) => ({
            ...slot,
            locationId: location,
            isVirtualOnly,
          }))
        )
      )
      ?.flat(2) || [];
  return (
    <div>
      <div className="d-flex">
        <CustomLabel className="mb-3 me-5">Teacher Available Time</CustomLabel>
        <div>
          <label className="me-3">
            <input
              type="radio"
              className="me-2"
              name="isVirtual"
              checked={!formInputs.isVirtual}
              value={false}
              onChange={handleFormInputsChange}
            />
            In Person
          </label>
          <label>
            <input
              type="radio"
              className="me-2"
              name="isVirtual"
              checked={formInputs.isVirtual}
              value={true}
              onChange={handleFormInputsChange}
            />
            Virtual
          </label>
        </div>
      </div>
      <AvailabilitiesContainer>
        {timeChunks?.length ? (
          <>
            <p className="fs-5 fw-bold">{`${fullName} is Available:`}</p>

            {filteredTeacherDays?.map((teacherDay) => {
              const {
                availableSlots,
                location,
                newStartDate,
                studio,
                isVirtualOnly,
              } = teacherDay;
              const weekDayName = weekDays.find(
                ({ id }) => id === moment(newStartDate).day()
              )?.name;
              const locationName = initialData.locations?.find(
                ({ id }) => id === location
              )?.name;
              const availableSlotsMarkup = availableSlots?.map((slot) => {
                const slotStartTime = moment(slot.start_time).format("hh:mm A");
                const slotEndTime = moment(slot.end_time).format("hh:mm A");

                const virtualOnlyStr = isVirtualOnly ? "(Virtual Only)" : "";

                return (
                  <p className="fs-5 fw-bold mb-0">
                    {`${weekDayName}, ${slotStartTime} - ${slotEndTime}, ${locationName} ${virtualOnlyStr}`}
                  </p>
                );
              });

              return availableSlotsMarkup;
            })}
          </>
        ) : (
          <p className="fs-5 fw-bold">{`${fullName} is Not Available on this day`}</p>
        )}
      </AvailabilitiesContainer>

      <div className="mt-4">
        <CustomLabel className="mb-3">Lesson Time</CustomLabel>
        <CustomSelectField
          name="lessonTime"
          value={formInputs.lessonTime}
          onChange={(e) => {
            const selectedOptionElement =
              e.target.options[e.target.selectedIndex];

            const [lessonTime, locationId] = selectedOptionElement
              .getAttribute("data-value")
              .split("__");
            handleLessonTimeChange(lessonTime, locationId);
          }}
          width="100%"
        >
          <option value="" disabled>
            Lesson Time
          </option>
          {timeChunks?.map((chunk, i) => (
            <option
              key={i}
              value={chunk.startTime}
              data-value={`${chunk.startTime}__${chunk.locationId}`}
            >
              {`${chunk.startTime} - ${chunk.endTime} (${
                formInputs.isVirtual ? "Virtual" : "In Person"
              })`}
            </option>
          ))}
        </CustomSelectField>
      </div>
    </div>
  );
};

export default AvailabilitiesSection;
