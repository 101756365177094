import {
  addDoc,
  deleteDoc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import {
  getCurrentTimeline,
  getStudents,
  updatedMomentNow,
} from "../../../utils/helpers";
import {
  AttendanceContext,
  AttendanceModalContext,
} from "../../common/contexts/AdminAttendanceContext";
import {
  StudentsContext,
  StudentsSelectContext,
} from "../../common/Library/common/libraryContext";
import SearchSelect from "../../common/Library/common/SearchSelect";
import LibraryStudentsList from "../../common/Library/tab-content/students-list/LibraryStudentsList";
import { FirebaseContext } from "../../Firebase";
import AttendanceListContainer from "./AttendanceListContainer";
import MarkAttendanceModal from "./MarkAttendanceModal";

const AdminAttendanceList = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const [students, setStudents] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [attendanceModalState, setAttendanceModalState] = useState({
    isOpen: false,
    attendance: {
      lessonDate: new Date(),
      attendaceDate: new Date(),
    },
    isEdit: false,
  });
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({ value: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);

  const getLocations = async () => {
    const locationsDocs = await getDocs(firebase.locations());
    const locationsArray = [];
    locationsDocs.forEach((location) =>
      locationsArray.push({ ...location.data(), id: location.id })
    );
    setLocations(locationsArray);
  };
  const getInstruments = async () => {
    const instrumentsDocs = await getDocs(firebase.instruments());
    const instrumentsArray = [];
    instrumentsDocs.forEach((instrument) =>
      instrumentsArray.push({ ...instrument.data(), id: instrument.id })
    );
    setInstruments(instrumentsArray);
  };
  const getSchoolYears = async () => {
    const schoolYearsDocs = await getDocs(firebase.getSchoolYears());
    const schoolYearsArray = [];
    schoolYearsDocs.forEach((schoolYear) =>
      schoolYearsArray.push({ ...schoolYear.data(), id: schoolYear.id })
    );
    setSchoolYears(schoolYearsArray);
  };
  const locationsOptions = useMemo(() => {
    return locations.map(({ id, name }) => ({ label: name, value: id }));
  }, [locations]);
  const filteredStudents = useMemo(() => {
    if (selectedLocation?.value === "") {
      return students;
    }
    return students.filter((student) => {
      if (student?.lessons?.length) {
        return student.lessons.some(
          ({ locationId, isPackageLsn }) =>
            locationId === selectedLocation.value || isPackageLsn
        );
      }
      return false;
    });
  }, [students, selectedLocation]);

  const getStudentsList = async () => {
    const studentsArray = await getStudents(true, false, firebase, user.uid);
    setStudents(studentsArray);
  };
  const getPrivateLessons = async () => {
    const privateLessonsSnap = await getDocs(firebase.PrivateLessons());

    // Doesnt consider package lsns
    const privateLessons = parseFirebaseDoc(privateLessonsSnap.docs);

    const lessons = {};
    privateLessons.forEach((privateLesson) => {
      const isPackageLsn = isPackagePrivateLesson(privateLesson.type);
      if (!lessons?.[privateLesson.studentId]) {
        lessons[privateLesson.studentId] = [];
      }
      if (isPackageLsn) {
        const lessonDates = privateLesson.packageSets
          ?.map(({ setItems }) => {
            return setItems.map(({ startDate }) => ({
              startDate,
            }));
          })
          ?.flat();
        lessons[privateLesson.studentId].push({
          lessonId: privateLesson.id,
          instrumentId: privateLesson.instrumentId,
          lessonDates,
          isPackageLsn: true,
        });
      } else {
        const currentTimeline = getCurrentTimeline(privateLesson.timeline);
        const lessonDates = (privateLesson.timeline || []).map(
          ({ startDate, lastDate }) => ({
            startDate,
            lastDate:
              lastDate ??
              Timestamp.fromDate(
                moment(startDate.toDate()).add(45, "weeks").toDate()
              ),
          })
        );
        lessons[privateLesson.studentId].push({
          locationId: currentTimeline.locationId,
          lessonId: privateLesson.id,
          instrumentId: privateLesson.instrumentId,
          lessonDates,
        });
      }
    });
    setStudents((previous) =>
      previous
        .map((student) => ({
          ...student,
          lessons: lessons?.[student.id] ? lessons?.[student.id] : [],
        }))
        .filter(({ lessons }) => lessons.length > 0)
    );
  };
  useEffect(async () => {
    setIsLoading(true);
    await Promise.all([
      getLocations(),
      getStudentsList(),
      getInstruments(),
      getSchoolYears(),
    ]);
    await getPrivateLessons();
    setIsLoading(false);
  }, []);
  const studentsContextValue = {
    students: filteredStudents,
  };
  const studentsSelectContextValue = {
    selected: [selectedStudent],
    select: (studentId) => {
      setSelectedStudent(studentId);
    },
    toggle: () => {},
  };
  const attendanceModalContextValue = {
    ...attendanceModalState,
    open: (attendance, isEdit = false) => {
      if (isEdit) {
        setAttendanceModalState({
          isOpen: true,
          attendance,
          isEdit,
        });
      } else {
        (async () => {
          toast.info("Marking attendance....");
          setIsReadOnly(true);
          const { weekNumber, lessonId, lessonDate } = attendance;

          const res = await addDoc(firebase.getLessonAttendanceCollection(), {
            studentId: selectedStudent,
            lessonId,
            weekNumber,
            locationId: selectedLocation?.value,
            date: Timestamp.fromDate(
              updatedMomentNow()
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .set("millisecond", 0)
                .add(lessonDate.get("hour"), "hours")
                .add(lessonDate.get("minute"), "minutes")
                .toDate()
            ),
          });
          console.log({ createdAttId: res.id });
          setIsReadOnly(false);
          toast.success("Success.");
        })();
      }
    },
    close: () => {
      setAttendanceModalState({
        isOpen: false,
        attendance: {
          lessonDate: new Date(),
          attendaceDate: new Date(),
        },
        isEdit: false,
      });
    },
    submit: async (data) => {
      toast.info("Marking attendance....");
      const { weekNumber, lessonId, attendanceDate, id, locationId } = data;
      if (id) {
        await updateDoc(firebase.getLessonAttendanceDoc(id), {
          date: Timestamp.fromDate(attendanceDate.toDate()),
          locationId,
        });
      } else {
        await addDoc(firebase.getLessonAttendanceCollection(), {
          studentId: selectedStudent,
          lessonId,
          weekNumber,
          locationId: selectedLocation?.value,
          // type: attendanceType,
          date: Timestamp.fromDate(attendanceDate.toDate()),
        });
      }
      setAttendanceModalState({
        isOpen: false,
        attendance: {
          lessonDate: new Date(),
          attendaceDate: new Date(),
        },
        isEdit: false,
      });
      toast.success("Success.");
    },
    deleteAttendance: async () => {
      toast.info("Clearing attendance....");
      const { id } = attendanceModalState.attendance;
      if (id) {
        await deleteDoc(firebase.getLessonAttendanceDoc(id));
      }
      setAttendanceModalState({
        isOpen: false,
        attendance: {
          lessonDate: new Date(),
          attendaceDate: new Date(),
        },
        isEdit: false,
      });
      toast.success("Success.");
    },
  };
  const attendanceContextValue = {
    selectedStudent: filteredStudents.find(({ id }) => id === selectedStudent),
    instruments,
    locations,
    schoolYears,
    isReadOnly,
    setIsReadOnly,
    addExtra: async (lessonId) => {
      toast.info("Adding additional attendance....");
      setIsReadOnly(true);
      const currentStudent = students.find(({ id }) => id === selectedStudent);

      await addDoc(firebase.getLessonAttendanceCollection(), {
        studentId: selectedStudent,
        lessonId,
        title: "Additional",
        date: Timestamp.now(),
        locationId: selectedLocation?.value,
        isAttended: true,
      });
      setIsReadOnly(false);
      toast.success("Success.");
    },
  };
  return (
    <div className={"h-100 d-flex"}>
      <StudentsContext.Provider value={studentsContextValue}>
        <StudentsSelectContext.Provider value={studentsSelectContextValue}>
          {isLoading ? (
            <div className="d-flex h-100 align-items-center justify-content-center col-3">
              <Spinner />
            </div>
          ) : (
            <div className="col-3">
              <h4>Location</h4>
              <div className="px-2">
                <SearchSelect
                  name="locations"
                  placeholder={"Location"}
                  options={locationsOptions}
                  onOptionSelect={(e) => {
                    setSelectedLocation(e);
                    setSelectedStudent("");
                  }}
                  width="100%"
                  value={selectedLocation}
                  isMulti={false}
                />
              </div>
              <LibraryStudentsList withToggle={false} fullWidth={true} />
            </div>
          )}
          <AttendanceContext.Provider value={attendanceContextValue}>
            <AttendanceModalContext.Provider
              value={attendanceModalContextValue}
            >
              {attendanceModalState.isOpen ? <MarkAttendanceModal /> : null}
              <AttendanceListContainer />
            </AttendanceModalContext.Provider>
          </AttendanceContext.Provider>
        </StudentsSelectContext.Provider>
      </StudentsContext.Provider>
    </div>
  );
};
export default AdminAttendanceList;
