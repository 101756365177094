export const notificationEvents = {
  ABSENCE: "absence",
  MAKEUP: "makeup",
  PRIVATE_LESSON: "private_lesson",
  UNSPECIFIED: "unspecified",
};

export const notificationTypes = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  UNSPECIFIED: "unspecified",
};

export const isAbsenceNotification = (event) => {
  if (!event) return false;
  return event === notificationEvents.ABSENCE;
};

export const isMakeupLessonNotification = (event) => {
  if (!event) return false;
  return event === notificationEvents.MAKEUP;
};
