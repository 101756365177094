import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  groupClassPaymentMethods,
  groupClassPaymentStudentTypes,
} from "src/constants/groupClassPaymentsConstants";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getPaypalOrderDetails,
  getPaypalSubscriptionDetails,
} from "src/utils/apiFns";

const initialDataInitialValues = {
  paymentObj: undefined,
  paymentOption: undefined,
  paypalOrderDetails: undefined,
  paypalSubscriptionDetails: undefined,
};
const useGCStudentPayment = ({ groupClassId, studentId, user }) => {
  const {
    getGroupClassPayments,
    getGroupClassPaymentOptionById,
    createGroupClassPayment,
    getUserById,
    getPaymentOptionsByGroupClassId,
  } = useFirebaseFns();
  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  async function markAsPaidExternally() {
    try {
      setLoadingAction(true);
      const [student, groupClassPaymentOptions] = await Promise.all([
        getUserById(studentId),
        getPaymentOptionsByGroupClassId(groupClassId),
      ]);

      const usedPaymentOption = groupClassPaymentOptions[0];

      const role = student?.role;
      const paymentObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        groupClassId,
        paymentOptionId: usedPaymentOption?.id,
        userId: studentId,
        userRole: role,
        paymentMethod: groupClassPaymentMethods.EXTERNAL,
      };
      await createGroupClassPayment(paymentObj);
      toast.success("Marked As Paid Externally!");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  useEffect(() => {
    if (!groupClassId || !studentId) return;
    const fetchData = async () => {
      try {
        setLoadingInitialData(true);
        const paymentObj = (
          await getGroupClassPayments({
            groupClassId,
            userId: studentId,
          })
        )[0];

        if (!paymentObj) {
          return;
        }
        const {
          paymentOptionId,
          paymentMethod,
          paypalSubscriptionID,
          paypalOrderID,
        } = paymentObj;

        const [paymentOption, paypalOrderRes, paypalSubscriptionRes] =
          await Promise.all([
            getGroupClassPaymentOptionById(paymentOptionId),
            paypalOrderID && getPaypalOrderDetails(paypalOrderID),
            paypalSubscriptionID &&
              getPaypalSubscriptionDetails(paypalSubscriptionID),
          ]);

        let paypalOrderDetails, paypalSubscriptionDetails;
        if (paypalOrderRes?.success) {
          paypalOrderDetails = paypalOrderRes.data;
        }
        if (paypalSubscriptionRes?.success) {
          paypalSubscriptionDetails = paypalSubscriptionRes.data;
        }
        console.log({
          paymentOption,
          paymentObj,
          paypalSubscriptionDetails,
          paypalOrderDetails,
        });

        setInitialData({
          paymentOption,
          paymentObj,
          paypalOrderDetails,
          paypalSubscriptionDetails,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchData();
  }, [groupClassId, studentId]);

  return {
    initialData,
    loadingInitialData,
    markAsPaidExternally,
    loadingAction,
  };
};

export default useGCStudentPayment;
