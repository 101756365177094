import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { MAIN_LOCATION_SYSTEM_ID } from "src/constants/constantVariables";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getCombinedSchoolBreaks,
  getGroupClassClassesByFromDates,
} from "src/utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { getMultiDayTeachersDaysAvailabilities } from "../../PrivateLessonCommonComponents/helperFns";
import {
  groupClassAvailableForMap,
  groupClassFrequency,
  groupClassLessonType,
  groupClassLocationStatuses,
  groupClassStatuses,
} from "src/constants/groupClassConstants";
import { createGroupClassViews, paymentAmountTypes } from "../constants";
import {
  groupClassPaymentStudentTypes,
  groupClassPaymentTypes,
  scheduledPaymentTimesToRun,
} from "src/constants/groupClassPaymentsConstants";
import { weekDays } from "src/constants/weekDays";
import { ImageNotSupportedDimensions } from "@styled-icons/material-outlined/ImageNotSupported";
import { createPaypalProduct } from "src/utils/apiFns";

const formInputsInitialData = {
  title: "",
  locationId: "",
  teachersIds: [],
  day: "",
  frequency: "",
  iterations: "",
  startDate: "",
  lessonLength: "",
  availableFor: "",
  lessonTime: "",
  locationStatus: groupClassLocationStatuses.IN_PERSON,
  description: "",
  adminNote: "",
  //

  // title: "s",
  // locationId: "6hInLrg3yHIxKAKa7zai",
  // teachersIds: ["HwoFPLck6uZ37igniz7V34CeAdz2"],
  // day: "1",
  // frequency: "daily",
  // iterations: 1,
  // startDate: "2023-10-30",
  // lessonLength: "30",
  // availableFor: "1",
  // lessonTime: "01:45 PM",
  // locationStatus: 3,
  // description: "ee",
  // adminNote: "rr",
};
const paymentFormInputsInitialData = {
  paymentType: groupClassPaymentTypes.ONE_TIME,
  interval: "",
  intervalCount: "",
  timesToRun: {
    type: "",
    amount: "",
  },

  [paymentAmountTypes.CURRENT_STUDENT_DIRECT_AMOUNTS]: [],
  [paymentAmountTypes.CURRENT_STUDENT_SCHEDULED_AMOUNTS]: [],
  [paymentAmountTypes.PUBLIC_DIRECT_AMOUNTS]: [],
  [paymentAmountTypes.PUBLIC_SCHEDULED_AMOUNTS]: [],
};

const useCreateGroupClass = ({ user }) => {
  const {
    getTeachers,
    getTeachersGroupClassDays,
    getTeachersActivities,
    getLocations,
    getAbsences,
    getSchoolYears,
    createGroupClass,
    createGroupClassPaymentOption,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    teachers: [],
    locations: [],
    absences: [], // teacher absences
    schoolYears: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentView, setCurrentView] = useState(
    createGroupClassViews.CLASS_INFO
  );

  const [formInputs, setFormInputs] = useState(formInputsInitialData);
  const [paymentFormInputs, setPaymentFormInputs] = useState(
    paymentFormInputsInitialData
  );

  const [recurringDates, setRecurringDates] = useState([]);

  const handlePaymentFormInputChange = (name, value) => {
    switch (name) {
      case "paymentType":
        setPaymentFormInputs((oldVal) => ({
          ...oldVal,
          paymentType: value,
          interval: "",
          intervalCount: "",
          timesToRun: {
            type: "",
            amount: "",
          },
        }));
        return;
      case "interval":
        setPaymentFormInputs((oldVal) => ({
          ...oldVal,
          interval: value,
          intervalCount: "",
          timesToRun: {
            type: "",
            amount: "",
          },
        }));
        return;

      case "intervalCount":
        setPaymentFormInputs((oldVal) => ({
          ...oldVal,
          intervalCount: value,
          timesToRun: {
            type: "",
            amount: "",
          },
        }));
        return;
      case "timesToRun.type":
        setPaymentFormInputs((oldVal) => ({
          ...oldVal,
          timesToRun: {
            type: value,
            amount: "",
          },
        }));
        return;
      case "timesToRun.amount":
        setPaymentFormInputs((oldVal) => ({
          ...oldVal,
          timesToRun: {
            ...oldVal.timesToRun,
            amount: value,
          },
        }));
        return;

      default:
        setPaymentFormInputs((oldVal) => ({
          ...oldVal,
          [name]: value,
        }));
        return;
    }
  };
  const handleAddNewPaymentAmount = (type) => {
    setPaymentFormInputs((oldVal) => ({
      ...oldVal,
      [type]: [
        ...oldVal[type],
        {
          id: uuidv4(),
          type: "",
          amount: "",
        },
      ],
    }));
  };
  const handleDeletePaymentAmount = (type, index) => {
    setPaymentFormInputs((oldVal) => ({
      ...oldVal,
      [type]: oldVal[type].filter((el, i) => i !== index),
    }));
  };
  const handleEditPaymentAmount = (type, name, value, i) => {
    let copy = [...paymentFormInputs[type]];
    copy[i][name] = value;

    setPaymentFormInputs((oldVal) => ({
      ...oldVal,
      [type]: copy,
    }));
  };

  const isValidAmounts = (amountType) => {
    const amounts = paymentFormInputs[amountType];

    if (!amounts?.length) return false;

    let pass = true;
    for (let i = 0; i < amounts.length; i++) {
      const current = amounts[i];
      if (!current.type || !current.amount || parseInt(current.amount) <= 0) {
        pass = false;
        break;
      }
    }
    return pass;
  };
  const handleFormInputsChange = (name, value) => {
    switch (name) {
      case "locationId": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          locationId: value,
          teachersIds: [],
          day: "",
          frequency: "",
          iterations: "",
          startDate: "",
          lessonLength: "",
          availableFor: "",
          lessonTime: "",
          locationStatus: groupClassLocationStatuses.IN_PERSON,
          description: "",
          adminNote: "",
        }));
        return;
      }
      case "teachersIds": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          teachersIds: value,
          day: "",
          frequency: "",
          iterations: "",
          startDate: "",
          lessonLength: "",
          availableFor: "",
          lessonTime: "",
          locationStatus: groupClassLocationStatuses.IN_PERSON,
          description: "",
          adminNote: "",
        }));
        return;
      }
      case "day": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          day: value,
          frequency: "",
          iterations: "",
          startDate: "",
          lessonLength: "",
          availableFor: "",
          lessonTime: "",
          locationStatus: groupClassLocationStatuses.IN_PERSON,
          description: "",
          adminNote: "",
        }));
        return;
      }
      case "frequency": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          frequency: value,
          iterations: "",
          startDate: "",
          lessonLength: "",
          availableFor: "",
          lessonTime: "",
          locationStatus: groupClassLocationStatuses.IN_PERSON,
          description: "",
          adminNote: "",
        }));
        return;
      }
      case "iterations": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          iterations: value,
          startDate: "",
          lessonLength: "",
          availableFor: "",
          lessonTime: "",
          locationStatus: groupClassLocationStatuses.IN_PERSON,
          description: "",
          adminNote: "",
        }));
        return;
      }
      case "startDate": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          startDate: value,
          lessonLength: "",
          availableFor: "",
          lessonTime: "",
          locationStatus: groupClassLocationStatuses.IN_PERSON,
          description: "",
          adminNote: "",
        }));
        return;
      }
      case "lessonLength": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          lessonLength: value,
          availableFor: "",
          lessonTime: "",
          locationStatus: groupClassLocationStatuses.IN_PERSON,
          description: "",
          adminNote: "",
        }));
        return;
      }

      default: {
        setFormInputs((oldVal) => ({
          ...oldVal,
          [name]: value,
        }));
        return;
      }
    }
  };

  const [loadingSavingGroupClass, setLoadingSavingGroupClass] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const reqs = [
          getTeachers(),

          getTeachersGroupClassDays(),
          getTeachersActivities(),
          getLocations(),
          getAbsences(),
          getSchoolYears(),
        ];

        const [
          teachers = [],
          teachersAvailableDays = [],
          teachersActivities = [],
          locations = [],
          absences = [],
          schoolYears,
        ] = await Promise.all(reqs);

        const teachersWithAdditionalData = teachers?.map((teacher) => {
          const teacherAvailableDays =
            teachersAvailableDays
              .find(({ id }) => id === teacher?.id)
              ?.availableDays?.filter(({ isPaused }) => !isPaused) || [];
          const teacherActivities =
            teachersActivities?.filter(
              ({ userId, usersIds }) =>
                userId === teacher?.id || usersIds?.includes(teacher?.id)
            ) || [];
          return {
            ...teacher,
            teacherAvailableDays,
            teacherActivities,
          };
        });

        setInitialData((oldVal) => ({
          ...oldVal,
          teachers: teachersWithAdditionalData,
          locations,
          absences,
          schoolYears,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  const isValidFrequencyAndIterations = useMemo(() => {
    if (!formInputs.frequency) return false;

    let isValid = false;
    if (formInputs.frequency === groupClassFrequency.ONCE) {
      isValid = true;
    } else {
      isValid = !!formInputs.iterations;
    }

    return isValid;
  }, [formInputs.frequency, formInputs.iterations]);

  // creates the group class
  const handleCreateGroupClass = async () => {
    try {
      //prevent saving the lsn if clicked twice on the btn
      if (loadingSavingGroupClass) return;

      setLoadingSavingGroupClass(true);

      if (!isCompleteGroupClassInfo) {
        toast.warn("Information is incomplete");
        return;
      }

      const {
        teachersIds,
        day,
        frequency,
        iterations,
        startDate,
        lessonLength,
        availableFor: availableForStr,
        lessonTime,
        description,
        adminNote,
        locationId,
        locationStatus,
        title,
      } = formInputs;

      const availableFor = parseInt(availableForStr);
      const lessonDuration = parseInt(lessonLength);

      const lessonStartHr = moment(lessonTime, "hh:mm A").hours();
      const lessonStartMin = moment(lessonTime, "hh:mm A").minutes();

      const lessonStartDate = moment(startDate)
        .set({
          hours: lessonStartHr,
          minutes: lessonStartMin,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
      const lessonEndDate = moment(lessonStartDate)
        .add(lessonDuration, "minutes")
        .toDate();

      const lessonObj = {
        createdBy: user.uid,
        createdAt: new Date(),
        duration: lessonDuration,
        startDate: lessonStartDate,
        locationId: locationId,
        locationStatus: locationStatus,
        type: groupClassLessonType.NORMAL, // the type of the class day whether its a normal class day or an additional day for example
        teachersIds,
      };

      const classes = getGroupClassClassesByFromDates({
        classObj: lessonObj,
        classDates: recurringDates,
      });

      const groupClassObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        status: groupClassStatuses.ACTIVE,
        availableFor,
        // groupClassStudentFee: 100,
        // privateStudentFee: 90,
        title,
        adminNote,
        description,
        frequency,
        ...(iterations && {
          iterations,
        }),
        classes,
      };

      const groupClassRes = await createGroupClass(groupClassObj);
      if (!groupClassRes.id) {
        toast.error("Couldnt create group class");
        return;
      }

      let paymentOptionObjArr = [];
      if (isAvailableForCurrentStudents || isAvailableForAll) {
        if (isScheduledPayment || isBothTypePayment) {
          const optionPayments =
            paymentFormInputs[
              paymentAmountTypes.CURRENT_STUDENT_SCHEDULED_AMOUNTS
            ];
          const paymentOptionObj = {
            groupClassId: groupClassRes.id,
            createdAt: new Date(),
            paymentType: groupClassPaymentTypes.SCHEDULED,
            forStudentType: groupClassPaymentStudentTypes.CURRENT_STUDENT,

            // scheduled type only
            interval: parseInt(paymentFormInputs.interval), // weekly
            intervalCount: parseInt(paymentFormInputs.intervalCount), // each 3 months
            timesToRun: {
              type: paymentFormInputs.timesToRun.type, // ends after number amount of payments
              ...(paymentFormInputs.timesToRun.amount && {
                amount: paymentFormInputs.timesToRun.amount,
              }),
            },

            optionPayments,
          };

          paymentOptionObjArr.push(paymentOptionObj);
        }
        if (isOneTimeTypePayment || isBothTypePayment) {
          const optionPayments =
            paymentFormInputs[
              paymentAmountTypes.CURRENT_STUDENT_DIRECT_AMOUNTS
            ];

          const paymentOptionObj = {
            groupClassId: groupClassRes.id,
            createdAt: new Date(),
            paymentType: groupClassPaymentTypes.ONE_TIME,
            forStudentType: groupClassPaymentStudentTypes.CURRENT_STUDENT,

            optionPayments,
          };

          paymentOptionObjArr.push(paymentOptionObj);
        }
        if (isFreeTypePayment) {
          const paymentOptionObj = {
            groupClassId: groupClassRes.id,
            createdAt: new Date(),
            paymentType: groupClassPaymentTypes.FREE,
            forStudentType: groupClassPaymentStudentTypes.CURRENT_STUDENT,
          };

          paymentOptionObjArr.push(paymentOptionObj);
        }
      }
      if (isAvailableForPublic || isAvailableForAll) {
        if (isScheduledPayment || isBothTypePayment) {
          const optionPayments =
            paymentFormInputs[paymentAmountTypes.PUBLIC_SCHEDULED_AMOUNTS];

          const paymentOptionObj = {
            groupClassId: groupClassRes.id,
            createdAt: new Date(),
            paymentType: groupClassPaymentTypes.SCHEDULED,
            forStudentType: groupClassPaymentStudentTypes.NEW_STUDENT,

            // scheduled type only
            interval: parseInt(paymentFormInputs.interval), // weekly
            intervalCount: parseInt(paymentFormInputs.intervalCount), // each 3 months
            timesToRun: {
              type: paymentFormInputs.timesToRun.type, // ends after number amount of payments
              ...(paymentFormInputs.timesToRun.amount && {
                amount: paymentFormInputs.timesToRun.amount,
              }),
            },

            optionPayments,
          };

          paymentOptionObjArr.push(paymentOptionObj);
        }
        if (isOneTimeTypePayment || isBothTypePayment) {
          const optionPayments =
            paymentFormInputs[paymentAmountTypes.PUBLIC_DIRECT_AMOUNTS];

          const paymentOptionObj = {
            groupClassId: groupClassRes.id,
            createdAt: new Date(),
            paymentType: groupClassPaymentTypes.ONE_TIME,
            forStudentType: groupClassPaymentStudentTypes.NEW_STUDENT,

            optionPayments,
          };

          paymentOptionObjArr.push(paymentOptionObj);
        }
        if (isFreeTypePayment) {
          const paymentOptionObj = {
            groupClassId: groupClassRes.id,
            createdAt: new Date(),
            paymentType: groupClassPaymentTypes.FREE,
            forStudentType: groupClassPaymentStudentTypes.NEW_STUDENT,
          };

          paymentOptionObjArr.push(paymentOptionObj);
        }
      }
      // console.log({ groupClassObj, paymentOptionObjArr });
      const paymentOptionsReqs = paymentOptionObjArr.map((obj) =>
        createGroupClassPaymentOption(obj)
      );

      await Promise.all(paymentOptionsReqs);

      toast.success("Group Class Created Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingSavingGroupClass(false);
    }
  };

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears || !initialData.locations?.length) return [];
    const locationId = initialData.locations.find(
      ({ systemID }) => systemID === MAIN_LOCATION_SYSTEM_ID
    )?.id;

    const combinedBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      locationId
    );

    return combinedBreaks;
  }, [initialData]);

  const selectedTeachers = useMemo(() => {
    if (!initialData.teachers.length) {
      return [];
    }
    return formInputs.teachersIds.map((teacherId) =>
      initialData.teachers.find(({ id }) => id === teacherId)
    );
  }, [formInputs, initialData]);

  const selectedTeacherDays = useMemo(() => {
    if (
      !selectedTeachers ||
      !formInputs.startDate ||
      !formInputs.day ||
      !formInputs.lessonLength ||
      !formInputs.availableFor ||
      !isValidFrequencyAndIterations ||
      !formInputs.locationId
    )
      return [];

    const teachersWithActivitiesAndAvailableDays = selectedTeachers.map(
      (teacher) => ({
        id: teacher.id,
        teacherActivities: teacher.teacherActivities,
        teacherAvailableDays: teacher.teacherAvailableDays,
      })
    );
    let teacherDaysWithTimeSlots;
    const { mergedTeacherDays, recurringDates } =
      getMultiDayTeachersDaysAvailabilities({
        teachersWithActivitiesAndAvailableDays,
        lessonDuration: parseInt(formInputs.lessonLength), // intm
        requestedStartDate: formInputs.startDate, // date
        schoolBreaks: combinedSchoolBreaks, // []
        absences: initialData.absences,
        frequency: formInputs.frequency,
        numberOfIterations: formInputs.iterations,
        locationId: formInputs.locationId,
      });
    teacherDaysWithTimeSlots = mergedTeacherDays;
    setRecurringDates(recurringDates || []);

    return teacherDaysWithTimeSlots;
  }, [
    selectedTeachers,
    formInputs.lessonLength,
    isValidFrequencyAndIterations,
    formInputs.frequency,
    formInputs.iterations,
    formInputs.locationId,
    formInputs.startDate,
    formInputs.teachersIds,
    formInputs.availableFor,
    initialData,
    combinedSchoolBreaks,
  ]);

  const filteredTeachers = useMemo(() => {
    const filtered = initialData.teachers.filter((teacher) => {
      const teacherLocations =
        teacher.teacherAvailableDays?.map(({ location }) => location) || [];
      const sameLocation = teacherLocations.includes(formInputs.locationId);

      return sameLocation;
    });

    return filtered;
  }, [initialData, formInputs.locationId]);
  const isAvailableForAll = useMemo(() => {
    return parseInt(formInputs.availableFor) === groupClassAvailableForMap.ALL;
  }, [formInputs.availableFor]);
  const isAvailableForPublic = useMemo(() => {
    return (
      parseInt(formInputs.availableFor) === groupClassAvailableForMap.PUBLIC
    );
  }, [formInputs.availableFor]);
  const isAvailableForCurrentStudents = useMemo(() => {
    return (
      parseInt(formInputs.availableFor) ===
      groupClassAvailableForMap.CURRENT_STUDENTS
    );
  }, [formInputs.availableFor]);
  const isScheduledPayment = useMemo(() => {
    return (
      parseInt(paymentFormInputs.paymentType) ===
      groupClassPaymentTypes.SCHEDULED
    );
  }, [paymentFormInputs.paymentType]);
  const isBothTypePayment = useMemo(() => {
    return (
      parseInt(paymentFormInputs.paymentType) === groupClassPaymentTypes.BOTH
    );
  }, [paymentFormInputs.paymentType]);
  const isOneTimeTypePayment = useMemo(() => {
    return (
      parseInt(paymentFormInputs.paymentType) ===
      groupClassPaymentTypes.ONE_TIME
    );
  }, [paymentFormInputs.paymentType]);

  const isFreeTypePayment = useMemo(() => {
    return (
      parseInt(paymentFormInputs.paymentType) === groupClassPaymentTypes.FREE
    );
  }, [paymentFormInputs.paymentType]);

  const showAvailabilities =
    !!formInputs.day &&
    !!formInputs.startDate &&
    !!selectedTeachers.length &&
    !!formInputs.lessonLength &&
    !!formInputs.availableFor;

  const showSaveBtn =
    !!formInputs.teachersIds.length &&
    !!formInputs.day &&
    !!formInputs.startDate &&
    !!formInputs.lessonLength &&
    !!formInputs.availableFor &&
    !!formInputs.lessonTime;

  const passOneTimePayment = useMemo(() => {
    if (!isBothTypePayment && !isOneTimeTypePayment) {
      return true;
    }

    const passCurrent =
      !!paymentFormInputs[paymentAmountTypes.CURRENT_STUDENT_DIRECT_AMOUNTS]
        ?.length &&
      isValidAmounts(paymentAmountTypes.CURRENT_STUDENT_DIRECT_AMOUNTS);

    const passPublic =
      !!paymentFormInputs[paymentAmountTypes.PUBLIC_DIRECT_AMOUNTS]?.length &&
      isValidAmounts(paymentAmountTypes.PUBLIC_DIRECT_AMOUNTS);

    let pass = false;
    if (isAvailableForCurrentStudents) {
      pass = passCurrent;
    } else if (isAvailableForPublic) {
      pass = passPublic;
    } else if (isAvailableForAll) {
      pass = passPublic && passCurrent;
    }
    return pass;
  }, [
    paymentFormInputs,
    isAvailableForCurrentStudents,
    isAvailableForPublic,
    isAvailableForAll,
  ]);
  const passScheduledPayment = useMemo(() => {
    if (!isBothTypePayment && !isScheduledPayment) {
      return true;
    }

    let isValidTimeToRun = true;
    if (!paymentFormInputs.timesToRun?.type) {
      isValidTimeToRun = false;
    } else if (
      paymentFormInputs.timesToRun?.type === scheduledPaymentTimesToRun.ENDS
    ) {
      isValidTimeToRun = !!paymentFormInputs.timesToRun?.amount;
    }

    const passCurrent =
      !!paymentFormInputs[paymentAmountTypes.CURRENT_STUDENT_SCHEDULED_AMOUNTS]
        ?.length &&
      isValidAmounts(paymentAmountTypes.CURRENT_STUDENT_SCHEDULED_AMOUNTS);

    const passPublic =
      !!paymentFormInputs[paymentAmountTypes.PUBLIC_SCHEDULED_AMOUNTS]
        ?.length && isValidAmounts(paymentAmountTypes.PUBLIC_SCHEDULED_AMOUNTS);

    if (
      !paymentFormInputs.interval ||
      !paymentFormInputs.intervalCount ||
      !isValidTimeToRun
    ) {
      return false;
    }

    let pass = false;
    if (isAvailableForCurrentStudents) {
      pass = passCurrent;
    } else if (isAvailableForPublic) {
      pass = passPublic;
    } else if (isAvailableForAll) {
      pass = passCurrent && passPublic;
    }
    return pass;
  }, [
    paymentFormInputs,
    isAvailableForCurrentStudents,
    isAvailableForPublic,
    isAvailableForAll,
  ]);
  const isCompleteGroupClassInfo = useMemo(() => {
    const passFormInfo =
      !!formInputs.title &&
      !!formInputs.locationId &&
      !!formInputs.teachersIds?.length &&
      !!weekDays.find(({ id }) => id === parseInt(formInputs.day)) &&
      isValidFrequencyAndIterations &&
      !!formInputs.startDate &&
      !!formInputs.lessonLength &&
      !!formInputs.availableFor &&
      !!formInputs.lessonTime &&
      !!formInputs.locationStatus;

    return passFormInfo && passScheduledPayment && passOneTimePayment;
  }, [formInputs, passScheduledPayment, passOneTimePayment]);

  return {
    initialData,
    loadingInitialData,
    formInputs,
    handleFormInputsChange,
    loadingSavingGroupClass,
    handleCreateGroupClass,
    selectedTeachers,
    selectedTeacherDays,
    showAvailabilities,
    showSaveBtn,
    filteredTeachers,
    isValidFrequencyAndIterations,
    currentView,
    setCurrentView,
    paymentFormInputs,
    handlePaymentFormInputChange,
    isScheduledPayment,
    isOneTimeTypePayment,
    isFreeTypePayment,
    isBothTypePayment,
    isCompleteGroupClassInfo,
    isAvailableForAll,
    isAvailableForCurrentStudents,
    isAvailableForPublic,
    handleAddNewPaymentAmount,
    handleDeletePaymentAmount,
    handleEditPaymentAmount,
  };
};

export default useCreateGroupClass;
