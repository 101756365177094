import React, { useEffect } from "react";
import "./style.css";

function Subscribe() {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "signupScript";
    script.src =
      "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js";
    script.async = true;
    script.defer = true;
    const script2 = document.createElement("script");
    script2.innerHTML = `var _ctct_m = "cea6e621975c1808a73520a36ffe7de6"`;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    const interval = setInterval(() => {
      var el = document.getElementById("email_address_0");
      if (el) {
        document.getElementById("email_address_0").placeholder = "EMAIL";
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      const el = document.getElementById("email_address_0");
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  });
  return (
    <>
      <div
        className="ctct-inline-form subscribe"
        data-form-id="747991f6-e6d2-4b51-ad2d-d9ee83c2d1ea"
      ></div>
    </>
  );
}

export default Subscribe;
