import { useCallback, useState } from "react";
import { useTeachersTableData } from "../../../hooks";
import { filterUnusedPrograms } from "../../../utils";

import { StyledTable } from "./styled";
import TeacherProfileModal from "./TeacherProfileModal";
import teachersTableColumns from "./teachersTableColumns";

const TeachersTable = ({
  onTeacherSelect,
  selectedTeacher,
  currentInstruments,
  currentProgram,
}) => {
  const { teachers, instruments } = useTeachersTableData();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentTeacher, setCurrentTeacher] = useState(null);

  const handleTeacherModalOpen = useCallback(
    (teacherId) => {
      const selectedTeacher = teachers?.find(
        (teacher) => teacher?.id === teacherId
      );
      const teacherIndex = teachers?.findIndex(
        (teacher) => teacher?.id === teacherId
      );

      setCurrentTeacher({
        ...selectedTeacher,
        instruments: instruments[teacherIndex]?.map(
          (inst) => inst?.instrument?.name
        ),
        programs: filterUnusedPrograms(
          instruments[teacherIndex]?.map((inst) => inst?.program?.name)
        ),
      });
      setModalOpen(true);
    },
    [teachers, instruments]
  );

  const teacherModalToggle = useCallback(() => {
    setModalOpen((prevState) => !prevState);
  }, []);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      onTeacherSelect(selectedRows[0].key);
    },
    getCheckboxProps: (record) => ({
      value: selectedTeacher,
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
    selectedRowKeys: [selectedTeacher],
  };

  const columns = teachersTableColumns({
    onNameClick: handleTeacherModalOpen,
  });

  const dataSource = teachers
    .map((teacher, index) => {
      const { fullName, id, imageUrl } = teacher;
      const teacherInstruments = instruments[index]?.map(
        (inst) => inst?.instrument?.name
      );
      const teacherPrograms = filterUnusedPrograms(
        instruments[index]?.map((inst) => inst?.program?.name)
      );

      if (
        (!currentInstruments?.length ||
          teacherInstruments?.some((instrument) =>
            currentInstruments?.includes(instrument?.toLowerCase())
          )) &&
        (!currentProgram || teacherPrograms?.includes(currentProgram))
      ) {
        return {
          key: id,
          name: fullName,
          instrument: teacherInstruments,
          program: teacherPrograms,
          imageUrl,
        };
      }

      return null;
    })
    .filter(Boolean);

  return (
    <>
      <StyledTable
        loading={!teachers.length || !instruments.length}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={false}
      />
      <TeacherProfileModal
        isOpen={modalOpen}
        toggle={teacherModalToggle}
        instruments={currentTeacher?.instruments}
        programs={currentTeacher?.programs}
        teacher={currentTeacher}
      />
    </>
  );
};

export default TeachersTable;
