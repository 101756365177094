import React from "react";
import SettingsRow from "src/components/common/SettingsRow";
import { FullPageSpinner } from "src/utils/shared/shared";
import {
  adminsSettingsRows,
  studentsSettingsRows,
  superAdminsSettingsRows,
  teachersSettingsRows,
} from "../constants";

const SettingsContent = ({ hookData }) => {
  const {
    initialData,
    loadingInitialData,
    isSettingDisabled,
    handleDisabledSettingsListChange,
  } = hookData;

  if (loadingInitialData) {
    return <FullPageSpinner height="85vh" />;
  }

  return (
    <div className="ps-3 pe-3">
      <div>
        <div className="mb-5">
          <h3 className="fw-bold">Teachers</h3>
        </div>
        <div>
          {teachersSettingsRows.map((row) => {
            const { description, propName } = row;
            const isDisabled = isSettingDisabled(propName);
            return (
              <div key={propName}>
                <SettingsRow
                  description={description}
                  isOn={!isDisabled}
                  onChange={() => handleDisabledSettingsListChange(propName)}
                />
                <hr />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-5">
        <div className="mb-5">
          <h3 className="fw-bold">Students</h3>
        </div>
        <div>
          {studentsSettingsRows.map((row) => {
            const { description, propName } = row;
            const isDisabled = isSettingDisabled(propName);
            return (
              <div key={propName}>
                <SettingsRow
                  description={description}
                  isOn={!isDisabled}
                  onChange={() => handleDisabledSettingsListChange(propName)}
                />
                <hr />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-5">
        <div className="mb-5">
          <h3 className="fw-bold">Admins</h3>
        </div>
        <div>
          {adminsSettingsRows.map((row) => {
            const { description, propName } = row;
            const isDisabled = isSettingDisabled(propName);
            return (
              <div key={propName}>
                <SettingsRow
                  description={description}
                  isOn={!isDisabled}
                  onChange={() => handleDisabledSettingsListChange(propName)}
                />
                <hr />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-5">
        <div className="mb-5">
          <h3 className="fw-bold">Super Admins</h3>
        </div>
        <div>
          {superAdminsSettingsRows.map((row) => {
            const { description, propName } = row;
            const isDisabled = isSettingDisabled(propName);
            return (
              <div key={propName}>
                <SettingsRow
                  description={description}
                  isOn={!isDisabled}
                  onChange={() => handleDisabledSettingsListChange(propName)}
                />
                <hr />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SettingsContent;
