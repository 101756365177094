import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useLibraryData = ({ user, libraryChosenItems }) => {
  const { getUserLibraryItems } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    libraryItems: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  // array of ids of selected library items
  const [selectedItems, setSelectedItems] = useState([]);

  const isItemSelected = (itemId) => {
    const isSelected = selectedItems.includes(itemId);
    return isSelected;
  };
  const handleSelectedItemsChange = (newItemId) => {
    const isFound = isItemSelected(newItemId);
    if (isFound) {
      setSelectedItems((oldVal) => oldVal.filter((id) => id !== newItemId));
    } else {
      setSelectedItems((oldVal) => [...oldVal, newItemId]);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const libraryItems = await getUserLibraryItems(user.uid);
        const notDeletedItems = libraryItems.filter(
          ({ isDeleted }) => !isDeleted
        );
        setInitialData({
          libraryItems: notDeletedItems,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    if (libraryChosenItems?.length) {
      setSelectedItems(libraryChosenItems.map(({ id }) => id));
    }
    fetchInitialData();
  }, []);
  return {
    initialData,
    loadingInitialData,
    selectedItems,
    handleSelectedItemsChange,
    isItemSelected,
  };
};

export default useLibraryData;
