import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import RouteContent from "../../../common/RouteContent";
import styled from "styled-components";
import NotesSection from "./NotesSection";
import Calendar from "react-calendar";
import ButtonsSection from "./ButtonsSection";

const SectionContainer = styled.section`
  padding: ${({ padding }) => (padding ? padding : "40px")};
  padding-top: 30px;
  border-radius: 40px;
  box-shadow: 0px 6px 15px #00000008;
  font-weight: 900;
  min-height: ${(props) => props.minHeight || "351px"};
`;
const AdminDashboard = () => {
  const [dateValue, setDateValue] = useState(new Date());

  return (
    <RouteContent title="Dashboard">
      <Container className="text-dark">
        <Row>
          <Col className="col-8">
            <SectionContainer minHeight="500px">
              <NotesSection />
            </SectionContainer>
          </Col>
          <Col>
            <Row>
              <Col>
                <SectionContainer padding="0px" minHeight="250px">
                  <Calendar
                    calendarType="US"
                    value={dateValue}
                    onChange={setDateValue}
                  />
                </SectionContainer>
                <ButtonsSection />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </RouteContent>
  );
};

export default AdminDashboard;
