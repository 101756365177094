import styled from "styled-components";
import { SearchAlt2 } from "styled-icons/boxicons-regular";

export const Container = styled.div`
  padding: 10px;
`;

export const SearchBar = styled.input`
  border-radius: 20px;
  padding: 10px 15px;
  width: 420px;
  border: 2px solid #dddddd;
  outline: none;
  transition: ease-in-out 0.1s;
  cursor: pointer;
  color: #979797;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    border: 2px solid #681e46;
  }
`;
export const SearchIcon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: transparent;

  &:hover * {
    color: #ff008a;
  }
`;
