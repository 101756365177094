import React from "react";
import { useState } from "react";
import { Dropdown, DropdownToggle } from "reactstrap";
import DropdownContent from "./DropdownContent";
import { CustomDropdownMenu } from "./styled";

const FeedItemDropdownMenu = ({
  dropdownToggleContainer: DropdownToggleContainer,
  dropdownToggleIcon: DropdownToggleIcon,
  feedItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = async () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const dropdownToggleMarkup = () => (
    <DropdownToggleContainer>
      <DropdownToggleIcon />
    </DropdownToggleContainer>
  );
  return (
    <div>
      <Dropdown isOpen={isOpen} toggle={toggleDropdown} direction={"down"}>
        <DropdownToggle tag="span">{dropdownToggleMarkup()}</DropdownToggle>
        <CustomDropdownMenu>
          <DropdownContent
            feedItem={feedItem}
            toggleDropdown={toggleDropdown}
          />
        </CustomDropdownMenu>
      </Dropdown>
    </div>
  );
};

export default FeedItemDropdownMenu;
