import React, { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import { TimeSpan } from "../styled";
import { prepareAvailabilities } from "../helperFns";

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InfoText1 = styled.p`
  margin: 0;
`;
const InfoText2 = styled.p`
  color: #710373;
  font-size: 0.8rem;
  margin: 0;
`;

const TeacherRow = ({
  teacher,
  requestedDate,
  selectedSlotInfo,
  handleSelectedSlotInfoChange,
}) => {
  const {
    fullName,
    teacherDaysWithAvailabilitiesData,
    makeupOpeningsWithAvailabilitiesData,
  } = teacher;

  // we pick first item of the array bec they all have the same date
  const formattedDate = moment(requestedDate).format("dddd, MMMM D, YYYY");

  // flatting and adding "scheduleType", "location","isVirtualOnly" props to each time chunk + grouping all chunks by location
  const chunksGroupedByLocation = useMemo(
    () =>
      prepareAvailabilities(
        teacherDaysWithAvailabilitiesData,
        makeupOpeningsWithAvailabilitiesData
      ),
    [teacherDaysWithAvailabilitiesData, makeupOpeningsWithAvailabilitiesData]
  );
  return (
    <>
      <SectionContainer>
        <div className="w-50">
          <InfoText1>{fullName}</InfoText1>
          <InfoText2>{formattedDate}</InfoText2>
        </div>
        <div className="w-50">
          {Object.keys(chunksGroupedByLocation).map((locationId, i) => {
            const currentLocationTimeChunks =
              chunksGroupedByLocation[locationId];
            // we use first element bec we know that all elements have the same location + isVirtualOnly
            const location = currentLocationTimeChunks?.[0]?.location;
            const locationName = currentLocationTimeChunks?.[0]?.isVirtualOnly
              ? "Virtual Only"
              : location?.name;
            return (
              <div key={i}>
                {i !== 0 && <hr />}
                <div className="mb-4">
                  <span className="d-block text-center mb-3">
                    {locationName}
                  </span>
                  <div className="d-flex flex-wrap">
                    {currentLocationTimeChunks?.map((chunk) => {
                      const parsedTime = moment(chunk.startTime, ["hh:mm A"]);
                      const hours = parsedTime.get("hour");
                      const minutes = parsedTime.get("minute");
                      const startTimeDate = moment(requestedDate)
                        .set({
                          hour: hours,
                          minute: minutes,
                          second: 0,
                          millisecond: 0,
                        })
                        .toDate();

                      // checks if selected time slot
                      const isSelected =
                        moment(startTimeDate).isSame(
                          moment(selectedSlotInfo?.date)
                        ) &&
                        selectedSlotInfo?.teacher?.id === teacher?.id &&
                        selectedSlotInfo?.location?.id === location?.id;

                      return (
                        <TimeSpan
                          onClick={() =>
                            handleSelectedSlotInfoChange({
                              date: startTimeDate,
                              teacher: teacher,
                              location,
                              scheduleType: chunk.scheduleType,
                              isVirtualOnly: chunk.isVirtualOnly,
                            })
                          }
                          selected={isSelected}
                        >
                          {chunk.startTime}
                        </TimeSpan>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </SectionContainer>
    </>
  );
};

export default TeacherRow;
