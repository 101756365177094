import React from "react";
import { concertProgramTypes } from "src/constants/concertEnum";
import { combineArrayToStr } from "src/utils/helpers";
import { CustomCheckboxContainer } from "src/utils/shared/styled";

const StudentItem = ({
  student,
  isStudentSelected,
  handleSelectedStudentsChange,
  privateLessons,
  instruments,
  programType,
}) => {
  const { id, fullName } = student;
  const studentPLs = privateLessons?.filter(
    ({ studentId }) => studentId === student.id
  );
  const uniqueInstrumentsIds = [
    ...new Set(studentPLs.map(({ instrumentId }) => instrumentId)),
  ];
  const instrumentNames = uniqueInstrumentsIds.map(
    (instrumentId) =>
      instruments.find((instrument) => instrument.id === instrumentId)?.name
  );
  const instrumentNamesStr = combineArrayToStr(instrumentNames || []);
  const isSpecialProgram = programType === concertProgramTypes.SPECIAL_PROGRAM;
  return (
    <div className="d-flex mb-3" style={{ gap: 20 }}>
      <CustomCheckboxContainer>
        <input
          checked={isStudentSelected(id)}
          id={id}
          onChange={() => handleSelectedStudentsChange({ studentId: id })}
          type="checkbox"
        />
      </CustomCheckboxContainer>
      <label htmlFor={id} style={{ cursor: "pointer" }}>
        {isSpecialProgram ? `${fullName} (${instrumentNamesStr})` : fullName}
      </label>
    </div>
  );
};

export default StudentItem;
