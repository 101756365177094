import { getDocs } from 'firebase/firestore';
import { inject, observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { compose } from 'recompose';
import { FirebaseContext } from 'src/components/Firebase';
import { UserRole } from 'src/constants/UserRoleEnum';
import { ParentDashboardContext } from 'src/contexts/ParentDashboardContext';

const ParentDashboardProvider = ({ children, UserStore }) => {
    const firebase = useContext(FirebaseContext);
    const [isLoading, setIsLoading] = useState(true);
    const [studentsList, setStudentsList] = useState([]);
    const [selectedStudentIndex, setSelectedStudentIndex] = useState(0);
    useEffect(() => {
        (async () => {
            if (UserStore.user?.role === UserRole.PARENT) {
                const students = [];
                const primaryEmailDocs = await getDocs(firebase.getParentStudents(UserStore.user.primaryEmail, "primaryContactEmail"));
                const secondaryEmailDocs = await getDocs(firebase.getParentStudents(UserStore.user.primaryEmail, "secondaryContactEmail"));
                primaryEmailDocs.forEach((doc) => {
                    students.push({
                        id: doc.id,
                        uid: doc.id,
                        ...doc.data(),
                    });
                });
                secondaryEmailDocs.forEach((doc) => {
                    students.push({
                        id: doc.id,
                        uid: doc.id,
                        ...doc.data(),
                    });
                });
                setStudentsList(students);
            }
            setIsLoading(false);
        })();
    }, [UserStore?.user?.role]);
    const parentDashboardContextValue = {
        isLoading,
        setIsLoading,
        studentsList,
        selectedStudentIndex,
        setSelectedStudentIndex,
    };
    if (isLoading) {
        return (
            <Spinner/>
        )
    }
    return (
        <ParentDashboardContext.Provider value={parentDashboardContextValue}>
            {children}
        </ParentDashboardContext.Provider>
    )
}
export default compose(inject("UserStore"), observer)(ParentDashboardProvider);