import React from "react";
import Select from "react-select";

const LocationSelect = ({
  selectedLocations = [],
  locations = [],
  onSelectLocation = () => {},
  placeholder,
  withValue = true,
  width,
}) => {
  const options = locations.map(({ id, name }) => ({ label: name, value: id }));
  const defaultValue = options.filter(({ value }) =>
    selectedLocations.includes(value)
  );
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      // marginLeft: 20,
      backgroundColor: "#f7f7f7",
      color: "#151515",
    }),

    control: (provided) => ({
      ...provided,
      // marginLeft: 20,
      backgroundColor: "#681E460D",
      color: "#505050",
      borderRadius: 30,
      border: "2px solid #681E46 !important",
      outline: "none",
      width: width || "320px",
    }),

    singleValue: (provided, state) => {
      return { ...provided, backgroundColor: "#e5e5e5" };
    },
    multiValue: (provided) => {
      return {
        ...provided,
        backgroundColor: "#e5e5e5",
        borderRadius: "10px",
        border: "1px solid #777",
      };
    },
  };
  return (
    <Select
      defaultValue={selectedLocations}
      value={withValue ? selectedLocations : undefined}
      isMulti={true}
      name="locations"
      options={options}
      styles={customStyles}
      onChange={onSelectLocation}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder={placeholder || "Select..."}
    />
  );
};
export default LocationSelect;
