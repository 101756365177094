import React from "react";
import { CustomInput, CustomSelectField } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";

const InstrumentSection = ({ instruments, value }) => {
  const instrumentName = instruments?.find(({ id }) => id === value)?.name;
  return (
    <>
      <CustomLabel className="mb-3">Instrument</CustomLabel>
      <CustomInput
        name="instrumentId"
        value={instrumentName || "N/A"}
        disabled
        bgColor="#eeeeeee6"
        width="100%"
      />
    </>
  );
};

export default InstrumentSection;
