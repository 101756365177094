import styled, { css } from "styled-components";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LabelInput } from "../index";
import React from "react";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

export const LibraryModal = styled(Modal)`
  & .modal-content {
    border-radius: 1.3rem;
    border: none !important;
    padding: 21px;
    position: relative;
    z-index: 999999;
  }
`;
export const LibraryModalHeader = styled(ModalHeader)`
  border: none;

  h5 {
    font-weight: 900;
    padding-top: 5px;
    font-size: 26px;
    color: #151515;
  }

  button {
    border: 1px solid #707070;
    padding: 3px 7px;
    font-size: 20px;
    border-radius: 30px;
    height: 40px;
    width: 40px;
    background-color: #681e460d;
    color: #707070;

    &:hover {
      background-color: #5a1a3d1d;
    }

    &:active {
      background-color: #42132d2d;
    }
  }
`;
export const LibraryModalBody = styled(ModalBody)`
  padding-top: 0px !important;
  // padding: 0 50px;
`;
export const LibraryModalFooter = styled(ModalFooter)`
  border: none !important;
`;
export const LibraryModalFileUpload = styled.div`
  background: #681e460d;
  border: 1px solid #dddddd;
  border-radius: 15px;
  padding: 9px 20px;
  font-size: 16px;
  height: 82px;
  margin-bottom: 9px;

  .label-container {
    height: 100%;
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }

  &:focus {
    border: 2px solid #dddddd !important;
  }
`;

export const LibraryFilePreview = styled.div`
  border: 1px solid #151515;
  border-radius: 30px;
  color: #151515;
  padding: 9px 13px;
  font-size: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #f0f0f0;
  }

  i {
    margin-left: 30px;
  }
`;
export const LibraryModalButton = styled.button`
  background-color: #681e46;
  box-shadow: 0 2px 10px #0000000a;
  border-radius: 30px;
  width: ${({ width }) => width || "245px"};
  height: 50px;
  font-size: 16px;

  &.disabled {
    /* background-color: #979797;
    color: #151515; */
  }

  &:hover {
    background-color: #5a1a3d;
  }

  &:active:not(.disabled) {
    background-color: #42132d;
  }
`;
export const SearchInputContainer = styled.div`
  position: relative;
  /* width: 530px; */
  width: 100%;
  margin-left: auto;
  input {
    color: #151515;
    font-weight: 100;
    font-size: 1rem;

    &:focus + i {
      color: #681e46;
    }
  }

  i {
    color: #979797;
    right: 14px;
    top: 8px;
    font-size: 1rem;
  }

  /* @media (max-width: ${ScreenSize.XXL}px) {
    width: 320px;
  }
  @media (max-width: ${ScreenSize.LG}px) {
    width: 250px;
  } */
  @media (max-width: ${ScreenSize.SM}px) {
    width: 100%;
  }
`;

export const LibraryStudentsListContainer = styled.div`
  width: 25%;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  margin-right: 5px;
  box-shadow: 0 0px 6px #000000d1;
  border-radius: 30px;
  padding: 20px 14px;
  max-height: 490px;
  overflow-y: auto;
`;
export const LibraryTeachersListContainer = styled.div`
  width: 30%;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  margin-right: 5px;
  box-shadow: 0 2px 10px #0000000a;
  border-radius: 30px;
  padding: 20px 14px;
  max-height: 490px;
  overflow-y: auto;
`;
export const LibraryLabeledCheckboxContainer = styled.div`
  input[type="checkbox"] {
    width: 26px;
    height: 24px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    accent-color: #681e46;
  }

  label {
    margin-left: 12px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #151515;
  }
`;
export const LibraryStudentRowContainer = styled.div`
  margin-bottom: 12px;
  cursor: pointer;

  input[type="checkbox"] {
    width: 24px;
    height: 22px;
  }
`;
export const AttachmentsItemContainer = styled.div`
  margin-bottom: 12px;
  font-size: 18px;
  height: 37px;

  h3 {
    margin-left: 12px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #151515;
  }
`;
export const AttachmentsListContainer = styled.div`
  flex-grow: 1;
  /* width: 70%; */
  margin-left: 5px;
  box-shadow: 0 2px 10px #0000000a;
  border-radius: 40px;
  padding: 10px 25px;
  max-height: 490px;
  overflow-y: auto;
`;
export const AttachmentToolbarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;
export const AttachmentToolbarItem = styled.div`
  @media (max-width: ${ScreenSize.SM}px) {
    width: 100%;
  }
`;

const truncateStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TextButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
  color: #979797;

  ${({ truncate }) => (truncate ? truncateStyles : {})}

  @media (max-width: ${ScreenSize.SM}px) {
    padding: 5px 5px;
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      color: #151515;
      cursor: pointer;

      &:hover {
        color: #252525;
        background-color: #f5f5f5;
      }

      &:active {
        color: #353535;
        background-color: #f1f1f1;
      }
    `}
  &:active,
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;
export const StyledLink = styled.a`
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 5px;
  color: #151515;

  &:active,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: #252525;
    background-color: #f5f5f5;
  }

  &:active {
    color: #353535;
    background-color: #f1f1f1;
  }
`;
export const LinkViewerContainer = styled.div`
  iframe {
    height: 600px;
    width: 100%;
    border: 1px solid #681e46;
    border-radius: 30px;
    overflow: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
`;
export const PDFViewerContainer = styled.div`
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
export const ImageViewerContainer = styled.div`
  min-height: 70vh;
  background-image: url(${({ image }) => image});
  background-size: contain;
  max-width: 80vw;
  background-position: center;
  background-repeat: no-repeat;
`;
export const PlayerSeekContainer = styled.div`
  width: 70%;

  .total {
    background-color: #ccc;
    height: 2px;
  }

  .played {
    top: -3px;

    .duration {
      background-color: #151515;
      height: 3px;
      width: ${({ played }) => played};
      transition: width 1s linear;
    }

    .indicator {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background-color: #151515;
      transform: translateX(-3px);
    }
  }
`;
export const PlayerControlsContainer = styled.div`
  .controls-btns {
    width: 150px;
  }

  .current-time,
  .duration {
    width: 63px;
  }
`;
export const MediaViewerContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  color: #151515;

  &.video-container {
    padding: 25px;
  }

  .close-container {
    margin-top: -15px;
    margin-right: -10px;

    &.video-close {
      position: absolute;
      right: 0;
      top: 5px;
    }

    button {
      border: 1px solid #707070;
      padding: 3px 7px;
      font-size: 20px;
      border-radius: 30px;
      height: 40px;
      width: 40px;
      background-color: #e7e7e7;
      color: #707070;

      &:hover {
        background-color: #d7d7d7;
      }

      &:active {
        background-color: #c7c7c7;
      }
    }
  }
`;
export const VideoContainer = styled.div`
  border-radius: 30px;
  background-color: #ddd;
  padding: 20px;
  width: 100%;
  height: 400px;
`;
export const MediaPlayerContainer = styled.div`
  //height: 100px;
  border-radius: 30px;
  background-color: #f1f1f1;
  padding: 15px;

  &.video-player {
    background: none;
    padding: 0 50px;
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 50px;

  @media (max-width: ${ScreenSize.LG}px) {
    justify-content: space-between;
  }
`;
export const LabeledRadioContainer = styled.div`
  margin-right: 40px;

  input[type="radio"] {
    height: 19px;
    width: 19px;
    accent-color: #681e46;
  }

  label {
    color: #151515;
    font-size: 16px;
    text-transform: capitalize;
  }
`;
export const LibraryModalInput = styled((props) => (
  <LabelInput {...props} labelColor="#151515" />
))`
  margin-bottom: 21px;
  width: 100% !important;

  & .form-control {
    background: #681e460d;
    border: 1px solid #dddddd;
    border-radius: 15px;
    padding: 9px 20px;
    font-size: 16px;

    &:focus {
      border: 2px solid #dddddd !important;
    }
  }

  label {
    margin-bottom: 16px;
    font-size: 18px;
    margin-left: 0;
  }
`;
