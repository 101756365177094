import { emailNotificationsList } from "src/constants/notificationSettingsEnum";

export const teachersSettingsRows = [
  {
    description: "New Student Enrollment Confirmation",
    propName: emailNotificationsList.newPrivateLessonToTeacher,
  },
  {
    description: "Student Withdrawal",
    propName: emailNotificationsList.privateLessonWithdrawalToTeacher,
  },
  {
    description: "New Trial Lesson Confirmation",
    propName: emailNotificationsList.newTrialLessonToTeacher,
  },
  {
    description: "Daily Schedule Reminder",
    propName: emailNotificationsList.scheduleReminderToTeacher,
  },
  {
    description: "New Payment Confirmation",
    propName: emailNotificationsList.newPaymentToTeacher,
  },
  {
    description: "New SDC Confirmation",
    propName: emailNotificationsList.newSDCToTeacher,
  },
  {
    description: "Teacher absence confirmation",
    propName: emailNotificationsList.absenceConfirmation,
  },
];
export const studentsSettingsRows = [
  {
    description: "New Make Up Lesson Confirmation",
    propName: emailNotificationsList.newMakeUpLessonToStudent,
  },
  {
    description: "Make Up Lessons Reminder",
    propName: emailNotificationsList.makeUpReminderToStudent,
  },
  {
    description: "New Trial Lesson Confirmation",
    propName: emailNotificationsList.newTrialLessonToStudent,
  },
  {
    description: "Trial Lessons Reminder",
    propName: emailNotificationsList.trialLessonReminderToStudent,
  },
  {
    description: "New Lesson Note Confirmation",
    propName: emailNotificationsList.newLessonNoteToStudent,
  },
  {
    description: "Lesson Note Update Confirmation",
    propName: emailNotificationsList.editedLessonNoteToStudent,
  },
  {
    description: "Make Up Request Approved Confirmation",
    propName: emailNotificationsList.approvedMakeupRequestToStudent,
  },
  {
    description: "Make Up Request Declined Confirmation",
    propName: emailNotificationsList.declinedMakeupRequestToStudent,
  },
  {
    description: "Private Lesson New Schedule Reminder",
    propName: emailNotificationsList.newPLScheduleChangeToStudent,
  },
  {
    description: "Group Class Enrollment Confirmation",
    propName: emailNotificationsList.newGroupClassEnrollmentToStudent,
  },
  {
    description: "New SDC Confirmation",
    propName: emailNotificationsList.newSDCToStudent,
  },
];
export const adminsSettingsRows = [
  {
    description: "New Payment Confirmation",
    propName: emailNotificationsList.newPaymentToAdmin,
  },
];
export const superAdminsSettingsRows = [
  {
    description: "New Payment Confirmation",
    propName: emailNotificationsList.newPaymentToSuperAdmin,
  },
  {
    description: "New Paid Make Up Request Confirmation",
    propName: emailNotificationsList.newMakeupRequestToSuperAdmin,
  },
];
