import { useEffect, useState } from "react";
import "./style.css";
import subscribeScript from "./subscribeScript";

import { ModalBody } from "reactstrap";
import { StyledModal, StyledModalHeader } from "../styled";

const SubscribeModal = ({ isOpen, toggle }) => {
  const [initialOpen, setInitialOpen] = useState(true);
  const form = document.querySelector(".modal-subscribe");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialOpen(false);
    }, 0);

    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    subscribeScript(script1, script2);

    if (!form?.children) {
      subscribeScript(script1, script2);
    }

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      clearTimeout(timeout);
    };
  }, [form?.children]);

  return (
    <StyledModal
      isOpen={initialOpen || isOpen}
      centered
      toggle={toggle}
      unmountOnClose={false}
      modalClassName={initialOpen ? "implicit-open" : ""}
      backdropClassName={initialOpen ? "implicit-open" : ""}
    >
      <StyledModalHeader toggle={toggle}>
        Subscribe to our newsletter
      </StyledModalHeader>
      <ModalBody>
        <div
          className="ctct-inline-form subscribe modal-subscribe"
          data-form-id="747991f6-e6d2-4b51-ad2d-d9ee83c2d1ea"
        ></div>
      </ModalBody>
    </StyledModal>
  );
};

export default SubscribeModal;
