import React from "react";
import { weekDays } from "src/constants/weekDays";
import styled from "styled-components";
import TableCell from "./TableCell";

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  height: 400px;
  overflow-y: auto;
  margin-top: 20px;
  @media print {
    overflow-x: hidden;
    overflow-y: hidden;
    zoom: 70%;
    height: 100%;
  }
`;

const Table = styled.table`
  margin-top: 20px;
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #d6d6d6;
  border-style: solid;
  color: #000000;
`;

const THead = styled.thead`
  background-color: #f7f3f3;
  position: sticky;
  top: 0;
`;

const TH = styled.th`
  border-width: 1px;
  border-color: #d6d6d6;
  border-style: solid;
  padding: 30px;
  text-align: center;
`;

const TD = styled.td`
  border-width: 1px;
  border-color: #d6d6d6;
  border-style: solid;
  padding: 30px;
  text-align: center;
`;

const DateAndLoc = styled.div`
  display: none;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media print {
    display: flex;
  }
`;

const WeekGrid = React.forwardRef(
  (
    {
      studios,
      studioUsageData,
      addUsage,
      teachers,
      deleteUsage,
      month,
      year,
      location,
    },
    ref
  ) => {
    const getData = (studio_id, weekday) => {
      if (studioUsageData[studio_id]) {
        if (studioUsageData[studio_id][weekday]) {
          return studioUsageData[studio_id][weekday];
        }
      }
      return null;
    };
    return (
      <div ref={ref}>
        <DateAndLoc>
          {`${month.label} ${year.label}, ${location.label}`}
        </DateAndLoc>
        <TableWrapper>
          <Table>
            <THead>
              <tr>
                <TH></TH>
                <TH>Mon</TH>
                <TH>Tue</TH>
                <TH>Wed</TH>
                <TH>Thu</TH>
                <TH>Fri</TH>
                <TH>Sat</TH>
              </tr>
            </THead>
            <tbody>
              {studios.map((studio) => {
                return (
                  <tr key={studio.id}>
                    <TD style={{ color: "red" }}>{studio.name}</TD>
                    {weekDays.map((weekday, index) => {
                      if (weekday.id !== 0)
                        return (
                          <TableCell
                            key={index}
                            studio_id={studio.id}
                            weekday={weekday.name}
                            data={getData(studio.id, weekday.name) ?? []}
                            addUsage={addUsage}
                            teachers={teachers}
                            deleteUsage={deleteUsage}
                          />
                        );
                      return null;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </div>
    );
  }
);

export default WeekGrid;
