import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { createContext } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { getTimeDiffInMins } from "src/utils/helpers";

const UserNotificationsContext = createContext();

export const UserNotificationsContextProvider = ({
  userId,
  userRole,
  children,
  ...props
}) => {
  const { getUserNotifications, readNotification } = useFirebaseFns();

  // stores the user notifications recieved in the forUsers prop
  const [notificationsForUser, setNotificationsForUser] = useState([]);
  // stores the user notifications recieved in the forRoles prop
  const [notificationsForRole, setNotificationsForRole] = useState([]);

  const updateRealtimeNotificationsForUser = (notifications) => {
    setNotificationsForUser(notifications);
  };

  const updateRealtimeNotificationsForRole = (notifications) => {
    setNotificationsForRole(notifications);
  };

  const readAllUnreadNotifications = async () => {
    const reqs = [];
    unreadNotifications.forEach(({ id }) => {
      reqs.push(readNotification(id, userId));
    });
    if (reqs.length) {
      await Promise.all(reqs);
    }
  };

  useEffect(async () => {
    if (userId && userRole) {
      const fetchUserNotifications = async () => {
        try {
          const { unsubscribe } = await getUserNotifications({
            userId,
            userRole,
            onNewNotificationsForUser: updateRealtimeNotificationsForUser,
            onNewNotificationsForRole: updateRealtimeNotificationsForRole,
          });
          return unsubscribe;
        } catch (err) {
          console.log(err);
        }
      };

      const unsub = await fetchUserNotifications();

      return () => unsub();
    }
  }, [userId, userRole]);

  const userNotifications = useMemo(() => {
    const combinedNotifications = [
      ...notificationsForUser,
      ...notificationsForRole,
    ];
    console.log({
      notificationsForUser,
      notificationsForRole,
      userNotifications,
      userRole,
      userId,
    });

    const uniqueIds = [];
    const uniqueNotifications = combinedNotifications.filter(({ id }) => {
      const isDuplicate = uniqueIds.includes(id);

      if (!isDuplicate) {
        uniqueIds.push(id);
        return true;
      }
      return false;
    });

    const sortedNotifications = uniqueNotifications.sort((a, b) =>
      getTimeDiffInMins(a.createdAt, b.createdAt)
    );
    return sortedNotifications;
  }, [notificationsForUser, notificationsForRole]);

  const unreadNotifications = useMemo(() => {
    return userNotifications.filter(({ readBy }) => !readBy?.includes(userId));
  }, [userNotifications, userId]);

  const contextValues = useMemo(
    () => ({
      userNotifications,
      unreadNotifications,
      readAllUnreadNotifications,
    }),
    [userNotifications, unreadNotifications, readAllUnreadNotifications]
  );

  return (
    <UserNotificationsContext.Provider value={contextValues} {...props}>
      {children}
    </UserNotificationsContext.Provider>
  );
};

export function useUserNotificationsContext() {
  const context = useContext(UserNotificationsContext);

  return context;
}
