import moment from "moment";
import React from "react";
import { ConcertImage, PrimaryText, SecondaryText } from "./styled";
import guitarImg from "src/assets/images/guitar.webp";
import { ModalButton } from "src/utils/shared/styled";
import { ModalButtonWithLoading } from "src/utils/shared/shared";

const DetailsSection = ({
  concert,
  location,

  goToSecondPage,
}) => {
  const { title, date, duration, locationId, attachments } = concert;

  const { url } = attachments?.find(({ type }) => type.includes("image")) || {};
  const formattedDate = moment(date).format("MM/DD/YYYY");
  const formattedTime =
    moment(date).format("hh:mm A") +
    " - " +
    moment(date).add(duration, "minutes").format("hh:mm A");

  const locationName = location?.name;

  return (
    <div>
      <div>
        <ConcertImage src={url || guitarImg} alt="concert-image" />
      </div>
      <div className="mt-4" style={{ flex: 1.5 }}>
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{ gap: "1rem", marginBottom: "1rem" }}
        >
          <div className="">
            <PrimaryText>Title</PrimaryText>
            <SecondaryText>{title}</SecondaryText>
          </div>
          <div>
            <PrimaryText>Location</PrimaryText>
            <SecondaryText>{locationName}</SecondaryText>
          </div>
        </div>
        <div
          className="d-flex justify-content-between flex-wrap"
          style={{ gap: "1rem", marginBottom: "1rem" }}
        >
          <div className="">
            <PrimaryText>Date</PrimaryText>
            <SecondaryText>{formattedDate}</SecondaryText>
          </div>
          <div>
            <PrimaryText>Time</PrimaryText>
            <SecondaryText>{formattedTime}</SecondaryText>
          </div>
        </div>

        <div className="text-center mt-5">
          <ModalButton fontWeight="bold" primary onClick={goToSecondPage}>
            Next
          </ModalButton>
        </div>
      </div>
    </div>
  );
};

export default DetailsSection;
