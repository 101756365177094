import React from "react";
import AbsenceRequestBody from "./AbsenceRequestBody";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "./styled";

const AbsenceRequestModal = ({
  absenceRequestModalData,
  privateLesson,
  onAbsenceRequestSuccess = () => {},
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } =
    absenceRequestModalData;
  return (
    <CustomModal
      size="md"
      // style={{ maxWidth: "950px" }}
      // className="min-w-50 text-dark"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Absence Request</h2>
      </CustomHeader>
      <CustomBody>
        <AbsenceRequestBody
          absenceRequestModalData={absenceRequestModalData}
          privateLesson={privateLesson}
          onAbsenceRequestSuccess={onAbsenceRequestSuccess}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default AbsenceRequestModal;
