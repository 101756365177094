import React from "react";
import LabeledInput from "../../common/LabeledInput";
import { InputContainer } from "../../../styled";
import { CustomInputWithCurrency } from "src/utils/shared/shared";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { CustomLabel } from "src/utils/shared/styled";

const CustomRateItem = ({
  i,
  rate,
  handleCustomPaymentChange,
  handleDeleteCustomPaymentRow,
  customRates,
}) => {
  return (
    <div className="mb-2" key={rate.id}>
      <div className="d-flex align-items-end" style={{ gap: 25 }}>
        <div style={{ flex: 1 }}>
          <LabeledInput
            label="Name"
            type="text"
            value={rate.title}
            onChange={(e) =>
              handleCustomPaymentChange("title", e.target.value, i)
            }
          />
        </div>
        <InputContainer style={{ flex: 1 }}>
          <CustomLabel>Amount:</CustomLabel>
          <CustomInputWithCurrency
            value={rate.value}
            onChange={(e) =>
              handleCustomPaymentChange("value", e.target.value, i)
            }
            width="100%"
          />
        </InputContainer>
        <DeleteOff
          onClick={() => handleDeleteCustomPaymentRow(rate.id)}
          style={{ cursor: "pointer", flex: 0.5 }}
          width="25px"
          height="25px"
        />
      </div>
      {i < customRates?.length - 1 && <hr />}
    </div>
  );
};

export default CustomRateItem;
