import React from "react";
import "./style.css";
import NavMenu from "../common/NavMenu";
import Content from "./Content";
import CoursesTable from "./CoursesTable";
import MusicRecitals from "./MusicRecitals";
import BookClassToday from "../common/BookClassToday";
import Footer from "../common/Footer";

// images
import orchestra from "./images/orchestra.svg";
import guitar1 from "./images/guitar-1.svg";
import guitar2 from "./images/guitar-2.svg";
import piano from "./images/piano.svg";

function PrivateInstructions() {
  // I couldn't understand for what here exists there three images...
  return (
    <main className="private-instructions-container">
      <NavMenu />
      <div className="private-instructions">
        <div className="home-page-container">
          {/* <img
            loading="lazy"
            className="image-orchestra"
            src={orchestra}
            alt="orchestra"
          />
          <img loading="lazy" className="image-piano" src={piano} alt="piano" />
          <img
            loading="lazy"
            className="image-guitar-1"
            src={guitar1}
            alt="guitar"
          />
          <img
            loading="lazy"
            className="image-guitar-2"
            src={guitar2}
            alt="guitar"
          /> */}
          <Content />
          <CoursesTable />
          <MusicRecitals />
          <BookClassToday />
          <Footer />
        </div>
      </div>
    </main>
  );
}

export default PrivateInstructions;
