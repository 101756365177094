import React from "react";
import UserNavSection from "../../../UserNavSection";
import { DateFiltersSection, LocationFilter } from "./components";
import { CustomLabel } from "src/utils/shared/styled";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const FiltersSection = ({ hookData, reportDataSectionRef }) => {
  const {
    currentUserType,
    setCurrentUserType,
    selectedDateFilterType,
    setSelectedDateFilterType,
    selectedFilterObj,
    handleFilterObjChange,
    initialData,
    selectedLocationId,
    setSelectedLocationId,
    loadReportData,
    loadingReportData,
  } = hookData;

  const scrollToReportDataSection = () => {
    if (!reportDataSectionRef.current) return;
    reportDataSectionRef.current.scrollIntoView();
  };

  return (
    <div>
      <div className="w-50">
        <UserNavSection
          currentUserType={currentUserType}
          setCurrentUserType={setCurrentUserType}
          withBothOptions
        />
      </div>
      <div className="mt-5 w-50">
        <div>
          <DateFiltersSection
            selectedDateFilterType={selectedDateFilterType}
            setSelectedDateFilterType={setSelectedDateFilterType}
            selectedFilterObj={selectedFilterObj}
            handleFilterObjChange={handleFilterObjChange}
          />
        </div>
        <hr className="mt-4 mb-4" />
        <div>
          <LocationFilter
            locations={initialData.locations}
            selectedLocationId={selectedLocationId}
            setSelectedLocationId={setSelectedLocationId}
          />
        </div>
        <div className="mt-5">
          <PrimaryButtonWithLoading
            onClick={() => loadReportData(scrollToReportDataSection)}
            width="100%"
            isLoading={loadingReportData}
          >
            Load Payment Report
          </PrimaryButtonWithLoading>
        </div>
      </div>
    </div>
  );
};

export default FiltersSection;
