import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import { updateDoc } from "firebase/firestore";
import store from "src/stores/UserStore";

import ProgramSelect from "src/components/Dashboard/Guest/components/student/ProgramSelect";
import { FirebaseContext } from "src/components/Firebase";
import { PrimaryButton, RequiredInputSpan } from "src/utils/shared/styled";
import { ButtonsWrapper, StyledProfileCard } from "../styled";
import InstrumentSelect from "src/components/Dashboard/Guest/components/InstrumentSelect";
import { GrCircleInformation } from "react-icons/gr";
import { ElementTitle, HintSpan, ProfileField } from "./styled";
import CustomTooltip from "src/components/Dashboard/common/components/CustomTooltip";
import TeachersTable from "../../../components/student/TeachersTable";
import PackageSelect from "../../../components/student/PackageSelect";
import ClassTypeSelect from "../../../components/student/ClassTypeSelect";
import {
  AvailableDaysList,
  ClassDaySelect,
  ClassTimeSelect,
} from "../../../components/student/ClassScheduleSelect";
import { Divider } from "antd";

const StudentSecondPage = () => {
  const { user, setUser } = store;
  const firebase = useContext(FirebaseContext);
  const userToUpdate = firebase.user(user.uid);
  const navigate = useNavigate();
  const { getTeacherAvailableDays, getLocationById } = useFirebaseFns();

  const [location, setLocation] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [instrumentValue, setInstrumentValue] = useState(
    user?.instruments || []
  );
  const [packageValue, setPackageValue] = useState(user?.package);
  const [programValue, setProgramValue] = useState(user?.program);
  const [classTypeValue, setClassTypeValue] = useState(user?.classType);
  const [selectedTeacher, setSelectedTeacher] = useState(
    user?.selectedTeacherId
  );
  const [selectedDays, setSelectedDays] = useState(
    user?.selectedClassDays || []
  );
  const [selectedTime, setSelectedTime] = useState(user?.selectedClassTime);

  useEffect(() => {
    (async () => {
      const availableDaysResponse = await getTeacherAvailableDays(
        selectedTeacher
      );

      await getLocationById(
        availableDaysResponse?.availableDays[0]?.location
      ).then(setLocation);

      setAvailableDays(availableDaysResponse?.availableDays);
    })();
  }, [selectedTeacher]);

  const handleSave = async () => {
    try {
      if (!user.birthday || !user.name || !user.primaryPhone) {
        return toast.error("Please fill all the fields on both pages!");
      }

      if (
        !instrumentValue?.length ||
        !selectedTeacher ||
        !packageValue ||
        !classTypeValue ||
        !selectedDays?.length
      ) {
        return toast.error("Please fill all the fields!");
      }

      await updateDoc(userToUpdate, {
        instruments: Array.isArray(instrumentValue)
          ? instrumentValue
          : [instrumentValue],
        package: packageValue,
        classType: classTypeValue,
        program: packageValue === "regular" ? programValue : "N/A",
        selectedTeacherId: selectedTeacher,
        selectedClassDays: Array.isArray(selectedDays)
          ? selectedDays
          : [selectedDays],
        selectedClassTime: selectedTime || null,
        paid: user?.paid ? true : false,
      });

      setUser({
        ...user,
        instruments: Array.isArray(instrumentValue)
          ? instrumentValue
          : [instrumentValue],
        package: packageValue,
        classType: classTypeValue,
        program: packageValue === "regular" ? programValue : "other",
        selectedTeacherId: selectedTeacher,
        selectedClassDays: Array.isArray(selectedDays)
          ? selectedDays
          : [selectedDays],
        selectedClassTime: selectedTime,
        paid: user?.paid ? true : false,
      });
      navigate("/dashboard/guest-profile/summary");
    } catch (error) {
      console.error(error);
      toast.error("Please fill all the fields!");
    }
  };

  const handlePackageSelect = (value) => {
    setPackageValue(value);
    if (value !== "regular") {
      setProgramValue(null);
    }
    if (value === "1_class") {
      setInstrumentValue((prevState) => prevState.slice(0, 1));
    }
    setSelectedDays((prevState) => prevState.slice(0, 1));
  };

  const handleTeacherSelect = (value) => {
    setSelectedTeacher(value);
    setSelectedDays([]);
  };

  const handleInstrumentSelect = (value) => {
    if (Array.isArray(value)) {
      setInstrumentValue(value);
    } else {
      setInstrumentValue([value]);
    }
  };

  return (
    <div>
      <StyledProfileCard>
        <ProfileField>
          <ElementTitle>
            <span>
              Instruments <RequiredInputSpan>*</RequiredInputSpan>{" "}
              <HintSpan>(select to display matching teachers)</HintSpan>
            </span>
            <GrCircleInformation size={24} id="instruments-info" />
            <CustomTooltip
              anchorSelect="#instruments-info"
              style={{ fontSize: 20, backgroundColor: "#681e46" }}
            >
              Select Instrument you interested to take a class and learn about
              it
            </CustomTooltip>
          </ElementTitle>
          <InstrumentSelect
            onChange={handleInstrumentSelect}
            value={instrumentValue}
            mode={packageValue !== "1_class" ? "multiple" : null}
          />
        </ProfileField>
        <Divider />
        <ProfileField>
          <ElementTitle>
            <span>
              Packages <RequiredInputSpan>*</RequiredInputSpan>
            </span>
            <GrCircleInformation size={24} id="package-info" />
            <CustomTooltip
              anchorSelect="#package-info"
              style={{ fontSize: 20, backgroundColor: "#681e46" }}
            >
              Select available package to proceed
            </CustomTooltip>
          </ElementTitle>
          <PackageSelect
            onSelect={handlePackageSelect}
            currentSelect={packageValue}
          />
        </ProfileField>
        {packageValue === "regular" && (
          <>
            <Divider />
            <ProfileField>
              <ElementTitle>
                <span>
                  Program <RequiredInputSpan>*</RequiredInputSpan>{" "}
                  <HintSpan>(select to display matching teachers)</HintSpan>
                </span>
                <GrCircleInformation size={24} id="program-info" />
                <CustomTooltip
                  anchorSelect="#program-info"
                  style={{ fontSize: 20, backgroundColor: "#681e46" }}
                >
                  Artis School of Music Student Programs
                </CustomTooltip>
              </ElementTitle>
              <ProgramSelect
                onSelect={setProgramValue}
                currentSelect={programValue}
              />
            </ProfileField>
          </>
        )}
      </StyledProfileCard>
      <StyledProfileCard>
        <ProfileField>
          <ElementTitle>
            <span>
              Class type <RequiredInputSpan>*</RequiredInputSpan>
            </span>
            <GrCircleInformation size={24} id="class-type-info" />
            <CustomTooltip
              anchorSelect="#class-type-info"
              style={{ fontSize: 20, backgroundColor: "#681e46" }}
            >
              We offer in person in our Location in Belmont CA and on-line
              opportunity
            </CustomTooltip>
          </ElementTitle>
          <ClassTypeSelect
            onChange={({ target }) => setClassTypeValue(target.value)}
            value={classTypeValue}
          />
        </ProfileField>
      </StyledProfileCard>
      <ProfileField>
        <ElementTitle>
          <span>
            Teachers <RequiredInputSpan>*</RequiredInputSpan>{" "}
            <HintSpan>
              (select by clicking the circle on the left side of the table)
            </HintSpan>
          </span>
        </ElementTitle>
        <TeachersTable
          onTeacherSelect={handleTeacherSelect}
          selectedTeacher={selectedTeacher}
          currentInstruments={instrumentValue}
          currentProgram={programValue}
        />
      </ProfileField>
      {selectedTeacher && (
        <StyledProfileCard>
          <ProfileField>
            <ElementTitle style={{ textAlign: "center" }}>
              Teacher's available weekdays
              <GrCircleInformation size={24} id="available-days-info" />
              <CustomTooltip
                anchorSelect="#available-days-info"
                style={{ fontSize: 20, backgroundColor: "#681e46" }}
              >
                Artis School Teacher Availability:
              </CustomTooltip>
            </ElementTitle>
            <AvailableDaysList days={availableDays} location={location} />
          </ProfileField>
          <Divider />
          <ProfileField>
            <ElementTitle>
              <span>
                Weekdays <RequiredInputSpan>*</RequiredInputSpan>
              </span>
              <GrCircleInformation size={24} id="weekdays-info" />
              <CustomTooltip
                anchorSelect="#weekdays-info"
                style={{ fontSize: 20, backgroundColor: "#681e46" }}
              >
                Select Days You Can Have a Class
              </CustomTooltip>
            </ElementTitle>
            <ClassDaySelect
              days={availableDays}
              mode={packageValue !== "1_class" ? "multiple" : null}
              onChange={(value) => setSelectedDays(value)}
              value={selectedDays}
            />
          </ProfileField>
          <Divider />
          <ProfileField>
            <ElementTitle>
              Preferred time
              <GrCircleInformation size={24} id="time-info" />
              <CustomTooltip
                anchorSelect="#time-info"
                style={{ fontSize: 20, backgroundColor: "#681e46" }}
              >
                Select your preferred time (not required, will be discussed
                during Enrollment orientation )
              </CustomTooltip>
            </ElementTitle>
            <ClassTimeSelect onChange={setSelectedTime} value={selectedTime} />
          </ProfileField>
        </StyledProfileCard>
      )}
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/guest-profile")}
        >
          Previous page
        </PrimaryButton>
        <PrimaryButton backgroundColor="#681e46DD" onClick={handleSave}>
          Next page
        </PrimaryButton>
      </ButtonsWrapper>
    </div>
  );
};

export default StudentSecondPage;
