import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { UserRole } from "src/constants/UserRoleEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useModal from "src/hooks/useModal";

const useAdminsData = ({ user }) => {
  const { getUsersByRoles, permaDeleteUserAccount } = useFirebaseFns();
  const deleteConfirmationModalData = useModal();
  const adminInfoModalData = useModal();

  const [users, setUsers] = useState([]);
  // when loading initial data
  const [loading, setLoading] = useState(false);

  // when loading any action (ex: delete user)
  const [loadingAction, setLoadingAction] = useState(false);

  const [currentUserId, setCurrentUserId] = useState("");

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const deleteUserPermanently = async (userId) => {
    try {
      if (!userId) {
        toast.error("Failed to delete user");
        return;
      }
      setLoadingAction(true);
      await permaDeleteUserAccount(userId);
      toast.success("Deleted Successfully");
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  };
  const openDeleteModalWithCurrentUser = (userId) => {
    setCurrentUserId(userId);
    deleteConfirmationModalData.openModal();
  };
  const onDeleteModalApprove = async () => {
    await deleteUserPermanently(currentUserId);
    deleteConfirmationModalData.closeModal();
    setCurrentUserId("");
  };

  const onDeleteModalCancel = async () => {
    deleteConfirmationModalData.closeModal();
    setCurrentUserId("");
  };
  const openAdminInfoModalWithCurrentUser = (userId) => {
    setCurrentUserId(userId);
    adminInfoModalData.openModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [users] = await Promise.all([
          getUsersByRoles([UserRole.ADMIN, UserRole.SUPER_ADMIN]),
        ]);

        setUsers(users);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh]);

  const currentUser = useMemo(() => {
    const current = users.find(({ id }) => id === currentUserId);

    return current;
  }, [users, currentUserId]);

  return {
    users,
    loading,
    loadingAction,
    deleteUserPermanently,
    setCurrentUserId,
    currentUser,
    deleteConfirmationModalData,
    onDeleteModalApprove,
    onDeleteModalCancel,
    openDeleteModalWithCurrentUser,
    adminInfoModalData,
    openAdminInfoModalWithCurrentUser,
    refreshData,
  };
};

export default useAdminsData;
