import { programOptions } from "../../../utils";
import { CardsWrapper, StyledCard } from "./styled";

const ProgramSelect = ({ onSelect, currentSelect }) => {
  return (
    <CardsWrapper>
      {programOptions.map(({ title, text, value }, index) => (
        <StyledCard
          onClick={() => onSelect(value)}
          title={title}
          hoverable
          key={index}
          current={currentSelect === value ? "true" : undefined}
        >
          {text}
        </StyledCard>
      ))}
    </CardsWrapper>
  );
};

export default ProgramSelect;
