import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { TeacherInfoItem, TeacherInfoList, TeacherInfoWrapper } from "./styled";
import { Divider, Tooltip } from "antd";
import defaultProfile from "src/assets/images/default-profile.jpg";
import { ProgramTag } from "../styled";
import { programDescriptionsEnum } from "src/components/Dashboard/Guest/utils";

const TeacherProfileModal = ({
  isOpen,
  toggle,
  teacher,
  instruments,
  programs,
}) => {
  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle} size="lg">
      <StyledModalHeader toggle={toggle}>Teacher information</StyledModalHeader>
      <ModalBody>
        <TeacherInfoWrapper>
          <img src={teacher?.imageUrl || defaultProfile} alt="teacher" />
          <TeacherInfoList>
            <TeacherInfoItem>Teacher name: {teacher?.fullName}</TeacherInfoItem>
            <TeacherInfoItem>
              <Divider />
            </TeacherInfoItem>
            <TeacherInfoItem>
              Teacher email: {teacher?.primaryEmail}
            </TeacherInfoItem>
            <TeacherInfoItem>
              <Divider />
            </TeacherInfoItem>
            <TeacherInfoItem>
              Instruments: {instruments?.join(", ")}
            </TeacherInfoItem>
            <TeacherInfoItem>
              <Divider />
            </TeacherInfoItem>
            <TeacherInfoItem>
              Programs:{" "}
              {programs?.length ? (
                programs?.map((program, index) => (
                  <Tooltip
                    color="#681e46DD"
                    overlayInnerStyle={{
                      fontSize: 16,
                      padding: 12,
                      textAlign: "center",
                    }}
                    title={programDescriptionsEnum[program]}
                    trigger="click"
                    key={index}
                  >
                    <ProgramTag color="#681e46CC">{program}</ProgramTag>
                  </Tooltip>
                ))
              ) : (
                <span>N/A</span>
              )}
            </TeacherInfoItem>
          </TeacherInfoList>
        </TeacherInfoWrapper>
      </ModalBody>
    </StyledModal>
  );
};

export default TeacherProfileModal;
