import React from "react";
import { PrimaryButton } from "src/utils/shared/styled";
import {
  CustomPrimaryButton,
  InfoText1,
  InfoText2,
  RowContainer,
} from "./styled";
import {
  calculatePaymentTotal,
  convertPaymentDateToDateStr,
} from "src/utils/helpers";

const PaymentInfoRow = ({
  bgColor,
  paymentObj,
  openPaymentInfoModal,
  setCurrentPaymentId,
}) => {
  const { payments, startDate, endDate, id } = paymentObj;
  const startStr = convertPaymentDateToDateStr(startDate);
  const endStr = convertPaymentDateToDateStr(endDate);

  const paymentTotal = calculatePaymentTotal(payments);

  const handleOpenClick = () => {
    setCurrentPaymentId(id);
    openPaymentInfoModal();
  };

  return (
    <RowContainer bgColor={bgColor}>
      <div>
        <InfoText1>${paymentTotal}</InfoText1>
      </div>
      <div>
        <InfoText2 className="mb-2">
          {startStr} - {endStr}
        </InfoText2>
        <CustomPrimaryButton onClick={handleOpenClick}>
          Open
        </CustomPrimaryButton>
      </div>
    </RowContainer>
  );
};

export default PaymentInfoRow;
