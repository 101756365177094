import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import FilePreview from "react-file-viewer";
import FirebaseContext from "../../Firebase/Context";
import { getDownloadURL, ref } from "firebase/storage";
const SupportedTypes = [
  "png",
  "jpeg",
  "gif",
  "bmp",
  "pdf",
  "csv",
  "xslx",
  "docx",
  "mp4",
  "webm",
  "mp3",
];
const PreviewFile = () => {
  const [file, setFile] = useState(null);
  const firebase = useContext(FirebaseContext);
  const location = useLocation();
  const { id } = qs.parse(location.search);
  const getFile = async () => {
    // const url = await firebase.storage.ref("files").child(id).getDownloadURL();
    const storageRef = ref(firebase.storage, id);
    const url = await getDownloadURL(storageRef);
    console.log("url", url);
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      console.log("xhr.response", xhr.response);
      const { type } = xhr.response;
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile({ file: e.target.result, type });
      };
      reader.readAsDataURL(xhr.response);
    });
    xhr.send();
  };
  useEffect(() => {
    getFile();
  }, []);

  const getSupportedFileType = () => {
    return SupportedTypes.find((stype) =>
      file.type.toLowerCase().includes(stype)
    );
  };

  if (!file) {
    return null;
  }

  return <FilePreview fileType={getSupportedFileType()} filePath={file.file} />;
};
export default PreviewFile;
