import styled, { css } from "styled-components";
import { Menu } from "@styled-icons/boxicons-regular/Menu";
import { Mail } from "@styled-icons/feather/Mail";
import { Settings2Outline } from "@styled-icons/evaicons-outline/Settings2Outline";
import { Alert } from "@styled-icons/fluentui-system-filled/Alert";
import { Ellipse } from "@styled-icons/ionicons-sharp/Ellipse";
import { mainPagePadding } from "src/constants/styles";

export const MobileNavbarContainer = styled.div`
  background-color: #681e46;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: ${mainPagePadding};
`;
export const IconsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const sharedIconStyles = css`
  height: 30px;
  color: #fff;
  cursor: pointer;
`;
export const MenuIcon = styled(Menu)`
  ${sharedIconStyles}
  height: 40px;
`;
export const NotificationIcon = styled(Alert)`
  ${sharedIconStyles}
`;
export const MailIcon = styled(Mail)`
  ${sharedIconStyles}
`;
export const SettingsIcon = styled(Settings2Outline)`
  ${sharedIconStyles}
`;
export const EllipseIcon = styled(Ellipse)`
  top: 1px;
  left: 6px;
  width: 10px;
  height: 10px;
  color: #681e46;
  background: #681e46;
  border: 1px solid #ffffff;
  opacity: 1;
  position: absolute;
  border-radius: 50%;
`;
export const UserInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #f7f3f5;
  min-height: 72px;
  padding: 15px ${mainPagePadding};
`;

export const UserImageContainer = styled.div`
  overflow: hidden;
`;
export const UserImage = styled.img`
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
`;
export const UserPrimaryText = styled.h4`
  font-weight: 900;
  font-size: 1rem;
  margin-bottom: 2px;
`;
export const UserSecondaryText = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: #681e46;
`;

export const PageTitleContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 45px;
  padding: 0px ${mainPagePadding};
`;
export const NavInfoText = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  color: #fff;
`;
