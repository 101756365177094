import "./style.css";
import React from "react";
import CustomCalendar from "./CustomCalendar";
import ArtisCalendar from "./ArtisCalendar";
function CalendarContainer() {
  return (
    <figure className="calendar-container">
      <h2 className="second-heading">Calendar</h2>
      <ArtisCalendar />
      <CustomCalendar />
    </figure>
  );
}

export default CalendarContainer;
