import React from "react";
import {
  isFreeForAllStudentsConcert,
  isTeacherRecommendationConcert,
} from "src/constants/concertEnum";
import { PrimaryButton } from "src/utils/shared/styled";

const TeacherButtons = ({
  openRecommendationModal,
  openSignupListModal,
  openProgramsModal,
  concert,
}) => {
  const { availableSignupOptions } = concert;
  const isTeacherRecommendationOptionAvailable = isTeacherRecommendationConcert(
    availableSignupOptions?.value
  );
  const isFreeForAllStudents = isFreeForAllStudentsConcert(
    availableSignupOptions.type
  );

  const showRecommendation = isTeacherRecommendationOptionAvailable;
  const showSignupList =
    isTeacherRecommendationOptionAvailable || isFreeForAllStudents;
  const showPrograms =
    isTeacherRecommendationOptionAvailable || isFreeForAllStudents;

  return (
    <>
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ gap: 10 }}
      >
        {showRecommendation && (
          <PrimaryButton
            onClick={() => openRecommendationModal()}
            className="text-nowrap"
            width="auto"
            style={{ flex: 1 }}
          >
            Recommendations
          </PrimaryButton>
        )}
        {showSignupList && (
          <PrimaryButton
            onClick={() => openSignupListModal()}
            className="text-nowrap"
            width="auto"
            style={{ flex: 1 }}
          >
            Sign Up List
          </PrimaryButton>
        )}
        {showPrograms && (
          <PrimaryButton
            onClick={() => openProgramsModal()}
            className="text-nowrap"
            width="auto"
            style={{ flex: 1 }}
          >
            Program
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default TeacherButtons;
