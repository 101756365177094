import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { getFullDateFromDateAndTimeInputs } from "src/utils/helpers";
import {
  checkForOverlapping,
  checkIfLessonInTeacherAvailabilities,
} from "../../../helperFns/lessonTimelineHelpers";

const formDataInitialValues = {
  startDate: null, // moment obj
  lastDate: null, // moment obj
  startTime: null, // in HH:mm local time input format
  lessonDuration: null, // number
  teacherId: "",
  locationId: "",
  isVirtual: false,
};
const initialDataInitialValues = {
  teachers: [],
  locations: [],
  programs: [],
  teachersAvailableDays: [],
};
const useEditTimeline = ({ regularPrivateLesson, currentTimeline }) => {
  const {
    getTeachers,
    getLocations,
    getPrograms,
    getTeachersAvailableDays,
    getTeacherActivities,
    editRegularLessonAndActivityTimeline,
  } = useFirebaseFns();

  const [formData, setFormData] = useState(formDataInitialValues);
  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  console.log(formData);

  const onFormFieldChange = (name, value) => {
    if (name === "locationId") {
      setFormData((oldVal) => ({
        ...oldVal,
        [name]: value,
        teacherId: "",
      }));
      return;
    }

    setFormData((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  };

  const submitChanges = async () => {
    try {
      const {
        startDate,
        lastDate,
        startTime,
        lessonDuration,
        teacherId,
        locationId,
        isVirtual,
      } = formData;

      if (
        !lessonDuration ||
        !startTime ||
        !startDate ||
        !teacherId ||
        !locationId
      ) {
        toast.warn("Please fill all required fields");
        return;
      }

      setIsSubmitting(true);

      const fullLastLessonDate = lastDate
        ? getFullDateFromDateAndTimeInputs(lastDate, startTime)
        : null;
      const fullNewLessonStartDate = getFullDateFromDateAndTimeInputs(
        startDate,
        startTime
      );

      // teacherDays is an array with the teacher days
      // const teacherDays = await getTeacherAvailableDays(formValues.teacherId);
      const teacherDays = initialData.teachersAvailableDays.find(
        ({ id }) => id === teacherId
      )?.availableDays;

      const isWithinTeacherAvailabilities =
        checkIfLessonInTeacherAvailabilities(
          {
            lessonStartDate: fullNewLessonStartDate,
            duration: parseInt(lessonDuration),
          },
          teacherDays
        );

      if (!isWithinTeacherAvailabilities) {
        toast.warn("Must be within the teacher schedule in (Teacher Days)");
        return;
      }

      const teacherActivities = await getTeacherActivities(teacherId);
      const isLessonOverlapping = checkForOverlapping(
        teacherActivities,
        teacherId,
        {
          lessonId: regularPrivateLesson.id, // need the id to prevent validating lesson against itself
          lessonStartDate: fullNewLessonStartDate,
          duration: parseInt(lessonDuration),
        }
      );
      if (isLessonOverlapping) {
        toast.warn(
          "The teacher has another activity that overlaps with this lesson"
        );
        return;
      }

      const editedTimelineObj = {
        ...currentTimeline,
        updatedAt: new Date(),
        startDate: fullNewLessonStartDate, // the new lesson date from where we start new timeline
        duration: parseInt(lessonDuration),
        teacherId,
        locationId,
        isVirtual,
        lastDate: fullLastLessonDate,
      };

      const updatedTimeline = regularPrivateLesson.timeline.map(
        (timelineObj) => {
          if (timelineObj.id === editedTimelineObj.id) {
            return editedTimelineObj;
          } else {
            return timelineObj;
          }
        }
      );
      const res = await editRegularLessonAndActivityTimeline({
        updatedTimeline,
        lessonId: regularPrivateLesson.id,
      });
      toast.success("Edited Successfully");
      window.location.reload();
      console.log({
        updatedTimeline,
        oldTimeline: regularPrivateLesson.timeline,
        editedTimelineId: editedTimelineObj.id,
      });
      return;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [teachers, locations, programs, teachersAvailableDays] =
          await Promise.all([
            getTeachers(),
            getLocations(),
            getPrograms(),
            getTeachersAvailableDays(),
          ]);

        setInitialData({
          teachers,
          locations,
          programs,
          teachersAvailableDays,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (!currentTimeline) {
      return;
    }

    const { startDate, duration, teacherId, lastDate, locationId, isVirtual } =
      currentTimeline;
    const startDateFieldValue = moment(startDate);
    const startTimeFieldValue = moment(startDate).format("HH:mm");

    const lastDateFieldValue = lastDate ? moment(lastDate) : null;

    setFormData((oldVal) => ({
      ...oldVal,
      startDate: startDateFieldValue,
      startTime: startTimeFieldValue,
      ...(lastDateFieldValue && { lastDate: lastDateFieldValue }),
      lessonDuration: duration,
      isVirtual: isVirtual ? true : false,
      teacherId,
      locationId,
    }));
  }, []);

  // available teachers for the lesson instrument + selected location (appears in the teachers select field)
  const availableTeachers = useMemo(() => {
    if (!regularPrivateLesson) return [];

    const filteredTeachers = initialData.teachers.filter((teacher) => {
      const teacherInstruments =
        teacher.instrumentsInfo?.map((info) => info.instrument) || [];
      const sameInstrument = teacherInstruments.includes(
        regularPrivateLesson.instrumentId
      );

      const teacherLocations =
        initialData.teachersAvailableDays
          .find(({ id }) => id === teacher.id)
          ?.availableDays?.map(({ location }) => location) || [];
      const sameLocation = teacherLocations.includes(formData.locationId);

      return sameInstrument && sameLocation;
    });

    return filteredTeachers;
  }, [initialData, regularPrivateLesson, formData.locationId]);

  return {
    formData,
    onFormFieldChange,
    initialData,
    loadingInitialData,
    isSubmitting,
    availableTeachers,
    submitChanges,
  };
};

export default useEditTimeline;
