import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import {
  absenceMakeUpOptions,
  isApprovedAbsence,
  isDeclinedAbsence,
  isPartiallyApprovedAbsence,
  isPerDurationAbsence,
} from "src/constants/absenceTypes";
import useModal from "src/hooks/useModal";
import {
  CustomTableContainer,
  CustomTable,
  TableRow,
} from "src/utils/shared/styled";
import AffectedLessonsModal from "../PendingAbsencesTable/AffectedLessonsModal";
import {
  ActionBtnsContainer,
  IconWrapper,
  InfoIcon,
  PeopleIcon,
  DeleteIcon,
} from "../../styled";
import AbsenceDetailsModal from "../PendingAbsencesTable/AbsenceDetailsModal";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { ConfirmDeleteModal } from "../ConfirmDeleteModal";

const ResolvedAbsencesTable = ({ absencesData }) => {
  const {
    initialData,
    teacherResolvedAbsences,
    currentAbsence,
    setCurrentAbsenceId,
  } = absencesData;
  const { teachers, adminCredentialsUsers } = initialData;

  const affectedLessonsModalData = useModal();
  const absenceDetailsModalData = useModal();

  const { deleteTeacherAbsence } = useFirebaseFns();
  const [teacherAbsences, setTeacherAbsences] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedAbsenceId, setSelectedAbsenceId] = useState(null);

  useEffect(() => {
    setTeacherAbsences([...teacherResolvedAbsences]);
  }, [teacherResolvedAbsences]);

  const openAffectedLessonsModal = (absenceId) => {
    setCurrentAbsenceId(absenceId);
    affectedLessonsModalData.openModal();
  };
  const openAbsenceDetailsModal = (absenceId) => {
    setCurrentAbsenceId(absenceId);
    absenceDetailsModalData.openModal();
  };

  const handleDeleteModalOpen = (absenceId) => {
    setSelectedAbsenceId(absenceId);
    setConfirmModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedAbsenceId(null);
    setConfirmModalOpen(false);
  };

  const handleAbsenceDelete = (absenceId) => {
    deleteTeacherAbsence(absenceId);
    setTeacherAbsences(
      teacherAbsences.filter((absence) => absence.id !== absenceId)
    );
    setSelectedAbsenceId(null);
    setConfirmModalOpen(false);
  };

  const renderActionButtons = (absence) => {
    return (
      <ActionBtnsContainer>
        <IconWrapper>
          <InfoIcon onClick={() => openAbsenceDetailsModal(absence.id)} />
        </IconWrapper>
        {absence.makeupOption === absenceMakeUpOptions.SUBSTITUTE && (
          <IconWrapper>
            <PeopleIcon onClick={() => openAffectedLessonsModal(absence.id)} />
          </IconWrapper>
        )}
        <IconWrapper>
          <DeleteIcon onClick={() => handleDeleteModalOpen(absence.id)} />
        </IconWrapper>
      </ActionBtnsContainer>
    );
  };

  return (
    <Container fluid className="p-0">
      <CustomTableContainer>
        <CustomTable className="w-100 text-dark">
          <thead>
            <TableRow>
              <th>
                <div>Request Date</div>
              </th>
              <th>
                <div>Absence Date</div>
              </th>
              <th>
                <div>Teacher Name</div>
              </th>
              <th>
                <div>Status</div>
              </th>
              <th>
                <div>Resolved By</div>
              </th>
              <th>
                <div>Actions</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {teacherAbsences.map((absence) => {
              const {
                date,
                createdAt,
                startDate,
                absenceBehaviour,
                teacherId,
                status,
                resolve,
              } = absence;
              const isDeclined = isDeclinedAbsence(status);
              const isApproved = isApprovedAbsence(status);
              const isPartiallyApproved = isPartiallyApprovedAbsence(status);

              const createdAtStr = moment(createdAt).format("MM-DD-YYYY");
              const dateStr = isPerDurationAbsence(absenceBehaviour)
                ? moment(startDate).format("MM-DD-YYYY")
                : moment(date).format("MM-DD-YYYY");

              const teacherName = teachers.find(
                ({ id }) => id === teacherId
              )?.fullName;

              const statusStr = isDeclined
                ? "Declined"
                : isApproved
                ? "Approved"
                : isPartiallyApproved
                ? "Partially Approved"
                : "N/A";

              const resolvedByStr = adminCredentialsUsers?.find(
                ({ id }) => id === resolve?.userId
              )?.fullName;
              return (
                <TableRow>
                  <td>{createdAtStr}</td>
                  <td>{dateStr}</td>
                  <td>{teacherName || "N/A"}</td>
                  <td>{statusStr}</td>
                  <td>{resolvedByStr}</td>
                  <td>{renderActionButtons(absence)}</td>
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>
      {absenceDetailsModalData.isModalOpen && currentAbsence && (
        <AbsenceDetailsModal
          modalData={absenceDetailsModalData}
          absencesData={absencesData}
          refreshData={absencesData.refreshData}
          openAffectedLessonsModal={affectedLessonsModalData.openModal}
          readOnly
        />
      )}
      {currentAbsence?.makeupOption === absenceMakeUpOptions.SUBSTITUTE && (
        <AffectedLessonsModal
          modalData={affectedLessonsModalData}
          absencesData={absencesData}
        />
      )}
      <ConfirmDeleteModal
        open={confirmModalOpen}
        onConfirm={() => handleAbsenceDelete(selectedAbsenceId)}
        onCancel={handleDeleteModalClose}
        text="Are you sure you want to delete this absence?"
      />
    </Container>
  );
};

export default ResolvedAbsencesTable;
