import React from "react";
import moment from "moment";
import { getAgeFromDate } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import useLessonsData from "../../../Hooks/useLessonsData";
import { CustomTextarea } from "src/utils/shared/styled";

const RegularLessonInfo = ({ lesson }) => {
  const { teacherNote, setTeacherNote, saveTeacherNote, loadingTeacherNote } =
    useLessonsData({ lesson });

  const { student, instrument, currentTimeline } = lesson || {};

  const { startDate, duration } = currentTimeline || {};
  const endDate =
    startDate && duration
      ? moment(startDate).add(parseInt(duration), "minutes")
      : undefined;

  const formattedTime =
    startDate && endDate
      ? `${moment(startDate).format("hh:mm A")} - ${moment(endDate).format(
          "hh:mm A"
        )}`
      : undefined;
  const lessonDay = startDate && moment(startDate).format("dddd");
  const age =
    student?.birthday &&
    getAgeFromDate(moment(student.birthday, "YYYY-MM-DD").toDate());
  const formattedAge = age ? `${age} years` : "N/A";

  const handleSaveTeacherNote = async () => {
    await saveTeacherNote();
  };
  return (
    <div>
      <div className="ms-2 mb-3">
        <h4 className="fw-bold">Lesson</h4>
      </div>
      <div className="lessonsContainer mb-4 ">
        <div className="UsableContainer leesonsDataContainer flex-wrap">
          <div className="UsableSinglePart d-flex flex-wrap">
            <p>Instrument : </p>
            <p className="DataInfo">{instrument?.name || "N/A"}</p>
          </div>
          <div className="UsableSinglePart d-flex flex-wrap">
            <p>Lesson Length : </p>
            <p className="DataInfo">{`${duration && duration + " Minutes"}`}</p>
          </div>
          <div className="UsableSinglePart d-flex flex-wrap">
            <p>Lesson Day : </p>
            <p className="DataInfo">{lessonDay}</p>
          </div>
          <div className="UsableSinglePart d-flex flex-wrap">
            <p>Lesson Time : </p>
            <p className="DataInfo">{formattedTime || "N/A"}</p>
          </div>
        </div>

        <div className="mt-4">
          <p className="d-flex justify-content-between">
            <span className="fw-bold">
              Student / Parent Note (For Teachers Use)
            </span>
            <PrimaryButtonWithLoading
              isLoading={loadingTeacherNote}
              padding="5px"
              width="120px"
              onClick={handleSaveTeacherNote}
            >
              Save Note
            </PrimaryButtonWithLoading>
          </p>
          <div>
            <CustomTextarea
              onChange={(e) => setTeacherNote(e.target.value)}
              width="100%"
              height="130px"
              value={teacherNote}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularLessonInfo;
