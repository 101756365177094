import { POSITIVE_8_GMT } from "./timezones";

export const paymentDatesFormat = "MM/DD/YYYY";
export const paymentDatesTimezone = POSITIVE_8_GMT;
export const workConditions = {
  fullTime: {
    title: "Full Time",
    value: "full_time",
  },
  partTime: {
    title: "Part Time",
    value: "part_time",
  },
};

export const paymentRates = {
  regularClassRate: {
    title: "regularClassRate",
    name1: "Regular Class Rate",
  },
  groupClassRate: {
    title: "groupClassRate",
    name1: "Group Class Rate",
  },
  trainingRate: {
    title: "trainingRate",
    name1: "Training Rate",
  },
  administrativeRate: {
    title: "administrativeRate",
    name1: "Administrative Rate",
  },
  reservationRate: {
    title: "reservationRate",
    name1: "Reservation Rate",
  },
  yearlySalary: {
    title: "yearlySalary",
    name1: "Yearly Salary",
  },
  workHoursRate: {
    title: "workHoursRate",
    name1: "Work Hours Rate",
  },
  extendedHoursRate: {
    title: "extendedHoursRate",
    name1: "Extended Hours Rate",
  },
  paidBreaksRate: {
    title: "paidBreaksRate",
    name1: "Paid Breaks Rate",
  },
  signUpsRate: {
    title: "signUpsRate",
    name1: "Sign Ups Rate",
  },
};

export const paymentRateTypes = {
  HOUR: "hour",
  UNIT: "unit",
  YEARLY_AMOUNT: "yearlyAmount",
};

export const paymentRatesMap = [
  {
    rateTitle: paymentRates.regularClassRate.title,
    rateType: paymentRateTypes.HOUR,
    label: "Private Instructions / Hr",
  },
  {
    rateTitle: paymentRates.groupClassRate.title,
    rateType: paymentRateTypes.UNIT,
    label: "Group Class /Unit",
  },
  {
    rateTitle: paymentRates.trainingRate.title,
    rateType: paymentRateTypes.HOUR,
    label: "Training Hours /Hr",
  },
  {
    rateTitle: paymentRates.administrativeRate.title,
    rateType: paymentRateTypes.HOUR,
    label: "Admin Hours /Hr",
  },
  {
    rateTitle: paymentRates.reservationRate.title,
    rateType: paymentRateTypes.HOUR,
    label: "Reservation Hours /Hr",
  },
  {
    rateTitle: paymentRates.workHoursRate.title,
    rateType: paymentRateTypes.HOUR,
    label: "Work Hours /Hr",
  },
  {
    rateTitle: paymentRates.extendedHoursRate.title,
    rateType: paymentRateTypes.HOUR,
    label: "Extended Hours /Hr",
  },
  {
    rateTitle: paymentRates.signUpsRate.title,
    rateType: paymentRateTypes.UNIT,
    label: "Sign Up Bonus /Unit",
  },
  {
    rateTitle: paymentRates.paidBreaksRate.title,
    rateType: paymentRateTypes.HOUR,
    label: "Paid Breaks /Hr",
  },
  {
    rateTitle: paymentRates.yearlySalary.title,
    rateType: paymentRateTypes.YEARLY_AMOUNT,
    label: "Yearly Salary",
  },
];
export const fullTimeAdminRates = [
  paymentRates.yearlySalary.title,
  paymentRates.extendedHoursRate.title,
  paymentRates.signUpsRate.title,
];
export const fullTimeTeacherRates = [
  //
  paymentRates.yearlySalary.title,
  paymentRates.trainingRate.title,
  paymentRates.administrativeRate.title,
  paymentRates.reservationRate.title,
];
export const partTimeAdminRates = [
  paymentRates.workHoursRate.title,
  paymentRates.extendedHoursRate.title,
  paymentRates.paidBreaksRate.title,
  paymentRates.signUpsRate.title,
];
export const partTimeTeacherRates = [
  paymentRates.regularClassRate.title,
  paymentRates.groupClassRate.title,
  paymentRates.trainingRate.title,
  paymentRates.administrativeRate.title,
  paymentRates.reservationRate.title,
];

export const paymentTypes = {
  REGULAR_CLASS: "regularClass",
  GROUP_CLASS: "groupClass",
  SDC: "sdc",
  TRAINING: "training",
  EA: "ea",
  RESERVATION: "reservation",
  ADMINISTRATIVE: "administrative",
  BONUS: "bonus",
  REIMBURSEMENT: "reimbursement",
  SALARY: "salary",
  WORK_HOURS: "workHours",
  EXTENDED_HOURS: "extendedHours",
  PAID_BREAKS: "paidBreaks",
  SIGN_UPS: "signUps",
};

export const paymentCalculationTypes = {
  COUNT: "count",
  HOUR: "hour",
  FIXED: "fixed",
  CUSTOM: "custom",
};

export const REGULAR_CLASS_30_MINS_BY_NUMBER = "regularClass30MinsByNumber";
export const REGULAR_CLASS_45_MINS_BY_NUMBER = "regularClass45MinsByNumber";
export const REGULAR_CLASS_60_MINS_BY_NUMBER = "regularClass60MinsByNumber";
export const GROUP_CLASS_BY_NUMBER = "groupClassByNumber";
export const SDC_BY_HRS = "sdcByHrs";
export const TRAINING_HOURS_BY_HRS = "trainingHoursByHrs";
export const RESERVATION_HOURS_BY_HRS = "reservationHoursByHrs";
export const ADMIN_HOURS_BY_HRS = "adminHoursByHrs";
export const BONUS_BY_CUSTOM_AMOUNT = "bonusByCustomAmount";
export const REIMBURSEMENT_BY_CUSTOM_AMOUNT = "reimbursementByCustomAmount";
export const EA_BY_HRS = "eaByHrs";
export const SALARY_BY_FIXED_AMOUNT = "salaryByFixedAmount";
export const WORK_HOURS_BY_HRS = "workHoursByHrs";
export const EXTENDED_HOURS_BY_HRS = "extendedHoursByHrs";
export const PAID_BREAKS_BY_HRS = "paidBreaksByHrs";
export const SIGN_UPS_BY_NUMBER = "signUpsByNumber";

// maps each field property name to the corresponding rate type and title
// we use this to know which propname uses which rate
// the unit amount value is always 1 except for the 30/45 mins lesson as they use a fraction of the hourly rate
export const paymentsMap = [
  {
    propName: REGULAR_CLASS_30_MINS_BY_NUMBER,
    name1: "30 mins lesson",
    name2: "30 mins (By Number)",
    rateTitle: paymentRates.regularClassRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Private Lessons",
    paymentType: paymentTypes.REGULAR_CLASS,
    paymentCalculationType: paymentCalculationTypes.COUNT,
    fractionPerUnit: 0.5,
    acceptsNegativeValue: false,
  },
  {
    propName: REGULAR_CLASS_45_MINS_BY_NUMBER,
    name1: "45 mins lesson",
    name2: "45 mins (By Number)",
    rateTitle: paymentRates.regularClassRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Private Lessons",
    paymentType: paymentTypes.REGULAR_CLASS,
    paymentCalculationType: paymentCalculationTypes.COUNT,
    fractionPerUnit: 0.75,
    acceptsNegativeValue: false,
  },
  {
    propName: REGULAR_CLASS_60_MINS_BY_NUMBER,
    name1: "60 mins lesson",
    name2: "60 mins (By Number)",
    rateTitle: paymentRates.regularClassRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Private Lessons",
    paymentType: paymentTypes.REGULAR_CLASS,
    paymentCalculationType: paymentCalculationTypes.COUNT,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: SDC_BY_HRS,
    name1: "Same Day Cancelation",
    name2: "SDC (By Hours)",
    rateTitle: paymentRates.regularClassRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "SDC",
    paymentType: paymentTypes.SDC,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: GROUP_CLASS_BY_NUMBER,
    name1: "Group Class",
    name2: "Group Class (By Number)",
    rateTitle: paymentRates.groupClassRate.title,
    rateType: paymentRateTypes.UNIT,
    paymentName: "Group Class",
    paymentType: paymentTypes.GROUP_CLASS,
    paymentCalculationType: paymentCalculationTypes.COUNT,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: TRAINING_HOURS_BY_HRS,
    name1: "Training Hours",
    name2: "Training Hours (By Hours)",
    rateTitle: paymentRates.trainingRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Training",
    paymentType: paymentTypes.TRAINING,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: RESERVATION_HOURS_BY_HRS,
    name1: "Reservation Hours",
    name2: "Reservation Hours (By Hours)",
    rateTitle: paymentRates.reservationRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Reservation",
    paymentType: paymentTypes.RESERVATION,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: ADMIN_HOURS_BY_HRS,
    name1: "Admin Hours",
    name2: "Admin Hours (By Hours)",
    rateTitle: paymentRates.administrativeRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Admin",
    paymentType: paymentTypes.ADMINISTRATIVE,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: BONUS_BY_CUSTOM_AMOUNT,
    name1: "Bonus",
    name2: "Bonus (Custom Amount)",
    rateTitle: null,
    rateType: null,
    paymentName: "Bonus",
    paymentType: paymentTypes.BONUS,
    paymentCalculationType: paymentCalculationTypes.CUSTOM,
    acceptsNegativeValue: false,
  },
  {
    propName: REIMBURSEMENT_BY_CUSTOM_AMOUNT,
    name1: "Reimbursement",
    name2: "Reimbursement (Custom Amount)",
    rateTitle: null,
    rateType: null,
    paymentName: "Reimbursement",
    paymentType: paymentTypes.REIMBURSEMENT,
    paymentCalculationType: paymentCalculationTypes.CUSTOM,
    acceptsNegativeValue: true,
  },
  {
    propName: EA_BY_HRS,
    name1: "Emergency Absence (No Payment)",
    name2: "EA (By Hours) (No Payment)",
    rateTitle: null,
    rateType: null,
    paymentName: "EA",
    paymentType: paymentTypes.EA,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: SALARY_BY_FIXED_AMOUNT,
    name1: "Salary",
    name2: "Salary (Fixed Amount)",
    rateTitle: paymentRates.yearlySalary.title,
    rateType: paymentRateTypes.YEARLY_AMOUNT,
    paymentName: "Salary",
    paymentType: paymentTypes.SALARY,
    paymentCalculationType: paymentCalculationTypes.FIXED,
    acceptsNegativeValue: false,
  },
  {
    propName: WORK_HOURS_BY_HRS,
    name1: "Work Hours",
    name2: "Work Hours (By Hours)",
    rateTitle: paymentRates.workHoursRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Work Hours",
    paymentType: paymentTypes.WORK_HOURS,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: EXTENDED_HOURS_BY_HRS,
    name1: "Extended Hours",
    name2: "Extended Hours (By Hours)",
    rateTitle: paymentRates.extendedHoursRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Extended Hours",
    paymentType: paymentTypes.EXTENDED_HOURS,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: PAID_BREAKS_BY_HRS,
    name1: "Paid Breaks",
    name2: "Paid Breaks (By Hours)",
    rateTitle: paymentRates.paidBreaksRate.title,
    rateType: paymentRateTypes.HOUR,
    paymentName: "Paid Breaks",
    paymentType: paymentTypes.PAID_BREAKS,
    paymentCalculationType: paymentCalculationTypes.HOUR,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
  {
    propName: SIGN_UPS_BY_NUMBER,
    name1: "Number Of Sign Ups",
    name2: "Number Of Sign Ups (By Number)",
    rateTitle: paymentRates.signUpsRate.title,
    rateType: paymentRateTypes.UNIT,
    paymentName: "Number Of Sign Ups",
    paymentType: paymentTypes.SIGN_UPS,
    paymentCalculationType: paymentCalculationTypes.COUNT,
    fractionPerUnit: 1,
    acceptsNegativeValue: false,
  },
];

// for the properties that don't require rates
export const paymentPropsWithoutRates = [
  BONUS_BY_CUSTOM_AMOUNT,
  REIMBURSEMENT_BY_CUSTOM_AMOUNT,
  EA_BY_HRS,
];

// props that wont have a field input
export const paymentPropsWithoutInputField = [SALARY_BY_FIXED_AMOUNT];

export const fullTimeAdminPaymentProps = [
  SALARY_BY_FIXED_AMOUNT,
  EXTENDED_HOURS_BY_HRS,
  SIGN_UPS_BY_NUMBER,
  BONUS_BY_CUSTOM_AMOUNT,
  REIMBURSEMENT_BY_CUSTOM_AMOUNT,
];
export const fullTimeTeacherPaymentProps = [
  SALARY_BY_FIXED_AMOUNT,
  TRAINING_HOURS_BY_HRS,
  RESERVATION_HOURS_BY_HRS,
  ADMIN_HOURS_BY_HRS,
  BONUS_BY_CUSTOM_AMOUNT,
  REIMBURSEMENT_BY_CUSTOM_AMOUNT,
  EA_BY_HRS,
];
export const partTimeAdminPaymentProps = [
  WORK_HOURS_BY_HRS,
  EXTENDED_HOURS_BY_HRS,
  PAID_BREAKS_BY_HRS,
  SIGN_UPS_BY_NUMBER,
  BONUS_BY_CUSTOM_AMOUNT,
  REIMBURSEMENT_BY_CUSTOM_AMOUNT,
];
export const partTimeTeacherPaymentProps = [
  REGULAR_CLASS_30_MINS_BY_NUMBER,
  REGULAR_CLASS_45_MINS_BY_NUMBER,
  REGULAR_CLASS_60_MINS_BY_NUMBER,
  SDC_BY_HRS,
  GROUP_CLASS_BY_NUMBER,
  TRAINING_HOURS_BY_HRS,
  RESERVATION_HOURS_BY_HRS,
  ADMIN_HOURS_BY_HRS,
  BONUS_BY_CUSTOM_AMOUNT,
  REIMBURSEMENT_BY_CUSTOM_AMOUNT,
  EA_BY_HRS,
];
