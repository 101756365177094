import React from "react";
import FeedItemDropdownMenu from "../../../components/FeedItemDropdownMenu";
import { OptionsEllipsisContainer, ThreeDotsIcon } from "../../../styled";
import { BackIcon, ModalHeaderContainer } from "../styled";

const DropdownContainer = ({ children }) => (
  <OptionsEllipsisContainer>{children}</OptionsEllipsisContainer>
);
const ModalHeaderContent = ({ modalData, feedItem }) => {
  return (
    <ModalHeaderContainer>
      <div>
        <BackIcon onClick={modalData.closeModal} />
      </div>
      <div>
        <FeedItemDropdownMenu
          dropdownToggleIcon={ThreeDotsIcon}
          dropdownToggleContainer={DropdownContainer}
          feedItem={feedItem}
        />
      </div>
    </ModalHeaderContainer>
  );
};

export default ModalHeaderContent;
