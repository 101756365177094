import { logEvent } from "firebase/analytics";

const logAnalyticsEvents = (analytics) => {
  logEvent(analytics, "page_view");
  logEvent(analytics, "exception");
  logEvent(analytics, "login");
  logEvent(analytics, "screen_view");
  logEvent(analytics, "select_content");
  logEvent(analytics, "sign_up");
  logEvent(analytics, "view_item");
  logEvent(analytics, "share");
  logEvent(analytics, "timing_complete");
  logEvent(analytics, "select_item");
  logEvent(analytics, "generate_lead");
  logEvent(analytics, "checkout_progress");
  logEvent(analytics, "view_item_list");
};

export default logAnalyticsEvents;
