import React from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { CustomLabel } from "src/utils/shared/styled";

const LocationFilter = ({
  selectedLocationId,
  setSelectedLocationId,
  locations,
}) => {
  const selectedLocation = locations.find(
    ({ id }) => id === selectedLocationId
  );
  const selectedLocationOption = selectedLocationId
    ? {
        label: selectedLocation?.name,
        value: selectedLocationId,
      }
    : null;

  const options = locations.map((location) => ({
    label: location.name,
    value: location.id,
  }));
  return (
    <div>
      <CustomLabel>Location:</CustomLabel>
      <CustomReactSelect
        isMulti={false}
        placeholder={"Select Location"}
        width="100%"
        options={options}
        onChange={(option) => setSelectedLocationId(option?.value || "")}
        value={selectedLocationOption}
        wrapOptions={false}
        isClearable={true}
      />
    </div>
  );
};

export default LocationFilter;
