import moment from "moment";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  absenceBehaviours,
  absenceStatuses,
  isCreditedAbsence,
} from "src/constants/absenceTypes";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getCombinedPackageSetItems,
  getCombinedSchoolBreaks,
  getCurrentSchoolYear,
  getPrivateLessonInfoOnSpecificDate,
  getSchoolYearInDate,
  updatedMomentNow,
} from "src/utils/helpers";

const useAbsenceRequestData = ({
  privateLesson,
  onAbsenceRequestSuccess = () => {},
  user,
  closeAbsenceModal = () => {},
}) => {
  const isPackageLsn = isPackagePrivateLesson(privateLesson.type);
  const { getPrivateLessonsAbsences, createAbsenceDoc, getSchoolYears } =
    useFirebaseFns();
  const [initialData, setInitialData] = useState({
    lessonAbsences: [],
    schoolYears: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [loadingSaveAbsence, setLoadingSaveAbsence] = useState(false);
  const [absenceFormInfo, setAbsenceFormInfo] = useState({
    date: null,
    absenceType: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!privateLesson) return;
        setLoadingInitialData(true);
        const [lessonAbsences, schoolYears] = await Promise.all([
          getPrivateLessonsAbsences([privateLesson.id]),
          getSchoolYears(),
        ]);
        console.log({ lessonAbsences });
        setInitialData({
          lessonAbsences,
          schoolYears,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchData();
  }, []);

  const handleConfirmAbsenceRequest = async () => {
    try {
      if (loadingSaveAbsence || !privateLesson) return;

      const isCompleteAbsenceInfo = Object.values(absenceFormInfo).every(
        (el) => el
      );
      if (!isCompleteAbsenceInfo) {
        toast.warn("Please fill all required fields");
        return;
      }

      setLoadingSaveAbsence(true);
      const lessonInfoOnAbsenceDate = getPrivateLessonInfoOnSpecificDate({
        privateLesson: privateLesson,
        date: absenceFormInfo.date,
        withTimelineApproximation: true,
      });
      const { startDate, teacherId } = lessonInfoOnAbsenceDate;

      let lessonStartHrs = moment(startDate).get("hours");
      let lessonStartMins = moment(startDate).get("minutes");
      if (!isPackagePrivateLesson(privateLesson.type)) {
        // checks if the selected date is a modified date
        const modifiedLesson = privateLesson.modifications?.find(
          ({ weekNumber, date }) =>
            date ? moment(date).isSame(absenceFormInfo.date, "day") : false
        );
        const modifiedLessonDate = modifiedLesson?.date;
        if (modifiedLessonDate) {
          lessonStartHrs = moment(modifiedLessonDate).get("hours");
          lessonStartMins = moment(modifiedLessonDate).get("minutes");
        }
      }

      //absence date is the date selected with the lesson time
      const absenceDate = moment(absenceFormInfo.date)
        .set({
          hours: lessonStartHrs,
          minutes: lessonStartMins,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();

      // check if there is already an absence on the same day
      const isExistingAbsenceOnSameDay = !!initialData.lessonAbsences.find(
        (absence) =>
          moment(absence.date || absence.startDate).isSame(absenceDate, "date")
      );
      if (isExistingAbsenceOnSameDay) {
        toast.warn("There is an existing absence request on the same day");
        return;
      }

      const absenceStatus = isCreditedAbsence(absenceFormInfo.absenceType)
        ? absenceStatuses.IN_PROGRESS
        : absenceStatuses.APPROVED;

      const absenceObj = {
        createdAt: new Date(),
        date: absenceDate,
        absenceType: parseInt(absenceFormInfo.absenceType),
        absenceBehaviour: absenceBehaviours.PER_LESSON,
        lessonId: privateLesson.id,
        status: absenceStatus,
        userId: user.uid,
        userRole: user.role,
        teacherId: teacherId,
        studentId: privateLesson.studentId,
      };
      const createdAbsenceRes = await createAbsenceDoc(absenceObj);
      if (createdAbsenceRes.id) {
        console.log({ absenceId: createdAbsenceRes.id, absenceObj });
        toast.success("Absence Request Approved");
        onAbsenceRequestSuccess();
        closeAbsenceModal();
      } else {
        toast.error("Couldnt create absence request");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingSaveAbsence(false);
    }
  };

  const isAllowedDate = (date) => {
    let isAllowed = true;
    if (isPackageLsn) {
      const combinedPackageItems = getCombinedPackageSetItems(
        privateLesson.packageSets
      );
      const isPackageLessonDate = combinedPackageItems.find(({ startDate }) =>
        moment(startDate).isSame(date, "date")
      );
      if (!isPackageLessonDate) {
        isAllowed = false;
      }
    } else {
    }

    return isAllowed;
  };

  const currentSchoolYearInLocation = useMemo(() => {
    if (!initialData.schoolYears.length || !absenceFormInfo.date) return;

    const { locationId } = getPrivateLessonInfoOnSpecificDate({
      date: absenceFormInfo.date,
      privateLesson,
      withTimelineApproximation: true,
    });
    const currentSchoolYear = getSchoolYearInDate(
      initialData.schoolYears,
      locationId,
      absenceFormInfo.date
    );
    return currentSchoolYear;
  }, [initialData, absenceFormInfo]);

  const isDuringSummerSession = useMemo(() => {
    if (!currentSchoolYearInLocation || !absenceFormInfo.date) return false;
    const { dates } = currentSchoolYearInLocation;
    const { summer_session_start, summer_session_end } = dates || {};
    if (!summer_session_start || !summer_session_end) return false;

    return moment(absenceFormInfo.date).isBetween(
      summer_session_start.toDate(),
      summer_session_end.toDate(),
      "minutes",
      "[]"
    );
  }, [currentSchoolYearInLocation, absenceFormInfo]);

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears) return [];

    const { locationId } = getPrivateLessonInfoOnSpecificDate({
      date: updatedMomentNow(),
      privateLesson,
      withTimelineApproximation: true,
    });
    if (!locationId) return [];

    const combinedBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      locationId
    );

    return combinedBreaks;
  }, [initialData, privateLesson]);

  return {
    initialData,
    loadingInitialData,
    handleConfirmAbsenceRequest,
    loadingSaveAbsence,
    absenceFormInfo,
    setAbsenceFormInfo,
    isAllowedDate,
    isDuringSummerSession,
    combinedSchoolBreaks,
  };
};

export default useAbsenceRequestData;
