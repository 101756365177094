import React from "react";
import ConfirmationModal from "../../../../ConfirmationModal";
import usePermaDeleteStudent from "./hooks/usePermaDeleteStudent";

const PermaDeleteStudentModal = ({
  modalData,
  studentId,
  onSuccess = () => {},
}) => {
  const { handlePermaDeleteStudent, loadingAction } = usePermaDeleteStudent({
    studentId,
    onSuccess,
    closeModal: modalData.closeModal,
  });

  const onApprove = async () => {
    await handlePermaDeleteStudent(studentId);
  };
  const onCancel = () => {
    modalData.closeModal();
  };
  return (
    <ConfirmationModal
      onApprove={onApprove}
      onCancel={onCancel}
      modalData={modalData}
      isSubmitting={loadingAction}
      approveBtnText={"Delete"}
      cancelBtnText="Cancel"
      title="Permanently Delete Student ?"
      withCheckbox={true}
      checkboxDescription="I understand that this will permanently delete the student and all lessons, absences, make up lessons, lesson notes, attendance and library."
    />
  );
};

export default PermaDeleteStudentModal;
