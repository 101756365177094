import React from "react";
import { InputContainer } from "../../styled";
import {
  CustomInput,
  CustomLabel,
  CustomTextarea,
} from "src/utils/shared/styled";

const LabeledTextarea = ({
  withLabel = true,
  label,
  value,
  onChange,
  ...props
}) => {
  return (
    <InputContainer>
      {withLabel && <CustomLabel>{label}:</CustomLabel>}
      <CustomTextarea
        disabledBgColor="e2e2e2"
        value={value}
        onChange={onChange}
        width="100%"
        {...props}
      />
    </InputContainer>
  );
};

export default LabeledTextarea;
