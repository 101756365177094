export const scheduleTypes = {
  teacherDays: {
    code: 1,
    name: "Teacher Days",
  },
  makeupOpenings: {
    code: 2,
    name: "Makeup Openings",
  },
  other: {
    code: 5,
    name: "other",
  },
};
