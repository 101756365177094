import { Document, Page, pdfjs } from "react-pdf";

import styled from "styled-components";
import { ThreeDots } from "styled-icons/bootstrap";

export const FeedSectionMainContainer = styled.section`
  & {
    height: 100%;
    box-shadow: 0px 6px 15px #00000008;
    border-radius: 10px;
    padding: 10px;
    color: #151515;
  }
  @media (max-width: 1200px) {
    & {
      /* height: 85vh; */
    }
  }
`;
export const FeedItemsContainer = styled.div`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
`;
export const FeedItemContainer = styled.div`
  display: flex;
  padding: 15px 0;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
  height: 130px;
  margin: 5px;

  &:hover {
    background-color: #dddddd;
  }
`;

export const ItemInfoContainer = styled.div`
  width: 65%;
`;
export const MetaContainer = styled.div`
  display: flex;
  gap: 15px;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
export const InfoSpan1 = styled.span`
  color: #151515;
  font-size: 13px;
  font-weight: 400;
`;
export const InfoSpan2 = styled.span`
  color: #151515;
  font-size: 13px;
  font-weight: 600;
`;
export const DescriptionContent = styled.p`
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #151515;
  letter-spacing: 0.3px;
  line-height: 1.5;
  margin-bottom: 0;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const AttachmentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
`;
export const AttachmentImageContainer = styled.div`
  width: 100%;
  height: 100%;

  & span {
    width: 100%;
    height: 100%;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
`;
export const VideoContainer = styled.div`
  &,
  & .video {
    width: 100%;
    height: 100%;
  }
  & .video {
    border-radius: 10%;
  }
`;
export const OptionsEllipsisContainer = styled.div``;
export const ThreeDotsIcon = styled(ThreeDots)`
  width: 30px;
  /* height: 30px; */
  color: #000;
  cursor: pointer;
`;

// nav ellipse when new feed items
export const EllipseIcon = styled.span`
  position: absolute;
  top: -13px;
  right: 8px;
  font-size: 0.5rem;
  padding: 3px 10px;
  color: #fff;
  background: #d71d82;
  border: 1px solid #ffffff;
  border-radius: 20px;
`;

export const CustomDocument = styled(Document)`
  width: 100%;
  height: 100%;
  & .react-pdf__Document,
  .react-pdf__Page,
  & canvas {
    width: 100% !important;
    height: 100% !important;
  }

  & canvas {
    object-fit: contain;
  }
`;
