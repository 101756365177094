import moment from "moment";
import { toast } from "react-toastify";
import { isPerLessonAbsence } from "src/constants/absenceTypes";
import { isRecurringEvent } from "src/constants/eventsEnum";
import { groupClassFrequency } from "src/constants/groupClassConstants";
import { weekDays } from "src/constants/weekDays";
import { attachAvailableTimeSlotstoTeacher } from "src/hooks/useTeachersAvailabilities/helperFns";
import {
  areTwoEventsOverlapping,
  checkIfActivityDuringSchoolBreak,
  checkIfCanceledFutureEvents,
  flattenDateRanges,
  getAvailableTimeSlots,
  getFullDateFromDateAndTimeInputs,
  getFutureTimelineOnWeekday,
  getNewDatesOnRequestDateByWeekday,
  getRecurringEventTimelineObj,
  getTimeChunks,
  isTeacherDayInRequestDate,
  mapDurationOrAllDayAbsenceToActivity,
  mergeOverlappingDateRanges,
  prepareBusySlots,
  prepareTeacherActivities,
  prepareTeacherDays,
  trimTimeSlot,
} from "src/utils/helpers";

// gets the teacher availabilities based on the form inputs (lsn the start date and lessonLength)
export const getTeacherDaysWithAvailableSlots = ({
  teacherId,
  teacherActivities,
  teacherAvailableDays,
  formInputs,
}) => {
  const preparedTeacherActivities = prepareTeacherActivities(
    teacherActivities,
    teacherId
  );

  // gets pls on the same week day and future single time events on the same week day
  const activitiesInRequestedDate =
    getTeacherActivitiesOnRequestDate(
      formInputs.startDate,
      preparedTeacherActivities,
      teacherId
    ) || [];

  // filters the teacher days and get the start and end times for each day based on the Date provided
  const teacherDaysOnRequestedDate = prepareTeacherDays(
    formInputs.startDate,
    teacherAvailableDays
  );

  const teacherDaysWithAvailableSlots = teacherDaysOnRequestedDate.map(
    (teacherDay) => {
      const { newStartDate: teacherDayStart, newEndDate: teacherDayEnd } =
        teacherDay;

      const busySlots = [...activitiesInRequestedDate].map((activity) => ({
        start_time: activity.start_time,
        end_time: activity.end_time,
      }));
      const preparedBusySlots = prepareBusySlots(
        busySlots,
        teacherDayStart,
        teacherDayEnd
      );

      const duration = parseInt(formInputs.lessonLength);

      let availableTimeSlots = getAvailableTimeSlots(
        teacherDayStart,
        teacherDayEnd,
        preparedBusySlots,
        duration,
        true
      );
      availableTimeSlots = availableTimeSlots.error ? [] : availableTimeSlots;

      const availableSlotsChunks = availableTimeSlots.map((slot) =>
        getTimeChunks(
          slot.start_time,
          slot.end_time,
          duration,
          undefined,
          false
        )
      );

      return {
        ...teacherDay,
        activitiesInRequestedDate,
        teacherDayStart,
        teacherDayEnd,
        preparedBusySlots,
        availableSlots: availableTimeSlots,
        availableSlotsChunks,
      };
    }
  );

  return teacherDaysWithAvailableSlots;
};

const getTeacherActivitiesOnRequestDate = (
  requestDate,
  teacherActivities,
  teacherId
) => {
  if (!requestDate || !teacherId) {
    toast.error("param is not provided to (getTeacherActivitiesOnRequestDate)");
    return;
  }
  if (!teacherActivities?.length) return [];

  requestDate = moment(requestDate)
    .set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    .toDate();

  const requestedDay = moment(requestDate).day();

  const activitiesInRequestedDate =
    teacherActivities
      ?.filter((activity) => {
        // checks if the event is canceled (for all future events + canceled date must be in past)
        // we only care about if the activity is canceled in the future bec we are creating a pl , and pls can only be created if we know for sure that the activity won't appear again in the future as its canceled
        const isFutureCanceledActivity = checkIfCanceledFutureEvents({
          occuranceDate: moment(requestDate).endOf("day"), // end of day bec if the cancelation is on the same day, it will be detected and opens up the slot
          canceledAt: activity.canceledAt,
          canceledDateRanges: activity.canceledRanges,
        });
        if (isFutureCanceledActivity) {
          return false;
        }

        if (isRecurringEvent(activity.type, activity.privateLessonType)) {
          const timelineObj = getFutureTimelineOnWeekday({
            requestDate: requestDate,
            timeline: activity.timeline,
            startDateAccessor: "start_time",
            endDateAccessor: "end_time",
            lastDateAccessor: "lastDate",
            withDuration: false,
          });
          const isSameTeacherOnTimeline = timelineObj?.userId === teacherId;
          return (
            timelineObj?.start_time &&
            requestedDay === moment(timelineObj.start_time).day() &&
            isSameTeacherOnTimeline
          );
        } else {
          return (
            (requestedDay === moment(activity.start_time).day() ||
              requestedDay === moment(activity.end_time).day()) &&
            moment(requestDate).isSameOrBefore(activity.end_time)
          );
        }
      })
      ?.map((activity) => {
        const timelineObj =
          isRecurringEvent(activity.type, activity.privateLessonType) &&
          getFutureTimelineOnWeekday({
            requestDate: requestDate,
            timeline: activity.timeline,
            startDateAccessor: "start_time",
            endDateAccessor: "end_time",
            lastDateAccessor: "lastDate",
          });
        const activityStart = timelineObj?.start_time || activity.start_time;
        const activityEnd = timelineObj?.end_time || activity.end_time;

        const { newStartDate, newEndDate } = getNewDatesOnRequestDateByWeekday(
          requestDate,
          activityStart,
          activityEnd
        );
        return {
          ...activity,
          start_time: newStartDate,
          end_time: newEndDate,
        };
      }) || [];
  return activitiesInRequestedDate;
};

export const getMultiDayTeacherDaysAvailabilities = ({
  teacherId,
  teacherActivities,
  teacherAvailableDays,
  lessonDuration,
  absences,
  schoolBreaks,
  requestedStartDate,
  frequency = groupClassFrequency.DAILY ||
    groupClassFrequency.WEEKLY ||
    groupClassFrequency.MONTHLY ||
    groupClassFrequency.ONCE,
  numberOfIterations,
  hasLocationFilter = false,
  hasVirtualFilter = false,
  locationId,
  isVirtual,
}) => {
  const teacherObj = {
    id: teacherId,
    teacherActivities,
    teacherDays: { availableDays: teacherAvailableDays },
  };
  const valueAddedToDate =
    frequency === groupClassFrequency.DAILY
      ? "days"
      : frequency === groupClassFrequency.WEEKLY
      ? "weeks"
      : frequency === groupClassFrequency.MONTHLY
      ? "months"
      : frequency === groupClassFrequency.ONCE
      ? "days"
      : "";
  if (!valueAddedToDate || !frequency) {
    toast.error("Error with getMultiDayTeacherDaysAvailabilities");
    return;
  }

  numberOfIterations =
    frequency === groupClassFrequency.ONCE ? 1 : numberOfIterations;
  let combinedAvails = [];

  for (let i = 0; i < numberOfIterations; i++) {
    if (numberOfIterations > 2000) {
      toast.error("Error with getMultiDayTeacherDaysAvailabilities");
      break;
    }

    // console.log(i);
    const date = moment(requestedStartDate, "YYYY-MM-DD")
      .add(i, valueAddedToDate)
      .format("YYYY-MM-DD");

    const isDuringSchoolBreak = schoolBreaks?.some((schoolBreak) =>
      checkIfActivityDuringSchoolBreak(
        { start: moment(date, "YYYY-MM-DD").toDate() },
        schoolBreak
      )
    );

    const isSunday =
      moment(date).weekday() ===
      weekDays.find((day) => day.name === "Sunday")?.id;

    // console.log({ isDuringSchoolBreak, date: moment(date).toString() });
    if (isDuringSchoolBreak || isSunday) {
      numberOfIterations++;
      continue;
    }

    const { teacherDaysWithAvailabilitiesData } =
      attachAvailableTimeSlotstoTeacher(
        teacherObj, // {}
        lessonDuration, // int
        date, // date
        schoolBreaks, // []
        absences, //[]
        false
      );

    const invalidLocation = hasLocationFilter
      ? teacherDaysWithAvailabilitiesData.some((availDay) => {
          const passLocationFilter = availDay.location === locationId;

          const isNotAllowed = !passLocationFilter;
          return isNotAllowed;
        })
      : false;
    const invalidVirtualStatus = hasVirtualFilter
      ? teacherDaysWithAvailabilitiesData.some((availDay) => {
          const passVirtualFilter = isVirtual ? true : !availDay.isVirtualOnly;

          const isNotAllowed = !passVirtualFilter;
          return isNotAllowed;
        })
      : false;

    if (
      !teacherDaysWithAvailabilitiesData?.length ||
      teacherDaysWithAvailabilitiesData.some(
        (availDay) => !availDay?.availableSlots?.length
      ) ||
      invalidLocation ||
      invalidVirtualStatus
    ) {
      // if one of the teacher days doesn't have an availability on one day then the whole combinedAvails will be empty
      combinedAvails = [];
      break;
    } else {
      combinedAvails.push(...teacherDaysWithAvailabilitiesData);
    }
  }
  const recurringDates = combinedAvails.map(
    (availableDays) => availableDays.newStartDate
  );

  const merged = mergeTeacherDaysWithAvailabilities(
    combinedAvails.flat(),
    lessonDuration,
    requestedStartDate
  );
  const parsed = merged?.map((teacherDay) => {
    return {
      availableSlotsChunks: teacherDay.availableSlotsChunks,
      availableSlots: teacherDay.availableSlots,
      location: teacherDay.location,
      newStartDate: teacherDay.newStartDate,
      isVirtualOnly: teacherDay.isVirtualOnly,
    };
  });
  return { mergedTeacherDays: parsed, recurringDates };
};
function mergeTeacherDaysWithAvailabilities(
  teacherDaysWithAvailabilities,
  lessonDuration,
  requestedStartDate,
  isAllDaysCanBeMerged = false
) {
  if (!teacherDaysWithAvailabilities?.length) return [];
  teacherDaysWithAvailabilities = teacherDaysWithAvailabilities.map((day) => ({
    ...day,
    availableSlots: day.availableSlots.map((range) => ({
      ...range,
      start_time: moment(range.start_time)
        .set({
          year: moment(requestedStartDate).year(),
          month: moment(requestedStartDate).month(),
          date: moment(requestedStartDate).date(),
        })
        .toDate(),
      end_time: moment(range.end_time)
        .set({
          year: moment(requestedStartDate).year(),
          month: moment(requestedStartDate).month(),
          date: moment(requestedStartDate).date(),
        })
        .toDate(),
    })),
  }));
  let merged = [];
  for (let i = 0; i < teacherDaysWithAvailabilities.length; i++) {
    const day = teacherDaysWithAvailabilities[i];

    const canMergeWithDayIndex = merged.findIndex((teacherDay) =>
      isAllDaysCanBeMerged ? true : canBeMerged(teacherDay, day)
    );
    const canMergeWithDay = merged[canMergeWithDayIndex];
    if (canMergeWithDay) {
      const mergedTwoDays = mergeAvailableDays(
        canMergeWithDay,
        day,
        lessonDuration,
        requestedStartDate
      );
      merged[canMergeWithDayIndex] = mergedTwoDays;
    } else {
      merged.push(day);
    }
  }
  return merged;
}
const canBeMerged = (firstTeacherDay, secondTeacherDay) => {
  if (!firstTeacherDay || !secondTeacherDay) return false;

  const isVirtual =
    firstTeacherDay.isVirtualOnly === secondTeacherDay.isVirtualOnly;

  // const isSameDate = moment(firstTeacherDay.startDate).isSame(
  //   secondTeacherDay.startDate,
  //   "minutes"
  // );
  const isSameDate =
    moment(
      firstTeacherDay.startDate || firstTeacherDay.newStartDate
    ).weekday() ===
    moment(
      secondTeacherDay.startDate || secondTeacherDay.newStartDate
    ).weekday();

  const isSameLocation = firstTeacherDay.location === secondTeacherDay.location;

  return isVirtual && isSameDate && isSameLocation;
};

const mergeAvailableDays = (
  firstTeacherDay,
  secondTeacherDay,
  lessonDuration,
  requestedStartDate
) => {
  if (!firstTeacherDay || !secondTeacherDay) return;
  const firstTimeSlots = firstTeacherDay.availableSlots;
  const secondTimeSlots = secondTeacherDay.availableSlots;
  // const { availableSlots: firstTimeSlots = [] } = firstTeacherDay;
  // const { availableSlots: secondTimeSlots = [] } = secondTeacherDay;

  const flattenedAvailableTimeSlotsOutput = flattenDateRanges(
    firstTimeSlots,
    secondTimeSlots
  )?.map((slot) => ({
    // makes all dates in the requested date
    ...slot,
    start_time: moment(slot.start_time)
      .set({
        year: moment(requestedStartDate).year(),
        month: moment(requestedStartDate).month(),
        date: moment(requestedStartDate).date(),
      })
      .toDate(),
    end_time: moment(slot.end_time)
      .set({
        year: moment(requestedStartDate).year(),
        month: moment(requestedStartDate).month(),
        date: moment(requestedStartDate).date(),
      })
      .toDate(),
  }));
  // console.log({
  //   firstTimeSlots: firstTimeSlots.map((range) => ({
  //     ...range,
  //     start_time: moment(range.start_time).toString(),
  //     end_time: moment(range.end_time).toString(),
  //   })),
  //   secondTimeSlots: secondTimeSlots.map((range) => ({
  //     ...range,
  //     start_time: moment(range.start_time).toString(),
  //     end_time: moment(range.end_time).toString(),
  //   })),
  //   flattenedAvailableTimeSlotsOutput: flattenedAvailableTimeSlotsOutput.map(
  //     (range) => ({
  //       ...range,
  //       start_time: moment(range.start_time).toString(),
  //       end_time: moment(range.end_time).toString(),
  //     })
  //   ),
  // });
  const mergedTimeSlotsChunks = flattenedAvailableTimeSlotsOutput.map((slot) =>
    getTimeChunks(
      slot.start_time,
      slot.end_time,
      lessonDuration,
      undefined,
      false
    )
  );
  const mergedDayObj = {
    availableSlotsChunks: mergedTimeSlotsChunks,
    availableSlots: flattenedAvailableTimeSlotsOutput,
    location: firstTeacherDay.location,
    newStartDate: firstTeacherDay.newStartDate,
    isVirtualOnly: firstTeacherDay.isVirtualOnly,
    startDate: firstTeacherDay.startDate,
    id: firstTeacherDay.id,
  };

  return mergedDayObj;
};

// example of elements in the teachersWithActivitiesAndAvailableDays arr
// const teacher={
//         id: selectedTeacherObj.id,
//         teacherActivities: selectedTeacherObj.teacherActivities,
//         teacherAvailableDays: selectedTeacherObj.teacherAvailableDays
// }
export function getMultiDayTeachersDaysAvailabilities({
  teachersWithActivitiesAndAvailableDays,
  lessonDuration,
  absences,
  schoolBreaks,
  requestedStartDate,
  locationId,
  frequency = groupClassFrequency.DAILY ||
    groupClassFrequency.WEEKLY ||
    groupClassFrequency.MONTHLY ||
    groupClassFrequency.ONCE,
  numberOfIterations,
}) {
  if (
    !teachersWithActivitiesAndAvailableDays?.length ||
    !lessonDuration ||
    !requestedStartDate ||
    !frequency
  )
    return { mergedTeacherDays: [], recurringDates: [] };

  let combinedMergedTeacherDays = [],
    recurringDates = [];

  for (let i = 0; i < teachersWithActivitiesAndAvailableDays.length; i++) {
    const teacherObj = teachersWithActivitiesAndAvailableDays[i];

    const { mergedTeacherDays, recurringDates: currentRecurringDates } =
      getMultiDayTeacherDaysAvailabilities({
        teacherId: teacherObj.id, // {}
        teacherActivities: teacherObj.teacherActivities,
        teacherAvailableDays: teacherObj.teacherAvailableDays,
        lessonDuration, // int
        requestedStartDate, // date
        schoolBreaks, // []
        absences,
        frequency,
        numberOfIterations,
        hasLocationFilter: true,
        hasVirtualFilter: false,
        locationId,
      });

    // makes sure days that has avails in different locations or different virtual status dont show together
    const filteredMergedDays = mergedTeacherDays.filter((day) => {
      const passLocationFilter = day.location === locationId;
      return passLocationFilter;
    });

    if (
      !filteredMergedDays?.length ||
      filteredMergedDays.some((day) => !day.availableSlots?.length)
    ) {
      combinedMergedTeacherDays = [];
      break;
    }

    combinedMergedTeacherDays.push(filteredMergedDays);

    // since recurring dates are garunteed to be the same for each teacher (As it checks for school breaks only), its safe to use any one of the teachers' avails recurring dates so we use the first here
    if (!recurringDates.length) {
      recurringDates = currentRecurringDates;
    }
  }

  //isAllDaysCanBeMerged is true here because we are sure all days can be merged as we have set the virtual and location filter already
  const merged = mergeTeacherDaysWithAvailabilities(
    combinedMergedTeacherDays.flat(),
    lessonDuration,
    requestedStartDate,
    true
  );

  const parsed = merged?.map((teacherDay) => {
    return {
      availableSlotsChunks: teacherDay.availableSlotsChunks,
      availableSlots: teacherDay.availableSlots,
      location: teacherDay.location,
      newStartDate: teacherDay.newStartDate,
      isVirtualOnly: teacherDay.isVirtualOnly,
    };
  });

  return { mergedTeacherDays: parsed, recurringDates };
}
