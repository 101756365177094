import React from "react";
import RouteContent from "src/components/common/RouteContent";
import useSchoolPoliciesData from "./hooks/useSchoolPoliciesData";
import NavigationBar from "./NavigationBar";
import PolicySection from "./PolicySection";

const SchoolPolicies = () => {
  const policiesData = useSchoolPoliciesData();
  const { currentTab, setCurrentTab } = policiesData;
  return (
    <RouteContent title="School Policies">
      <div className="p-3">
        <div className="mb-4">
          <NavigationBar
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
        <div>
          <PolicySection policiesData={policiesData} />
        </div>
      </div>
    </RouteContent>
  );
};

export default SchoolPolicies;
