import React, { useEffect, useMemo, useState } from "react";
import { infoCardViews } from "../constants";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { getUpcomingGroupClassEnrollments } from "src/utils/helpers";

const initialDataInitialValues = {
  groupCLassEnrollments: [],
};

const useGroupClassInfoCard = ({ groupClass }) => {
  const { getGroupClassEnrollments, getUsersByIds } = useFirebaseFns();

  const [currentInfoCardView, setCurrentInfoCardView] = useState(
    infoCardViews.info.value
  );

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const isStudentEnrollmentsView = useMemo(
    () => currentInfoCardView === infoCardViews.enrollments.value,
    [currentInfoCardView]
  );

  const groupClassEnrollments = useMemo(() => {
    if (
      !groupClass?.classes?.length ||
      !initialData.groupCLassEnrollments?.length
    )
      return [];

    const classEnrollments = getUpcomingGroupClassEnrollments(
      groupClass.classes,
      initialData.groupCLassEnrollments
    );

    return classEnrollments;
  }, [groupClass?.id, initialData]);

  useEffect(() => {
    if (!isStudentEnrollmentsView || !groupClass) return;

    async function fetchData() {
      try {
        setLoadingInitialData(true);
        const [enrollments] = await Promise.all([
          getGroupClassEnrollments(groupClass.id),
        ]);

        const studentsIds = [
          ...new Set(enrollments.map(({ studentId }) => studentId)),
        ];

        const students = await getUsersByIds(studentsIds);

        const modifiedEnrollments = enrollments.map((enrollment) => {
          const student = students.find(
            ({ id }) => id === enrollment.studentId
          );
          return {
            ...enrollment,
            student,
          };
        });

        setInitialData({ groupCLassEnrollments: modifiedEnrollments });
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingInitialData(false);
      }
    }
    fetchData();
  }, [currentInfoCardView, groupClass?.id]);

  return {
    currentInfoCardView,
    setCurrentInfoCardView,
    isStudentEnrollmentsView,
    initialData,
    loadingInitialData,
    groupClassEnrollments,
  };
};

export default useGroupClassInfoCard;
