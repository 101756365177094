// input: absence obj from firebase
// output: the absence with parsed dates
export const parseAbsenceObjDates = (absenceObj) => {
  if (!absenceObj) return;

  const {
    createdAt,
    updatedAt,
    approvedAt,
    date,
    startDate,
    endDate,
    makeupOpening,
    request,
    resolve,
  } = absenceObj;
  const modifiedAbsenceObj = {
    ...absenceObj,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(updatedAt && {
      updatedAt: new Date(updatedAt.toDate().setSeconds(0, 0)),
    }),
    ...(date && { date: new Date(date.toDate().setSeconds(0, 0)) }),
    ...(startDate && {
      startDate: new Date(startDate.toDate().setSeconds(0, 0)),
    }),
    ...(endDate && {
      endDate: new Date(endDate.toDate().setSeconds(0, 0)),
    }),

    ...(makeupOpening && {
      makeupOpening: {
        ...makeupOpening,
        ...(makeupOpening.startDate && {
          startDate: new Date(
            makeupOpening.startDate.toDate().setSeconds(0, 0)
          ),
        }),

        ...(makeupOpening.endDate && {
          endDate: new Date(makeupOpening.endDate.toDate().setSeconds(0, 0)),
        }),
      },
    }),
    ...(request && {
      request: {
        ...request,

        ...(request.date && {
          date: new Date(request.date.toDate().setSeconds(0, 0)),
        }),
        ...(request.startDate && {
          startDate: new Date(request.startDate.toDate().setSeconds(0, 0)),
        }),
        ...(request.endDate && {
          endDate: new Date(request.endDate.toDate().setSeconds(0, 0)),
        }),
      },
    }),
    ...(resolve && {
      resolve: {
        ...resolve,

        ...(resolve.resolvedAt && {
          resolvedAt: new Date(resolve.resolvedAt.toDate().setSeconds(0, 0)),
        }),
      },
    }),
  };

  return modifiedAbsenceObj;
};

export const parsePrivateLessonDates = (lessonObj) => {
  if (!lessonObj) return;

  const {
    createdAt,
    updatedAt,
    modifications,
    withdrawal,
    timeline,
    packageSets,
    summerBreaks,
  } = lessonObj;

  let parsedTimeline, parsedPackageSets;
  if (timeline) {
    parsedTimeline = timeline.map((timelineObj) => {
      const { startDate, lastDate, requestedAt } = timelineObj;
      return {
        ...timelineObj,
        ...(startDate && {
          startDate: startDate.toDate(),
        }),
        ...(lastDate && {
          lastDate: lastDate.toDate(),
        }),
        ...(requestedAt && {
          requestedAt: requestedAt.toDate(),
        }),
      };
    });
  }
  if (packageSets) {
    parsedPackageSets = packageSets.map((packageSet) => {
      const { setItems, expirationDate } = packageSet;

      const parsedSetItems = setItems?.map((setItem) => {
        const { startDate, requestedAt } = setItem;
        return {
          ...setItem,
          ...(startDate && {
            startDate: startDate.toDate(),
          }),
          ...(requestedAt && {
            requestedAt: requestedAt.toDate(),
          }),
        };
      });

      return {
        ...packageSet,
        ...(expirationDate && {
          expirationDate: expirationDate.toDate(),
        }),
        ...(setItems && { setItems: parsedSetItems }),
      };
    });
  }

  const parsedSummerBreaks =
    summerBreaks?.map((br) => ({
      ...br,
      requestDate: br.requestDate.toDate(),
      breakStart: br.breakStart.toDate(),
      ...(br.breakEnd && { breakEnd: br.breakEnd.toDate() }),
    })) || [];

  const modifiedObj = {
    ...lessonObj,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(updatedAt && {
      updatedAt: updatedAt.toDate(),
    }),
    ...(timeline && { timeline: parsedTimeline }),
    ...(packageSets && { packageSets: parsedPackageSets }),

    ...(modifications && {
      modifications: modifications.map((modification) => ({
        ...modification,
        date: modification.date.toDate(),
      })),
    }),

    ...(withdrawal && {
      withdrawal: {
        ...withdrawal,
        requestDate: withdrawal.requestDate.toDate(),
        withdrawalDate: withdrawal.withdrawalDate.toDate(),
      },
    }),
    ...(summerBreaks && {
      summerBreaks: parsedSummerBreaks,
    }),
  };
  return modifiedObj;
};
export const parseTrialLessonDates = (lessonObj) => {
  if (!lessonObj) return;

  const { createdAt, date, updatedAt } = lessonObj;

  const modifiedObj = {
    ...lessonObj,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(updatedAt && {
      updatedAt: updatedAt.toDate(),
    }),
    ...(date && {
      date: new Date(date.toDate().setSeconds(0, 0)),
    }),
  };
  return modifiedObj;
};
export const parseMakeupLessonDates = (lessonObj) => {
  if (!lessonObj) return;

  const { createdAt, date, updatedAt } = lessonObj;
  const { startDate, endDate } = date || {};

  const modifiedObj = {
    ...lessonObj,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(updatedAt && {
      updatedAt: updatedAt.toDate(),
    }),

    ...(date && {
      date: {
        ...date,
        ...(startDate && {
          startDate: new Date(startDate.toDate().setSeconds(0, 0)),
        }),
        ...(endDate && {
          endDate: new Date(endDate.toDate().setSeconds(0, 0)),
        }),
      },
    }),
  };
  return modifiedObj;
};

export const parseUserActivityDates = (userActivity) => {
  if (!userActivity) return;
  const {
    start_time,
    end_time,
    createdAt,
    canceledAt,
    canceledDates,
    // modifications,
    timeline,
    packageItems,
    canceledRanges,
    groupClassItems,
  } = userActivity;

  let parsedTimeline, parsedPackageItems, parsedGroupClassItems;
  if (timeline) {
    // in case of recurring event
    parsedTimeline = timeline.map((timelineObj) => {
      const { start_time, end_time, lastDate, requestedAt } = timelineObj;

      return {
        ...timelineObj,
        ...(start_time && {
          start_time: start_time.toDate(),
        }),
        ...(end_time && {
          end_time: end_time.toDate(),
        }),
        ...(lastDate && {
          lastDate: lastDate.toDate(),
        }),
        ...(requestedAt && {
          requestedAt: requestedAt.toDate(),
        }),
      };
    });
  }
  if (packageItems) {
    // in case of package items
    parsedPackageItems = packageItems.map((packageItem) => {
      const { start_time, end_time, requestedAt } = packageItem;

      return {
        ...packageItem,
        ...(start_time && {
          start_time: start_time.toDate(),
        }),
        ...(end_time && {
          end_time: end_time.toDate(),
        }),
        ...(requestedAt && {
          requestedAt: requestedAt.toDate(),
        }),
      };
    });
  }

  if (groupClassItems) {
    parsedGroupClassItems = groupClassItems.map((classItem) => {
      const { start_time, end_time } = classItem;

      return {
        ...classItem,
        ...(start_time && {
          start_time: start_time.toDate(),
        }),
        ...(end_time && {
          end_time: end_time.toDate(),
        }),
      };
    });
  }

  const parsedCanceledRanges =
    canceledRanges?.map((range) => ({
      ...range,
      start: range.start.toDate(),
      ...(range.end && { end: range.end.toDate() }),
    })) || [];

  const modifiedObj = {
    ...userActivity,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(canceledAt && {
      canceledAt: canceledAt.toDate(),
    }),
    ...(canceledDates && {
      canceledDates: canceledDates.map((date) => date.toDate()),
    }),
    ...(canceledRanges && {
      canceledRanges: parsedCanceledRanges,
    }),
    // ...(modifications && {
    //   modifications: modifications.map((modification) => ({
    //     ...modification,
    //     start_time: modification.start_time.toDate(),
    //     end_time: modification.end_time.toDate(),
    //   })),
    // }),
    ...(start_time && {
      start_time: start_time.toDate(),
    }),
    ...(end_time && {
      end_time: end_time.toDate(),
    }),

    ...(timeline && {
      timeline: parsedTimeline,
    }),
    ...(packageItems && {
      packageItems: parsedPackageItems,
    }),
    ...(groupClassItems && {
      groupClassItems: parsedGroupClassItems,
    }),
  };

  return modifiedObj;
};

export const parseBreakDates = (userBreak) => {
  if (!userBreak) return;

  const { date, createdAt, canceledAt, canceledDates, timeline } = userBreak;

  // in case of recurring event
  const parsedTimeline = timeline?.map((timelineObj) => {
    const { date, lastDate } = timelineObj;

    return {
      ...timelineObj,
      ...(date && {
        date: date.toDate(),
      }),
      ...(lastDate && {
        lastDate: lastDate.toDate(),
      }),
    };
  });

  const modifiedObj = {
    ...userBreak,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(canceledAt && {
      canceledAt: canceledAt.toDate(),
    }),
    ...(canceledDates && {
      canceledDates: canceledDates.map((date) => date.toDate()),
    }),
    ...(date && {
      date: date.toDate(),
    }),

    ...(timeline && {
      timeline: parsedTimeline,
    }),
  };

  return modifiedObj;
};

export const parseNotificationDates = (not) => {
  if (!not) return;

  const { createdAt } = not;
  const modifiedObj = {
    ...not,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
  };

  return modifiedObj;
};

export const parseAppointmentDates = (appt) => {
  if (!appt) return;
  const { createdAt, startDate, endDate } = appt;

  const modifiedObj = {
    ...appt,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(startDate && {
      startDate: startDate.toDate(),
    }),
    ...(endDate && {
      endDate: endDate.toDate(),
    }),
  };

  return modifiedObj;
};

export const parseUserAvailableDayDates = (day) => {
  if (!day) return;

  const { userRole, availableDays } = day;
  if (!availableDays?.length) return day;

  const parsedAvailableDays = availableDays.map((availableDay) => {
    const { startDate, endDate } = availableDay;
    return {
      ...availableDay,
      ...(startDate && {
        startDate: startDate.toDate(),
      }),
      ...(endDate && {
        endDate: endDate.toDate(),
      }),
    };
  });

  const modifiedObj = {
    ...day,
    availableDays: parsedAvailableDays,
  };
  return modifiedObj;
};
export const parseGroupClassAvailableDayDates = (day) => {
  if (!day) return;

  const { userRole, availableDays } = day;
  if (!availableDays?.length) return day;

  const parsedAvailableDays = availableDays.map((availableDay) => {
    const { startDate, endDate } = availableDay;
    return {
      ...availableDay,
      ...(startDate && {
        startDate: startDate.toDate(),
      }),
      ...(endDate && {
        endDate: endDate.toDate(),
      }),
    };
  });

  const modifiedObj = {
    ...day,
    availableDays: parsedAvailableDays,
  };
  return modifiedObj;
};

export const parseMakeupOpeningDates = (opening) => {
  if (!opening) return;

  const { startDate, endDate, createdAt } = opening;

  const modifiedObj = {
    ...opening,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(startDate && {
      startDate: startDate.toDate(),
    }),
    ...(endDate && {
      endDate: endDate.toDate(),
    }),
  };
  return modifiedObj;
};
export const parseCalendarLabelDates = (label) => {
  if (!label) return;
  const { timeline, createdAt, date, canceledAt, canceledDates } = label;

  const parsedTimeline = timeline?.map((timelineObj) => {
    const { lastDate, requestedAt, date } = timelineObj;
    return {
      ...timelineObj,
      ...(date && {
        date: date.toDate(),
      }),
      ...(lastDate && {
        lastDate: lastDate.toDate(),
      }),
      ...(requestedAt && {
        requestedAt: requestedAt.toDate(),
      }),
    };
  });

  const modifiedObj = {
    ...label,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(date && {
      date: date.toDate(),
    }),
    ...(canceledAt && {
      canceledAt: canceledAt.toDate(),
    }),
    ...(canceledDates && {
      canceledDates: canceledDates.map((date) => date.toDate()),
    }),
    ...(timeline && {
      timeline: parsedTimeline,
    }),
  };

  return modifiedObj;
};

export const parseFeedItemDates = (feedItem) => {
  if (!feedItem) return;
  const { createdAt, publishDate } = feedItem;

  const modifiedObj = {
    ...feedItem,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(publishDate && {
      publishDate: publishDate.toDate(),
    }),
  };

  return modifiedObj;
};

export const parseUserPaymentRateDates = (paymentRate) => {
  if (!paymentRate) return;
  const { createdAt } = paymentRate;

  const modifiedObj = {
    ...paymentRate,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
  };

  return modifiedObj;
};

export const parseUserPaymentDates = (paymentObj) => {
  if (!paymentObj) return;
  const { createdAt, startDate, endDate } = paymentObj;

  const modifiedObj = {
    ...paymentObj,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(startDate && {
      startDate: startDate.toDate(),
    }),
    ...(endDate && {
      endDate: endDate.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseAttendanceDates = (attendance) => {
  if (!attendance) return;
  const { createdAt, date } = attendance;

  const modifiedObj = {
    ...attendance,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(date && {
      date: date.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseMakeupRequestDates = (makeupRequest) => {
  if (!makeupRequest) return;
  const { createdAt, resolve } = makeupRequest;

  const parsedResolve = resolve
    ? {
        ...resolve,
        resolvedAt: resolve.resolvedAt.toDate(),
      }
    : null;

  const modifiedObj = {
    ...makeupRequest,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(resolve && parsedResolve),
  };

  return modifiedObj;
};

export const parseLessonNoteDates = (note) => {
  if (!note) return;
  const { createdAt, updatedAt } = note;

  const modifiedObj = {
    ...note,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(updatedAt && {
      updatedAt: updatedAt.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseGroupClassDates = (groupClass) => {
  if (!groupClass) return;

  const { createdAt, classes } = groupClass;

  const parsedClasses = classes?.map((classObj) => {
    const { createdAt: classCreatedAt, startDate } = classObj;
    return {
      ...classObj,
      ...(classCreatedAt && {
        createdAt: classCreatedAt.toDate(),
      }),
      ...(startDate && {
        startDate: startDate.toDate(),
      }),
    };
  });

  const modifiedObj = {
    ...groupClass,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),

    ...(classes && {
      classes: parsedClasses,
    }),
  };

  return modifiedObj;
};

export const parseGroupClassEnrollmentDates = (enrollment) => {
  if (!enrollment) return;

  const { createdAt, enrollmentDate } = enrollment;

  const modifiedObj = {
    ...enrollment,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(enrollmentDate && {
      enrollmentDate: enrollmentDate.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseGroupClassPaymentOptionDates = (option) => {
  if (!option) return;

  const { createdAt, paymentFirstDate } = option;

  const modifiedObj = {
    ...option,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(paymentFirstDate && {
      paymentFirstDate: paymentFirstDate.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseGroupClassPaymentDates = (payment) => {
  if (!payment) return;

  const { createdAt, paymentFirstDate } = payment;

  const modifiedObj = {
    ...payment,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(paymentFirstDate && {
      paymentFirstDate: paymentFirstDate.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseConcertObjDates = (concert) => {
  if (!concert) return;

  const { createdAt, date } = concert;

  const modifiedObj = {
    ...concert,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(date && {
      date: date.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseConcertInvitationObjDates = (concertInvitation) => {
  if (!concertInvitation) return;

  const { createdAt, usersResponses } = concertInvitation;
  const parsedUsersResponses = Object.entries(usersResponses || {}).reduce(
    (acc, curr) => {
      const [key, value] = curr;

      const updatedValue = {
        ...value,
        ...(value.createdAt && {
          createdAt: value.createdAt.toDate(),
        }),
      };

      acc[key] = updatedValue;
      return acc;
    },
    {}
  );

  const modifiedObj = {
    ...concertInvitation,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
    ...(usersResponses && {
      usersResponses: parsedUsersResponses,
    }),
  };
  return modifiedObj;
};
export const parseConcertSignupObjDates = (concertSignup) => {
  if (!concertSignup) return;

  const { createdAt } = concertSignup;

  const modifiedObj = {
    ...concertSignup,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
  };

  return modifiedObj;
};
export const parseConcertProgramObjDates = (concertProgram) => {
  if (!concertProgram) return;

  const { createdAt } = concertProgram;

  const modifiedObj = {
    ...concertProgram,
    ...(createdAt && {
      createdAt: createdAt.toDate(),
    }),
  };

  return modifiedObj;
};
