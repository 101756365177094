import React from "react";
import { isActiveTeacher } from "src/utils/helpers";
import { CustomSelectField } from "src/utils/shared/styled";

const TeachersSelectField = ({ teachers = [], value, onChange }) => {
  return (
    <div style={{ marginBottom: "30px" }}>
      <p className="mb-2">
        Please select a teacher to see all of the teacher's availabilities
      </p>
      <CustomSelectField
        width="300px"
        className="m-auto"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="" disabled>
          Select Teacher
        </option>
        {teachers
          .filter((teacher) => isActiveTeacher(teacher))
          .map(({ id, fullName }) => (
            <option key={id} value={id}>
              {fullName}
            </option>
          ))}
      </CustomSelectField>
    </div>
  );
};

export default TeachersSelectField;
