import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const GridContainer = styled.main`
  display: grid;
  grid-template-areas:
    "first first first first first first first second second second"
    "third third third third third third third third third third"
    "third third third third third third third third third third";
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 40vh 25vh 25vh;
  margin-top: 20px;

  @media (max-width: ${ScreenSize.XL}px) {
    grid-template-areas:
      "first first first first"
      "second second second second"
      /* "calendar calendar calendar calendar " */
      "third third third third";
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: unset;
  }
`;
export const GridItem = styled.section`
  grid-area: ${({ gridArea }) => gridArea || "none"};
  background-color: ${({ bgColor }) => bgColor || "none"};
`;

export const GroupClassesListContainer = styled.div`
  margin-top: 10px;
  border-radius: 7px;
  background: #fafafa;
  overflow: hidden;
  padding: 15px;

  & > div {
    overflow: auto;
    max-height: 80vh;

    > p {
      text-align: center;
      font-weight: 600;
      margin-top: 24px;
    }
  }
`;

// CARD
export const GroupClassCardContainer = styled.div`
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: #ebebeb 0px 0px 4px 0px;
`;

// ------------------------------------ HEADER -------------------------------------
export const GroupClassHeader = styled.h5`
  background-color: ${({ bgColor }) => bgColor};
  padding: 10px 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;
// ----------------------------------------------------------------------------------

export const InnerCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
`;
export const ClassInfoContainer = styled.div`
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 4px;
  box-shadow: #ebebeb 0px 0px 4px 0px;
  width: ${({ width }) => width || "auto"};
`;

// ------------------------------------Text Info--------------------------------------
export const TextInfoContainer = styled.div``;
export const InfoPair = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  width: 300px;
`;
export const InfoTitle = styled.span`
  display: inline-block;
  color: #bfbfbf;
  width: 40%;
  text-align: start;
`;

export const InfoText = styled.span`
  display: inline-block;
  width: 60%;
  text-align: start;
`;
// ----------------------------------------------------------------------------------

// ------------------------------------ Teachers Pics --------------------------------------
export const TeachersPicsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  & img {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
`;
// ----------------------------------------------------------------------------------

// ------------------------------------ Rightside btns --------------------------------------
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: end;

  & > div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;
export const StyledButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #e45c6e;

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

export const GroupClassActionButton = styled.button`
  padding: 12px 14px;
  border: 1px solid transparent;

  color: #e45c6eaa;
  font-weight: 600;
  font-size: 16px;

  background-color: #fef993;
  border-radius: 12px;
  outline: none;
  transition-duration: 150ms;

  &:is(:hover, :focus) {
    color: #e45c6e;
    border-color: #e45c6e;
  }
`;
// ----------------------------------------------------------------------------------
