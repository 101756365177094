import { setEventColorByCode } from "../utils";
import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { EventsItem, EventsList, EventTime, EventTitle } from "./styled";
import { isGroupClassEvent } from "src/constants/eventsEnum";
import { Badge } from "antd";

const ScheduleReminderModal = ({ isOpen, toggle, events }) => {
  const eventMarkup = (event, index) => (
    <EventsItem
      color={setEventColorByCode(event?.eventCode, event?.isCanceled)}
      key={index}
    >
      <EventTitle>{event?.title}</EventTitle>
      <EventTime>
        {event?.start?.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        })}{" "}
        -{" "}
        {event?.end?.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </EventTime>
    </EventsItem>
  );

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>
        Today's schedule ({new Date().toLocaleDateString("en-US")})
      </StyledModalHeader>
      <ModalBody>
        <EventsList>
          {events?.length ? (
            events?.map((event, index) =>
              isGroupClassEvent(event?.eventCode) && event?.isCanceled ? (
                <Badge.Ribbon
                  text="Canceled"
                  placement="end"
                  color="#2196f3"
                  style={{ fontSize: 20 }}
                >
                  {eventMarkup(event, index)}
                </Badge.Ribbon>
              ) : (
                eventMarkup(event, index)
              )
            )
          ) : (
            <li style={{ textAlign: "center", fontSize: 18, fontWeight: 600 }}>
              No classes today
            </li>
          )}
        </EventsList>
      </ModalBody>
    </StyledModal>
  );
};

export default ScheduleReminderModal;
