import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UserRole } from "src/constants/UserRoleEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { v4 as uuidv4 } from "uuid";

const useRatesData = ({
  ratesValuesInitialValues,
  user,
  selectedUser,
  refreshUserData,
  lockRates,
}) => {
  const { createUserPaymentRates } = useFirebaseFns();
  const [ratesValues, setRatesValues] = useState([]);

  const [loadingSavingRates, setLoadingSavingRates] = useState(false);

  function handleRatesValuesChange({ title, type, value }) {
    const valuesCopy = [...ratesValues];
    const existingValueObj = valuesCopy.find(
      (val) => val.title === title && val.type === type
    );
    if (existingValueObj) {
      if (value) {
        setRatesValues((oldVal) =>
          oldVal.map((val) =>
            val.title === title && val.type === type ? { ...val, value } : val
          )
        );
      } else {
        setRatesValues((oldVal) =>
          oldVal.filter((val) =>
            val.title === title && val.type === type ? false : true
          )
        );
      }
    } else {
      setRatesValues((oldVal) => [...oldVal, { title, type, value }]);
    }
  }

  async function saveNewRates() {
    try {
      if (!selectedUser) {
        toast.error("Error with saveNewRates fn");
        return;
      }
      if (!ratesValues.length) {
        toast.warn("Please fill at least 1 rate value");
        return;
      }
      setLoadingSavingRates(true);

      const ratesValuesWithIds = ratesValues.map((val) => ({
        id: uuidv4(),
        ...val,
      }));

      const newRatesObj = {
        createdAt: new Date(),
        createdBy: user.uid,

        userId: selectedUser.id,
        userRole: selectedUser.role,
        rates: ratesValuesWithIds,
      };
      console.log(newRatesObj);
      const res = await createUserPaymentRates(newRatesObj);
      if (res.id) {
        toast.success("Saved Successfully");
        refreshUserData();
        lockRates();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingSavingRates(false);
    }
  }

  useEffect(() => {
    if (ratesValuesInitialValues) {
      setRatesValues(ratesValuesInitialValues);
    } else {
      setRatesValues([]);
    }
  }, [ratesValuesInitialValues]);

  return {
    ratesValues,
    handleRatesValuesChange,
    saveNewRates,
    loadingSavingRates,
  };
};

export default useRatesData;
