import React, { useContext, useEffect, useMemo, useState } from "react";
import { getDoc, getDocs, query, where } from "firebase/firestore";
import { UserRole } from "../../../../../constants/UserRoleEnum";
import { parseFirebaseDoc } from "../../../../../utils/getFirebaseDoc";
import { FirebaseContext } from "../../../../Firebase";
import axios from "axios";
import { useInitialPageDataContext } from "../../../../../contexts/InitialPageDataContext";
import {
  parseMakeupOpeningDates,
  parseUserAvailableDayDates,
} from "src/utils/firebaseDatesParserFns";
import { teachersDisplayOptions } from "../constants";
import { isActiveTeacher } from "src/utils/helpers";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useTeacherData = () => {
  const { getTeachersGroupClassDays } = useFirebaseFns();
  const firebase = useContext(FirebaseContext);
  const {
    instruments,
    locations,
    programs,
    loading: loadingInitialData,
  } = useInitialPageDataContext();
  // Initializing states
  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const [teachersList, setTeachersList] = useState([]);
  const [loadingTeachersList, setLoadingTeachersList] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [teachersDisplayOption, setTeachersDisplayOption] = useState(
    teachersDisplayOptions.ACTIVE
  );

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);

  const handleSelectedLocationsChange = (selectedLessons) => {
    setSelectedLocations(selectedLessons);
  };

  const handleSelectedInstrumentsChange = (selectedInstruments) => {
    setSelectedInstruments(selectedInstruments);
  };

  const getTeachers = async () => {
    try {
      const teachersQuery = query(
        firebase.users(),
        where("role", "==", UserRole.TEACHER)
      );
      const teachersSnap = await getDocs(teachersQuery);
      const teachers = parseFirebaseDoc(teachersSnap.docs);
      return teachers;
    } catch (err) {
      console.log(err);
    }
  };
  // Gets all available days for the teachers
  const getTeachersAvailableDays = async () => {
    try {
      const availableDaysQuery = query(
        firebase.usersAvailableDays(),
        where("userRole", "==", UserRole.TEACHER)
      );
      const availableDaysSnapshot = await getDocs(availableDaysQuery);
      const availableDays = parseFirebaseDoc(availableDaysSnapshot.docs);
      const availableDaysWithDates = availableDays.map((day) =>
        parseUserAvailableDayDates(day)
      );
      return availableDaysWithDates;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  const getTeachersMakeUpOpenings = async () => {
    try {
      const makeupOpeningsQuery = query(
        firebase.makeupOpenings(),
        where("userRole", "==", UserRole.TEACHER)
      );
      const openingsSnapshot = await getDocs(makeupOpeningsQuery);
      const makeupOpenings = parseFirebaseDoc(openingsSnapshot.docs);

      const makeupOpeningsWithDates = makeupOpenings.map((opening) =>
        parseMakeupOpeningDates(opening)
      );
      return makeupOpeningsWithDates;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  useEffect(() => {
    if (!loadingInitialData) {
      const fetchAllTeachers = async () => {
        try {
          setLoadingTeachersList(true);

          const [
            teachers,
            teachersAvailabilities,
            makeupOpenings,
            groupClassDays,
          ] = await Promise.all([
            getTeachers(),
            getTeachersAvailableDays(),
            getTeachersMakeUpOpenings(),
            getTeachersGroupClassDays(),
          ]);
          if (teachers?.length) {
            const teachersList = teachers.map((teacher) => {
              const teacherWithDays = addAvailabilitiesToTeacher(
                teacher,
                teachersAvailabilities
              );

              const teacherWithInstruments = addInstrumentsToTeacher(
                teacherWithDays,
                instruments,
                programs
              );
              const teacherWithMakeUpOpenings = addMakeUpOpeningsToTeacher(
                teacherWithInstruments,
                makeupOpenings
              );
              const teacherWithGroupClassesDays = addGroupClassDaysToTeacher(
                teacherWithMakeUpOpenings,
                groupClassDays
              );
              return teacherWithGroupClassesDays;
            });
            setTeachersList(teachersList);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setLoadingTeachersList(false);
        }
      };
      fetchAllTeachers();
    }
  }, [refresh, loadingInitialData]);

  const displayedTeachers = useMemo(() => {
    const filteredTeachers = teachersList.filter((teacher) => {
      const isActive = isActiveTeacher(teacher);
      const displayableTeacher =
        (isActive && teachersDisplayOption === teachersDisplayOptions.ACTIVE) ||
        (!isActive &&
          teachersDisplayOption === teachersDisplayOptions.INACTIVE);

      let passSearchFilter = true;
      let passLocationFilter = true;
      let passInstrumentsFilter = true;

      if (searchTerm) {
        passSearchFilter = teacher?.fullName
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase());
      }
      if (selectedLocations.length) {
        const teacherLocationsIds =
          teacher.teacherDays?.availableDays?.map(({ location }) => location) ||
          [];

        passLocationFilter = teacherLocationsIds.some((locationId) =>
          selectedLocations.map(({ value }) => value).includes(locationId)
        );
      }

      if (selectedInstruments?.length) {
        const teacherInstruments =
          teacher.instrumentsInfo?.map((item) => item?.instrument?.id) || [];

        passInstrumentsFilter = teacherInstruments.some((instrumentId) =>
          selectedInstruments?.map(({ value }) => value).includes(instrumentId)
        );
      }

      return (
        displayableTeacher &&
        passSearchFilter &&
        passLocationFilter &&
        passInstrumentsFilter
      );
    });

    return filteredTeachers;
  }, [
    searchTerm,
    teachersList,
    selectedLocations,
    teachersDisplayOption,
    selectedInstruments,
  ]);
  // loading state for wheter either of the initial data or the teachers list are loading
  const loading = useMemo(
    () => loadingInitialData || loadingTeachersList,
    [loadingInitialData, loadingTeachersList]
  );
  return {
    teachersList,
    loadingTeachersList,
    loading,
    refreshData,
    setSearchTerm,
    searchTerm,
    displayedTeachers,
    selectedLocations,
    selectedInstruments,
    handleSelectedLocationsChange,
    handleSelectedInstrumentsChange,
    teachersDisplayOption,
    setTeachersDisplayOption,
  };
};

// Adds the teacherDays obj to each teacher in the list
const addAvailabilitiesToTeacher = (teacher = {}, availabilities = []) => {
  const teacherAvailability =
    availabilities.find((avail) => avail.id === teacher.id) || {};
  if (Object.keys(teacherAvailability).length > 1) {
    return {
      ...teacher,
      teacherDays: teacherAvailability,
    };
  } else {
    return teacher;
  }
};
const addGroupClassDaysToTeacher = (teacher = {}, availabilities = []) => {
  const teacherAvailability =
    availabilities.find((avail) => avail.id === teacher.id) || {};
  if (Object.keys(teacherAvailability).length > 1) {
    return {
      ...teacher,
      groupClassDays: teacherAvailability,
    };
  } else {
    return teacher;
  }
};
const addMakeUpOpeningsToTeacher = (teacher = {}, makeupOpenings = []) => {
  const teacherOpenings =
    makeupOpenings?.filter((opening) => opening.userId === teacher.id) || [];
  return {
    ...teacher,
    makeupOpenings: teacherOpenings,
  };
};
const addInstrumentsToTeacher = (
  teacher = {},
  instruments = [],
  programs = []
) => {
  if (!instruments?.length && !programs?.length) return teacher;
  const { id, instrumentsInfo } = teacher || {};
  if (instrumentsInfo?.length) {
    const teachersInstruments = instrumentsInfo.map((instrumentInfo) => ({
      ...instrumentInfo,
      instrument: instruments.find(
        (instrument) => instrument?.id === instrumentInfo?.instrument
      ),
      program: programs.find(
        (program) => program?.id === instrumentInfo?.program
      ),
    }));
    return { ...teacher, instrumentsInfo: teachersInstruments };
  } else {
    return teacher;
  }
};

export default useTeacherData;
