import React from "react";
import { useDrop } from "react-dnd";
import { Timestamp } from "firebase/firestore";
import { injectUserStore } from "../../../utils/helpers";
import TeacherContainer from "./TeacherContainer";
import styled from "styled-components";

const TD = styled.td`
  border-width: 1px;
  border-color: #d6d6d6;
  border-style: solid;
  padding: 30px;
  text-align: center;
`;

const TableCell = ({
  weekday,
  studio_id,
  data = [],
  UserStore,
  addUsage,
  teachers,
  deleteUsage,
}) => {
  const [dropProps, drop] = useDrop(() => ({
    accept: "teacher",
    drop: (monitor) => {
      let data = {
        teacher_id: monitor.teacher_id,
        studio_id: studio_id,
        weekday: weekday,
        createdAt: Timestamp.now(),
        createdBy: UserStore.user.uid,
      };
      addUsage(data);
    },
  }));
  return (
    <TD ref={drop}>
      {data.map((item) => {
        return (
          <TeacherContainer
            teachers={teachers}
            key={item.id}
            teacher_id={item.teacher_id}
            id={item.id}
            deleteUsage={deleteUsage}
          />
        );
      })}
    </TD>
  );
};

export default injectUserStore(TableCell);
