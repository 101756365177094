import moment from "moment";
import React from "react";
import { isStudent } from "src/constants/UserRoleEnum";
import { isSameDayOrPassedDeadline, updatedMomentNow } from "src/utils/helpers";
import styled from "styled-components";
import DateRow from "../../../AvailabilitiesComponents/DateRow";
import TeacherRow from "../../../AvailabilitiesComponents/TeacherRow";
import { makeupViews } from "../../constants";

const SectionContainer = styled.div`
  min-height: 400px;
  box-shadow: 0 0px 5px 0px #e8e8e8;
  border-radius: 10px;
  padding: 20px;
`;
const TeacherAvailabilitiesGrid = ({ makeupData, requestedDateData, user }) => {
  const { currentView, dateViewData, teachersViewData } = makeupData;

  const renderDateView = () => {
    const { teachersWithAvailableTimeSlots } = dateViewData || {};
    if (!teachersWithAvailableTimeSlots?.length) {
      return (
        <div
          style={{ height: "50vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h1 className="text-center">No Teachers are available on this day</h1>
        </div>
      );
    } else if (
      isSameDayOrPassedDeadline(
        updatedMomentNow().toDate(),
        requestedDateData.requestedDate
      ) &&
      isStudent(user?.role)
    ) {
      return (
        <div
          style={{ height: "50vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h1 className="text-center">
            Can't schedule the make up this day after the daily make up deadline
          </h1>
        </div>
      );
    } else {
      return (
        <SectionContainer>
          {teachersWithAvailableTimeSlots?.map((teacher) => {
            return (
              <div key={teacher.id}>
                <TeacherRow
                  teacher={teacher}
                  selectedSlotInfo={makeupData.selectedMakeupLesson}
                  handleSelectedSlotInfoChange={
                    makeupData.handleSelectMakeupLesson
                  }
                  requestedDate={requestedDateData.requestedDate}
                />
                <hr />
              </div>
            );
          })}
        </SectionContainer>
      );
    }
  };

  const renderTeachersView = () => {
    const {
      selectedTeacherId,
      handleSelectedTeacherChange,
      selectedTeacherWithAvailableTimeSlots,
    } = teachersViewData || {};

    const { fullName, selectedTeacherAvailabilities } =
      selectedTeacherWithAvailableTimeSlots || {};

    if (!selectedTeacherWithAvailableTimeSlots) {
      return (
        <div
          style={{ height: "50vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h1 className="text-center">Please Select a Teacher</h1>
        </div>
      );
    } else {
      return (
        <SectionContainer>
          <h2 className="text-center mb-5">{fullName || "N/A"}</h2>
          {selectedTeacherAvailabilities?.map((avail) => {
            const passedDeadline = isSameDayOrPassedDeadline(
              updatedMomentNow().toDate(),
              avail.date
            );
            const isUnavailableDate = passedDeadline && isStudent(user?.role);
            return isUnavailableDate ? (
              <></>
            ) : (
              <div key={avail.date}>
                <DateRow
                  dateAvailabilities={avail}
                  handleSelectedSlotInfoChange={
                    makeupData.handleSelectMakeupLesson
                  }
                  isUnavailableDate={isUnavailableDate}
                  selectedSlotInfo={makeupData.selectedMakeupLesson}
                  teacher={selectedTeacherWithAvailableTimeSlots}
                />
                <hr />
              </div>
            );
          })}
        </SectionContainer>
      );
    }
  };

  return currentView === makeupViews.DATE
    ? renderDateView()
    : renderTeachersView();
};

export default TeacherAvailabilitiesGrid;
