import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";
import styled from "styled-components";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

export const CustomModal = styled(Modal)`
  min-width: 650px;
  max-width: 650px;
  & .modal-content {
    border: none;
    background: transparent;
  }

  @media (max-width: ${ScreenSize.MD}px) {
    min-width: 95%;
    max-width: 95%;
  }
`;
export const CustomHeader = styled(ModalHeader)`
  background-color: #681e46;
  border-radius: 30px 30px 0 0;
`;
export const CustomBody = styled(ModalBody)`
  padding: 0 !important;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;

export const BodyInnerWrapper = styled.div`
  background-color: #fff;

  border-radius: 0 0 30px 30px;
`;
export const CurveContainer = styled.div`
  /* background-color: #681e46; */
  background-color: #fff;
`;

export const ImageContainer = styled.div`
  margin: 30px;
  margin-top: 0;
`;
export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 55px;
  height: 55px;
  object-fit: cover;
`;
export const Header = styled.h4`
  font-size: 1.4rem;
`;
export const InfoText = styled.p`
  margin-bottom: 2px;
`;
