import { getDocs } from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "reactstrap";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import {
  formatAttendanceList,
  getCombinedPackageSetItems,
  updatedMomentNow,
} from "src/utils/helpers";
import {
  AttendanceContext,
  AttendanceModalContext,
} from "../../common/contexts/AdminAttendanceContext";
import CustomDropDown from "../../common/DropDown";
import { FirebaseContext } from "../../Firebase";
import AttendanceBlock from "./AttendanceBlock";
import ExtraAttendanceBlock from "./ExtraAttendanceBlock";

const AttendanceListContainer = () => {
  const {
    instruments,
    schoolYears,
    selectedStudent,
    isReadOnly,
    setIsReadOnly,
  } = useContext(AttendanceContext);
  const { isOpen } = useContext(AttendanceModalContext);
  const firebase = useContext(FirebaseContext);
  const [selectedYear, setSelectedYear] = useState({
    label: "School Year",
    value: "",
  });
  const [selectedInstrument, setSelectedInstrument] = useState({
    label: "Instrument",
    value: "",
  });
  const [attendance, setAttendance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let attendanceContainerRef = useRef(null);
  const selectedLesson = useMemo(() => {
    if (selectedStudent?.lessons?.length && selectedInstrument.value !== "") {
      return (
        selectedStudent.lessons.find(
          (lesson) => lesson.instrumentId === selectedInstrument.value
        ) || {}
      );
    }
    return {};
  }, [selectedStudent, selectedInstrument]);
  const yearsOptions = useMemo(() => {
    const options = schoolYears.map((schoolYears) => ({
      label: schoolYears.title,
      value: schoolYears.id,
    }));
    setSelectedYear((previous) => options?.[0] ?? previous);
    return options;
  }, [schoolYears]);
  const instrumentsOptions = useMemo(() => {
    if (selectedStudent?.lessons?.length) {
      const options = instruments
        .filter(
          ({ id }) =>
            selectedStudent.lessons.filter(
              ({ instrumentId }) => instrumentId === id
            ).length > 0
        )
        .map((instrument) => ({
          label: instrument.name,
          value: instrument.id,
        }));
      setSelectedInstrument((previous) => options?.[0] ?? previous);
      return options;
    }
    return [];
  }, [instruments, selectedStudent]);
  let attendanceArray = useMemo(
    () => formatAttendanceList(attendance, selectedLesson),
    [attendance, selectedLesson]
  );
  const getLessonAttendance = async () => {
    if (selectedStudent?.lessons?.length && selectedInstrument.value !== "") {
      const isPackageLsn = isPackagePrivateLesson(selectedLesson.type);
      const snap = await getDocs(
        firebase.getLessonAttendanceList(
          selectedLesson.lessonId,
          selectedStudent.id
        )
      );
      const attendancesList = parseFirebaseDoc(snap.docs);
      const docsObj = {
        extra: [],
      };

      const currentYear = schoolYears.find(
        ({ id }) => id === selectedYear.value
      );
      const schoolStartDates = [];
      const schoolEndDates = [];
      for (let locationId in currentYear.locations) {
        if (currentYear.locations?.[locationId]?.dates?.start_date) {
          schoolStartDates.push(
            moment(currentYear.locations[locationId].dates.start_date.toDate())
          );
          schoolEndDates.push(
            moment(currentYear.locations[locationId].dates.end_date.toDate())
          );
        }
      }
      const yearStartDate = moment.min(schoolStartDates);
      const yearEndDate = moment.max(schoolEndDates);
      attendancesList.forEach((attendanceItem) => {
        const attendanceDate = moment(attendanceItem.date.toDate());
        if (isPackageLsn) {
          const combinedPackageSetItems = getCombinedPackageSetItems(
            selectedLesson.packageSets
          );
          // week number here only represents the number of the package item
          const packageItemsWithWeekNumber = combinedPackageSetItems?.map(
            (item, i) => ({
              ...item,
              weekNumber: i + 1,
            })
          );
          const attendancePackageItem = packageItemsWithWeekNumber.find(
            ({ startDate }) =>
              moment(startDate.toDate()).isSame(attendanceItem.date.toDate()),
            "minute"
          );
          if (attendancePackageItem) {
            docsObj[attendancePackageItem.weekNumber] = attendanceItem;
          }
        } else {
          if (attendanceDate.isBetween(yearStartDate, yearEndDate)) {
            if (attendanceItem?.title) {
              docsObj.extra.push(attendanceItem);
            } else {
              docsObj[attendanceItem.weekNumber] = attendanceItem;
            }
          }
        }
      });
      setAttendance(docsObj);
    }
  };
  if (
    attendanceContainerRef.current &&
    attendanceContainerRef.current.scrollTop === 0
  ) {
    let elementsCount = 0;
    const count = Object.keys(attendance).length;
    for (let i = 1; i < count; i++) {
      if (attendance?.[i]) {
        elementsCount++;
      } else {
        break;
      }
    }
    const attendanceBlocks =
      document.getElementsByClassName("attendance-block");
    const lastBlockInRow = attendanceBlocks[elementsCount - 1];
    if (lastBlockInRow) {
      const blockBoundingRect = lastBlockInRow.getBoundingClientRect();
      const containersBounding =
        attendanceContainerRef.current.getBoundingClientRect();
      attendanceContainerRef.current.scrollTop =
        blockBoundingRect.y -
        containersBounding.y +
        (containersBounding.right - blockBoundingRect.right <
        blockBoundingRect.width
          ? blockBoundingRect.height
          : 0) +
        5;
    }
  }
  useEffect(async () => {
    if (selectedYear?.value) {
      const currentYear = schoolYears.find(
        ({ id }) => id === selectedYear.value
      );
      const schoolEndDates = [];
      for (let locationId in currentYear.locations) {
        if (currentYear.locations?.[locationId]?.dates?.end_date) {
          schoolEndDates.push(
            moment(currentYear.locations[locationId].dates.end_date.toDate())
          );
        }
      }
      const yearEndDate = moment.max(schoolEndDates);

      setIsReadOnly(yearEndDate.isBefore(updatedMomentNow()));
    }
    if (!isOpen) {
      setIsLoading(true);
      await getLessonAttendance();
      setIsLoading(false);
    }
  }, [selectedStudent, selectedInstrument, isOpen, isReadOnly, selectedYear]);
  const onYearSelect = (val) => {
    setSelectedYear(val);
  };
  const onInstrumentSelect = (val) => {
    setSelectedInstrument(val);
  };
  if (attendance?.extra) {
    // attendanceArray.push(...attendance.extra.map((attendanceObject, index) => {
    //     return {
    //         ...attendanceObject,
    //         title: `${attendanceObject.title} ${(index + 1)}`,
    //         attendanceDate: moment(attendanceObject.date.toDate()).format("MM-DD-YYYY hh:mmA"),
    //     };
    // }));
    attendanceArray.push(
      ...attendance.extra.map((attendanceObject, index) => {
        return {
          ...attendanceObject,
          title: `${attendanceObject.title} ${index + 1}`,
          attendanceDate: moment(attendanceObject.date.toDate()).format(
            "MM-DD-YYYY hh:mmA"
          ),
        };
      })
    );
    // attendanceArray.sort((a, b) => moment(a.attendanceDate, "MM-DD-YYYY hh:mmA").isBefore(moment(b.attendanceDate, "MM-DD-YYYY hh:mmA")) ? -1 : 1)
  }
  return (
    <div className="col p-4">
      <div className="row mb-4">
        <div className="col">
          <CustomDropDown
            label={selectedYear.label}
            value={selectedYear.label}
            keyName={"label"}
            options={yearsOptions}
            onOptionClick={onYearSelect}
          />
        </div>
        <div className="col">
          <CustomDropDown
            label={selectedInstrument.label}
            value={selectedInstrument.label}
            keyName={"label"}
            options={instrumentsOptions}
            onOptionClick={onInstrumentSelect}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spinner />
        </div>
      ) : selectedStudent?.lessons?.length &&
        selectedInstrument.value !== "" ? (
        <div
          ref={attendanceContainerRef}
          className="d-flex align-items-center flex-wrap pb-2"
          style={{ height: 500, maxHeight: "70vh", overflowY: "scroll" }}
        >
          {attendanceArray.map((date, i) => (
            <AttendanceBlock attendance={date} key={i} />
          ))}
          {!isReadOnly && !selectedLesson.isPackageLsn ? (
            <ExtraAttendanceBlock lessonId={selectedLesson.lessonId} />
          ) : null}
        </div>
      ) : (
        <div className={"alert alert-warning text-center"}>
          Select a student and a lesson to view attendance
        </div>
      )}
    </div>
  );
};
export default AttendanceListContainer;
