export const notificationSettingsScopes = {
  USER: "user",
  SCHOOL: "school",
};
export const notificationSettingsTypes = {
  EMAIL: "email",
  INTERNAL: "internal",
};
export const emailNotificationsList = {
  //Teachers
  privateLessonWithdrawalToTeacher: "privateLessonWithdrawalToTeacher",
  newPrivateLessonToTeacher: "newPrivateLessonToTeacher",
  newTrialLessonToTeacher: "newTrialLessonToTeacher",
  scheduleReminderToTeacher: "scheduleReminderToTeacher",
  newPaymentToTeacher: "newPaymentToTeacher",
  newSDCToTeacher: "newSDCToTeacher",
  absenceConfirmation: "absenceConfirmation",

  //Students
  newMakeUpLessonToStudent: "newMakeUpLessonToStudent",
  makeUpReminderToStudent: "makeUpReminderToStudent",
  newTrialLessonToStudent: "newTrialLessonToStudent",
  trialLessonReminderToStudent: "trialLessonReminderToStudent",
  newLessonNoteToStudent: "newLessonNoteToStudent",
  editedLessonNoteToStudent: "editedLessonNoteToStudent",
  approvedMakeupRequestToStudent: "approvedMakeupRequestToStudent",
  declinedMakeupRequestToStudent: "declinedMakeupRequestToStudent",
  newPLScheduleChangeToStudent: "newPLScheduleChangeToStudent",
  newGroupClassEnrollmentToStudent: "newGroupClassEnrollmentToStudent",
  newSDCToStudent: "newSDCToStudent",

  //Admins
  newPaymentToAdmin: "newPaymentToAdmin",

  //Super Admins
  newPaymentToSuperAdmin: "newPaymentToSuperAdmin",
  newMakeupRequestToSuperAdmin: "newMakeupRequestToSuperAdmin",
};
export const internalNotificationsList = {
  // Teachers
  newSharedLibraryItemToTeacher: "newSharedLibraryItemToTeacher",
  newAbsenceToTeacher: "newAbsenceToTeacher",
  newMakeUpLessonToTeacher: "newMakeUpLessonToTeacher",
  newStudentHwSubmissionToTeacher: "newStudentHwSubmissionToTeacher",
  newPaymentToTeacher: "newPaymentToTeacher",
  newConcertSignupToTeacher: "newConcertSignupToTeacher",

  // Students
  newSharedLibraryItemToStudent: "newSharedLibraryItemToStudent",
  newAbsenceToStudent: "newAbsenceToStudent",
  newMakeUpLessonToStudent: "newMakeUpLessonToStudent",
  newTeacherHwCommentToStudent: "newTeacherHwCommentToStudent",

  //admins
  newAbsenceToAdmin: "newAbsenceToAdmin",
  newMakeUpLessonToAdmin: "newMakeUpLessonToAdmin",
  newStudentHwSubmissionToAdmin: "newStudentHwSubmissionToAdmin",
  newPaymentToAdmin: "newPaymentToAdmin",
  newConcertSignupToAdmin: "newConcertSignupToAdmin",

  //super admins
  newAbsenceToSuperAdmin: "newAbsenceToSuperAdmin",
  newMakeUpLessonToSuperAdmin: "newMakeUpLessonToSuperAdmin",
  newStudentHwSubmissionToSuperAdmin: "newStudentHwSubmissionToSuperAdmin",
  newPaymentToSuperAdmin: "newPaymentToSuperAdmin",
  newConcertSignupToSuperAdmin: "newConcertSignupToSuperAdmin",
};

export const isUserScopeNotificationSettings = (scope) => {
  return scope === notificationSettingsScopes.USER;
};
export const isSchoolScopeNotificationSettings = (scope) => {
  return scope === notificationSettingsScopes.SCHOOL;
};
export const isEmailTypeNotificationSettings = (type) => {
  return type === notificationSettingsTypes.EMAIL;
};
export const isInternalTypeNotificationSettings = (type) => {
  return type === notificationSettingsTypes.INTERNAL;
};
