import React, { useMemo } from "react";
import StyledBarContent from "src/components/common/StyledBarContent";
import { paymentTypes } from "src/constants/paymentsEnum";
import usePaymentInfo from "../hooks/usePaymentInfo";
import { ContentContainer, IconsContainer, MainHeader } from "../styled";
import OtherPaymentsSection from "./OtherPaymentsSection";
import PrivateLessonsSection from "./PrivateLessonsSection";
import { Edit } from "@styled-icons/fluentui-system-filled/Edit";
import { DeleteOff } from "@styled-icons/fluentui-system-filled/DeleteOff";
import useModal from "src/hooks/useModal";
import EditPaymentModal from "../../EditPaymentModal";
import {
  calculatePaymentTotal,
  convertPaymentDateToDateStr,
  injectUserStore,
} from "src/utils/helpers";
import { isSuperAdmin, isTeacher } from "src/constants/UserRoleEnum";
import ConfirmationModal from "../../ConfirmationModal";
import { CustomTextarea } from "src/utils/shared/styled";

const paymentsSortOrder = [
  paymentTypes.SALARY,
  paymentTypes.REGULAR_CLASS,
  paymentTypes.SDC,
  paymentTypes.BONUS,
  paymentTypes.REIMBURSEMENT,
  paymentTypes.WORK_HOURS,
  paymentTypes.GROUP_CLASS,
  paymentTypes.TRAINING,
  paymentTypes.RESERVATION,
  paymentTypes.ADMINISTRATIVE,
  paymentTypes.EXTENDED_HOURS,
  paymentTypes.PAID_BREAKS,
  paymentTypes.SIGN_UPS,
  paymentTypes.EA,
];

const PaymentInfoBody = ({
  paymentObj,
  modalData,
  onPaymentDeleteSuccess,
  UserStore,
}) => {
  const user = UserStore.user;
  const canEditPayment = isSuperAdmin(user?.role);

  const isTeacherUser = isTeacher(paymentObj.userRole);

  const editPaymentModalData = useModal();
  const deleteConfirmationModalData = useModal();

  const paymentInfo = usePaymentInfo({
    paymentObj,
    closePaymentInfoModal: modalData.closeModal,
    onPaymentDeleteSuccess,
  });
  const { initialData, loadingInitialData } = paymentInfo;
  const { paymentRatesObj } = initialData;

  const { startDate, endDate, payments, paymentMethod, note } = paymentObj;
  const paymentsList = payments || [];

  const startStr = convertPaymentDateToDateStr(startDate);
  const endStr = convertPaymentDateToDateStr(endDate);

  const teachingPeriod = `${startStr} - ${endStr}`;

  const paymentTotal = calculatePaymentTotal(paymentsList);

  const privateLessonPayments = [];
  const filteredPayments = [];
  for (const payment of paymentsList) {
    const { type } = payment;
    if ([paymentTypes.REGULAR_CLASS, paymentTypes.SDC].includes(type)) {
      privateLessonPayments.push(payment);
    } else {
      filteredPayments.push(payment);
    }
  }
  const sortedFilteredPayments = useMemo(() => {
    const sorted = filteredPayments.sort((a, b) => {
      return (
        paymentsSortOrder.indexOf(a.type) - paymentsSortOrder.indexOf(b.type)
      );
    });
    return sorted;
  }, [filteredPayments]);
  const eaPayment = paymentsList.find(({ type }) => type === paymentTypes.EA);

  const handleApproveOption = async () => {
    await paymentInfo.deletePayment();
  };
  const handleCancelOption = () => {
    deleteConfirmationModalData.closeModal();
  };

  return (
    <div>
      <StyledBarContent title="Payment Details" overFlowContent="visible">
        <ContentContainer>
          <MainHeader>
            {isTeacherUser ? "Teaching" : "Work"} Period: {teachingPeriod}
          </MainHeader>
          <IconsContainer className="mt-4">
            {canEditPayment && (
              <>
                <Edit onClick={editPaymentModalData.openModal} />
                <DeleteOff onClick={deleteConfirmationModalData.openModal} />
              </>
            )}
          </IconsContainer>
          <div className="mt-4">
            {privateLessonPayments.length ? (
              <PrivateLessonsSection
                privateLessonPayments={privateLessonPayments}
                paymentRatesObj={paymentRatesObj}
                teachingPeriod={teachingPeriod}
              />
            ) : null}

            {sortedFilteredPayments?.length ? (
              <OtherPaymentsSection
                payments={sortedFilteredPayments}
                paymentRatesObj={paymentRatesObj}
                paymentMethod={paymentMethod}
                workingPeriod={teachingPeriod}
              />
            ) : null}
          </div>
          <h4
            style={{ color: "#269900" }}
            className="d-flex justify-content-between fw-bold mt-5"
          >
            <span>Total Payment:</span>
            <span>${paymentTotal?.toLocaleString("en-US")}</span>
          </h4>

          {eaPayment ? (
            <p
              style={{ color: "#979797" }}
              className="d-flex justify-content-between fw-bold mt-5"
            >
              <span>Emergency Absence</span>
              <span>{eaPayment.calculatedByAmount} Hrs</span>
            </p>
          ) : null}
          {note && (
            <div className="mt-5">
              <CustomTextarea
                boxShadow="none"
                width="100%"
                height="100px"
                disabled
                value={note}
                resize="none"
              />
            </div>
          )}
          <p style={{ color: "#681e46" }} className="text-end fw-bold mt-5">
            Thank You !
          </p>
        </ContentContainer>
      </StyledBarContent>

      {/* MODALS */}
      {editPaymentModalData.isModalOpen && canEditPayment && (
        <EditPaymentModal
          modalData={editPaymentModalData}
          paymentObj={paymentObj}
        />
      )}
      {deleteConfirmationModalData.isModalOpen && (
        <ConfirmationModal
          modalData={deleteConfirmationModalData}
          onApprove={handleApproveOption}
          onCancel={handleCancelOption}
          isSubmitting={paymentInfo.loadingAction}
          title="Delete Payment ?"
          approveBtnText="Delete"
          cancelBtnText="Cancel"
        />
      )}
    </div>
  );
};

export default injectUserStore(PaymentInfoBody);
