import styled from "styled-components";
import { Button, Table } from "antd";
import { setActionBgColor, setActionColor } from "./utils";

const StyledTable = styled(Table)`
  .ant-table {
    border: 1px solid #681e46 !important;
    border-radius: 1.3rem !important;
    text-transform: capitalize;
  }

  .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background-color: #681e4633 !important;
  }

  .approved {
    background-color: #a9dfbf !important;

    .ant-table-cell-row-hover {
      background-color: #a9dfbf !important;
    }
  }

  .declined {
    background-color: #d6002055 !important;

    .ant-table-cell-row-hover {
      background-color: #d6002000 !important;
    }
  }
`;

const StyledActionButton = styled(Button)`
  background-color: ${({ actiontype }) => setActionBgColor(actiontype)};
  color: ${({ actiontype }) => setActionColor(actiontype)};
`;

const StyledActionForm = styled.form`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

const ActionSubmitButton = styled(Button)`
  display: block;
  width: 300px;
  margin: 0 auto;
`;

export {
  StyledTable,
  StyledActionButton,
  StyledActionForm,
  ActionSubmitButton,
};
