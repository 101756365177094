import React, { useMemo, useState } from "react";
import { calendarsList } from "../constants";

const useArtisCalendar = () => {
  const [selectedCalendarValue, setSelectedCalendarValue] = useState(
    calendarsList[0].value
  );

  const selectedCalendar = useMemo(() => {
    return calendarsList.find(({ value }) => value === selectedCalendarValue);
  }, [selectedCalendarValue]);

  return { setSelectedCalendarValue, selectedCalendar };
};

export default useArtisCalendar;
