import {
  getTimeDiffInMins,
  updatedMomentNow,
} from "../../../../../../../../../../utils/helpers";
import moment from "moment";

export const isValidStartAndEndTime = (teacherDays) => {
  let isValid = true;
  if (!teacherDays?.length) {
    isValid = false;
  } else {
    for (const teacherDay of teacherDays) {
      const splittedStartTeacherDay = teacherDay.start.split(":");
      const splittedEndTeacherDay = teacherDay.end.split(":");
      const [teacherDayStartHr, teacherDayStartMin] = splittedStartTeacherDay;
      const [teacherDayEndHr, teacherDayEndMin] = splittedEndTeacherDay;

      const teacherDayStartDate = updatedMomentNow().set({
        hour: parseInt(teacherDayStartHr),
        minute: parseInt(teacherDayStartMin),
        second: 0,
        millisecond: 0,
      });
      const teacherDayEndDate = updatedMomentNow().set({
        hour: parseInt(teacherDayEndHr),
        minute: parseInt(teacherDayEndMin),
        second: 0,
        millisecond: 0,
      });

      const startAndEndDiff = getTimeDiffInMins(
        teacherDayStartDate,
        teacherDayEndDate
      );
      if (startAndEndDiff <= 0) {
        isValid = false;
        break;
      }
    }
  }
  return isValid;
};
