import React from "react";
import AttachmentSection from "./AttachmentSection";
import InfoSection from "./InfoSection";

const ModalBodyContent = ({ feedItem }) => {
  const { attachments } = feedItem || {};

  return (
    <div>
      <div>
        <AttachmentSection attachments={attachments} />
      </div>
      <hr />
      <div>
        <InfoSection feedItem={feedItem} />
      </div>
    </div>
  );
};

export default ModalBodyContent;
