import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FirebaseContext from "../../Firebase/Context";
import {
  addDoc,
  deleteDoc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  getStorage,
  ref,
} from "firebase/storage";
import TeacherLibraryTabs from "./tabs/TeacherLibraryTabs";
import AttachmentModal from "./new-item-modal/AttachmentModal";
import TabContent from "./tab-content/TabContent";
import {
  ActiveTabContext,
  AddItemModalContext,
  AdminUserContext,
  DeleteItemsModalContext,
  DownloadItemsModalContext,
  ItemsSearchContext,
  LibraryContext,
  LoadingContext,
  RenameItemContext,
  ShareItemModalContext,
  StudentsContext,
  StudentsSelectContext,
  TeachersContext,
  TeachersSelectContext,
  ViewItemModalContext,
} from "./common/libraryContext";
import ShareItemModal from "./share-item-modal/ShareItemModal";
import ViewItemModal from "./view-item-modal/ViewItemModal";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import { DIRECTORY } from "./common/constants";
import DeleteConfirmModal from "./delete-confirm-modal/DeleteConfirmModal";
import AdminUserSelect from "./admin-user-select/AdminUserSelect";
import {
  canUserUploadFileCheck,
  downloadSingleItem,
  getStudents,
  uploadFileWithRandomId,
} from "../../../utils/helpers";
import shareItemModal from "./share-item-modal/ShareItemModal";
import { createStorageFileCopy } from "src/utils/apiFns";
import DownloadConfirmationModal from "./common/download-confirm-modal/DownloadConfirmationModal";
import RenameItemModal from "./rename-item-modal/RenameItemModal";

const Library = ({ user, isTeacher, isAdmin }) => {
  const firebase = useContext(FirebaseContext);
  const [activeTab, setActiveTab] = useState("musicSheets");
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showShareItemModal, setShowShareItemModal] = useState(false);
  const [showViewItemModal, setShowViewItemModal] = useState({
    isOpen: false,
    item: {},
  });
  const [selectedUserType, setSelectedUserType] = useState("all");
  const [itemsSearchValue, setItemsSearchValue] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sharedItems, setSharedItems] = useState([]);
  const [deleteItemsModal, setDeleteItemsModal] = useState({
    isOpen: false,
    items: [],
  });
  const [renameItemModal, setRenameItemModal] = useState({
    isOpen: false,
    item: "",
  });
  const [downloadItemsModal, setDownloadItemsModal] = useState({
    isOpen: false,
    items: [],
  });

  const [userLibrary, setUserLibrary] = useState({
    musicSheets: [],
    recordings: [],
    videos: [],
    recommendationLinks: [],
    studentsUploads: [],
  });
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [studentsList, setStudentsList] = useState([]);
  const [teachersList, setTeachersList] = useState([]);
  const storage = getStorage();

  const getLibrary = async (userId) => {
    // ** This snippet is to delete all documents of the teacher's library collection
    // const items = await getDocs(firebase.library());
    // items.forEach(async (i) => {
    //   await deleteDoc(firebase.getSingleLibraryDoc(i.id));
    // });
    const isLoadingLibraryForAdmin = isAdmin && userId === user.uid;
    const lib = await getDocs(
      isLoadingLibraryForAdmin
        ? firebase.library()
        : firebase.getLibrary(userId)
    );
    const shared = isLoadingLibraryForAdmin
      ? []
      : await getDocs(firebase.getSharedLibraryItems(userId));
    const library = {
      musicSheets: [],
      recordings: [],
      videos: [],
      recommendationLinks: [],
      studentsUploads: [],
    };
    lib.forEach((item) => {
      const data = item.data();
      if (data.isDeleted && data.userId === user.uid) {
        return;
      }
      library[data.attachmentType].push({ ...data, id: item.id });
    });
    shared.forEach((item) => {
      const data = item.data();
      library[data.attachmentType].push({ ...data, id: item.id });
    });
    setUserLibrary(library);
  };
  const getStudentsList = async () => {
    const studentsArray = await getStudents(
      isAdmin,
      isTeacher,
      firebase,
      user.uid
    );
    setStudentsList(studentsArray);
  };
  const getTeachersList = async () => {
    // get student teachers
    const teachersDocs = await getDocs(firebase.filterUsersByRole("Teacher"));
    let teachersArray = [];
    teachersDocs.forEach((teacher) => {
      if (teacher.id === user.uid) {
        return;
      }
      teachersArray.push({
        id: teacher.id,
        ...teacher.data(),
      });
    });
    if (!isTeacher && !isAdmin) {
      const studentLessons = await getDocs(
        firebase.getStudentLessons(user.uid)
      );
      const teachersIds = [];
      studentLessons.forEach((lesson) => {
        teachersIds.push(...lesson.data().teachersIds);
      });
      teachersArray = teachersArray.filter((teacher) =>
        teachersIds.includes(teacher.id)
      );
    }
    setTeachersList(teachersArray);
  };
  const loadLibrary = async (userId) => {
    setPageLoading(true);
    await Promise.all([
      getLibrary(userId),
      getStudentsList(),
      getTeachersList(),
    ]);
    setPageLoading(false);
  };
  useEffect(async () => {
    if (user?.uid) {
      await loadLibrary(user.uid);
    }
  }, [user?.uid]);

  const onAddItemModalSave = async (formData) => {
    // setLoading(true);
    const { file } = formData;

    if (file) {
      const canUpload = canUserUploadFileCheck(user, file.size);
      console.log({ canUpload, fileSize: file.size });

      onCloseAttachmentModal();
      if (!canUpload) {
        toast.error("User has reached maximum storage capacity");
        return;
      }
    }

    toast.info("Saving data...", { autoClose: 3000 });
    toast.info("Saving in the background, please don't refresh the page.", {
      autoClose: false,
    });
    try {
      await handleSaveAttachment(formData);
      toast.success("Successfully saved");
    } catch (error) {
      setLoading(false);
      toast.error("An error has occurred");
      console.log(error);
    }
  };
  const onTabClick = (tab) => {
    setActiveTab(tab);
    setItemsSearchValue("");
    setSelectedItems([]);
  };
  const handleSaveAttachment = async ({ title, file, recommendationLink }) => {
    const newAttachment = {
      title,
      userId: isAdmin ? "school" : user.uid,
      uploadedBy: user.uid,
      createdAt: Timestamp.now(),
      sharedWith: [],
    };
    newAttachment.attachmentType = activeTab;
    if (activeTab !== "recommendationLinks") {
      const { downloadUrl, id } = await uploadFileWithRandomId(
        firebase,
        file,
        `library/${user.uid}/${DIRECTORY[activeTab]}`
      );
      newAttachment.type = file.type;
      newAttachment.downloadUrl = downloadUrl;
      newAttachment.fileId = id;
    } else {
      newAttachment.downloadUrl = recommendationLink;
      newAttachment.type = "link";
    }
    const added = await addDoc(firebase.library(), newAttachment);
    newAttachment.id = added.id;
    await getLibrary(user.uid);
  };

  const activeTabContextValue = {
    activeTab,
    setActiveTab: onTabClick,
    isTeacher,
    isAdmin,
  };
  const loadingContextValue = {
    loading,
    setLoading,
  };
  const onCloseAttachmentModal = () => {
    setLoading(false);
    setShowAddItemModal(false);
  };
  const addItemModalContextValue = {
    isOpen: showAddItemModal,
    open: () => setShowAddItemModal(true),
    close: onCloseAttachmentModal,
  };
  const onCloseShareModal = () => {
    setLoading(false);
    setShowShareItemModal(false);
    setSharedItems([]);
    setSelectedTeachers([]);
    setSelectedStudents([]);
    setSelectedItems([]);
  };
  const onSubmitShare = async () => {
    toast.info("Sharing item(s)...");
    toast.info("Running in the background, please don't refresh the page.");
    onCloseShareModal();
    for (const itemId of sharedItems) {
      const item = userLibrary[activeTab].find(({ id }) => id === itemId);
      const currentSharedWith = item.sharedWith || [];
      const newSharedWith = currentSharedWith
        .concat(selectedStudents)
        .concat(selectedTeachers);
      const uniqueSharedWith = [...new Set(newSharedWith)];

      await setDoc(
        firebase.setSharedLibraryItem(itemId),
        {
          sharedWith: uniqueSharedWith,
        },
        {
          merge: true,
        }
      );
    }
    await getLibrary(user.uid);
    toast.success("Successfully shared.");
  };
  const openShareItemModal = (ids) => {
    if (ids.length === 1) {
      const selectedItem = userLibrary[activeTab].find(
        ({ id }) => id === ids[0]
      );
      const currentSelectedTeachers = teachersList
        .filter(({ id }) => selectedItem.sharedWith.includes(id))
        .map(({ id }) => id);
      const currentSelectedStudents = studentsList
        .filter(({ id }) => selectedItem.sharedWith.includes(id))
        .map(({ id }) => id);
      setSelectedTeachers(currentSelectedTeachers);
      setSelectedStudents(currentSelectedStudents);
    }
    setSharedItems(ids);
    setShowShareItemModal(true);
  };
  const shareItemModalContextValue = {
    isOpen: showShareItemModal,
    open: openShareItemModal,
    close: onCloseShareModal,
    submit: onSubmitShare,
  };
  const onCloseViewModal = () => {
    setShowViewItemModal({
      isOpen: false,
      item: Object.assign({}, {}),
    });
  };
  const viewItemModalContextValue = {
    state: showViewItemModal,
    open: (item) => {
      setShowViewItemModal({
        isOpen: true,
        item: Object.assign({}, item),
      });
    },
    close: onCloseViewModal,
  };
  const itemsSearchContextValue = {
    itemsSearchValue,
    setItemsSearchValue,
  };
  const onItemSelect = (id) => {
    const index = selectedItems.findIndex((ID) => id === ID);
    if (index === -1) {
      setSelectedItems((prev) => [...prev, id]);
    } else {
      setSelectedItems((prev) => prev.filter((ID) => id !== ID));
    }
  };
  const onToggleItemsClick = () => {
    if (selectedItems.length === userLibrary[activeTab].length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(userLibrary[activeTab].map(({ id }) => id));
    }
  };
  const shareSingleItem = async (id) => {
    openShareItemModal([id]);
  };

  const openDeleteConfirmation = (items) => {
    setDeleteItemsModal({
      isOpen: true,
      items,
    });
  };
  const openRenameItemModal = (itemId) => {
    setRenameItemModal({
      isOpen: true,
      item: itemId,
    });
  };
  const closeRenameItemModal = () => {
    setRenameItemModal({
      isOpen: false,
      item: "",
    });
  };
  const deleteItems = async (
    permanentDelete = false, // perma deletes selected items
    deletePartiallyDeletedItemsOnly = false // perma deletes partially deleted items only
  ) => {
    toast.info("Deleting items...");
    toast.info("Running in background, please don't refresh the page.");
    const items = [].concat(deleteItemsModal.items);
    setDeleteItemsModal({
      isOpen: false,
      items: [],
    });
    for (const item of items) {
      try {
        const libraryItem = userLibrary[activeTab].filter(
          ({ id }) => item === id
        )[0];

        // user will be able to delete the item if he is the one uploaded the item or its an admin
        const isAbleToDeleteItem = user.uid === libraryItem.userId || isAdmin;
        if (isAbleToDeleteItem) {
          if (permanentDelete) {
            console.log("is Perma Delete");
            const isPartiallyDeletedItem = libraryItem.isDeleted;
            if (deletePartiallyDeletedItemsOnly && !isPartiallyDeletedItem) {
              continue;
            }
            const itemRef = ref(storage, libraryItem.downloadUrl);
            await deleteDoc(firebase.getSingleLibraryDoc(item));
            await deleteObject(itemRef);
          } else {
            console.log("Not Perma Delete");

            await updateDoc(firebase.getSingleLibraryDoc(item), {
              isDeleted: true,
              deletedAt: Timestamp.now(),
            });
          }
        } else {
          console.log("not the uploader user, removing user from sharedWith");

          const sharedWith = libraryItem.sharedWith.filter(
            (element) => element !== user.uid
          );
          await updateDoc(firebase.getSingleLibraryDoc(item), {
            sharedWith,
          });
        }
        await getLibrary(user.uid);
      } catch (e) {
        console.error("Delete library item error", e);
      }
    }

    toast.success("Deleted successfully.");
  };
  const deleteItemsModalContextValue = {
    state: {
      isOpen: deleteItemsModal.isOpen,
      items: deleteItemsModal.items,
    },
    deleteItems,
    close: () => {
      setDeleteItemsModal({
        isOpen: false,
        items: [],
      });
    },
  };

  const renameItem = async (itemId, newTitle) => {
    try {
      if (!newTitle) {
        toast.warn("Title can't be empty");
        return;
      }
      closeRenameItemModal();
      const libraryItem = userLibrary[activeTab].find(
        ({ id }) => itemId === id
      );
      if (!libraryItem) {
        toast.error("Couldnt find library item to rename");
        return;
      }

      // user will be able to rename the item if he is the one uploaded the item or its an admin
      const isAbleToRenameItem = user.uid === libraryItem.userId || isAdmin;

      if (!isAbleToRenameItem) {
        toast.error("Only the uploader user or an admin can rename the item");
        return;
      }

      toast.info("In Progress...");

      await updateDoc(firebase.getSingleLibraryDoc(itemId), {
        title: newTitle,
      });
      toast.success("Renamed successfully.");
      await getLibrary(user.uid);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const renameItemModalContextValue = {
    state: {
      isOpen: renameItemModal.isOpen,
      item: renameItemModal.item,
    },
    renameItem,
    close: closeRenameItemModal,
  };

  const deleteOne = (id) => {
    openDeleteConfirmation([id]);
  };
  const deleteAll = () => {
    openDeleteConfirmation(selectedItems);
  };

  const shareAll = () => {
    openShareItemModal(selectedItems);
  };

  const openDownloadConfirmation = (items) => {
    setDownloadItemsModal({
      isOpen: true,
      items,
    });
  };

  const openSingleItemDownloadModal = (id) => {
    openDownloadConfirmation([id]);
  };
  const openItemsDownloadModal = (ids) => {
    openDownloadConfirmation(selectedItems);
  };

  const downloadItem = async () => {
    try {
      const libraryItemsIds = downloadItemsModal.items;

      if (!libraryItemsIds?.length) return;

      setDownloadItemsModal({
        isOpen: false,
        items: [],
      });

      toast.info("In Progress, please don't refresh the page...");
      const firstItemId = libraryItemsIds[0];
      const firstItem = userLibrary[activeTab].find(
        ({ id }) => firstItemId === id
      );
      if (!firstItem) {
        toast.error("couldnt find a library item");
        return;
      }

      await downloadSingleItem(firstItem.downloadUrl, firstItem.title);
    } catch (err) {
      console.log(err);
    }
  };

  const copyLibraryItems = async () => {
    try {
      const libraryItemsIds = downloadItemsModal.items;
      if (!libraryItemsIds) return;

      toast.info("In Progress, please don't refresh the page...");
      const items = [].concat(downloadItemsModal.items);
      setDownloadItemsModal({
        isOpen: false,
        items: [],
      });

      for (const itemId of libraryItemsIds) {
        const libraryItem = userLibrary[activeTab].find(
          ({ id }) => itemId === id
        );
        if (!libraryItem) {
          toast.error("couldnt find a library item");
          continue;
        }

        const itemRef = ref(storage, libraryItem.downloadUrl);
        const sourcePath = itemRef.fullPath;
        const newFileId = uuidv4();
        const targetPath = `library/${user.uid}/${DIRECTORY[activeTab]}${newFileId}`;

        console.log({ sourcePath, targetPath });
        const fileCopyRes = await createStorageFileCopy(sourcePath, targetPath);

        if (!fileCopyRes.success) {
          toast.error("Failed to create a copy of the file");
          continue;
        }
        const copiedFileRef = ref(storage, targetPath);
        const newDownloadUrl = await getDownloadURL(copiedFileRef);
        if (!newDownloadUrl) {
          toast.error("Failed to get download url for the copied file");
          continue;
        }

        const copiedLibraryItemObj = {
          title: libraryItem.title,
          attachmentType: libraryItem.attachmentType,
          type: libraryItem.type,
          userId: isAdmin ? "school" : user.uid,
          uploadedBy: user.uid,
          createdAt: Timestamp.now(),
          sharedWith: [],
          downloadUrl: newDownloadUrl,
          fileId: newFileId,
          isDeleted: false,
        };
        const addedFile = await addDoc(
          firebase.library(),
          copiedLibraryItemObj
        );
        copiedLibraryItemObj.id = addedFile.id;
        console.log({ copiedLibraryItemObj });
      }
      await getLibrary(user.uid);
      toast.success("Done successfully.");
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!");
    }
  };

  const downloadItemsModalContextValue = {
    state: {
      isOpen: downloadItemsModal.isOpen,
      items: downloadItemsModal.items,
    },
    downloadItem: downloadItem,
    moveItemsToUserLibrary: copyLibraryItems,
    close: () => {
      setDownloadItemsModal({
        isOpen: false,
        items: [],
      });
    },
  };
  const libraryContextValue = {
    library: userLibrary,
    selected: selectedItems,
    shared: sharedItems,
    add: onAddItemModalSave,
    toggle: onToggleItemsClick,
    select: onItemSelect,
    shareOne: shareSingleItem,
    downloadOne: openSingleItemDownloadModal,
    downloadAll: openItemsDownloadModal,
    shareAll,
    deleteOne,
    deleteAll,
    openRenameItemModal: (id) => openRenameItemModal(id),
  };
  const onStudentSelect = (id) => {
    const index = selectedStudents.findIndex((ID) => id === ID);
    if (index === -1) {
      setSelectedStudents((prev) => [...prev, id]);
    } else {
      setSelectedStudents((prev) => prev.filter((ID) => id !== ID));
    }
  };
  const onMultiStudentsSelect = (studentsIds) => {
    setSelectedStudents(studentsIds);
  };

  const onToggleStudentsClick = () => {
    if (selectedStudents.length === studentsList.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(studentsList.map(({ id }) => id));
    }
  };
  const studentsSelectContextValue = {
    selected: selectedStudents,
    select: onStudentSelect,
    toggle: onToggleStudentsClick,
    multiSelect: onMultiStudentsSelect,
  };

  const onTeacherSelect = (id) => {
    const index = selectedTeachers.findIndex((ID) => id === ID);
    if (index === -1) {
      setSelectedTeachers((prev) => [...prev, id]);
    } else {
      setSelectedTeachers((prev) => prev.filter((ID) => id !== ID));
    }
  };
  const onToggleTeachersClick = () => {
    if (selectedTeachers.length === teachersList.length) {
      setSelectedTeachers([]);
    } else {
      setSelectedTeachers(teachersList.map(({ id }) => id));
    }
  };
  const teachersSelectContextValue = {
    selected: selectedTeachers,
    select: onTeacherSelect,
    toggle: onToggleTeachersClick,
  };
  const teachersContextValue = {
    teachers: teachersList,
  };
  const studentsContextValue = {
    students: studentsList,
  };
  const loadLibraryForUserId = (userId) => {
    (async () => {
      await loadLibrary(userId);
    })();
  };
  const adminUserSelectContextValue = {
    selectedUserType,
    setSelectedUserType,
    selectUserType: (val) => {
      if (val === "all") {
        loadLibraryForUserId(user.uid);
      } else if (val === "school") {
        loadLibraryForUserId("school");
      }
    },
    onUserSelect: loadLibraryForUserId,
  };
  return (
    <div className={"h-100 d-flex flex-column"} style={{ gap: "20px" }}>
      {pageLoading ? (
        <Spinner />
      ) : (
        <AdminUserContext.Provider value={adminUserSelectContextValue}>
          <TeachersContext.Provider value={teachersContextValue}>
            <StudentsContext.Provider value={studentsContextValue}>
              {isAdmin ? <AdminUserSelect /> : null}
              <ActiveTabContext.Provider value={activeTabContextValue}>
                <TeacherLibraryTabs />
                <AddItemModalContext.Provider value={addItemModalContextValue}>
                  <LoadingContext.Provider value={loadingContextValue}>
                    <AttachmentModal onModalSave={onAddItemModalSave} />
                  </LoadingContext.Provider>
                  <ItemsSearchContext.Provider value={itemsSearchContextValue}>
                    <LibraryContext.Provider value={libraryContextValue}>
                      <RenameItemContext.Provider
                        value={renameItemModalContextValue}
                      >
                        <StudentsSelectContext.Provider
                          value={studentsSelectContextValue}
                        >
                          <TeachersSelectContext.Provider
                            value={teachersSelectContextValue}
                          >
                            <ShareItemModalContext.Provider
                              value={shareItemModalContextValue}
                            >
                              {showShareItemModal ? <ShareItemModal /> : null}
                            </ShareItemModalContext.Provider>

                            <DeleteItemsModalContext.Provider
                              value={deleteItemsModalContextValue}
                            >
                              <DownloadItemsModalContext.Provider
                                value={downloadItemsModalContextValue}
                              >
                                <ViewItemModalContext.Provider
                                  value={viewItemModalContextValue}
                                >
                                  <RenameItemModal />
                                  <ViewItemModal />
                                  <DeleteConfirmModal />
                                  <DownloadConfirmationModal user={user} />
                                  <TabContent user={user} />
                                </ViewItemModalContext.Provider>
                              </DownloadItemsModalContext.Provider>
                            </DeleteItemsModalContext.Provider>
                          </TeachersSelectContext.Provider>
                        </StudentsSelectContext.Provider>
                      </RenameItemContext.Provider>
                    </LibraryContext.Provider>
                  </ItemsSearchContext.Provider>
                </AddItemModalContext.Provider>
              </ActiveTabContext.Provider>
            </StudentsContext.Provider>
          </TeachersContext.Provider>
        </AdminUserContext.Provider>
      )}
    </div>
  );
};

export default Library;
