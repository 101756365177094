import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  concertInvitationStatuses,
  concertLimitedSignupOptionTypes,
  concertProgramTypes,
  concertSignupOptions,
} from "src/constants/concertEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { v4 as uuidv4 } from "uuid";

const initialDataInitialValues = {
  concert: undefined,
  students: [],
  privateLessons: [],
  instruments: [],
};

const useConcertRecommendation = ({
  onInvitationSuccess = () => {},
  user,
  concertId,
}) => {
  const {
    getStudents,
    getConcertById,
    createConcertInvitation,
    getPrivateLessons,
    getInstruments,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);

  const [programType, setProgramType] = useState(
    concertProgramTypes.SOLO_PROGRAM
  );
  const [studentSearchTerm, setStudentSearchTerm] = useState("");
  const [selectedStudentsIds, setSelectedStudentsIds] = useState([]);

  function handleSelectedStudentsChange({ studentId, isSelectAll }) {
    if (programType === concertProgramTypes.SOLO_PROGRAM) {
      const newSelectedStudents =
        selectedStudentsIds[0] === studentId ? [] : [studentId];
      setSelectedStudentsIds(newSelectedStudents);
    } else {
      if (isSelectAll) {
        if (selectedStudentsIds.length) {
          setSelectedStudentsIds(initialData.students.map(({ id }) => id));
        } else {
          setSelectedStudentsIds([]);
        }
      } else {
        const newSelectedStudents = selectedStudentsIds.includes(studentId)
          ? selectedStudentsIds.filter((id) => id !== studentId)
          : [...selectedStudentsIds, studentId];
        setSelectedStudentsIds(newSelectedStudents);
      }
    }
  }

  function isStudentSelected(studentId) {
    return selectedStudentsIds.includes(studentId);
  }

  async function sendInvite() {
    try {
      if (!concertId || !user || !selectedStudentsIds.length) {
        throw new Error("Missing Information");
      }

      setSendingInvite(true);

      const programGroupId = uuidv4();
      const invitationObj = {
        createdAt: new Date(),
        concertId,
        createdBy: user.uid,
        signupOption: {
          type: concertSignupOptions.LIMITED,
          value: concertLimitedSignupOptionTypes.TEACHER_RECOMMENDATION,
        },
        status: concertInvitationStatuses.TEACHER_RECOMMENDATION_CREATED,
        programType,
        programGroupId,
        invitedUsersIds: selectedStudentsIds,
      };
      console.log({ invitationObj });

      await createConcertInvitation(invitationObj);
      toast.success("Invitation sent successfully");
      onInvitationSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setSendingInvite(false);
    }
  }
  console.log({ init: initialData.students });
  useEffect(() => {
    if (!concertId) return;
    const fetch = async () => {
      try {
        setLoadingInitialData(true);

        const [concert, students, privateLessons, instruments] =
          await Promise.all([
            getConcertById(concertId),
            getStudents(),
            getPrivateLessons(),
            getInstruments(),
          ]);

        setInitialData({
          concert,
          students,
          privateLessons,
          instruments,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  // resets selected students when the program type changes
  useEffect(() => {
    setSelectedStudentsIds([]);
  }, [programType]);

  const selectedStudents = useMemo(() => {
    return selectedStudentsIds.map((studentId) =>
      initialData.students.find(({ id }) => id === studentId)
    );
  }, [selectedStudentsIds, initialData]);

  const filteredStudentsList = useMemo(() => {
    const filtered =
      programType === concertProgramTypes.SOLO_PROGRAM
        ? initialData.students.filter((student) => {
            return initialData.privateLessons.find(
              ({ studentId, teachersIds }) =>
                teachersIds.includes(user.uid) && studentId === student.id
            );
          })
        : initialData.students;

    const sorted = filtered.sort((a, b) =>
      a.fullName.localeCompare(b.fullName)
    );
    if (!studentSearchTerm) return sorted;

    return sorted.filter(({ fullName }) => {
      return fullName
        .toLowerCase()
        .includes(studentSearchTerm.toLocaleLowerCase());
    });
  }, [programType, initialData, studentSearchTerm]);

  return {
    initialData,
    loadingInitialData,
    programType,
    setProgramType,
    studentSearchTerm,
    setStudentSearchTerm,
    handleSelectedStudentsChange,
    selectedStudents,
    filteredStudentsList,
    isStudentSelected,
    sendInvite,
    sendingInvite,
  };
};

export default useConcertRecommendation;
