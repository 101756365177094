import { SummaryItem } from "../styled";

const PartnerSummary = ({ user }) => {
  return (
    <>
      <SummaryItem>
        Selected location:{" "}
        {Array.isArray(user?.selectedLocation)
          ? user?.selectedLocation?.join(", ")
          : user?.selectedLocation}
      </SummaryItem>
    </>
  );
};

export default PartnerSummary;
