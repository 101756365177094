import React from "react";
import { CustomInput } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import {
  CurrencyInputContainer,
  IconsContainer,
  RateItemContainer,
  RatesContainer,
  RatesSectionContainer,
} from "../../styled";
import { Lock } from "@styled-icons/boxicons-solid/Lock";
import { LockOpen } from "@styled-icons/boxicons-solid/LockOpen";
import { SubmitSpinner } from "src/components/common";
import useRatesData from "./hooks/useRatesData";
import {
  fullTimeAdminRates,
  fullTimeTeacherRates,
  partTimeAdminRates,
  partTimeTeacherRates,
  paymentRates,
  paymentRatesMap,
  paymentRateTypes,
} from "src/constants/paymentsEnum";
import CustomInputWithLabel from "./CustomInputWithLabel";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { getUserPaymentRatesTitles, injectUserStore } from "src/utils/helpers";
import { Edit } from "@styled-icons/fluentui-system-filled/Edit";
import { History } from "@styled-icons/fluentui-system-filled/History";
import ViewPaymentRatesHistoryModal from "src/components/Dashboard/common/components/ViewPaymentRatesHistoryModal";
import useModal from "src/hooks/useModal";
import {
  hasAdminCredentials,
  isAdmin,
  isTeacher,
} from "src/constants/UserRoleEnum";

const RatesSection = ({ createPaymentData, UserStore }) => {
  const user = UserStore.user;
  const isAdminUser = isAdmin(user.role);

  const {
    areRatesLocked,
    setAreRatesLocked,
    selectedUser,
    selectedUserData,
    loadingSelectedUserData,
    refreshUserData,
    showRatesSection,
  } = createPaymentData;
  const { userCurrentPaymentRates } = selectedUserData;

  const ratesData = useRatesData({
    ratesValuesInitialValues: userCurrentPaymentRates?.rates,
    user,
    selectedUser,
    refreshUserData,
    lockRates: () => setAreRatesLocked(true),
  });
  const {
    handleRatesValuesChange,
    ratesValues,
    saveNewRates,
    loadingSavingRates,
  } = ratesData;

  const paymentRatesHistoryModalData = useModal();

  const currentUserPaymentRates = getUserPaymentRatesTitles(selectedUser);

  if (isAdminUser) {
    return (
      <RatesSectionContainer className="d-flex justify-content-center align-items-center opacity-0"></RatesSectionContainer>
    );
  }
  if (!showRatesSection) {
    return (
      <RatesSectionContainer className="d-flex justify-content-center align-items-center">
        <h4>Please Select The User To See The Rates</h4>
      </RatesSectionContainer>
    );
  }
  if (loadingSelectedUserData) {
    return (
      <RatesSectionContainer className="d-flex justify-content-center align-items-center">
        <SubmitSpinner
          style={{
            width: "3rem",
            height: "3rem",
            color: "#681e46",
            margin: "20px",
          }}
        />
      </RatesSectionContainer>
    );
  }
  return (
    <RatesSectionContainer>
      <IconsContainer className="mb-4">
        <History onClick={() => paymentRatesHistoryModalData.openModal()} />
        {areRatesLocked ? (
          <Lock onClick={() => setAreRatesLocked(false)} />
        ) : (
          <LockOpen color="#0eb90e" onClick={() => setAreRatesLocked(true)} />
        )}
      </IconsContainer>
      <RatesContainer>
        {currentUserPaymentRates.map((rateTitle) => {
          const rateMap = paymentRatesMap.find(
            (map) => map.rateTitle === rateTitle
          );
          return (
            <CustomInputWithLabel
              key={`${rateMap.rateTitle}-${rateMap.rateType}`}
              label={`${rateMap.label}:`}
              title={rateMap.rateTitle}
              type={rateMap.rateType}
              rates={ratesValues}
              handleRatesValuesChange={handleRatesValuesChange}
              disabled={areRatesLocked}
            />
          );
        })}
        <RateItemContainer></RateItemContainer>
        <div className="w-100">
          {!areRatesLocked && (
            <PrimaryButtonWithLoading
              onClick={saveNewRates}
              width="100%"
              isLoading={loadingSavingRates}
            >
              Save Rates
            </PrimaryButtonWithLoading>
          )}
        </div>
      </RatesContainer>
      {paymentRatesHistoryModalData.isModalOpen && selectedUser?.id && (
        <ViewPaymentRatesHistoryModal
          userId={selectedUser.id}
          modalData={paymentRatesHistoryModalData}
        />
      )}
    </RatesSectionContainer>
  );
};

export default injectUserStore(RatesSection);
