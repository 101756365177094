import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import SearchSelect from "src/components/common/Library/common/SearchSelect";
import { Modal } from "../../common";
import {
  AttendanceContext,
  AttendanceModalContext,
} from "../../common/contexts/AdminAttendanceContext";

const MarkAttendanceModal = () => {
  const { close, isOpen, attendance, submit, isEdit, deleteAttendance } =
    useContext(AttendanceModalContext);
  const { locations } = useContext(AttendanceContext);
  const [attendanceDate, setAttendanceDate] = useState(
    moment(
      isEdit
        ? moment(attendance.attendanceDate, "MM-DD-YYYY hh:mmA")
        : attendance.lessonDate
    ).format("YYYY-MM-DD")
  );
  const [attendanceTime, setAttendanceTime] = useState(
    moment(
      isEdit
        ? moment(attendance.attendanceDate, "MM-DD-YYYY hh:mmA")
        : attendance.lessonDate
    ).format("hh:mm")
  );
  // const [attendanceType, setAttendanceType] = useState(attendance.type);
  const [selectedLocation, setSelectedLocation] = useState({
    label: "",
    value: "",
  });
  useEffect(() => {
    if (locationsOptions.length) {
      const locationOption = locationsOptions.find(
        ({ value }) => value === attendance.locationId
      );
      setSelectedLocation(locationOption);
    }
  }, [attendance, locationsOptions]);
  const locationsOptions = useMemo(() => {
    return locations.map(({ id, name }) => ({ label: name, value: id }));
  }, [locations]);

  const buttons = (
    <>
      <Button
        onClick={() =>
          submit({
            ...attendance,
            attendanceDate: moment(`${attendanceDate} ${attendanceTime}`),
            locationId: selectedLocation.value,
          })
        }
        className="bg-purple"
      >
        Mark
      </Button>
      <Button onClick={deleteAttendance} color="danger" className="ms-2">
        Clear
      </Button>
    </>
  );
  const modalBody = () => (
    <div
      className={
        "d-flex flex-column py-5 align-items-center justify-content-center"
      }
    >
      {/* <p className="lead mb-2">Lesson Date: {attendance.lessonDate.format("MM-DD-YYYY hh:mmA")}</p> */}
      <div className="px-5 col w-100">
        <div className="d-flex col mb-2 align-items-center">
          <p className="mb-0 lead">Attendance Date: </p>
          <div className="inputContainer ms-2 flex-grow-1">
            <input
              className="InputField w-100 m-0"
              type="date"
              value={attendanceDate}
              onChange={(e) => {
                setAttendanceDate(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="d-flex col mb-2 align-items-center">
          <p className="mb-0 lead">Attendance Time: </p>
          <div className="inputContainer ms-2 flex-grow-1">
            <input
              className="InputField w-100 m-0"
              type="time"
              value={attendanceTime}
              onChange={(e) => {
                setAttendanceTime(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="mb-2 d-flex align-items-center">
          <p className="mb-0 lead">Check-In Location: </p>
          <SearchSelect
            name="locations"
            placeholder={"Location"}
            options={locationsOptions}
            onOptionSelect={(e) => {
              setSelectedLocation(e);
            }}
            value={selectedLocation}
            isMulti={false}
          />
        </div>
        {/* <div className="d-flex col mb-2 align-items-end">
                    <p className="mb-0 lead">Attendance Type: </p>
                    <div className="d-flex ms-2 col align-items-center">
                        <LabeledRadioButton
                            label={"normal"}
                            onChange={() => setAttendanceType('normal')}
                            checked={attendanceType === "normal"}
                            id={"lesson-type-normal"}
                        />
                        <LabeledRadioButton
                            label={"makeup"}
                            onChange={() => setAttendanceType('makeup')}
                            checked={attendanceType === "makeup"}
                            id={"lesson-type-makeup"}
                        />
                    </div>
                </div> */}
      </div>
    </div>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Mark Attendance"}
      isOpen={isOpen}
      toggle={close}
      buttons={buttons}
    />
  );
};
export default MarkAttendanceModal;
