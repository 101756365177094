import store from "src/stores/UserStore";
import { isGuestStudent, isPartnerApplicant } from "src/constants/UserRoleEnum";
import RouteContent from "src/components/common/RouteContent";
import StudentThirdPage from "./StudentThirdPage";
import PartnerThirdPage from "./PartnerThirdPage";

const GuestProfileThirdPage = () => {
  const { user } = store;

  return (
    <RouteContent title={user.role.replace(/_/g, " ") + " Profile"}>
      {isGuestStudent(user.role) && <StudentThirdPage />}
      {isPartnerApplicant(user.role) && <PartnerThirdPage />}
    </RouteContent>
  );
};

export default GuestProfileThirdPage;
