import React from "react";
import SignUpTypeSection from "./SignUpTypeSection";
import MinutesPerProgramSection from "./MinutesPerProgramSection";
import EquipmentSection from "./EquipmentSection";

const SecondPage = ({ hookData }) => {
  const {
    concertData,
    handleConcertDataChange,
    setCurrentView,
    maxNumberOfEnrollments,
  } = hookData;
  return (
    <div>
      <div className="mb-5">
        <SignUpTypeSection
          concertData={concertData}
          handleConcertDataChange={handleConcertDataChange}
        />
      </div>
      <div className="mb-5">
        <MinutesPerProgramSection
          concertData={concertData}
          handleConcertDataChange={handleConcertDataChange}
          maxNumberOfEnrollments={maxNumberOfEnrollments}
        />
      </div>
      <div>
        <EquipmentSection
          concertData={concertData}
          handleConcertDataChange={handleConcertDataChange}
        />
      </div>
    </div>
  );
};

export default SecondPage;
