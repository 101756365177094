import React, { useEffect, useMemo, useState } from "react";
import { Container } from "reactstrap";
import {
  ActionBtnsContainer,
  CheckIcon,
  EditIcon,
  IconWrapper,
  TableRow,
} from "./styled";
import { SubmitSpinner } from "../../../../../common";
import { modalMap } from "./utils/constants";
import {
  availableDaysToString,
  instrumentsToString,
} from "../../../../../../utils/helpers";
import {
  LessonsInfoModal,
  TeacherCardModal,
  TeacherInfoModal,
  TeacherBreaksModal,
  TeacherReactivationModal,
} from "./modals";
import DeleteTeacherLibraryItemsModal from "./modals/DeleteTeacherLibraryItemsModal";
import { SortButton } from "src/components/Dashboard/TeacherAndStaffDirectory/TeacherAndStaffDirectory.styled";
import { FaSortAlphaDown, FaSortAlphaUpAlt } from "react-icons/fa";

const TableLayout = ({
  teachersList,
  loadingTeachersList,
  modalData,
  refreshData,
}) => {
  const [currentTeacherId, setCurrentTeacherId] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedTeachers, setSortedTeachers] = useState(teachersList || []);

  useEffect(() => {
    setSortedTeachers(teachersList);
  }, [teachersList]);

  const handleSort = () => {
    const sorted = [...sortedTeachers].sort((a, b) => {
      const nameA = a.fullName.toLowerCase();
      const nameB = b.fullName.toLowerCase();
      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
    setSortedTeachers(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const openCurrentTeacherCardModal = (teacherId) => {
    setCurrentTeacherId(teacherId);
    modalData.openModalWithName(modalMap.teacherCard.name);
  };
  const openCurrentTeacherInfoModal = (teacherId) => {
    setCurrentTeacherId(teacherId);
    modalData.openModalWithName(modalMap.teacherInfo.name);
  };
  const openCurrentTeacherLessonsInfoModal = (teacherId) => {
    setCurrentTeacherId(teacherId);
    modalData.openModalWithName(modalMap.lessonsInfo.name);
  };

  const currentTeacher = useMemo(() => {
    return teachersList?.find(({ id }) => id === currentTeacherId);
  }, [currentTeacherId, teachersList]);

  // No Teachers found display
  if (!teachersList?.length && !loadingTeachersList)
    return (
      <h2 className="text-dark mt-5 text-center">No Teachers Were Found</h2>
    );
  // Loading display
  if (loadingTeachersList) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }

  const ActionButtons = ({ teacher }) => {
    return (
      <ActionBtnsContainer>
        <IconWrapper>
          <EditIcon onClick={() => openCurrentTeacherInfoModal(teacher?.id)} />
        </IconWrapper>
        <IconWrapper>
          <CheckIcon
            onClick={() => openCurrentTeacherLessonsInfoModal(teacher?.id)}
          />
        </IconWrapper>
      </ActionBtnsContainer>
    );
  };
  return (
    <Container fluid>
      <div className="overflow-hidden">
        <table className="w-100 text-dark">
          <thead>
            <TableRow>
              <th>
                <div>
                  Name of Teacher{" "}
                  <SortButton
                    onClick={handleSort}
                    aria-label="Sort by alphabet"
                    title="Sort by alphabet"
                  >
                    {sortOrder === "asc" ? (
                      <FaSortAlphaDown size={24} />
                    ) : (
                      <FaSortAlphaUpAlt size={24} />
                    )}
                  </SortButton>
                </div>
              </th>
              <th>
                <div>Instrument</div>
              </th>
              <th>
                <div>Teaching Days</div>
              </th>
              <th>
                <div>Phone Number</div>
              </th>
              <th>
                <div>Email</div>
              </th>
              <th>
                <div>Actions</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {sortedTeachers?.map((teacher) => {
              const {
                id,
                fullName,
                primaryPhone,
                primaryEmail,
                instrumentsInfo,
                teacherDays,
              } = teacher || {};
              const teacherInstrumentsNames = instrumentsInfo?.map(
                (el) => el?.instrument?.name
              );
              return (
                <TableRow key={id}>
                  <td
                    style={{ textAlign: "left" }}
                    onClick={() => openCurrentTeacherCardModal(id)}
                  >
                    {fullName || "N/A"}
                  </td>
                  <td>
                    {instrumentsToString(teacherInstrumentsNames) || "N/A"}
                  </td>
                  <td>{availableDaysToString(teacherDays?.availableDays)}</td>
                  <td>{primaryPhone || "N/A"}</td>
                  <td>{primaryEmail || "N/A"}</td>
                  <td>
                    <ActionButtons teacher={teacher} />
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </table>
        {/* Modals */}
        {currentTeacher && (
          <>
            <TeacherCardModal
              teacher={currentTeacher}
              modalData={modalData}
              refreshData={refreshData}
            />
            <TeacherInfoModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
              openCurrentTeacherCardModal={openCurrentTeacherCardModal}
            />
            <LessonsInfoModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
              openCurrentTeacherCardModal={openCurrentTeacherCardModal}
            />
            <TeacherBreaksModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
              openCurrentTeacherCardModal={openCurrentTeacherCardModal}
            />
            <TeacherReactivationModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
            />
            <DeleteTeacherLibraryItemsModal
              refreshData={refreshData}
              teacher={currentTeacher}
              modalData={modalData}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default TableLayout;
