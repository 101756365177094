import { Card } from "antd";
import styled from "styled-components";

const StyledFeedbackCard = styled(Card)`
  width: 100%;
  border-color: #681e46;

  h4 {
    text-align: center;
  }

  @media screen and (min-width: 1300px) {
    max-width: 620px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 720px;
  }

  @media screen and (min-width: 1466px) {
    max-width: 820px;
  }
`;

const CardActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  padding: 12px;
`;

const FeedbackMessage = styled.p`
  padding: 18px;
  height: 250px;
  width: 100%;
  overflow-y: scroll;

  overflow-wrap: break-word;
  white-space: pre-line;
  font-size: 18px;
  background-color: #f3f3f3;
  border-radius: 12px;
`;

export { CardActionsWrapper, StyledFeedbackCard, FeedbackMessage };
