import React from "react";
import { LessonsLengthEnum } from "src/constants/LessonsLength";
import { CustomLabel, CustomSelectField } from "../../styled";

const LessonLengthSelect = ({ value, handleChange }) => {
  return (
    <>
      <CustomLabel className="mb-3">Lesson Length</CustomLabel>
      <CustomSelectField
        name="lessonLength"
        value={value}
        onChange={handleChange}
        width="100%"
      >
        <option value="" disabled>
          Lesson Length
        </option>
        {Object.keys(LessonsLengthEnum).map((key) => (
          <option key={key} value={key}>
            {LessonsLengthEnum[key]}
          </option>
        ))}
      </CustomSelectField>
    </>
  );
};

export default LessonLengthSelect;
