import { useState } from "react";

import { FeedbackMessage, StyledFeedbackCard } from "./styled";
import FeedbackReplyModal from "../modals/FeedbackReplyModal";
import CardActions from "./CardActions";
import ForwardFeedbackModal from "../modals/ForwardFeedbackModal";
import { Collapse, Divider } from "antd";
import AttachmentsList from "./AttachmentsList";
import AdditionalFeedbackInfo from "../AdditionalFeedbackInfo";

const ActiveFeedback = ({ feedback, onArchive, onDelete, isLoading }) => {
  const [replyModalOpen, setReplyModalOpen] = useState(false);
  const [forwardModalOpen, setForwardModalOpen] = useState(false);

  return (
    <>
      <StyledFeedbackCard
        title={
          feedback?.createdBy?.name
            ? `${feedback?.createdBy?.name}'s feedback`
            : "Feedback"
        }
        actions={[
          feedback && (
            <CardActions
              key="1"
              onReplyClick={() => setReplyModalOpen(true)}
              onForwardClick={() => setForwardModalOpen(true)}
              onArchiveClick={() => onArchive(feedback?.id, true)}
              onDeleteClick={() => onDelete(feedback?.id)}
              isLoading={isLoading}
            />
          ),
        ]}
      >
        <FeedbackMessage>
          {feedback?.message || "Feedback message"}
        </FeedbackMessage>
        <Divider />
        <AttachmentsList attachments={feedback?.attachments} />
        <Divider />
        <Collapse
          items={[
            {
              key: "1",
              label: "Additional information",
              children: <AdditionalFeedbackInfo feedback={feedback} />,
            },
          ]}
        />
      </StyledFeedbackCard>
      <FeedbackReplyModal
        isOpen={replyModalOpen}
        toggle={() => setReplyModalOpen((prevState) => !prevState)}
        feedback={feedback}
        onConfirm={() => setReplyModalOpen(false)}
      />
      <ForwardFeedbackModal
        isOpen={forwardModalOpen}
        toggle={() => setForwardModalOpen((prevState) => !prevState)}
        feedback={feedback}
        onConfirm={() => setForwardModalOpen(false)}
      />
    </>
  );
};

export default ActiveFeedback;
