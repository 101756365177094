import LibraryStudentsList from "../tab-content/students-list/LibraryStudentsList";
import LibraryTeachersList from "../tab-content/teachers-list/LibraryTeachersList";
import React, { useState } from "react";
import LabeledRadioButton from "../common/LabeledRadioButton";
import styled from "styled-components";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width: ${ScreenSize.LG}px) {
    flex-direction: column;
  }
`;

const ShareModalTeacherView = () => {
  const [view, setView] = useState("both");
  return (
    <>
      <div
        className="mb-5 d-flex align-items-center flex-wrap px-3"
        style={{ gap: 20 }}
      >
        <LabeledRadioButton
          label={"both"}
          onChange={setView}
          checked={view === "both"}
          id={"teacherLibraryViewBoth"}
        />
        <LabeledRadioButton
          label={"student"}
          onChange={setView}
          checked={view === "student"}
          id={"teacherLibraryViewStudent"}
        />
        <LabeledRadioButton
          label={"teacher"}
          onChange={setView}
          checked={view === "teacher"}
          id={"teacherLibraryViewTeacher"}
        />
      </div>
      <div className="mb-5">
        <FlexContainer>
          {view === "both" || view === "student" ? (
            <div className="col">
              <h3 className="mb-4 text-black">Select Student</h3>
              <LibraryStudentsList fullWidth={true} />
            </div>
          ) : null}
          {view === "both" || view === "teacher" ? (
            <div className="col">
              <h3 className="mb-4 text-black">Select Teacher</h3>
              <LibraryTeachersList fullWidth={true} />
            </div>
          ) : null}
        </FlexContainer>
      </div>
    </>
  );
};
export default ShareModalTeacherView;
