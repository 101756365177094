import { Field } from "formik";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const Container = styled.div`
  width: 70%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #151515;

  @media (max-width: ${ScreenSize.LG}px) {
    width: 90%;
  }
`;
export const PageLogo = styled.img`
  width: 290px;

  @media (max-width: ${ScreenSize.SM}px) {
    width: 190px;
  }
`;
export const InputLabel = styled.label`
  display: block;
  margin: 10px 0;
  font-weight: 500;
`;
export const Input = styled(Field)`
  display: block;
  height: 45px;
  background-color: #bb8a920f;
  outline: none;
  border: 2px solid #ededed;
  border-radius: 15px;
  width: 100%;
  padding-left: 10px;
  &:disabled {
    background-color: #f3f3f3;
  }
`;
export const RadioWrapper = styled.span`
  display: inline-block;
  padding: 10px 0;
  margin-right: 25px;
`;
export const RadioInput = styled(Field)`
  display: inline-block;
  margin-right: 10px;
`;

export const ErrorMessage = styled.p`
  color: #ce0000;
  font-weight: 400;
`;
