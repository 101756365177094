import { useEffect, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { useApplications } from "../../hooks";

import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { ModalBody } from "reactstrap";
import { PrimaryButton } from "src/utils/shared/styled";
import {
  DeleteButtonWrapper,
  DeleteStatusWrapper,
  WithdrawalReason,
} from "./styled";
import { Divider } from "antd";

const DeleteModal = ({ isOpen, toggle, applicantId, onConfirm }) => {
  const [applicant, setApplicant] = useState(null);
  const { getUserById } = useFirebaseFns();
  const { deleteApplication } = useApplications();

  useEffect(() => {
    (async () => {
      const response = await getUserById(applicantId);
      setApplicant(response);
    })();
  }, [applicantId, getUserById]);

  const handleDeleteClick = () => {
    deleteApplication(applicant);
    onConfirm();
  };

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>Applicant withdraw</StyledModalHeader>
      <ModalBody>
        <DeleteStatusWrapper>
          {applicant?.withdrawal?.requested ? (
            <WithdrawalReason>
              <p>This user requested application withdrawal</p>
              <Divider />
              <p>Reason: {applicant?.withdrawal?.reason}</p>
            </WithdrawalReason>
          ) : (
            <p>This user didn't request application withdrawal</p>
          )}
        </DeleteStatusWrapper>
        <Divider />
        <DeleteButtonWrapper>
          <PrimaryButton
            backgroundColor="#681e46DD"
            onClick={handleDeleteClick}
          >
            {applicant?.withdrawal?.requested ? "Delete" : "Delete anyway"}
          </PrimaryButton>
        </DeleteButtonWrapper>
      </ModalBody>
    </StyledModal>
  );
};

export default DeleteModal;
