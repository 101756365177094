import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { isStudent } from "src/constants/UserRoleEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { isConcertInvitationPendingStudentAction } from "src/utils/helpers";

const initialDataInitialValues = {
  concertsList: [],
  locations: [],
};
const currentConcertDataInitialValues = {
  userInvitations: [], // we only get this for student users
};

const useConcertsListWithDetails = ({ user }) => {
  const { getUpcomingConcerts, getLocations, getConcertInvitationsByUserId } =
    useFirebaseFns();
  const isStudentUser = isStudent(user.role);

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentConcertId, setCurrentConcertId] = useState("");
  const [currentConcertData, setCurrentConcertData] = useState(
    currentConcertDataInitialValues
  );
  const [loadingCurrentConcertData, setLoadingCurrentConcertData] =
    useState(false);

  const [currentConcertColor, setCurrentConcertColor] = useState("");
  const [refresh, setRefresh] = useState(0);

  const [currentInvitationId, setCurrentInvitationId] = useState("");

  const refreshData = () => setRefresh((oldVal) => oldVal + 1);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoadingInitialData(true);

        const [concertsList, locations, userInvitations] = await Promise.all([
          getUpcomingConcerts(),
          getLocations(),
        ]);

        setInitialData({
          concertsList,
          locations,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, [refresh]);

  useEffect(() => {
    if (!currentConcertId) return;

    const fetch = async () => {
      try {
        setLoadingCurrentConcertData(true);

        const [userInvitations] = await Promise.all([
          isStudentUser
            ? getConcertInvitationsByUserId(user.uid, currentConcertId)
            : [],
        ]);

        setCurrentConcertData({
          userInvitations,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingCurrentConcertData(false);
      }
    };
    fetch();
  }, [currentConcertId, initialData]);

  const currentConcert = useMemo(() => {
    return initialData.concertsList?.find(({ id }) => id === currentConcertId);
  }, [currentConcertId, initialData]);

  const userActiveInvitations = useMemo(() => {
    return currentConcertData.userInvitations.filter((invitation) =>
      isConcertInvitationPendingStudentAction(invitation, user.uid)
    );
  }, [currentConcertData]);

  return {
    currentConcertColor,
    setCurrentConcertColor,
    currentConcert,
    setCurrentConcertId,
    loadingInitialData,
    initialData,
    refreshData,
    userActiveInvitations,
    currentConcertData,
    loadingCurrentConcertData,
    currentInvitationId,
    setCurrentInvitationId,
  };
};

export default useConcertsListWithDetails;
