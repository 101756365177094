import styled from "styled-components";

const FeatureNotifyWrapper = styled.div`
  h4 {
    text-align: center;
    color: #681e46;
  }

  p {
    text-align: center;
    font-size: 18px;
    margin-top: 12px;
  }

  button {
    display: block;
    margin: 0 auto;
  }
`;

export { FeatureNotifyWrapper };
