import TeacherLibrarySearchInput from "../../common/TeacherLibrarySearchInput";
import { LibraryStudentsListContainer } from "../../../styled-components/teacherLibraryStyledComponents";
import LibrarySingleStudentRow from "./LibrarySingleStudentRow";
import React, { useContext, useState } from "react";
import {
  StudentsSelectContext,
  StudentsSearchContext,
  StudentsContext,
  studentViews,
} from "../../common/libraryContext";
import EmptyResults from "../../common/EmptyResults";
import SelectAllStudents from "./SelectAllStudents";

const LibraryStudentsList = ({ fullWidth, withToggle = true }) => {
  const { select, selected } = useContext(StudentsSelectContext);
  const { students, currentStudentsView, teacherStudents } =
    useContext(StudentsContext);
  const [studentsSearchValue, setStudentsSearchValue] = useState("");

  const showTeacherStudents =
    currentStudentsView === studentViews.TEACHER_STUDENTS;
  const displayedStudents = showTeacherStudents ? teacherStudents : students;

  const studentsSearchContextValue = {
    studentsSearchValue,
    setStudentsSearchValue,
  };
  const filteredStudents =
    studentsSearchValue === ""
      ? displayedStudents
      : displayedStudents.filter(({ fullName }) =>
          fullName.toLowerCase().includes(studentsSearchValue)
        );

  return (
    <StudentsSearchContext.Provider value={studentsSearchContextValue}>
      <LibraryStudentsListContainer fullWidth={fullWidth}>
        <div className="mb-5">
          <TeacherLibrarySearchInput
            query={studentsSearchValue}
            setQuery={setStudentsSearchValue}
          />
        </div>
        {studentsSearchValue === "" && withToggle ? (
          <SelectAllStudents />
        ) : null}
        {filteredStudents.map(({ id, fullName }, index) => (
          <LibrarySingleStudentRow
            id={id}
            key={id || index}
            checked={selected.includes(id)}
            onChange={() => {
              select(id);
            }}
            label={fullName}
          />
        ))}
        {filteredStudents.length === 0 && studentsSearchValue !== "" && (
          <EmptyResults />
        )}
      </LibraryStudentsListContainer>
    </StudentsSearchContext.Provider>
  );
};
export default LibraryStudentsList;
