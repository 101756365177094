/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../../../../Firebase";
import { getDoc, getDocs, query, where } from "firebase/firestore";
import { parseFirebaseDoc } from "../../../../../utils/getFirebaseDoc";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getRecurringEventTimelineObj,
  updatedMomentNow,
} from "src/utils/helpers";
import moment from "moment";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";

function UseGetStudentsAndLessons(UserID) {
  const { getPrivateLessonsByTeacherId } = useFirebaseFns();

  const firebase = useContext(FirebaseContext);
  const [StudentRows, setStudentRows] = useState([]);
  const [loadingData, setloadingData] = useState(true);
  useEffect(async () => {
    try {
      let lessons = await getPrivateLessonsByTeacherId(UserID);

      const studentRowsValues = [];
      for (let lesson of lessons) {
        const { instrumentId, studentId, type } = lesson;
        const isPackageLsn = isPackagePrivateLesson(type);

        let currentTimeline;
        if (!isPackageLsn) {
          currentTimeline = getRecurringEventTimelineObj({
            timeline: lesson.timeline,
            requestDate: updatedMomentNow().toDate(),
            startDateAccessor: "startDate",
            lastDateAccessor: "lastDate",
            withApproximation: true,
          });
        }

        if (!isPackageLsn && currentTimeline?.teacherId !== UserID) {
          continue;
        }

        let reqs = [
          getDoc(firebase.instrument(instrumentId)),
          getDoc(firebase.user(studentId)),
        ];
        const [InstrumentDoc, studentDoc] = await Promise.all(reqs);
        const instrument = { id: InstrumentDoc.id, ...InstrumentDoc.data() };
        const student = { id: studentDoc.id, ...studentDoc.data() };
        const lessonWithAdditionalData = {
          ...lesson,
          instrument,
          student,
          ...(currentTimeline && { currentTimeline }),
        };
        studentRowsValues.push(lessonWithAdditionalData);
      }
      setStudentRows(studentRowsValues);
      setloadingData(false);
    } catch (error) {
      console.log(error);
      setloadingData(true);
    } finally {
      setloadingData(false);
    }
  }, []);
  return { StudentRows, loadingData };
}

export default UseGetStudentsAndLessons;
