import React from "react";
import useWeather from "../hooks/useWeather";
import DateAndTime from "./DateAndTime";
import useModal from "../hooks/useModal";
import CreateFeedItemsSection from "src/components/Dashboard/common/components/CreateFeedItemsSection";
const NotesSection = () => {
  const weather = useWeather();
  const modalData = useModal();
  return (
    <div>
      <div className="mb-5">
        <DateAndTime weather={weather} />
      </div>
      <CreateFeedItemsSection />
    </div>
  );
};

export default NotesSection;
