import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";

export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
export const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  margin: auto;
  margin-bottom: 10px;
`;
export const TeacherImage = styled.img`
  width: 100%;
  height: 100%;
  border: 12px solid white;
  object-fit: cover;
  border-radius: 50%;
  /* box-shadow: 0 0 0 10px #f3f5f6 inset; */
`;
export const InfoText = styled.p`
  color: grey;
`;
