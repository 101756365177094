import React, { useMemo } from "react";
import {
  groupClassPaymentMethods,
  groupClassPaymentTypes,
} from "src/constants/groupClassPaymentsConstants";
import PaypalScheduledPaymentDetails from "../components/PaypalScheduledPaymentDetails";
import PaypalDirectPaymentDetails from "../components/PaypalDirectPaymentDetails";
import { SubmitSpinner } from "src/components/common";
import NotPaidComponent from "../components/NotPaidComponent";
import ExternalPaymentDetails from "../components/ExternalPaymentDetails";
import FreePaymentDetails from "../components/FreePaymentDetails";

const ViewGroupClassStudentPaymentBody = ({
  groupClassId,
  studentId,
  hookData,
}) => {
  const {
    initialData,
    loadingAction,
    loadingInitialData,
    markAsPaidExternally,
  } = hookData;
  const {
    paymentObj,
    paymentOption,
    paypalOrderDetails,
    paypalSubscriptionDetails,
  } = initialData;

  const map = useMemo(() => {
    return [
      {
        method: groupClassPaymentMethods.PAYPAL,
        type: groupClassPaymentTypes.ONE_TIME,
        component: () => (
          <PaypalDirectPaymentDetails paypalOrderDetails={paypalOrderDetails} />
        ),
      },
      {
        method: groupClassPaymentMethods.PAYPAL,
        type: groupClassPaymentTypes.SCHEDULED,
        component: () => (
          <PaypalScheduledPaymentDetails
            paymentOption={paymentOption}
            paypalSubscriptionDetails={paypalSubscriptionDetails}
          />
        ),
      },
      {
        method: groupClassPaymentMethods.EXTERNAL,
        type: groupClassPaymentTypes.ONE_TIME,
        component: () => <ExternalPaymentDetails />,
      },
      {
        method: groupClassPaymentMethods.EXTERNAL,
        type: groupClassPaymentTypes.SCHEDULED,
        component: () => <ExternalPaymentDetails />,
      },
      {
        type: groupClassPaymentTypes.FREE,
        component: () => <FreePaymentDetails />,
      },
    ];
  }, [initialData]);

  // if we paid externally or there is no payment at all, we display the pay externally components
  const renderCurrentPaymentComponent = () => {
    const { component: Component } =
      map.find(
        ({ method, type }) =>
          type === paymentOption.paymentType &&
          method === paymentObj.paymentMethod
      ) || {};

    return <Component />;
  };
  if (loadingInitialData) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <div>
      {paymentObj ? (
        renderCurrentPaymentComponent()
      ) : (
        <NotPaidComponent
          loadingAction={loadingAction}
          markAsPaidExternally={markAsPaidExternally}
        />
      )}
    </div>
  );
};

export default ViewGroupClassStudentPaymentBody;
