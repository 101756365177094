import LibrarySingleTeacherRow from "./LibrarySingleTeacherRow";
import React, { useContext } from "react";
import {TeachersContext, TeachersSelectContext} from "../../common/libraryContext";

const SelectAllTeachers = () => {
  const { selected, toggle } = useContext(TeachersSelectContext);
  const {teachers} = useContext(TeachersContext);
  return (
    <div className={"d-flex align-items-start justify-content-between"}>
      <LibrarySingleTeacherRow
        id={"all_teachers"}
        checked={selected.length === teachers.length && teachers.length}
        onChange={toggle}
        label={"All"}
      />

      {selected.length ? (
        <div className={"px-3 text-muted"}>
          <span>{selected.length}</span>
          <span className={"ms-1"}>
            Teacher{selected.length > 1 && "s"} Selected
          </span>
        </div>
      ) : null}
    </div>
  );
};
export default SelectAllTeachers;
