import React, { useMemo, useState } from "react";
import RouteContent from "../../../common/RouteContent";
import CustomCalendarComponent from "../../../common/CustomCalendar";
import useScheduleData from "./hooks/useScheduleData";
import { SubmitSpinner } from "../../../common";
import {
  eventPropGetter,
  excludeAbsenceFromPLs,
  excludeSchoolBreaksFromRecurringEvents,
  filterSummerBreaksFromPLs,
  filterWithdrawalFromPLs,
  generateRecurringEvents,
  joinSimilarEvents,
  mapGroupClassToEvents,
  mapMakeupLessonToEvent,
  mapPackagePrivateLessonToEvents,
  mapSchoolBreakToScheduleEvent,
  mapTLToEvent,
} from "../helperFns";
import {
  eventsMap,
  isPackagePrivateLesson,
  isPrivateLessonEvent,
} from "../../../../constants/eventsEnum";
import useModal from "../hooks/useModal";
import StudentEventDetailsModal from "./components/StudentEventDetailsModal";
import { UserRole } from "../../../../constants/UserRoleEnum";
import useAbsenceModal from "./hooks/useAbsenceModal";
import AbsenceModal from "./components/AbsenceModal";
import { absenceModalMap } from "./constants";
import MakeupModal from "../../common/components/MakeupModal";
import { CustomSelectField } from "../styled";
import { modalDetailsEventsCodes } from "../constants";
import { isCanceledTrialLesson } from "src/constants/trialLessonStatuses";
import { groupClassesListViews } from "../../GroupClass/constants";

const StudentSchedule = ({ user }) => {
  const { isModalOpen, ...modalActions } = useModal();
  const eventDetailsModalData = useModal();

  const absenceModalData = useAbsenceModal();
  const makeupModalData = useModal();
  const {
    initialData,
    loadingInitialData,
    selectedEvent,
    setSelectedEvent,
    refreshData,
    allEventsLocationsIds,
    selectedLocationId,
    handleSelectedLocationIdChange,
    combinedSchoolBreaks,
    currentSchoolYearInLocation,
  } = useScheduleData(user) || {};
  const {
    instruments,
    programs,
    locations,
    studios,
    studentPLs,
    studentTLs,
    studentMakeupLessons,
    schoolYears,
    absences,
    studentGroupClasses,
  } = initialData;

  const [currentDate, setCurrentDate] = useState(new Date());
  // when creating the absence, it sets the justCreatedAbsence state to that just created absence obj (Its then used to connect the makeup to this absence)
  const [justCreatedAbsence, setJustCreatedAbsence] = useState(null);

  const regularPrivateLessonsRecurringEvents = useMemo(() => {
    const recurringEvents = generateRecurringEvents({
      docs: studentPLs.filter(({ type }) => !isPackagePrivateLesson(type)),
      startDateAccessor: "startDate",
      lastDateAccessor: "lastDate",
      date: currentDate,
      resourceIdAccessor: "teacherId",
      eventCode: eventsMap.privateLesson.code,
      instruments,
      role: UserRole.STUDENT,
    });

    // location filter
    const eventsInLocation = recurringEvents.filter(
      ({ currentTimeline }) =>
        selectedLocationId === currentTimeline?.locationId
    );

    return eventsInLocation;
  }, [studentPLs, selectedLocationId, currentDate, instruments]);

  const packagePrivateLessonsEvents = useMemo(() => {
    const packageItemsEvents = studentPLs
      .filter(({ type }) => isPackagePrivateLesson(type))
      .map((lesson) =>
        mapPackagePrivateLessonToEvents({
          packageLesson: lesson,
          selectedTeachers: null,
          role: UserRole.STUDENT,
          instruments,
        })
      );
    const flatPackageLessonEvents = packageItemsEvents.flat();
    // location filter
    const eventsInLocation = flatPackageLessonEvents.filter(
      ({ locationId }) => selectedLocationId === locationId
    );

    return eventsInLocation;
  }, [studentPLs, selectedLocationId]);

  const combinedPrivateLessonsEvents = useMemo(() => {
    return [
      ...regularPrivateLessonsRecurringEvents,
      ...packagePrivateLessonsEvents,
    ];
  }, [regularPrivateLessonsRecurringEvents, packagePrivateLessonsEvents]);

  const filteredPLsRecurringEvents = useMemo(() => {
    const filteredAbsencesFromPLs = excludeAbsenceFromPLs(
      combinedPrivateLessonsEvents,
      absences
    );

    // remove the event starting from the withdrawal date + change color and title of last lesson date
    const filteredWithdrawalsFromPLs = filterWithdrawalFromPLs(
      filteredAbsencesFromPLs
    );

    const filteredSummerBreaksFromPLs = filterSummerBreaksFromPLs(
      filteredWithdrawalsFromPLs
    );
    return filteredSummerBreaksFromPLs;
  }, [combinedPrivateLessonsEvents, absences]);
  const PLsRecurringEventsWithoutSchoolBreaks = useMemo(
    () =>
      excludeSchoolBreaksFromRecurringEvents(
        filteredPLsRecurringEvents,
        combinedSchoolBreaks
      ),
    [filteredPLsRecurringEvents, combinedSchoolBreaks]
  );
  const trialLessonsEvents = useMemo(
    () =>
      studentTLs
        .filter(({ status }) => !isCanceledTrialLesson(status))
        .map((lesson) => mapTLToEvent(lesson, UserRole.STUDENT, instruments)),
    [studentTLs, instruments]
  );
  const makeupLessonsEvents = useMemo(
    () =>
      studentMakeupLessons.map((lesson) =>
        mapMakeupLessonToEvent(lesson, UserRole.STUDENT, instruments)
      ),
    [studentMakeupLessons, instruments]
  );
  const schoolBreaksCalendarEvents = useMemo(
    () =>
      combinedSchoolBreaks.map((schoolBreak) =>
        mapSchoolBreakToScheduleEvent(schoolBreak)
      ),
    [combinedSchoolBreaks]
  );

  const groupClassesEvents = useMemo(() => {
    const events = studentGroupClasses
      .map((groupClass) =>
        mapGroupClassToEvents({
          groupClass,
          selectedTeachers: null,
          role: UserRole.STUDENT,
        })
      )
      .flat();

    return events;
  }, [studentGroupClasses]);

  let selectedEvents = useMemo(
    () => [
      ...PLsRecurringEventsWithoutSchoolBreaks,
      ...trialLessonsEvents,
      ...makeupLessonsEvents,
      ...schoolBreaksCalendarEvents,
      ...groupClassesEvents
        .filter((event) => event?.status === groupClassesListViews.active.value)
        .map((event) => ({
          ...event,
          title: !event?.isCanceled
            ? event?.title
            : `${event?.title} (Canceled)`,
        })),
    ],
    [
      PLsRecurringEventsWithoutSchoolBreaks,
      trialLessonsEvents,
      makeupLessonsEvents,
      schoolBreaksCalendarEvents,
      groupClassesEvents,
    ]
  );

  // joins the makeup lsn with the private lesson if they come right after each other
  selectedEvents = useMemo(
    () => joinSimilarEvents(selectedEvents),
    [selectedEvents]
  );

  return (
    <RouteContent childrenWithNoPadding title={"My Schedule"}>
      {!loadingInitialData ? (
        <div className="vh-100" style={{ marginTop: "50px" }}>
          {allEventsLocationsIds.length > 1 && (
            <div className="mb-4">
              <CustomSelectField
                className="m-auto"
                width="300px"
                value={selectedLocationId}
                onChange={(e) => handleSelectedLocationIdChange(e.target.value)}
              >
                <option value="" disabled>
                  Select A Location
                </option>

                {Object.values(initialData.locations)
                  .filter(({ id }) => allEventsLocationsIds.includes(id))
                  .map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
              </CustomSelectField>
            </div>
          )}
          <CustomCalendarComponent
            showCurrentTimeIndicatorDot
            defaultView="month"
            events={selectedEvents}
            eventPropGetter={eventPropGetter}
            onNavigate={(newDate) => {
              setCurrentDate(newDate);
            }}
            // On clicking on an event in the calendar
            onSelectEvent={(event) => {
              if (modalDetailsEventsCodes.includes(event?.eventCode)) {
                eventDetailsModalData.openModal();
                setSelectedEvent(event);
              }
            }}
            date={currentDate}
            popup
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <SubmitSpinner
            style={{ width: "3rem", height: "3rem", color: "#681e46" }}
          />
        </div>
      )}
      {eventDetailsModalData.isModalOpen &&
        modalDetailsEventsCodes.includes(selectedEvent?.eventCode) && (
          <StudentEventDetailsModal
            event={selectedEvent}
            modalData={eventDetailsModalData}
            initialData={initialData}
            openAbsenceModal={() =>
              absenceModalData.openModalWithName(
                absenceModalMap.absencePolicy.name
              )
            }
          />
        )}
      {isPrivateLessonEvent(selectedEvent?.eventCode) &&
        !selectedEvent.isPLAbsenceEvent && (
          <>
            <AbsenceModal
              setJustCreatedAbsence={setJustCreatedAbsence}
              user={user}
              absenceModalData={absenceModalData}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              refreshData={refreshData}
              closeEventDetailsModal={eventDetailsModalData.closeModal}
              makeupModalData={makeupModalData}
              currentSchoolYearInLocation={currentSchoolYearInLocation}
            />
            {justCreatedAbsence && (
              <MakeupModal
                currentAbsence={justCreatedAbsence}
                makeupModalData={makeupModalData}
                lesson={selectedEvent}
                onSuccess={() => {
                  window.location.reload();
                }}
                shortenTimeSlots
              />
            )}
          </>
        )}
    </RouteContent>
  );
};
export default StudentSchedule;
