import React from "react";
import { SubmitSpinner } from "src/components/common";
import { injectUserStore } from "src/utils/helpers";
import { dropdownOptionsValues } from "../../../constants";
import { useFeedSectionContext } from "../../../contexts/FeedSectionContext";
import { DropdownOptionsContainer } from "../styled";
import DropdownOption from "./DropdownOption";

const DropdownContent = ({ toggleDropdown, feedItem, UserStore }) => {
  const user = UserStore.user;
  const userRole = user.role;
  const { dropdownOptions, loadingAction, getFeedItemMetaInfo } =
    useFeedSectionContext();

  if (loadingAction) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: 125 }}
      >
        <SubmitSpinner
          style={{ width: "2rem", height: "2rem", color: "#681e46" }}
        />
      </div>
    );
  }

  const { isBookmarked, isHidden, isPinned } = getFeedItemMetaInfo(feedItem.id);

  const filteredOptions = dropdownOptions.filter(({ availableFor, value }) => {
    const isAvailableForUserRole = availableFor.includes(userRole);

    let isAvailableAction = false;

    switch (value) {
      case dropdownOptionsValues.BOOKMARK:
        isAvailableAction = !isBookmarked;
        break;
      case dropdownOptionsValues.UNBOOKMARK:
        isAvailableAction = isBookmarked;
        break;
      case dropdownOptionsValues.PIN:
        isAvailableAction = !isPinned;
        break;
      case dropdownOptionsValues.UNPIN:
        isAvailableAction = isPinned;
        break;
      case dropdownOptionsValues.HIDE:
        isAvailableAction = !isHidden;
        break;
      case dropdownOptionsValues.RESTORE:
        isAvailableAction = isHidden;
        break;
      case dropdownOptionsValues.EDIT:
        isAvailableAction = true;
        break;
      case dropdownOptionsValues.DELETE:
        isAvailableAction = true;
        break;
      case dropdownOptionsValues.DOWNLOAD_ATTACHMENT:
        isAvailableAction = true;
        break;
      default:
        break;
    }

    return isAvailableForUserRole && isAvailableAction;
  });
  return (
    <DropdownOptionsContainer>
      {filteredOptions.map((option) => {
        return (
          <DropdownOption
            key={option.value}
            option={option}
            toggleDropdown={toggleDropdown}
            feedItem={feedItem}
          />
        );
      })}
    </DropdownOptionsContainer>
  );
};

export default injectUserStore(DropdownContent);
