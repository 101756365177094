/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-expressions */
import React, { useContext, useRef, useState, useEffect } from "react";
import "./style.css";
import { toast } from "react-toastify";
import { FirebaseContext } from "../../Firebase";
import { setDoc, addDoc, updateDoc, Timestamp } from "firebase/firestore";
import CreateStudentTrialLesson from "./CreateStudentTrialLesson";
import TrialStudentModal from "./TrialStudentModal";

const AddTrialLessonStudentModal = ({
  mode,
  initialValues,
  isOpen,
  onAddStudentSuccess,
  onStudentUpdateSuccess,
  onClose,
}) => {
  const firebase = useContext(FirebaseContext);
  const [currentUserID, setCurrentUserID] = useState("");
  const [lessonModal, setLessonModal] = useState(false);

  const close = () => {
    onClose();
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const { id, ...studentDocument } = values;
    setSubmitting(true);
    try {
      if (mode === "edit") {
        toast.info("Updating student info...");
        studentDocument.updatedAt = Timestamp.now();
        await updateDoc(
          firebase.getTrialStudent(initialValues.id),
          studentDocument
        );
        toast.success("Student Updated Successfully");
        onStudentUpdateSuccess({ ...studentDocument, id });
        close();
      } else {
        toast.info("Creating a new student...");
        const doc = await addDoc(firebase.getTrialStudents(), {
          ...studentDocument,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        });
        toast.success("Student Created Successfully");
        onAddStudentSuccess({
          ...studentDocument,
          id: doc.id,
        });
        setCurrentUserID(doc.id);
        setLessonModal(true);
      }
      var x = document.getElementsByTagName("BODY")[0];
      x.classList.remove("overflowBody");
      setSubmitting(false);
    } catch (error) {
      toast.error(error.message);
      console.log("saving student-error", error);
    }
  };

  return (
    <>
      <TrialStudentModal
        isOpen={isOpen}
        onClose={close}
        initialValues={initialValues}
        mode={mode}
        onSubmit={onSubmit}
        onAddStudentSuccess={onAddStudentSuccess}
      />

      {lessonModal ? (
        <CreateStudentTrialLesson
          studentID={currentUserID}
          isOpen={lessonModal}
          firebase={firebase}
          onClose={() => {
            setLessonModal(false);
            onClose();
          }}
        />
      ) : null}
    </>
  );
};
export default AddTrialLessonStudentModal;
