import React, { useMemo } from "react";

import {
  CustomInput,
  CustomLabel,
  CustomSelectField,
  CustomTextarea,
  PrimaryButton,
} from "src/utils/shared/styled";
import styled from "styled-components";
import useAbsenceRequestData from "../hooks/useAbsenceRequestData";
import {
  Container,
  CustomRangeContainer,
  CustomRangeInput,
  FormContainer,
  OptionContainer,
  OptionsContainer,
  RedBarContainer,
  RedBarText,
} from "../styled";
import AbsenceBehaviourSelect from "./AbsenceBehaviourSelect";
import MakeUpOptionsSection from "./MakeUpOptionsSection";
import { toJS } from "mobx";
import { SubmitSpinner } from "src/components/common";
import { injectUserStore, updatedMomentNow } from "src/utils/helpers";

const AbsenceRequestBody = ({
  teacher,
  modalData,
  onAbsenceRequestSuccess,
  limitDatePicker,
  UserStore,
  submitButtonText,
}) => {
  const user = toJS(UserStore)?.user;
  const absenceRequestData = useAbsenceRequestData({
    user,
    teacher,
    modalData,
    onAbsenceRequestSuccess,
  });
  const {
    selectedDate,
    handleSelectedDateChange,
    teacherNote,
    handleTeacherNoteChange,
    submitAbsenceRequest,
    isSubmitting,
  } = absenceRequestData;

  const minValue = limitDatePicker
    ? updatedMomentNow().add(3, "weeks").format("YYYY-MM-DD")
    : null;
  return (
    <div>
      <Container>
        <RedBarContainer>
          <RedBarText>Absence Request</RedBarText>
        </RedBarContainer>
        <FormContainer>
          <div className="d-flex justify-content-between">
            <div style={{ width: "70%" }}>
              <CustomLabel>Date:</CustomLabel>
              <CustomInput
                width="100%"
                type="date"
                value={selectedDate}
                onChange={(e) => handleSelectedDateChange(e.target.value)}
                onKeyDown={(e) => e.preventDefault()}
                min={minValue}
              />
            </div>
          </div>
          <div>
            <AbsenceBehaviourSelect absenceRequestData={absenceRequestData} />
          </div>
          <div style={{ marginTop: "60px" }}>
            <CustomLabel>Reason and Note</CustomLabel>
            <CustomTextarea
              width={"100%"}
              value={teacherNote}
              onChange={(e) => handleTeacherNoteChange(e.target.value)}
            />
          </div>
          <div className="mt-5">
            <MakeUpOptionsSection absenceRequestData={absenceRequestData} />
          </div>
          <div className="mt-5 text-center">
            <PrimaryButton onClick={submitAbsenceRequest}>
              {isSubmitting ? (
                <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
              ) : (
                submitButtonText
              )}
            </PrimaryButton>
          </div>
        </FormContainer>
      </Container>
      <div className="mt-5 mb-5"></div>
    </div>
  );
};

export default injectUserStore(AbsenceRequestBody);
