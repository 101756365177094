import { arrayUnion, deleteDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const deleteOneTimeBreakAndActivity = async (breakId) => {
    try {
      const reqs = [
        deleteDoc(firebase.break(breakId)),
        deleteDoc(firebase.userActivity(breakId)),
      ];
      await Promise.all(reqs);
    } catch (err) {
      toast.error(err?.message);
      console.log(err);
    }
  };

  const cancelOneRecurringBreakAndActivity = async (breakId, canceledDate) => {
    try {
      const updateObj = {
        canceledDates: arrayUnion(canceledDate),
      };

      await updateDoc(firebase.break(breakId), updateObj);
      await updateDoc(firebase.userActivity(breakId), updateObj);
    } catch (err) {
      toast.error(err?.message);
      console.log(err);
    }
  };
  const cancelFutureRecurringBreakAndActivity = async (
    breakId,
    canceledDate
  ) => {
    try {
      const updateObj = {
        canceledAt: canceledDate,
      };

      await updateDoc(firebase.break(breakId), updateObj);
      await updateDoc(firebase.userActivity(breakId), updateObj);
    } catch (err) {
      toast.error(err?.message);
      console.log(err);
    }
  };
  return {
    deleteOneTimeBreakAndActivity,
    cancelOneRecurringBreakAndActivity,
    cancelFutureRecurringBreakAndActivity,
  };
};

export default useFirebaseFns;
