import moment from "moment";
import React from "react";
import { Container } from "reactstrap";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import {
  getCurrentSchoolYear,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
  isRequestDateWithinSchoolYear,
} from "src/utils/helpers";
import {
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";
import { parseDate } from "../helperFns";

const MakeUpTable = ({ absences, selectedLessonMakeups }) => {
  return (
    <CustomTableContainer>
      <CustomTable>
        <thead>
          <TableRow>
            <th>
              <div>Absence Date</div>
            </th>
            <th>
              <div>Make Up Date</div>
            </th>
            <th>
              <div>Make Up Duration</div>
            </th>
            <th>
              <div>Lesson Time</div>
            </th>
            <th>
              <div>Teacher Name</div>
            </th>
            <th>
              <div>Fee</div>
            </th>
          </TableRow>
        </thead>
        <tbody>
          {selectedLessonMakeups.map((makeUp, i) => {
            const lessonAbsence = absences.find(
              ({ id }) => id === makeUp.forAbsenceId
            );
            const lessonAbsenceDate =
              lessonAbsence?.date || lessonAbsence?.startDate;

            const makeUpDuration = getTimeDiffInMins(
              makeUp.date.startDate,
              makeUp.date.endDate
            );

            const makeUpStartTime = moment(makeUp.date.startDate).format(
              "h:mm a"
            );

            const formattedFee = makeUp.paymentAmount
              ? `$${makeUp.paymentAmount}`
              : "Included";
            return (
              <TableRow key={i}>
                <td>{parseDate(lessonAbsenceDate)}</td>
                <td>{parseDate(makeUp.date.startDate)}</td>
                <td>{`${makeUpDuration} Minutes`}</td>
                <td>{makeUpStartTime}</td>
                <td>{makeUp.teacher?.fullName}</td>
                <td>{formattedFee}</td>
              </TableRow>
            );
          })}
        </tbody>
      </CustomTable>
    </CustomTableContainer>
  );
};

export default MakeUpTable;
