import React, { createContext, useState, useEffect, useMemo } from "react";
import RouteContent from "../../../common/RouteContent";
import "../Style/Style.css";
import SearchContainer from "./SearchContainer";
import StudentsTable from "./StudentsTable";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import UseGetStudentsAndLessons from "./Hooks/UseGetStudentsAndLessons";
export const SearchContext = createContext();
function StudentList({ UserStore }) {
  const [searchUser, setsearchUser] = useState("");
  const UserId = UserStore.user.uid;
  const lessonsData = UseGetStudentsAndLessons(UserId);

  return (
    <>
      <RouteContent title="Student List">
        <SearchContext.Provider value={{ searchUser, setsearchUser }}>
          <SearchContainer />
        </SearchContext.Provider>

        <StudentsTable lessonsData={lessonsData} searchTerm={searchUser} />
      </RouteContent>
    </>
  );
}

export default compose(inject("UserStore"), observer)(StudentList);
