import { updateDoc } from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  LessonDetailsModalContext,
  LessonNotesContext,
  SubmitHomeworkModalContext,
  TeachersContext,
  ViewHomeworkModalContext,
  ViewItemModalContext,
  ViewNoteModalContext,
} from "../../common/Library/common/libraryContext";
import ViewItemModal from "../../common/Library/view-item-modal/ViewItemModal";
import { FirebaseContext } from "../../Firebase";
import LessonNote from "./LessonNote";
import SubmitHomeworkModal from "./submit-homework-modal/SubmitHomeworkModal";
import ViewHomeworkModal from "./view-homework-modal/ViewHomeworkModal";
import ViewLessonDetailsModal from "./view-lesson-details-modal/ViewLessonDetailsModal";
import ViewNoteModal from "./view-note-modal/ViewNoteModal";
const INITIAL_HOMEWORK_STATE = {
  lesson: {
    date: new Date(),
    title: "",
  },
  noteId: "",
};
const LessonNotes = () => {
  const { notes, students, isEditable, lessons, userRole } =
    useContext(LessonNotesContext);
  const firebase = useContext(FirebaseContext);
  const { teachers } = useContext(TeachersContext);
  const { isOpen: showSubmitHomeworkModal } = useContext(
    SubmitHomeworkModalContext
  );
  const [showViewHomeworkModal, setShowViewHomeworkModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showViewItemModal, setShowViewItemModal] = useState({
    isOpen: false,
    item: {},
  });
  const [showViewLessonDetailsModal, setShowViewLessonDetailsModal] = useState({
    isOpen: false,
    lesson: {},
  });
  const [viewNoteModal, setViewNoteModal] = useState({
    isOpen: false,
    note: {},
  });
  const sortedNotes = useMemo(() => {
    notes.sort((a, b) =>
      moment(a.createdAt.toDate()).isBefore(moment(b.createdAt.toDate()))
        ? 1
        : -1
    );
    return notes;
  }, [notes]);
  useEffect(() => {
    // setSelectedUser(students?.length ? students[0] : {});
  }, [students]);
  const [homework, setHomework] = useState(INITIAL_HOMEWORK_STATE);
  const viewHomeworkModalContextValue = {
    isOpen: showViewHomeworkModal,
    close: () => {
      setShowViewHomeworkModal(false);
      setHomework({ ...INITIAL_HOMEWORK_STATE });
    },
    openHomework: (homework, note) => {
      setSelectedUser(
        isEditable
          ? students.find(({ id }) => id === note.studentsIds[0])
          : teachers.find(({ id }) => id === note.teacherId)
      );
      setHomework({
        ...homework,
        noteId: note.id,
        lesson: {
          date: new Date(),
          title: "",
        },
      });
      setShowViewHomeworkModal(true);
    },
    homework,
    approveHomework: async () => {
      toast.info("Approving student homework...");
      toast.info("Saving in background, please don't refresh the page");
      setShowViewHomeworkModal(false);
      setHomework({ ...INITIAL_HOMEWORK_STATE });
      const { noteId } = homework;
      const { id } = selectedUser;
      const note = notes.find(({ id }) => id === noteId);
      await updateDoc(firebase.getLessonNote(noteId), {
        homework: {
          ...note.homework,
          [id]: {
            ...note.homework[id],
            isApproved: true,
          },
        },
      });
      toast.success("Homework approved successfully.");
    },
    submitTeacherComment: async (teacherComment) => {
      toast.info("Submitting Comment...");
      setShowViewHomeworkModal(false);
      setHomework({ ...INITIAL_HOMEWORK_STATE });
      const { noteId } = homework;
      const { id } = selectedUser;
      const note = notes.find(({ id }) => id === noteId);
      await updateDoc(firebase.getLessonNote(noteId), {
        homework: {
          ...note.homework,
          [id]: {
            ...note.homework[id],
            teacherComment,
          },
        },
      });
      toast.success("Updated successfully.");
    },
    selectedUser,
    setSelectedUser: (userId) => {
      setSelectedUser(
        students.find(({ id }) => id === userId) ||
          teachers.find(({ id }) => id === userId) ||
          {}
      );
    },
  };
  const onCloseViewModal = () => {
    setShowViewItemModal({
      isOpen: false,
      item: Object.assign({}, {}),
    });
  };
  const viewItemModalContextValue = {
    state: showViewItemModal,
    open: (item) => {
      setShowViewItemModal({
        isOpen: true,
        item: Object.assign({}, item),
      });
    },
    close: onCloseViewModal,
  };
  const viewNoteModalContextValue = {
    isOpen: viewNoteModal.isOpen,
    open: (note) => {
      setSelectedUser(
        isEditable
          ? students.find(({ id }) => id === note.studentsIds[0])
          : teachers.find(({ id }) => id === note.teacherId)
      );
      setViewNoteModal({
        note,
        isOpen: true,
      });
    },
    close: () => {
      setViewNoteModal({
        isOpen: false,
        note: {},
      });
    },
    note: viewNoteModal.note,
  };
  const viewLessonDetailsModalContextValue = {
    isOpen: showViewLessonDetailsModal.isOpen,
    lesson: showViewLessonDetailsModal.lesson,
    open: (note) => {
      setShowViewLessonDetailsModal({
        isOpen: true,
        lesson: {},
      });
    },
    close: () => {
      setShowViewLessonDetailsModal({
        isOpen: false,
        lesson: {},
      });
    },
  };

  return (
    <ViewNoteModalContext.Provider value={viewNoteModalContextValue}>
      <ViewHomeworkModalContext.Provider value={viewHomeworkModalContextValue}>
        <LessonDetailsModalContext.Provider
          value={viewLessonDetailsModalContextValue}
        >
          <div
            className={"ms-2"}
            style={{
              maxHeight: "430px",
              overflowY: "scroll",
            }}
          >
            <ViewItemModalContext.Provider value={viewItemModalContextValue}>
              {showViewHomeworkModal ? <ViewHomeworkModal /> : null}
              <ViewItemModal />
              {showViewLessonDetailsModal.isOpen ? (
                <ViewLessonDetailsModal />
              ) : null}
              {viewNoteModal.isOpen ? <ViewNoteModal /> : null}
              {sortedNotes.map((note, i) => (
                <LessonNote note={note} key={i} />
              ))}
              {notes.length === 0 ? (
                <div className={"alert alert-warning text-center py-4 mx-5"}>
                  No notes here.
                </div>
              ) : null}
              {showSubmitHomeworkModal ? <SubmitHomeworkModal /> : null}
            </ViewItemModalContext.Provider>
          </div>
        </LessonDetailsModalContext.Provider>
      </ViewHomeworkModalContext.Provider>
    </ViewNoteModalContext.Provider>
  );
};
export default LessonNotes;
