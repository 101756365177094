import React from "react";
import { CustomFormInput, SecondaryText } from "../styled";
import { PrimaryText } from "src/components/common/NotificationsMenu/styled";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { Plus } from "@styled-icons/bootstrap/Plus";
import { RequiredInputSpan } from "src/utils/shared/styled";

const RepertoiresSection = ({
  repertoires,
  addNewRepertoireRow,
  handleDeleteRepertoire,
  handleRepertoireChange,
}) => {
  return (
    <div>
      <SecondaryText className="mb-2">
        Repertoire(s)
        <RequiredInputSpan>*</RequiredInputSpan>
        <Plus
          onClick={() => addNewRepertoireRow()}
          style={{ cursor: "pointer", marginLeft: 20, color: "#000" }}
          width="25px"
          height="25px"
        />
      </SecondaryText>
      {repertoires.map((rep, i) => {
        return (
          <div key={rep.id}>
            <div className="d-flex align-items-end" style={{ gap: 25 }}>
              <PrimaryText fontWeight="bold">{i + 1}.</PrimaryText>

              <div
                className="d-flex align-items-end flex-wrap"
                style={{ gap: 25 }}
              >
                <CustomFormInput
                  width="50%"
                  minWidth="150px"
                  value={rep.description}
                  onChange={(e) =>
                    handleRepertoireChange("description", e.target.value, i)
                  }
                />
                <CustomFormInput
                  width="50px"
                  type="number"
                  value={rep.duration}
                  onChange={(e) =>
                    handleRepertoireChange("duration", e.target.value, i)
                  }
                />
                <SecondaryText>minutes</SecondaryText>
                <DeleteOff
                  onClick={() => handleDeleteRepertoire(rep.id)}
                  style={{ cursor: "pointer" }}
                  width="25px"
                  height="25px"
                />
              </div>
            </div>
            {i < repertoires.length - 1 && <hr />}
          </div>
        );
      })}
    </div>
  );
};

export default RepertoiresSection;
