import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import {
  absenceMakeUpOptions,
  isInProgressAbsence,
  isPerDurationAbsence,
  isPerLessonAbsence,
  isSubmittedAbsence,
} from "src/constants/absenceTypes";
import useModal from "src/hooks/useModal";
import {
  CustomTableContainer,
  CustomTable,
  TableRow,
  ClickableTableCell,
} from "src/utils/shared/styled";
import { IN_PROGRESS_COLOR, SUBMITTED_COLOR } from "../../constants";
import { StatusCircle, IconWrapper, DeleteIcon } from "../../styled";
import AbsenceDetailsModal from "./AbsenceDetailsModal";
import AffectedLessonsModal from "./AffectedLessonsModal";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { ConfirmDeleteModal } from "../ConfirmDeleteModal";

const PendingAbsencesTable = ({ absencesData }) => {
  const {
    initialData,
    teacherPendingAbsences,
    currentAbsence,
    setCurrentAbsenceId,
    refreshData,
    justApprovedAbsence,
  } = absencesData;
  const { teachers } = initialData;
  const absenceDetailsModalData = useModal();
  const { deleteTeacherAbsence } = useFirebaseFns();

  // isOpenByDefault bec the modal opens after submitting the absence action , which triggers a refreshData() which resets the modal states , so the affected lessons modal needs to be open by default (waits for the justApprovedAbsence to actually open)
  const affectedLessonsModalData = useModal({ isOpenByDefault: true });
  const [teacherAbsences, setTeacherAbsences] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedAbsenceId, setSelectedAbsenceId] = useState(null);

  const openAbsenceDetailsModal = (absenceId) => {
    setCurrentAbsenceId(absenceId);
    absenceDetailsModalData.openModal();
  };

  useEffect(() => {
    setTeacherAbsences([...teacherPendingAbsences]);
  }, [teacherPendingAbsences]);

  const handleDeleteModalOpen = (absenceId) => {
    setSelectedAbsenceId(absenceId);
    setConfirmModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedAbsenceId(null);
    setConfirmModalOpen(false);
  };

  const handleAbsenceDelete = async (absenceId) => {
    await deleteTeacherAbsence(absenceId);
    setTeacherAbsences(
      teacherAbsences.filter((absence) => absence.id !== absenceId)
    );
    setSelectedAbsenceId(null);
    setConfirmModalOpen(false);
  };

  // clears the current absence id when closing the modal
  useEffect(() => {
    if (!absenceDetailsModalData.isModalOpen) {
      setCurrentAbsenceId("");
    }
  }, [absenceDetailsModalData.isModalOpen]);

  return (
    <Container fluid className="p-0">
      <CustomTableContainer>
        <CustomTable className="w-100 text-dark">
          <thead>
            <TableRow>
              <th>
                <div>Request Date</div>
              </th>
              <th>
                <div>Absence Date</div>
              </th>
              <th>
                <div>Teacher Name</div>
              </th>
              <th>
                <div>Status</div>
              </th>
              <th>
                <div>Type</div>
              </th>
              <th>
                <div>Actions</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {teacherAbsences.map((absence) => {
              const { date, createdAt, startDate, teacherId, status, request } =
                absence;
              const isSubmitted = isSubmittedAbsence(status);
              const isInProgress = isInProgressAbsence(status);

              const isPerDuration = isPerDurationAbsence(
                request?.absenceBehaviour
              );
              const isPerLesson = isPerLessonAbsence(request?.absenceBehaviour);

              const createdAtStr = moment(createdAt).format("MM-DD-YYYY");
              const dateStr = moment(
                date || startDate || request?.date || request?.startDate
              ).format("MM-DD-YYYY");

              const teacherName = teachers.find(
                ({ id }) => id === teacherId
              )?.fullName;

              const statusStr = isSubmitted ? "Submitted" : "In Progress";
              const behaviourStr = isPerDuration
                ? "Duration"
                : isPerLesson
                ? "Per Lesson"
                : "N/A";

              const statusColor = isSubmitted
                ? SUBMITTED_COLOR
                : isInProgress
                ? IN_PROGRESS_COLOR
                : "#000";
              return (
                <TableRow>
                  <ClickableTableCell
                    onClick={() => openAbsenceDetailsModal(absence.id)}
                  >
                    <StatusCircle bgColor={statusColor}></StatusCircle>
                    <span className="d-inline-block">{createdAtStr}</span>
                  </ClickableTableCell>
                  <td>{dateStr}</td>
                  <td>{teacherName || "N/A"}</td>
                  <td>{statusStr}</td>
                  <td>{behaviourStr}</td>
                  <td>
                    <IconWrapper
                      onClick={() => handleDeleteModalOpen(absence.id)}
                    >
                      <DeleteIcon />
                    </IconWrapper>
                  </td>
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>
      {absenceDetailsModalData.isModalOpen && currentAbsence && (
        <AbsenceDetailsModal
          modalData={absenceDetailsModalData}
          absencesData={absencesData}
          refreshData={refreshData}
          openAffectedLessonsModal={affectedLessonsModalData.openModal}
        />
      )}
      {justApprovedAbsence?.makeupOption ===
        absenceMakeUpOptions.SUBSTITUTE && (
        <AffectedLessonsModal
          modalData={affectedLessonsModalData}
          absencesData={absencesData}
          withJustApprovedAbsence
        />
      )}
      <ConfirmDeleteModal
        open={confirmModalOpen}
        onConfirm={() => handleAbsenceDelete(selectedAbsenceId)}
        onCancel={handleDeleteModalClose}
        text="Are you sure you want to delete this absence?"
      />
    </Container>
  );
};

export default PendingAbsencesTable;
