import "./style.css";
import guitarImage from "./images/guitar.webp";
import guitarImageBig from "./images/guitarBig.webp";
import pianoImage from "./images/piano.webp";
import pianoImageBig from "./images/pianoBig.webp";

import React from "react";

function SubGallery() {
  return (
    <figure className="sub-gallery">
      <picture>
        <source media="(min-width:1066px)" srcSet={guitarImageBig} />
        <img loading="lazy" src={guitarImage} alt="guitar" />
      </picture>
      <picture>
        <source media="(min-width:1066px)" srcSet={pianoImageBig} />
        <img loading="lazy" src={pianoImage} alt="party" />
      </picture>
    </figure>
  );
}

export default SubGallery;
