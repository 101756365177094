import React from "react";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "../styled";
import LessonHistoryBody from "./LessonHistoryBody";

const LessonHistoryModal = ({
  lessonHistoryModalData,
  regularPrivateLesson,
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } =
    lessonHistoryModalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Lesson History</h2>
      </CustomHeader>
      <CustomBody>
        <LessonHistoryBody
          lessonHistoryModalData={lessonHistoryModalData}
          regularPrivateLesson={regularPrivateLesson}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default LessonHistoryModal;
