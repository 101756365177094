import "./style.css";
import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import Footer from "../common/Footer";
import NavMenu from "../common/NavMenu";
import NewsElementPage from "./NewsElementPage";
import { Spinner } from "reactstrap";
import { FirebaseContext } from "../../Firebase";
import { getDoc } from "firebase/firestore";
function NewsModal() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const firebase = useContext(FirebaseContext);
  useEffect(() => {
    (async () => {
      const newsRef = await firebase.feedItem(id);
      const dataPromise = await getDoc(newsRef);
      const newsData = dataPromise
        ? { ...dataPromise.data(), id: dataPromise.id }
        : undefined;
      setData(newsData);
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      <main className="news-page">
        <NavMenu />
        {isLoading ? (
          <div className="news-loader-container">
            <Spinner />
          </div>
        ) : data ? (
          <NewsElementPage {...data} />
        ) : (
          <Navigate to="404" replace={true} />
        )}
        <Footer />
      </main>
    </>
  );
}

export default NewsModal;
