import { Formik, Form } from "formik";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useInitialPageDataContext } from "../../../../../../../../../contexts/InitialPageDataContext";
import { SubmitSpinner } from "../../../../../../../../common";
import { FirebaseContext } from "../../../../../../../../Firebase";
import {
  NoteSection,
  PrivateLessonsSectionContainer,
  Textarea,
} from "../styled";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../../../../../../../../utils/shared/styled";
import { addDoc, deleteDoc, setDoc, updateDoc } from "firebase/firestore";
import {
  isAdmin,
  isSuperAdmin,
  UserRole,
} from "../../../../../../../../../constants/UserRoleEnum";
import {
  isCompleteInstrumentsInfo,
  isCompleteTeacherDays,
  isCompleteMakeupOpenings,
  validateMakeupOpenings,
} from "../../../../../helperFns";
import styled from "styled-components";
import InstrumentsInfo from "./InstrumentsInfo";
import TeacherDays from "./TeacherDays";
import MakeupOpenings from "./MakeupOpenings";
import moment from "moment";
import {
  getMakeupOpeningDates,
  getStartAndEndDatesFromTimeInputs,
  haveSuperAdminPermission,
  injectUserStore,
  updatedMomentNow,
} from "src/utils/helpers";
import {
  getDeletedMakeUpOpenings,
  getEditedMakeUpOpenings,
  getNewMakeUpOpenings,
} from "./helperFns";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import GroupClassDays from "./GroupClassDays";
import TeacherAbsenceRequestModal from "src/components/Dashboard/common/components/TeacherAbsenceRequestModal";
import useModal from "src/hooks/useModal";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

const LessonsInfoBody = ({ modalData, teacher, refreshData, UserStore }) => {
  const firebase = useContext(FirebaseContext);
  const user = UserStore.user;

  const isSuperAdminUser = isSuperAdmin(user.role);
  const isAdminUser = isAdmin(user.role);
  const canEditInstrumentsSection = isSuperAdminUser;
  const canEditTeacherDaysSection = isSuperAdminUser;
  const canEditMakeupOpeningsSection = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.createTeachers
  );
  const canEditNoteSection = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.createTeachers
  );
  const canSubmitChanges =
    canEditInstrumentsSection ||
    canEditTeacherDaysSection ||
    canEditMakeupOpeningsSection ||
    canEditNoteSection;

  const teacherAbsenceModalData = useModal();

  const { getTeacherActivities, getSchoolYears } = useFirebaseFns();
  const {
    instruments,
    locations,
    programs,
    loading: loadingInitialData,
  } = useInitialPageDataContext();

  const [instrumentsInfo, setInstrumentsInfo] = useState(
    teacher?.instrumentsInfo?.map((instrumentInfo) => ({
      instrument: instrumentInfo?.instrument?.id,
      program: instrumentInfo?.program?.id,
    })) || []
  );
  const [note, setNote] = useState(teacher?.note || "");
  const [color, setColor] = useState(teacher?.color || "");

  const [editableTeacherDays, setEditableTeacherDays] = useState([]);
  const [originalTeacherDays, setOriginalTeacherDays] = useState([]);

  const [editableGroupClassDays, setEditableGroupClassDays] = useState([]);
  // const [originalGroupClassDays, setOriginalGroupClassDays] = useState([]);

  useEffect(() => {
    setOriginalTeacherDays(teacher?.teacherDays?.availableDays || []);
    // setOriginalGroupClassDays(teacher?.groupClassDays?.availableDays || []);

    const parsedTeacherDays = teacher?.teacherDays?.availableDays?.map(
      (day) => {
        const { startDate, endDate, ...rest } = day;
        const weekday = moment(startDate).day();
        const start = moment(startDate).format("HH:mm");
        const end = moment(endDate).format("HH:mm");

        return {
          day: weekday,
          start,
          end,
          ...rest,
        };
      }
    );
    const parsedGroupClassDays = teacher?.groupClassDays?.availableDays?.map(
      (day) => {
        const { startDate, endDate, ...rest } = day;
        const weekday = moment(startDate).day();
        const start = moment(startDate).format("HH:mm");
        const end = moment(endDate).format("HH:mm");

        return {
          day: weekday,
          start,
          end,
          ...rest,
        };
      }
    );

    setEditableTeacherDays(parsedTeacherDays || []);
    setEditableGroupClassDays(parsedGroupClassDays || []);
  }, [teacher]);

  const parsedMakeupOpenings = teacher.makeupOpenings?.map((opening) => {
    const { id, startDate, endDate, location, isVirtualOnly } = opening;
    const date = moment(startDate).format("YYYY-MM-DD");
    const start = moment(startDate).format("HH:mm");
    const end = moment(endDate).format("HH:mm");

    return {
      id,
      date,
      start,
      end,
      location,
      isVirtualOnly,
    };
  });

  const [makeupOpenings, setMakeupOpenings] = useState(
    parsedMakeupOpenings || []
  );
  const [loadingInitialModalData, setLoadingInitialModalData] = useState(true);
  const [initialModalData, setInitialModalData] = useState({
    userActivities: [],
    schoolYears: [],
  });
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  useEffect(() => {
    // getting user activities and school events
    const fetchInitialModalData = async () => {
      try {
        const userActivitiesReq = getTeacherActivities(teacher?.id);
        const schoolYearsReq = getSchoolYears();
        const [activities, schoolYears] = await Promise.all([
          userActivitiesReq,
          schoolYearsReq,
        ]);

        setInitialModalData({
          userActivities: activities,
          schoolYears,
        });
      } catch (err) {
        console.log(err);
        setInitialModalData({ userActivities: [], schoolYears: [] });
      } finally {
        setLoadingInitialModalData(false);
      }
    };
    fetchInitialModalData();
  }, []);

  if (loadingInitialModalData)
    return (
      <SpinnerWrapper>
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </SpinnerWrapper>
    );
  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            const isCompleteAvailableDays = isCompleteTeacherDays(
              editableTeacherDays,
              ["isNew", "isVirtualOnly", "isPaused"]
            );
            const isCompleteGCDays = isCompleteTeacherDays(
              editableGroupClassDays,
              ["isNew", "isVirtualOnly", "isPaused"]
            );
            const isCompleteInstruments =
              isCompleteInstrumentsInfo(instrumentsInfo);
            const isCompleteMakeups = isCompleteMakeupOpenings(makeupOpenings, [
              "isVirtualOnly",
              "isNew",
              "isEdited",
            ]);

            if (
              !isCompleteAvailableDays ||
              !isCompleteGCDays ||
              !isCompleteInstruments ||
              !isCompleteMakeups
            ) {
              toast.warn("Please fill all required data");
              return;
            }

            // adds startDate and endDate to each makeup obj in the makeupOpenings array
            const makeupOpeningsWithDates = makeupOpenings?.map(
              (makeupOpening) => {
                const { date, start, end } = makeupOpening;

                const { startDate, endDate } = getMakeupOpeningDates(
                  date,
                  start,
                  end
                );

                return {
                  ...makeupOpening,
                  startDate,
                  endDate,
                };
              }
            );
            // Checks if the new teacher days conflicts with the user's activities
            // const {
            //   isValid: isValidTeacherDays,
            //   message: teacherDaysErrMessage,
            // } = validateTeacherDays(
            //   teacher?.id,
            //   editableTeacherDays,
            //   initialModalData.userActivities
            // );
            // if (!isValidTeacherDays) {
            //   toast.warn(teacherDaysErrMessage);
            //   return;
            // }

            const {
              isValid: isValidMakeupOpenings,
              message: makeupOpeningsErrMessage,
            } = validateMakeupOpenings(
              makeupOpeningsWithDates,
              editableTeacherDays,
              initialModalData.schoolYears
            );
            if (!isValidMakeupOpenings) {
              toast.warn(makeupOpeningsErrMessage);
              return;
            }

            const parsedTeacherDays = editableTeacherDays.map((day) => {
              const { startDate, endDate } = getStartAndEndDatesFromTimeInputs(
                updatedMomentNow().day(day.day).toDate(),
                day.start,
                day.end
              );
              return {
                id: day.id,
                startDate,
                endDate,
                location: day.location,
                isVirtualOnly: day.isVirtualOnly || false,
                disabledForRoles: day.disabledForRoles || [],
              };
            });
            const parsedGroupClassDays = editableGroupClassDays.map((day) => {
              const { startDate, endDate } = getStartAndEndDatesFromTimeInputs(
                updatedMomentNow().day(day.day).toDate(),
                day.start,
                day.end
              );
              return {
                id: day.id,
                startDate,
                endDate,
                location: day.location,
                isVirtualOnly: day.isVirtualOnly || false,
                disabledForRoles: day.disabledForRoles || [],
              };
            });

            const teacherDaysReq = setDoc(
              firebase.userAvailableDays(teacher?.id),
              {
                userRole: UserRole.TEACHER,
                availableDays: parsedTeacherDays,
              }
            );
            const groupClassDaysReq = setDoc(
              firebase.groupClassAvailableDay(teacher?.id),
              {
                userRole: UserRole.TEACHER,
                availableDays: parsedGroupClassDays,
              }
            );

            // MAKEUPS //
            let makeupOpeningsReqs = [];
            // Check for new makeup lessons, then add them to the firebaseReqs
            const { areNewMakeUpOpeningsDetected, newMakeUpOpenings } =
              getNewMakeUpOpenings(makeupOpeningsWithDates);
            if (areNewMakeUpOpeningsDetected) {
              for (let newMakeupOpening of newMakeUpOpenings) {
                const makeupObj = {
                  startDate: newMakeupOpening.startDate,
                  endDate: newMakeupOpening.endDate,
                  location: newMakeupOpening.location,
                  isVirtualOnly: newMakeupOpening.isVirtualOnly,
                  userId: teacher.id,
                  userRole: UserRole.TEACHER,
                };
                console.log("NEW OPENING =>> ", makeupObj);
                makeupOpeningsReqs.push(
                  addDoc(firebase.makeupOpenings(), makeupObj)
                );
              }
            }

            // Check for new makeup lessons, then add them to the firebaseReqs
            const { areEditedMakeUpOpeningsDetected, editedMakeUpOpenings } =
              getEditedMakeUpOpenings(makeupOpeningsWithDates);
            if (areEditedMakeUpOpeningsDetected) {
              for (let editedMakeupOpening of editedMakeUpOpenings) {
                const makeupObj = {
                  startDate: editedMakeupOpening.startDate,
                  endDate: editedMakeupOpening.endDate,
                  location: editedMakeupOpening.location,
                  isVirtualOnly: editedMakeupOpening.isVirtualOnly,
                };
                console.log("EDITED OPENING =>> ", editedMakeupOpening);
                makeupOpeningsReqs.push(
                  updateDoc(
                    firebase.makeupOpening(editedMakeupOpening.id),
                    makeupObj
                  )
                );
              }
            }
            const { areDeletedMakeUpOpeningsDetected, deletedMakeUpOpenings } =
              getDeletedMakeUpOpenings(
                parsedMakeupOpenings,
                makeupOpeningsWithDates
              );
            if (areDeletedMakeUpOpeningsDetected) {
              for (let deletedMakeupOpening of deletedMakeUpOpenings) {
                console.log("DELETED OPENING =>> ", deletedMakeupOpening);
                makeupOpeningsReqs.push(
                  deleteDoc(firebase.makeupOpening(deletedMakeupOpening.id))
                );
              }
            }

            const teachersInfoReq = updateDoc(firebase.user(teacher?.id), {
              note,
              color,
              instrumentsInfo,
            });

            const [daysRes, infoRes] = await Promise.all([
              teacherDaysReq,
              teachersInfoReq,
              groupClassDaysReq,
              [...makeupOpeningsReqs],
            ]);
            toast.success("Teacher Updated Successfully");
            refreshData();
            modalData.closeModal();
          } catch (error) {
            toast.error(error.message);
            console.log("Updating Teacher error", error);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <Form className="px-3 mt-3">
            {/* Selected Instruments Section */}
            <InstrumentsInfo
              instrumentsInfo={instrumentsInfo}
              setInstrumentsInfo={setInstrumentsInfo}
              instruments={instruments}
              programs={programs}
              hasPermissionToEdit={canEditInstrumentsSection}
            />

            {/* Teacher Days Section */}
            <PrivateLessonsSectionContainer>
              <h2 className="fs-4">Private Lessons</h2>
              <hr />
              <TeacherDays
                teacher={teacher}
                editableTeacherDays={editableTeacherDays}
                setEditableTeacherDays={setEditableTeacherDays}
                color={color}
                setColor={setColor}
                locations={locations}
                hasPermissionToEdit={canEditTeacherDaysSection}
              />
              <MakeupOpenings
                makeupOpenings={makeupOpenings}
                setMakeupOpenings={setMakeupOpenings}
                locations={locations}
                hasPermissionToEdit={canEditMakeupOpeningsSection}
              />
            </PrivateLessonsSectionContainer>
            <PrivateLessonsSectionContainer>
              <h2 className="fs-4">Group Classes</h2>
              <hr />
              <GroupClassDays
                editableGroupClassDays={editableGroupClassDays}
                setEditableGroupClassDays={setEditableGroupClassDays}
                locations={locations}
                hasPermissionToEdit={canEditTeacherDaysSection}
              />
            </PrivateLessonsSectionContainer>
            <div className="text-center mt-4 mb-4">
              <PrimaryButton
                type="button"
                width="80%"
                onClick={teacherAbsenceModalData.openModal}
              >
                Request Teacher Absence
              </PrimaryButton>
            </div>
            {/* Note Section */}
            <NoteSection>
              <h2 className="mb-3">Note</h2>
              <Textarea
                disabled={!canEditNoteSection}
                name="note"
                value={note}
                onChange={handleNoteChange}
                height={"100px"}
              />
            </NoteSection>
            {canSubmitChanges && (
              <div className="text-end">
                <PrimaryButtonWithLoading
                  isLoading={isSubmitting}
                  type="submit"
                  height="45px"
                  padding="0"
                >
                  Update Teacher
                </PrimaryButtonWithLoading>
              </div>
            )}
          </Form>
        )}
      </Formik>
      {teacherAbsenceModalData.isModalOpen && (
        <TeacherAbsenceRequestModal
          limitDatePicker={false}
          modalData={teacherAbsenceModalData}
          teacher={teacher}
          onAbsenceRequestSuccess={() => window.location.reload()}
          submitButtonText="Confirm Absence"
        />
      )}
    </>
  );
};

export default injectUserStore(LessonsInfoBody);
