import React from "react";
import { NavButton } from "src/utils/shared/styled";
import { makeupViews } from "../constants";

const NavigationBar = ({ currentView, handleCurrentViewChange }) => {
  return (
    <div className="d-flex align-items-center">
      <div>
        <NavButton
          className={currentView === makeupViews.DATE ? "active" : undefined}
          onClick={() => handleCurrentViewChange(makeupViews.DATE)}
        >
          View By Date
        </NavButton>
      </div>
      <div>
        <NavButton
          className={currentView === makeupViews.TEACHER ? "active" : undefined}
          onClick={() => handleCurrentViewChange(makeupViews.TEACHER)}
        >
          View By Teacher
        </NavButton>
      </div>
    </div>
  );
};

export default NavigationBar;
