import styled from "styled-components";
import moment from "moment";
import useWeather from "../../hooks/useWeather";
import { SubmitSpinner } from "../../../../../common";
import { WeatherIconMap } from "src/constants/weatherData";
import { Clouds } from "@styled-icons/bootstrap/Clouds";
import { updatedMomentNow } from "src/utils/helpers";

const OuterContainer = styled.div`
  background-color: #681e46;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
`;
const InnerContainer = styled.div`
  background-color: #832357;
  border-radius: 15px;
`;
const DateSection = styled.div`
  background-color: white;
  border-radius: 15px;
  height: 100px;
  width: 110px;
  position: relative;
`;

const ArrRight = styled.div`
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 33px solid white;
  position: absolute;
  right: -26px;
  border-radius: 9px;
`;
const WeatherSection = styled.div``;
const TimeSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
`;
const DateAndTime = ({ weather }) => {
  const { weatherData, loadingWeather } = weather;
  const { weatherDescription, temp } = weatherData || {};
  const month = updatedMomentNow().format("MMMM");
  const day = updatedMomentNow().format("DD");
  const hour = updatedMomentNow().format("hh:mm A");

  return (
    <OuterContainer>
      <InnerContainer className="d-flex justify-content-between">
        <DateSection className="d-flex align-items-center justify-content-center">
          <div>
            <span className="d-block text-center fw-normal">{month}</span>
            <span className="d-block text-center fs-5">{day}</span>
          </div>
          <ArrRight />
        </DateSection>

        <WeatherSection className="d-flex  align-items-center justify-content-center text-white">
          {loadingWeather ? (
            <SubmitSpinner
              style={{ width: "3rem", height: "3rem", color: "#fff" }}
            />
          ) : Object.keys(weatherData)?.length ? (
            <>
              <div className="d-flex justify-content-center align-items-center p-3">
                {WeatherIconMap[weatherDescription] || (
                  <Clouds width={40} height={40} />
                )}
              </div>
              <div className="p-3">
                <span className="d-block text-center fw-normal">
                  {weatherDescription}
                </span>
                <span className="d-block text-center fs-5">{`${
                  Math.round(temp) || "N/A"
                }°F`}</span>
              </div>
            </>
          ) : (
            <span className="w-75 text-center">
              Allow location access to see the weather
            </span>
          )}
        </WeatherSection>
        <TimeSection className="text-white">
          <h4 className="fs-1">{hour}</h4>
        </TimeSection>
      </InnerContainer>
    </OuterContainer>
  );
};

export default DateAndTime;
