import React, { useEffect  , useState} from "react";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import { inject, observer } from "mobx-react";
import { Spinner } from "../../common";
import { useNavigate , useLocation} from "react-router";
const withAuthorization = Component => {
  const WithAuthorization = props => {
    const { firebase, UserStore} = props;
    const Navigate = useNavigate();
    const location = useLocation() 
    useEffect(
      () =>
        firebase.onAuthUserListener(
          (user) => {},
          () => {
            Navigate({
              pathname: "/signIn",
              state: { from: location },
            });
          }
        ),
      []
    );
    if (UserStore.isLoggedIn) {
      return <Component user={UserStore.user} {...props} />;
    }
    return UserStore.isLoaded ? null : <Spinner />;
  };

  return compose(
    withFirebase,
    inject("UserStore"),
    observer
  )(WithAuthorization);
};

export default withAuthorization;
