export const paymentPeriodFilters = {
  currentMonth: { title: "This Month", value: "current_month" },
  lastMonth: { title: "Last Month", value: "last_month" },
  currentYear: { title: "This Year", value: "current_year" },
  lastYear: { title: "Last Year", value: "last_year" },
  custom: { title: "Custom", value: "custom" },
};

export const filtersWithMonthFilter = [
  paymentPeriodFilters.currentYear.value,
  paymentPeriodFilters.lastYear.value,
];
