import { Select } from "antd";

const ClassDaySelect = ({ days, onChange, value, mode }) => {
  const daysOptions = days.map((day) => {
    const startDate = new Date(day?.startDate);

    return {
      label: startDate?.toLocaleDateString("en-US", {
        weekday: "long",
      }),
      value: startDate?.toLocaleDateString("en-US", {
        weekday: "long",
      }),
    };
  });

  return (
    <Select
      style={{ width: "100%" }}
      mode={mode}
      placeholder="Select days"
      allowClear
      onChange={onChange}
      value={value}
      options={daysOptions}
    />
  );
};

export default ClassDaySelect;
