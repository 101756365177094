import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import store from "src/stores/UserStore";
import { sendEmailConfirmation } from "../../../utils";
import { updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

import { PrimaryButton } from "src/utils/shared/styled";
import { ButtonsWrapper } from "../../GuestProfileSecondPage/styled";
import ProfileSummary from "../components/ProfileSummary";
import PaymentPanel from "./PaymentPanel";
import WithdrawModal from "../components/WithdrawModal";
import { FirebaseContext } from "src/components/Firebase";
import ActionConfirmModal from "../../../components/ActionConfirmModal";
import CustomTooltip from "src/components/Dashboard/common/components/CustomTooltip";

const StudentThirdPage = () => {
  const { user, setStatus } = store;
  const navigate = useNavigate();
  const { getTeachersByIds } = useFirebaseFns();
  const firebase = useContext(FirebaseContext);

  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [teacher, setTeacher] = useState(null);

  useEffect(() => {
    (async () => {
      if (user?.selectedTeacherId) {
        const response = await getTeachersByIds([user?.selectedTeacherId]);

        setTeacher(response[0]);
      }
    })();
  }, [user?.selectedTeacherId]);

  const handleSubmit = async () => {
    if (!user?.paid) return toast.error("You haven't made payment");

    const userToUpdate = firebase.user(user.uid);

    await updateDoc(userToUpdate, {
      status: "On review",
    });
    setStatus("On review");

    setConfirmModalOpen(true);
    await sendEmailConfirmation(user);
  };

  return (
    <>
      <ProfileSummary user={user} teacher={teacher} />
      <PaymentPanel />
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/guest-profile/2")}
        >
          Previous page
        </PrimaryButton>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => setWithdrawModalOpen(true)}
        >
          Withdraw
        </PrimaryButton>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={handleSubmit}
          disabled={user?.status !== "Need profile information"}
          id="student-submit"
        >
          Submit
        </PrimaryButton>
        {user?.status !== "Need profile information" && (
          <CustomTooltip anchorSelect="#student-submit">
            You've already submitted your application
          </CustomTooltip>
        )}
      </ButtonsWrapper>
      <ActionConfirmModal
        isOpen={confirmModalOpen}
        toggle={() => setConfirmModalOpen((prevState) => !prevState)}
        text="Thank you for completing the application! We will contact you soon"
      />
      <WithdrawModal
        isOpen={withdrawModalOpen}
        toggle={() => setWithdrawModalOpen((prevState) => !prevState)}
      />
    </>
  );
};

export default StudentThirdPage;
