import React from "react";
import { injectUserStore } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { modalViews } from "../constants";
import usePackageData from "../hooks/usePackageData";
import NavigationBar from "./NavigationBar";
import RequestedDatePicker from "./RequestedDatePicker";
import TeacherAvailabilitiesGrid from "./TeachersAvailabilitiesGrid";
import TeachersSelectField from "./TeachersSelectField";

const BodyContent = ({
  studentId,
  lessonLocationId,
  lessonInstrumentId,
  lessonDuration,
  UserStore,
  shortenTimeSlots,
  modalData,
  onLessonCreateSuccess,
  isNewLesson,
  packageLessonId,
  itemSet,
}) => {
  const user = UserStore.user;

  const packageData = usePackageData({
    lessonLocationId,
    lessonInstrumentId,
    lessonDuration,
    user,
    shortenTimeSlots,
    studentId,
    isNewLesson,
    packageLessonId,
    itemSet,
  });
  const {
    initialData,
    currentView,
    handleCurrentViewChange,
    requestedDateData,
    dateViewData,
    teachersViewData,
    selectedLessonSlot,
    isVirtualLesson,
    handleIsVirtualLessonChange,
    createLesson,
    loadingCreatingLesson,
  } = packageData;

  const handleCreateLesson = async () => {
    const { success } = await createLesson();
    if (success) {
      modalData.closeModal();
      onLessonCreateSuccess();
    }
  };

  return (
    <div>
      <div className="mb-4">
        <NavigationBar
          currentView={currentView}
          handleCurrentViewChange={handleCurrentViewChange}
        />
      </div>
      <div className="mb-4 d-flex justify-content-center flex-wrap align-items-center">
        {currentView === modalViews.DATE ? (
          <RequestedDatePicker requestedDateData={requestedDateData} />
        ) : (
          <TeachersSelectField
            teachers={initialData.teachers}
            onChange={teachersViewData.handleSelectedTeacherChange}
            value={teachersViewData.selectedTeacherId}
          />
        )}

        {/* {hasAdminCredentials(user?.role) && (
          <div>
            <PrimaryButtonWithLoading
              isLoading={isLoadingPdf}
              onClick={handleDownloadAvailabilitiesClick}
              style={{ background: "#a501a5" }}
            >
              Download Availabilities
            </PrimaryButtonWithLoading>
          </div>
        )} */}
      </div>
      <TeacherAvailabilitiesGrid
        packageData={packageData}
        requestedDateData={requestedDateData}
        user={user}
      />
      {selectedLessonSlot && !selectedLessonSlot?.isVirtualOnly && (
        <div className="text-center mb-3 mt-5">
          <label className="me-3">
            <input
              type="radio"
              className="me-2"
              checked={!isVirtualLesson}
              // value={false}
              onChange={() => handleIsVirtualLessonChange(false)}
            />
            In Person
          </label>
          <label>
            <input
              type="radio"
              className="me-2"
              checked={isVirtualLesson}
              onChange={() => handleIsVirtualLessonChange(true)}
            />
            Virtual
          </label>
        </div>
      )}
      {/* <p className="mt-3">Make up lesson is final, It can't be rescheduled</p> */}

      <div className="mt-4 d-flex justify-content-center">
        {selectedLessonSlot && (
          <div>
            <PrimaryButtonWithLoading
              isLoading={loadingCreatingLesson}
              onClick={handleCreateLesson}
            >
              Confirm
            </PrimaryButtonWithLoading>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectUserStore(BodyContent);
