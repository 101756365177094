import { paymentTypes } from "src/constants/paymentsEnum";

const isPrivateLessonPayment = (paymentName) =>
  paymentName?.toLowerCase()?.includes("private");

const isGroupClassPayment = (paymentName) =>
  paymentName?.toLowerCase()?.includes("group");

const isSDCPayment = (paymentName) =>
  paymentName?.toLowerCase()?.includes("sdc");

const isBonus = (paymentName) => paymentName?.toLowerCase()?.includes("bonus");

const isReservation = (paymentName) =>
  paymentName?.toLowerCase()?.includes("reservation");

const isTraining = (paymentName) =>
  paymentName?.toLowerCase()?.includes("training");

const isAdminHours = (paymentName) =>
  paymentName?.toLowerCase()?.includes("admin");

const isReimbursement = (paymentName) =>
  paymentName?.toLowerCase()?.includes("reimbursement");

const getTeacherChartColor = (paymentName) => {
  const normalizedName = paymentName?.toLowerCase();

  if (
    isPrivateLessonPayment(normalizedName) &&
    normalizedName?.includes("30")
  ) {
    return "#26C6DA";
  } else if (
    isPrivateLessonPayment(normalizedName) &&
    normalizedName?.includes("45")
  ) {
    return "#26C699";
  } else if (
    isPrivateLessonPayment(normalizedName) &&
    normalizedName?.includes("60")
  ) {
    return "#7ABFFF";
  } else if (isGroupClassPayment(normalizedName)) {
    return "#26DA80";
  } else if (isSDCPayment(normalizedName)) {
    return "#FFCA28";
  } else if (isBonus(normalizedName)) {
    return "#BA71FF";
  } else if (isReservation(normalizedName)) {
    return "#D4E157";
  } else if (isTraining(normalizedName)) {
    return "#5C6BC0";
  } else if (isAdminHours(normalizedName)) {
    return "#B67570";
  } else if (isReimbursement(normalizedName)) {
    return "#F8F8FF";
  }

  return "#FF8742";
};

const getAdminChartColor = (paymentType) => {
  switch (paymentType) {
    case paymentTypes.SALARY:
      return "#7ABFFF";
    case paymentTypes.EXTENDED_HOURS:
      return "#26DA80";
    case paymentTypes.SIGN_UPS:
      return "#FFF075";
    case paymentTypes.BONUS:
      return "#BA71FF";
    case paymentTypes.REIMBURSEMENT:
      return "#F8F8FF";
    default:
      return "#FF8742";
  }
};

export {
  isGroupClassPayment,
  isPrivateLessonPayment,
  isSDCPayment,
  getTeacherChartColor,
  getAdminChartColor,
};
