import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";

export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
export const HeaderContainer = styled.div`
  padding: 0 70px;
  padding-right: 35px;
`;
export const NavButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
`;

export const LessonsInfoContainer = styled.div`
  background: #f6f6f69e;
  display: flex;
  width: 100%;
`;
export const RedBarContainer = styled.div`
  background: #681e46;
  width: 50px;
  color: #fff;
  writing-mode: vertical-lr;
  text-orientation: revert;
  display: flex;
  align-items: center;
  padding: 40px 26px;
`;
export const RedBarText = styled.p`
  font-size: 1.3rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
`;
export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 35px 50px;
`;
export const InputWrapper = styled.div``;
