import SecondaryList from "./SecondaryList";
import logo from "../../../../assets/icons/logo.svg";
import "./style.css";
import GuestMap from "../GuestMap";
import store from "src/stores/UserStore";
import { isGuest } from "src/constants/UserRoleEnum";

function NavMenu() {
  const { isLoggedIn, user } = store;

  return (
    <nav className="home-nav">
      <div className="nav-left-column">
        <a href="/" className="home-nav-logo">
          <img src={logo} alt="logo" />
        </a>
        {(isGuest(user?.role) || !isLoggedIn) && <GuestMap />}
      </div>
      <SecondaryList />
    </nav>
  );
}

export default NavMenu;
