import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem } from "reactstrap";
import styled from "styled-components";
import {
  CustomDropDownMenu,
  CustomDropdownToggle,
  CustomLabel,
} from "../../utils/styled";
import "./style.css";
const CustomDropDown = ({
  options,
  keyName,
  renderError,
  onOptionClick,
  className,
  headerClassName = "",
  size,
  header,
  label,
  toggleClassName,
  value,
  CheckDisable,
  menuClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(value ?? "");
  useEffect(() => {
    setActiveOption(value);
  }, [value]);
  return (
    <div
      // style={{ flexDirection: "column" }}
      // className={className ?? (!size ? "col-12" : `col-${size}`)}
      className="w-100"
    >
      {header && <CustomLabel className="mb-3">{header}</CustomLabel>}
      <Dropdown
        className="w-100 dropdownbtn"
        disabled={CheckDisable}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        <CustomDropdownToggle
          style={{ whiteSpace: "break-spaces" }}
          className={`w-100 dropdownButton ${headerClassName}`}
          id={`custom-dropdown-${isOpen ? "open" : ""}`}
          isOpen={isOpen}
          caret
        >
          {!activeOption ? label : activeOption}
        </CustomDropdownToggle>
        {/**className={"w-100"} */}
        <CustomDropDownMenu style={{}} className={"w-100"}>
          {options.map((option, index) => (
            <DropdownItem
              key={index}
              onClick={(e) => {
                e.preventDefault();
                onOptionClick(option);
                setActiveOption(keyName ? option[keyName] : option);
              }}
              active={option == activeOption}
            >
              {keyName ? option[keyName] : option}
            </DropdownItem>
          ))}
        </CustomDropDownMenu>
      </Dropdown>
      {renderError}
    </div>
  );
};
export default CustomDropDown;
