import moment from "moment";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { absenceMakeUpStatuses } from "src/constants/absenceMakeUpStatuses";
import { absenceTypes, isSDCAbsence } from "src/constants/absenceTypes";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { UserRole } from "src/constants/UserRoleEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getAbsenceMakeUpStatusData,
  getCurrentSchoolYear,
  getMakeupPeriodInRequestedDate,
  getPrivateLessonInfoOnSpecificDate,
  getRecurringEventTimelineObj,
  getTimeDiffInMins,
  isActiveTeacher,
  updatedMomentNow,
} from "src/utils/helpers";
import { displayOptions } from "../constants";

let timer; // Timer identifier
const waitTime = 1000;

const filteredStudentsDataInitialValues = {
  absences: [],
  makeupLessons: [],
};
const filterOptionsInitialValues = {
  selectedLocationId: "",
  selectedTeacherId: "",
  selectedStudentId: "",
};
const initialDataInitialValues = {
  locations: [],
  users: [],
  teachersAvailableDays: [],
  schoolYears: [],
  privateLessons: [], // all pls
};

const useMakeupNeededData = () => {
  const {
    getLocations,
    getUsers,
    getPrivateLessonsAbsences,
    getMakeUpLessonsByAbsencesIds,
    getTeachersAvailableDays,
    getSchoolYears,
    getPrivateLessons,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  // the selected display option
  const [currentDisplayOption, setCurrentDisplayOption] = useState(
    displayOptions.SEARCH
  );
  // Search Bar value
  const [searchTerm, setSearchTerm] = useState("");
  // the top filters values
  const [filterOptions, setFilterOptions] = useState(
    filterOptionsInitialValues
  );
  // data for the filtered students (appears on the table)
  const [filteredStudentsData, setFilteredStudentsData] = useState(
    filteredStudentsDataInitialValues
  );
  const [loadingFilteredStudentsData, setLoadingFilteredStudentsData] =
    useState(false);

  const [currentStudentId, setCurrentStudentId] = useState(null);

  const applyFilters = () => {
    const { selectedLocationId, selectedTeacherId, selectedStudentId } =
      filterOptions || {};
    if (!selectedLocationId || !selectedTeacherId) {
      toast.warn("Select location and teacher at least to apply the filters");
      return;
    }
    setCurrentDisplayOption(displayOptions.FILTER);
  };
  const clearFilters = () => {
    setCurrentDisplayOption(displayOptions.SEARCH);
  };

  // changes the search term and makes sure search is enabled
  const handleSearchTermChange = (newSearchTerm) => {
    if (currentDisplayOption !== displayOptions.SEARCH) {
      clearFilters();
    }
    setSearchTerm(newSearchTerm);
  };
  const handleFiltersChange = (name, value) => {
    if (name === "selectedLocationId") {
      setFilterOptions((oldVal) => ({
        ...oldVal,
        [name]: value,
        selectedTeacherId: "",
        selectedStudentId: "",
      }));
    } else if (name === "selectedTeacherId") {
      setFilterOptions((oldVal) => ({
        ...oldVal,
        [name]: value,
        selectedStudentId: "",
      }));
    } else {
      setFilterOptions((oldVal) => ({
        ...oldVal,
        [name]: value,
      }));
    }
  };

  // filter options data
  const selectableTeachers = useMemo(() => {
    const selectedLocationId = filterOptions.selectedLocationId;

    if (!selectedLocationId) return [];

    const teachers = initialData.users.filter(
      ({ role }) => role === UserRole.TEACHER
    );
    const activeTeachers = teachers.filter((teacher) =>
      isActiveTeacher(teacher)
    );
    const teachersInSelectedLocation = activeTeachers.filter(({ id }) => {
      const teacherAvailableDays = initialData.teachersAvailableDays.find(
        (teacherDay) => teacherDay.id === id
      );

      const teacherDaysUniqueLocations = [
        ...new Set(
          teacherAvailableDays?.availableDays?.map(
            ({ location }) => location
          ) || []
        ),
      ];

      const isLocationFound =
        teacherDaysUniqueLocations.includes(selectedLocationId);

      return isLocationFound;
    });

    return teachersInSelectedLocation;
  }, [initialData, filterOptions.selectedLocationId]);

  const selectableStudents = useMemo(() => {
    const selectedTeacherId = filterOptions.selectedTeacherId;

    if (!selectedTeacherId) return [];

    const students = initialData.users.filter(
      ({ role }) => role === UserRole.STUDENT
    );

    const studentsWithSelectedTeacher = students.filter(({ id }) => {
      const studentPLs = initialData.privateLessons.filter(
        ({ studentId }) => studentId === id
      );

      // checks if the student has a pl with the selected teacher
      const isStudentWithTeacher = studentPLs.some(({ teachersIds }) =>
        teachersIds?.includes(selectedTeacherId)
      );

      return isStudentWithTeacher;
    });

    return studentsWithSelectedTeacher;
  }, [initialData, filterOptions.selectedTeacherId]);

  // displayed students on the table
  const filteredStudents = useMemo(() => {
    let filtered = [];

    if (currentDisplayOption === displayOptions.SEARCH) {
      if (searchTerm) {
        filtered = initialData.users.filter(({ fullName, role }) => {
          const isStudent = role === UserRole.STUDENT;
          const isMatchingName =
            fullName &&
            fullName.toLowerCase().includes(searchTerm.toLowerCase());
          return isStudent && isMatchingName;
        });
      } else {
        filtered = [];
      }
    } else if (currentDisplayOption === displayOptions.FILTER) {
      const { selectedLocationId, selectedTeacherId, selectedStudentId } =
        filterOptions;
      if (selectedStudentId) {
        filtered = initialData.users.filter(
          ({ id, role }) => id === selectedStudentId
        );
      } else if (selectedTeacherId) {
        // if we select the teacher, then we show all the teacher's students on the table
        filtered = selectableStudents;
      } else {
        filtered = [];
      }
    } else {
      filtered = [];
    }

    return filtered;
  }, [initialData, searchTerm, filterOptions, currentDisplayOption]);
  // fetches initialData
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [
          locations,
          users,
          teachersAvailableDays,
          schoolYears,
          privateLessons,
        ] = await Promise.all([
          getLocations(),
          getUsers(),
          getTeachersAvailableDays(),
          getSchoolYears(),
          getPrivateLessons(),
        ]);

        setInitialData((oldVal) => ({
          ...oldVal,
          locations,
          users,
          teachersAvailableDays,
          schoolYears,
          privateLessons,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  // fetches the filtered students data
  useEffect(() => {
    if (!filteredStudents?.length) {
      clearTimeout(timer);
      setFilteredStudentsData(filteredStudentsDataInitialValues);
      return;
    }

    const fetchFilteredStudentsData = async () => {
      try {
        setLoadingFilteredStudentsData(true);

        const filteredStudentsIds = filteredStudents.map(({ id }) => id);

        const filteredStudentsPLs = initialData.privateLessons.filter(
          ({ studentId }) => filteredStudentsIds.includes(studentId)
        );

        const plsIds = filteredStudentsPLs.map(({ id }) => id);
        const filteredStudentsAbsences = await getPrivateLessonsAbsences(
          plsIds
        );
        const filteredAbsences = filteredStudentsAbsences.filter(
          ({ teacherId }) => {
            if (currentDisplayOption === displayOptions.FILTER) {
              return teacherId === filterOptions.selectedTeacherId;
            } else {
              return true;
            }
          }
        );

        const absencesIds = filteredAbsences.map(({ id }) => id);
        const absencesMakeupLessons = await getMakeUpLessonsByAbsencesIds(
          absencesIds
        );

        setFilteredStudentsData((oldVal) => ({
          ...oldVal,
          absences: filteredAbsences,
          makeupLessons: absencesMakeupLessons,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingFilteredStudentsData(false);
      }
    };

    if (currentDisplayOption === displayOptions.SEARCH) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        fetchFilteredStudentsData();
      }, waitTime);
    } else {
      clearTimeout(timer);
      fetchFilteredStudentsData();
    }
  }, [filteredStudents, currentDisplayOption]);

  const currentStudent = useMemo(() => {
    if (!currentStudentId) return;

    return initialData.users.find(({ id }) => id === currentStudentId);
  }, [initialData.users, currentStudentId]);

  const filteredAbsencesWithAdditionalData = useMemo(() => {
    const filteredStudentsIds = filteredStudents.map(({ id }) => id);

    // gets the filtered student's lessons (excluding the lessons that are with a different teacher from the one in the filters only if filters are enabled)
    const filteredStudentsLessons = initialData.privateLessons.filter(
      ({ studentId, teachersIds }) => {
        const isSameStudent = filteredStudentsIds.includes(studentId);
        if (
          currentDisplayOption === displayOptions.FILTER &&
          filterOptions.selectedTeacherId
        ) {
          const isSameTeacher = teachersIds.includes(
            filterOptions.selectedTeacherId
          );
          return isSameTeacher && isSameStudent;
        } else {
          return isSameStudent;
        }
      }
    );
    const lessonsWithAbsences = filteredStudentsLessons.map((pl) => {
      const isPackageLsn = isPackagePrivateLesson(pl.type);

      const lessonAbsences =
        filteredStudentsData.absences?.filter(
          (absence) =>
            absence.lessonId === pl.id ||
            absence.affectedPrivateLessonsIds?.includes(pl.id)
        ) || [];

      // filters out the completed absences + adds extra data for table display
      const absencesWithExtraData = lessonAbsences.map((absence, i) => {
        const requestedByName = initialData.users.find(
          ({ id }) => id === absence.userId
        )?.fullName;

        // we get the student name from the lesson (not the studentId of the absence)
        const studentName = initialData.users.find(
          (user) => user.id === pl.studentId
        )?.fullName;

        const absenceTypeStr = Object.values(absenceTypes).find(
          (type) => type.code === absence.absenceType
        )?.abbr;
        const absenceDate = absence.date || absence.startDate;
        const formattedAbsenceDate = moment(absenceDate).format("MM/DD/YYYY");

        // the makeup lessons for this absence (filtered by using the same student and lesson)
        const absenceMakeUps = filteredStudentsData.makeupLessons.filter(
          (makeup) =>
            makeup.forAbsenceId === absence.id &&
            makeup.studentId === pl.studentId &&
            makeup.forLessonId === pl.id
        );

        const { duration: lessonDuration, locationId } =
          getPrivateLessonInfoOnSpecificDate({
            privateLesson: pl,
            date: absenceDate,
            withTimelineApproximation: true,
          });
        let makeupStatus;
        if (lessonDuration && locationId) {
          let makeupPeriodDeadline;
          if (isPackageLsn) {
            makeupPeriodDeadline = updatedMomentNow().add(5, "years");
          } else {
            const currentSchoolYear = getCurrentSchoolYear(
              initialData.schoolYears,
              locationId
            );
            const currentMakeupPeriod = getMakeupPeriodInRequestedDate(
              absenceDate,
              currentSchoolYear
            );
            makeupPeriodDeadline = currentMakeupPeriod?.deadline?.toDate
              ? currentMakeupPeriod.deadline.toDate()
              : undefined;
          }
          const { absenceMakeUpStatus } = getAbsenceMakeUpStatusData(
            absence,
            absenceMakeUps,
            lessonDuration,
            makeupPeriodDeadline
          );
          makeupStatus = absenceMakeUpStatus;
        }
        const absenceStatusObj = Object.values(absenceMakeUpStatuses).find(
          ({ code }) => code === makeupStatus
        );
        const makeupStatusStr = absenceStatusObj?.description2;
        return {
          ...absence,
          makeupStatus,
          makeupStatusStr,
          requestedByName,
          studentName,
          formattedAbsenceDate,
          absenceTypeStr,
        };
      });

      // filter out the absences that we can't schedule a make up for anymore
      const filteredAbsences = absencesWithExtraData.filter(
        ({ makeupStatus, absenceType }) => {
          const isSDC = isSDCAbsence(absenceType);

          const { expired, fullyMadeUpFor, fullyScheduled, noMakeUpDeadline } =
            absenceMakeUpStatuses;
          return (
            ![
              fullyMadeUpFor.code,
              fullyScheduled.code,
              expired.code,
              noMakeUpDeadline.code,
            ].includes(makeupStatus) && !isSDC
          );
        }
      );

      return {
        ...pl,
        lessonAbsences: filteredAbsences,
      };
    });

    return lessonsWithAbsences
      .map(({ lessonAbsences }) => lessonAbsences)
      .flat()
      .sort((a, b) => {
        const firstAbsenceDate = a.date || a.startDate;
        const secondAbsenceDate = b.date || b.startDate;

        return getTimeDiffInMins(secondAbsenceDate, firstAbsenceDate);
      });
  }, [initialData, filteredStudentsData]);
  return {
    initialData,
    loadingInitialData,
    currentDisplayOption,
    setCurrentDisplayOption,
    searchTerm,
    handleSearchTermChange,
    filteredStudents,
    filteredStudentsData,
    filterOptions,
    setFilterOptions,
    loadingFilteredStudentsData,
    selectableTeachers,
    selectableStudents,
    applyFilters,
    handleFiltersChange,
    currentStudent,
    setCurrentStudentId,
    clearFilters,
    filteredAbsencesWithAdditionalData,
  };
};

export default useMakeupNeededData;
