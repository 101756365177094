import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";
import styled from "styled-components";
import { modalMap } from "../../utils/constants";
import TeacherCardBody from "./TeacherCardBody";

const CustomModal = styled(Modal)`
  min-width: 800px;
  max-width: 800px;
  & .modal-content {
    border: none;
    background-color: #681e46;
    border-radius: 20px;
  }
`;
const CustomHeader = styled(ModalHeader)`
  /* background-color: #681e46; */
`;
const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
const CustomBody = styled(ModalBody)`
  padding: 0 !important;
`;

const TeacherCardModal = ({ modalData, teacher, refreshData }) => {
  return (
    <CustomModal
      size="md"
      className="min-w-50  text-dark"
      centered
      isOpen={modalData.isModalNameOpen(modalMap.teacherCard.name)}
      toggle={modalData.toggleModal}
    >
      <CustomHeader
        close={<CloseIcon onClick={modalData.closeModal} />}
        className="px-5 pb-0 border-bottom-0 "
        toggle={modalData.toggleModal}
      ></CustomHeader>
      <CustomBody>
        <TeacherCardBody
          modalData={modalData}
          teacher={teacher}
          refreshData={refreshData}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default TeacherCardModal;
