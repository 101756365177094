import React, { useRef } from "react";
import { injectUserStore } from "src/utils/helpers";
import useReportData from "./hooks/useReportData";
import { FiltersSection, ReportDataSection } from "./components";

const Rates = ({ UserStore }) => {
  const user = UserStore?.user;
  const hookData = useReportData();
  const {
    reportData: { payments },
  } = hookData;

  const reportDataSectionRef = useRef(null);

  return (
    <div>
      <div>
        <FiltersSection
          reportDataSectionRef={reportDataSectionRef}
          hookData={hookData}
        />
      </div>
      <div ref={reportDataSectionRef} className="mt-5">
        <ReportDataSection hookData={hookData} />
      </div>
    </div>
  );
};

export default injectUserStore(Rates);
