import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { absenceTypes, isPerDurationAbsence } from "src/constants/absenceTypes";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import useModal from "src/hooks/useModal";
import {
  getCurrentSchoolYear,
  getPrivateLessonInfoOnSpecificDate,
  getRecurringEventTimelineObj,
} from "src/utils/helpers";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import {
  CustomSelectField,
  AbsencesListContainer,
  AbsencesRow,
  CustomInput,
  CustomLabel,
  CustomTextarea,
} from "../../styled";
import MakeUpSection from "./MakeUpSection";

const AbsenceAndMakeUpItem = ({
  absence,
  index,
  currentLesson,
  makeUpData,
  setCurrentAbsenceId,
  makeupModalData,
  openAbsenceDeleteConfirmationModal,
  openMakeupDeleteConfirmationModal,
  isPassedLastSetDeadline,
}) => {
  const { schoolYears } = useStudentInfoContext();

  const { date, startDate, createdAt, absenceType, comment } = absence;
  const absenceDate = useMemo(() => {
    return absence.date || absence.startDate;
  }, [absence]);
  const formattedAbsenceDate = moment(absenceDate).format("YYYY-MM-DD");

  const lessonInfoOnAbsenceDate = useMemo(() => {
    if (!currentLesson) {
      return;
    }
    const lessonInfo = getPrivateLessonInfoOnSpecificDate({
      privateLesson: currentLesson,
      date: absenceDate,
      withTimelineApproximation: true,
    });

    return lessonInfo;
  }, [currentLesson, absenceDate]);

  // current school year based on the lesson locationId
  const currentSchoolYearInLocation = useMemo(
    () =>
      getCurrentSchoolYear(schoolYears, lessonInfoOnAbsenceDate?.locationId),
    [schoolYears, lessonInfoOnAbsenceDate]
  );

  const isDuringSummerSession = useMemo(() => {
    if (!currentSchoolYearInLocation || !absenceDate) return false;
    const { dates } = currentSchoolYearInLocation;
    const { summer_session_start, summer_session_end } = dates || {};
    if (!summer_session_start || !summer_session_end) return false;

    return moment(absenceDate).isBetween(
      summer_session_start.toDate(),
      summer_session_end.toDate(),
      "minutes",
      "[]"
    );
  }, [currentSchoolYearInLocation, absenceDate]);

  // makes sure the absence is for the current lesson
  if (
    absence.lessonId !== currentLesson.id &&
    !absence.affectedPrivateLessonsIds?.includes(currentLesson.id)
  ) {
    return null;
  }

  const lessonLength = parseInt(lessonInfoOnAbsenceDate?.duration);

  const absenceTypeObj = Object.values(absenceTypes).find(
    (el) => parseInt(el.code) === parseInt(absenceType)
  );
  const absenceTypeName = absenceTypeObj?.name;
  const absenceTypeCode = absenceTypeObj?.code;
  const isSDC =
    parseInt(absence.absenceType) === absenceTypes.sameDayCancelation.code;
  const isEA =
    parseInt(absence.absenceType) === absenceTypes.emergencyAbsence.code;
  const isEditableAbsenceType = isSDC || isEA;

  const schoolYearStart =
    currentSchoolYearInLocation &&
    currentSchoolYearInLocation?.dates?.start_date.toDate();
  const schoolYearEnd =
    currentSchoolYearInLocation &&
    currentSchoolYearInLocation?.dates?.end_date.toDate();

  const isAbsenceInPastSchoolYear = moment(absenceDate).isBefore(
    schoolYearStart,
    "date"
  );
  return (
    <>
      <AbsencesListContainer key={absence.id}>
        <div
          style={{
            marginRight: "30px",
            marginTop: "30px",
            ...(isAbsenceInPastSchoolYear && {
              color: "red",
              fontSize: "13px",
            }),
          }}
        >
          <h3>{`${index + 1}.`}</h3>
          {isAbsenceInPastSchoolYear && <span>Last Academic Year</span>}
        </div>
        <div style={{ width: "100%" }}>
          <AbsencesRow>
            <div>
              <CustomLabel>Absence Date:</CustomLabel>
              <CustomInput
                width="180px"
                type="date"
                className="form-control"
                value={formattedAbsenceDate}
                disabled
              />
            </div>
            <div>
              <CustomLabel>Absence Type:</CustomLabel>
              <CustomSelectField
                value={absenceTypeCode}
                name="absenceType"
                onChange={(e) =>
                  makeUpData.handleAbsencesListChange(
                    absence.id,
                    e.target.name,
                    parseInt(e.target.value)
                  )
                }
                width={"180px"}
              >
                {Object.values(absenceTypes).map((absenceType) => {
                  const isRegularAbsenceDuringSummerSession =
                    isDuringSummerSession &&
                    absenceType.code === absenceTypes.regular.code;
                  const isSummerAbsenceNotInSummerSession =
                    !isDuringSummerSession &&
                    absenceType.code === absenceTypes.summerAbsence.code;

                  const label = isRegularAbsenceDuringSummerSession
                    ? `${absenceType.abbr} (Disabled for Summer Session)`
                    : isSummerAbsenceNotInSummerSession
                    ? `${absenceType.abbr} (Disabled)`
                    : absenceType.abbr;

                  const isDisabledOption =
                    isRegularAbsenceDuringSummerSession ||
                    isSummerAbsenceNotInSummerSession;
                  return (
                    <option
                      disabled={isDisabledOption}
                      key={absenceType.code}
                      value={absenceType.code}
                    >
                      {label}
                    </option>
                  );
                })}
              </CustomSelectField>
            </div>
            <div>
              <CustomLabel>Lesson Length:</CustomLabel>
              <CustomInput
                width="180px"
                type="text"
                className="form-control"
                value={`${lessonLength} Minutes`}
              />
            </div>
            {!isPerDurationAbsence(absence?.absenceBehaviour) ? (
              <div
                onClick={() => openAbsenceDeleteConfirmationModal(absence?.id)}
                className="mb-2"
                style={{ cursor: "pointer" }}
              >
                <DeleteOff width={30} height={30} color="#8a8a8a" />
              </div>
            ) : null}
          </AbsencesRow>
          <hr />
          <MakeUpSection
            absence={absence}
            makeUpData={makeUpData}
            setCurrentAbsenceId={setCurrentAbsenceId}
            makeupModalData={makeupModalData}
            currentLesson={currentLesson}
            lessonDuration={lessonInfoOnAbsenceDate?.duration}
            currentSchoolYearInLocation={currentSchoolYearInLocation}
            openMakeupDeleteConfirmationModal={
              openMakeupDeleteConfirmationModal
            }
            instrumentId={currentLesson?.instrumentId}
            isPassedLastSetDeadline={isPassedLastSetDeadline}
          />
          <div>
            <CustomLabel>Comment:</CustomLabel>
            <CustomTextarea
              width="100%"
              placeholder="Comment..."
              name="comment"
              value={comment}
              onChange={(e) =>
                makeUpData.handleAbsencesListChange(
                  absence.id,
                  e.target.name,
                  e.target.value
                )
              }
            />
          </div>
        </div>
      </AbsencesListContainer>
    </>
  );
};

export default AbsenceAndMakeUpItem;
