import React from "react";
import RouteContent from "src/components/common/RouteContent";
import useFirebaseFns from "./hooks/useFirebaseFns";
import useLogsData from "./hooks/useLogsData";
import LogsTable from "./LogsTable";
import PagingOptions from "./PagingOptions";
import { TableContainer, TopSectionContainer } from "./styled";
import TopSection from "./TopSection";

const AbsenceLogs = () => {
  const logsData = useLogsData();

  return (
    <RouteContent title="Absences Logs">
      <div className="p-3">
        <TopSectionContainer>
          <TopSection logsData={logsData} />
        </TopSectionContainer>
        <TableContainer>
          <LogsTable logsData={logsData} />
        </TableContainer>
        <div className="mt-3">
          <PagingOptions logsData={logsData} />
        </div>
      </div>
    </RouteContent>
  );
};

export default AbsenceLogs;
