// import data from "../data.js";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import NewsElement from "./NewsElement";
import "./style.css";
import { Spinner } from "reactstrap";
import backIcon from "../../../../assets/icons/backIcon.svg";
import { FirebaseContext } from "../../../Firebase";
import {
  getDocs,
  limit,
  orderBy,
  query,
  where,
  startAfter,
} from "firebase/firestore";
import { parseFirebaseDoc } from "../../../../utils/getFirebaseDoc";
import { newsUserRoles } from "src/constants/UserRoleEnum";
import { feedItems } from "src/constants/feedItemsEnum";

const newsLimit = 4;
function NewsContainer() {
  const [data, SetData] = useState();
  const [lastElementRef, setLastElementRef] = useState();
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    (async () => {
      const newsQuery = query(
        firebase.feedItems(),
        where("forRoles", "array-contains", newsUserRoles.LANDING),
        where("type", "==", feedItems.news.type),
        where("publishDate", "<", new Date()),
        orderBy("publishDate", "desc"),
        limit(newsLimit)
      );

      const snap = await getDocs(newsQuery);
      const lastRef = snap.docs[snap.docs.length - 1];

      const news = parseFirebaseDoc(snap.docs);

      setLastElementRef(lastRef);
      SetData(news);
    })();
  }, []);

  const handleClick = () => {
    const fetchNews = async () => {
      const newsQuery = query(
        firebase.feedItems(),
        where("forRoles", "array-contains", newsUserRoles.LANDING),
        where("type", "==", feedItems.news.type),
        where("publishDate", "<", new Date()),
        orderBy("publishDate", "desc"),
        startAfter(lastElementRef),
        limit(newsLimit)
      );

      const newsPromise = await getDocs(newsQuery);
      if (newsPromise.docs.length > 0) {
        setLastElementRef(newsPromise.docs[newsPromise.docs.length - 1]);
        const newsData = parseFirebaseDoc(newsPromise.docs);
        SetData(data.concat(newsData));
      } else {
        setLastElementRef(undefined);
      }
    };
    if (lastElementRef) {
      fetchNews();
    }
  };

  return data ? (
    <>
      <h2 className="news-container-header">Artis News</h2>
      <div className="news-container">
        {data
          ? data.map((item) => (
              <Link to={"/news/" + item.id} key={item.id}>
                <NewsElement {...item} />
              </Link>
            ))
          : "..."}
      </div>
      <a
        className="older-entries"
        href=""
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <img src={backIcon} alt="down Icon" />
        Load More
      </a>
    </>
  ) : (
    <div className="news-loader-container">
      <Spinner />
    </div>
  );
}

export default NewsContainer;
