export const studentViewOptions = {
  NEW: "New",
  CURRENT: "Current",
};

export const enrollmentComponents = {
  NEW_STUDENT: "new_student",
  CURRENT_STUDENT: "current_student",
  PAYMENT: "payment",
};
export const enrollmentOptions = {
  payNow: {
    name: "Pay Now",
    value: "pay_now",
  },
  enroll_now_pay_later: {
    name: "Enroll Now Pay Later",
    value: "enroll_now_pay_later",
  },
};
