import { toJS } from "mobx";
import moment from "moment";
import React, { useContext, useState } from "react";
import { SubmitSpinner } from "src/components/common";
import {
  Container,
  RedBarContainer,
  RedBarText,
  CustomInput,
  CustomLabel,
  CustomSelectField,
  InputWrapper,
  CustomButton,
  CustomButtonsWrapper,
} from "../../../../styled";
import "react-datepicker/dist/react-datepicker.css";
import { LessonsLengthEnum } from "src/constants/LessonsLength";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import {
  CalendarPickerWrapper,
  CustomTextarea,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import { changeOptionsList, optionsPropNames } from "../../../constants";
import {
  checkIfActivityDuringSchoolBreak,
  isActiveTeacher,
} from "src/utils/helpers";

const FormSection = ({
  lessonChangesModalData,
  lessonWithCurrentTimeline,
  hookData,
}) => {
  const { programs, locations, teachers, instruments } =
    useStudentInfoContext();

  const {
    formValues,
    handleFormValuesChange,
    availableTeachers,
    availableClassDays,
    selectedGroup,
    combinedSchoolBreaks,
    isInstrumentChangeSelected,
    currentTeacher,
    filteredTeacherInstrumentsList,
  } = hookData;

  const { closeModal } = lessonChangesModalData;

  const lessonStartDate = lessonWithCurrentTimeline.currentTimeline?.startDate;
  const lessonModifications = lessonWithCurrentTimeline.modifications || [];

  const displayField = (propName) => {
    const selectedGroupObj = changeOptionsList.find(
      (groupObj) => groupObj.propName === selectedGroup
    );
    const availableOptions = selectedGroupObj?.availableOptions;
    return availableOptions?.includes(propName);
  };

  const isExcludedSchoolBreakDate = (date) => {
    return combinedSchoolBreaks?.some((schoolBreak) =>
      checkIfActivityDuringSchoolBreak({ start: date }, schoolBreak)
    );
  };
  const isExcludedModificationDate = (date) => {
    //mods original dates to exclude
    const excludedOriginalDates = lessonModifications
      .filter(
        ({ date, weekNumber }) =>
          !moment(date).isSame(
            moment(lessonStartDate).add(parseInt(weekNumber) - 1, "weeks"),
            "day"
          )
      )
      .map(({ weekNumber }) =>
        moment(lessonStartDate)
          .add(parseInt(weekNumber) - 1, "weeks")
          .toDate()
      );

    let isExcluded = false;
    for (const excludedDate of excludedOriginalDates) {
      if (moment(date).isSame(excludedDate, "date")) {
        isExcluded = true;
        break;
      }
    }
    return isExcluded;
  };

  const [isLastLessonDatePickerOpen, setIsLastLessonDatePickerOpen] =
    useState(false);
  const [isNewLessonDatePickerOpen, setIsNewLessonDatePickerOpen] =
    useState(false);
  return (
    <>
      <div>
        {displayField(optionsPropNames.LESSON_TIME) && (
          <InputWrapper>
            <CustomLabel>
              Lesson Time:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CustomInput
              name="lessonTime"
              value={formValues.lessonTime}
              onChange={(e) =>
                handleFormValuesChange(e.target.name, e.target.value)
              }
              type="time"
              width="100%"
            />
          </InputWrapper>
        )}

        {displayField(optionsPropNames.LESSON_LENGTH) && (
          <InputWrapper>
            <CustomLabel>
              Lesson Length:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CustomSelectField
              width="100%"
              name="lessonLength"
              value={formValues.lessonLength}
              onChange={(e) =>
                handleFormValuesChange(e.target.name, parseInt(e.target.value))
              }
            >
              <option value="" disabled>
                Select Length
              </option>

              {Object.keys(LessonsLengthEnum).map((duration) => (
                <option key={duration} value={duration}>
                  {`${duration} Minutes`}
                </option>
              ))}
            </CustomSelectField>
          </InputWrapper>
        )}
        {displayField(optionsPropNames.LOCATION) && (
          <InputWrapper>
            <CustomLabel>
              Location:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CustomSelectField
              width="100%"
              name="locationId"
              value={formValues.locationId}
              onChange={(e) =>
                handleFormValuesChange(e.target.name, e.target.value)
              }
            >
              <option value="" disabled>
                Select Location
              </option>
              {locations.map(({ id, name }) => {
                return (
                  <option key={id} value={id}>
                    {name}
                  </option>
                );
              })}
            </CustomSelectField>
          </InputWrapper>
        )}
        {displayField(optionsPropNames.TEACHER) && (
          <InputWrapper>
            <CustomLabel>
              Teacher/Program:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CustomSelectField
              hideDisabledOptions
              width="100%"
              name="teacherId"
              value={formValues.teacherId}
              onChange={(e) =>
                handleFormValuesChange(e.target.name, e.target.value)
              }
            >
              <option value="" disabled>
                Select Teacher
              </option>
              {availableTeachers.map((teacher) => {
                const programId = teacher.instrumentsInfo?.find(
                  ({ instrument, program }) =>
                    instrument === lessonWithCurrentTimeline.instrumentId
                )?.program;

                const programName = programs.find(
                  ({ id }) => id === programId
                )?.name;
                return (
                  <option
                    disabled={!isActiveTeacher(teacher)}
                    key={teacher.id}
                    value={teacher.id}
                  >
                    {`${teacher.fullName} | ${programName}`}
                  </option>
                );
              })}
            </CustomSelectField>
          </InputWrapper>
        )}
        {displayField(optionsPropNames.CLASS_DAY) && (
          <InputWrapper>
            <CustomLabel>
              Class Day:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CustomSelectField
              width="100%"
              name="weekDay"
              value={formValues.weekDay}
              onChange={(e) =>
                handleFormValuesChange(e.target.name, parseInt(e.target.value))
              }
            >
              <option value="" disabled>
                Select Day
              </option>
              {availableClassDays.map((day) => (
                <option key={day.id} value={day.id}>
                  {day.name}
                </option>
              ))}
            </CustomSelectField>
          </InputWrapper>
        )}
        {displayField(optionsPropNames.IS_VIRTUAL) && (
          <InputWrapper>
            <CustomLabel>
              Lesson Type:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <div width="100%" name="weekDay">
              <label className="me-3">
                <input
                  type="radio"
                  checked={!formValues.isVirtual}
                  name="isVirtual"
                  onChange={(e) => handleFormValuesChange(e.target.name, false)}
                />{" "}
                In Person
              </label>
              <label>
                <input
                  type="radio"
                  checked={formValues.isVirtual}
                  name="isVirtual"
                  onChange={(e) => handleFormValuesChange(e.target.name, true)}
                />{" "}
                Virtual
              </label>
            </div>
          </InputWrapper>
        )}
        {displayField(optionsPropNames.INSTRUMENT) && (
          <InputWrapper>
            <CustomLabel>
              Instrument/Program:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CustomSelectField
              width="100%"
              name="instrumentId"
              value={formValues.instrumentId}
              onChange={(e) =>
                handleFormValuesChange(e.target.name, e.target.value)
              }
            >
              <option value="" disabled>
                Select Instrument
              </option>

              {filteredTeacherInstrumentsList.map((instrument) => {
                const teacherProgramId = currentTeacher?.instrumentsInfo?.find(
                  (instrumentInfo) =>
                    instrumentInfo.instrument === instrument.id
                )?.program;
                const programName = programs?.find(
                  (program) => program.id === teacherProgramId
                )?.name;
                const instrumentName = instrument.name;
                const label = `${instrumentName} | ${programName}`;
                return (
                  <option key={instrument.id} value={instrument.id}>
                    {label}
                  </option>
                );
              })}
            </CustomSelectField>
          </InputWrapper>
        )}
        {!isInstrumentChangeSelected && (
          <>
            <InputWrapper>
              <CustomLabel>
                Last Lesson Date (Original Schedule):
                <RequiredInputSpan>*</RequiredInputSpan>
              </CustomLabel>
              <CalendarPickerWrapper>
                <SingleDatePicker
                  id="lastLessonDatePicker"
                  date={formValues.lastLessonDate} // momentPropTypes.momentObj or null
                  onDateChange={(date) => {
                    handleFormValuesChange("lastLessonDate", date);
                  }}
                  focused={isLastLessonDatePickerOpen} // PropTypes.bool
                  onFocusChange={({ focused }) => {
                    setIsLastLessonDatePickerOpen(focused);
                  }}
                  // numberOfMonths={1}
                  // showClearDate={true}
                  isOutsideRange={(date) => {
                    if (isExcludedSchoolBreakDate(date)) {
                      return true;
                    }
                    // //disables dates before the lesson start
                    // if (moment(date).isBefore(lessonStartDate, "date"))
                    //   return true;

                    // // disables dates that are not on the same weekday as the lsn
                    // if (moment(date).day() !== moment(lessonStartDate).day())
                    //   return true;

                    // if (isExcludedModificationDate(date)) return true;

                    return false;
                  }}
                />
              </CalendarPickerWrapper>
            </InputWrapper>
            <InputWrapper>
              <CustomLabel>
                New Lesson Date (New Schedule):
                <RequiredInputSpan>*</RequiredInputSpan>
              </CustomLabel>
              <CalendarPickerWrapper>
                <SingleDatePicker
                  id="newLessonDatePicker"
                  date={formValues.newLessonDate} // momentPropTypes.momentObj or null
                  onDateChange={(date) => {
                    handleFormValuesChange("newLessonDate", date);
                  }}
                  focused={isNewLessonDatePickerOpen} // PropTypes.bool
                  onFocusChange={({ focused }) => {
                    setIsNewLessonDatePickerOpen(focused);
                  }}
                  // numberOfMonths={1}
                  // showClearDate={true}
                  isOutsideRange={(date) => {
                    if (isExcludedSchoolBreakDate(date)) {
                      return true;
                    }
                    //disables dates before the lesson start
                    if (moment(date).isBefore(lessonStartDate, "date"))
                      return true;

                    // disables dates that are not on the same weekday as the lsn
                    if (moment(date).day() !== parseInt(formValues.weekDay))
                      return true;

                    return false;
                  }}
                />
              </CalendarPickerWrapper>
            </InputWrapper>
            <InputWrapper>
              <CustomLabel>
                Note (For Calendar Use):
                <RequiredInputSpan>*</RequiredInputSpan>
              </CustomLabel>
              <CustomTextarea
                width="100%"
                name="note"
                value={formValues.note}
                onChange={(e) =>
                  handleFormValuesChange(e.target.name, e.target.value)
                }
              />
            </InputWrapper>
          </>
        )}
      </div>
    </>
  );
};

export default FormSection;
