import React from "react";
import RouteContent from "src/components/common/RouteContent";
import { injectUserStore } from "src/utils/helpers";
import StudentsSection from "./components/StudentsSection";
import TableSection from "./components/TableSection";
import useStudentsMakeUp from "./hooks/useStudentsMakeUp";

const StudentsMakeUp = ({ UserStore }) => {
  const user = UserStore.user;
  const studentsMakeupData = useStudentsMakeUp({ user });
  const { filteredAbsencesWithAdditionalData, loadingFilteredStudentsData } =
    studentsMakeupData;
  return (
    <RouteContent title="Students Make Up">
      <div className="mb-4">
        <StudentsSection studentsMakeupData={studentsMakeupData} />
      </div>
      <div>
        <TableSection
          filteredAbsencesWithAdditionalData={
            filteredAbsencesWithAdditionalData
          }
          loadingFilteredStudentsData={loadingFilteredStudentsData}
        />
      </div>
    </RouteContent>
  );
};

export default injectUserStore(StudentsMakeUp);
