import { documentId, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useContext } from "react";
import {
  isDeclinedAbsence,
  isPendingAbsence,
} from "src/constants/absenceTypes";
import { parseUserAvailableDayDates } from "src/utils/firebaseDatesParserFns";
import {
  getFirebaseDoc,
  parseFirebaseDoc,
} from "../../../../../utils/getFirebaseDoc";
import { chunkArrayInGroups } from "../../../../../utils/helpers";
import { FirebaseContext } from "../../../../Firebase";
import { studentTypes } from "../../constants";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getInstruments = async () => {
    try {
      const instrumentsSnap = await getDocs(firebase.instruments());
      const instruments = getFirebaseDoc(instrumentsSnap);
      return instruments;
    } catch (err) {
      console.log(err);
    }
  };
  const getPrograms = async () => {
    try {
      const programsSnap = await getDocs(firebase.programs());
      const programs = getFirebaseDoc(programsSnap);
      return programs;
    } catch (err) {
      console.log(err);
    }
  };
  const getStudios = async () => {
    try {
      const studiosSnap = await getDocs(firebase.studios());
      const studios = getFirebaseDoc(studiosSnap);
      return studios;
    } catch (err) {
      console.log(err);
    }
  };
  const getLocations = async () => {
    try {
      const locationsSnap = await getDocs(firebase.locations());
      const locations = getFirebaseDoc(locationsSnap);
      return locations;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeacherAvailableDays = async (teacherId) => {
    try {
      const availableDaysSnapshot = await getDoc(
        firebase.userAvailableDays(teacherId)
      );
      const availableDays = {
        id: availableDaysSnapshot.id,
        ...availableDaysSnapshot.data(),
      };

      const parsedDates = parseUserAvailableDayDates(availableDays);
      return parsedDates;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeacherPLs = async (teacherId) => {
    try {
      const privateLessonsQuery = query(
        firebase.PrivateLessons(),
        where("teachersIds", "array-contains", teacherId)
      );
      const privateLessonsSnapshot = await getDocs(privateLessonsQuery);
      const privateLessons = getFirebaseDoc(privateLessonsSnapshot);
      return privateLessons;
    } catch (err) {
      console.log(err);
    }
  };
  const getTeacherTLs = async (teacherId) => {
    try {
      const trialLessonsQuery = query(
        firebase.trialLessons(),
        where("teacherId", "==", teacherId)
      );
      const trialLessonsSnapshot = await getDocs(trialLessonsQuery);
      const trialLessons = getFirebaseDoc(trialLessonsSnapshot);
      return trialLessons;
    } catch (err) {
      console.log(err);
    }
  };
  const getTeacherMakeupLessons = async (teacherId) => {
    try {
      const makeupLessonsQuery = query(
        firebase.makeupLessons(),
        where("teacherId", "==", teacherId)
      );
      const makeupLessonsSnapshot = await getDocs(makeupLessonsQuery);
      const makeupLessons = getFirebaseDoc(makeupLessonsSnapshot);
      return makeupLessons;
    } catch (err) {
      console.log(err);
    }
  };
  const getTeacherAppointments = async (teacherId) => {
    try {
      const appointmentsQuery = query(
        firebase.appointments(),
        where("teacherId", "==", teacherId)
      );
      const appointmentSnapshot = await getDocs(appointmentsQuery);
      const appointment = getFirebaseDoc(appointmentSnapshot);
      return appointment;
    } catch (err) {
      console.log(err);
    }
  };

  // Takes an array of student ids , returns those students from firebase whether they are private or trial students (supports more than 10 students)
  const getStudentsByIds = async (
    studentIds,
    studentType = studentTypes.privateStudent.name
  ) => {
    if (!studentIds?.length) return {};
    try {
      const studentIdsChunks = chunkArrayInGroups(studentIds, 10);
      let requests = [];
      studentIdsChunks.forEach((studentIdsArray) => {
        const studentsDocRef =
          studentType === studentTypes.privateStudent.name
            ? firebase.users()
            : studentType === studentTypes.trialStudent.name
            ? firebase.trialStudents()
            : null;
        const studentsQuery = query(
          studentsDocRef,
          where(documentId(), "in", studentIdsArray)
        );
        const req = getDocs(studentsQuery);
        requests.push(req);
      });
      const studentsSnapshotsChunks = await Promise.all(requests);
      const studentsChunks = studentsSnapshotsChunks.map((studentsSnapshot) =>
        getFirebaseDoc(studentsSnapshot)
      );
      const students = studentsChunks.reduce(
        (acc, current) => Object.assign(acc, current),
        {}
      );
      return students;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const getTeacherAbsences = async (teacherId) => {
    try {
      if (!teacherId) return [];

      const q = query(firebase.absences(), where("teacherId", "==", teacherId));

      const absences = parseFirebaseDoc((await getDocs(q)).docs);

      const filteredAbsences = absences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );
      return filteredAbsences;
    } catch (err) {
      console.log(err);
    }
  };

  const getSchoolYears = async () => {
    try {
      const schoolYearsSnap = await getDocs(firebase.getSchoolYears());
      const schoolYears = parseFirebaseDoc(schoolYearsSnap.docs);
      return schoolYears;
    } catch (err) {
      console.log(err);
    }
  };

  const getBreaksByTeacherId = async (teacherId) => {
    if (!teacherId) return [];
    try {
      const q = query(firebase.breaks(), where("userId", "==", teacherId));
      const snap = await getDocs(q);

      const breaks = parseFirebaseDoc(snap.docs);

      return breaks;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  return {
    getInstruments,
    getLocations,
    getStudios,
    getPrograms,
    getTeacherAvailableDays,
    getTeacherPLs,
    getTeacherTLs,
    getStudentsByIds,
    getTeacherMakeupLessons,
    getTeacherAppointments,
    getTeacherAbsences,
    getSchoolYears,
    getBreaksByTeacherId,
  };
};

export default useFirebaseFns;
