import React from "react";
import { isAdmin, isSuperAdmin } from "src/constants/UserRoleEnum";
import { injectUserStore } from "src/utils/helpers";
import ChangeProfileImageButton from "../../../ChangeProfileImageButton";
import ContactInfoSection from "./ContactInfoSection";
import ExtraFunctionsSection from "./ExtraFunctionsSection";
import AdminActions from "./AdminActions";

const ModalBodyContent = ({
  modalData,
  adminUser,
  onExtraFunctionsChangeSuccess,
  UserStore,
}) => {
  const user = UserStore.user;
  const isModalUserAdmin = isAdmin(adminUser.role);
  const isLoggedInUserSuperAdmin = isSuperAdmin(user.role);
  const showExtraFunctionsSection =
    isModalUserAdmin && isLoggedInUserSuperAdmin;
  return (
    <div className="pt-2 mb-4 px-4 pb-4">
      <h5 className="text-center text-capitalize mb-4">
        {adminUser?.fullName}
        <div className="text-center mt-3">
          <ChangeProfileImageButton
            userId={adminUser?.id}
            onSuccess={() => window.location.reload()}
          />
        </div>
      </h5>
      <div>
        <AdminActions admin={adminUser} />
      </div>
      <div>
        <ContactInfoSection adminUser={adminUser} />
      </div>
      {showExtraFunctionsSection && (
        <div>
          <hr />
          <ExtraFunctionsSection
            onExtraFunctionsChangeSuccess={onExtraFunctionsChangeSuccess}
            adminUser={adminUser}
          />
        </div>
      )}
    </div>
  );
};

export default injectUserStore(ModalBodyContent);
