import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  isEmailTypeNotificationSettings,
  isSchoolScopeNotificationSettings,
  isUserScopeNotificationSettings,
  notificationSettingsScopes,
  notificationSettingsTypes,
} from "src/constants/notificationSettingsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useNotificationsSettingsData = ({ scope, userId, type }) => {
  const {
    getSchoolNotificationSettingsByType,
    getUserNotificationSettingsByType,
    updateNotificationSettingsDoc,
    createNotificationSettingsDoc,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    settingsDoc: null,
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  // const [disabledSettingsList, setDisabledSettingsList] = useState([]);

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  const isSettingDisabled = (propName) => {
    if (!propName) return;

    const isDisabled =
      initialData.settingsDoc?.disabledList?.includes(propName);
    return isDisabled;
  };
  const handleDisabledSettingsListChange = async (propName) => {
    try {
      if (!propName || !scope || !type) return;

      const currentDisabledList = initialData.settingsDoc?.disabledList || [];

      // adds the propName to the disabledList , or remove it if it already exists (toggles the prop)
      let newDisabledList;
      if (currentDisabledList.includes(propName)) {
        newDisabledList = currentDisabledList.filter(
          (prop) => prop !== propName
        );
      } else {
        newDisabledList = [...currentDisabledList, propName];
      }

      if (initialData.settingsDoc) {
        const updateObj = {
          ...initialData.settingsDoc,
          disabledList: newDisabledList,
        };
        await updateNotificationSettingsDoc(
          initialData.settingsDoc.id,
          updateObj
        );
      } else {
        const settingsObj = {
          scope,
          type,
          ...(scope === notificationSettingsScopes.USER && {
            userId,
          }),
          disabledList: newDisabledList,
        };
        await createNotificationSettingsDoc(settingsObj);
      }
      toast.success("Updated Successfully");
      refreshData();
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!scope || !type) return;

        setLoadingInitialData(true);

        let settingsDoc;
        if (isSchoolScopeNotificationSettings(scope)) {
          if (isEmailTypeNotificationSettings(type)) {
            settingsDoc = await getSchoolNotificationSettingsByType(
              notificationSettingsTypes.EMAIL
            );
          } else {
            settingsDoc = await getSchoolNotificationSettingsByType(
              notificationSettingsTypes.INTERNAL
            );
          }
        } else if (isUserScopeNotificationSettings(scope)) {
          if (isEmailTypeNotificationSettings(type)) {
            settingsDoc = await getUserNotificationSettingsByType(
              userId,
              notificationSettingsTypes.EMAIL
            );
          } else {
            settingsDoc = await getUserNotificationSettingsByType(
              userId,
              notificationSettingsTypes.INTERNAL
            );
          }
        }

        console.log(settingsDoc);

        setInitialData({
          settingsDoc: settingsDoc,
        });
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh]);

  return {
    initialData,
    loadingInitialData,
    refreshData,
    isSettingDisabled,
    handleDisabledSettingsListChange,
  };
};

export default useNotificationsSettingsData;
