/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { SearchAlt2 } from "@styled-icons/boxicons-regular/SearchAlt2";
import styled from "styled-components";
import { FirebaseContext } from "../../Firebase";
import AddTrialLessonStudentModal from "./AddTrialLessonStudentModal";
import { UserRole } from "../../../constants/UserRoleEnum";
import RouteContent from "../../common/RouteContent";
import { getFirebaseDoc } from "../../../utils/getFirebaseDoc";
import TrialLessonsStudentsTable from "./TrialLessonsStudentsTable";
import LocationSelect from "../../common/LocationSelect.js";
import { getDocs, query, where } from "firebase/firestore";
import "./style.css";
import { TextButton } from "../../common/styled-components/teacherLibraryStyledComponents";
import DropdownFilter from "src/components/common/DropdownFilter";
import months from "../../../constants/months";
import years from "src/constants/trialLessonYears";
import moment from "moment";
import { parseTrialLessonDates } from "src/utils/firebaseDatesParserFns";
import { updatedMomentNow } from "src/utils/helpers";

export const TrialLessonsContext = createContext({});
const SearchandAddContainer = styled.div`
  display: flex;
  width: 100%;
`;
const Searchicon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
`;

const TrialLessons = () => {
  const firebase = useContext(FirebaseContext);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [addStudentModalOpen, setAddStudentModalOpen] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [trialLessons, setTrialLessons] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(
    months.find(
      (monthOption) => monthOption.value === updatedMomentNow().month() + 1
    )
  );
  const [selectedYear, setSelectedYear] = useState(
    years.find((yearOption) => yearOption.value === updatedMomentNow().year())
  );

  const fetchStudents = async () => {
    const usersDocs = await getDocs(firebase.getTrialStudents());
    const students = usersDocs.docs.map((user) => {
      const userData = user.data();
      return {
        id: user.id,
        lessons: [],
        ...user.data(),
      };
    });
    setStudents(students);
  };
  const fetchInstruments = async () => {
    const instrumentsDocs = await getDocs(firebase.instruments());
    const instruments = getFirebaseDoc(instrumentsDocs);
    setInstruments(instruments);
  };
  const fetchPrograms = async () => {
    const programsDocs = await getDocs(firebase.programs());
    const programs = getFirebaseDoc(programsDocs);
    setPrograms(programs);
  };
  const fetchTeachers = async () => {
    const q = query(firebase.users(), where("role", "==", UserRole.TEACHER));
    const teachersDocs = await getDocs(q);
    const teachers = getFirebaseDoc(teachersDocs);
    setTeachers(teachers);
  };
  const fetchLocations = async () => {
    const locationsDocs = await getDocs(firebase.getLocations());
    const addedLocations = locationsDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const locationsIds = addedLocations.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    setLocations(addedLocations);
    setSelectedLocations(locationsIds);
  };
  const fetchTrialLessons = async () => {
    const lessonsDocs = await getDocs(firebase.getTrialLessons());
    const lessons = getFirebaseDoc(lessonsDocs);
    setTrialLessons(lessons);
  };

  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  useEffect(async () => {
    setLoading(true);
    await Promise.all([
      fetchInstruments(),
      fetchLocations(),
      fetchStudents(),
      fetchTeachers(),
      fetchPrograms(),
    ]);
    await fetchTrialLessons();
    setLoading(false);
  }, [refresh]);
  const updateStudentsLessons = () => {
    setStudents((previous) => {
      return [].concat(previous).map((student) => {
        if (student?.lessons?.length) {
          student.trialLessons = student.lessons.map((lessonId) => ({
            id: lessonId,
            ...trialLessons[lessonId],
          }));
        }
        return student;
      });
    });
  };
  useEffect(() => {
    updateStudentsLessons();
  }, [trialLessons]);
  const handleChange = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    const StudentsFilteredList = students.filter((student) => {
      if (searchQuery == null || searchQuery === "" || searchQuery === " ") {
        return false;
      } else {
        return student.fullName
          ? student.fullName.toLowerCase().includes(searchQuery)
          : null;
      }
    });
    setFilteredUsers([...StudentsFilteredList]);
  }, [searchQuery, loading, students]);
  // search user logic functions
  const onSelectLocation = (locationsIds) => {
    setSelectedLocations(locationsIds);
  };
  const onStudentUpdateSuccess = (student) => {
    setStudents((previous) =>
      previous.map((s) => {
        if (s.id === student.id) {
          return {
            ...s,
            ...student,
          };
        } else {
          return s;
        }
      })
    );
  };
  const onLessonCreateSuccess = (studentId, lessonId, lesson) => {
    const lessonData = { ...lesson };
    setTrialLessons((previous) => ({
      ...previous,
      [lessonId]: { ...lessonData },
    }));
    setStudents((previous) =>
      previous.map((student) => {
        if (student.id === studentId) {
          console.log(lessonData);
          return {
            ...student,
            lessons: (student?.lessons || []).concat([lessonId]),
            trialLessons: (student?.trialLessons || []).concat([
              { ...lessonData },
            ]),
          };
        } else {
          return student;
        }
      })
    );
  };
  let filteredStudents = students;
  if (searchQuery !== "") {
    filteredStudents = filteredStudents.filter(({ name }) =>
      name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  if (selectedLocations.length || selectedMonth || selectedYear) {
    const locationsIds = selectedLocations.map(({ value }) => value);
    filteredStudents = filteredStudents.map((student) => {
      const studentTrialLessons = student.trialLessons || [];
      let trialLessons = studentTrialLessons.filter(({ locations }) =>
        locationsIds.includes(locations[0])
      );
      if (selectedYear) {
        trialLessons = trialLessons.filter((lesson) => {
          return (
            moment(parseTrialLessonDates(lesson).date).year() ===
            selectedYear.value
          );
        });
      }
      if (selectedMonth) {
        trialLessons = trialLessons.filter((lesson) => {
          return (
            moment(parseTrialLessonDates(lesson).date).month() + 1 ===
            selectedMonth.value
          );
        });
      }

      return {
        ...student,
        lessons: trialLessons.map(({ id }) => id),
        trialLessons,
      };
    });
    filteredStudents = filteredStudents.filter(
      ({ trialLessons = [] }) => trialLessons.length > 0
    );
  }
  const updateTrialLessons = async () => {
    await fetchStudents();
    await fetchTrialLessons();
    updateStudentsLessons();
  };
  const onLessonUpdateSuccess = (lessonId, lesson) => {
    setTrialLessons((previous) => ({
      ...previous,
      [lessonId]: {
        ...previous[lessonId],
        ...lesson,
      },
    }));
    updateStudentsLessons();
  };
  return (
    <>
      <RouteContent title="Trial Lessons">
        <SearchandAddContainer className="d-flex align-items-center mb-3 justify-content-between">
          <div className={"d-flex align-items-center"}>
            <div className="align-items-center d-flex">
              <TextButton
                className={"fw-bold"}
                onClick={() => {
                  setAddStudentModalOpen(true);
                  const x = document.getElementsByTagName("BODY")[0];
                  x.classList.add("overflowBody");
                }}
              >
                <i className="fas fa-plus mx-2" />
                Add New Trial Lesson
              </TextButton>
            </div>
            <div className={"mx-4"}>
              <div className="searchContainer">
                <input
                  placeholder="Search by Student Name"
                  className="searchInput"
                  value={searchQuery}
                  onChange={(e) => handleChange(e)}
                />
                <Searchicon />
              </div>
            </div>
          </div>
          <div>
            <LocationSelect
              selectedLocations={selectedLocations}
              locations={locations}
              onSelectLocation={onSelectLocation}
            />
          </div>
        </SearchandAddContainer>
        <div className="trialLessonsDayFilterContainer">
          <DropdownFilter
            value={selectedMonth}
            setValue={setSelectedMonth}
            options={months}
            placeholder={"Select Month"}
          />
          <DropdownFilter
            value={selectedYear}
            setValue={setSelectedYear}
            options={years}
            placeholder={"Select Year"}
          />
        </div>
        <Container fluid>
          <TrialLessonsContext.Provider
            value={{
              instruments,
              teachers,
              programs,
              students: filteredStudents,
              SortedStudents: [],
              lessons: [],
              locations,
              updateStudents: setStudents,
              updateTrialLessons,
              onLessonUpdateSuccess,
              onLessonCreateSuccess,
              setLoading,
              loading,
              filteredUsers,
              refreshData,
            }}
          >
            <TrialLessonsStudentsTable
              itemsPerPage={15}
              onStudentUpdateSuccess={onStudentUpdateSuccess}
            />
            <AddTrialLessonStudentModal
              isOpen={addStudentModalOpen}
              onStudentUpdateSuccess={onStudentUpdateSuccess}
              onAddStudentSuccess={(student) => {
                setStudents([...students, student]);
                setAddStudentModalOpen(false);
              }}
              onClose={() => setAddStudentModalOpen(false)}
            />
          </TrialLessonsContext.Provider>
        </Container>
      </RouteContent>
    </>
  );
};
export default TrialLessons;
