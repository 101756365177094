import React from "react";
import TeacherLibrary from "./TeacherLibrary";
import RouteContent from "../../../components/common/RouteContent";
const TeacherLibraryRoute = ({ user }) => {
  return (
    <RouteContent title={"Teacher Library"}>
      <TeacherLibrary user={user} />
    </RouteContent>
  );
};
export default TeacherLibraryRoute;
