import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { schoolPolicies } from "src/constants/schoolPolicies";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import styled from "styled-components";
import {
  CustomTextarea,
  PrimaryButton,
} from "../../../../../../utils/shared/styled";
import { SubmitSpinner } from "../../../../../common";
import { absenceModalMap } from "../../constants";
import { CheckboxContainer } from "./styled";

const Textarea = styled.textarea`
  display: block;
  min-height: 100px;
  background-color: #bb8a920f;
  outline: none;
  border: 2px solid #ededed;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
`;

const AbsencePolicy = ({
  isPolicyAccepted,
  setIsPolicyAccepted,
  handleConfirmPolicy,
  loadingAbsence,
  absenceReason,
  handleAbsenceReasonChange,
  isSDC,
  makeUpPeriodDeadline,
  isPackagePL,
}) => {
  const { getSchoolPolicy } = useFirebaseFns();
  const [absencePolicyDescription, setAbsencePolicyDescription] = useState("");

  const handlePolicyAcceptanceChange = () => {
    setIsPolicyAccepted((oldVal) => !oldVal);
  };
  const formattedMakeupDeadline =
    moment(makeUpPeriodDeadline).format("MM/DD/YYYY");
  useEffect(() => {
    const getAbsencePolicy = async () => {
      try {
        let policy;

        const absencePolicy = await getSchoolPolicy(
          isPackagePL
            ? schoolPolicies.packageAbsencePolicy.key
            : schoolPolicies.absencePolicy.key
        );
        if (!absencePolicy || !absencePolicy?.description) {
          toast.warn("Couldnt find an absence policy to display");
          return;
        }
        policy = absencePolicy.description;

        setAbsencePolicyDescription(policy);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    };
    getAbsencePolicy();
  }, []);
  return (
    <div>
      <CustomTextarea
        className="mb-3"
        width="100%"
        disabled
        bgColor="transparent"
        height="200px"
        boxShadow="none"
        value={absencePolicyDescription}
      />
      {!isPackagePL && (
        <p className="text-muted">Makeup Deadline: {formattedMakeupDeadline}</p>
      )}

      {isSDC && (
        <div className="mt-4">
          <h5>Reason for the absence</h5>
          <div>
            <Textarea
              name="body"
              value={absenceReason}
              onChange={handleAbsenceReasonChange}
              height={"100px"}
            />
          </div>
        </div>
      )}
      <CheckboxContainer className={"mt-4 d-flex align-items-center"}>
        <input
          checked={isPolicyAccepted}
          onChange={handlePolicyAcceptanceChange}
          type="checkbox"
          id="policyAccept"
          name="policyAccept"
        />
        <label className="ms-2" for="policyAccept">
          I understand the policy
        </label>
      </CheckboxContainer>
      <div className="mt-4 d-flex justify-content-center">
        <PrimaryButton onClick={handleConfirmPolicy}>
          {!loadingAbsence ? (
            "Confirm"
          ) : (
            <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
          )}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default AbsencePolicy;
