export const viewComponents = {
  classRoster: {
    name: "Class Roster",
    value: "class_roster",
  },
  classInfo: {
    name: "Class Info and makeup",
    value: "class_info",
  },
  paymentInfo: {
    name: "Payment Info",
    value: "payment_info",
  },
};
