import styled from "styled-components";

export const LessonsInfoSectionContainer = styled.div`
  margin: 20px 0 20px 0;
`;

export const Container = styled.div`
  background: #f6f6f69e;
  display: flex;
  width: 100%;
  overflow: hidden;
`;
export const RedBarContainer = styled.div`
  background: #681e46;
  width: 50px;
  color: #fff;
  writing-mode: vertical-lr;
  text-orientation: revert;
  display: flex;
  align-items: center;
  padding: 40px 26px;
`;
export const RedBarText = styled.p`
  font-size: 1.3rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
`;
export const HeaderContainer = styled.div`
  padding: 0 70px;
  padding-right: 35px;
`;

export const NavButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  justify-content: ${({ justifyRight }) =>
    justifyRight ? "flex-end" : "start"};
`;
export const LessonNavBtn = styled.button`
  padding: 5px;
  font-size: 18px;
  font-weight: 600;
  background-color: ${({ isWithdrawn }) =>
    isWithdrawn ? "rgb(218, 217, 215)" : "transparent"};
  color: ${({ isWithdrawn }) => (isWithdrawn ? "#747474" : "#151515")};
  border-radius: ${({ isWithdrawn }) => (isWithdrawn ? "5px" : "0px")};
  border: none;
  text-transform: capitalize;
  outline: none;
  box-shadow: none;

  &.active {
    color: #910050;
  }
  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
    color: #910050;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 35px 50px;
`;

export const InputWrapper = styled.div``;
export const CustomLabel = styled.label`
  font-weight: 500;
  margin-bottom: 10px;
`;
export const CustomTextarea = styled.textarea`
  display: block;
  border-radius: 15px;
  margin-top: 10px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "75px"};

  ::placeholder {
    color: #979797;
  }
`;
export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  padding: 12px;
  /* padding: 5px; */
  /* height:40px; */
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fff";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
  min-width: 100px;
`;
export const AbsencesSectionSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

//

export const AbsencesListContainer = styled.div`
  display: flex;
  margin-bottom: 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cacaca;
`;
export const AbsencesRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 35px;
`;
export const MakeUpRow = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const CustomInput = styled.input`
  border-radius: 15px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => width || "auto"};
  ::placeholder {
    color: #979797;
  }

  &[type="time"] {
    /* width: 180px; */
  }

  &:disabled {
    background-color: #fff;
  }
`;
export const ButtonContainer = styled.div`
  position: relative;
  margin-top: 15px;
`;
export const EllipseIcon = styled.span`
  position: absolute;
  top: -13px;
  right: 8px;
  font-size: 0.5rem;
  padding: 3px 10px;
  color: #fff;
  background: #681e46;
  border: 1px solid #ffffff;
  border-radius: 20px;
`;
