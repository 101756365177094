import moment from "moment";
import React from "react";
import { weekDays } from "src/constants/weekDays";
import {
  InfoContainer,
  TimelineContainer,
  DeleteIcon,
  EditIcon,
  InfoText,
} from "../../styled";

const TimelineItem = ({
  timelineObj,
  teachers,
  locations,
  handleEditTimelineClick,
  handleDeleteTimelineClick,
  showDeleteTimelineBtn,
  isReturnFromSummerBreak,
  isPastTimeline,
}) => {
  const teacherId = timelineObj.teacherId;
  const teacher = teachers.find(({ id }) => id === teacherId);

  const locationId = timelineObj.locationId;
  const location = locations?.find(({ id }) => id === locationId);

  const startDate = timelineObj.startDate;
  const lastDate = timelineObj.lastDate;

  const weekDay = moment(startDate).day();
  const lessonLength = parseInt(timelineObj.duration);

  const formattedStartDate = moment(startDate).format("MM-DD-YYYY");
  const formattedLastDate = lastDate
    ? moment(lastDate).format("MM-DD-YYYY")
    : "In Progress";

  const weekDayName = weekDays.find(({ id }) => id === weekDay)?.name;
  const startTime = moment(startDate).format("hh:mm A");
  const teacherName = teacher?.fullName;
  const locationName = location?.name;

  const lessonType = timelineObj.isVirtual ? "Virtual" : "In Person";

  return (
    <TimelineContainer
      borderTop={lastDate ? "4px solid #681e46" : "4px solid #3dba00"}
      className="mb-3 "
    >
      <div>
        <h5 className="text-center">
          {isReturnFromSummerBreak
            ? "Return From Summer Break"
            : isPastTimeline
            ? "Past Timeline"
            : "Timeline"}
        </h5>
      </div>
      <div className="d-flex justify-content-end mb-3" style={{ gap: 10 }}>
        <div>
          <EditIcon onClick={() => handleEditTimelineClick(timelineObj.id)} />
        </div>
        {showDeleteTimelineBtn && (
          <div>
            <DeleteIcon
              onClick={() => handleDeleteTimelineClick(timelineObj.id)}
            />
          </div>
        )}
      </div>
      <InfoContainer>
        <InfoText bold className="me-2">
          Started On:
        </InfoText>
        <InfoText>{formattedStartDate}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Last Date:
        </InfoText>
        <InfoText>{formattedLastDate}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Class Day:
        </InfoText>
        <InfoText>{weekDayName}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Lesson Time:
        </InfoText>
        <InfoText>{startTime}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Duration:
        </InfoText>
        <InfoText>{`${lessonLength} Minutes`}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Teacher Name:
        </InfoText>
        <InfoText>{teacherName}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Location:
        </InfoText>
        <InfoText>{locationName}</InfoText>
      </InfoContainer>
      <InfoContainer>
        <InfoText bold className="me-2">
          Type:
        </InfoText>
        <InfoText>{lessonType}</InfoText>
      </InfoContainer>
    </TimelineContainer>
  );
};

export default TimelineItem;
