import React, { useContext, useState } from "react";
import {
  SubmitHomeworkModalContext,
  ViewHomeworkModalContext,
} from "../../../common/Library/common/libraryContext";
import BaseNoteModal from "../BaseNoteModal";
import CustomDropDown from "../../../common/DropDown";
import {
  ALLOWED_EXTENSIONS,
  typesOptions,
} from "../../../common/Library/common/constants";
import FilesPreview from "../../../common/Library/new-item-modal/FilesPreview";
import LibraryFileUploadInput from "../../../common/Library/new-item-modal/LibraryFileUploadInput";
import {
  LibraryModalBody,
  LibraryModalButton,
  LibraryModalInput,
} from "../../../common/styled-components/teacherLibraryStyledComponents";
import { toast } from "react-toastify";
import { HomeworkTextareaContainer } from "../../../common/styled-components/lessonNotesStyledComponents";

const SubmitHomeworkModal = () => {
  const [recommendationLink, setRecommendationLink] = useState("");
  const [fileType, setFileType] = useState(typesOptions[0]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const { selectedUser, setSelectedUser } = useContext(
    ViewHomeworkModalContext
  );
  const [error, setError] = useState("");
  const { isOpen, close, submitHomework } = useContext(
    SubmitHomeworkModalContext
  );
  const [comment, setComment] = useState("");
  const onUploadFile = (e) => {
    const file = e.target.files[0];
    const { type } = file;
    const extension = type.split("/")[1];
    if (ALLOWED_EXTENSIONS.includes(extension)) {
      const fileObject = {
        file,
        name: `${fileType.label} | ${file.name}`,
        attachmentType: fileType.value,
      };
      setUploadFiles((previous) => [...previous, fileObject]);
      setFileType({ ...typesOptions[0] });
      setError("");
    } else {
      setError("File extension not supported");
    }
    e.target.value = "";
  };
  const onAddClick = () => {
    const fileObject = {
      name: `${fileType.label} | ${recommendationLink}`,
      recommendationLink,
      attachmentType: fileType.value,
    };
    setUploadFiles((previous) => [...previous, fileObject]);
    setFileType(typesOptions[0]);
    setError("");
    setRecommendationLink("");
  };
  const submit = () => {
    toast.info("Uploading homework...");
    toast.info(
      "Saving in background, please don't refresh or close the page until finished"
    );
    submitHomework({ attachments: uploadFiles, comment });
  };
  const removeFile = (file) => {
    setUploadFiles((previous) => {
      const index = previous.indexOf(file);
      previous.splice(index, 1);
      return [...previous];
    });
  };
  const onFileOptionChange = (option) => {
    setFileType(option);
  };
  const isDisabled = () => {
    return !uploadFiles.length;
  };
  const modalBody = () => (
    <LibraryModalBody className={"pb-0"}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="col-6 pe-2">
          <CustomDropDown
            label={fileType.label}
            value={fileType.label}
            // header={"Attachment Type"}
            keyName={"label"}
            options={typesOptions}
            onOptionClick={onFileOptionChange}
          />
        </div>
        <div className="col-6 pe-2">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              {fileType.id === "recommendationLinks" ? (
                <div className={"d-flex align-items-center"}>
                  <div>
                    <LibraryModalInput
                      formik={false}
                      // label={"Recommendation Link"}
                      name="Recommendation Link"
                      placeholder="Recommendation Link"
                      height={"40px"}
                      size={12}
                      value={recommendationLink}
                      customStyle={{
                        marginBottom: 0,
                      }}
                      onChange={(e) => setRecommendationLink(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={onAddClick}
                    className={"btn btn-light d-flex align-items-center ms-2"}
                  >
                    <i className="fa fa-plus me-2" />
                    <span>Add</span>
                  </button>
                </div>
              ) : (
                <LibraryFileUploadInput
                  onUploadFile={onUploadFile}
                  customStyle={{
                    height: 40,
                    marginBottom: 0,
                  }}
                  label={"Upload"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-muted mb-2">
        Supported extensions: {ALLOWED_EXTENSIONS.join(", ")}
      </div>
      {error !== "" && <div className={"alert alert-danger mb-2"}>{error}</div>}
      <h6 className="text-muted">Comment</h6>
      <HomeworkTextareaContainer
        className="mb-2"
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
      <p>The teacher will reply to you before the lesson</p>
      <FilesPreview files={uploadFiles} onRemove={removeFile} />
    </LibraryModalBody>
  );
  return (
    <BaseNoteModal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Student Homework"}
      isOpen={isOpen}
      actionButtonColor={"success"}
      buttonText={"Submit"}
      okAction={submit}
      toggle={close}
    />
  );
};
export default SubmitHomeworkModal;
