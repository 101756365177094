import moment from "moment";
import React from "react";
import { getAgeFromDate } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import useLessonsData from "../../Hooks/useLessonsData";
import { CustomTextarea } from "src/utils/shared/styled";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import PackageLessonInfo from "./PackageLessonInfo";
import RegularLessonInfo from "./RegularLessonInfo";

const LessonSection = ({ Userlesson }) => {
  const { type } = Userlesson || {};
  const isPackageLsn = isPackagePrivateLesson(type);

  return (
    <div>
      {isPackageLsn ? (
        <PackageLessonInfo lesson={Userlesson} />
      ) : (
        <RegularLessonInfo lesson={Userlesson} />
      )}
    </div>
  );
};

export default LessonSection;
