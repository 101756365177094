import moment from "moment";
import React from "react";
import { useContext } from "react";
import SettingsRow from "src/components/common/SettingsRow";
import ConfirmationModal from "src/components/Dashboard/common/components/ConfirmationModal";
import { TrialLessonsContext } from "src/components/Dashboard/TrialLessons/TrialLessons";
import {
  isCanceledTrialLesson,
  trialLessonStatuses,
} from "src/constants/trialLessonStatuses";
import { weekDays } from "src/constants/weekDays";
import useModal from "src/hooks/useModal";
import {
  CustomInput,
  CustomTextarea,
  PrimaryButton,
} from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import { FormContainer, InputWrapper } from "../../../styled";

const LessonsInfoContent = ({
  selectedLesson,
  openEditLessonModal,
  handleTrialLessonUpdate,
  followUpNote,
  setFollowUpNote,
  handleDeleteTrialLesson,
}) => {
  const { instruments, teachers, programs, locations } =
    useContext(TrialLessonsContext);

  const deleteConfirmationModalData = useModal();
  const activationConfirmationModalData = useModal();

  const isCanceledLesson = isCanceledTrialLesson(selectedLesson?.status);

  const selectedLessonInstrumentName =
    instruments[selectedLesson?.instrument]?.name;

  const lessonTeacher = teachers[selectedLesson?.teacherId];

  const teacherProgram = Object.values(programs).find(
    ({ id }) =>
      id ===
      lessonTeacher?.instrumentsInfo?.find(
        (info) => info.instrument === selectedLesson?.instrument
      )?.program
  );

  const formattedLessonDate = moment(selectedLesson?.date).format("MM/DD/YYYY");
  const lessonWeekDay = moment(selectedLesson?.date).day();
  const lessonStartTime = moment(selectedLesson?.date).format("HH:mm");
  const lessonLength = selectedLesson?.lessonLength;

  const lessonLocationName = Object.values(locations).find(
    ({ id }) => id === selectedLesson?.locations?.[0]
  )?.name;

  const lessonType = selectedLesson?.isVirtual ? "Virtual" : "In Person";

  const isPaidStr = selectedLesson?.isPaid ? "Paid" : "Not Paid";
  const lessonStatusStr = isCanceledLesson ? "Is Canceled" : "Is Active";

  const isFollowedUp = selectedLesson?.isFollowedUp ? true : false;
  const isSignedUp = selectedLesson?.isSignedUp ? true : false;

  const activationModalTitle = isCanceledLesson
    ? "Activate Lesson ?"
    : "Cancel Lesson ?";

  const onDeleteModalApprove = async () => {
    await handleDeleteTrialLesson();
    deleteConfirmationModalData.closeModal();
  };
  const onDeleteModalCancel = () => {
    deleteConfirmationModalData.closeModal();
  };
  const onActivationModalApprove = async () => {
    isCanceledLesson
      ? await handleTrialLessonUpdate("status", trialLessonStatuses.active.code)
      : await handleTrialLessonUpdate(
          "status",
          trialLessonStatuses.canceled.code
        );
    activationConfirmationModalData.closeModal();
  };
  const onActivationModalCancel = () => {
    activationConfirmationModalData.closeModal();
  };
  return (
    <FormContainer>
      <InputWrapper>
        <CustomLabel>Instrument:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="instrumentId"
          className="form-control"
          value={selectedLessonInstrumentName || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Program:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="text"
          className="form-control"
          value={teacherProgram?.name || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Teacher:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          name="teacherId"
          type="text"
          className="form-control"
          value={lessonTeacher?.fullName || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Class Day:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          name="classDay"
          type="text"
          className="form-control"
          value={weekDays.find(({ id }) => id === lessonWeekDay)?.name || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Date:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          name="classDay"
          type="text"
          className="form-control"
          value={formattedLessonDate || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Lesson Length:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          name="lessonLength"
          type="text"
          className="form-control"
          value={`${lessonLength} Minutes`}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Lesson Time:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="time"
          className="form-control"
          name="lessonTime"
          value={lessonStartTime}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Location:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="text"
          className="form-control"
          name="locationId"
          value={lessonLocationName || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Type:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="text"
          className="form-control"
          name="type"
          value={lessonType || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Is Paid:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="text"
          className="form-control"
          name="isPaid"
          value={isPaidStr}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Fee:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="text"
          className="form-control"
          name="fee"
          value={selectedLesson?.fee || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Date Of Payment:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="text"
          className="form-control"
          name="dateOfPayment"
          value={selectedLesson?.dateOfPayment || "N/A"}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomLabel>Lesson Status:</CustomLabel>
        <CustomInput
          disabled
          width="200px"
          type="text"
          className="form-control"
          name="status"
          value={lessonStatusStr}
        />
      </InputWrapper>
      <InputWrapper className="mt-4">
        <div className="mb-3 d-flex align-items-center">
          <CustomLabel className="mb-0">Lesson Note (For Teacher):</CustomLabel>
        </div>
        <CustomTextarea
          bgColor="transparent"
          width="650px"
          height="130px"
          className="form-control"
          name="note"
          disabled
          value={selectedLesson?.note}
        />
      </InputWrapper>
      <div className="w-100">
        <SettingsRow
          description={"Signed Up"}
          isOn={isSignedUp}
          onChange={async () => {
            await handleTrialLessonUpdate("isSignedUp", !isSignedUp);
          }}
        />
      </div>
      <div className="w-100">
        <SettingsRow
          description={"Followed Up"}
          isOn={isFollowedUp}
          onChange={async () => {
            await handleTrialLessonUpdate("isFollowedUp", !isFollowedUp);
          }}
        />
      </div>
      <InputWrapper className="mt-4">
        <div className="mb-3 d-flex align-items-center">
          <CustomLabel className="mb-0">
            Follow Up Note (For Admins):
          </CustomLabel>
          <div className="ms-3 d-flex">
            <PrimaryButton
              backgroundColor="#e357a2"
              onClick={() =>
                handleTrialLessonUpdate("followUpNote", followUpNote)
              }
              width="145px"
              padding="6px"
            >
              Save Note
            </PrimaryButton>
          </div>
        </div>
        <CustomTextarea
          bgColor="transparent"
          width="650px"
          height="130px"
          className="form-control"
          name="note"
          value={followUpNote}
          onChange={(e) => setFollowUpNote(e.target.value)}
        />
      </InputWrapper>

      <div className="mt-4 m-auto d-flex w-100 justify-content-evenly">
        <div>
          <PrimaryButton
            onClick={() => {
              openEditLessonModal();
            }}
          >
            Edit
          </PrimaryButton>
        </div>
        <div>
          <PrimaryButton onClick={activationConfirmationModalData.openModal}>
            {isCanceledLesson ? "Activate Lesson" : "Cancel Lesson"}
          </PrimaryButton>
        </div>
        <div>
          <PrimaryButton
            onClick={() => {
              deleteConfirmationModalData.openModal();
            }}
          >
            Delete Lesson
          </PrimaryButton>
        </div>
      </div>

      <ConfirmationModal
        modalData={deleteConfirmationModalData}
        onApprove={onDeleteModalApprove}
        onCancel={onDeleteModalCancel}
        isSubmitting={false}
        title="Permanently Delete Trial Lesson ?"
        approveBtnText="Delete"
        cancelBtnText="Cancel"
      />
      <ConfirmationModal
        modalData={activationConfirmationModalData}
        onApprove={onActivationModalApprove}
        onCancel={onActivationModalCancel}
        isSubmitting={false}
        title={activationModalTitle}
        approveBtnText="Confirm"
        cancelBtnText="Cancel"
      />
    </FormContainer>
  );
};

export default LessonsInfoContent;
