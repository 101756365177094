import {Spinner} from "../../index";
import React, {useContext, useState} from "react";
import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalButton,
  LibraryModalHeader,
} from "../../styled-components/teacherLibraryStyledComponents";
import {
  ActiveTabContext,
  LoadingContext,
  ShareItemModalContext,
  TeachersContext,
  TeachersSelectContext,
} from "../common/libraryContext";
import ShareModalTeacherView from "./ShareModalTeacherView";
import ShareModalStudentView from "./ShareModalStudentView";

const ShareItemModal = () => {
  const { loading } = useContext(LoadingContext);
  const {isTeacher} = useContext(ActiveTabContext);
  const { isOpen, close, submit } = useContext(ShareItemModalContext);
const {teachers} = useContext(TeachersContext);
  const [view, setView] = useState(teachers.length === 0 ? 'school':"teacher");
  const [email, setEmail] = useState("");

  const isDisabled = () => {
    switch (view) {
      case 'email':
        return email.length === 0;
      case 'teacher':
      case 'school':
        return false;
    }
  }
  return (
    <LibraryModal className={"p-0"} size={"lg"} centered isOpen={isOpen}>
      <LibraryModalHeader
        close={
          <button className="close" onClick={close}>
            <i className="fas fa-times" />
          </button>
        }
      >
        Share
      </LibraryModalHeader>
      <LibraryModalBody className={"p-0"}>
          {isTeacher ? <ShareModalTeacherView /> : <ShareModalStudentView
          view={view}
          setView={setView}
          email={email}
          setEmail={setEmail}
          /> }
        <LibraryModalButton
          type={"submit"}
          onClick={submit}
          disabled={loading}
          className={
            "d-flex font-weight-bold mx-auto  text-white align-items-center justify-content-center"
          }
        >
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <div className={"d-flex align-items-center justify-content-center"}>
              <span>Share</span>
              <i className="ms-2 fas fa-share" />
            </div>
          )}
        </LibraryModalButton>
      </LibraryModalBody>
    </LibraryModal>
  );
};
export default ShareItemModal;
