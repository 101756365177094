import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  concertProgramTypes,
  concertSignupOptions,
} from "src/constants/concertEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { v4 as uuidv4 } from "uuid";

const useFreeForAllConcertSignup = ({ user, concertId, onActionSuccess }) => {
  const { createConcertSignup } = useFirebaseFns();

  const [loadingAction, setLoadingAction] = useState(false);

  async function createUserSignup() {
    try {
      if (!concertId || !user) {
        throw new Error("Missing info");
      }
      setLoadingAction(true);
      const signupObj = {
        createdAt: new Date(),
        userId: user.uid,
        concertId,
        programGroupId: uuidv4(),
        signupOption: {
          type: concertSignupOptions.FREE_FOR_ALL_STUDENTS,
        },
        programType: concertProgramTypes.SOLO_PROGRAM,
      };

      const signupResponse = await createConcertSignup(signupObj);
      if (signupResponse.id) {
        onActionSuccess();
        toast.success("Enrolled Successfully");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }
  return { loadingAction, createUserSignup };
};

export default useFreeForAllConcertSignup;
