import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
import { CustomInput, PrimaryButton } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalButton,
  LibraryModalHeader,
} from "../../styled-components/teacherLibraryStyledComponents";
import { RenameItemContext } from "../common/libraryContext";

const RenameItemModal = () => {
  const { state, close, renameItem } = useContext(RenameItemContext);
  const { isOpen, item } = state;

  const [newTitle, setNewTitle] = useState("");

  const handleRenameBtnClick = async () => {
    if (!newTitle) {
      toast.warn("Title cant be empty");
      return;
    }
    await renameItem(item, newTitle);
    setNewTitle("");
  };

  return (
    <LibraryModal className={"p-0"} size={"md"} centered isOpen={isOpen}>
      <LibraryModalHeader
        close={
          <button className="close" onClick={close}>
            <i className="fas fa-times" />
          </button>
        }
      >
        Rename
      </LibraryModalHeader>
      <LibraryModalBody className={"p-0"}>
        <div className="mt-4 mb-5 d-flex justify-content-center w-100">
          <div style={{ width: "80%" }}>
            <CustomLabel>New Title</CustomLabel>
            <CustomInput
              width="100%"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-4 text-center">
          <PrimaryButton width="80%" onClick={handleRenameBtnClick}>
            Rename
          </PrimaryButton>
        </div>
      </LibraryModalBody>
    </LibraryModal>
  );
};

export default RenameItemModal;
