import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";

export const adminsExtraFunctionsRows = [
  {
    description: "Create Teachers",
    propName: adminsExtraFunctions.createTeachers,
  },
  {
    description: "Create Feed Items",
    propName: adminsExtraFunctions.createFeedItems,
  },
  {
    description: "Manage Teachers Studios",
    propName: adminsExtraFunctions.manageTeachersStudios,
  },
  {
    description: "Manage Users Payment",
    propName: adminsExtraFunctions.manageUsersPayments,
  },
  {
    description: "Manage Student Actions",
    propName: adminsExtraFunctions.manageStudentActions,
  },
  {
    description: "Manage Parent Actions",
    propName: adminsExtraFunctions.manageParentActions,
  },
];
