import React from "react";
import {
  ColumnContainer,
  CustomButton,
  CustomButtonsWrapper,
  InputContainer,
  InputsRow,
  MainContainer,
} from "../../styled";
import LabeledInput from "../common/LabeledInput";
import AttachmentsSection from "../AttachmentsSection";
import LabeledDateInput from "../common/LabeledDateInput";
import LabeledSelectField from "../common/LabeledSelectField";
import {
  concertAgeGroupTypes,
  concertInstrumentTypes,
  concertTeachingStudioTypes,
} from "src/constants/concertEnum";
import LabeledRadioInput from "../common/LabeledRadioInput";
import { concertViews } from "../../constants";
import LabeledTextarea from "../common/LabeledTextarea";

const FirstPage = ({ closeModal, hookData }) => {
  const {
    concertData,
    handleConcertDataChange,
    teachingStudiosSelectOptions,
    teachingStudiosCurrentOption,
    levelsSelectOptions,
    levelCurrentOption,
    setCurrentView,
    instrumentsSelectOptions,
    instrumentsCurrentOption,
  } = hookData;

  function onAttachmentsChange(newAttachments) {
    handleConcertDataChange("attachments", newAttachments);
  }
  return (
    <div>
      <MainContainer>
        <ColumnContainer>
          {/* Title */}
          <InputsRow>
            <LabeledInput
              label="Concert Title"
              type="text"
              value={concertData.title}
              onChange={(e) => handleConcertDataChange("title", e.target.value)}
            />
          </InputsRow>

          {/* Start/End Time */}
          <InputsRow>
            <LabeledInput
              label="Start Time"
              type="time"
              value={concertData.startTime}
              onChange={(e) =>
                handleConcertDataChange("startTime", e.target.value)
              }
            />
            <LabeledInput
              label="End Time"
              type="time"
              value={concertData.endTime}
              onChange={(e) =>
                handleConcertDataChange("endTime", e.target.value)
              }
            />
          </InputsRow>

          {/* Level */}
          <InputsRow>
            <LabeledSelectField
              label="Level"
              placeholder="Select Level"
              isMulti={false}
              options={levelsSelectOptions}
              value={levelCurrentOption}
              onChange={(selected) => {
                const value = selected.value;
                handleConcertDataChange("level", value);
              }}
            />
          </InputsRow>

          {/* Age limitation */}
          <InputsRow>
            <LabeledRadioInput
              label="Age Limitation"
              options={[
                { title: "Any", value: concertAgeGroupTypes.ANY },
                {
                  title: "Limited",
                  value: concertAgeGroupTypes.LIMITED,
                },
              ]}
              onChange={(currVal) =>
                handleConcertDataChange("ageGroup.type", currVal)
              }
              value={concertData.ageGroup.type}
            />
          </InputsRow>
          <InputsRow>
            <LabeledInput
              withLabel={false}
              placeholder="Min"
              disabled={concertData.ageGroup.type === concertAgeGroupTypes.ANY}
              // label="Min"
              type="number"
              value={concertData.ageGroup.min}
              onChange={(e) =>
                handleConcertDataChange("ageGroup.min", e.target.value)
              }
            />
            <LabeledInput
              withLabel={false}
              placeholder="Max"
              disabled={concertData.ageGroup.type === concertAgeGroupTypes.ANY}
              // label="Max"
              type="number"
              value={concertData.ageGroup.max}
              onChange={(e) =>
                handleConcertDataChange("ageGroup.max", e.target.value)
              }
            />
          </InputsRow>

          {/* attachments */}
          <div>
            <AttachmentsSection
              attachments={concertData.attachments}
              onAttachmentsChange={onAttachmentsChange}
            />
          </div>
        </ColumnContainer>

        {/*  */}
        <ColumnContainer>
          {/* Date */}
          <InputsRow>
            <LabeledDateInput
              label={"Date"}
              value={concertData.date}
              onChange={(newDate) => handleConcertDataChange("date", newDate)}
            />
          </InputsRow>

          {/* Location */}
          <InputsRow>
            <LabeledTextarea
              label="Concert's Location"
              placeholder="Location"
              onChange={(e) => {
                handleConcertDataChange("address", e.target.value);
              }}
            />
          </InputsRow>

          {/* Teaching Studios */}
          <InputsRow>
            <LabeledRadioInput
              label="Teaching Studios"
              options={[
                { title: "Any", value: concertTeachingStudioTypes.ALL },
                {
                  title: "Selection",
                  value: concertTeachingStudioTypes.SPECIFIC,
                },
              ]}
              onChange={(currVal) =>
                handleConcertDataChange("teachingStudios.type", currVal)
              }
              value={concertData.teachingStudios.type}
            />
          </InputsRow>
          <InputsRow>
            <LabeledSelectField
              isDisabled={
                concertData.teachingStudios.type ===
                concertTeachingStudioTypes.ALL
              }
              withLabel={false}
              // label="Teaching Studios"
              placeholder="Select Teaching Studios"
              options={teachingStudiosSelectOptions}
              value={teachingStudiosCurrentOption}
              onChange={(newTeachers) => {
                const idsValue = newTeachers.map(({ value }) => value);
                handleConcertDataChange("teachingStudios.value", idsValue);
              }}
            />
          </InputsRow>

          {/* Deadline */}
          <InputsRow>
            <LabeledRadioInput
              label="Deadline for Sign Up"
              options={[
                { title: "Yes", value: true },
                {
                  title: "No",
                  value: false,
                },
              ]}
              onChange={(currVal) =>
                handleConcertDataChange("signupDeadline.isEnabled", currVal)
              }
              value={concertData.signupDeadline.isEnabled}
            />
          </InputsRow>
          <InputsRow>
            <LabeledDateInput
              disabled={!concertData.signupDeadline.isEnabled}
              label={"Deadline"}
              value={concertData.signupDeadline.value}
              onChange={(newDate) =>
                handleConcertDataChange("signupDeadline.value", newDate)
              }
            />
          </InputsRow>

          <InputsRow>
            <LabeledRadioInput
              label="Instruments"
              options={[
                { title: "All", value: concertInstrumentTypes.ALL },
                {
                  title: "Selection",
                  value: concertInstrumentTypes.LIMITED,
                },
              ]}
              onChange={(currVal) =>
                handleConcertDataChange("instruments.type", currVal)
              }
              value={concertData.instruments.type}
            />
          </InputsRow>
          <InputsRow>
            <LabeledSelectField
              isDisabled={
                concertData.instruments.type === concertInstrumentTypes.ALL
              }
              withLabel={false}
              placeholder="Select Instruments"
              options={instrumentsSelectOptions}
              value={instrumentsCurrentOption}
              onChange={(newInstruments) => {
                const idsValue = newInstruments.map(({ value }) => value);
                handleConcertDataChange("instruments.value", idsValue);
              }}
            />
          </InputsRow>

          {/* isVirtual */}
          <InputsRow>
            <LabeledRadioInput
              label="Virtual"
              options={[
                { title: "Yes", value: true },
                {
                  title: "No",
                  value: false,
                },
              ]}
              onChange={(currVal) =>
                handleConcertDataChange("isVirtual", currVal)
              }
              value={concertData.isVirtual}
            />
          </InputsRow>
        </ColumnContainer>
      </MainContainer>
    </div>
  );
};

export default FirstPage;
