import React from "react";
import RouteContent from "../../common/RouteContent";
import AdminCheckIn from "./AdminCheckIn";
const AdminCheckInRoute = ({ user }) => {
  return (
    <RouteContent title={"Check In"}>
      <AdminCheckIn user={user} />
    </RouteContent>
  );
};
export default AdminCheckInRoute;
