import "./style.css";
import { useNavigate } from "react-router-dom";

const IconWrapper = ({ children, name }) => {
  const navigate = useNavigate();
  const onClickHandler = (e) => {
    e.preventDefault();
    navigate(`/our-team?nameForIconFilter=${name}`);
  };
  return (
    <div className="iconWrapper" onClick={onClickHandler}>
      {children}
    </div>
  );
};

export default IconWrapper;
