import React from "react";
import RouteContent from "../../common/RouteContent";
import AdminAttendanceList from "./AdminAttendanceList";
const AdminAttendanceListRoute = ({ user }) => {
  return (
    <RouteContent title={"Attendance List"}>
      <AdminAttendanceList user={user} />
    </RouteContent>
  );
};
export default AdminAttendanceListRoute;
