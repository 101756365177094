import styled, { css } from "styled-components";

export const ImageContainer = styled.div`
  margin: 30px;
  margin-left: 0;
  margin-top: 0;
`;
export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 55px;
  height: 55px;
  object-fit: cover;
`;
export const Header = styled.h4`
  font-size: 1.4rem;
`;
export const InfoText = styled.p`
  margin-bottom: 2px;
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  /* padding-right: 35px; */
`;
