import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { addDoc, setDoc, Timestamp } from "firebase/firestore";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import { UserRole } from "src/constants/UserRoleEnum";

const useDraftStudent = () => {
  const firebase = useContext(FirebaseContext);
  const { secondaryApp } = firebase;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkIfStudentCanBeSavedAsDraft = (values) => {
    if (!values) return false;

    // const canBeSaved = Object.keys(values).some((key) => {
    //   const isValueExist = !!values[key];
    //   return isValueExist;
    // });

    const canBeSaved = !!values.primaryEmail;

    return canBeSaved;
  };

  const saveStudentAsDraft = async (values, onSuccess) => {
    try {
      setIsSubmitting(true);
      const canBeSaved = checkIfStudentCanBeSavedAsDraft(values);
      if (!canBeSaved) {
        toast.warn(
          "Please Fill At Least The Primary Email Field To Save A Draft Student"
        );
        return;
      }

      console.log("SAVING AS DRAFT", { values });
      const auth = getAuth(secondaryApp);

      const lowerCaseEmail = values.primaryEmail.toLowerCase();
      const authUser = await createUserWithEmailAndPassword(
        auth,
        lowerCaseEmail,
        process.env.REACT_APP_USERS_GLOBAL_PASSWORD
      );
      if (!authUser.user.uid) return;

      const studentObj = {
        ...values,
        createdAt: Timestamp.now(),
        role: UserRole.STUDENT,
        active: true,
        primaryEmail: lowerCaseEmail,
      };
      await setDoc(firebase.user(authUser.user.uid), studentObj);
      toast.success("Draft Student Created Successfully");

      const createdDoc = {
        id: authUser.user.uid,
        ...studentObj,
      };

      onSuccess(createdDoc);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    checkIfStudentCanBeSavedAsDraft,
    saveStudentAsDraft,
    isSubmitting,
    setIsSubmitting,
  };
};

export default useDraftStudent;
