import {
  StyledModal,
  ButtonsWrapper,
  ModalText,
} from "./ConfirmDeleteModal.styled";
import { ModalBody, ModalFooter } from "reactstrap";
import { PrimaryButton } from "src/utils/shared/styled";

const ConfirmDeleteModal = ({ open, onConfirm, onCancel, text }) => {
  return (
    <StyledModal isOpen={open} centered unmountOnClose>
      <ModalBody>
        <ModalText>{text}</ModalText>
      </ModalBody>
      <ModalFooter>
        <ButtonsWrapper>
          <PrimaryButton
            type="button"
            onClick={onCancel}
            backgroundColor="#681e4677"
            width="50%"
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            type="button"
            onClick={onConfirm}
            backgroundColor="#681e46DD"
            width="50%"
          >
            Confirm
          </PrimaryButton>
        </ButtonsWrapper>
      </ModalFooter>
    </StyledModal>
  );
};

export default ConfirmDeleteModal;
