import styled from "styled-components";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";

export const CustomModal = styled(Modal)`
  min-width: ${({ modalWidth }) => (modalWidth ? modalWidth : "480px")};
  max-width: ${({ modalWidth }) => (modalWidth ? modalWidth : "480px")};
  & .modal-content {
    border: none;
    border-radius: 30px;
  }
`;
export const CustomHeader = styled(ModalHeader)`
  border-radius: 30px 30px 0 0;
`;
export const CustomBody = styled(ModalBody)`
  margin-top: 20px;
  min-height: 200px;
  padding: 0 !important;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;

export const Container = styled.div`
  background: #f6f6f69e;
  display: flex;
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`;
export const CustomLabel = styled.label`
  font-weight: 500;
  margin-bottom: 10px;
`;
export const CustomInput = styled.input`
  display: block;
  border-radius: 15px;
  padding: 10px;
  font-weight: 500;
  box-shadow: 0px 0px 7px 0px #e3e3e3;
  border: none;
  width: ${({ width }) => width || "auto"};
  ::placeholder {
    color: #979797;
  }
`;
export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  padding: 12px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fff";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;

export const CustomButtonsWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const CustomButton = styled.button`
  border: none;
  outline: none;
  background: #fff;
  color: ${({ primary }) => (primary ? "#681e46" : "grey")};
`;
