import "./style.css";
import React from "react";
import { GrWorkshop } from "react-icons/gr";

function MusicRecitals() {
  return (
    <article className="music-recitals">
      <h2 className="second-heading">Music Recitals and Workshops <GrWorkshop /> </h2>
      <br>
      </br>
      <p>
        Two times annually fun and seasonal recitals each year. The recitals
        provide rich and enjoyable music experience to students that will
        contribute to the total development of self-confidence. It will also
        provide students with a short-term goal, which can bring their level of
        achievement to a higher standard of excellence.
        <br /> <br /> In the course of the year, we offer free music workshops
        for students to participate. It builds student’s music skills in
        listening, music history, sight-reading famous music repertoires, and
        music improvisation.
      </p>
    </article>
  );
}

export default MusicRecitals;
