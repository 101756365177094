import moment from "moment";
import { useContext } from "react";
import { AttendanceContext } from "src/components/common/contexts/AdminAttendanceContext";
import { AttendanceBlockContainer } from "../../common/styled-components/attendanceStylesComponents";

const StudentAttendanceBlock = ({attendance}) => {
  const {locations} = useContext(AttendanceContext);
  const currentLocation = locations.find(({id}) => attendance.locationId === id) || {};
  return (
      <AttendanceBlockContainer className="d-flex m-2 py-3 px-4 student-block text-white flex-column align-items-center justify-content-center">
          <h4>{moment(attendance.attendanceDate, "MM-DD-YYYY hh:mmA").format("MM-DD-YYYY")}</h4>
          <span>{currentLocation.name}</span>
          <i className=" fa fa-check" />
      </AttendanceBlockContainer>
  )
}
export default StudentAttendanceBlock;
