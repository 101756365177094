import React, { useState } from "react";
import { PrimaryButton } from "../../../../../../../utils/shared/styled";
import {
  BodyContainer,
  ColorOption,
  ColorPalleteContainer,
  CustomInput,
  CustomLabel,
  CustomText,
  ErrorMessage,
  FormGroup,
  SpinnerWrapper,
} from "../styled";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import {
  positiveIntegerValidationSchema,
  requiredStringSchema,
} from "../../../../../../../utils/validations";
import moment from "moment";
import { SubmitSpinner } from "../../../../../../common";
import { validateAppointment } from "../../../helperFns";
import { toast } from "react-toastify";
import {
  getFullDateFromDateAndTimeInputs,
  updatedMomentNow,
} from "src/utils/helpers";
import { appointmentTypes } from "../constants";
import { UserRole } from "src/constants/UserRoleEnum";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { eventsMap } from "src/constants/eventsEnum";
import { v4 as uuidv4 } from "uuid";

const colorsPallete = [
  {
    color: "#F44336",
  },
  {
    color: "#E91E63",
  },
  {
    color: "#9C27B0",
  },
  {
    color: "#673AB7",
  },
  {
    color: "#3F51B5",
  },
  {
    color: "#2196F3",
  },
  {
    color: "#FFEB3B",
  },
  {
    color: "#FF9800",
  },
  {
    color: "#FF5722",
  },
  {
    color: "#795548",
  },
];

const AppointmentModalBody = ({
  modalData,
  selectedSlotInfo,
  appointmentData,
  refreshSelectedTeachersEvents,
  locations,
  UserStore,
}) => {
  const user = UserStore.user;
  // the form type (either appointment or a calendar label)
  const [type, setType] = useState(appointmentTypes.appointment);

  // in case type is calendar label , this specifies whether it's a single time label or recurring
  const [labelType, setLabelType] = useState(eventsMap.calendarLabel.code);

  const handleTypeChange = (e) => {
    setType(parseInt(e.target.value));
  };

  const { start, end, resource } = selectedSlotInfo;
  const {
    createAppointmentAndActivity,
    loadingAppointmentCreation,
    createLabel,
    initialData,
  } = appointmentData;
  //Getting initial values
  const initialStartTime = moment(start).format("HH:mm");
  const initialEndTime = moment(end).format("HH:mm");
  const initialDate = moment(start).format("YYYY-MM-DD");

  const appointmentValidationSchema = yup.object().shape({
    title: requiredStringSchema,
    date: requiredStringSchema,
    ...(type === appointmentTypes.appointment && {
      startTime: requiredStringSchema,
      endTime: requiredStringSchema,
      locationId: requiredStringSchema,
      numberOfIterations: positiveIntegerValidationSchema,
    }),
  });
  return (
    <Formik
      initialValues={{
        title: "",
        locationId: "",
        date: initialDate,
        startTime: initialStartTime,
        endTime: initialEndTime,
        note: "",
        eventColor: "",
        numberOfIterations: 1,
      }}
      validationSchema={appointmentValidationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          setSubmitting(true);

          const newAppointments = [];
          if (type === appointmentTypes.appointment) {
            const iterationsArray = Array.from(
              Array(parseInt(values.numberOfIterations)).keys()
            );
            for (const iteration of iterationsArray) {
              const startDate = moment(
                getFullDateFromDateAndTimeInputs(values.date, values.startTime)
              )
                .add(iteration, "weeks")
                .toDate();
              const endDate = moment(
                getFullDateFromDateAndTimeInputs(values.date, values.endTime)
              )
                .add(iteration, "weeks")
                .toDate();
              console.log({ iteration, startDate, endDate });

              const appointmentObj = {
                title: values.title,
                locationId: values.locationId,
                startDate,
                endDate,
                note: values.note,
                eventColor: values.eventColor,
                teacherId: resource.resourceId,
              };
              const {
                isValid: isValidAppointmentDate,
                message: validationErrorMessage,
              } = validateAppointment(
                appointmentObj,
                initialData.userActivities,
                initialData.absences
              );

              if (!isValidAppointmentDate) {
                toast.warn(validationErrorMessage);
                return;
              }
              newAppointments.push(appointmentObj);
            }
            //creates the appointment + the activity
            await Promise.all(
              newAppointments.map((appointmentObj) =>
                createAppointmentAndActivity(appointmentObj)
              )
            );
            toast.success("Appointment Created Successfully");
            refreshSelectedTeachersEvents();
            modalData.closeModal();
          } else {
            let labelObj;
            if (labelType === eventsMap.calendarLabel.code) {
              labelObj = {
                createdAt: new Date(),
                title: values.title,
                date: moment(values.date).toDate(),
                note: values.note,
                eventColor: values.eventColor,
                userId: resource.resourceId,
                userRole: UserRole.TEACHER,
                requestedBy: user.uid,
                type: eventsMap.calendarLabel.code,
              };
            } else {
              const timelineObjId = uuidv4();
              labelObj = {
                createdAt: new Date(),
                eventColor: values.eventColor,
                userId: resource.resourceId,
                userRole: UserRole.TEACHER,
                type: eventsMap.recurringCalendarLabel.code,

                timeline: [
                  {
                    id: timelineObjId,
                    date: moment(values.date).toDate(),
                    title: values.title,
                    note: values.note,
                    requestedBy: user.uid,
                    requestedAt: new Date(),
                  },
                ],
              };
            }
            console.log({ labelObj });

            await createLabel(labelObj);
            toast.success("Calendar Label Created Successfully");
            refreshSelectedTeachersEvents();
            modalData.closeModal();
          }
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        touched,
        isSubmitting,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => (
        <BodyContainer>
          <h3 className="text-center mb-4">
            {resource?.fullName || "Unknown"}
          </h3>
          <Form>
            <FormGroup className="form-group">
              <CustomLabel>Type:</CustomLabel>
              <label>
                <input
                  className="m-4 me-0"
                  value={appointmentTypes.appointment}
                  type="radio"
                  name="type"
                  id="appointment-option"
                  checked={type === appointmentTypes.appointment}
                  onChange={handleTypeChange}
                />{" "}
                Appointment
              </label>
              <label>
                <input
                  className="m-4 me-0"
                  value={appointmentTypes.label}
                  type="radio"
                  name="type"
                  checked={type === appointmentTypes.label}
                  onChange={handleTypeChange}
                />{" "}
                Calendar Label
              </label>
            </FormGroup>
            {/* Title */}
            <FormGroup className="form-group">
              <CustomLabel htmlFor="appointmentTitle">Add Title:</CustomLabel>
              <CustomInput
                type="text"
                className="form-control"
                id="appointmentTitle"
                placeholder="Title"
                name="title"
              />
              {errors.title && touched.title && (
                <ErrorMessage>{errors.title}</ErrorMessage>
              )}
            </FormGroup>
            {/* Location */}
            {type === appointmentTypes.appointment && (
              <FormGroup className="form-group">
                <CustomLabel htmlFor="appointmentTitle">Location:</CustomLabel>
                <CustomInput
                  className="form-control"
                  id="appointmentLocation"
                  placeholder="Location"
                  name="locationId"
                  component="select"
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </CustomInput>
                {errors.locationId && touched.locationId && (
                  <ErrorMessage>{errors.locationId}</ErrorMessage>
                )}
                {/* Date */}
              </FormGroup>
            )}
            <FormGroup className="form-group">
              <CustomLabel htmlFor="appointmentDate">Date:</CustomLabel>
              <CustomInput
                type="date"
                className="form-control"
                id="appointmentDate"
                name="date"
              />
              {errors.date && touched.date && (
                <ErrorMessage>{errors.date}</ErrorMessage>
              )}
            </FormGroup>
            {/* Time */}
            {type === appointmentTypes.appointment && (
              <>
                <FormGroup className="form-group">
                  <div className="d-flex justify-content-between">
                    <div>
                      <CustomLabel>Time:</CustomLabel>
                      <CustomInput
                        type="time"
                        className="form-control"
                        name="startTime"
                      />
                    </div>
                    <CustomText>TO:</CustomText>
                    <CustomInput
                      type="time"
                      className="form-control align-self-end"
                      name="endTime"
                    />
                  </div>
                  {(errors.startTime && touched.startTime) ||
                  (errors.endTime && touched.endTime) ? (
                    <ErrorMessage>
                      {errors.startTime || errors.endTime}
                    </ErrorMessage>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group">
                  <CustomLabel htmlFor="numberOfIterations">
                    Number of Weeks (Between 1-50):
                  </CustomLabel>
                  <CustomInput
                    type="number"
                    className="form-control"
                    id="numberOfIterations"
                    name="numberOfIterations"
                    min="1"
                    max="50"
                  />
                  {errors.numberOfIterations && touched.numberOfIterations && (
                    <ErrorMessage>{errors.numberOfIterations}</ErrorMessage>
                  )}
                </FormGroup>
              </>
            )}

            {/* Note Textarea */}
            <FormGroup className="form-group">
              <CustomLabel htmlFor="appointmentNote">Note:</CustomLabel>
              <CustomInput
                className="form-control"
                id="appointmentNote"
                placeholder="Note"
                name="note"
                component="textarea"
              />
            </FormGroup>
            {type === appointmentTypes.label && (
              <FormGroup className="form-group">
                <CustomLabel>Label Type:</CustomLabel>
                <label>
                  <input
                    className="m-4 me-0"
                    value={eventsMap.calendarLabel.code}
                    type="radio"
                    name="labelType"
                    checked={labelType === eventsMap.calendarLabel.code}
                    onChange={() => setLabelType(eventsMap.calendarLabel.code)}
                  />{" "}
                  One Time
                </label>
                <label>
                  <input
                    className="m-4 me-0"
                    value={eventsMap.recurringCalendarLabel.code}
                    type="radio"
                    name="labelType"
                    checked={
                      labelType === eventsMap.recurringCalendarLabel.code
                    }
                    onChange={() =>
                      setLabelType(eventsMap.recurringCalendarLabel.code)
                    }
                  />{" "}
                  Recurring
                </label>
              </FormGroup>
            )}
            {/* Event Color */}
            <FormGroup className="form-group">
              <CustomLabel>Color:</CustomLabel>
              <ColorPalleteContainer>
                {colorsPallete.map((colorObj) => (
                  <ColorOption
                    key={colorObj.color}
                    value={colorObj.color}
                    onClick={() => setFieldValue("eventColor", colorObj.color)}
                    background={colorObj.color}
                  />
                ))}
              </ColorPalleteContainer>
            </FormGroup>
            <FormGroup className="form-group">
              <div className="d-flex align-items-center">
                <CustomLabel className="me-2 mb-0">Selected Color:</CustomLabel>
                {values.eventColor && (
                  <ColorOption
                    background={values.eventColor}
                    className="mb-0"
                  />
                )}
              </div>
            </FormGroup>
            <div className="d-flex justify-content-end">
              <PrimaryButton>
                {isSubmitting ? (
                  <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                ) : (
                  "Save"
                )}
              </PrimaryButton>
            </div>
          </Form>
        </BodyContainer>
      )}
    </Formik>
  );
};

export default compose(inject("UserStore"), observer)(AppointmentModalBody);
