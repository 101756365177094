import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import RatesHistoryBody from "./RatesHistoryBody";

const ViewPaymentRatesHistoryModal = ({ modalData, userId }) => {
  const { isModalOpen, closeModal, toggleModal } = modalData;
  return (
    <CustomModal
      size="md"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding="0px"
    >
      <CustomModalHeader
        tag="div"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
        padding="25px"
      >
        <h2>Payment Rates History</h2>
      </CustomModalHeader>
      <CustomModalBody padding="0px">
        <RatesHistoryBody userId={userId} />
        <div className="mt-5"></div>
      </CustomModalBody>
    </CustomModal>
  );
};

export default ViewPaymentRatesHistoryModal;
