import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import { InfoText } from "../../../../../../utils/styled";
import styled from "styled-components";
import { SearchAlt2 } from "@styled-icons/boxicons-regular/SearchAlt2";
import RegistrationModal from "./components/RegistrationModal";
import { RegisterationContextProvider } from "../../../../../../contexts/RegisterationContext";
import FirstTeacherRegister from "./components/RegisterModalComponents/FirstTeacherRegister";
import SecondTeacherRegister from "./components/RegisterModalComponents/SecondTeacherRegister";
import ThirdTeacherRegister from "./components/RegisterModalComponents/ThirdTeacherRegister";
import { modalMap } from "../TableLayout/utils/constants";
import { toJS } from "mobx";
import { useInitialPageDataContext } from "src/contexts/InitialPageDataContext";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { haveSuperAdminPermission, injectUserStore } from "src/utils/helpers";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import { PrimaryButton } from "src/utils/shared/styled";
import { teachersDisplayOptions } from "../../constants";
import DropdownFilter from "src/components/common/DropdownFilter";
import instrumentsIconsEnum from "src/constants/instrumentsIconsEnum";

const SearchAndAddContainer = styled.div`
  display: flex;
  margin: 30px 15px 20px 10px;
  gap: 20px;
  justify-content: space-between;
`;
const ItemContainer = styled.div`
  width: ${({ width }) => width || "auto"};
`;
const SearchIcon = styled(SearchAlt2)`
  color: #dddddd;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
`;

const initialValues = {
  firstStepValues: {},
  secondStepValues: {},
  thirdStepValues: {},
};
const TopSection = ({ teacherData, modalData, UserStore }) => {
  const {
    selectedLocations,
    handleSelectedLocationsChange,
    handleSelectedInstrumentsChange,
    searchTerm,
    setSearchTerm,
    refreshData,
    teachersDisplayOption,
    setTeachersDisplayOption,
  } = teacherData;
  const [selectedInstruments, setSelectedInstruments] = useState(null);

  const { locations, instruments } = useInitialPageDataContext();
  const user = toJS(UserStore)?.user;

  useEffect(() => {
    handleSelectedInstrumentsChange(selectedInstruments || []);
  }, [selectedInstruments]);

  const handleTeachersDisplayOptionChange = () =>
    setTeachersDisplayOption((oldVal) =>
      oldVal === teachersDisplayOptions.ACTIVE
        ? teachersDisplayOptions.INACTIVE
        : teachersDisplayOptions.ACTIVE
    );

  const registrationComponents = {
    0: { component: <FirstTeacherRegister />, title: "Add New Teacher" },
    1: { component: <SecondTeacherRegister />, title: null },
    2: {
      component: (
        <ThirdTeacherRegister refreshData={refreshData} modalData={modalData} />
      ),
      title: null,
    },
  };

  const isAddTeachersBtnDisplayed = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.createTeachers
  );

  return (
    <>
      <SearchAndAddContainer>
        <ItemContainer width="160px" className="align-items-center d-flex">
          {isAddTeachersBtnDisplayed && (
            <InfoText
              style={{ cursor: "pointer" }}
              onClick={() =>
                modalData.openModalWithName(modalMap.registration.name)
              }
            >
              <i
                style={{ color: "black", marginTop: "7px" }}
                className="fas fa-plus mx-2"
              />
              Add New Teacher
            </InfoText>
          )}
        </ItemContainer>
        <ItemContainer width="45%">
          <FormGroup className="searchContainer">
            <input
              placeholder="Search by Teacher Name"
              className="searchInput"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchIcon />
          </FormGroup>
        </ItemContainer>
        <ItemContainer>
          <DropdownFilter
            placeholder="Select instruments"
            value={selectedInstruments}
            setValue={setSelectedInstruments}
            options={instruments?.map((instrument) => {
              const label = instrument?.name;
              const value = instrument?.id;
              return {
                label: (
                  <span>
                    {instrumentsIconsEnum[label]} {label}
                  </span>
                ),
                value,
              };
            })}
            width={"300px"}
            isSearchable
            isMulti
          />
        </ItemContainer>
        <ItemContainer width="35%">
          <CustomReactSelect
            placeholder={"Location Filter"}
            options={locations.map((location) => {
              const label = location.name;
              const value = location.id;
              return {
                label,
                value,
              };
            })}
            onChange={handleSelectedLocationsChange}
            value={selectedLocations}
            wrapOptions={false}
            // bgColor="#fff"
            width="100%"
          />
        </ItemContainer>
      </SearchAndAddContainer>
      <div className="mt-2 mb-4">
        <PrimaryButton onClick={handleTeachersDisplayOptionChange}>
          {teachersDisplayOption === teachersDisplayOptions.ACTIVE
            ? "Show Inactive Teachers"
            : "Show active Teachers"}
        </PrimaryButton>
      </div>
      {/* Registration Modal */}
      {modalData.isModalOpen ? (
        <RegisterationContextProvider
          initialValues={initialValues}
          componentList={registrationComponents}
        >
          <RegistrationModal
            modalData={modalData}
            componentList={registrationComponents}
          />
        </RegisterationContextProvider>
      ) : null}
    </>
  );
};

export default injectUserStore(TopSection);
