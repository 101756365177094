import React, { useContext, useState } from "react";
import CustomDropDown from "../../common/DropDown";
import {
  AUDIO_EXTENSIONS,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
  typesOptions,
} from "../../common/Library/common/constants";
import { NoteAttachmentsContext } from "../../common/Library/common/libraryContext";
import FilesPreview from "../../common/Library/new-item-modal/FilesPreview";
import LibraryFileUploadInput from "../../common/Library/new-item-modal/LibraryFileUploadInput";

import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalButton,
  LibraryModalHeader,
  LibraryModalInput,
} from "../../common/styled-components/teacherLibraryStyledComponents";
import { toast } from "react-toastify";

const AddLessonNoteAttachmentsModal = () => {
  const { isOpen, close, onSubmit } = useContext(NoteAttachmentsContext);
  const [recommendationLink, setRecommendationLink] = useState("");
  const [fileType, setFileType] = useState(typesOptions[0]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [error, setError] = useState("");

  const onUploadFile = (e) => {
    const file = e.target.files[0];
    const { type } = file;
    const extension = type.split("/")[1];

    const isImage =
      fileType.id === "musicSheets" && IMAGE_EXTENSIONS.includes(extension);
    const isRecording =
      fileType.id === "recordings" && AUDIO_EXTENSIONS.includes(extension);
    const isVideo =
      fileType.id === "videos" && VIDEO_EXTENSIONS.includes(extension);

    if (isImage || isRecording || isVideo) {
      const fileObject = {
        file,
        name: `${fileType.label} | ${file.name}`,
        attachmentType: fileType.value,
      };
      setUploadFiles((previous) => [...previous, fileObject]);
      setError("");
    } else {
      setError("File extension not supported for current category");
    }
    e.target.value = "";
  };

  const onAddClick = () => {
    const fileObject = {
      name: `${fileType.label} | ${recommendationLink}`,
      recommendationLink,
      attachmentType: fileType.value,
    };
    setUploadFiles((previous) => [...previous, fileObject]);
    setError("");
    setRecommendationLink("");
  };

  const submit = () => {
    toast.info("Uploading attachments...");
    toast.info(
      "Saving in background, please don't refresh or close the page until finished"
    );
    onSubmit(uploadFiles);
  };

  const removeFile = (file) => {
    setUploadFiles((previous) => {
      const index = previous.indexOf(file);
      previous.splice(index, 1);
      return [...previous];
    });
  };

  const onFileOptionChange = (option) => {
    setFileType(option);
  };

  const isDisabled = () => {
    return !uploadFiles.length;
  };

  const checkSelectedOption = () => {
    switch (fileType.id) {
      case "musicSheets":
        return IMAGE_EXTENSIONS;
      case "recordings":
        return AUDIO_EXTENSIONS;
      case "videos":
        return VIDEO_EXTENSIONS;
      default:
        return null;
    }
  };
  return (
    <LibraryModal className={"p-0"} centered isOpen={isOpen} size={"lg"}>
      <LibraryModalHeader
        close={
          <button className="close" onClick={close}>
            <i className="fas fa-times" />
          </button>
        }
      >
        Upload attachments
      </LibraryModalHeader>
      <LibraryModalBody>
        <FilesPreview files={uploadFiles} onRemove={removeFile} />
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ marginBottom: 20 }}
        >
          <div className="col-6 pe-2">
            <CustomDropDown
              label={fileType.label}
              value={fileType.label}
              // header={"Attachment Type"}
              keyName={"label"}
              options={typesOptions}
              onOptionClick={onFileOptionChange}
            />
          </div>
          <div className="col-6 pe-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                {fileType.id === "recommendationLinks" ? (
                  <div className={"d-flex align-items-center"}>
                    <LibraryModalInput
                      formik={false}
                      // label={"Recommendation Link"}
                      name="Recommendation Link"
                      placeholder="Recommendation Link"
                      height={"40px"}
                      size={12}
                      value={recommendationLink}
                      customStyle={{
                        marginBottom: 0,
                      }}
                      onChange={(e) => setRecommendationLink(e.target.value)}
                    />
                    <button
                      onClick={onAddClick}
                      className={"btn btn-light d-flex align-items-center ms-2"}
                    >
                      <span>Add</span>
                    </button>
                  </div>
                ) : (
                  <LibraryFileUploadInput
                    onUploadFile={onUploadFile}
                    customStyle={{
                      height: 40,
                      marginBottom: 0,
                    }}
                    label={"Upload"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {checkSelectedOption() ? (
          <div className="text-muted mb-2">
            Supported extensions: {checkSelectedOption()?.join(", ")}
          </div>
        ) : null}

        {error !== "" && (
          <div className={"alert alert-danger mb-2"}>{error}</div>
        )}
        <LibraryModalButton
          type={"submit"}
          onClick={submit}
          disabled={isDisabled()}
          className={`d-flex w-auto px-5 font-weight-bold mx-auto ${
            isDisabled() && "disabled"
          } text-white align-items-center justify-content-center`}
        >
          <div className={"d-flex align-items-center justify-content-center"}>
            <span>Submit</span>
          </div>
        </LibraryModalButton>
      </LibraryModalBody>
    </LibraryModal>
  );
};
export default AddLessonNoteAttachmentsModal;
