import { Notepad } from "@styled-icons/boxicons-regular/Notepad";
import { Avatar } from "antd";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import moment from "moment";

const eventInfoItems = (event) => [
  {
    label: "Start",
    children: moment(event?.start)?.format("dddd, MMMM D | h:mm a"),
  },
  {
    label: "End",
    children: moment(event?.end)?.format("dddd, MMMM D | h:mm a"),
  },
  {
    label: (
      <span>
        <Notepad width={24} height={24} color="#681e46" /> Note
      </span>
    ),
    children: event?.note || "No note found",
  },
];

const teacherInfoItems = ({
  event,
  teachingDays,
  instruments,
  studio,
  location,
}) => [
  {
    label: (
      <Avatar
        src={event?.teacher?.imageUrl || UnknownProfilePhoto}
        alt="teacher profile photo"
        size={100}
        style={{
          marginLeft: "20%",
        }}
      />
    ),
  },
  {
    label: "Contact name",
    children: event?.teacher?.fullName,
  },
  {
    label: "Email",
    children: event?.teacher?.primaryEmail,
  },
  {
    label: "Phone",
    children: event?.teacher?.primaryPhone,
  },
  {
    label: "Location",
    children: location || "No location found",
  },
  {
    label: "Studio",
    children: studio || "No studio found",
  },
  {
    label: "Teaching days",
    children: teachingDays,
  },
  {
    label: "Instruments",
    children: instruments,
  },
];

const studentInfoItems = (event) => [
  {
    label: (
      <Avatar
        src={event?.student?.imageUrl || UnknownProfilePhoto}
        alt="teacher profile photo"
        size={100}
        style={{
          marginLeft: "20%",
        }}
      />
    ),
  },
  {
    label: "Contact name",
    children: event?.student?.primaryContactName,
  },
  {
    label: "Email",
    children: event?.student?.primaryContactEmail,
  },
  {
    label: "Phone",
    children: event?.student?.primaryPhone,
  },
];

export { eventInfoItems, teacherInfoItems, studentInfoItems };
