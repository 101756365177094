import React from "react";
import { TabContainer } from "./styled";
import { TABS } from "../../common/constants";
import styled from "styled-components";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

const MobileTab = ({ title, IconComponent, isActive, ...rest }) => {
  const StyledIcon = styled(IconComponent)`
    width: 60px;
    height: 60px;
    color: ${isActive ? "#681E46" : "#DDDDDD"};

    @media (max-width: ${ScreenSize.SM}px) {
      width: 35px;
      height: 35px;
    }
  `;

  const StyledTitle = styled.p`
    color: ${isActive ? "#681E46" : "#DDDDDD"};
    margin: 0;

    @media (max-width: ${ScreenSize.SM}px) {
      font-size: 0.7rem;
    }
  `;

  return (
    <TabContainer {...rest}>
      <StyledIcon />
      <StyledTitle>{title}</StyledTitle>
    </TabContainer>
  );
};

export default MobileTab;
