import React from "react";
import NotificationsMenu from "src/components/common/NotificationsMenu";
import SettingsMenu from "src/components/common/SettingsMenu";
import { useSidebarContext } from "src/contexts/SidebarContext";
import { getCurrentMomentTimezoneValue } from "src/utils/helpers";
import {
  EllipseIcon,
  IconsContainer,
  MailIcon,
  MenuIcon,
  MobileNavbarContainer,
  NotificationIcon,
  SettingsIcon,
  NavInfoText,
} from "../styled";

const MobileNavbar = () => {
  const { isSidebarOpen, toggleSideBar, closeSidebar, openSidebar } =
    useSidebarContext();

  const NotificationsDropdownContainer = ({ children }) => (
    <div className="position-relative">{children}</div>
  );
  const SettingsDropdownContainer = ({ children }) => (
    <div className="position-relative">{children}</div>
  );

  const contactUsEmail = process.env.REACT_APP_CONTACT_US_EMAIL;

  const { shortName: zoneName } = getCurrentMomentTimezoneValue();

  return (
    <MobileNavbarContainer>
      <div>
        <MenuIcon onClick={toggleSideBar} />
      </div>
      <IconsContainer>
        <NavInfoText>{zoneName || "Unknown Timezone"}</NavInfoText>
        <NotificationsMenu
          dropdownToggleContainer={NotificationsDropdownContainer}
          dropdownToggleIcon={NotificationIcon}
          ellipseIcon={EllipseIcon}
        />

        <div>
          <a href={`mailto:${contactUsEmail}`}>
            <MailIcon />
          </a>
        </div>
        <SettingsMenu
          dropdownToggleContainer={SettingsDropdownContainer}
          dropdownToggleIcon={SettingsIcon}
        />
      </IconsContainer>
    </MobileNavbarContainer>
  );
};

export default MobileNavbar;
