import { Button, Popconfirm, Space } from "antd";
import moment from "moment";
import { StyledActionButton } from "../../Applications/styled";
import { MdDelete } from "react-icons/md";
import { BiSolidArchiveOut } from "react-icons/bi";

const feedbacksTableColumns = ({
  onDeleteClick,
  onUnarchiveClick,
  onMessageClick,
  isLoading,
}) => {
  const currentMonth = moment().format("MM/YYYY");

  return [
    {
      title: "Request date",
      dataIndex: "request_date",
      key: "request_date",
      responsive: ["sm"],
      sorter: (a, b) =>
        moment(a.request_date, "MM/DD/YYYY").valueOf() -
        moment(b.request_date, "MM/DD/YYYY").valueOf(),
      filters: [
        {
          text: "This month",
          value: currentMonth,
        },
        {
          text: "Last month",
          value: moment().subtract(1, "months").format("MM/YYYY"),
        },
      ],
      onFilter: (value, record) => {
        const recordDate = moment(record?.request_date, "MM/DD/YYYY");
        return recordDate.format("MM/YYYY") === value;
      },
    },
    {
      title: "Author's name",
      dataIndex: "name",
      key: "name",
      responsive: ["lg"],
    },
    {
      title: "Author's role",
      dataIndex: "role",
      key: "role",
      responsive: ["sm"],
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      ellipsis: true,
      render: (_, record) => (
        <Button type="link" onClick={() => onMessageClick(record)}>
          {record?.message}
        </Button>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Space size="middle">
          <StyledActionButton
            title="Unarchive"
            onClick={() => onUnarchiveClick(record?.key, false)}
          >
            <BiSolidArchiveOut size={18} />
          </StyledActionButton>
          <Popconfirm
            title="Delete feedback"
            description="Delete this feedback?"
            okButtonProps={[{ loading: isLoading }]}
            onConfirm={() => onDeleteClick(record?.key)}
          >
            <StyledActionButton aria-label="Delete feedback" title="Delete">
              <MdDelete size={18} />
            </StyledActionButton>
          </Popconfirm>
        </Space>
      ),
    },
  ];
};

export default feedbacksTableColumns;
