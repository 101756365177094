import React from "react";
import PaymentInfoRow from "src/components/common/PaymentInfoRow";
import UserNavSection from "../UserNavSection";
import UserSelection from "../UserSelection";
import UserPayments from "./components/UserPayments";
import usePaymentList from "./hooks/usePaymentList";

const PaymentList = () => {
  const paymentListData = usePaymentList();
  const {
    currentUserType,
    setCurrentUserType,
    initialData,
    loadingInitialData,
    selectedUserId,
    selectedUserData,
    setSelectedUserId,
    selectedUser,
    loadingSelectedUserData,
    currentPaymentObj,
    setCurrentPaymentId,
    refreshUserData,
  } = paymentListData;
  return (
    <div>
      <div className="w-50">
        <div>
          <UserNavSection
            currentUserType={currentUserType}
            setCurrentUserType={setCurrentUserType}
          />
        </div>
        <div className="mt-4">
          <UserSelection
            users={initialData.users}
            currentUserType={currentUserType}
            setSelectedUserId={setSelectedUserId}
            selectedUser={selectedUser}
          />
        </div>
      </div>
      <div className="mt-5">
        <UserPayments
          currentPaymentObj={currentPaymentObj}
          setCurrentPaymentId={setCurrentPaymentId}
          userPaymentsList={selectedUserData.userPaymentsList}
          refreshUserData={refreshUserData}
        />
      </div>
    </div>
  );
};

export default PaymentList;
