import { useContext, useState } from "react";
import { Timestamp, addDoc } from "firebase/firestore";
import store from "src/stores/UserStore";
import { uploadFileWithRandomId } from "src/utils/helpers";
import { toast } from "react-toastify";

import { ModalBody } from "reactstrap";
import { FeedbackModalHeader, StyledFeedbackModal } from "./styled";
import MessageSection from "./components/MessageSection";
import { FirebaseContext } from "src/components/Firebase";
import SummarySection from "./components/SummarySection";
import AttachmentOverlay from "./components/AttachmentOverlay";
import ModalFooter from "./components/ModalFooter";

const FeedbackModal = ({
  isOpen,
  toggle,
  onConfirm,
  onScreenshot,
  afterScreenshot,
}) => {
  const [currentSection, setCurrentSection] = useState(1);
  const [messageValue, setMessageValue] = useState("");
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isError, setIsError] = useState(false);

  const firebase = useContext(FirebaseContext);
  const { user } = store;

  const handleNextClick = () => {
    if (messageValue) {
      setCurrentSection(2);
      return setIsError(false);
    }

    return setIsError(true);
  };

  const handleSubmitClick = async () => {
    try {
      if (!messageValue) return;

      await addDoc(firebase.feedbacks(), {
        createdAt: Timestamp.now(),
        createdBy: {
          name: user?.name || user?.fullName,
          email: user?.email || user?.primaryEmail,
          role: user?.role,
        },
        message: messageValue,
        status: "On review",
        attachments,
        archived: false,
      });

      onConfirm();
      toast.success("Feedback sent to our administration, thank you!");
      setMessageValue("");
      setAttachments([]);
      setCurrentSection(1);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong, please try again");
    }
  };

  const handleUpload = async (file) => {
    const res = await uploadFileWithRandomId(firebase, file, "/feedbacks");

    setAttachments((prevState) => [...prevState, res?.downloadUrl]);
    setAttachmentOpen(false);
    afterScreenshot();
  };

  return (
    <StyledFeedbackModal
      isOpen={isOpen}
      backdrop={false}
      size="md"
      unmountOnClose={false}
      zIndex={999}
    >
      <FeedbackModalHeader toggle={toggle}>Feedback</FeedbackModalHeader>
      <ModalBody>
        {currentSection === 1 ? (
          <MessageSection
            value={messageValue}
            onChange={({ target }) => setMessageValue(target?.value)}
            onCameraClick={() => setAttachmentOpen(true)}
            error={isError}
          />
        ) : (
          <SummarySection message={messageValue} attachments={attachments} />
        )}
        <ModalFooter
          currentSection={currentSection}
          onNextClick={handleNextClick}
          onBackClick={() => setCurrentSection(1)}
          onSubmit={handleSubmitClick}
        />
      </ModalBody>
      <AttachmentOverlay
        isOpen={attachmentOpen}
        onCancel={() => setAttachmentOpen(false)}
        onUpload={handleUpload}
        onScreenshot={onScreenshot}
      />
    </StyledFeedbackModal>
  );
};
export default FeedbackModal;
