import React from "react";
import MakeupRequestDetailsBody from "./MakeupRequestDetailsBody";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";

const MakeupRequestDetailsModal = ({ modalData, makeupRequest, onSuccess }) => {
  return (
    <CustomModal
      size="md"
      centered
      isOpen={modalData.isModalOpen}
      toggle={modalData.toggleModal}
      padding={"0px"}
    >
      <CustomModalHeader
        // close={<CloseIcon onClick={modalData.closeModal} />}
        className="pb-0 border-bottom-0"
        toggle={modalData.toggleModal}
        padding="25px"
      >
        <h2 className="fs-3 fw-bold">Makeup Request Details</h2>
      </CustomModalHeader>
      <CustomModalBody padding={"0px"}>
        <MakeupRequestDetailsBody
          makeupRequest={makeupRequest}
          modalData={modalData}
          onSuccess={onSuccess}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default MakeupRequestDetailsModal;
