import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { parseUserPaymentRateDates } from "src/utils/firebaseDatesParserFns";

const initialDataInitialValues = {
  userPaymentRates: [],
};
const useRatesHistory = ({ userId }) => {
  const { getUserPaymentRates, getUsersByIds } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  console.log(initialData);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if (!userId) return;

        setLoadingInitialData(true);

        const [paymentRates] = await Promise.all([
          getUserPaymentRates(userId, { orderByDate: true }),
        ]);

        const uniquePaymentRatesCreatorsIds = [
          ...new Set(paymentRates.map(({ createdBy }) => createdBy)),
        ];
        const paymentRatesCreators = uniquePaymentRatesCreatorsIds.length
          ? await getUsersByIds(uniquePaymentRatesCreatorsIds)
          : [];

        const paymentRatesWithCreators = paymentRates.map((ratesObj) => {
          const createdByUser = paymentRatesCreators?.find(
            ({ id }) => id === ratesObj.createdBy
          );
          return {
            ...ratesObj,
            createdByUser,
          };
        });

        setInitialData((oldVal) => ({
          ...oldVal,
          userPaymentRates: paymentRatesWithCreators,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };

    fetchInitialData();
  }, [userId]);

  return { initialData, loadingInitialData };
};

export default useRatesHistory;
