import React, { useContext } from "react";
import logo from "../../../assets/images/logo.png";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import {
  emailValidationSchema,
  passwordConfirmValidationSchema,
  passwordValidationSchema,
  requiredStringSchema,
} from "../../../utils/validations";
import { FirebaseContext } from "../../Firebase";
import { setDoc, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router";
import {
  UserRole,
  adminCredentialsRolesList,
  hasAdminCredentials,
  isTeacher,
} from "../../../constants/UserRoleEnum";
import {
  Container,
  InputLabel,
  PageLogo,
  RadioInput,
  RadioWrapper,
} from "../shared/StyledComponents";
import InputWithLabel from "../shared/InputWithLabel";
import { SubmitSpinner } from "../../common";
import InputWrapper from "../shared/InputWrapper";
import { PrimaryButton } from "src/utils/shared/styled";
import { AuthButtonsWrapper, AuthSubmitButton } from "../styled";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import MobileAuthNav from "../MobileAuthNav";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { workConditions } from "src/constants/paymentsEnum";
import { getInitialsImageUrl } from "src/utils/helpers";

const EMAIL_ALREADY_IN_USE = "auth/email-already-in-use";

const signUpValidationSchema = yup.object().shape({
  primaryEmail: emailValidationSchema,
  password: requiredStringSchema,
  passwordConfirm: passwordConfirmValidationSchema,
  fullName: requiredStringSchema,
});
const SignUp = ({ UserStore }) => {
  const Navigate = useNavigate();
  const firebase = useContext(FirebaseContext);

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;
  return (
    <Container>
      <div className="text-center">
        <PageLogo
          src={logo}
          alt="logo"
          role="button"
          onClick={() => Navigate("/")}
        />
      </div>
      {!isDesktopScreen && <MobileAuthNav />}
      <Formik
        initialValues={{
          primaryEmail: "",
          password: "",
          passwordConfirm: "",
          fullName: "",
          role: UserRole.PARENT,
        }}
        validationSchema={signUpValidationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const { primaryEmail, password, fullName, role } = values;

          setSubmitting(true);
          try {
            const lowerCaseEmail = primaryEmail.toLowerCase();
            const authUser = await firebase.signUpWithEmailAndPassword(
              lowerCaseEmail,
              password
            );
            const initialAvatarImage = getInitialsImageUrl(fullName);

            await setDoc(firebase.user(authUser.user.uid), {
              createdAt: Timestamp.now(),
              primaryEmail: lowerCaseEmail,
              fullName,
              role,
              imageUrl: initialAvatarImage,
              ...(hasAdminCredentials(role) ||
                (isTeacher(role) && {
                  workCondition: workConditions.partTime.value,
                })),
            });
            /**
             * prev navigate line in version 5
             * const { from } = props.location.state || {from: { pathname: "/" },};
             */
            Navigate("/dashboard");
            setSubmitting(false);
          } catch (err) {
            if (err.code === EMAIL_ALREADY_IN_USE) {
              setErrors({ primaryEmail: "Email Already In Use" });
            }
            console.log(err.code);
          }
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          values,
          handleBlur,
          handleChange,
        }) => (
          <Form className="mt-5">
            <InputWrapper>
              <InputWithLabel
                type="text"
                name="fullName"
                error={errors.fullName}
                touched={touched.fullName}
                label="Name, Surname"
                placeholder="Name, Surname"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="primaryEmail"
                error={errors.primaryEmail}
                touched={touched.primaryEmail}
                label="Email Address"
                placeholder="Email Address"
                type="email"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="password"
                error={errors.password}
                touched={touched.password}
                label="Password"
                placeholder="Password"
                type="password"
              />
            </InputWrapper>
            <InputWrapper>
              <InputWithLabel
                name="passwordConfirm"
                error={errors.passwordConfirm}
                touched={touched.passwordConfirm}
                label="Confirm Password"
                placeholder="Confirm Password"
                type="password"
              />
            </InputWrapper>
            <div className="w-75 m-auto">
              <InputLabel>Role</InputLabel>
              {UserStore.user?.role === UserRole.SUPER_ADMIN && (
                <RadioWrapper>
                  <RadioInput
                    type="radio"
                    value={UserRole.SUPER_ADMIN}
                    name="role"
                  />
                  Super Admin
                </RadioWrapper>
              )}
              {adminCredentialsRolesList.includes(UserStore.user?.role) && (
                <>
                  <RadioWrapper>
                    <RadioInput
                      type="radio"
                      value={UserRole.ADMIN}
                      name="role"
                    />
                    Admin
                  </RadioWrapper>
                </>
              )}
              <RadioWrapper>
                <RadioInput type="radio" value={UserRole.PARENT} name="role" />
                Parent
              </RadioWrapper>
              {/* <RadioWrapper>
                <RadioInput type="radio" value={UserRole.STUDENT} name="role" />
                Student
              </RadioWrapper>
              <RadioWrapper>
                <RadioInput type="radio" value={UserRole.TEACHER} name="role" />
                Teacher
              </RadioWrapper> */}
              <AuthButtonsWrapper>
                <AuthSubmitButton type="submit" width="245px">
                  {isSubmitting ? (
                    <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
                  ) : (
                    "Sign Up"
                  )}
                </AuthSubmitButton>
              </AuthButtonsWrapper>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default compose(inject("UserStore"), observer)(SignUp);
