import React from "react";
import CustomNavButtons from "src/components/common/CustomNavButtons";
import { paymentUserTypes } from "../../constants";

const UserNavSection = ({
  currentUserType,
  setCurrentUserType,
  withBothOptions,
}) => {
  const options = [
    {
      name: "Instructor",
      value: paymentUserTypes.INSTRUCTOR,
    },
    {
      name: "Operation Team",
      value: paymentUserTypes.OPERATION_TEAM,
    },
    ...(withBothOptions
      ? [
          {
            name: "Both",
            value: paymentUserTypes.BOTH,
          },
        ]
      : []),
  ];
  return (
    <div>
      <CustomNavButtons
        options={options}
        currentOption={currentUserType}
        setCurrentOption={setCurrentUserType}
      />
    </div>
  );
};

export default UserNavSection;
