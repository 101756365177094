import React from "react";
import StyledBarContent from "src/components/common/StyledBarContent";
import {
  CustomInput,
  CustomLabel,
  CustomSelectField,
  CustomTextarea,
  InputContainer,
  InputRow,
} from "../../../styled";
import { injectUserStore, updatedMomentNow } from "src/utils/helpers";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import WeekDaySelect from "./WeekdaySelect";
import CustomNavButtons from "src/components/common/CustomNavButtons";
import {
  groupClassAvailableForMap,
  groupClassFrequency,
  groupClassLocationStatuses,
} from "src/constants/groupClassConstants";
import { LessonsLengthEnum } from "src/constants/LessonsLength";
import AvailabilitiesSection from "./AvailabilitiesSection";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { createGroupClassViews } from "../../../constants";
import NumberInput from "../shared/NumberInput";

const availableForOptions = Object.keys(groupClassAvailableForMap).map(
  (key) => {
    const value = groupClassAvailableForMap[key];
    let title;
    switch (value) {
      case groupClassAvailableForMap.ALL:
        title = "All";
        break;
      case groupClassAvailableForMap.CURRENT_STUDENTS:
        title = "Current Students Only";
        break;
      case groupClassAvailableForMap.PUBLIC:
        title = "Public Only";
        break;
      default:
        break;
    }
    return {
      title,
      value,
    };
  }
);

const ClassInfoComponent = ({ hookData }) => {
  const {
    formInputs,
    handleFormInputsChange,
    initialData,
    selectedTeachers,
    showAvailabilities,
    showSaveBtn,
    loadingSavingGroupClass,
    handleCreateGroupClass,
    filteredTeachers,
    isValidFrequencyAndIterations,
    setCurrentView,
  } = hookData;

  const selectedTeachersWithLabel = formInputs.teachersIds.map((id) => {
    const teacher = filteredTeachers.find((teacher) => teacher.id === id);
    const label = teacher?.fullName;
    return { value: id, label };
  });
  const onTeachersOptionsChange = (selected = []) => {
    const idsValue = selected.map(({ value }) => value);
    handleFormInputsChange("teachersIds", idsValue);
  };

  const frequencyOptions = Object.values(groupClassFrequency).map((option) => ({
    name: option,
    value: option,
  }));
  return (
    <div>
      <InputRow>
        <InputContainer>
          <CustomLabel>Group Class Title:</CustomLabel>
          <CustomInput
            type="text"
            value={formInputs.title}
            onChange={(e) => handleFormInputsChange("title", e.target.value)}
            width="100%"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <CustomLabel>Location:</CustomLabel>
          <CustomSelectField
            value={formInputs.locationId}
            onChange={(e) =>
              handleFormInputsChange("locationId", e.target.value)
            }
            width="100%"
          >
            <option value="" disabled>
              Location
            </option>
            {initialData.locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </CustomSelectField>
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <CustomLabel>Teachers:</CustomLabel>
          <CustomReactSelect
            disabledBackgroundColor="#f1f1f1;"
            isDisabled={!formInputs.locationId}
            placeholder={"Select Teachers"}
            options={filteredTeachers.map((teacher) => {
              const value = teacher.id;
              const label = teacher.fullName;
              return {
                label,
                value,
              };
            })}
            onChange={onTeachersOptionsChange}
            value={selectedTeachersWithLabel}
            width="100%"
            wrapOptions={false}
            bgColor="#fff"
            margin="auto"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <WeekDaySelect
            selectedTeachers={selectedTeachers}
            formInputs={formInputs}
            handleFormInputsChange={handleFormInputsChange}
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <CustomLabel>Frequency:</CustomLabel>
          <CustomNavButtons
            buttonsPadding={"12px"}
            isDisabled={
              formInputs.day === null ||
              formInputs.day === undefined ||
              formInputs.day === ""
            }
            options={frequencyOptions}
            currentOption={formInputs.frequency}
            setCurrentOption={(value) =>
              handleFormInputsChange("frequency", value)
            }
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <CustomLabel>Number Of Classes:</CustomLabel>
          <NumberInput
            disabled={
              !formInputs.frequency ||
              formInputs.frequency === groupClassFrequency.ONCE
            }
            max={30}
            value={formInputs.iterations}
            onChange={(value) => {
              handleFormInputsChange("iterations", parseInt(value));
            }}
            width="100%"
          />
        </InputContainer>
        <InputContainer>
          <CustomLabel>Start Date:</CustomLabel>
          <CustomInput
            type="date"
            disabled={!isValidFrequencyAndIterations}
            value={formInputs.startDate}
            onChange={(e) =>
              handleFormInputsChange("startDate", e.target.value)
            }
            width="100%"
            min={
              formInputs.day &&
              updatedMomentNow()
                .set({ year: 2015 })
                .isoWeekday(parseInt(formInputs.day))
                .format("YYYY-MM-DD")
            }
            step="7"
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer>
          <CustomLabel>Duration:</CustomLabel>
          <CustomSelectField
            disabled={!formInputs.startDate}
            value={formInputs.lessonLength}
            onChange={(e) =>
              handleFormInputsChange("lessonLength", e.target.value)
            }
            width="100%"
          >
            <option value="" disabled>
              Duration
            </option>
            {Object.keys(LessonsLengthEnum).map((key) => (
              <option key={key} value={key}>
                {LessonsLengthEnum[key]}
              </option>
            ))}
          </CustomSelectField>
        </InputContainer>
        <InputContainer>
          <CustomLabel>Available For:</CustomLabel>
          <CustomSelectField
            disabled={!formInputs.lessonLength}
            value={formInputs.availableFor}
            onChange={(e) =>
              handleFormInputsChange("availableFor", e.target.value)
            }
            width="100%"
          >
            <option value="" disabled>
              Available For
            </option>
            {availableForOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.title}
              </option>
            ))}
          </CustomSelectField>
        </InputContainer>
      </InputRow>

      <div>
        {showAvailabilities && (
          <>
            <AvailabilitiesSection hookData={hookData} />
          </>
        )}
      </div>
      {showSaveBtn && (
        <>
          <InputRow className="mt-4">
            <InputContainer className="d-flex">
              <CustomLabel className="mb-3 me-5">Type:</CustomLabel>
              <div>
                <label className="me-3">
                  <input
                    type="radio"
                    className="me-2"
                    checked={
                      formInputs.locationStatus ===
                      groupClassLocationStatuses.IN_PERSON
                    }
                    onChange={() =>
                      handleFormInputsChange(
                        "locationStatus",
                        groupClassLocationStatuses.IN_PERSON
                      )
                    }
                  />
                  In Person
                </label>
                <label className="me-3">
                  <input
                    type="radio"
                    className="me-2"
                    checked={
                      formInputs.locationStatus ===
                      groupClassLocationStatuses.VIRTUAL
                    }
                    onChange={() =>
                      handleFormInputsChange(
                        "locationStatus",
                        groupClassLocationStatuses.VIRTUAL
                      )
                    }
                  />
                  Virtual
                </label>
                <label>
                  <input
                    type="radio"
                    className="me-2"
                    checked={
                      formInputs.locationStatus ===
                      groupClassLocationStatuses.BOTH
                    }
                    onChange={() =>
                      handleFormInputsChange(
                        "locationStatus",
                        groupClassLocationStatuses.BOTH
                      )
                    }
                  />
                  Both
                </label>
              </div>
            </InputContainer>
          </InputRow>
          <div>
            <CustomLabel className="mb-3">Description:</CustomLabel>
            <CustomTextarea
              width="100%"
              value={formInputs.note}
              onChange={(e) =>
                handleFormInputsChange("description", e.target.value)
              }
            />
          </div>
          <div className="mt-3">
            <CustomLabel className="mb-3">Note (For Admins):</CustomLabel>
            <CustomTextarea
              width="100%"
              value={formInputs.adminNote}
              onChange={(e) =>
                handleFormInputsChange("adminNote", e.target.value)
              }
            />
          </div>
          <div className="mt-4 text-center">
            <PrimaryButtonWithLoading
              onClick={() => setCurrentView(createGroupClassViews.PAYMENT)}
              width="60%"
            >
              Next
            </PrimaryButtonWithLoading>
          </div>
        </>
      )}
    </div>
  );
};

export default injectUserStore(ClassInfoComponent);
