import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { injectUserStore } from "src/utils/helpers";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import { CustomModal, CustomModalBody } from "src/utils/styled";

import ChangeProfileImageButton from "../ChangeProfileImageButton";
import { NoteModalHeader } from "src/components/common/styled-components/lessonNotesStyledComponents";
import ContactInfo from "./components/ContactInfo";

function ViewParentInfoModal({ modalData, parentUser, UserStore }) {
  const { isModalOpen, closeModal, toggleModal } = modalData;
  const user = toJS(UserStore)?.user;

  if (!parentUser) {
    modalData.closeModal();
    toast.error("Cant open Parent modal without the parent");
    return <div></div>;
  }
  return (
    <div>
      <CustomModal
        padding="0px"
        centered
        isOpen={isModalOpen}
        toggle={toggleModal}
        size={"lg"}
      >
        <NoteModalHeader className="position-relative">
          <div className="position-absolute close-btn-container bg-white rounded-circle">
            <button
              onClick={() => {
                closeModal();
              }}
              className={"close border-0 outline-0"}
            >
              <i className="fa fa-times" />
            </button>
          </div>
          <div className="curve-container position-absolute top-0 left-0 w-100">
            <svg
              width="100%"
              height="100%"
              id="svg"
              viewBox="0 0 750 150"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              className="transition duration-300 ease-in-out delay-150"
            >
              <path fill="#681E46" d="M0,150V0h750v150C750,150,375,70,0,150z" />
            </svg>
          </div>
          <div className="position-relative user-details d-flex justify-content-center flex-column align-items-center">
            <div className="avatar overflow-hidden d-flex align-items-center justify-content-center bg-light rounded-circle border border-4 border-white">
              {parentUser?.imageUrl ? (
                <img src={parentUser.imageUrl} alt="user image" />
              ) : null}
            </div>
          </div>
        </NoteModalHeader>
        <CustomModalBody padding="0px">
          <div className="pt-2 mb-4 px-4 pb-4">
            <h5 className="text-center text-capitalize mb-4">
              {parentUser?.fullName}
              <div className="text-center mt-3">
                <ChangeProfileImageButton
                  userId={parentUser?.id}
                  onSuccess={() => window.location.reload()}
                />
              </div>
            </h5>
            <ContactInfo
              closeModal={modalData.closeModal}
              parentUser={parentUser}
            />
          </div>
        </CustomModalBody>
      </CustomModal>
    </div>
  );
}
export default injectUserStore(ViewParentInfoModal);
