import moment from "moment";
import React, { useMemo, useState } from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import {
  mapOptionsToRoles,
  mapRolesToOptions,
} from "src/components/Dashboard/SuperAdmin/TeachersPage/helperFns";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import { weekDays } from "../../../../../../../../../constants/weekDays";
import { InputWithLabel } from "../../../../../../../../../utils/shared/shared";

import {
  AddNewDayBtn,
  AvailableDaysSectionContainer,
  SelectField,
} from "../styled";
import { PrimaryButton } from "src/utils/shared/styled";
import useModal from "src/hooks/useModal";
import TeacherAbsenceRequestModal from "src/components/Dashboard/common/components/TeacherAbsenceRequestModal";
import PauseTeacherDayModal from "src/components/Dashboard/common/components/PauseTeacherDayModal";
import { v4 as uuidv4 } from "uuid";

const TeacherDays = ({
  teacher,
  editableTeacherDays,
  setEditableTeacherDays,
  color,
  setColor,
  locations,
  hasPermissionToEdit,
}) => {
  const pauseTeacherDayModalData = useModal();

  const [currentScheduleDayId, setCurrentScheduleDayId] = useState("");

  const handleAddNewDay = () => {
    setEditableTeacherDays((oldVal) => [
      ...oldVal,
      {
        id: uuidv4(),
        day: "",
        start: "",
        end: "",
        location: "",
        isVirtualOnly: false,
        disabledForRoles: [],
        isNew: true,
      },
    ]);
  };
  const handleDeleteDay = (index) => {
    setEditableTeacherDays((oldVal) => oldVal.filter((day, i) => i !== index));
  };
  const handleDayChange = (name, value, i) => {
    let teacherDaysCopy = [...editableTeacherDays];
    teacherDaysCopy[i][name] = value;
    setEditableTeacherDays(teacherDaysCopy);
  };

  const handlePauseStudentsClick = (dayId) => {
    setCurrentScheduleDayId(dayId);
    pauseTeacherDayModalData.openModal();
  };

  return (
    <>
      <AvailableDaysSectionContainer className="mt-3">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <h2 className="me-3 fs-4">Teacher's Days</h2>
            <input
              disabled={!hasPermissionToEdit}
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </div>
          {hasPermissionToEdit && (
            <AddNewDayBtn onClick={handleAddNewDay}>Add A New Day</AddNewDayBtn>
          )}
        </div>
        {!hasPermissionToEdit && (
          <div className="mt-3 mb-4">
            <h5 style={{ color: " #acacac" }}>
              For the change, please contact a super admin
            </h5>
          </div>
        )}
        {editableTeacherDays.map((day, i) => {
          const isEndTimeNextDay = moment(day.start, "HH:mm").isAfter(
            moment(day.end, "HH:mm"),
            "minutes"
          );

          return (
            <div key={i}>
              <div className="d-flex justify-content-between align-items-end">
                <SelectField
                  disabled={!hasPermissionToEdit}
                  name="day"
                  defaultValue=""
                  value={editableTeacherDays[i].day}
                  onChange={(e) => handleDayChange("day", e.target.value, i)}
                  width={"110px"}
                >
                  <option value="" disabled>
                    Day
                  </option>
                  {weekDays.map((day) => (
                    <option key={day.id} value={day.id}>
                      {day.name}
                    </option>
                  ))}
                </SelectField>
                <div>
                  <InputWithLabel
                    disabled={!hasPermissionToEdit}
                    label="Start"
                    type="time"
                    name="start"
                    width="115px"
                    value={editableTeacherDays[i].start}
                    onChange={(e) =>
                      handleDayChange("start", e.target.value, i)
                    }
                  />
                </div>
                <div>
                  <InputWithLabel
                    disabled={!hasPermissionToEdit}
                    label={`End ${isEndTimeNextDay ? "(Next Day)" : ""}`}
                    type="time"
                    name="end"
                    width="115px"
                    value={editableTeacherDays[i].end}
                    onChange={(e) => handleDayChange("end", e.target.value, i)}
                  />
                </div>
                <SelectField
                  disabled={!hasPermissionToEdit}
                  name="location"
                  defaultValue=""
                  value={editableTeacherDays[i].location}
                  onChange={(e) =>
                    handleDayChange("location", e.target.value, i)
                  }
                  width={"110px"}
                >
                  <option value="" disabled>
                    Location
                  </option>
                  {locations.map((day) => (
                    <option key={day.id} value={day.id}>
                      {day.name}
                    </option>
                  ))}
                </SelectField>

                {hasPermissionToEdit && (
                  <div
                    onClick={() => handleDeleteDay(i)}
                    className="pb-2"
                    style={{ cursor: "pointer" }}
                  >
                    <DeleteOff width={30} height={30} color="#8a8a8a" />
                  </div>
                )}
              </div>
              <div
                className="mt-3 ps-1 d-flex align-items-center"
                style={{ gap: 20 }}
              >
                <div style={{ width: "50%" }}>
                  <CustomReactSelect
                    isDisabled={!hasPermissionToEdit}
                    placeholder="Disabled for"
                    width="100%"
                    options={mapRolesToOptions()}
                    onChange={(selectedRoles) => {
                      handleDayChange(
                        "disabledForRoles",
                        mapOptionsToRoles(selectedRoles),
                        i
                      );
                    }}
                    value={mapRolesToOptions(
                      editableTeacherDays[i].disabledForRoles || []
                    )}
                    wrapOptions={false}
                  />
                </div>
                <label>
                  <input
                    disabled={!hasPermissionToEdit}
                    type="checkbox"
                    className="me-1"
                    name="isVirtualOnly"
                    checked={editableTeacherDays[i].isVirtualOnly}
                    onChange={(e) =>
                      handleDayChange("isVirtualOnly", e.target.checked, i)
                    }
                  />
                  Virtual Only
                </label>
                {!day.isNew && !day.isPaused && (
                  <div>
                    <PrimaryButton
                      onClick={() => handlePauseStudentsClick(day.id)}
                      type="button"
                      width="175px"
                      padding="10px"
                      backgroundColor="#e357a2"
                    >
                      Pause New Students
                    </PrimaryButton>
                  </div>
                )}
              </div>

              <hr className="mb-4 mt-4" />
            </div>
          );
        })}
      </AvailableDaysSectionContainer>

      {pauseTeacherDayModalData.isModalOpen && (
        <PauseTeacherDayModal
          modalData={pauseTeacherDayModalData}
          scheduleDayId={currentScheduleDayId}
          teacherId={teacher?.id}
        />
      )}
    </>
  );
};

export default TeacherDays;
