import React from "react";
import { toast } from "react-toastify";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "../styled";
import LessonChangesBody from "./LessonChangesBody";

const LessonChangesModal = ({
  lessonChangesModalData,
  lessonWithCurrentTimeline,
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } =
    lessonChangesModalData;

  if (!lessonWithCurrentTimeline) {
    toast.error("No Lesson");
    closeModal();
    return <></>;
  }

  return (
    <CustomModal
      size="md"
      modalWidth="550px"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Lesson Changes</h2>
      </CustomHeader>
      <CustomBody>
        <LessonChangesBody
          lessonChangesModalData={lessonChangesModalData}
          lessonWithCurrentTimeline={lessonWithCurrentTimeline}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default LessonChangesModal;
