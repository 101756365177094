import { Qrcode } from "@styled-icons/fa-solid/Qrcode";
import { Mail } from "@styled-icons/feather/Mail";
import { Settings2Outline } from "@styled-icons/evaicons-outline/Settings2Outline";
import QRCode from "react-qr-code";
import styled from "styled-components";
import { Alert } from "@styled-icons/fluentui-system-filled/Alert";
import { Ellipse } from "@styled-icons/ionicons-sharp/Ellipse";

export const Mailstyle = {
  width: "24px",
  height: "20px",
  position: "relative",
};
export const Alertstyle = {
  width: "26px",
  height: "29px",
  position: "relative",
  cursor: "pointer",
};
export const QRstyle = {
  width: "26px",
  height: "26px",
};
export const settingstyle = {
  width: "33px",
  height: "33px",
  cursor: "pointer",
};
export const UserRolestyle = {
  display: "flex",
  flexDirection: "column",
};
export const modalStyle = {
  height: "410px",
  width: "100%",
};
export const ModalHeaderstyle = {
  color: "#151515",
  fontSize: "29px",
  fontWeight: "800",
  marginBottom: "7px",
};
export const MainQRContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;
export const LeftSideCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RightSideCont = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
`;
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const QRicon = styled(Qrcode)`
  opacity: 0.9;
  color: #dddddd;
  cursor: pointer;
`;

export const Mailicon = styled(Mail)`
  opacity: 1;
  color: #dddddd;
`;

export const Settingicon = styled(Settings2Outline)`
  opacity: 1;
  color: #dddddd;
`;

export const Alerticon = styled(Alert)`
  opacity: 1;
  color: #dddddd;
`;
export const Ellipseicon = styled(Ellipse)`
  top: -2px;
  left: 16px;
  width: 12px;
  height: 12px;
  color: #681e46;
  background: #681e46;
  border: 1px solid #ffffff;
  opacity: 1;
  position: absolute;
  border-radius: 50%;
`;

export const MutedText = styled.p`
  margin: 0;
  color: #dadada;
`;
