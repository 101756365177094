import styled from "styled-components";

export const PrimaryText = styled.p`
  font-size: 1rem;
  margin: 0;
`;
export const PrimarySpan = styled.span`
  font-size: 1rem;
  margin: 0;
`;
export const SecondaryText = styled.p`
  font-size: 0.9rem;
  margin: 0;
  color: #000;
`;

export const Group = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;
export const GroupItem = styled.div`
  flex: 1;
`;

export const CustomFormLabel = styled.label`
  display: block;
  font-size: 0.9rem;
  margin: 0;
  margin-bottom: 10px;
  color: #a2a2a2;
`;
export const CustomFormInput = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid #000;
  display: ${({ display }) => display || "inline-block"};
  padding: ${({ padding }) => (padding ? padding : "5px 10px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "auto")};
  ::placeholder {
    color: #979797;
  }
  &:disabled {
    background-color: ${({ disabledBgColor }) => disabledBgColor || "#fff"};
  }
`;
