import { getDocs } from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import { updatedMomentNow } from "src/utils/helpers";
import {
  AttendanceContext,
  AttendanceModalContext,
} from "../../common/contexts/AdminAttendanceContext";
import CustomDropDown from "../../common/DropDown";
import { FirebaseContext } from "../../Firebase";
import AttendanceBlock from "./AttendanceBlock";
import StudentAttendanceBlock from "./StudentAttendanceBlock";

const StudentAttendanceListContainer = () => {
  const {
    instruments,
    schoolYears,
    selectedStudent,
    isReadOnly,
    setIsReadOnly,
  } = useContext(AttendanceContext);
  const { isOpen } = useContext(AttendanceModalContext);
  const firebase = useContext(FirebaseContext);
  const [selectedYear, setSelectedYear] = useState({
    label: "School Year",
    value: "",
  });

  const currentYear = schoolYears.find(({ id }) => id === selectedYear.value);
  const schoolStartDates = [];
  const schoolEndDates = [];
  for (let locationId in currentYear?.locations || []) {
    if (currentYear.locations?.[locationId]?.dates?.start_date) {
      schoolStartDates.push(
        moment(currentYear.locations[locationId].dates.start_date.toDate())
      );
      schoolEndDates.push(
        moment(currentYear.locations[locationId].dates.end_date.toDate())
      );
    }
  }
  const yearStartDate = moment.min(schoolStartDates);
  const yearEndDate = moment.max(schoolEndDates);

  const [selectedInstrument, setSelectedInstrument] = useState({
    label: "Instrument",
    value: "",
  });
  const [attendance, setAttendance] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const selectedLesson = useMemo(() => {
    if (selectedStudent?.lessons?.length && selectedInstrument.value !== "") {
      return selectedStudent.lessons.find(
        (lesson) => lesson.instrumentId === selectedInstrument.value
      );
    }
    return {};
  }, [selectedInstrument]);

  const yearsOptions = useMemo(() => {
    const options = schoolYears.map((schoolYears) => ({
      label: schoolYears.title,
      value: schoolYears.id,
    }));
    setSelectedYear((previous) => options?.[0] ?? previous);
    return options;
  }, [schoolYears]);
  const instrumentsOptions = useMemo(() => {
    if (selectedStudent?.lessons?.length) {
      const options = instruments
        .filter(
          ({ id }) =>
            selectedStudent.lessons.filter(
              ({ instrumentId }) => instrumentId === id
            ).length > 0
        )
        .map((instrument) => ({
          label: instrument.name,
          value: instrument.id,
        }));
      setSelectedInstrument((previous) => options?.[0] ?? previous);
      return options;
    }
    return [];
  }, [instruments, selectedStudent]);
  const getLessonAttendance = async () => {
    if (selectedStudent?.lessons?.length && selectedInstrument.value !== "") {
      const docs = await getDocs(
        firebase.getLessonAttendanceList(
          selectedLesson.lessonId,
          selectedStudent.id
        )
      );
      const docsObj = {
        extra: [],
      };

      docs.forEach((doc) => {
        const documentObject = {
          id: doc.id,
          ...doc.data(),
        };
        const attendanceDate = moment(documentObject.date.toDate());
        if (attendanceDate.isBetween(yearStartDate, yearEndDate)) {
          if (documentObject?.title) {
            docsObj.extra.push(documentObject);
          } else {
            docsObj[documentObject.weekNumber] = documentObject;
          }
        }
      });
      setAttendance(docsObj);
    }
  };
  useEffect(async () => {
    if (selectedYear?.value && !isOpen) {
      setIsReadOnly(
        yearEndDate ? moment(yearEndDate).isBefore(updatedMomentNow()) : true
      );
      setIsLoading(true);
      await getLessonAttendance();
      setIsLoading(false);
    }
  }, [selectedStudent, selectedInstrument, isOpen, isReadOnly, selectedYear]);
  const onYearSelect = (val) => {
    setSelectedYear(val);
  };
  const onInstrumentSelect = (val) => {
    setSelectedInstrument(val);
  };
  const attendanceArray = Object.keys(attendance)
    .filter((key) => key !== "extra")
    .map((key) => {
      if (selectedLesson) {
        // const lessonDate = moment(selectedLesson.lessonDate).add(key, "weeks");

        const weekNumber = parseInt(key, 10);
        const attendanceObject = attendance?.[weekNumber];
        const lessonDate = attendanceObject.date.toDate();
        return {
          lessonId: selectedLesson.lessonId,
          lessonDate,
          attendanceDate: attendanceObject
            ? moment(attendanceObject.date.toDate()).format("MM-DD-YYYY hh:mmA")
            : "",
          isAttended: !!attendanceObject,
          weekNumber,
          id: attendanceObject ? attendanceObject.id : undefined,
          type: attendanceObject ? attendanceObject.type : "normal",
          locationId: attendanceObject?.locationId ?? "",
        };
      }
      return {};
    });

  if (attendance?.extra) {
    attendanceArray.push(
      ...attendance.extra.map((attendanceObject, index) => {
        return {
          ...attendanceObject,
          title: `${attendanceObject.title} ${index + 1}`,
          attendanceDate: moment(attendanceObject.date.toDate()).format(
            "MM-DD-YYYY hh:mmA"
          ),
        };
      })
    );
  }
  return (
    <div className="col p-4">
      <div className="row mb-4">
        <div className="col">
          <CustomDropDown
            label={selectedYear.label}
            value={selectedYear.label}
            keyName={"label"}
            options={yearsOptions}
            onOptionClick={onYearSelect}
          />
        </div>
        <div className="col">
          <CustomDropDown
            label={selectedInstrument.label}
            value={selectedInstrument.label}
            keyName={"label"}
            options={instrumentsOptions}
            onOptionClick={onInstrumentSelect}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spinner />
        </div>
      ) : selectedStudent?.lessons?.length &&
        selectedInstrument.value !== "" ? (
        <div
          className="d-flex flex-wrap align-items-start pb-2"
          style={{ height: 500, maxHeight: "70vh", overflowY: "scroll" }}
        >
          {attendanceArray.map((date, i) => (
            <StudentAttendanceBlock
              attendance={date}
              key={i}
              lessonNumber={i + 1}
            />
          ))}
        </div>
      ) : (
        <div className={"alert alert-warning text-center"}>
          Select a student and a lesson to view attendance
        </div>
      )}
    </div>
  );
};
export default StudentAttendanceListContainer;
