import React, { useState } from "react";
import { SubmitSpinner } from "src/components/common";
import { LessonsLengthEnum } from "src/constants/LessonsLength";
import { PrimaryButton } from "src/utils/shared/styled";
import { CustomInput, CustomLabel, CustomSelectField } from "../styled";

const ModificationBody = ({ modificationData, initialData }) => {
  const {
    modificationsObj,
    handleFieldInputChange,
    enableField,
    disableField,
    saveModifications,
    loadingSaveChanges,
  } = modificationData;
  return (
    <div className="mt-5">
      {/* Title */}
      <div className="d-flex justify-content-evenly align-items-end mb-4">
        <div>
          <CustomLabel htmlFor="appointmentTitle">Title:</CustomLabel>
          <CustomInput
            disabled={!modificationsObj.title.isEnabled}
            className="form-control"
            placeholder="Title"
            name="title"
            type="text"
            value={modificationsObj.title.value}
            onChange={handleFieldInputChange}
          />
        </div>
        <div>
          {modificationsObj.title.isEnabled ? (
            <PrimaryButton
              onClick={() => disableField("title")}
              width="170px"
              backgroundColor="#d31717"
            >
              Cancel
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={() => enableField("title")} width="170px">
              Modify
            </PrimaryButton>
          )}
        </div>
      </div>

      {/* Date And Time */}
      <div className="d-flex justify-content-evenly align-items-end mb-4">
        <div>
          <CustomLabel htmlFor="appointmentTitle">Date And Time:</CustomLabel>
          <CustomInput
            disabled={!modificationsObj.date.isEnabled}
            className="form-control"
            placeholder="Date"
            name="date"
            type="datetime-local"
            value={modificationsObj.date.value}
            onChange={handleFieldInputChange}
          />
        </div>
        <div>
          {modificationsObj.date.isEnabled ? (
            <PrimaryButton
              onClick={() => disableField("date")}
              width="170px"
              backgroundColor="#d31717"
            >
              Cancel
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={() => enableField("date")} width="170px">
              Modify
            </PrimaryButton>
          )}
        </div>
      </div>

      {/* Duration */}
      <div className="d-flex justify-content-evenly align-items-end mb-4">
        <div>
          <CustomLabel htmlFor="appointmentTitle">Duration:</CustomLabel>
          <CustomSelectField
            disabled={!modificationsObj.duration.isEnabled}
            className="form-control"
            name="duration"
            value={modificationsObj.duration.value}
            onChange={handleFieldInputChange}
          >
            <option disabled value="">
              Duration
            </option>
            {Object.keys(LessonsLengthEnum).map((key) => (
              <option key={key} value={key}>
                {LessonsLengthEnum[key]}
              </option>
            ))}
          </CustomSelectField>
        </div>
        <div>
          {modificationsObj.duration.isEnabled ? (
            <PrimaryButton
              onClick={() => disableField("duration")}
              width="170px"
              backgroundColor="#d31717"
            >
              Cancel
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() => enableField("duration")}
              width="170px"
            >
              Modify
            </PrimaryButton>
          )}
        </div>
      </div>

      {/* Instrument */}
      <div className="d-flex justify-content-evenly align-items-end mb-4">
        <div>
          <CustomLabel htmlFor="appointmentTitle">Instrument:</CustomLabel>
          <CustomSelectField
            disabled={!modificationsObj.instrumentId.isEnabled}
            className="form-control"
            name="instrumentId"
            value={modificationsObj.instrumentId.value}
            onChange={handleFieldInputChange}
          >
            <option disabled value="">
              Instrument
            </option>
            {Object.values(initialData.instruments || {}).map((instrument) => (
              <option key={instrument.id} value={instrument.id}>
                {instrument.name}
              </option>
            ))}
          </CustomSelectField>
        </div>
        <div>
          {modificationsObj.instrumentId.isEnabled ? (
            <PrimaryButton
              onClick={() => disableField("instrumentId")}
              width="170px"
              backgroundColor="#d31717"
            >
              Cancel
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() => enableField("instrumentId")}
              width="170px"
            >
              Modify
            </PrimaryButton>
          )}
        </div>
      </div>
      <div onClick={saveModifications} className="text-center mt-5">
        <PrimaryButton>
          {loadingSaveChanges ? (
            <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
          ) : (
            "Save Changes"
          )}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ModificationBody;
