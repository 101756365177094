import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { viewComponents } from "../constants";

const initialDataInitialValues = {
  groupClass: null,
  enrollments: [],
  paymentOptions: [],
  users: [], // only the group class related users
  absences: [],
  groupClassPayments: [],
};
const useGroupClassInfo = ({ groupClassId }) => {
  const {
    getGroupClassById,
    getGroupClassEnrollments,
    getUsersByIds,
    getPaymentOptionsByGroupClassId,
    getGroupClassPayments,
    getPerLessonAbsencesByLessonId,
  } = useFirebaseFns();

  const [currentView, setCurrentView] = useState(
    viewComponents.classRoster.value
  );

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState("");

  const currentStudent = useMemo(() => {
    if (!currentStudentId) return;

    return initialData.users.find(({ id }) => id === currentStudentId);
  }, [currentStudentId, initialData]);

  useEffect(() => {
    if (!groupClassId) return;

    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);

        const [
          groupClass,
          enrollments,
          paymentOptions,
          absences,
          groupClassPayments,
        ] = await Promise.all([
          getGroupClassById(groupClassId),
          getGroupClassEnrollments(groupClassId),
          getPaymentOptionsByGroupClassId(groupClassId),
          getPerLessonAbsencesByLessonId(groupClassId),
          getGroupClassPayments({ groupClassId }),
        ]);

        const teachersIds = groupClass?.teachersIds || [];
        const studentsIds = [
          ...new Set(enrollments?.map(({ studentId }) => studentId)),
        ];
        const combinedIds = [...teachersIds, ...studentsIds];

        const users = await getUsersByIds(combinedIds);

        setInitialData({
          groupClass,
          enrollments,
          paymentOptions,
          users,
          absences,
          groupClassPayments,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [groupClassId]);

  return {
    initialData,
    loadingInitialData,
    currentView,
    setCurrentView,
    setCurrentStudentId,
    currentStudent,
  };
};

export default useGroupClassInfo;
