import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const confirmAbsenceApproval = async ({
  type,
  to_email,
  to_name,
  from_name,
  time,
  message,
  date,
}) => {
  try {
    const approveMessage = `
    Thank you for submitting your request.
    Your absence on ${date.toLocaleDateString()} was approved.`;
    const PAMessage = `
    Thank you for submitting your request.
    Your absence on ${date.toLocaleDateString()} was partially approved. 
    Message from admin: ${message} 
    Approved time: ${time}`;

    await emailjs.send(
      "service_m7j255t",
      "template_lb0du76",
      {
        subject: `Your absence was ${
          type === "PA" ? "partially approved" : "approved"
        }`,
        to_name,
        from_name,
        to_email,
        message: type === "PA" ? PAMessage : approveMessage,
        attachmentSrc:
          "https://firebasestorage.googleapis.com/v0/b/artisconnect-a3c6e.appspot.com/o/image_2024-06-20_182658749.png?alt=media&token=3ef3653b-dd5e-4a98-8e28-feb19b137d24",
      },
      "l6-vMvPNxhUUxuyxh"
    );
  } catch (error) {
    console.error(error);
    toast.error("An error occurred");
  }
};

const convertTimeStringToNumeric = (timeString) => {
  if (!timeString) return "";
  const [hours, minutes] = timeString.split(":");

  const date = new Date();
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

export { confirmAbsenceApproval, convertTimeStringToNumeric };
