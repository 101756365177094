import React from "react";
import moment from "moment";
import { getAgeFromDate } from "src/utils/helpers";
import {
  ClickableTableCell,
  CustomTable,
  CustomTableContainer,
  PrimaryButton,
  TableRow,
} from "src/utils/shared/styled";
import { SectionContainer } from "../../styled";
import useModal from "src/hooks/useModal";
import GroupClassAbsenceRequestModal from "../../../GroupClassAbsenceRequestModal";
import ViewGroupClassStudentInfoModal from "../../../ViewGroupClassStudentInfoModal";
import ViewGroupClassStudentPaymentModal from "../../../ViewGroupClassStudentPaymentModal";
import { groupClassPaymentTypes } from "src/constants/groupClassPaymentsConstants";

const RosterSection = ({
  groupClassId,
  enrollments,
  users,
  setCurrentStudentId,
  currentStudent,
  groupClassPayments,
  paymentOptions,
}) => {
  const isFree = paymentOptions.some(
    (option) => option.paymentType === groupClassPaymentTypes.FREE
  );
  const absenceRequestModalData = useModal();
  const studentInfoModalData = useModal();
  const paymentModalData = useModal();

  function handleStudentClick(studentId) {
    setCurrentStudentId(studentId);
    studentInfoModalData.openModal();
  }
  function handlePaymentModalClick(studentId) {
    setCurrentStudentId(studentId);
    paymentModalData.openModal();
  }

  return (
    <>
      <SectionContainer>
        <h6 className="d-flex justify-content-between">
          <span>Class Roster</span>
          <span>
            <PrimaryButton
              onClick={absenceRequestModalData.openModal}
              width="170px"
              padding="7px"
            >
              Absence Request
            </PrimaryButton>
          </span>
        </h6>
        <hr />
        <div
          style={{
            overflow: "auto",
          }}
        >
          <CustomTableContainer minHeight="0px">
            <CustomTable>
              <thead style={{ backgroundColor: "#f5f2f4" }}>
                <TableRow height="70px">
                  <th>
                    <div>No.</div>
                  </th>
                  <th>
                    <div>Name</div>
                  </th>
                  <th>
                    <div>Age</div>
                  </th>
                  <th>
                    <div>Enrollment Date</div>
                  </th>
                  <th>
                    <div>Payment</div>
                  </th>
                  <th>
                    <div>Payment Status</div>
                  </th>
                  <th>
                    <div>Primary Contact Email</div>
                  </th>
                  <th>
                    <div>Status</div>
                  </th>
                </TableRow>
              </thead>
              <tbody>
                {enrollments.map((enrollment, i) => {
                  // preparing table values
                  const { studentId, enrollmentDate } = enrollment;

                  const student = users.find(({ id }) => id === studentId);
                  const studentName = student.fullName;
                  const studentLoginEmail = student.primaryEmail;
                  const studentPrimaryContactEmail =
                    student.primaryContactEmail;

                  const studentAge =
                    student.birthday &&
                    getAgeFromDate(
                      moment(student.birthday, "YYYY-MM-DD").toDate()
                    );

                  const formattedEnrollmentDate = enrollmentDate
                    ? moment(enrollmentDate).format("MM-DD-YYYY hh:mm A")
                    : undefined;

                  const paymentObj = groupClassPayments.find(
                    ({ userId }) => userId === enrollment.studentId
                  );

                  const paymentStatus = paymentObj ? "Paid" : "Not Paid";

                  const isEvenRow = i % 2 === 0;

                  const rowNumber = i + 1;
                  return (
                    <TableRow
                      height="70px"
                      style={{ ...(isEvenRow && { background: "#f9f9f9" }) }}
                      key={i}
                    >
                      <td>{rowNumber}</td>
                      <ClickableTableCell
                        onClick={() => handleStudentClick(studentId)}
                      >
                        {studentName || "N/A"}
                      </ClickableTableCell>
                      <td>{studentAge || "N/A"}</td>

                      <td>{formattedEnrollmentDate || "N/A"}</td>
                      <td>
                        {isFree ? (
                          "Free"
                        ) : (
                          <PrimaryButton
                            onClick={() => handlePaymentModalClick(studentId)}
                            padding="7px"
                            width="120px"
                          >
                            Open
                          </PrimaryButton>
                        )}
                      </td>
                      <td>{paymentStatus}</td>
                      <td>{studentPrimaryContactEmail || "N/A"}</td>
                      <td>Active</td>
                    </TableRow>
                  );
                })}
              </tbody>
            </CustomTable>
          </CustomTableContainer>
        </div>
      </SectionContainer>

      {/* MODALS */}
      {paymentModalData.isModalOpen && (
        <ViewGroupClassStudentPaymentModal
          modalData={paymentModalData}
          groupClassId={groupClassId}
          studentId={currentStudent.id}
        />
      )}
      {absenceRequestModalData.isModalOpen && (
        <GroupClassAbsenceRequestModal
          modalData={absenceRequestModalData}
          groupClassId={groupClassId}
        />
      )}
      {studentInfoModalData.isModalOpen && currentStudent && (
        <ViewGroupClassStudentInfoModal
          studentId={currentStudent.id}
          modalData={studentInfoModalData}
        />
      )}
    </>
  );
};

export default RosterSection;
