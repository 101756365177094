import React from "react";
import Library from "../../common/Library/Library";

const AdminLibrary = ({ user }) => {
  return (
    <Library user={user} isAdmin={true}/>
  );
};

export default AdminLibrary;
