import moment from "moment";
import React, { useMemo } from "react";
import { SingleDatePicker } from "react-dates";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { CalendarPickerWrapper } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";

const GroupClassAbsenceRequestBody = ({ hookData, groupClassId }) => {
  const {
    initialData,
    loadingInitialData,
    handleStudentIdChange,
    handleSelectedDateChange,
    selectedStudent,
    selectedDate,
    isDatePickerOpen,
    setIsDatePickerOpen,
    classDates,
    isSaving,
    requestAbsence,
  } = hookData;
  const { students } = initialData;

  const options = useMemo(() => {
    return students.map((student) => mapStudentToOption(student));
  }, [students]);

  const handleOptionsChange = (option) => {
    if (!option) return;

    handleStudentIdChange(option);
  };

  const selectedOption = selectedStudent
    ? mapStudentToOption(selectedStudent)
    : null;

  return (
    <div className="p-4">
      <div className="mb-3">
        <CustomLabel>Student:</CustomLabel>
        <CustomReactSelect
          isDisabled={false}
          isMulti={false}
          placeholder="Select A Student.."
          width="100%"
          options={options}
          onChange={handleOptionsChange}
          value={selectedOption}
          wrapOptions={false}
        />
      </div>
      <div className="mb-3">
        <CustomLabel>Class Date:</CustomLabel>
        <CalendarPickerWrapper>
          <SingleDatePicker
            id="DatePicker"
            date={selectedDate} // momentPropTypes.momentObj or null
            onDateChange={(date) => {
              handleSelectedDateChange(date);
            }}
            focused={isDatePickerOpen} // PropTypes.bool
            onFocusChange={({ focused }) => {
              setIsDatePickerOpen(focused);
            }}
            // numberOfMonths={1}
            // showClearDate={true}
            isOutsideRange={(date) => {
              if (
                classDates.some((classDate) =>
                  moment(classDate).isSame(date, "date")
                )
              ) {
                return false;
              } else {
                return true;
              }
            }}
          />
        </CalendarPickerWrapper>
      </div>
      <div className="text-center">
        <PrimaryButtonWithLoading onClick={requestAbsence} isLoading={isSaving}>
          Confirm
        </PrimaryButtonWithLoading>
      </div>
    </div>
  );
};

const mapStudentToOption = (student) => {
  if (!student) return;

  const label = student.fullName;
  const option = {
    label,
    value: student.id,
  };

  return option;
};

export default GroupClassAbsenceRequestBody;
