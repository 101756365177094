import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CURRENT_TZ_LS_PROP_NAME } from "src/constants/constantVariables";

const useTimezone = () => {
  const [selectedTimezone, setSelectedTimezone] = useState("");

  const saveSelectedTimezone = () => {
    window.localStorage.setItem(CURRENT_TZ_LS_PROP_NAME, selectedTimezone);
    window.location.reload();
  };

  useEffect(() => {
    const lsCurrentTimezone = window.localStorage.getItem(
      CURRENT_TZ_LS_PROP_NAME
    );

    if (lsCurrentTimezone) {
      setSelectedTimezone(lsCurrentTimezone);
    }
  }, []);
  return {
    selectedTimezone,
    setSelectedTimezone,
    saveSelectedTimezone,
  };
};

export default useTimezone;
