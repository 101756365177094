import React from "react";
import { FormGroup } from "reactstrap";
import {
  CustomSwitch,
  SettingDescription,
  SwitchRowContainer,
} from "src/utils/shared/styled";

const SettingsRow = ({ isOn, onChange, description }) => {
  return (
    <SwitchRowContainer>
      <SettingDescription>{description}</SettingDescription>
      <FormGroup switch>
        <CustomSwitch
          type="switch"
          role="switch"
          checked={isOn}
          onChange={onChange}
        />
      </FormGroup>
    </SwitchRowContainer>
  );
};

export default SettingsRow;
