import "./style.css";
import penIconImage from "./penIcon.svg";

import React from "react";

function PenIcon({
  changeModalData,
  fieldName,
  headerText,
  inputType,
  bodyText,
}) {
  return (
    <picture
      className="pen-icon-image"
      onClick={() =>
        changeModalData({
          activeStatus: true,
          fieldName,
          headerText,
          inputType,
          bodyText,
        })
      }
    >
      <img loading="lazy" src={penIconImage} alt="pen icon image" />
    </picture>
  );
}

export default PenIcon;
