import React from "react";
import {
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import { PackageLessonContextProvider } from "./contexts/PackageLessonContext";
import PackageLessonBody from "./PackageLessonBody";
import { CloseIcon } from "./styled";

const ViewPackageLessonInfoModal = ({ modalData, currentLesson }) => {
  const { isModalOpen, closeModal, toggleModal } = modalData;
  return (
    <CustomModal
      size="lg"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding="0px"
    >
      <CustomModalHeader
        tag="div"
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
        padding="25px"
      >
        <h2>Package Lesson Info</h2>
      </CustomModalHeader>
      <CustomModalBody padding="0px">
        <PackageLessonContextProvider packageLesson={currentLesson}>
          <PackageLessonBody modalData={modalData} />
        </PackageLessonContextProvider>
        <div className="mt-5"></div>
      </CustomModalBody>
    </CustomModal>
  );
};

export default ViewPackageLessonInfoModal;
