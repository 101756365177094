import { useNavigate } from "react-router-dom";
import TeamMemberHeaderWrapper from "../TeamMemberHeaderWrapper";
import "./style.css";
import ModalWrapper from "src/components/common/ModalWrapper";

function TeamProfileModal({
  name,
  profession,
  about,
  image,
  social,
  specialization,
  setIsModalActive,
}) {
  const navigate = useNavigate();
  const closeModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModalActive(false);
    navigate("/our-team", { replace: true });
  };

  const teamMemberHeaderWrapperProps = {
    name,
    image,
    social,
    profession,
    specialization,
  };
  const backButtonHandler = (e) => {
    setIsModalActive(false);
    navigate(-1, {
      fallback: "/dashboard", // If going back would leave the website, navigate to /dashboard instead
    });
  };
  return (
    <ModalWrapper onClickToClose={closeModal}>
      <div className="teamProfileExtended">
        <button className="backButton" onClick={backButtonHandler}>
          back
        </button>
        <TeamMemberHeaderWrapper
          {...teamMemberHeaderWrapperProps}
          isModal={true}
        />
        {about}
      </div>
    </ModalWrapper>
  );
}

export default TeamProfileModal;
