import { Input } from "antd";
import styled from "styled-components";

const InputWrapper = styled.div`
  padding: 24px;

  label {
    font-size: 18px;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  padding: 12px 6px;
  margin-top: 16px;
  font-size: 18px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 16px;
  width: 100%;
`;

export { ButtonsWrapper, InputWrapper, StyledInput };
