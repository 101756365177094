import useFirebaseFns from "src/hooks/useFirebaseFns";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  combineArrayToStr,
  isRecurringActivityAbsence,
} from "src/utils/helpers";
import { groupClassLocationStatuses } from "src/constants/groupClassConstants";
import { updateDoc } from "firebase/firestore";
import { FirebaseContext } from "src/components/Firebase";
import { toast } from "react-toastify";

import {
  ClassContainer,
  InfoContainer,
  InfoText,
  ClassInfoButtons,
} from "./styled";
import { GroupClassActionButton } from "src/components/Dashboard/GroupClass/styled";
import { Badge, Popconfirm } from "antd";
import { confirmClassCancel } from "../../helpers";

const ClassInfo = ({ users, classObj, i, absences, groupClassId }) => {
  const { duration, startDate, locationStatus, teachersIds } = classObj;
  const firebase = useContext(FirebaseContext);
  const { getGroupClassById } = useFirebaseFns();
  const [isCanceled, setIsCanceled] = useState(classObj?.isCanceled || false);

  useEffect(() => {
    setIsCanceled(classObj?.isCanceled || false);
  }, [classObj?.isCanceled]);

  const endDate = moment(startDate).add(duration, "minutes");

  const formattedDate = moment(startDate).format("MM/DD/YYYY hh:mm A");
  const formattedEndTime = moment(startDate)
    .add(duration, "minutes")
    .format("hh:mm A");
  const formattedDateAndTime = `${formattedDate} - ${formattedEndTime}`;
  const classDuration = `${duration} Minutes`;
  const locationStatusStr =
    locationStatus === groupClassLocationStatuses.IN_PERSON
      ? "In Person"
      : locationStatus === groupClassLocationStatuses.VIRTUAL
      ? "Virtual"
      : "In Person/Virtual";

  const classTeachersNames = teachersIds?.map(
    (teacherId) => users.find(({ id }) => id === teacherId)?.fullName
  );
  const teacherNamesStr = combineArrayToStr(classTeachersNames);
  const classNumber = i + 1;

  const classAbsences = absences.filter((absence) => {
    const isAbsence = isRecurringActivityAbsence(
      {
        start: startDate,
        end: endDate,
        id: groupClassId,
      },
      absence,
      { excludeTA: true, excludeGCAbsence: false }
    );
    return isAbsence;
  });

  const absencesStudentIds = [
    ...new Set(
      classAbsences.map(({ studentId }) => studentId).filter((el) => el)
    ),
  ];
  const absencesStudentNames = absencesStudentIds.map(
    (id) => users.find((user) => user.id === id)?.fullName
  );
  const studentAbsenceNames = combineArrayToStr(absencesStudentNames);

  const toggleClassCancel = async (classId) => {
    try {
      const groupClass = await getGroupClassById(groupClassId);
      const groupClassToUpdate = firebase.groupClass(groupClassId);

      const updatedClasses = groupClass?.classes?.map((c) =>
        c?.id === classId ? { ...c, isCanceled: !isCanceled } : c
      );

      await updateDoc(groupClassToUpdate, {
        classes: updatedClasses,
      });
      setIsCanceled(!isCanceled);

      for (let i = 0; i < users?.length; i++) {
        await confirmClassCancel({
          to_email: users[i]?.primaryEmail,
          to_name: users[i]?.fullName,
          isCanceled: !isCanceled,
          classDate: startDate?.toLocaleDateString("en-US"),
        });
      }

      toast.info(`Class ${isCanceled ? "resumed" : "canceled"}`);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    }
  };

  const infoMarkup = (
    <ClassContainer className="mb-3">
      <div style={{ marginTop: 32 }}>
        <InfoContainer>
          <InfoText bold className="me-2">
            Class:
          </InfoText>
          <InfoText>{classNumber}</InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoText bold className="me-2">
            Date/Time:
          </InfoText>
          <InfoText>{formattedDateAndTime}</InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoText bold className="me-2">
            Duration:
          </InfoText>
          <InfoText>{classDuration}</InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoText bold className="me-2">
            Class Type:
          </InfoText>
          <InfoText>{locationStatusStr}</InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoText bold className="me-2">
            Instructor:
          </InfoText>
          <InfoText>{teacherNamesStr}</InfoText>
        </InfoContainer>
        <InfoContainer>
          <InfoText bold className="me-2">
            Student Absence:
          </InfoText>
          <InfoText>{studentAbsenceNames}</InfoText>
        </InfoContainer>
      </div>
      <ClassInfoButtons>
        <Popconfirm
          onConfirm={() => toggleClassCancel(classObj?.id)}
          title={`${isCanceled ? "Resume" : "Cancel"} this class?`}
          okText="Yes"
          cancelText="No"
          zIndex={9999}
        >
          <GroupClassActionButton>
            {isCanceled ? "Resume" : "Cancel"} class
          </GroupClassActionButton>
        </Popconfirm>
        {/* <GroupClassActionButton>Make up</GroupClassActionButton> */}
      </ClassInfoButtons>
    </ClassContainer>
  );

  return isCanceled ? (
    <Badge.Ribbon
      text="Canceled"
      placement="end"
      color="#2196F3"
      style={{ fontSize: 20 }}
    >
      {infoMarkup}
    </Badge.Ribbon>
  ) : (
    infoMarkup
  );
};

export default ClassInfo;
