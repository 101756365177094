import React, { useState } from "react";
import CustomMiniCalendar from "../../common/components/CustomMiniCalendar";

const MiniCalendarSection = ({ dateValue, onDateValueChange }) => {
  const [miniCalendarKey, setMiniCalendarKey] = useState(0);

  return (
    <div>
      <CustomMiniCalendar
        calendarKey={miniCalendarKey}
        dateValue={dateValue}
        onDateValueChange={onDateValueChange}
      />
    </div>
  );
};

export default MiniCalendarSection;
