import { feedItems } from "src/constants/feedItemsEnum";

export const modalDetailsMap = {
  [feedItems.news.type]: {
    title1: "News",
    title2: "Add News",
  },
  [feedItems.announcements.type]: {
    title1: "Announcement",
    title2: "Add Announcement",
  },
  [feedItems.otherTopics.type]: {
    title1: "For Fun",
    title2: "Fun Things",
  },
  [feedItems.workUpdates.type]: {
    title1: "Work Update",
    title2: "Add Work Update",
  },
};
