import React from "react";
import CreatePrivateLessonModal from "../CreatePrivateLessonModal";

const ReturnFromSummerBreakModal = ({
  modalData,
  studentId,
  privateLessonId,
  summerBreakId,
}) => {
  const returnFromSummerBreakData = {
    privateLessonId,
    summerBreakId,
  };
  return (
    <CreatePrivateLessonModal
      modalData={modalData}
      studentId={studentId}
      headerTitle="Add New Weekly lessons"
      isReturnFromSummerBreak={true}
      returnFromSummerBreakData={returnFromSummerBreakData}
    />
  );
};

export default ReturnFromSummerBreakModal;
