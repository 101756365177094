import React from "react";
import { Spinner } from "reactstrap";
import { useFeedSectionContext } from "../../contexts/FeedSectionContext";
import { FeedItemContainer, FeedItemsContainer } from "../../styled";
import FeedItem from "./FeedItem";

const FeedItems = () => {
  const { displayedFeedItems, loadingFeedItems } = useFeedSectionContext();
  const displayedItems = displayedFeedItems;

  if (loadingFeedItems) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: 200 }}
      >
        <Spinner
          style={{
            width: "3rem",
            height: "3rem",
            color: "#681e46",
          }}
        />
      </div>
    );
  }
  return (
    <FeedItemsContainer>
      {displayedItems.map((item) => {
        return <FeedItem key={item.id} feedItem={item} />;
      })}
    </FeedItemsContainer>
  );
};

export default FeedItems;
