const filterTypeOptions = [
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Quarter",
    value: "quarter",
  },
  {
    label: "Year",
    value: "year",
  },
  {
    label: "Exact date",
    value: "custom",
  },
];

export default filterTypeOptions;
