import React, { useRef } from "react";
import { LibraryModalFileUpload } from "../../styled-components/teacherLibraryStyledComponents";

const LibraryFileUploadInput = ({
  onUploadFile,
  onRemove,
  isUploading,
  label,
  files,
  customStyle = {},
}) => {
  const uploadRef = useRef();

  return (
    <LibraryModalFileUpload style={customStyle}>
      <div
        onClick={() => {
          uploadRef.current.click();
        }}
        className="justify-content-center label-container d-flex align-items-center"
      >
        <input ref={uploadRef} hidden type={"file"} onChange={onUploadFile} />
        <label style={{ color: "gray" }} className={"mb-0"}>
          {isUploading ? "uploading" : label}
        </label>
        <i style={{ color: "gray" }} className="fas fa-cloud-upload-alt mx-2" />
      </div>
    </LibraryModalFileUpload>
  );
};
export default LibraryFileUploadInput;
