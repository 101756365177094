import React, { useContext } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import CustomDropDown from "../../common/DropDown";
import {
  LessonNotesContext,
  SubmitHomeworkModalContext,
  ViewHomeworkModalContext,
  ViewNoteModalContext,
} from "../../common/Library/common/libraryContext";
import {
  CurveContainer,
  NoteModalHeader,
} from "../../common/styled-components/lessonNotesStyledComponents";
import {
  LibraryModal,
  LibraryModalHeader,
  TextButton,
} from "../../common/styled-components/teacherLibraryStyledComponents";

const BaseNoteModal = ({
  closeButtonText = "Cancel",
  hasAction = true,
  extraButton = null,
  actionButtonColor = "danger",
  centered,
  size = "sm",
  okAction,
  isOpen,
  toggle,
  disabled,
  isLoading,
  body,
  title,
  buttonText = "Ok",
}) => {
  const { selectedUser, setSelectedUser } = useContext(
    ViewHomeworkModalContext
  );
  const { notes, isEditable, students } = useContext(LessonNotesContext);
  const { homework } = useContext(ViewHomeworkModalContext);
  const { noteId: noteIdFromSubmitContext } = useContext(
    SubmitHomeworkModalContext
  );
  const { noteId } = homework;
  let { note } = useContext(ViewNoteModalContext);
  const studentsArray = [];
  if (!note?.id) {
    const id = noteIdFromSubmitContext || noteId;
    note = notes.find((n) => n.id === id);
  }
  if (note?.studentsIds?.length) {
    note.studentsIds.forEach((id) => {
      const student = students.find((s) => s.id === id);
      if (student) {
        studentsArray.push({
          id,
          value: id,
          fullName: student.fullName,
        });
      }
    });
  }
  const avatar = selectedUser?.avatar || selectedUser?.imageUrl;
  const name = selectedUser?.name || selectedUser?.fullName;
  return (
    <Modal className={"p-0"} centered isOpen={isOpen} size={"xl"}>
      <NoteModalHeader className="position-relative">
        <div className="position-absolute close-btn-container bg-white rounded-circle">
          <button
            onClick={() => {
              toggle(false);
            }}
            className={"close border-0 outline-0"}
          >
            <i className="fa fa-times" />
          </button>
        </div>
        <div className="curve-container position-absolute top-0 left-0 w-100">
          <svg
            width="100%"
            height="100%"
            id="svg"
            viewBox="0 0 750 150"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition duration-300 ease-in-out delay-150"
          >
            <path fill="#681E46" d="M0,150V0h750v150C750,150,375,70,0,150z" />
          </svg>
        </div>
        {note?.studentsIds?.length > 0 ? (
          <div className="position-relative user-details d-flex justify-content-center flex-column align-items-center">
            <div className="avatar overflow-hidden d-flex align-items-center justify-content-center bg-light rounded-circle border border-4 border-white">
              {avatar ? <img src={avatar} alt="user image" /> : null}
            </div>
            {isEditable &&
            note?.studentsIds?.length &&
            note?.studentsIds?.length > 1 ? (
              <div className="user-select">
                <CustomDropDown
                  label={selectedUser.fullName}
                  value={selectedUser.fullName}
                  keyName={"fullName"}
                  options={studentsArray}
                  onOptionClick={(option) => setSelectedUser(option.id)}
                />
              </div>
            ) : (
              <h5 className="user-name text-capitalize">{name}</h5>
            )}
          </div>
        ) : null}
      </NoteModalHeader>
      <ModalBody className={"pt-2 pb-0"}>{body}</ModalBody>
      <ModalFooter className={"w-100 border-0 pt-0"}>
        <div className="d-flex w-100 align-items-center justify-content-center">
          <Button
            className="rounded-pill"
            color="secondary"
            onClick={() => toggle(false)}
          >
            {closeButtonText}
          </Button>
          {note?.studentsIds?.length > 0 ? (
            <>
              {hasAction ? (
                <Button
                  className={"rounded-pill ms-2 bg-purple"}
                  color={actionButtonColor}
                  onClick={okAction}
                  disabled={disabled || isLoading}
                >
                  {buttonText} {isLoading ? <Spinner size={"sm"} /> : null}
                </Button>
              ) : null}
              {extraButton}
            </>
          ) : null}
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default BaseNoteModal;
