import { getDocs } from "firebase/firestore";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import { getCurrentTimeline } from "src/utils/helpers";
import { AttendanceContext } from "../../common/contexts/AdminAttendanceContext";
import { FirebaseContext } from "../../Firebase";
import StudentAttendanceListContainer from "./StudentAttendanceListContainer";

const StudentAttendanceList = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [instruments, setInstruments] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [locations, setLocations] = useState([]);
  const getLocations = async () => {
    const locationsDocs = await getDocs(firebase.locations());
    const locationsArray = [];
    locationsDocs.forEach((location) =>
      locationsArray.push({ ...location.data(), id: location.id })
    );
    setLocations(locationsArray);
  };
  const getInstruments = async () => {
    const instrumentsDocs = await getDocs(firebase.instruments());
    const instrumentsArray = [];
    instrumentsDocs.forEach((instrument) =>
      instrumentsArray.push({ ...instrument.data(), id: instrument.id })
    );
    setInstruments(instrumentsArray);
  };
  const getSchoolYears = async () => {
    const schoolYearsDocs = await getDocs(firebase.getSchoolYears());
    const schoolYearsArray = [];
    schoolYearsDocs.forEach((schoolYear) =>
      schoolYearsArray.push({ ...schoolYear.data(), id: schoolYear.id })
    );
    setSchoolYears(schoolYearsArray);
  };
  const getPrivateLessons = async () => {
    const lessonsSnap = await getDocs(
      firebase.getStudentPrivateLessons(user.uid)
    );
    // Doesnt consider package lsns
    const privateLessons = parseFirebaseDoc(lessonsSnap.docs);
    const lessons = [];
    privateLessons.forEach((privateLesson) => {
      lessons.push({
        lessonId: privateLesson.id,
        instrumentId: privateLesson.instrumentId,
      });
    });
    setSelectedStudent({
      id: user.uid,
      lessons,
    });
  };
  useEffect(async () => {
    if (user.uid) {
      setIsLoading(true);
      await Promise.all([getInstruments(), getSchoolYears(), getLocations()]);
      await getPrivateLessons();
      setIsLoading(false);
    }
  }, [user?.uid]);
  const attendanceContextValue = {
    selectedStudent,
    instruments,
    schoolYears,
    locations,
    isReadOnly,
    setIsReadOnly,
    isStudent: true,
  };
  return (
    <div className={"h-100 d-flex"}>
      {isLoading ? (
        <div className="d-flex h-100 align-items-center justify-content-center col">
          <Spinner />
        </div>
      ) : (
        <AttendanceContext.Provider value={attendanceContextValue}>
          <StudentAttendanceListContainer />
        </AttendanceContext.Provider>
      )}
    </div>
  );
};
export default StudentAttendanceList;
