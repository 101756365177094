import React from "react";
import {
  AttachmentItemContainer,
  AttachmentName,
  AttachmentsListContainer,
  CrossIcon,
  CrossIconContainer,
} from "../../styled";

const AttachmentsList = ({ attachments, onAttachmentsChange }) => {
  const onRemoveAttachment = (attachmentId) => {
    const updatedAttachments = attachments.filter(
      ({ id }) => id !== attachmentId
    );
    onAttachmentsChange(updatedAttachments);
  };
  return (
    <AttachmentsListContainer>
      {attachments.map((attachment) => {
        const { id, file } = attachment;
        return (
          <AttachmentItemContainer key={id}>
            <AttachmentName>{file.name}</AttachmentName>
            <CrossIconContainer>
              <CrossIcon onClick={() => onRemoveAttachment(id)} />
            </CrossIconContainer>
          </AttachmentItemContainer>
        );
      })}
    </AttachmentsListContainer>
  );
};

export default AttachmentsList;
