import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const formFieldsInitialValues = {
  title: "",
  body: "",
  forRoles: [],
};
const useFormData = (feedItem, user) => {
  const userId = user.id || user.uid;
  const { updateFeedItem } = useFirebaseFns();
  const [formFields, setFormFields] = useState(formFieldsInitialValues);
  const [isLoading, setIsLoading] = useState(false);

  const onFormFieldChange = (name, value) => {
    setFormFields((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  };
  const onRolesChange = (role, checked) => {
    if (checked === true) {
      setFormFields((oldVal) => ({
        ...oldVal,
        forRoles: [...oldVal.forRoles, role],
      }));
    } else {
      setFormFields((oldVal) => ({
        ...oldVal,
        forRoles: oldVal.forRoles.filter((forRole) => forRole !== role),
      }));
    }
  };

  const saveFormData = async () => {
    try {
      const { title, body, forRoles } = formFields;
      if (!title || !body || !forRoles?.length) {
        toast.warn("Please fill all required fields");
        return;
      }
      setIsLoading(true);
      const updateObj = {
        title,
        body,
        forRoles,
        updatedAt: new Date(),
        updatedBy: userId,
      };
      console.log({ updateObj });

      await updateFeedItem(feedItem.id, updateObj);
      toast.success("Updated Successfully");
      return updateObj;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFormFields({
      title: feedItem.title || "",
      body: feedItem.body || "",
      forRoles: feedItem.forRoles || [],
    });
  }, []);
  return {
    formFields,
    onFormFieldChange,
    onRolesChange,
    saveFormData,
    isLoading,
  };
};

export default useFormData;
