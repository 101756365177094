import React from "react";
import { feedItems } from "src/constants/feedItemsEnum";
import { newsUserRoles, UserRole } from "src/constants/UserRoleEnum";
import {
  CheckboxesContainer,
  CheckboxField,
  CheckboxWrapper,
} from "../../styled";

const ForRolesSection = ({ feedItem, formData }) => {
  const { formFields, onFormFieldChange, onRolesChange } = formData;
  const isNews = feedItem.type === feedItems.news.type;

  const isChecked = (role) => {
    return formFields.forRoles?.includes(role);
  };

  return (
    <CheckboxesContainer>
      <CheckboxWrapper>
        <CheckboxField
          checked={isChecked(UserRole.STUDENT)}
          onChange={(e) => onRolesChange(UserRole.STUDENT, e.target.checked)}
          type="checkbox"
          name="forRoles"
        />
        For Students
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          checked={isChecked(UserRole.PARENT)}
          onChange={(e) => onRolesChange(UserRole.PARENT, e.target.checked)}
          type="checkbox"
          name="forRoles"
        />
        For Parents
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          checked={isChecked(UserRole.TEACHER)}
          onChange={(e) => onRolesChange(UserRole.TEACHER, e.target.checked)}
          type="checkbox"
          name="forRoles"
        />
        For Teachers
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          type="checkbox"
          checked={isChecked(UserRole.ADMIN)}
          onChange={(e) => onRolesChange(UserRole.ADMIN, e.target.checked)}
          name="forRoles"
        />
        For Admins
      </CheckboxWrapper>
      <CheckboxWrapper>
        <CheckboxField
          type="checkbox"
          checked={isChecked(UserRole.SUPER_ADMIN)}
          onChange={(e) =>
            onRolesChange(UserRole.SUPER_ADMIN, e.target.checked)
          }
          name="forRoles"
        />
        For Super Admins
      </CheckboxWrapper>
      {isNews ? (
        <CheckboxWrapper>
          <CheckboxField
            type="checkbox"
            checked={isChecked(newsUserRoles.LANDING)}
            onChange={(e) =>
              onRolesChange(newsUserRoles.LANDING, e.target.checked)
            }
            name="forRoles"
          />
          For Landing Page
        </CheckboxWrapper>
      ) : null}
    </CheckboxesContainer>
  );
};

export default ForRolesSection;
