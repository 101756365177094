export const infoCardViews = {
  info: {
    name: "Class Info",
    value: "info",
  },
  enrollments: {
    name: "Enrolled Students",
    value: "enrollments",
  },
};
