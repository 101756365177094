import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const initialDataInitialValues = {
  users: [],
  absences: [],
};
const useGroupClassEventContent = ({ event, showAbsences }) => {
  const { getUsersByIds, getPerLessonAbsencesByLessonId } = useFirebaseFns();
  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  useEffect(() => {
    if (!event.id) return;

    async function fetch() {
      try {
        setLoadingInitialData(true);
        if (showAbsences) {
          const [absences] = await Promise.all([
            getPerLessonAbsencesByLessonId(event.mainLessonId),
          ]);

          const absencesStudentIds = [
            ...new Set(
              absences.map(({ studentId }) => studentId).filter((el) => el)
            ),
          ];

          const users = await getUsersByIds(absencesStudentIds);

          setInitialData({
            absences,
            users,
          });
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    }
    fetch();
  }, [event.id]);

  return { initialData, loadingInitialData };
};

export default useGroupClassEventContent;
