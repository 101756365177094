import React from "react";
import {
  isPackagePrivateLesson,
  isRegularPrivateLesson,
  privateLessonTypes,
} from "src/constants/eventsEnum";
import { CustomLabel } from "src/utils/styled";
import styled from "styled-components";

const RadioWrapper = styled.label`
  display: inline-block;
  padding: 10px 0;
  margin-right: 25px;
`;

export const RadioInput = styled.input`
  display: inline-block;
  margin-right: 10px;
`;
const LessonTypeSelection = ({ selectedLessonType, setSelectedLessonType }) => {
  return (
    <div>
      <CustomLabel className="me-4">Lesson Type:</CustomLabel>
      <RadioWrapper>
        <RadioInput
          type="radio"
          value={privateLessonTypes.REGULAR}
          checked={isRegularPrivateLesson(selectedLessonType)}
          onChange={() => {
            setSelectedLessonType(privateLessonTypes.REGULAR);
          }}
        />
        Regular Weekly
      </RadioWrapper>
      <RadioWrapper>
        <RadioInput
          type="radio"
          value={privateLessonTypes.PACKAGE}
          checked={isPackagePrivateLesson(selectedLessonType)}
          onChange={() => {
            setSelectedLessonType(privateLessonTypes.PACKAGE);
          }}
        />
        10 Lessons Package
      </RadioWrapper>
    </div>
  );
};

export default LessonTypeSelection;
