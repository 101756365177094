import moment from "moment";
import React from "react";
import { weekDays } from "src/constants/weekDays";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import useModal from "src/hooks/useModal";
import { PrimaryButton } from "src/utils/shared/styled";
import styled from "styled-components";
import ConfirmationModal from "../../../../ConfirmationModal";
import EditRegularLessonTimelineModal from "../../../../EditRegularLessonTimelineModal";
import { Container, RedBarContainer, RedBarText } from "../../styled";
import useLessonHistory from "../hooks/useLessonHistory";
import {
  checkIfCanceledFutureEvents,
  checkIfSummerBreakReturnIsScheduled,
  checkIfTimelineObjIsSummerBreakReturn,
  updatedMomentNow,
} from "src/utils/helpers";
import TimelineItem from "./TimelineItem";
import { TimelineContainer } from "../styled";
import SummerBreakItem from "./SummerBreakItem";
import EditSummerBreakModal from "../../../../EditSummerBreakModal";

const LessonHistoryBody = ({
  lessonHistoryModalData,
  regularPrivateLesson,
}) => {
  const { teachers, locations } = useStudentInfoContext();

  const {
    currentTimeline,
    setCurrentTimelineId,
    deleteTimeline,
    isSubmitting,
    currentSummerBreakId,
    currentSummerBreak,
    setCurrentSummerBreakId,
    deleteSummerBreak,
    historyList,
  } = useLessonHistory({
    regularPrivateLesson,
  });

  const editTimelineModalData = useModal();
  const deleteTimelineModalData = useModal();
  const editSummerBreakModalData = useModal();
  const deleteSummerBreakModalData = useModal();

  const handleEditTimelineClick = (timelineId) => {
    setCurrentTimelineId(timelineId);
    editTimelineModalData.openModal();
  };
  const handleDeleteTimelineClick = (timelineId) => {
    setCurrentTimelineId(timelineId);
    deleteTimelineModalData.openModal();
  };
  const handleApproveDeleteTimeline = async () => {
    await deleteTimeline({ restorePrevTimeline: false });
    window.location.reload();
  };
  const handleApproveDeleteTimelineAndRestorePrevTimeline = async () => {
    await deleteTimeline({ restorePrevTimeline: true });
    window.location.reload();
  };

  const handleEditSummerBreakeClick = (summerBreakId) => {
    setCurrentSummerBreakId(summerBreakId);
    editSummerBreakModalData.openModal();
  };
  const handleDeleteSummerBreakClick = (summerBreakId) => {
    setCurrentSummerBreakId(summerBreakId);
    deleteSummerBreakModalData.openModal();
  };

  const handleApproveDeleteSummerBreak = async () => {
    await deleteSummerBreak({ restorePrevTimeline: false });
    window.location.reload();
  };
  const handleApproveDeleteSummerBreakAndRestorePrevTimeline = async () => {
    await deleteSummerBreak({ restorePrevTimeline: true });
    window.location.reload();
  };
  const handleDeclineTimelineDelete = () => {
    deleteTimelineModalData.closeModal();
  };
  const handleDeclineBreakDelete = () => {
    deleteSummerBreakModalData.closeModal();
  };

  const { timeline, withdrawal } = regularPrivateLesson || {};
  const showDeleteTimelineBtn = timeline?.length > 1;

  const withdrawalDate = withdrawal?.withdrawalDate;
  const lastLessonDate = withdrawalDate
    ? moment(withdrawalDate).subtract(1, "week").format("MM/DD/YYYY")
    : null;
  const isWithdrawnInFuture = withdrawalDate
    ? updatedMomentNow().isBefore(withdrawalDate, "minutes")
    : false;
  const isWithdrawnInPast = withdrawalDate
    ? updatedMomentNow().isSameOrAfter(withdrawalDate, "minutes")
    : false;

  return (
    <>
      <Container>
        {/* Red Bar */}
        <RedBarContainer>
          <RedBarText>Lesson History</RedBarText>
        </RedBarContainer>
        <div
          style={{
            // padding: "15px 35px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "400px",
            overflow: "auto",
          }}
        >
          <div className="w-100 p-4">
            {isWithdrawnInFuture ? (
              <TimelineContainer className="mb-3">
                <h6>Withdrawing, Last Lesson Date: {lastLessonDate}</h6>
              </TimelineContainer>
            ) : isWithdrawnInPast ? (
              <TimelineContainer className="mb-3">
                <h6>Withdrawed, Last Lesson Date: {lastLessonDate}</h6>
              </TimelineContainer>
            ) : null}
            {historyList?.map((historyObj) => {
              const { isTimeLine, isSummerBreak } = historyObj;
              if (isSummerBreak) {
                return (
                  <SummerBreakItem
                    summerBreak={historyObj}
                    handleDeleteSummerBreakClick={handleDeleteSummerBreakClick}
                    handleEditSummerBreakeClick={handleEditSummerBreakeClick}
                  />
                );
              } else {
                const isReturnFromSummerBreak =
                  checkIfTimelineObjIsSummerBreakReturn(
                    historyObj,
                    regularPrivateLesson.summerBreaks
                  );
                const isPastTimeline =
                  historyObj.lastDate &&
                  moment(historyObj.lastDate).isBefore(updatedMomentNow());
                return (
                  <TimelineItem
                    locations={locations}
                    teachers={teachers}
                    timelineObj={historyObj}
                    handleEditTimelineClick={handleEditTimelineClick}
                    handleDeleteTimelineClick={handleDeleteTimelineClick}
                    showDeleteTimelineBtn={showDeleteTimelineBtn}
                    isReturnFromSummerBreak={isReturnFromSummerBreak}
                    isPastTimeline={isPastTimeline}
                  />
                );
              }
            })}
          </div>
        </div>
      </Container>
      <div className="mt-4"></div>
      {/* MODALS */}
      {editTimelineModalData.isModalOpen && currentTimeline && (
        <EditRegularLessonTimelineModal
          modalData={editTimelineModalData}
          regularPrivateLesson={regularPrivateLesson}
          currentTimeline={currentTimeline}
        />
      )}
      {deleteTimelineModalData.isModalOpen && currentTimeline && (
        <ConfirmationModal
          modalData={deleteTimelineModalData}
          isSubmitting={isSubmitting}
          onApprove={handleApproveDeleteTimeline}
          onCancel={handleDeclineTimelineDelete}
          approveBtnText="Delete"
          cancelBtnText="Cancel"
          title="Delete Schedule ?"
          extraButtons={[
            {
              onClick: handleApproveDeleteTimelineAndRestorePrevTimeline,
              btnText: "Delete And Restore Original Schedule",
            },
          ]}
        />
      )}
      {deleteSummerBreakModalData.isModalOpen && currentSummerBreakId && (
        <ConfirmationModal
          modalData={deleteSummerBreakModalData}
          isSubmitting={isSubmitting}
          onApprove={handleApproveDeleteSummerBreak}
          onCancel={handleDeclineBreakDelete}
          approveBtnText="Delete"
          cancelBtnText="Cancel"
          title="Delete Summer Break ?"
          extraButtons={[
            {
              onClick: handleApproveDeleteSummerBreakAndRestorePrevTimeline,
              btnText: "Delete And Restore Original Schedule",
            },
          ]}
        />
      )}
      {editSummerBreakModalData.isModalOpen && currentSummerBreakId && (
        <EditSummerBreakModal
          modalData={editSummerBreakModalData}
          privateLessonId={regularPrivateLesson.id}
          summerBreakId={currentSummerBreakId}
          onEditSuccess={() => window.location.reload()}
        />
      )}
    </>
  );
};

export default LessonHistoryBody;
