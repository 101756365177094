export const groupClassPaymentTypes = {
  ONE_TIME: 1,
  SCHEDULED: 2,
  BOTH: 3,
  FREE: 4,
};
export const paymentStudentTypes = {};

export const groupClassScheduledPaymentIntervals = {
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
};

export const groupClassPaymentExcutedAt = {
  weekly: {
    SATURDAY: "saturday",
    SUNDAY: "sunday",
    MONDAY: "monday",
    TUESDAY: "tuesday",
    WEDNESDAY: "wednesday",
    THURSDAY: "thursday",
    FRIDAY: "friday",
  },
  monthly: {
    START: "start",
    END: "end",
  },
  yearly: {
    START: "start",
    END: "end",
  },
};
export const scheduledPaymentTimesToRun = {
  CONTINOUS: "continous",
  ENDS: "ends",
};
export const groupClassPaymentStudentTypes = {
  NEW_STUDENT: 1,
  CURRENT_STUDENT: 2,
};
export const paymentOptionMethods = {
  SETUP: "setup",
  SCHEDULED: "scheduled",
};
export const groupClassPaymentOptionsMap = {
  onceEnrollmentFee: {
    value: 1,
    title1: "Enrollment Fee (Setup)",
    method: paymentOptionMethods.SETUP,
    forPaymentTypes: [
      groupClassPaymentTypes.SCHEDULED,
      groupClassPaymentTypes.ONE_TIME,
    ],
    forStudentTypes: [
      groupClassPaymentStudentTypes.CURRENT_STUDENT,
      groupClassPaymentStudentTypes.NEW_STUDENT,
    ],
  },
  onceMaterialFee: {
    value: 2,
    title1: "Material Fee (Setup)",
    method: paymentOptionMethods.SETUP,

    forPaymentTypes: [
      groupClassPaymentTypes.SCHEDULED,
      groupClassPaymentTypes.ONE_TIME,
    ],
    forStudentTypes: [
      groupClassPaymentStudentTypes.CURRENT_STUDENT,
      groupClassPaymentStudentTypes.NEW_STUDENT,
    ],
  },
  onceTuition: {
    value: 3,
    title1: "Tuition (Setup)",
    method: paymentOptionMethods.SETUP,
    forPaymentTypes: [groupClassPaymentTypes.ONE_TIME],
    forStudentTypes: [
      groupClassPaymentStudentTypes.CURRENT_STUDENT,
      groupClassPaymentStudentTypes.NEW_STUDENT,
    ],
  },
  scheduledMaterialFee: {
    value: 4,
    title1: "Material Fee (Installment)",
    method: paymentOptionMethods.SCHEDULED,
    forPaymentTypes: [groupClassPaymentTypes.SCHEDULED],
    forStudentTypes: [
      groupClassPaymentStudentTypes.CURRENT_STUDENT,
      groupClassPaymentStudentTypes.NEW_STUDENT,
    ],
  },
  scheduledTuition: {
    value: 5,
    title1: "Tuition (Installments)",
    method: paymentOptionMethods.SCHEDULED,
    forPaymentTypes: [groupClassPaymentTypes.SCHEDULED],
    forStudentTypes: [
      groupClassPaymentStudentTypes.CURRENT_STUDENT,
      groupClassPaymentStudentTypes.NEW_STUDENT,
    ],
  },
};

export const groupClassPaymentMethods = {
  PAYPAL: 1,
  EXTERNAL: 2,
};
