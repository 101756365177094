import React, { useContext, useState } from "react";

import { Button, Spinner } from "reactstrap";
import {
  ActiveTabContext,
  DeleteItemsModalContext,
  DeleteNoteContext,
  LibraryContext,
} from "../../../common/Library/common/libraryContext";
import Modal from "../../../common/Modal";

const DeleteNoteConfirmModal = ({ userId }) => {
  const { isAdmin } = useContext(ActiveTabContext);
  const { onDelete, isOpen, close } = useContext(DeleteNoteContext);
  const modalBody = () => (
    <div
      className={
        "d-flex flex-column py-5 align-items-center justify-content-center"
      }
    >
      <p className="lead mb-2">Are you sure you want to delete this note?</p>
      <p className="lead mb-0 alert alert-danger">
        <i className={"fa fa-alert"} />
        Deleting this note will also delete its attachments
      </p>
    </div>
  );

  const buttons = (
    <>
      <Button className={"ms-2"} color="danger" onClick={() => onDelete(false)}>
        Yes, Delete
      </Button>
      {isAdmin ? (
        <Button
          className={"ms-2"}
          color="danger"
          onClick={() => onDelete(true)}
        >
          Delete forever
        </Button>
      ) : null}
    </>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Confirm Deletion"}
      isOpen={isOpen}
      buttons={buttons}
      toggle={close}
    />
  );
};
export default DeleteNoteConfirmModal;
