import React from "react";
import styled from "styled-components";
import FormInfo from "./FormInfo";
import UserProfile from "./UserProfile";

const SectionContainer = styled.div`
  min-height: 500px;
  margin-top: -20px;
`;
const ThirdTeacherRegister = ({ modalData, refreshData }) => {
  return (
    <SectionContainer>
      <div className="d-flex justify-content-center">
        <UserProfile />
        <FormInfo refreshData={refreshData} modalData={modalData} />
      </div>
    </SectionContainer>
  );
};

export default ThirdTeacherRegister;
