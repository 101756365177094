import moment from "moment";
import React from "react";
import { handleCopyText } from "src/utils/helpers";
import { PrimaryButton, StyledCopyIcon } from "src/utils/shared/styled";

const StudentContactInfoSection = ({ onEditStudentClick, trialStudent }) => {
  const {
    name,
    birthday,
    primaryContactEmail,
    primaryContactName,
    primaryPhone,
    secondaryContactEmail,
    secondaryContactName,
    secondPhone,
  } = trialStudent || {};
  return (
    <div className="mb-4">
      <div className="mt-3 d-flex justify-content-end align-items-center">
        <PrimaryButton
          onClick={() => {
            onEditStudentClick(trialStudent);
          }}
          width="105px"
        >
          Edit
        </PrimaryButton>
      </div>

      <div className="UsableContainer">
        <div className="UsableSinglePart">
          <p>Name of Student </p>
          <div>
            <p>{name || "N/A"}</p>
          </div>
        </div>
        <div className="UsableSinglePart">
          <p>Birthday</p>
          <div>
            <p>
              {birthday
                ? moment(birthday, "YYYY-MM-DD").format("MM/DD/YYYY")
                : "N/A"}
            </p>
          </div>
        </div>
      </div>
      <div className="UsableContainer">
        <div className="UsableSinglePart">
          <p>
            Primary Contact Email
            <StyledCopyIcon
              onClick={() => handleCopyText(primaryContactEmail || "")}
              width={25}
              height={25}
            />
          </p>
          <div>
            <p>{primaryContactEmail || "N/A"}</p>
          </div>
        </div>
      </div>
      <div className="UsableContainer">
        <div className="UsableSinglePart">
          <p>
            Primary Contact Name
            <StyledCopyIcon
              onClick={() => handleCopyText(primaryContactName || "")}
              width={25}
              height={25}
            />
          </p>
          <div>
            <p>{primaryContactName || "N/A"}</p>
          </div>
        </div>
        <div className="UsableSinglePart">
          <p>Primary Phone</p>
          <div>
            <p>{primaryPhone || "N/A"}</p>
          </div>
        </div>
      </div>
      <div className="UsableContainer">
        <div className="UsableSinglePart">
          <p>
            2nd Contact Email
            <StyledCopyIcon
              onClick={() => handleCopyText(secondaryContactEmail || "")}
              width={25}
              height={25}
            />
          </p>
          <div>
            <p>{secondaryContactEmail || "N/A"}</p>
          </div>
        </div>
      </div>
      <div className="UsableContainer">
        <div className="UsableSinglePart">
          <p>
            2nd Contact Name
            <StyledCopyIcon
              onClick={() => handleCopyText(secondaryContactName || "")}
              width={25}
              height={25}
            />
          </p>
          <div>
            <p>{secondaryContactName || "N/A"}</p>
          </div>
        </div>
        <div className="UsableSinglePart">
          <p>2nd Phone</p>
          <div>
            <p>{secondPhone || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentContactInfoSection;
