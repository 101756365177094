import { toJS } from "mobx";
import React from "react";
import useModal from "src/hooks/useModal";
import { injectUserStore } from "src/utils/helpers";
import { PrimaryButton } from "src/utils/shared/styled";
import SinglePackageLessonCreationModal from "../../../../SinglePackageLessonCreationModal";
import usePackagePLCreationData from "../../../hooks/usePackagePLCreationData";
import InstrumentSelect from "../InstrumentSelect";
import LessonLengthSelect from "../LessonLengthSelect";
import LocationSelect from "../LocationSelect";

const SinglePackageLesson = ({
  modalData,
  studentId,
  providedInitialData,
  UserStore,
}) => {
  const user = toJS(UserStore).user;

  const scheduleLessonModalData = useModal();

  const packageLessonData = usePackagePLCreationData({
    providedInitialData,
    studentId,
    modalData,
    user,
  });
  const {
    initialData,
    selectedInstrumentId,
    setSelectedInstrumentId,
    selectedLessonDuration,
    setSelectedLessonDuration,
    selectedLocationId,
    setSelectedLocationId,
    canScheduleFirstLesson,
  } = packageLessonData;

  return (
    <div>
      <div className="d-flex" style={{ gap: "30px" }}>
        <div style={{ width: "33.333%" }}>
          <InstrumentSelect
            instruments={initialData.instruments}
            value={selectedInstrumentId}
            handleChange={(e) => setSelectedInstrumentId(e.target.value)}
          />
        </div>
        <div style={{ width: "33.333%" }}>
          <LessonLengthSelect
            value={selectedLessonDuration}
            handleChange={(e) =>
              setSelectedLessonDuration(parseInt(e.target.value))
            }
          />
        </div>
        <div style={{ width: "33.333%" }}>
          <LocationSelect
            locations={initialData.locations}
            value={selectedLocationId}
            handleChange={(e) => setSelectedLocationId(e.target.value)}
          />
        </div>
      </div>
      <div>
        {canScheduleFirstLesson && (
          <div className="mt-5 text-center">
            <PrimaryButton
              onClick={scheduleLessonModalData.openModal}
              width="50%"
            >
              Schedule the first lesson
            </PrimaryButton>
          </div>
        )}
      </div>

      {/* Modals */}
      <SinglePackageLessonCreationModal
        onLessonCreateSuccess={() => {
          modalData.closeModal();
        }}
        studentId={studentId}
        lessonDuration={selectedLessonDuration}
        lessonInstrumentId={selectedInstrumentId}
        lessonLocationId={selectedLocationId}
        modalData={scheduleLessonModalData}
        isNewLesson={true}
      />
    </div>
  );
};

export default injectUserStore(SinglePackageLesson);
