import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { schoolPolicies } from "src/constants/schoolPolicies";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useSchoolPoliciesData = () => {
  const { getSchoolPolicies, addNewSchoolPolicy, updateSchoolPolicy } =
    useFirebaseFns();

  const [currentTab, setCurrentTab] = useState(
    schoolPolicies.absencePolicy.key
  );

  const [initialData, setInitialData] = useState({
    schoolPolicies: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [loadingUpdatePolicy, setLoadingUpdatePolicy] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const schoolPolicies = await getSchoolPolicies();
        setInitialData({
          schoolPolicies,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [refresh]);

  const currentTabPolicy = useMemo(() => {
    return initialData.schoolPolicies.find(({ type }) => type === currentTab);
  }, [initialData.schoolPolicies, currentTab]);

  const updateCurrentPolicy = async (newPolicyDescription) => {
    try {
      if (!newPolicyDescription) {
        toast.error("couldnt update the policy");
        return;
      }

      setLoadingUpdatePolicy(true);

      if (!currentTabPolicy?.id) {
        console.log("ADDING NEW POLICY");
        const newPolicyObj = {
          type: currentTab,
          description: newPolicyDescription,
        };
        const snap = await addNewSchoolPolicy(newPolicyObj);
      } else {
        console.log("UPDATING POLICY");

        const updateObj = {
          description: newPolicyDescription,
        };
        await updateSchoolPolicy(currentTabPolicy.id, updateObj);
      }
      toast.success("Updated Successfully");
      refreshData();
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUpdatePolicy(false);
    }
  };

  return {
    currentTab,
    setCurrentTab,
    initialData,
    loadingInitialData,
    currentTabPolicy,
    updateCurrentPolicy,
    refreshData,
    loadingUpdatePolicy,
  };
};

export default useSchoolPoliciesData;
