export const filterTeachersByInstrument = (teachers, instrumentId) => {
  if (!teachers?.length) return [];

  const filteredTeachers = teachers.filter((teacher) => {
    const teachersInstrumentsIds = teacher?.instrumentsInfo?.map(
      (instrumentInfo) => instrumentInfo?.instrument
    );
    return teachersInstrumentsIds?.includes(instrumentId);
  });

  return filteredTeachers;
};
