export const getFirebaseDoc = (docs) => {
  return docs.docs.reduce((acc, cur) => {
    acc[cur.id] = { ...cur.data(), id: cur.id };
    return acc;
  }, {});
};

export const parseFirebaseDoc = (docs) => {
  if (!docs?.length) {
    return [];
  }
  const parsedDocs = docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return parsedDocs;
};
