import React, { useState } from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { CustomLabel } from "src/utils/styled";
import { dateFilters } from "../../constants";
import { CalendarPickerWrapper } from "src/utils/shared/styled";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

const periodOptions = Object.values(dateFilters).map((filterObj) => {
  return {
    label: filterObj.title,
    value: filterObj.value,
  };
});
const DateFilter = ({
  dateFilter,
  handleDateFilterChange,
  customDateFilterValues,
  handleCustomDateFilterChange,
}) => {
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);

  const selectedFilterObj = dateFilter
    ? Object.values(dateFilters).find(({ value }) => value === dateFilter)
    : null;
  const selectedFilterOption = selectedFilterObj
    ? {
        label: selectedFilterObj.title,
        value: selectedFilterObj.value,
      }
    : null;

  const showCustomRange = dateFilter === dateFilters.CUSTOM.value;

  return (
    <div className="d-flex" style={{ gap: 40 }}>
      <div className="">
        <CustomLabel displayBlock>Period:</CustomLabel>
        <CustomReactSelect
          isMulti={false}
          placeholder="Period"
          width="320px"
          options={periodOptions}
          onChange={(option) => handleDateFilterChange(option?.value)}
          value={selectedFilterOption}
          wrapOptions={false}
          isClearable={true}
        />
      </div>
      {showCustomRange && (
        <div className="d-flex" style={{ gap: 10 }}>
          <div>
            <CustomLabel>Start Date:</CustomLabel>
            <CalendarPickerWrapper>
              <SingleDatePicker
                date={customDateFilterValues.startDate}
                onDateChange={(date) => {
                  handleCustomDateFilterChange("startDate", date);
                }}
                focused={isStartDatePickerOpen} // PropTypes.bool
                onFocusChange={({ focused }) => {
                  setIsStartDatePickerOpen(focused);
                }}
                // numberOfMonths={1}
                // showClearDate={true}
                isOutsideRange={(date) => {
                  return false;
                }}
              />
            </CalendarPickerWrapper>
          </div>
          <div>
            <CustomLabel>End Date:</CustomLabel>
            <CalendarPickerWrapper>
              <SingleDatePicker
                date={customDateFilterValues.endDate}
                onDateChange={(date) => {
                  handleCustomDateFilterChange("endDate", date);
                }}
                focused={isEndDatePickerOpen} // PropTypes.bool
                onFocusChange={({ focused }) => {
                  setIsEndDatePickerOpen(focused);
                }}
                // numberOfMonths={1}
                // showClearDate={true}
                isOutsideRange={(date) => {
                  if (
                    !customDateFilterValues.startDate ||
                    moment(date).isBefore(
                      customDateFilterValues.startDate,
                      "date"
                    )
                  ) {
                    return true;
                  }
                  return false;
                }}
              />
            </CalendarPickerWrapper>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateFilter;
