import React from "react";
import ConfirmationModal from "../../../ConfirmationModal";

const DeleteLessonModal = ({ modalData, onDelete, loading }) => {
  const handleApprove = async () => {
    if (!loading) {
      await onDelete();
      modalData.closeModal();
    }
  };
  const handleDecline = () => {
    modalData.closeModal();
  };
  return (
    modalData.isModalOpen && (
      <ConfirmationModal
        onApprove={handleApprove}
        onCancel={handleDecline}
        modalData={modalData}
        isSubmitting={loading}
        approveBtnText={"Confirm And Delete"}
        cancelBtnText="Cancel"
        title="Delete Lesson?"
        withCheckbox={true}
        checkboxDescription="I understand that this will delete all absences, make up lessons, lesson notes and attendances."
      />
    )
  );
};

export default DeleteLessonModal;
