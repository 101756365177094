import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  isAbsenceNotification,
  isMakeupLessonNotification,
} from "src/constants/notificationsEnum";
import { useUserNotificationsContext } from "src/contexts/UserNotificationsContext";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useNotificationsData = ({ isNotificationsOpen }) => {
  const { userNotifications, readAllUnreadNotifications } =
    useUserNotificationsContext();

  const {
    getAbsencesByIds,
    getMakeupLessonsByIds,
    getPrivateLessonsByIds,
    getUsersByIds,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    absences: [],
    makeupLessons: [],
    privateLessons: [],
    users: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // only fetches the data when we open the notification dropdown
        if (!isNotificationsOpen) return;

        setLoadingInitialData(true);
        let absencesIds = [];
        let makeupLessonsIds = [];
        userNotifications.forEach((not) => {
          const { event, eventDocId } = not;
          if (isAbsenceNotification(event)) {
            absencesIds.push(eventDocId);
          } else if (isMakeupLessonNotification(event)) {
            makeupLessonsIds.push(eventDocId);
          }
        });
        const uniqueAbsencesIds = [...new Set(absencesIds)];
        const uniqueMakeupLessonsIds = [...new Set(makeupLessonsIds)];

        const [absences, makeupLessons] = await Promise.all([
          getAbsencesByIds(uniqueAbsencesIds),
          getMakeupLessonsByIds(uniqueMakeupLessonsIds),
        ]);

        const uniqueAbsencePLsIds = [
          ...new Set(
            absences
              .map(
                ({ affectedPrivateLessonsIds, lessonId }) =>
                  lessonId || affectedPrivateLessonsIds
              )
              .flat()
          ),
        ];

        const privateLessons = await getPrivateLessonsByIds(
          uniqueAbsencePLsIds
        );

        const makeupLessonsStudentsIds = makeupLessons.map(
          ({ studentId }) => studentId
        );
        const privateLessonsStudentsIds = privateLessons.map(
          ({ studentId }) => studentId
        );
        const uniqueUsersIds = [
          ...new Set([
            ...[...makeupLessonsStudentsIds, ...privateLessonsStudentsIds],
          ]),
        ];

        const users = await getUsersByIds(uniqueUsersIds);

        setInitialData((oldVal) => ({
          ...oldVal,
          absences,
          makeupLessons,
          privateLessons,
          users,
        }));
      } catch (err) {
        toast.error(err.message);
        console.log(err);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, [userNotifications, isNotificationsOpen]);

  return { initialData, loadingInitialData };
};

export default useNotificationsData;
