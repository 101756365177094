import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useContext, createContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { getTimeDiffInMins } from "src/utils/helpers";

const StudentInfoContext = createContext({
  instruments: [],
  programs: [],
  teachers: [],
  students: [],
  //   lessons,
  privateLessons: [],
  locations: [],
  schoolYears: [],
  isLoading: false,
});

export const StudentInfoContextProvider = ({
  children,
  providedInitialData = {
    schoolYears: undefined,
    privateLessons: undefined,
    instruments: undefined,
    programs: undefined,
    teachers: undefined,
    students: undefined,
    locations: undefined,
  },
  ...props
}) => {
  const {
    getInstruments,
    getLocations,
    getPrograms,
    getPrivateLessons,
    getTeachers,
    getStudents,
    getSchoolYears,
  } = useFirebaseFns();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    instruments: [],
    locations: [],
    programs: [],
    privateLessons: [],
    teachers: [],
    students: [],
    schoolYears: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const reqs = [
          providedInitialData.instruments || getInstruments(),
          providedInitialData.locations || getLocations(),
          providedInitialData.programs || getPrograms(),
          providedInitialData.privateLessons || getPrivateLessons(),
          providedInitialData.students || getStudents(),
          providedInitialData.teachers || getTeachers(),
          providedInitialData.schoolYears || getSchoolYears(),
        ];

        const [
          instruments,
          locations,
          programs,
          privateLessons,
          students,
          teachers,
          schoolYears,
        ] = await Promise.all(reqs);

        const sortedSchoolYearsDesc = schoolYears?.sort((a, b) => {
          const firstDate = Object.values(a?.locations || {})[0]?.dates
            ?.start_date;
          const secondDate = Object.values(b?.locations || {})[0]?.dates
            ?.start_date;

          const firstYearDate = firstDate ? firstDate.toDate() : null;
          const secondYearDate = secondDate ? secondDate.toDate() : null;
          return (
            firstYearDate &&
            secondYearDate &&
            getTimeDiffInMins(firstYearDate, secondYearDate)
          );
        });

        setData((oldVal) => ({
          ...oldVal,
          instruments,
          locations,
          programs,
          privateLessons,
          students,
          teachers,
          schoolYears: sortedSchoolYearsDesc,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const value = useMemo(
    () => ({
      isLoading,
      ...data,
    }),
    [isLoading, data]
  );

  return (
    <StudentInfoContext.Provider value={value} {...props}>
      {children}
    </StudentInfoContext.Provider>
  );
};

export const useStudentInfoContext = () => {
  return useContext(StudentInfoContext);
};

export default StudentInfoContext;
