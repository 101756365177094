import React, { useContext } from "react";
import { ADD_LABELS } from "../common/constants";
import AddNewItemModal from "./AddNewItemModal";
import {
  ActiveTabContext,
  AddItemModalContext,
} from "../common/libraryContext";
import { TextButton } from "../../styled-components/teacherLibraryStyledComponents";
import styled from "styled-components";

const AttachmentModal = ({ onModalSave }) => {
  const { activeTab } = useContext(ActiveTabContext);
  const { open } = useContext(AddItemModalContext);
  return (
    <div className={"mb-4"}>
      {activeTab !== "studentsUploads" && (
        <>
          <TextButton
            className="mb-2 text-start btn d-flex"
            onClick={open}
            style={{ maxWidth: "95%" }}
          >
            <i className="fas fa-plus mx-2" />
            <span>Add new {ADD_LABELS[activeTab]}</span>
          </TextButton>
          <AddNewItemModal onSave={onModalSave} />
        </>
      )}
    </div>
  );
};
export default AttachmentModal;
