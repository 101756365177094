import styled from "styled-components";
import { Close } from "@styled-icons/evaicons-solid/Close";

export const CreateClassButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #ac0f64;
  font-weight: bold;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
