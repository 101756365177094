import { formatTime } from "src/components/Dashboard/Guest/utils";
import { DaysCards } from "./styled";
import { StyledCard } from "../../ProgramSelect/styled";

const AvailableDaysList = ({ days, location }) => {
  return (
    <>
      <DaysCards>
        {days.map((day, index) => {
          const startDate = new Date(day?.startDate);
          const endDate = new Date(day?.endDate);

          const formattedDay = {
            day: startDate?.toLocaleDateString("en-US", { weekday: "long" }),
            start: formatTime(startDate),
            end: formatTime(endDate),
          };

          return (
            <StyledCard title="Available day" key={index} bordered>
              <p>Day: {formattedDay?.day}</p>
              <p>Hours: {`${formattedDay?.start} - ${formattedDay?.end}`}</p>
              <p>Virtual only: {day.isVirtualOnly ? "Yes" : "No"}</p>
              <p>Location: {location.name}</p>
            </StyledCard>
          );
        })}
      </DaysCards>
    </>
  );
};

export default AvailableDaysList;
