import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SubmitSpinner } from "src/components/common";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { CustomTextarea } from "src/utils/shared/styled";

const PolicySection = ({ policiesData }) => {
  const {
    currentTabPolicy,
    currentTab,
    loadingInitialData,
    loadingUpdatePolicy,
    updateCurrentPolicy,
  } = policiesData;
  const { description } = currentTabPolicy || {};

  const [policyDescription, setPolicyDescription] = useState("");

  useEffect(() => {
    if (description) {
      setPolicyDescription(description);
    } else {
      setPolicyDescription("");
    }
  }, [description]);

  const handleUpdatePolicyClick = async () => {
    if (!policyDescription) {
      toast.warn("Policy Cant be empty");
      return;
    }
    await updateCurrentPolicy(policyDescription);
  };

  if (loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <div>
      <div>
        <CustomTextarea
          height="200px"
          width="100%"
          value={policyDescription}
          onChange={(e) => setPolicyDescription(e.target.value)}
        />
      </div>
      <div className="mt-4 text-end">
        <PrimaryButtonWithLoading
          isLoading={loadingUpdatePolicy}
          onClick={handleUpdatePolicyClick}
        >
          Update Policy
        </PrimaryButtonWithLoading>
      </div>
    </div>
  );
};

export default PolicySection;
