import { updateDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { FirebaseContext } from "src/components/Firebase";

const useFeedbacks = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [activeFeedback, setActiveFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [archivedFeedbacks, setArchivedFeedbacks] = useState([]);
  const { getFeedbacks, deleteFeedback } = useFirebaseFns();
  const firebase = useContext(FirebaseContext);

  const fetchFeedbacks = async () => {
    setIsLoading(true);
    const feedbacksResponse = await getFeedbacks(false);
    const archivedFeedbacksResponse = await getFeedbacks(true);
    setFeedbacks(feedbacksResponse);
    setArchivedFeedbacks(archivedFeedbacksResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const archiveFeedbackToggle = async (feedbackId, archived) => {
    const feedbackToUpdate = firebase.feedback(feedbackId);
    await updateDoc(feedbackToUpdate, { archived });
    await fetchFeedbacks();
    setActiveFeedback(null);
  };

  const deleteFeedbackFromList = async (feedbackId) => {
    await deleteFeedback(feedbackId);
    await fetchFeedbacks();
    setActiveFeedback(null);
  };

  const changeFeedbackStatus = async (feedback, status, isSendEmailChecked) => {
    const feedbackToUpdate = firebase.feedback(feedback?.id);

    await updateDoc(feedbackToUpdate, { status });
    await fetchFeedbacks();

    if (isSendEmailChecked) await confirmStatusChange(feedback, status);
  };

  const confirmStatusChange = async (feedback, status) => {
    try {
      await emailjs.send(
        "service_m7j255t",
        "template_lb0du76",
        {
          subject: "Feedback status update",
          to_name: feedback?.createdBy?.name,
          from_name: "Artis School of Music",
          to_email: feedback?.createdBy?.email,
          message: `
          Your feedback status updated
          Current status: ${status}
        `,
          attachmentSrc:
            "https://firebasestorage.googleapis.com/v0/b/artisconnect-a3c6e.appspot.com/o/image_2024-06-20_182658749.png?alt=media&token=3ef3653b-dd5e-4a98-8e28-feb19b137d24",
        },
        "l6-vMvPNxhUUxuyxh"
      );
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  const replyToFeedback = async (feedback, subject, message) => {
    try {
      await emailjs.send(
        "service_m7j255t",
        "template_lb0du76",
        {
          subject,
          to_name: feedback?.createdBy?.name,
          from_name: "Artis School of Music",
          to_email: feedback?.createdBy?.email,
          message,
          attachmentSrc:
            "https://firebasestorage.googleapis.com/v0/b/artisconnect-a3c6e.appspot.com/o/image_2024-06-20_182658749.png?alt=media&token=3ef3653b-dd5e-4a98-8e28-feb19b137d24",
        },
        "l6-vMvPNxhUUxuyxh"
      );

      toast.success("Email sent successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  const forwardFeedbackToDeveloper = async (feedback, subject, message) => {
    try {
      await emailjs.send(
        "service_m7j255t",
        "template_lb0du76",
        {
          subject,
          to_name: feedback?.createdBy?.name,
          from_name: "Artis School of Music",
          to_email: feedback?.createdBy?.email,
          message,
          attachmentSrc: feedback?.attachments[0],
        },
        "l6-vMvPNxhUUxuyxh"
      );
      toast.success("Email sent successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  return {
    feedbacks,
    archivedFeedbacks,
    isLoading,
    archiveFeedbackToggle,
    replyToFeedback,
    forwardFeedbackToDeveloper,
    fetchFeedbacks,
    deleteFeedbackFromList,
    changeFeedbackStatus,
    confirmStatusChange,
    activeFeedback,
    setActiveFeedback,
  };
};

export default useFeedbacks;
