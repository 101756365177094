import React from "react";
import { useTimezoneContext } from "src/contexts/TimezoneContext";
import { CustomSelectField, PrimaryButton } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import { timezoneOptions } from "./constants";
import useTimezone from "./hooks/useTimezone";

const ChangeTimezoneBody = () => {
  const { selectedTimezone, setSelectedTimezone, saveSelectedTimezone } =
    useTimezone();
  return (
    <div>
      <div>
        <CustomLabel>Timezone:</CustomLabel>
        <CustomSelectField
          width="100%"
          name="timezone"
          value={selectedTimezone}
          onChange={(e) => setSelectedTimezone(e.target.value)}
        >
          <option value="">Local Timezone (Default)</option>

          {timezoneOptions.map((tz) => (
            <option key={tz} value={tz}>
              {tz}
            </option>
          ))}
        </CustomSelectField>
      </div>
      <div className="text-center mt-5">
        <PrimaryButton width="80%" onClick={saveSelectedTimezone}>
          Save Timezone
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ChangeTimezoneBody;
