import moment from "moment";
import React from "react";
import { Container } from "reactstrap";
import { SubmitSpinner } from "src/components/common";
import {
  isInProgressAbsence,
  isPerDurationAbsence,
  isPerLessonAbsence,
  isResolvedAbsence,
  isSubmittedAbsence,
} from "src/constants/absenceTypes";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import {
  getLessonWithCurrentTimeline,
  getPackageItemByDate,
  getPrivateLessonInfoOnSpecificDate,
} from "src/utils/helpers";
import {
  CustomTableContainer,
  CustomTable,
  TableRow,
} from "src/utils/shared/styled";

const AbsenceTable = ({ absenceData, displayedAbsences }) => {
  const { initialData, loadingInitialData } = absenceData;

  const { teacherPLs } = initialData;

  // Loading display
  if (loadingInitialData) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <Container fluid>
      <CustomTableContainer>
        <CustomTable className="w-100 text-dark">
          <thead>
            <TableRow>
              <th>
                <div>Request Date</div>
              </th>
              <th>
                <div>Absence Date</div>
              </th>
              <th>
                <div>Absence Time</div>
              </th>
              <th>
                <div>Status</div>
              </th>
            </TableRow>
          </thead>
          <tbody>
            {displayedAbsences.map((absence) => {
              const { createdAt, request } = absence;
              const absenceBehaviour =
                absence.absenceBehaviour || absence.request?.absenceBehaviour;
              const absenceDate =
                absence.date ||
                absence.startDate ||
                request?.date ||
                request?.startDate;

              const createdAtStr = moment(createdAt).format("MM-DD-YYYY");

              const dateStr = moment(absenceDate).format("MM-DD-YYYY");

              let timeStr = "";
              if (isPerLessonAbsence(absenceBehaviour)) {
                const absenceLesson = teacherPLs.find(
                  ({ id }) =>
                    id === (absence.lessonId || absence.request?.lessonId)
                );

                if (absenceLesson) {
                  const {
                    startDate: lessonStartDate,
                    duration: lessonDuration,
                  } = getPrivateLessonInfoOnSpecificDate({
                    privateLesson: absenceLesson,
                    date: absenceDate,
                    withTimelineApproximation: true,
                  });

                  const lessonEndDate = lessonStartDate
                    ? moment(lessonStartDate).add(
                        parseInt(lessonDuration),
                        "minutes"
                      )
                    : undefined;

                  const absenceStartTimeStr =
                    moment(lessonStartDate).format("hh:mm A");
                  const absenceEndTimeStr =
                    moment(lessonEndDate).format("hh:mm A");
                  timeStr = `${absenceStartTimeStr} - ${absenceEndTimeStr}`;
                } else {
                  timeStr = "Unavailable";
                }
              } else if (isPerDurationAbsence(absenceBehaviour)) {
                const absenceStartTimeStr = moment(
                  absence.startDate || absence.request?.startDate
                ).format("hh:mm A");
                const absenceEndTimeStr = moment(
                  absence.endDate || absence.request?.endDate
                ).format("hh:mm A");
                timeStr = `${absenceStartTimeStr} - ${absenceEndTimeStr}`;
              } else {
                timeStr = "N/A";
              }

              const statusStr = isSubmittedAbsence(absence.status)
                ? "Submitted"
                : isInProgressAbsence(absence.status)
                ? "Pending"
                : isResolvedAbsence(absence.status)
                ? "Resolved"
                : "N/A";

              return (
                <TableRow>
                  <td>{createdAtStr}</td>
                  <td>{dateStr}</td>
                  <td>{timeStr}</td>
                  <td>{statusStr}</td>
                </TableRow>
              );
            })}
          </tbody>
        </CustomTable>
      </CustomTableContainer>
    </Container>
  );
};

export default AbsenceTable;
