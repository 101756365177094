import React from "react";
import IconsTable from "./IconsTable";
import "./style.css";
import { GiGrandPiano } from "react-icons/gi";
import { PiBabyLight } from "react-icons/pi";
import { FcAlarmClock } from "react-icons/fc";
import { MdKeyboardVoice } from "react-icons/md";
import { GiFlute } from "react-icons/gi";
import { BsFileEarmarkMusic } from "react-icons/bs";
import { GiViolin } from "react-icons/gi";
import { GiClarinet } from "react-icons/gi";
import { LiaGuitarSolid } from "react-icons/lia";
import { GiGuitar } from "react-icons/gi";
import { GiDrum } from "react-icons/gi";
import { GiMusicalScore } from "react-icons/gi";
import { PiStudent } from "react-icons/pi";
import { GiMeditation } from "react-icons/gi";

function Content() {
  return (
    <article className="instruction-content">
      <h2 className="second-heading">
        Private Lessons On-Line and in-Person in Belmont Ca.
      </h2>
      {/* <a href="/signUp">Register </a> */}
      <p className="firstParagraphPrivateInstructions fontSize25">
        Enrollment is welcome anytime! It’s never too late to join. 
        </p>
        <p className="firstParagraphPrivateInstructions fontSize25">
           Just register on-line, call us or stop by the school to sign up!
      </p>
      <IconsTable />

      {/* <ul className="courses">
        <li>
          Piano <GiGrandPiano className="privateInstructionsIcons" />
        </li>
        <li>
          Voice <MdKeyboardVoice className="privateInstructionsIcons" />
        </li>
        <li>
          Flute <GiFlute className="privateInstructionsIcons" />
        </li>
        <li>
          Music Theory{" "}
          <BsFileEarmarkMusic className="privateInstructionsIcons" />
        </li>
        <li>
          Violin <GiViolin className="privateInstructionsIcons" />
        </li>
        <li>
          Guitar <GiGuitar className="privateInstructionsIcons" />
        </li>
        <li>
          Clarinet <GiClarinet className="privateInstructionsIcons" />
        </li>
        <li>
          Music Composition{" "}
          <GiMusicalScore className="privateInstructionsIcons" />
        </li>
        <li>Viola</li>
        <li>
          Ukulele <LiaGuitarSolid className="privateInstructionsIcons" />
        </li>
        <li>
          Drum <GiDrum className="privateInstructionsIcons" />
        </li>
        <li>
          Meditation <GiMeditation className="privateInstructionsIcons" />
        </li>
      </ul> */}

      {/* <p>
        We offer <strong> 30-minute, 45-minute</strong>, and{" "}
        <strong>
          {" "}
          60-minute
          <FcAlarmClock className="privateInstructionsGeneralIcons" />
        </strong>{" "}
        duration{" "} */}
      <strong>
        {/* <br /> */}
        <br />{" "}
      </strong>
      <p className="fontSize25">
         <strong>Lessons are available for students 5 and up</strong>. Class is
        once a week.
        <br />
        <br />
        Teacher will teach the basic foundations with a variety of styles,
        techniques, 
        <br />
        <br />
        and different levels based on the different learning
        structures for each child.
      </p>
      {/* </p> */}
    </article>
  );
}

export default Content;
