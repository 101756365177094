import React, { useState } from "react";
import pianoImage from "../../../../Home/SubGallery/images/piano.webp";
import { Document, Page } from "react-pdf";
import styled from "styled-components";

const CustomImage = styled.img`
  max-width: 100%;
  display: inline;
  vertical-align: middle;
  margin: 0;
  height: 100%;
  padding: 0;
  object-fit: contain;
`;
const CustomDocument = styled(Document)`
  height: 100%;
`;
const CustomPage = styled(Page)`
  height: 100%;
  & canvas {
    height: 100% !important;
    object-fit: contain;
    margin: auto;
    width: 100% !important;
  }
`;
function Attachment(props) {
  let attachment;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (props.attachments[0]) {
    const { type = "", url = "", name = "" } = props.attachments[0];

    const firstWord = type.substring(0, 5);

    if (type === "application/pdf") {
      const options = {
        cMapUrl: "cmaps/",
        cMapPacked: true,
        standardFontDataUrl: "standard_fonts/",
      };
      attachment = (
        <CustomDocument
          className="attachment"
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <CustomPage
              className="attachment-page"
              renderMode="canvas"
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              style={{ objectFit: "contain" }}
            />
          ))}
          {/* <CustomPage
            className="attachment-page"
            renderMode="canvas"
            pageNumber={1}
            style={{ objectFit: "contain" }}
          /> */}
        </CustomDocument>
      );
      // attachment = (
      //   <iframe
      //     className="attachment"
      //     loading="lazy"
      //     src={url}
      //     name={name}
      //     title={name}
      //     scrolling="no"
      //   />
      // );
    } else if (firstWord === "image") {
      attachment = <CustomImage loading="lazy" src={url} alt={name} />;
    } else if (firstWord === "video") {
      attachment = (
        <video
          className="attachment"
          loading="lazy"
          src={url}
          alt={name}
          controls
        />
      );
    }
  } else {
    attachment = <CustomImage loading="lazy" src={pianoImage} alt={"piano"} />;
  }
  return <div className="attachment-container">{attachment}</div>;
}

export default Attachment;
