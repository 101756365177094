export const CURRENT_TZ_LS_PROP_NAME = "selectedTZ";
export const MAIN_LOCATION_SYSTEM_ID = 1;

export function getMainLocation(locations) {
  if (!locations?.length) return;

  return locations.find(({ systemID }) => systemID === MAIN_LOCATION_SYSTEM_ID);
}

export const PAYPAL_CLIENT_ID =
  process.env.REACT_APP_MODE === "prod"
    ? process.env.REACT_APP_PAYPAL_CLIENT_ID
    : process.env.REACT_APP_MODE === "dev"
    ? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID
    : "";
export const PAYPAL_CLIENT_SECRET =
  process.env.REACT_APP_MODE === "prod"
    ? process.env.REACT_APP_PAYPAL_CLIENT_SECRET
    : process.env.REACT_APP_MODE === "dev"
    ? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_SECRET
    : "";
