import React from "react";

import { CustomModal, CustomModalBody } from "src/utils/styled";
import ModalBodyContent from "./components/ModalBodyContent";
import ModalHeader from "./components/ModalHeader";

const ViewAdminInfoModal = ({
  modalData,
  adminUser,
  onExtraFunctionsChangeSuccess,
}) => {
  return (
    <CustomModal
      padding="0px"
      centered
      isOpen={modalData.isModalOpen}
      toggle={modalData.toggleModal}
      size={"lg"}
    >
      <ModalHeader
        imageUrl={adminUser?.imageUrl}
        closeModal={modalData.closeModal}
      />
      <CustomModalBody padding="0px">
        {adminUser ? (
          <ModalBodyContent
            onExtraFunctionsChangeSuccess={onExtraFunctionsChangeSuccess}
            adminUser={adminUser}
            modalData={modalData}
          />
        ) : (
          <div></div>
        )}
      </CustomModalBody>
    </CustomModal>
  );
};

export default ViewAdminInfoModal;
