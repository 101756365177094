import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { PrimaryButton } from "src/utils/shared/styled";

export const SideButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #681e46;
  @media (max-width: ${ScreenSize.LG}px) {
    width: 100%;
    margin-top: 30px;
  }
`;
export const AuthSubmitButton = styled(PrimaryButton)`
  width: 245px;
  @media (max-width: ${ScreenSize.LG}px) {
    width: 100%;
  }
`;
export const AuthContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #681e46;

  & > aside {
    width: 20%;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  & > main {
    width: 80%;
    border-radius: 30px 0px 0px 30px;
    position: relative;
    min-height: 100vh;
    background-color: white;
  }

  @media (max-width: ${ScreenSize.LG}px) {
    & > aside {
      display: none;
    }
    & > main {
      width: 100%;
      border-radius: 0px;
    }
  }
`;

export const SidebarContent = styled.div`
  width: 185px;
  padding: 0;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
`;

export const SidebarContentItem = styled(NavLink)`
  display: block;
  color: #ffffff;
  border-radius: 20px 0px 0px 20px;
  padding: 15px 0;
  text-decoration: none;

  &:hover {
    color: #ffffff;
  }
  &[aria-current] {
    color: #681e46;
    /* background-color: #ff9ed2; */
    background-color: rgb(255, 255, 255);
  }
`;

export const LogoutBtnWrapper = styled.span`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
`;
export const AuthButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
`;
