import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { UserRole } from "src/constants/UserRoleEnum";
import signUpSchema from "./signUpSchema";
import { Timestamp, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

import { Formik } from "formik";
import { ModalBody } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { PrimaryButton } from "src/utils/shared/styled";
import { ErrorMessage, SignUpForm } from "./styled";
import { FirebaseContext } from "src/components/Firebase";
import {
  ReCaptchaWrapper,
  StyledModal,
  StyledModalHeader,
  InputWrapper,
} from "../styled";
import { InputWithLabel } from "src/utils/shared/shared";

const EMAIL_ALREADY_IN_USE = "auth/email-already-in-use";

const SignUpModal = ({ type, isOpen, toggle }) => {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const firebase = useContext(FirebaseContext);

  const checkUserType = () => {
    switch (type) {
      case "student":
        return UserRole.GUEST_STUDENT;
      case "teacher":
        return UserRole.TEACHER_APPLICANT;
      case "administration":
        return UserRole.ADMIN_APPLICANT;
      case "partner":
        return UserRole.PARTNER_APPLICANT;
      default:
        return null;
    }
  };

  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle} size="lg">
      <StyledModalHeader toggle={toggle}>
        <span>Sign up for {type === "student" ? "trial student" : type}</span>
      </StyledModalHeader>
      <ModalBody>
        <p style={{ color: "red" }}>Create an account</p>
        <Formik
          initialValues={{
            email: "",
            emailVerified: false,
            role: checkUserType(),
            status: "Need email confirmation",
          }}
          validationSchema={signUpSchema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const { email, password, emailVerified, role, status } = values;
            setSubmitting(true);

            if (!captchaVerified) {
              setErrors({ captcha: "Please verify you're not a robot" });
              setSubmitting(false);
              return;
            }

            try {
              const normalizedEmail = email.toLowerCase();
              const user = await firebase.signUpWithEmailAndPassword(
                normalizedEmail,
                password
              );

              await setDoc(firebase.user(user?.user?.uid), {
                createdAt: Timestamp.now(),
                email: normalizedEmail,
                emailVerified,
                role,
                status,
                archived: false,
                featureNotificationSeen: true,
              });

              await firebase.sendEmailVerification();

              setSubmitting(false);
              navigate("/dashboard/guest-profile");
            } catch (error) {
              if (error.code === EMAIL_ALREADY_IN_USE) {
                setErrors({ email: "Email already in use" });
              } else {
                toast.error(error.message);
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <SignUpForm>
              <InputWrapper>
                <InputWithLabel
                  name="email"
                  type="email"
                  error={errors.email}
                  touched={touched.email}
                  label="Email"
                  placeholder="example@email.com"
                />
              </InputWrapper>
              <InputWrapper>
                <InputWithLabel
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  error={errors.password}
                  touched={touched.password}
                  label="Password"
                  placeholder="Password"
                  onShowClick={() =>
                    setPasswordShown((prevState) => !prevState)
                  }
                  withPasswordShowBtn
                />
              </InputWrapper>
              <InputWrapper>
                <InputWithLabel
                  name="confirmPassword"
                  type={passwordShown ? "text" : "password"}
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  label="Confirm password"
                  placeholder="Confirm password"
                  onShowClick={() =>
                    setPasswordShown((prevState) => !prevState)
                  }
                  withPasswordShowBtn
                />
              </InputWrapper>
              <ReCaptchaWrapper>
                <ReCAPTCHA
                  sitekey="6LfMfMMpAAAAAH2ySnp37hfNFvKSgb2GwhbjqmkV"
                  onChange={(value) => setCaptchaVerified(!!value)}
                />
              </ReCaptchaWrapper>
              <ErrorMessage>{errors.captcha}</ErrorMessage>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </SignUpForm>
          )}
        </Formik>
      </ModalBody>
    </StyledModal>
  );
};

export default SignUpModal;
