import React, { useContext } from "react";
import { ViewItemModalContext } from "../../../common/Library/common/libraryContext";
import { TextButton } from "../../../common/styled-components/teacherLibraryStyledComponents";

const SingleHomeworkItem = ({item}) => {
    const {open} = useContext(ViewItemModalContext);

    return (
        <div>
            <TextButton className={"mb-3 shadow-sm rounded w-100 text-start text-capitalize"} onClick={() => {open(item);}}>
              {item?.title}
            </TextButton>
        </div>
    );
}
export default SingleHomeworkItem;
