import React, { useContext } from "react";
import { downloadSingleItem } from "../../../utils/helpers";
import { ADD_LABELS } from "../../common/Library/common/constants";
import {
  LessonNotesContext,
  ViewItemModalContext,
} from "../../common/Library/common/libraryContext";
import { NoteAttachmentContainer } from "../../common/styled-components/lessonNotesStyledComponents";
import {
  StyledLink,
  TextButton,
} from "../../common/styled-components/teacherLibraryStyledComponents";
const NoteAttachmentsList = ({ note }) => {
  const { isEditable, deleteAttachment } = useContext(LessonNotesContext);
  const { open: previewAttachment } = useContext(ViewItemModalContext);
  return note.attachments.map((attachment, index) => (
    <NoteAttachmentContainer
      className={
        "mb-2 d-flex rounded-pill border me-3 ps-3 pe-2 py-1 align-items-center shadow-sm"
      }
      key={index}
    >
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        className={"fw-bold text-capitalize"}
      >
        {attachment?.name || attachment?.downloadUrl}
      </span>
      <TextButton
        className={"p-1 text-purple"}
        onClick={() => {
          previewAttachment(attachment);
        }}
      >
        <i className="fa fa-eye" />
      </TextButton>
      {attachment.attachmentType === "recommendationLinks" ? (
        <StyledLink target={"_blank"} href={attachment.downloadUrl}>
          <i className="fa fa-external-link-alt" />
        </StyledLink>
      ) : (
        <TextButton
          className={"p-1 text-purple"}
          onClick={() => {
            downloadSingleItem(
              attachment.downloadUrl,
              `${ADD_LABELS[attachment.attachmentType]} - ${note.title} - ${
                attachment.name
              }`
            );
          }}
        >
          <i className="fa fa-cloud-download-alt" />
        </TextButton>
      )}
      {isEditable ? (
        <TextButton
          className={"p-1 text-danger"}
          onClick={() => {
            deleteAttachment(index, note.id);
          }}
        >
          <i className="fa fa-trash" />
        </TextButton>
      ) : null}
    </NoteAttachmentContainer>
  ));
};
export default NoteAttachmentsList;
