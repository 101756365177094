import React from "react";
import { ClassInfoContainer, TextInfoContainer } from "../../../styled";
import {
  CustomTable,
  CustomTableContainer,
  TableRow,
} from "src/utils/shared/styled";
import moment from "moment";
import { getAgeFromDate } from "src/utils/helpers";

const EnrolledStudents = ({ enrollments }) => {
  return (
    <ClassInfoContainer width="100%">
      <div
        style={{
          height: "130px",
          width: "100%",
          overflow: "auto",
          // display: "flex",
          // gap: 15,
          // minWidth: "500px",
          // flexWrap: "wrap",
        }}
      >
        {/* {students.map((student) => {
        return <span style={{ height: "15px" }}>{student.fullName}</span>;
      })} */}
        <CustomTableContainer minHeight="0px">
          <CustomTable>
            <thead>
              <TableRow height="30px">
                <th>
                  <div>Name</div>
                </th>
                <th>
                  <div>Age</div>
                </th>
                <th>
                  <div>Enrollment Date</div>
                </th>
                <th>
                  <div>Primary Contact Phone #</div>
                </th>
                <th>
                  <div>Primary Contact Email</div>
                </th>
              </TableRow>
            </thead>
            <tbody>
              {enrollments.map((enrollment, i) => {
                // preparing table values
                const { student, enrollmentDate } = enrollment;

                const studentName = student.fullName;
                const studentLoginEmail = student.primaryEmail;
                const studentPrimaryContactEmail = student.primaryContactEmail;
                const primaryContactPhone = student.primaryPhone;

                const studentAge =
                  student.birthday &&
                  getAgeFromDate(
                    moment(student.birthday, "YYYY-MM-DD").toDate()
                  );

                const formattedEnrollmentDate = enrollmentDate
                  ? moment(enrollmentDate).format("MM-DD-YYYY hh:mm A")
                  : undefined;

                const isEvenRow = i % 2 === 0;
                return (
                  <TableRow
                    height="30px"
                    style={{ ...(isEvenRow && { background: "#f9f9f9" }) }}
                    key={i}
                  >
                    <td>{studentName || "N/A"}</td>
                    <td>{studentAge || "N/A"}</td>

                    <td>{formattedEnrollmentDate || "N/A"}</td>
                    <td>{primaryContactPhone || "N/A"}</td>
                    <td>{studentPrimaryContactEmail || "N/A"}</td>
                  </TableRow>
                );
              })}
            </tbody>
          </CustomTable>
        </CustomTableContainer>
      </div>
    </ClassInfoContainer>
  );
};

export default EnrolledStudents;
