import React from "react";
import { PrimaryButton } from "src/utils/shared/styled";

const AdminButtons = ({ concert, openSignupListModal, openProgramsModal }) => {
  const { availableSignupOptions } = concert;

  const showSignupList = true;
  const showPrograms = true;

  return (
    <>
      <div
        className="d-flex justify-content-center flex-wrap"
        style={{ gap: 10 }}
      >
        {showSignupList && (
          <PrimaryButton
            onClick={() => openSignupListModal()}
            className="text-nowrap"
            width="auto"
            style={{ flex: 1 }}
          >
            Sign Up List
          </PrimaryButton>
        )}
        {showPrograms && (
          <PrimaryButton
            onClick={() => openProgramsModal()}
            className="text-nowrap"
            width="auto"
            style={{ flex: 1 }}
          >
            Program
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default AdminButtons;
