import React, { useContext, useEffect, useState } from "react";
import { absenceModalMap } from "../../constants";
import AbsencePolicy from "./AbsencePolicy";
import AbsenceConfirmation from "./AbsenceConfirmation";
import { FirebaseContext } from "../../../../../Firebase";
import {
  updateDoc,
  Timestamp,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import {
  getMakeupPeriodInRequestedDate,
  getTimeDiffInMins,
  isDateDuringSummerSession,
  isNowDuringSummerSession,
  isSameDayOrPassedDeadline,
  updatedMomentNow,
} from "../../../../../../utils/helpers";
import moment from "moment";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "./styled";

import {
  absenceBehaviours,
  absenceStatuses,
  absenceTypes,
} from "../../../../../../constants/absenceTypes";
import { UserRole } from "../../../../../../constants/UserRoleEnum";
import { logActionTypes, logTypes } from "src/constants/logTypes";
import {
  isPackagePrivateLesson,
  isPrivateLessonEvent,
} from "src/constants/eventsEnum";

//This component is built assuming we only make absence request for "Private Lessons"
const AbsenceModal = ({
  setJustCreatedAbsence,
  user,
  absenceModalData,
  selectedEvent,
  refreshData,
  closeEventDetailsModal,
  makeupModalData,
  currentSchoolYearInLocation,
  setSelectedEvent,
}) => {
  const firebase = useContext(FirebaseContext);

  const {
    id,
    mainLessonId,
    start,
    studentId,
    currentTimeline,
    type,
    eventCode,
    withdrawal,
  } = selectedEvent;
  const isPackagePL =
    isPrivateLessonEvent(eventCode) && isPackagePrivateLesson(type);
  const lessonId = isPackagePL ? mainLessonId : id;

  let teacherId;
  if (isPackagePL) {
    teacherId = selectedEvent.teacherId;
  } else {
    teacherId = currentTimeline.teacherId;
  }

  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [loadingAbsence, setLoadingAbsence] = useState(false);

  // absence reason is only added when the absence is within 24hrs
  const [absenceReason, setAbsenceReason] = useState("");
  const handleAbsenceReasonChange = (e) => {
    setAbsenceReason(e.target.value);
  };

  if (!currentSchoolYearInLocation && !isPackagePL) {
    toast.error("Can't find school year");
    return <div></div>;
  }

  let makeUpPeriodDeadline;
  if (!isPackagePL) {
    // calculating makeup period based on the absence date
    const currentMakeupPeriod = getMakeupPeriodInRequestedDate(
      start,
      currentSchoolYearInLocation
    );
    makeUpPeriodDeadline = currentMakeupPeriod?.deadline?.toDate
      ? currentMakeupPeriod?.deadline?.toDate()
      : null;
  }

  // checks if WA
  const isWA = withdrawal
    ? moment(start).isBetween(
        withdrawal.requestDate,
        withdrawal.withdrawalDate,
        "minutes",
        "[]"
      )
    : false;

  // checks if SDC
  const isSDC = isSameDayOrPassedDeadline(updatedMomentNow(), start);

  const isDuringSummerSession = isDateDuringSummerSession(
    currentSchoolYearInLocation,
    start
  );

  const requestAbsence = async () => {
    try {
      setLoadingAbsence(true);

      const absenceType = isSDC
        ? absenceTypes.sameDayCancelation.code
        : isWA
        ? absenceTypes.withdrawalAbsence.code
        : isDuringSummerSession
        ? absenceTypes.summerAbsence.code
        : absenceTypes.regular.code;

      const newAbsenceObj = {
        createdAt: new Date(),
        date: start,
        userRole: UserRole.STUDENT,
        userId: user.uid,
        absenceType: absenceType,
        status: absenceStatuses.APPROVED,
        absenceBehaviour: absenceBehaviours.PER_LESSON,
        lessonId,
        studentNote: absenceReason,
        teacherId,
        studentId,
      };

      const createdAbsenceSnap = await addDoc(
        firebase.absences(),
        newAbsenceObj
      );
      console.log("CREATED ABSENCE", {
        id: createdAbsenceSnap.id,
        ...newAbsenceObj,
      });
      setJustCreatedAbsence({ id: createdAbsenceSnap.id, ...newAbsenceObj });

      if (createdAbsenceSnap.id) {
        toast.success("Absence is confirmed");
        // if absence is confirmed, close the event details modal and refresh the calendar data and show the absence confirmation modal
        closeEventDetailsModal();
        refreshData();
        absenceModalData.setCurrentModalName(
          absenceModalMap.absenceConfirmation.name
        );
      } else {
        toast.error("couldnt create absence");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAbsence(false);
    }
  };

  const handleConfirmPolicy = async () => {
    if (isPolicyAccepted) {
      await requestAbsence();
    } else {
      toast.warn("Please accept the school absence policy");
    }
  };

  const absenceComponents = {
    [absenceModalMap.absencePolicy.name]: (
      <AbsencePolicy
        isPackagePL={isPackagePL}
        isPolicyAccepted={isPolicyAccepted}
        setIsPolicyAccepted={setIsPolicyAccepted}
        handleConfirmPolicy={handleConfirmPolicy}
        absenceReason={absenceReason}
        handleAbsenceReasonChange={handleAbsenceReasonChange}
        isSDC={isSDC}
        requestAbsence={requestAbsence}
        loadingAbsence={loadingAbsence}
        makeUpPeriodDeadline={makeUpPeriodDeadline}
      />
    ),
    [absenceModalMap.absenceConfirmation.name]: (
      <AbsenceConfirmation
        selectedEvent={selectedEvent}
        isSDC={isSDC}
        absenceModalData={absenceModalData}
        makeupModalData={makeupModalData}
      />
    ),
  };
  const displayCurrentAbsenceComponent = () => {
    return absenceComponents[absenceModalData.currentModalName];
  };
  return (
    <CustomModal
      size="md"
      centered
      isOpen={absenceModalData.isModalOpen}
      toggle={() => {
        absenceModalData.toggleModal();
        setIsPolicyAccepted(false);
      }}
    >
      <CustomHeader
        close={
          <CloseIcon
            onClick={() => {
              absenceModalData.closeModal();
              setIsPolicyAccepted(false);
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={absenceModalData.toggleModal}
      >
        <h2>{absenceModalMap[absenceModalData.currentModalName]?.title}</h2>
      </CustomHeader>
      <CustomBody>{displayCurrentAbsenceComponent()}</CustomBody>
    </CustomModal>
  );
};

export default AbsenceModal;
