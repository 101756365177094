import { getDocs, Timestamp, updateDoc } from "firebase/firestore";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { SubmitSpinner } from "src/components/common";
import CustomDropDown from "src/components/common/DropDown";
import { FirebaseContext } from "src/components/Firebase";
import { SCHOOL_DATES_ALERTS, SCHOOL_DATES_TABS, SchoolDatesContext } from "..";

const SchoolBreaks = ({ changeTab, showInfoModal }) => {
  const firebase = useContext(FirebaseContext);
  const {
    schoolYearsOptions,
    schoolYears,
    setSchoolYears,
    locations,
    lockSchoolYear,
    setIsUnlockConfirmModalOpen,
    selectedLocation,
    setSelectedLocation,
    locationsOptions,
    selectedSchoolYear,
    setSelectedSchoolYear,
  } = useContext(SchoolDatesContext);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolYearBreaks, setSchoolYearBreaks] = useState([]);
  useEffect(() => {
    const schoolYear = schoolYears.find(
      ({ id }) => id === selectedSchoolYear.value
    );
    if (schoolYear) {
      const schoolBreaksObject =
        schoolYear?.locations?.[selectedLocation.value]?.breaks || {};
      const schoolBreaksArray = [];
      for (const key in schoolBreaksObject) {
        const schoolBreak = schoolBreaksObject[key];
        schoolBreaksArray.push({
          from: moment(schoolBreak.from.toDate()).format("YYYY-MM-DD"),
          to: moment(schoolBreak.to.toDate()).format("YYYY-MM-DD"),
        });
      }
      setSchoolYearBreaks(schoolBreaksArray);
    }
  }, [selectedSchoolYear, selectedLocation]);
  const saveSchoolYearBreaks = async () => {
    toast.info("Saving...");
    setIsLoading(true);
    const { value } = selectedSchoolYear;
    const currentSchoolYear = schoolYears.find(({ id }) => id === value);
    const newBreaksData = schoolYearBreaks
      .filter((schoolBreak) => {
        return schoolBreak.from !== "" && schoolBreak.to !== "";
      })
      .map((schoolBreak) => ({
        from: Timestamp.fromDate(
          moment(schoolBreak.from, "YYYY-MM-DD")
            .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
        to: Timestamp.fromDate(
          moment(schoolBreak.to, "YYYY-MM-DD")
            .set({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
            .toDate()
        ),
      }));
    const locationId = selectedLocation.value;
    const newDocumentData = {
      isLocked: true,
      locations: {
        ...(currentSchoolYear?.locations || {}),
        [locationId]: {
          ...(currentSchoolYear?.locations?.[locationId] || {}),
          breaks: {
            ...newBreaksData,
          },
        },
      },
    };
    await updateDoc(firebase.getSchoolYearDoc(value), newDocumentData);
    toast.success("Success.");
    if (currentSchoolYear?.locations?.[locationId]?.dates === undefined) {
      toast.warn(SCHOOL_DATES_ALERTS.schoolDates, {
        autoClose: false,
      });
      showInfoModal("Required Data", SCHOOL_DATES_ALERTS.schoolDates);
      changeTab(SCHOOL_DATES_TABS[0]);
    } else if (
      currentSchoolYear?.locations?.[locationId]?.makeups === undefined
    ) {
      toast.warn(SCHOOL_DATES_ALERTS.makeupDeadlines, {
        autoClose: false,
      });
      showInfoModal("Required data", SCHOOL_DATES_ALERTS.makeupDeadlines);
      changeTab(SCHOOL_DATES_TABS[2]);
    } else {
      setSchoolYears((previous) =>
        previous.map((schoolYear) => {
          if (schoolYear.id === value) {
            return {
              ...schoolYear,
              locations: {
                ...schoolYear.locations,
                [locationId]: {
                  ...(schoolYear.locations?.[locationId] || {}),
                  breaks: {
                    ...newBreaksData,
                  },
                },
              },
            };
          }
          return schoolYear;
        })
      );
      showInfoModal("All set", SCHOOL_DATES_ALERTS.allSet);
    }
    setIsLoading(false);
  };
  const updateSchoolYearBreaks = (index, key, value) => {
    setSchoolYearBreaks((previous) => {
      previous[index][key] = value;
      return [].concat(previous);
    });
  };
  const addAnotherSchoolBreak = () => {
    setSchoolYearBreaks((previous) => {
      const tempArray = [].concat(previous);
      tempArray.push({
        from: "",
        to: "",
      });
      return tempArray;
    });
  };
  const removeSchoolBreak = (index) => {
    setSchoolYearBreaks((previous) => {
      const tempArray = [].concat(previous);
      tempArray.splice(index, 1);
      return tempArray;
    });
  };
  const isYearDataNotCompleted = () => {
    const currentSelectedSchoolYear = schoolYears.find(
      ({ id }) => id === selectedSchoolYear.value
    );
    return (
      Object.keys(
        currentSelectedSchoolYear?.locations?.[selectedLocation.value] || {}
      ).length < 3
    );
  };
  // const lockSchoolYear = async () => {
  //   toast.info("Locking school year");
  //   await updateDoc(firebase.getSchoolYearDoc(selectedSchoolYear.value), {isLocked: true});
  //   setSelectedSchoolYear(previous => ({...previous, isLocked: true}));
  //   setSchoolYears((previous) => previous.map(schoolYear => {
  //     if (schoolYear.id === selectedSchoolYear.value) {
  //       return {
  //         ...schoolYear,
  //         isLocked: true,
  //       };
  //     }
  //     return schoolYear;
  //   }))
  //   toast.success("Successfully locked");
  // }
  // const unlockSchoolYear = async () => {
  //   toast.info("Unlocking school year");
  //   setIsUnlockConfirmModalOpen(false);
  //   await updateDoc(firebase.getSchoolYearDoc(selectedSchoolYear.value), {isLocked: false});
  //   setSelectedSchoolYear(previous => ({...previous, isLocked: false}));
  //   setSchoolYears((previous) => previous.map(schoolYear => {
  //     if (schoolYear.id === selectedSchoolYear.value) {
  //       return {
  //         ...schoolYear,
  //         isLocked: false,
  //       };
  //     }
  //     return schoolYear;
  //   }))
  //   toast.success("Successfully unlocked");
  // }
  return (
    <div className="h-100 w-100 p-4">
      {isLoading ? (
        <div className="p-5 d-flex align-items-center justify-content-center">
          <SubmitSpinner style={{ color: "#681E46" }} />
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-end mb-4">
            <div className="inputContainer col-4 m-0">
              <div className="h5 mb-1">School Year</div>
              <CustomDropDown
                label={selectedSchoolYear.label}
                options={schoolYearsOptions}
                keyName={"label"}
                value={selectedSchoolYear.label}
                onOptionClick={setSelectedSchoolYear}
              />
            </div>
            <span className="col-1 px-3" />
            <div className="inputContainer col-4 ms-2">
              <div className="h5 mb-1">Location</div>
              <CustomDropDown
                label={selectedLocation.label}
                options={locationsOptions}
                keyName={"label"}
                value={selectedLocation.label}
                onOptionClick={setSelectedLocation}
              />
            </div>
            {selectedSchoolYear.isLocked ? (
              <Button
                onClick={() => setIsUnlockConfirmModalOpen(true)}
                className="h6 ms-2 px-3 py-1 mb-0"
                color="danger"
              >
                Unlock
              </Button>
            ) : (
              <Button
                onClick={lockSchoolYear}
                className="h6 ms-2 px-3 py-1 mb-0"
                color="success"
              >
                Lock
              </Button>
            )}
          </div>
          {isYearDataNotCompleted() ? (
            <div className="alert alert-warning">
              Please fill the <b>school year dates</b>, <b>school breaks</b>,
              and <b>makeup deadlines</b> and save
            </div>
          ) : null}
          {schoolYearBreaks.map((schoolBreak, index) => (
            <div key={index} className="d-flex align-items-center mb-4">
              <span className="h5 me-2">{index + 1}</span>
              <div className="inputContainer col-4 m-0 me-2">
                <input
                  disabled={selectedSchoolYear.isLocked}
                  className="InputField w-100 m-0"
                  type="date"
                  name={`school_break_${index}_from`}
                  value={schoolBreak.from}
                  onChange={(e) => {
                    updateSchoolYearBreaks(index, "from", e.target.value);
                  }}
                />
              </div>
              <span className="px-3">TO</span>
              <div className="inputContainer col-4 m-0">
                <input
                  disabled={selectedSchoolYear.isLocked}
                  className="InputField w-100 m-0"
                  type="date"
                  name={`school_break_${index}_to`}
                  value={schoolBreak.to}
                  onChange={(e) => {
                    updateSchoolYearBreaks(index, "to", e.target.value);
                  }}
                />
              </div>
              {!selectedSchoolYear.isLocked ? (
                schoolYearBreaks.length > 1 ? (
                  <Button
                    className="ms-2"
                    color="danger"
                    onClick={() => removeSchoolBreak(index)}
                  >
                    <i className="fa fa-times" />
                  </Button>
                ) : null
              ) : null}
            </div>
          ))}
          {!selectedSchoolYear.isLocked ? (
            <div className="d-flex justify-content-center">
              <button
                disabled={selectedSchoolYear.isLocked}
                onClick={saveSchoolYearBreaks}
                className="submitbutton h5 px-5"
                style={{ width: 200 }}
              >
                Save
              </button>
              <button
                onClick={addAnotherSchoolBreak}
                className="submitbutton h5 px-5 ms-3"
                style={{ width: 200 }}
              >
                Add More
              </button>
            </div>
          ) : null}
          {/*  {isUnlockConfirmModalOpen ?*/}
          {/*<UnlockConfirmModal close={() => setIsUnlockConfirmModalOpen(false)} onConfirm={unlockSchoolYear}/> : null}*/}
        </div>
      )}
    </div>
  );
};
export default SchoolBreaks;
