import React, { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import { prepareAvailabilities } from "../helperFns";
import { TimeSpan } from "../styled";

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InfoText1 = styled.p`
  margin: 0;
`;
const InfoText2 = styled.p`
  color: #b9b9b9;
  font-size: 0.8rem;
  margin: 0;
`;

const DateRow = ({
  dateAvailabilities,
  teacher,
  selectedSlotInfo,
  handleSelectedSlotInfoChange,
  isUnavailableDate,
}) => {
  const {
    date,
    teacherDaysWithAvailabilitiesData,
    makeupOpeningsWithAvailabilitiesData,
  } = dateAvailabilities;

  const formattedDate = moment(date).format("dddd, MMMM D, YYYY");

  // flatting and adding "scheduleType", "location","isVirtualOnly" props to each time chunk + grouping all chunks by location
  const chunksGroupedByLocation = useMemo(
    () =>
      prepareAvailabilities(
        teacherDaysWithAvailabilitiesData,
        makeupOpeningsWithAvailabilitiesData
      ),
    [teacherDaysWithAvailabilitiesData, makeupOpeningsWithAvailabilitiesData]
  );
  return (
    <>
      <SectionContainer>
        <div className="w-50">
          <InfoText1>{formattedDate}</InfoText1>
        </div>
        {isUnavailableDate ? (
          <div className="w-50">
            <h3 className="text-center">Not Available</h3>
          </div>
        ) : (
          <div className="w-50">
            {Object.keys(chunksGroupedByLocation).map((locationId, i) => {
              const currentLocationTimeChunks =
                chunksGroupedByLocation[locationId];

              const location = currentLocationTimeChunks?.[0]?.location;
              const locationName = currentLocationTimeChunks?.[0]?.isVirtualOnly
                ? "Virtual Only"
                : location?.name;
              return (
                <div key={i}>
                  {i !== 0 && <hr />}
                  <div className="mb-4">
                    <span className="d-block text-center mb-3">
                      {locationName}
                    </span>
                    <div className="d-flex flex-wrap">
                      {currentLocationTimeChunks?.map((chunk) => {
                        const parsedTime = moment(chunk.startTime, ["hh:mm A"]);
                        const hours = parsedTime.get("hour");
                        const minutes = parsedTime.get("minute");
                        const startTimeDate = moment(date)
                          .set({
                            hour: hours,
                            minute: minutes,
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate();

                        // checks if selected time slot
                        const isSelected =
                          moment(startTimeDate).isSame(
                            moment(selectedSlotInfo?.date)
                          ) &&
                          selectedSlotInfo?.teacher?.id === teacher?.id &&
                          selectedSlotInfo?.location?.id === location?.id;

                        return (
                          <TimeSpan
                            onClick={() =>
                              handleSelectedSlotInfoChange({
                                date: startTimeDate,
                                teacher: teacher,
                                location,
                                scheduleType: chunk.scheduleType,
                                isVirtualOnly: chunk.isVirtualOnly,
                              })
                            }
                            selected={isSelected}
                          >
                            {chunk.startTime}
                          </TimeSpan>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </SectionContainer>
    </>
  );
};

export default DateRow;
