import React from "react";
import { Container } from "../styled";
import StyledBarContent from "src/components/common/StyledBarContent";
import RequestDetails from "./RequestDetails";
import useMakeupRequestInfo from "../hooks/useMakeupRequestInfo";
import { injectUserStore } from "src/utils/helpers";

const MakeupRequestDetailsBody = ({
  makeupRequest,
  modalData,
  onSuccess,
  UserStore,
}) => {
  const user = UserStore.user;
  const requestData = useMakeupRequestInfo({
    makeupRequest,
    closeModal: modalData.closeModal,
    onSuccess,
    user,
  });
  return (
    <div>
      <Container>
        <StyledBarContent title={"Makeup Request"}>
          <div className="p-4">
            <RequestDetails
              requestData={requestData}
              makeupRequest={makeupRequest}
            />
          </div>
        </StyledBarContent>
      </Container>
      <div className="mt-5 mb-5"></div>
    </div>
  );
};

export default injectUserStore(MakeupRequestDetailsBody);
