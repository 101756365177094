import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import StyledBarContent from "src/components/common/StyledBarContent";
import {
  CalendarPickerWrapper,
  CustomTextarea,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import { PrimaryButton } from "src/utils/shared/styled";
import { injectUserStore } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import usePauseTeacherDay from "./hooks/usePauseTeacherDay";

const PauseTeacherDayBody = ({
  scheduleDayId,
  teacherId,
  modalData,
  userId,
  onSuccess,
  UserStore,
}) => {
  const user = UserStore.user;

  const { pauseReason, loading, pauseDay, setPauseReason } = usePauseTeacherDay(
    {
      onSuccess: () => window.location.reload(),
      scheduleDayId,
      teacherId,
      user,
    }
  );

  return (
    <div>
      <StyledBarContent title={"Pause Schedule"} overFlowContent={"visible"}>
        <div className="p-4">
          <div>
            <CustomLabel>
              Reason Of The Pause:<RequiredInputSpan>*</RequiredInputSpan>
            </CustomLabel>
            <CustomTextarea
              value={pauseReason}
              onChange={(e) => setPauseReason(e.target.value)}
              width="100%"
            />
          </div>
        </div>
        <div className="mt-5 mb-3 text-center">
          <PrimaryButtonWithLoading
            isLoading={loading}
            onClick={pauseDay}
            width="50%"
          >
            Pause Schedule
          </PrimaryButtonWithLoading>
        </div>
      </StyledBarContent>

      <div className="mt-5"></div>
    </div>
  );
};

export default injectUserStore(PauseTeacherDayBody);
