import styled from "styled-components";
import { Modal, ModalHeader } from "reactstrap";

const DropdownWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 1.3rem;
  }

  .modal-body {
    padding-top: 32px !important;

    @media screen and (max-width: 420px) {
      padding: 16px !important;
    }
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: space-between;
`;

const ReCaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 576px) {
    width: 70%;
  }
`;

export {
  DropdownWrapper,
  StyledModal,
  StyledModalHeader,
  ReCaptchaWrapper,
  InputWrapper,
};
