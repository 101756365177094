import React from "react";
import { InputContainer } from "../../styled";
import { CustomInput, CustomLabel } from "src/utils/shared/styled";

const LabeledInput = ({
  withLabel = true,
  label,
  value,
  onChange,
  ...props
}) => {
  return (
    <InputContainer>
      {withLabel && <CustomLabel>{label}:</CustomLabel>}
      <CustomInput
        disabledBgColor="e2e2e2"
        value={value}
        onChange={onChange}
        width="100%"
        {...props}
      />
    </InputContainer>
  );
};

export default LabeledInput;
