import store from "src/stores/UserStore";
import { isGuest } from "src/constants/UserRoleEnum";
import "./style.css";
import GuestDropdown from "../../common/GuestMap/GuestDropdown";

function About() {
  const { isLoggedIn, user } = store;

  return (
    <div id="about-us" className="about-us">
      <article className="about-us-article">
        {(isGuest(user?.role) || !isLoggedIn) && (
          <div className="guest-map-container">
            <GuestDropdown />
          </div>
        )}
        <h2 className="second-heading">About Us</h2>
        <p>
          Artis School of Music offers child center teaching philosophy,
          providing private or group lessons for piano, violin, viola, voice,
          guitar, ukulele, flute, drum, music theory, and music composition for
          ages 5 and up. Our goal is to synthesize a wide variety of disciplines
          to best fit each individual’s needs. We believe that teaching good
          fundamental techniques and positive learning attitudes are the
          cornerstone to developing a well-rounded young musician.
          <br />
          <br />
          We have established a solid foundation with many students, considering
          performance techniques and psychology in designing a well-structured
          curriculum. Our school provides monthly music events and workshops to
          encourage students to start performing and improvising music at a
          young age. Artis evaluations are also valuable opportunities for
          students to practice with high standards in repertoire, scales,
          sight-reading and aural skills, and to receive constructive feedback
          from professional, outside judges for a positive learning experience.
          <br />
          <br />
          Our goal is to synthesize a wide variety of disciplines to best fit
          each individual’s needs, and blend the learning with social, fun and
          performance-oriented settings. We believe that teaching good
          fundamental techniques and positive learning attitudes is a
          cornerstone to developing a well-rounded musician!
          <br />
        </p>
      </article>
    </div>
  );
}

export default About;
