import { Divider, Image } from "antd";
import { SummaryWrapper } from "./styled";

const SummarySection = ({ message, attachments }) => {
  return (
    <SummaryWrapper>
      <textarea readOnly defaultValue={`Your message: ${message}`} />
      {attachments?.length ? (
        <>
          <Divider />
          <p>
            <strong>Your attachments:</strong>
          </p>
          <ul>
            <Image.PreviewGroup>
              {attachments?.map((attachment, index) => (
                <li key={index}>
                  <Image
                    width={150}
                    src={attachment}
                    placeholder
                    alt="feedback attachment"
                    preview={{ destroyOnClose: true, style: { zIndex: 9999 } }}
                    loading="lazy"
                  />
                </li>
              ))}
            </Image.PreviewGroup>
          </ul>
        </>
      ) : null}
    </SummaryWrapper>
  );
};

export default SummarySection;
