import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import StyledBarContent from "src/components/common/StyledBarContent";
import {
  CalendarPickerWrapper,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import useDisableUser from "../hooks/useDisableUser";
import { CustomLabel } from "src/utils/styled";
import { PrimaryButton } from "src/utils/shared/styled";
import { injectUserStore } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const DisableUserBody = ({ modalData, userId, onSuccess, UserStore }) => {
  const user = UserStore.user;

  const { disableUser, loadingAction, deletionDate, setDeletionDate } =
    useDisableUser({ userId, authUserId: user.uid, onSuccess });

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  return (
    <div>
      <StyledBarContent title={"Delete User"} overFlowContent={"visible"}>
        <div>
          <CustomLabel>
            Deletion Date:<RequiredInputSpan>*</RequiredInputSpan>
          </CustomLabel>
          <CalendarPickerWrapper>
            <SingleDatePicker
              id="lastLessonDate"
              date={deletionDate}
              onDateChange={(date) => {
                setDeletionDate(date);
              }}
              focused={isDatePickerOpen} // PropTypes.bool
              onFocusChange={({ focused }) => {
                setIsDatePickerOpen(focused);
              }}
              // numberOfMonths={1}
              // showClearDate={true}
              isOutsideRange={(date) => {
                return false;
              }}
            />
          </CalendarPickerWrapper>
        </div>

        <div className="mt-5 mb-3 text-center">
          <PrimaryButtonWithLoading
            isLoading={loadingAction}
            onClick={disableUser}
            width="50%"
          >
            Delete User
          </PrimaryButtonWithLoading>
        </div>
      </StyledBarContent>

      <div className="mt-5"></div>
    </div>
  );
};

export default injectUserStore(DisableUserBody);
