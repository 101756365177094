import React from "react";
import { injectUserStore } from "src/utils/helpers";
import { componentViews } from "../constants";
import AcceptanceSection from "../components/AcceptanceSection";
import DetailsSection from "../components/DetailsSection";

const StudentInvitationBody = ({
  hookData,
  closeModal,
  UserStore,
  invitationId,
}) => {
  const user = UserStore.user;
  const {
    currentView,
    setCurrentView,
    goToSecondPage,
    setIsInviteAccepted,
    isInviteAccepted,
    concert,
    initialData,
    declineInvitation,
    loadingInitialData,
    acceptInvitation,
    loadingAction,
  } = hookData;

  const renderCurrentComponent = () => {
    if (loadingInitialData) {
      return <div></div>;
    } else if (currentView === componentViews.FIRST_PAGE) {
      return (
        <DetailsSection
          concert={initialData.concert}
          location={initialData.location}
          goToSecondPage={goToSecondPage}
        />
      );
    } else if (currentView === componentViews.SECOND_PAGE) {
      return (
        <AcceptanceSection
          user={user}
          isInviteAccepted={isInviteAccepted}
          setIsInviteAccepted={setIsInviteAccepted}
          declineInvitation={declineInvitation}
          loadingAction={loadingAction}
          acceptInvitation={acceptInvitation}
        />
      );
    }
  };
  return <div>{renderCurrentComponent()}</div>;
};

export default injectUserStore(StudentInvitationBody);
