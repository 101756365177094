import {
  addDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  documentId,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  ref,
} from "firebase/storage";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
import { IMAGE_EXTENSIONS } from "src/components/common/Library/common/constants";
import { FirebaseContext } from "src/components/Firebase";
import {
  absenceLessonTypes,
  absenceStatuses,
  absenceTypes,
  isDeclinedAbsence,
  isPendingAbsence,
  pendingAbsenceStatusesArr,
} from "src/constants/absenceTypes";
import { eventsMap, privateLessonTypes } from "src/constants/eventsEnum";
import { feedItems } from "src/constants/feedItemsEnum";
import { makeupRequestStatuses } from "src/constants/makeupRequestEnum";
import { notificationSettingsScopes } from "src/constants/notificationSettingsEnum";
import { packageLessonSetTypes } from "src/constants/packageLessonsEnum";
import {
  filtersWithMonthFilter,
  paymentPeriodFilters,
} from "src/constants/paymentPeriodFilters";
import { scheduleTypes } from "src/constants/scheduleTypes";
import { UserRole } from "src/constants/UserRoleEnum";
import {
  parseAbsenceObjDates,
  parseConcertInvitationObjDates,
  parseConcertObjDates,
  parseConcertProgramObjDates,
  parseConcertSignupObjDates,
  parseFeedItemDates,
  parseGroupClassAvailableDayDates,
  parseGroupClassDates,
  parseGroupClassEnrollmentDates,
  parseGroupClassPaymentDates,
  parseGroupClassPaymentOptionDates,
  parseLessonNoteDates,
  parseMakeupLessonDates,
  parseMakeupOpeningDates,
  parseMakeupRequestDates,
  parseNotificationDates,
  parsePrivateLessonDates,
  parseUserActivityDates,
  parseUserAvailableDayDates,
  parseUserPaymentDates,
  parseUserPaymentRateDates,
} from "src/utils/firebaseDatesParserFns";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import {
  chunkArrayInGroups,
  getCombinedPackageSetItems,
  getTimeDiffInMins,
  mapRegularLessonTimelineToActivity,
  momentPayment,
  sortArrayByDates,
  updatedMomentNow,
  uploadFile,
} from "src/utils/helpers";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getPrivateLessons = async () => {
    const snap = await getDocs(firebase.PrivateLessons());
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parsePrivateLessonDates(doc));

    return parsedDates;
  };

  const getUserNotifications = async ({
    userId,
    userRole,
    onNewNotificationsForUser = () => {}, // for updating the state with new notifications
    onNewNotificationsForRole = () => {}, // for updating the state with new notifications
  }) => {
    try {
      const q1 = query(
        firebase.notifications(),
        orderBy("createdAt", "desc"),
        where("createdAt", ">", updatedMomentNow().subtract(1, "day").toDate()),
        where(`forUsers`, "array-contains", userId)
      );
      const q2 = query(
        firebase.notifications(),
        orderBy("createdAt", "desc"),
        where("createdAt", ">", updatedMomentNow().subtract(1, "day").toDate()),
        where("forRoles", "array-contains", userRole)
      );

      // first query realtime onSnapshot
      const unsubscribe1 = onSnapshot(q1, (snap1) => {
        const notifications1 = parseFirebaseDoc(snap1.docs);
        const notifications1WithDates = notifications1.map((not) =>
          parseNotificationDates(not)
        );
        onNewNotificationsForUser(notifications1WithDates);
      });

      // second query realtime onSnapshot
      const unsubscribe2 = onSnapshot(q2, (snap2) => {
        const notifications2 = parseFirebaseDoc(snap2.docs);
        const notifications2WithDates = notifications2.map((not) =>
          parseNotificationDates(not)
        );
        onNewNotificationsForRole(notifications2WithDates);
      });

      const unsubscribe = () => {
        unsubscribe1();
        unsubscribe2();
      };

      return { unsubscribe };
    } catch (err) {
      toast.error(err.message);
      console.log(err);
      return [];
    }
  };

  const readNotification = async (notificationId, userId) => {
    if (!notificationId) return;

    await updateDoc(firebase.notification(notificationId), {
      readBy: arrayUnion(userId),
    });
  };

  const getSubmittedTeacherAbsences = async () => {
    const q = query(
      firebase.absences(),
      where("absenceType", "==", absenceTypes.teacherAbsence.code),
      where("status", "==", absenceStatuses.SUBMITTED)
    );

    const snap = await getDocs(q);
    const absences = parseFirebaseDoc(snap.docs);
    const absencesWithDates = absences.map((absence) =>
      parseAbsenceObjDates(absence)
    );

    return absencesWithDates;
  };

  const getAbsencesByIds = async (absencesIds) => {
    if (!absencesIds?.length) return [];

    const absencesIdsChunks = chunkArrayInGroups(absencesIds, 10);
    let requests = [];
    absencesIdsChunks.forEach((absenceIdsArray) => {
      const absencesQuery = query(
        firebase.absences(),
        where(documentId(), "in", absenceIdsArray)
      );
      const req = getDocs(absencesQuery);
      requests.push(req);
    });
    const absencesSnapshotsChunks = await Promise.all(requests);
    const absencesChunks = absencesSnapshotsChunks.map((absencesSnapshot) =>
      parseFirebaseDoc(absencesSnapshot.docs)
    );
    const absences = absencesChunks.flat();

    const absencesWithDates = absences.map((absence) =>
      parseAbsenceObjDates(absence)
    );

    return absencesWithDates;
  };

  const getMakeupLessonsByIds = async (makeupsIds) => {
    if (!makeupsIds?.length) return [];

    const makeupsIdsChunks = chunkArrayInGroups(makeupsIds, 10);
    let requests = [];
    makeupsIdsChunks.forEach((makeupsIdsArray) => {
      const makeupsQuery = query(
        firebase.makeupLessons(),
        where(documentId(), "in", makeupsIdsArray)
      );
      const req = getDocs(makeupsQuery);
      requests.push(req);
    });
    const makeupsSnapshotsChunks = await Promise.all(requests);
    const makeupsChunks = makeupsSnapshotsChunks.map((absencesSnapshot) =>
      parseFirebaseDoc(absencesSnapshot.docs)
    );
    const makeupLessons = makeupsChunks.flat();

    const makeupLessonsWithDates = makeupLessons.map((lesson) =>
      parseMakeupLessonDates(lesson)
    );

    return makeupLessonsWithDates;
  };

  const getPrivateLessonsByIds = async (privateLessonsIds) => {
    if (!privateLessonsIds?.length) return [];

    const privateLessonsIdsChunks = chunkArrayInGroups(privateLessonsIds, 10);
    let requests = [];
    privateLessonsIdsChunks.forEach((privateLessonsIdsArray) => {
      const q = query(
        firebase.PrivateLessons(),
        where(documentId(), "in", privateLessonsIdsArray)
      );
      const req = getDocs(q);
      requests.push(req);
    });
    const snapshotsChunks = await Promise.all(requests);
    const privateLessonsChunks = snapshotsChunks.map((absencesSnapshot) =>
      parseFirebaseDoc(absencesSnapshot.docs)
    );
    const lessons = privateLessonsChunks.flat();

    const lessonsWithDates = lessons.map((lesson) =>
      parsePrivateLessonDates(lesson)
    );

    return lessonsWithDates;
  };

  const getUsersByIds = async (usersIds) => {
    if (!usersIds?.length) return [];

    const idsChunks = chunkArrayInGroups(usersIds, 10);
    let requests = [];
    idsChunks.forEach((idsArray) => {
      const q = query(firebase.users(), where(documentId(), "in", idsArray));
      const req = getDocs(q);
      requests.push(req);
    });
    const snapshotsChunks = await Promise.all(requests);
    const docsChunks = snapshotsChunks.map((snap) =>
      parseFirebaseDoc(snap.docs)
    );
    const users = docsChunks.flat();

    return users;
  };
  const getUserById = async (userId) => {
    if (!userId) return;

    const snap = await getDoc(firebase.user(userId));
    const doc = { id: userId, ...snap.data() };

    return doc;
  };
  const getUserActivityById = async (activityId) => {
    if (!activityId) return;

    const snap = await getDoc(firebase.userActivity(activityId));
    const doc = { id: activityId, ...snap.data() };
    const parsedDates = parseUserActivityDates(doc);

    return parsedDates;
  };
  const getUsersByRoles = async (roles) => {
    if (!roles?.length) return [];

    const q = query(firebase.users(), where("role", "in", roles));
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getTeacherActivities = async (teacherId) => {
    const q1 = query(
      firebase.usersActivities(),
      where("userId", "==", teacherId),
      where("isActive", "==", true)
    );
    const q2 = query(
      firebase.usersActivities(),
      where("usersIds", "array-contains", teacherId),
      where("isActive", "==", true)
    );
    const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
    const [docs1, docs2] = [
      parseFirebaseDoc(snap1.docs),
      parseFirebaseDoc(snap2.docs),
    ];
    const combinedActivities = [...docs1, ...docs2];

    const activitiesWithParsedDates = combinedActivities.map((activity) =>
      parseUserActivityDates(activity)
    );
    return activitiesWithParsedDates;
  };
  const getSchoolYears = async () => {
    const snap = await getDocs(firebase.getSchoolYears());
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };

  const getTeachersAvailableDays = async () => {
    const availableDaysQuery = query(
      firebase.usersAvailableDays(),
      where("userRole", "==", UserRole.TEACHER)
    );
    const availableDaysSnap = await getDocs(availableDaysQuery);
    const availableDays = parseFirebaseDoc(availableDaysSnap.docs);
    const availableDaysWithDates = availableDays.map((day) =>
      parseUserAvailableDayDates(day)
    );
    return availableDaysWithDates;
  };
  const getTeacherAvailableDays = async (teacherId) => {
    const availableDaysSnap = await getDoc(
      firebase.userAvailableDays(teacherId)
    );

    const availableDays = {
      id: availableDaysSnap.id,
      ...availableDaysSnap.data(),
    };
    const availableDaysWithDates = parseUserAvailableDayDates(availableDays);

    return availableDaysWithDates;
  };
  const getLocations = async () => {
    const snap = await getDocs(firebase.locations());
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getTeachers = async () => {
    const q = query(firebase.users(), where("role", "==", UserRole.TEACHER));
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getStudents = async () => {
    const q = query(firebase.users(), where("role", "==", UserRole.STUDENT));
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  // admins and SA
  const getAdminsTeam = async () => {
    const q = query(
      firebase.users(),
      where("role", "in", [UserRole.ADMIN, UserRole.SUPER_ADMIN])
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getTeachersAndAdminTeam = async () => {
    const q = query(
      firebase.users(),
      where("role", "in", [
        UserRole.ADMIN,
        UserRole.SUPER_ADMIN,
        UserRole.TEACHER,
      ])
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getUsers = async () => {
    const snap = await getDocs(firebase.users());
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };

  const getStudioUsage = async (
    month = null,
    year = null,
    location_id = null,
    teachers_ids = null
  ) => {
    const collRef = firebase.studioUsages();
    const queryParams = [collRef];
    if (month) {
      queryParams.push(where("month", "==", month));
    }
    if (year) {
      queryParams.push(where("year", "==", year));
    }
    if (location_id) {
      queryParams.push(where("location_id", "==", location_id));
    }
    if (teachers_ids) {
      queryParams.push(where("teacher_id", "in", teachers_ids));
    }
    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };

  const addStudioUsage = async (studioUsage) => {
    if (!studioUsage) return;

    const res = await addDoc(firebase.studioUsages(), studioUsage);
    return res;
  };

  const deleteStudioUsage = async (studioUsageId) => {
    if (!studioUsageId) return;
    await deleteDoc(firebase.studioUsage(studioUsageId));
  };

  const getPrivateLessonsByStudentsIds = async (studentsIds) => {
    if (!studentsIds?.length) return [];
    const studentsIdsChunks = chunkArrayInGroups(studentsIds, 10);
    let requests = [];
    studentsIdsChunks.forEach((studentsIdsArray) => {
      const lessonsQuery = query(
        firebase.PrivateLessons(),
        where("studentId", "in", studentsIdsArray)
      );
      const req = getDocs(lessonsQuery);
      requests.push(req);
    });
    const snapshotsChunks = await Promise.all(requests);
    const docsChunks = snapshotsChunks.map((snap) =>
      parseFirebaseDoc(snap.docs)
    );
    const docs = docsChunks.flat();

    const parsedDates = docs.map((doc) => parsePrivateLessonDates(doc));
    return parsedDates;
  };

  const getPrivateLessonsAbsences = async (
    privateLessonsIds,
    includeInactiveAbsences = false,
    includeDurationAbsences = true
  ) => {
    if (!privateLessonsIds?.length) return [];

    const privateLessonsIdsChunks = chunkArrayInGroups(privateLessonsIds, 10);
    let requests = [];
    privateLessonsIdsChunks.forEach((privateLessonsIdsArray) => {
      // Can't have "not-in" with "array-contains" or "in" on the same query, but we filter pending absences on client side anyways
      const q1 = query(
        firebase.absences(),
        where("lessonId", "in", privateLessonsIdsArray)
      );
      const q2 = query(
        firebase.absences(),
        where(
          "affectedPrivateLessonsIds",
          "array-contains-any",
          privateLessonsIdsArray
        )
      );

      const req1 = getDocs(q1);
      const req2 = getDocs(q2);

      requests.push(req1);
      if (includeDurationAbsences) requests.push(req2);
    });
    const snapshotsChunks = await Promise.all(requests);
    const docsChunks = snapshotsChunks.map((snap) =>
      parseFirebaseDoc(snap.docs)
    );
    const docs = docsChunks.flat();

    const filteredDocs = includeInactiveAbsences
      ? docs
      : docs.filter(
          ({ status }) =>
            !isPendingAbsence(status) && !isDeclinedAbsence(status)
        );

    const parsedDates = filteredDocs.map((doc) => parseAbsenceObjDates(doc));

    return parsedDates;
  };

  const getMakeUpLessonsByAbsencesIds = async (absencesIds) => {
    if (!absencesIds?.length) return [];

    const absencesIdsChunks = chunkArrayInGroups(absencesIds, 10);
    let requests = [];
    absencesIdsChunks.forEach((absencesIdsArray) => {
      const q = query(
        firebase.makeupLessons(),
        where("forAbsenceId", "in", absencesIdsArray)
      );

      const req = getDocs(q);
      requests.push(req);
    });
    const snapshotsChunks = await Promise.all(requests);
    const docsChunks = snapshotsChunks.map((snap) =>
      parseFirebaseDoc(snap.docs)
    );
    const docs = docsChunks.flat();

    const parsedDates = docs.map((doc) => parseMakeupLessonDates(doc));
    return parsedDates;
  };

  const updateUserAuth = async (userId, updateObj) => {
    if (!userId || !updateObj) {
      toast.error("userId or updateObj wasnt provided ( updateUserAuth() )");

      return;
    }
    const { data: newUserRecord } = await firebase.updateUserAuthWithAdminSDK({
      userId,
      updateObj,
    });

    return newUserRecord;
  };

  const getPrivateLessonsByTeacherId = async (teacherId) => {
    if (!teacherId) {
      return [];
    }

    const q = query(
      firebase.PrivateLessons(),
      where("teachersIds", "array-contains", teacherId)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parsePrivateLessonDates(doc));
    return parsedDates;
  };

  const updatePrivateLesson = async (lessonId, updateObj) => {
    if (!updateObj || !lessonId) {
      toast.error(
        "lessonId or updateObj wasnt provided ( updatePrivateLesson() )"
      );
      return;
    }

    await updateDoc(firebase.PrivateLesson(lessonId), updateObj);

    return;
  };
  const updateAbsence = async (absenceId, updateObj) => {
    if (!updateObj || !absenceId) {
      toast.error("absenceId or updateObj wasnt provided ( updateAbsence() )");
      return;
    }

    await updateDoc(firebase.absence(absenceId), updateObj);

    return;
  };

  const createCalendarLabel = async (labelObj) => {
    if (!labelObj) return;

    const res = await addDoc(firebase.calendarLabels(), labelObj);
    return res;
  };

  const getUserLibraryItems = async (userId) => {
    if (!userId) return [];

    const q = query(firebase.library(), where("userId", "==", userId));
    const snap = await getDocs(q);

    const parsedDocs = parseFirebaseDoc(snap.docs);

    return parsedDocs;
  };
  const getFileMetadata = async (path) => {
    if (!path) return;
    const fileRef = ref(firebase.storage, path);

    const metadata = await getMetadata(fileRef);

    return metadata;
  };
  const changeUserProfileImage = async (userId, file) => {
    if (!userId || !file) return;

    const { type } = file;
    const extension = type.split("/")[1];
    if (!IMAGE_EXTENSIONS.includes(extension)) {
      throw new Error("File extension not supported");
    }

    const { downloadUrl } = await uploadFile(
      firebase,
      file,
      "profileImages/",
      userId
    );
    const userDoc = firebase.user(userId);

    await updateDoc(userDoc, { imageUrl: downloadUrl });

    return { downloadUrl };
  };

  const getSchoolPolicies = async () => {
    const snap = await getDocs(firebase.schoolPolicies());
    const docs = parseFirebaseDoc(snap.docs);

    return docs;
  };
  const getSchoolPolicy = async (policyType) => {
    if (!policyType) return;

    const q = query(firebase.schoolPolicies(), where("type", "==", policyType));

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const policy = docs[0];

    return policy;
  };

  const addNewSchoolPolicy = async (newPolicyObj) => {
    if (!newPolicyObj) return;

    const res = await addDoc(firebase.schoolPolicies(), newPolicyObj);
    return res;
  };
  const updateSchoolPolicy = async (policyId, updateObj) => {
    if (!policyId || !updateObj) return;

    await updateDoc(firebase.schoolPolicy(policyId), updateObj);
  };
  const getInstruments = async () => {
    const snap = await getDocs(firebase.instruments());
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getStudios = async (sortBy = null, direction = null) => {
    const collRef = firebase.studios();
    const queryParams = [collRef];
    if (sortBy && direction) {
      queryParams.push(orderBy(sortBy, direction));
    }
    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getPrograms = async () => {
    const snap = await getDocs(firebase.programs());
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };

  const getTeacherProgramById = async (programId) => {
    if (!programId) return;

    const snap = await getDoc(firebase.program(programId));
    const doc = { id: programId, ...snap.data() };

    return doc;
  };
  const getParents = async () => {
    const q = query(firebase.users(), where("role", "==", UserRole.PARENT));
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getStudentsByParentsEmails = async (parentsEmails) => {
    if (!parentsEmails?.length) return [];
    const emailsChunks = chunkArrayInGroups(parentsEmails, 10);
    let requests = [];
    emailsChunks.forEach((emails) => {
      const q1 = query(
        firebase.users(),
        where("primaryContactEmail", "in", emails)
      );
      const q2 = query(
        firebase.users(),
        where("secondaryContactEmail", "in", emails)
      );
      const req1 = getDocs(q1);
      const req2 = getDocs(q2);
      requests.push(req1, req2);
    });
    const snapshotsChunks = await Promise.all(requests);
    const docsChunks = snapshotsChunks.map((snap) =>
      parseFirebaseDoc(snap.docs)
    );
    const docs = docsChunks.flat();

    const uniqueDocs = docs.filter(
      (doc, i, arr) => arr.findIndex((v) => v.id === doc.id) === i
    );

    return uniqueDocs;
  };
  const getSchoolNotificationSettingsByType = async (type) => {
    if (!type) return;

    const q = query(
      firebase.notificationSettings(),
      where("scope", "==", notificationSettingsScopes.SCHOOL),
      where("type", "==", type)
    );
    const snap = await getDocs(q);

    const docs = parseFirebaseDoc(snap.docs);
    const doc = docs[0];
    return doc;
  };
  const getUserNotificationSettingsByType = async (userId, type) => {
    if (!type) return;

    const q = query(
      firebase.notificationSettings(),
      where("scope", "==", notificationSettingsScopes.USER),
      where("userId", "==", userId),
      where("type", "==", type)
    );
    const snap = await getDocs(q);

    const docs = parseFirebaseDoc(snap.docs);
    const doc = docs[0];
    return doc;
  };
  const updateNotificationSettingsDoc = async (docId, updateObj) => {
    if (!updateObj || !docId) return;

    await updateDoc(firebase.notificationSetting(docId), updateObj);
  };
  const createNotificationSettingsDoc = async (settingsObj) => {
    if (!settingsObj) return;

    await addDoc(firebase.notificationSettings(), settingsObj);
  };

  const getFeedItems = async (
    options = {
      maxDate: undefined,
      type: undefined,
      afterPublishDate: true,
      filterByRoles: [],
      feedItemId: null,
    }
  ) => {
    const {
      maxDate,
      type,
      afterPublishDate = true,
      filterByRoles = [],
      feedItemId,
    } = options || {};

    const collRef = firebase.feedItems();
    const queryParams = [collRef];

    // if maxDate provided, will only get docs that were published after that date
    if (maxDate) {
      queryParams.push(where("publishDate", ">", maxDate));
    }
    // type filter
    if (type) {
      queryParams.push(where("type", "==", type));
    }
    // filters feed items by the role that should see the item (supports up to 10 items only)
    if (filterByRoles?.length) {
      queryParams.push(where("forRoles", "array-contains-any", filterByRoles));
    }
    // filter by document id
    if (feedItemId) {
      queryParams.push(where(documentId(), "==", feedItemId));
    }
    if (maxDate) {
      queryParams.push(orderBy("publishDate", "desc"));
    }

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((doc) => parseFeedItemDates(doc));

    // only gets the docs that has publishDate property before today date
    const filteredDocs = parsedDates.filter(({ publishDate }) => {
      const todayDate = updatedMomentNow().toDate();

      const passPublishDateCheck = afterPublishDate
        ? publishDate && moment(publishDate).isSameOrBefore(todayDate, "minute")
        : true;

      return passPublishDateCheck;
    });

    const sortedDocs = sortArrayByDates(filteredDocs, {
      asc: false,
      dateAccessor: "publishDate",
    });

    return sortedDocs;
  };

  const updateFeedItem = async (itemId, updateObj) => {
    if (!itemId || !updateObj) {
      return;
    }
    await updateDoc(firebase.feedItem(itemId), updateObj);
  };
  const getUserFeedItemsMetadata = async (
    userId,
    options = { feedItemType: "" }
  ) => {
    if (!userId) {
      return;
    }
    const { feedItemType } = options;
    const collRef = firebase.feedItemsMetadata();
    const queryParams = [collRef];

    queryParams.push(where("userId", "==", userId));
    if (feedItemType) {
      queryParams.push(where("feedItemType", "==", feedItemType));
    }

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const getFeedItemMetadata = async (
    feedItemId,
    options = { userId: "", actionType: "" }
  ) => {
    if (!feedItemId) {
      return;
    }
    const { actionType, userId } = options;

    const collRef = firebase.feedItemsMetadata();
    const queryParams = [collRef];
    queryParams.push(where("feedItemId", "==", feedItemId));
    if (actionType) {
      queryParams.push(where("actionType", "==", actionType));
    }
    if (userId) {
      queryParams.push(where("userId", "==", userId));
    }
    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };
  const addFeedItemMetadata = async (metaObj) => {
    if (!metaObj) {
      return;
    }
    const resRef = await addDoc(firebase.feedItemsMetadata(), metaObj);

    let createdDoc;
    if (resRef.id) {
      createdDoc = {
        id: resRef.id,
        ...metaObj,
      };
    }
    return createdDoc;
  };
  const deleteFeedItemMetadata = async (
    feedItemId,
    options = { userId: "", actionType: "" }
  ) => {
    const { userId, actionType } = options;
    if (!userId || !feedItemId || !actionType) {
      return;
    }
    const feedDocs = await getFeedItemMetadata(feedItemId, {
      actionType,
      userId,
    });

    const docsIds = feedDocs.map(({ id }) => id);
    for (const docId of docsIds) {
      await deleteDoc(firebase.feedItemMetadata(docId));
    }

    return docsIds;
  };
  const deleteFeedItemWithClearMetaOption = async (
    feedItemId,
    attachmentsUrls = [],
    clearMetadata = true
  ) => {
    if (!feedItemId) {
      return;
    }
    await deleteDoc(firebase.feedItem(feedItemId));

    // deletes storage files
    for (const url of attachmentsUrls) {
      try {
        const objectRef = ref(firebase.storage, url);
        await deleteObject(objectRef);
      } catch (err) {
        console.log(err);
      }
    }

    // clears the feed item metadata
    if (clearMetadata) {
      const feedDocs = await getFeedItemMetadata(feedItemId);

      const docsIds = feedDocs.map(({ id }) => id);
      for (const docId of docsIds) {
        await deleteDoc(firebase.feedItemMetadata(docId));
      }
    }

    return;
  };

  const permaDeleteUserAccount = async (userId) => {
    if (!userId) return;

    const dataObj = {
      userId,
    };
    await firebase.deleteUserWithAdminSDK(dataObj);
  };

  const updateUserInfo = async (userId, updateObj) => {
    if (!userId || !updateObj) return;
    await updateDoc(firebase.user(userId), updateObj);
  };
  const disableTeacher = async (userId, disableObj) => {
    if (!userId || !disableObj) return;

    const updateObj = {
      disableObj,
    };
    await updateDoc(firebase.user(userId), updateObj);
    await deleteDoc(firebase.userAvailableDays(userId));
    const userMakeupOpenings = await getUserMakeUpOpenings(userId);

    //delete future makeup openings
    for (const opening of userMakeupOpenings) {
      if (updatedMomentNow().isBefore(opening.startDate, "minutes"))
        await deleteDoc(firebase.makeupOpening(opening.id));
    }
  };

  const updateTrialLesson = async (lessonId, updateObj) => {
    if (!updateObj || !lessonId) {
      toast.error(
        "lessonId or updateObj wasnt provided ( updateTrialLesson() )"
      );
      return;
    }
    await updateDoc(firebase.trialLesson(lessonId), updateObj);
    return;
  };
  const updateUserActivity = async (activityId, updateObj) => {
    if (!updateObj || !activityId) {
      toast.error(
        "activityId or updateObj wasnt provided ( updateUserActivity() )"
      );
      return;
    }
    await updateDoc(firebase.userActivity(activityId), updateObj);
    return;
  };

  const getUsersByFullName = async (fullName) => {
    if (!fullName) return [];
    const q = query(firebase.users(), where("fullName", "==", fullName));
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };

  const deleteTrialLessonAndActivity = async (lessonId, studentId) => {
    if (!lessonId) return;
    await deleteDoc(firebase.trialLesson(lessonId));
    await deleteDoc(firebase.userActivity(lessonId));
    await updateDoc(firebase.trialStudent(studentId), {
      lessons: arrayRemove(lessonId),
    });
  };

  const getTeachersActivities = async () => {
    const q = query(firebase.usersActivities(), where("isActive", "==", true));
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((doc) => parseUserActivityDates(doc));
    return parsedDates;
  };

  const getAbsences = async () => {
    const q = query(
      firebase.absences(),
      where("status", "not-in", pendingAbsenceStatusesArr)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const filtered = docs.filter(
      ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
    );
    const parsedDates = filtered.map((absence) => {
      return parseAbsenceObjDates(absence);
    });
    return parsedDates;
  };
  const getTeachersMakeUpOpenings = async () => {
    const q = query(
      firebase.makeupOpenings(),
      where("userRole", "==", UserRole.TEACHER)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseMakeupOpeningDates(doc));
    return parsedDates;
  };
  const getUserMakeUpOpenings = async (userId) => {
    if (!userId) return [];
    const q = query(
      firebase.makeupOpenings(),
      where("userRole", "==", UserRole.TEACHER),
      where("userId", "==", userId)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseMakeupOpeningDates(doc));
    return parsedDates;
  };

  const createPrivateLesson = async (lessonObj) => {
    if (!lessonObj) return;

    const res = await addDoc(firebase.PrivateLessons(), lessonObj);
    return res;
  };
  const createUserActivity = async (docId, activityObj) => {
    if (!activityObj || !docId) return;

    const res = await setDoc(firebase.userActivity(docId), activityObj);
    return res;
  };
  const createPackagePrivateLessonAndActivity = async (lessonObj) => {
    if (!lessonObj) return;

    const lessonRes = await createPrivateLesson(lessonObj);

    if (lessonRes.id) {
      // updates the student's private lessons array
      await updateUserInfo(lessonObj.studentId, {
        Privatelessons: arrayUnion(lessonRes.id),
      });

      const combinedPackageItems = getCombinedPackageSetItems(
        lessonObj.packageSets
      );
      const activityPackageItems = combinedPackageItems.map((packageItem) => {
        const activityStart = packageItem.startDate;
        const activityDuration = packageItem.duration;
        const activityEnd = moment(activityStart)
          .add(parseInt(activityDuration), "minutes")
          .toDate();
        return {
          id: packageItem.id,
          start_time: activityStart,
          end_time: activityEnd,
          location: packageItem.locationId,
          userId: packageItem.teacherId,
          userRole: UserRole.TEACHER,
        };
      });

      const activityObj = {
        createdAt: new Date(),
        scheduleType: scheduleTypes.teacherDays.code,
        type: eventsMap.privateLesson.code,
        privateLessonType: privateLessonTypes.PACKAGE,
        isActive: true,
        packageItems: activityPackageItems,
      };
      await createUserActivity(lessonRes.id, activityObj);
    }
    return lessonRes;
  };
  const createPackageItemAndActivity = async (
    packageLessonId,
    packageItemObj,
    setNumber
  ) => {
    if (!packageLessonId || !packageItemObj || !setNumber) {
      return;
    }
    const [packageLesson] = await getPrivateLessonsByIds([packageLessonId]);
    let updatedPackageSets;
    if (
      !!packageLesson.packageSets.find(
        (setObj) => setObj.setNumber === setNumber
      )
    ) {
      updatedPackageSets = packageLesson.packageSets.map((set) => {
        if (set.setNumber === setNumber) {
          const updatedSet = {
            ...set,
            setItems: [...(set.setItems || []), packageItemObj],
          };
          return updatedSet;
        } else {
          return set;
        }
      });
    } else {
      const newSet = {
        setNumber: setNumber,
        setType: packageLessonSetTypes.SINGLE,
        setItems: [packageItemObj],
      };
      updatedPackageSets = [...packageLesson.packageSets, newSet];
    }
    await updateDoc(firebase.PrivateLesson(packageLessonId), {
      packageSets: updatedPackageSets,
    });

    const activityStart = packageItemObj.startDate;
    const activityDuration = packageItemObj.duration;
    const activityEnd = moment(activityStart)
      .add(parseInt(activityDuration), "minutes")
      .toDate();
    const packageItemActivityObj = {
      id: packageItemObj.id,
      start_time: activityStart,
      end_time: activityEnd,
      location: packageItemObj.locationId,
      userId: packageItemObj.teacherId,
      userRole: UserRole.TEACHER,
    };

    await updateDoc(firebase.userActivity(packageLessonId), {
      packageItems: arrayUnion(packageItemActivityObj),
    });
  };

  const updatePackageSetsAndActivity = async (
    updatedPackageSets,
    packageLessonId
  ) => {
    if (!packageLessonId || !updatedPackageSets) {
      return;
    }

    await updateDoc(firebase.PrivateLesson(packageLessonId), {
      packageSets: updatedPackageSets,
    });

    const combinedPackageItems = getCombinedPackageSetItems(updatedPackageSets);

    const updatedActivityPackage = combinedPackageItems.map((packageItem) => {
      const activityStart = packageItem.startDate;
      const activityDuration = packageItem.duration;
      const activityEnd = moment(activityStart)
        .add(parseInt(activityDuration), "minutes")
        .toDate();
      const packageItemActivityObj = {
        id: packageItem.id,
        start_time: activityStart,
        end_time: activityEnd,
        location: packageItem.locationId,
        userId: packageItem.teacherId,
        userRole: UserRole.TEACHER,
      };

      return packageItemActivityObj;
    });
    await updateDoc(firebase.userActivity(packageLessonId), {
      packageItems: updatedActivityPackage,
    });
  };
  const getStudentMakeupLessons = async (studentId) => {
    if (!studentId) return [];
    const q = query(
      firebase.makeupLessons(),
      where("studentId", "==", studentId)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((doc) => parseMakeupLessonDates(doc));
    return parsedDates;
  };

  const getActivitiesByTeachersIds = async (teachersIds) => {
    if (teachersIds.length) {
      const q1 = query(
        firebase.usersActivities(),
        where("userId", "in", teachersIds),
        where("isActive", "==", true)
      );
      const q2 = query(
        firebase.usersActivities(),
        where("usersIds", "array-contains-any", teachersIds),
        where("isActive", "==", true)
      );
      const [snap1, snap2] = await Promise.all([getDocs(q1), getDocs(q2)]);
      const [docs1, docs2] = [
        parseFirebaseDoc(snap1.docs),
        parseFirebaseDoc(snap2.docs),
      ];
      const combinedActivities = [...docs1, ...docs2];
      return combinedActivities;
    } else {
      return [];
    }
  };

  const getTeachersByIds = async (teachersIds) => {
    if (!teachersIds?.length) return [];
    const idsChunks = chunkArrayInGroups(teachersIds, 10);
    let requests = [];
    idsChunks.forEach((idsArray) => {
      const q = query(firebase.users(), where(documentId(), "in", idsArray));
      const req = getDocs(q);
      requests.push(req);
    });
    const snapChunks = await Promise.all(requests);
    const docsChunks = snapChunks.map((snap) => parseFirebaseDoc(snap.docs));
    const docs = docsChunks.flat();
    return docs;
  };

  const createAbsenceDoc = async (absenceObj) => {
    if (!absenceObj) return;
    const res = await addDoc(firebase.absences(), absenceObj);
    return res;
  };

  const editRegularLessonAndActivityTimeline = async ({
    updatedTimeline,
    lessonId,
  }) => {
    try {
      if (!lessonId || !updatedTimeline) {
        toast.error("Can't find lessonId or updatedTimeline ");
        return;
      }

      await updateDoc(firebase.PrivateLesson(lessonId), {
        timeline: updatedTimeline,
      });

      const updatedActivityTimeline = updatedTimeline.map((timelineObj) => {
        const lastDate = timelineObj.lastDate;
        const activityStart = timelineObj.startDate;
        const lessonDuration = timelineObj.duration;

        const activityEnd = moment(activityStart)
          .add(lessonDuration, "minutes")
          .toDate();
        const activityTimelineObj = {
          id: timelineObj.id, // same id that the timeline has in the original doc
          start_time: activityStart, // first lesson date
          end_time: activityEnd,
          location: timelineObj.locationId,
          userId: timelineObj.teacherId,
          userRole: UserRole.TEACHER,
          ...(lastDate && {
            lastDate: lastDate,
          }),
        };

        return activityTimelineObj;
      });
      await updateDoc(firebase.userActivity(lessonId), {
        timeline: updatedActivityTimeline,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteSummerBreakAndActivity = async (lessonId, summerBreakId) => {
    if (!lessonId || !summerBreakId) {
      toast.error("Can't find lessonId or summerBreakId ");
      return;
    }
    const [[lesson], activity] = await Promise.all([
      getPrivateLessonsByIds([lessonId]),
      getUserActivityById(lessonId),
    ]);
    if (!lesson || !activity) {
      toast.error("Something went wrong with (deleteSummerBreakAndActivity())");
      return;
    }

    const toBeDeletedBreak = lesson.summerBreaks?.find(
      ({ id }) => id === summerBreakId
    );

    const updatedSummerBreaks = lesson.summerBreaks?.filter(
      ({ id }) => id !== toBeDeletedBreak.id
    );
    await updateDoc(firebase.PrivateLesson(lessonId), {
      summerBreaks: updatedSummerBreaks,
    });

    const currentCanceledRanges = activity.canceledRanges || [];
    const updatedCanceledRanges = currentCanceledRanges.filter(
      (range) =>
        !moment(toBeDeletedBreak.breakStart).isSame(range.start, "minutes")
    );

    await updateDoc(firebase.userActivity(lessonId), {
      canceledRanges: updatedCanceledRanges,
    });
  };
  const deleteAppointmentAndActivity = async (appointmentId) => {
    if (!appointmentId) return;
    await deleteDoc(firebase.appointment(appointmentId));
    await deleteDoc(firebase.userActivity(appointmentId));
  };

  const updateMakeupLesson = async (lessonId, updateObj) => {
    if (!updateObj || !lessonId) {
      toast.error(
        "lessonId or updateObj wasnt provided ( updateMakeupLesson() )"
      );
      return;
    }
    await updateDoc(firebase.makeupLesson(lessonId), updateObj);
    return;
  };
  const getUserPaymentRates = async (
    userId,
    options = { orderByDate: false }
  ) => {
    if (!userId) return;

    const { orderByDate } = options || {};

    const queryParams = [firebase.usersPaymentRates()];
    if (orderByDate) {
      queryParams.push(orderBy("createdAt", "desc"));
    }
    queryParams.push(where("userId", "==", userId));
    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((doc) => parseUserPaymentRateDates(doc));
    return parsedDates;
  };
  const createUserPaymentRates = async (ratesObj) => {
    if (!ratesObj) return;
    const res = await addDoc(firebase.usersPaymentRates(), ratesObj);
    return res;
  };

  const addUserPayment = async (paymentObj) => {
    if (!paymentObj) return;
    const res = await addDoc(firebase.usersPayment(), paymentObj);
    return res;
  };

  const getPaymentsWithinDateRange = async ({
    startDate,
    endDate,
    userRoles,
  }) => {
    if (!startDate || !endDate) return [];

    const queryParams = [
      firebase.usersPayment(),
      where("startDate", "==", startDate),
      where("endDate", "==", endDate),
    ];
    if (userRoles?.length) {
      queryParams.push(where("userRole", "in", userRoles));
    }

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((doc) => parseUserPaymentDates(doc));

    const sorted = parsedDates.sort((a, b) => {
      const firstDate = a.startDate;
      const secondDate = b.startDate;

      return getTimeDiffInMins(firstDate, secondDate);
    });
    return sorted;
  };
  const getPaymentsDuringDatePeriod = async ({ period, month, userRoles }) => {
    if (!period) return [];

    const queryParams = [firebase.usersPayment()];
    if (period === paymentPeriodFilters.currentMonth.value) {
      const startDate = momentPayment().startOf("month").toDate();
      queryParams.push(where("startDate", ">=", startDate));
    } else if (period === paymentPeriodFilters.currentYear.value) {
      const startDate = momentPayment().startOf("year").toDate();
      queryParams.push(where("startDate", ">=", startDate));
    } else if (period === paymentPeriodFilters.lastMonth.value) {
      const startDate = momentPayment()
        .subtract(1, "month")
        .startOf("month")
        .toDate();
      const endDate = momentPayment()
        .subtract(1, "month")
        .endOf("month")
        .toDate();
      queryParams.push(where("startDate", ">=", startDate));
      queryParams.push(where("startDate", "<=", endDate));
    } else if (period === paymentPeriodFilters.lastYear.value) {
      const startDate = momentPayment()
        .subtract(1, "year")
        .startOf("year")
        .toDate();
      const endDate = momentPayment()
        .subtract(1, "year")
        .endOf("year")
        .toDate();
      queryParams.push(where("startDate", ">=", startDate));
      queryParams.push(where("startDate", "<=", endDate));
    }

    if (userRoles?.length) {
      queryParams.push(where("userRole", "in", userRoles));
    }

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((doc) => parseUserPaymentDates(doc));
    let filtered = [];
    if (
      filtersWithMonthFilter.includes(period) &&
      month !== undefined &&
      month !== ""
    ) {
      filtered = parsedDates.filter(
        ({ startDate }) => momentPayment(startDate).month() === month
      );
    } else {
      filtered = parsedDates;
    }

    const sorted = filtered.sort((a, b) => {
      const firstDate = a.startDate;
      const secondDate = b.startDate;

      return getTimeDiffInMins(firstDate, secondDate);
    });
    return sorted;
  };
  const getUserPayments = async (userId, options = { orderByDate: false }) => {
    if (!userId) return [];

    const { orderByDate } = options || {};

    const queryParams = [firebase.usersPayment()];
    if (orderByDate) {
      queryParams.push(orderBy("startDate", "desc"));
    }
    queryParams.push(where("userId", "==", userId));
    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseUserPaymentDates(doc));
    return parsedDates;
  };
  const getUserPaymentRateById = async (rateId) => {
    if (!rateId) return;

    const snap = await getDoc(firebase.userPaymentRate(rateId));
    const doc = { id: rateId, ...snap.data() };

    return doc;
  };
  const updateUserPayment = async (paymentId, updateObj) => {
    if (!paymentId || !updateObj) {
      toast.error("Error with ( updateUserPayment() )");
      return;
    }

    await updateDoc(firebase.userPayment(paymentId), updateObj);

    return;
  };

  const updateUserAvailableDay = async (dayId, updateObj) => {
    if (!dayId || !updateObj) {
      toast.error("Error with ( updateUserAvailableDay() )");
      return;
    }

    await updateDoc(firebase.userAvailableDays(dayId), updateObj);

    return;
  };
  const deleteUserPayment = async (paymentId) => {
    if (!paymentId) {
      toast.error("Error with ( deleteUserPayment() )");
      return;
    }

    await deleteDoc(firebase.userPayment(paymentId));

    return;
  };

  const getTeacherAbsences = async (teacherId, absenceType) => {
    if (!teacherId) return [];

    const queryParams = [
      firebase.absences(),
      where("teacherId", "==", teacherId),
    ];
    if (absenceType) {
      queryParams.push(where("absenceType", "==", absenceType));
    }
    const absencesQuery = query(...queryParams);

    const absences = parseFirebaseDoc((await getDocs(absencesQuery)).docs);
    const parsedDates = absences.map((absence) => {
      return parseAbsenceObjDates(absence);
    });
    return parsedDates;
  };

  const deleteTeacherAbsence = async (absenceId) => {
    if (!absenceId) return null;

    await deleteDoc(firebase.absence(absenceId));
    toast.success("Absence deleted");
  };

  const deleteMakeupRequest = async (makeupId) => {
    if (!makeupId) return null;

    await deleteDoc(firebase.makeupRequest(makeupId));
    toast.success("Makeup request deleted");
  };

  const getPrivateAndTrialStudents = async () => {
    const plStudentsQuery = query(
      firebase.users(),
      where("role", "==", UserRole.STUDENT)
    );
    const [plStudentsSnap, tlStudentsSnap] = await Promise.all([
      getDocs(plStudentsQuery),
      getDocs(firebase.trialStudents()),
    ]);
    const plStudents = parseFirebaseDoc(plStudentsSnap.docs);
    const tlStudents = parseFirebaseDoc(tlStudentsSnap.docs);
    const students = [...plStudents, ...tlStudents];
    return students;
  };

  const getUserAuthInfo = async (userId) => {
    if (!userId) return;
    const dataObj = {
      userId,
    };
    const res = await firebase.getUserFromAuthWithAdminSDK(dataObj);

    const user = res?.data;

    return user;
  };

  const deleteLibraryItemPermanently = async (libraryItem) => {
    if (!libraryItem) return;

    await deleteDoc(firebase.getSingleLibraryDoc(libraryItem.id));
    try {
      const itemRef = ref(firebase.storage, libraryItem.downloadUrl);
      await deleteObject(itemRef);
    } catch (err) {
      console.log(`error deleting object from storage`, err);
    }
  };

  const createMakeupRequest = async (makeupRequestObj) => {
    if (!makeupRequestObj) return;

    const res = await addDoc(firebase.makeupRequests(), makeupRequestObj);
    return res;
  };

  const getMakeupRequests = async () => {
    const q = query(firebase.makeupRequests(), orderBy("createdAt", "desc"));

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseMakeupRequestDates(doc));

    return parsedDates;
  };
  const getInstrumentById = async (instrumentId) => {
    if (!instrumentId) return;

    const snap = await getDoc(firebase.instrument(instrumentId));
    const doc = { id: instrumentId, ...snap.data() };

    return doc;
  };
  const getLocationById = async (id) => {
    if (!id) return;

    const snap = await getDoc(firebase.getLocation(id));
    const doc = { id: id, ...snap.data() };

    return doc;
  };

  const updateMakeupRequest = async (requestId, updateObj) => {
    if (!updateObj || !requestId) {
      toast.error(
        "requestId or updateObj wasnt provided ( updateMakeupRequest() )"
      );
      return;
    }

    await updateDoc(firebase.makeupRequest(requestId), updateObj);

    return;
  };

  const getMakeupRequestsByAbsenceId = async (forAbsenceId) => {
    if (!forAbsenceId) return;
    const q = query(
      firebase.makeupRequests(),
      where("forAbsenceId", "==", forAbsenceId),
      orderBy("createdAt", "desc")
    );

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseMakeupRequestDates(doc));

    return parsedDates;
  };
  const getPendingMakeupRequests = async () => {
    const q = query(
      firebase.makeupRequests(),
      where("status", "==", makeupRequestStatuses.PENDING),
      orderBy("createdAt", "desc")
    );

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseMakeupRequestDates(doc));

    return parsedDates;
  };

  const getMakeUpRequestsByLessonsIds = async (lessonsIds) => {
    if (!lessonsIds?.length) return [];

    const lessonsIdsChunks = chunkArrayInGroups(lessonsIds, 10);
    let requests = [];
    lessonsIdsChunks.forEach((lessonsIdsArray) => {
      const q = query(
        firebase.makeupRequests(),
        where("forLessonId", "in", lessonsIdsArray)
      );

      const req = getDocs(q);
      requests.push(req);
    });
    const snapshotsChunks = await Promise.all(requests);
    const docsChunks = snapshotsChunks.map((snap) =>
      parseFirebaseDoc(snap.docs)
    );
    const docs = docsChunks.flat();

    const parsedDates = docs
      .map((doc) => parseMakeupRequestDates(doc))
      .sort((a, b) => {
        const firstDate = a.createdAt;
        const secondDate = b.createdAt;

        return getTimeDiffInMins(firstDate, secondDate);
      });
    return parsedDates;
  };
  const returnFromSummerBreak = async (
    lessonId,
    summerBreakId,
    newTimelineObj
  ) => {
    if (!lessonId || !summerBreakId || !newTimelineObj) return;

    const [[lesson], activity] = await Promise.all([
      getPrivateLessonsByIds([lessonId]),
      getUserActivityById(lessonId),
    ]);
    if (!lesson || !activity) {
      toast.error("Something went wrong with (returnFromSummerBreak())");
      return;
    }

    const updatedTimeline = [...lesson.timeline, newTimelineObj];
    const updatedSummerBreaks = lesson.summerBreaks?.map((summerBreak) => {
      if (summerBreak.id === summerBreakId) {
        return {
          ...summerBreak,
          breakEnd: newTimelineObj.startDate,
        };
      } else {
        return summerBreak;
      }
    });
    const updateObj = {
      summerBreaks: updatedSummerBreaks,
      timeline: updatedTimeline,
    };
    await updateDoc(firebase.PrivateLesson(lessonId), updateObj);

    const updatedActivityTimeline = updatedTimeline.map((timelineObj) =>
      mapRegularLessonTimelineToActivity(timelineObj)
    );

    const currentCanceledRanges = activity.canceledRanges || [];
    const returnedFromBreakObj = lesson.summerBreaks.find(
      ({ id }) => id === summerBreakId
    );
    const updatedCanceledRanges = currentCanceledRanges.map((range) => {
      if (
        moment(returnedFromBreakObj.breakStart).isSame(range.start, "minutes")
      ) {
        return {
          ...range,
          end: newTimelineObj.startDate,
        };
      } else {
        return range;
      }
    });

    const activityUpdateObj = {
      timeline: updatedActivityTimeline,
      canceledRanges: updatedCanceledRanges,
    };
    console.log({ activityUpdateObj, updateObj });
    await updateDoc(firebase.userActivity(lessonId), activityUpdateObj);
  };

  const getPerLessonAbsencesByLessonId = async (lessonId) => {
    if (!lessonId) {
      return [];
    }

    const q = query(firebase.absences(), where("lessonId", "==", lessonId));

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseAbsenceObjDates(doc));
    return parsedDates;
  };
  const getMakeupLessonsByLessonId = async (lessonId) => {
    if (!lessonId) {
      return [];
    }

    const q = query(
      firebase.makeupLessons(),
      where("forLessonId", "==", lessonId)
    );

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    const parsedDates = docs.map((doc) => parseMakeupLessonDates(doc));
    return parsedDates;
  };

  const getLessonAttendances = async (lessonId) => {
    if (!lessonId) return [];
    const q = query(
      firebase.getLessonAttendanceCollection(),
      where("lessonId", "==", lessonId)
    );

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    return docs;
  };

  const deleteLessonAbsencesAndMakeUps = async (lessonId) => {
    if (!lessonId) return;

    const [lessonAbsences] = await Promise.all([
      getPerLessonAbsencesByLessonId(lessonId),
    ]);

    const deletedAbsencesReqs = lessonAbsences.map((absence) =>
      deleteDoc(firebase.absence(absence.id))
    );
    await Promise.all([...deletedAbsencesReqs]);
  };

  const permaDeletePrivateLesson = async (lessonId) => {
    if (!lessonId) return;

    const [
      lessonAbsences,
      lessonMakeups,
      lessonAttendances,
      makeupRequests,
      lessonNotes,
    ] = await Promise.all([
      getPerLessonAbsencesByLessonId(lessonId),
      getMakeupLessonsByLessonId(lessonId),
      getLessonAttendances(lessonId),
      getMakeUpRequestsByLessonsIds([lessonId]),
      getPLLessonNotes(lessonId),
    ]);

    const deletedAbsencesReqs = lessonAbsences.map((absence) =>
      deleteDoc(firebase.absence(absence.id))
    );
    const deletedMakeupLessonsReqs = lessonMakeups.map((makeup) =>
      deleteDoc(firebase.makeupLesson(makeup.id))
    );
    const deletedMakeupsActivitiesReqs = lessonMakeups.map((makeup) =>
      deleteDoc(firebase.userActivity(makeup.id))
    );
    const deletedAttendancesReqs = lessonAttendances.map((attendance) =>
      deleteDoc(firebase.attendance(attendance.id))
    );
    const deletedMakeupRequestsReqs = makeupRequests.map((makeupRequest) =>
      deleteDoc(firebase.makeupRequest(makeupRequest.id))
    );
    const deletedLessonNotesReqs = lessonNotes.map((note) =>
      permaDeleteLessonNote(note)
    );
    await Promise.all([
      ...deletedAbsencesReqs,
      ...deletedMakeupLessonsReqs,
      ...deletedAttendancesReqs,
      ...deletedMakeupRequestsReqs,
      ...deletedMakeupsActivitiesReqs,
      ...deletedLessonNotesReqs,
    ]);

    await Promise.all([
      deleteDoc(firebase.PrivateLesson(lessonId)),
      deleteDoc(firebase.userActivity(lessonId)),
    ]);
  };

  const permaDeleteStudentUser = async (studentId) => {
    if (!studentId) return;
    const [privateLessons, libraryItems] = await Promise.all([
      getPrivateLessonsByStudentsIds([studentId]),
      getUserLibraryItems(studentId),
    ]);
    for (const privateLesson of privateLessons) {
      await permaDeletePrivateLesson(privateLesson.id);
    }
    for (const libraryItem of libraryItems) {
      await deleteLibraryItemPermanently(libraryItem);
    }
    await permaDeleteUserAccount(studentId);
  };

  const getLessonNotesByStudentId = async (userId) => {
    if (!userId) return [];

    const q = query(
      firebase.lessonsNotes(),
      where("studentsIds", "array-contains", userId)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((doc) => parseLessonNoteDates(doc));
    return parsedDates;
  };

  const getPLLessonNotes = async (lessonId) => {
    if (!lessonId) return [];

    const [privateLesson] = await getPrivateLessonsByIds([lessonId]);
    if (!privateLesson) return [];

    const studentLessonNotes = await getLessonNotesByStudentId(
      privateLesson.studentId
    );

    const lessonTeachersIds = privateLesson.teachersIds || [];

    const teachersLessonNotes = studentLessonNotes?.filter((note) =>
      lessonTeachersIds.includes(note.teacherId)
    );

    return teachersLessonNotes;
  };

  const permaDeleteLessonNote = async (lessonNote) => {
    if (!lessonNote) return;

    await deleteDoc(firebase.lessonNote(lessonNote.id));
    for (const attachment of lessonNote.attachments || []) {
      try {
        const itemRef = ref(firebase.storage, attachment.downloadUrl);
        await deleteObject(itemRef);
      } catch (err) {
        console.log(
          `error deleting lesson note attachment object from storage`,
          err
        );
      }
    }
  };

  const createGroupClass = async (groupClassObj) => {
    if (!groupClassObj) return;

    const res = await addDoc(firebase.groupClasses(), groupClassObj);
    return res;
  };
  const createGroupClassPaymentOption = async (paymentOptionObj) => {
    if (!paymentOptionObj) return;

    const res = await addDoc(
      firebase.groupClassPaymentOptions(),
      paymentOptionObj
    );
    return res;
  };
  const getGroupClasses = async () => {
    const q = query(firebase.groupClasses());
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const parsedDates = docs.map((absence) => {
      return parseGroupClassDates(absence);
    });
    return parsedDates;
  };

  const getTeacherGroupClassDays = async (teacherId) => {
    const groupClassDaysSnap = await getDoc(
      firebase.groupClassAvailableDay(teacherId)
    );

    const availableDays = {
      id: groupClassDaysSnap.id,
      ...groupClassDaysSnap.data(),
    };
    const availableDaysWithDates =
      parseGroupClassAvailableDayDates(availableDays);

    return availableDaysWithDates;
  };
  const getTeachersGroupClassDays = async () => {
    const q = query(
      firebase.groupClassAvailableDays(),
      where("userRole", "==", UserRole.TEACHER)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((day) => parseGroupClassAvailableDayDates(day));
    return withDates;
  };
  const getPaymentOptionsByGroupClassId = async (groupClassId) => {
    if (!groupClassId) return [];
    const q = query(
      firebase.groupClassPaymentOptions(),
      where("groupClassId", "==", groupClassId)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((day) => parseGroupClassPaymentOptionDates(day));
    return withDates;
  };
  const getGroupClassPaymentOptionById = async (id) => {
    if (!id) return;

    const snap = await getDoc(firebase.groupClassPaymentOption(id));
    const doc = { id: id, ...snap.data() };

    const parsedDates = parseGroupClassPaymentOptionDates(doc);
    return parsedDates;
  };
  const getGroupClassById = async (groupClassId) => {
    if (!groupClassId) return;

    const snap = await getDoc(firebase.groupClass(groupClassId));
    const doc = { id: groupClassId, ...snap.data() };

    const parsedDates = parseGroupClassDates(doc);
    return parsedDates;
  };

  const createGroupClassPayment = async (paymentObj) => {
    if (!paymentObj) return;

    const res = await addDoc(firebase.groupClassPayments(), paymentObj);
    return res;
  };

  const createGroupClassEnrollment = async (enrollmentObj) => {
    if (!enrollmentObj) return;

    const res = await addDoc(firebase.groupClassEnrollments(), enrollmentObj);
    return res;
  };

  const getGroupClassEnrollments = async (groupClassId) => {
    const q = query(
      firebase.groupClassEnrollments(),
      where("groupClassId", "==", groupClassId)
    );
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseGroupClassEnrollmentDates(doc));
    return withDates;
  };

  const getGroupClassesByTeachersIds = async (teachersIds) => {
    if (!teachersIds?.length) return [];

    const idsChunks = chunkArrayInGroups(teachersIds, 10);
    let requests = [];
    idsChunks.forEach((idsArray) => {
      const q = query(
        firebase.groupClasses(),
        where("teachersIds", "array-contains-any", idsArray)
      );
      const req = getDocs(q);
      requests.push(req);
    });
    const snapChunks = await Promise.all(requests);
    const docsChunks = snapChunks.map((snap) => parseFirebaseDoc(snap.docs));
    const docs = docsChunks.flat();
    const withDates = docs.map((doc) => parseGroupClassDates(doc));

    return withDates;
  };
  const getGroupClassesByIds = async (groupClassesIds) => {
    if (!groupClassesIds?.length) return [];

    const idsChunks = chunkArrayInGroups(groupClassesIds, 10);
    let requests = [];
    idsChunks.forEach((idsArray) => {
      const q = query(
        firebase.groupClasses(),
        where(documentId(), "in", idsArray)
      );
      const req = getDocs(q);
      requests.push(req);
    });
    const snapChunks = await Promise.all(requests);
    const docsChunks = snapChunks.map((snap) => parseFirebaseDoc(snap.docs));
    const docs = docsChunks.flat();
    const withDates = docs.map((doc) => parseGroupClassDates(doc));

    return withDates;
  };

  const getGroupClassEnrollmentsByStudentsIds = async (studentsIds) => {
    if (!studentsIds?.length) return [];

    const idsChunks = chunkArrayInGroups(studentsIds, 10);
    let requests = [];
    idsChunks.forEach((idsArray) => {
      const q = query(
        firebase.groupClassEnrollments(),
        where("studentId", "in", idsArray)
      );
      const req = getDocs(q);
      requests.push(req);
    });
    const snapChunks = await Promise.all(requests);
    const docsChunks = snapChunks.map((snap) => parseFirebaseDoc(snap.docs));
    const docs = docsChunks.flat();
    const withDates = docs.map((doc) => parseGroupClassEnrollmentDates(doc));

    return withDates;
  };

  const getGroupClassPayments = async ({ groupClassId, userId = "" }) => {
    const collRef = firebase.groupClassPayments();
    const queryParams = [collRef];
    queryParams.push(where("groupClassId", "==", groupClassId));

    if (userId) {
      queryParams.push(where("userId", "==", userId));
    }
    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseGroupClassPaymentDates(doc));
    return withDates;
  };

  const createConcert = async (createObj) => {
    if (!createObj) return;

    const res = await addDoc(firebase.concerts(), createObj);
    return res;
  };

  const getGroupClassAbsences = async () => {
    const collRef = firebase.absences();
    const queryParams = [collRef];
    queryParams.push(where("lessonType", "==", absenceLessonTypes.GROUP_CLASS));

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseAbsenceObjDates(doc));
    return withDates;
  };

  const getUpcomingConcerts = async () => {
    const collRef = firebase.concerts();
    const queryParams = [collRef];
    queryParams.push(where("date", ">=", updatedMomentNow().toDate()));

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseConcertObjDates(doc));
    return withDates;
  };

  const getConcertById = async (id) => {
    if (!id) return;

    const snap = await getDoc(firebase.concert(id));
    const doc = { id: id, ...snap.data() };

    const parsedDates = parseConcertObjDates(doc);
    return parsedDates;
  };

  const createConcertInvitation = async (createObj) => {
    if (!createObj) return;

    const res = await addDoc(firebase.concertInvitations(), createObj);
    return res;
  };

  const getConcertInvitationsByUserId = async (userId, concertId = "") => {
    const collRef = firebase.concertInvitations();
    const queryParams = [collRef];
    queryParams.push(where("invitedUsersIds", "array-contains", userId));
    if (concertId) {
      queryParams.push(where("concertId", "==", concertId));
    }

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseConcertInvitationObjDates(doc));
    return withDates;
  };

  const updateConcertInvitation = async (invitationId, updateObj) => {
    if (!invitationId || !updateObj) {
      toast.error("Error with ( updateConcertInvitation() )");
      return;
    }

    await updateDoc(firebase.concertInvitation(invitationId), updateObj);

    return;
  };

  const getConcertSignupsByConcertId = async (concertId) => {
    const collRef = firebase.concertSignups();
    const queryParams = [collRef];
    queryParams.push(where("concertId", "==", concertId));

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseConcertSignupObjDates(doc));
    return withDates;
  };

  const getConcertSignupById = async (id) => {
    if (!id) return;

    const snap = await getDoc(firebase.concertSignup(id));
    const doc = { id: id, ...snap.data() };
    const parsedDates = parseConcertSignupObjDates(doc);

    return parsedDates;
  };

  const getConcertInvitationsByIds = async (invitationsIds) => {
    if (!invitationsIds?.length) return [];

    const idsChunks = chunkArrayInGroups(invitationsIds, 10);
    let requests = [];
    idsChunks.forEach((idsArray) => {
      const q = query(
        firebase.concertInvitations(),
        where(documentId(), "in", idsArray)
      );
      const req = getDocs(q);
      requests.push(req);
    });
    const snapshotsChunks = await Promise.all(requests);
    const docsChunks = snapshotsChunks.map((snap) =>
      parseFirebaseDoc(snap.docs)
    );
    const users = docsChunks
      .flat()
      .map((doc) => parseConcertInvitationObjDates(doc));

    return users;
  };

  const createConcertProgram = async (createObj) => {
    if (!createObj) return;

    const res = await addDoc(firebase.concertPrograms(), createObj);
    return res;
  };

  const getConcertProgramsByConcertId = async (concertId) => {
    const collRef = firebase.concertPrograms();
    const queryParams = [collRef];
    queryParams.push(where("concertId", "==", concertId));

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseConcertProgramObjDates(doc));
    return withDates;
  };

  const createConcertSignup = async (createObj) => {
    if (!createObj) return;

    const res = await addDoc(firebase.concertSignups(), createObj);
    return res;
  };

  const deleteConcertSignup = async (signupId) => {
    if (!signupId) return;

    await deleteDoc(firebase.concertSignup(signupId));
  };
  const deleteConcertProgram = async (programId) => {
    if (!programId) return;

    await deleteDoc(firebase.concertProgram(programId));
  };

  const getConcertProgramsBySignupId = async (signupId) => {
    const collRef = firebase.concertPrograms();
    const queryParams = [collRef];
    queryParams.push(where("signupId", "==", signupId));

    const q = query(...queryParams);
    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);
    const withDates = docs.map((doc) => parseConcertProgramObjDates(doc));
    return withDates;
  };

  const getProgramById = async (id) => {
    if (!id) return;

    const snap = await getDoc(firebase.concertProgram(id));
    const doc = { id: id, ...snap.data() };

    const parsedDates = parseConcertProgramObjDates(doc);
    return parsedDates;
  };

  const updateConcertProgram = async (id, updateObj) => {
    if (!id || !updateObj) {
      toast.error("missing info");
      return;
    }

    await updateDoc(firebase.concertProgram(id), updateObj);

    return;
  };

  const createConcertPaymentOption = async (createObj) => {
    if (!createObj) return;

    const res = await addDoc(firebase.concertPaymentOptions(), createObj);
    return res;
  };

  const getApplications = async (isArchived) => {
    const q = query(
      firebase.users(),
      where("role", "in", [
        UserRole.ADMIN_APPLICANT,
        UserRole.TEACHER_APPLICANT,
        UserRole.GUEST_STUDENT,
        UserRole.PARTNER_APPLICANT,
      ]),
      where("archived", "==", isArchived)
    );

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    return docs;
  };

  const getFeedbacks = async (isArchived) => {
    const q = query(firebase.feedbacks(), where("archived", "==", isArchived));

    const snap = await getDocs(q);
    const docs = parseFirebaseDoc(snap.docs);

    return docs;
  };

  const getFeedbackById = async (feedbackId) => {
    if (!feedbackId) return;

    const snap = await getDoc(firebase.feedback(feedbackId));
    const doc = { id: feedbackId, ...snap.data() };

    return doc;
  };

  const deleteFeedback = async (feedbackId) => {
    if (!feedbackId) return null;

    await deleteDoc(firebase.feedback(feedbackId));
    toast.success("Feedback deleted");
  };

  const toggleFeatureNotificationSeen = async (userId, notificationSeen) => {
    if (!userId) return null;

    await updateDoc(firebase.user(userId), {
      featureNotificationSeen: notificationSeen,
    });
  };

  const getFeatureNotifications = async () => {
    const snap = await getDocs(firebase.featureNotifications());
    const docs = parseFirebaseDoc(snap.docs);

    return docs;
  };

  return {
    getInstrumentById,
    getUserNotifications,
    readNotification,
    getSubmittedTeacherAbsences,
    getAbsencesByIds,
    getAbsences,
    getMakeupLessonsByIds,
    getPrivateLessonsByIds,
    getUsersByIds,
    getTeacherActivities,
    getTeachersActivities,
    getSchoolYears,
    getTeachersAvailableDays,
    getLocations,
    getTeachers,
    getStudents,
    getUsers,
    getPrivateLessonsByStudentsIds,
    getPrivateLessonsAbsences,
    getMakeUpLessonsByAbsencesIds,
    getPrivateLessons,
    updateUserAuth,
    updateUserInfo,
    getPrivateLessonsByTeacherId,
    updatePrivateLesson,
    createCalendarLabel,
    getUserLibraryItems,
    getFileMetadata,
    changeUserProfileImage,
    getSchoolPolicies,
    getSchoolPolicy,
    updateSchoolPolicy,
    addNewSchoolPolicy,
    getInstruments,
    getStudios,
    getPrograms,
    getParents,
    getStudentsByParentsEmails,
    getSchoolNotificationSettingsByType,
    getUserNotificationSettingsByType,
    updateNotificationSettingsDoc,
    createNotificationSettingsDoc,
    getFeedItems,
    updateFeedItem,
    addFeedItemMetadata,
    getFeedItemMetadata,
    getUserFeedItemsMetadata,
    deleteFeedItemMetadata,
    deleteFeedItemWithClearMetaOption,
    getUsersByRoles,
    permaDeleteUserAccount,
    updateTrialLesson,
    updateUserActivity,
    toggleFeatureNotificationSeen,
    getUsersByFullName,
    deleteTrialLessonAndActivity,
    getTeachersMakeUpOpenings,
    createPackagePrivateLessonAndActivity,
    getStudentMakeupLessons,
    getActivitiesByTeachersIds,
    getTeachersByIds,
    createPackageItemAndActivity,
    createAbsenceDoc,
    getStudioUsage,
    addStudioUsage,
    deleteStudioUsage,
    editRegularLessonAndActivityTimeline,
    updatePackageSetsAndActivity,
    deleteAppointmentAndActivity,
    updateMakeupLesson,
    getUserPaymentRates,
    createUserPaymentRates,
    addUserPayment,
    getUserPayments,
    getUserPaymentRateById,
    updateUserPayment,
    getAdminsTeam,
    getUserById,
    getUserActivityById,
    deleteUserPayment,
    getTeacherAbsences,
    deleteTeacherAbsence,
    getPrivateAndTrialStudents,
    getTeachersAndAdminTeam,
    getPaymentsWithinDateRange,
    getPaymentsDuringDatePeriod,
    getUserAuthInfo,
    disableTeacher,
    updateUserAvailableDay,
    getTeacherAvailableDays,
    deleteLibraryItemPermanently,
    createMakeupRequest,
    getMakeupRequests,
    updateMakeupRequest,
    getPendingMakeupRequests,
    getMakeupRequestsByAbsenceId,
    getMakeUpRequestsByLessonsIds,
    returnFromSummerBreak,
    deleteSummerBreakAndActivity,
    updateAbsence,
    getPerLessonAbsencesByLessonId,
    deleteLessonAbsencesAndMakeUps,
    permaDeletePrivateLesson,
    permaDeleteStudentUser,
    getPLLessonNotes,
    createGroupClass,
    getGroupClasses,
    getTeacherGroupClassDays,
    getTeachersGroupClassDays,
    createGroupClassPaymentOption,
    getPaymentOptionsByGroupClassId,
    getGroupClassById,
    createGroupClassPayment,
    createGroupClassEnrollment,
    getGroupClassEnrollments,
    getGroupClassesByTeachersIds,
    getGroupClassEnrollmentsByStudentsIds,
    getGroupClassesByIds,
    getGroupClassPayments,
    createConcert,
    getGroupClassPaymentOptionById,
    getGroupClassAbsences,
    getUpcomingConcerts,
    getConcertById,
    createConcertInvitation,
    getConcertInvitationsByUserId,
    getLocationById,
    updateConcertInvitation,
    getConcertSignupsByConcertId,
    getConcertSignupById,
    getConcertInvitationsByIds,
    createConcertProgram,
    getConcertProgramsByConcertId,
    createConcertSignup,
    deleteConcertSignup,
    deleteConcertProgram,
    getConcertProgramsBySignupId,
    getProgramById,
    updateConcertProgram,
    createConcertPaymentOption,
    getApplications,
    getFeedbacks,
    getFeedbackById,
    deleteFeedback,
    deleteMakeupRequest,
    getTeacherProgramById,
    getFeatureNotifications,
    getUserMakeUpOpenings,
  };
};

export default useFirebaseFns;
