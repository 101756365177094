import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { PrimaryButton } from "src/utils/shared/styled";
import { CustomModalBody } from "src/utils/styled";
import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";
import { deleteOptions } from "./constants";
import useLabelData from "./hooks/useLabelData";

export const CustomModal = styled(Modal)`
  min-width: 450px;
  max-width: 450px;
  & .modal-content {
    border: none;
    /* background: transparent; */
    border-radius: 30px;
  }
`;
export const CustomHeader = styled(ModalHeader)`
  height: 65px;
  padding-top: 0 !important;
`;

export const CustomBody = styled(ModalBody)`
  padding: 0 !important;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;

export const BodyContainer = styled.div`
  min-height: 300px;
  padding: 30px;
  padding-top: 30px;
`;

const RecurringLabelDeleteOptionsModal = ({
  modalData,
  closeEventDetailsModal,
  event,
  refreshData,
}) => {
  const labelData = useLabelData({
    event,
  });
  const {
    deleteOption,
    setDeleteOption,
    confirmDeleteRecurringLabel,
    loading,
  } = labelData;

  const handleConfirmDelete = async () => {
    await confirmDeleteRecurringLabel();
    refreshData();
    modalData.closeModal();
    closeEventDetailsModal();
  };

  return (
    <div>
      <CustomModal
        size="xs"
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
      >
        <CustomHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
        >
          <h2 className="fs-3 fw-bold">Delete Options</h2>
        </CustomHeader>
        <CustomModalBody>
          <div>
            <div className="mt-5">
              <div className="mb-3">
                <label>
                  <input
                    checked={deleteOption === deleteOptions.ONE_EVENT}
                    onChange={() => setDeleteOption(deleteOptions.ONE_EVENT)}
                    className="me-2"
                    type="radio"
                  />
                  Cancel This Event
                </label>
              </div>
              <div className="mb-3">
                <label>
                  <input
                    checked={deleteOption === deleteOptions.FUTURE_EVENTS}
                    onChange={() =>
                      setDeleteOption(deleteOptions.FUTURE_EVENTS)
                    }
                    className="me-2"
                    type="radio"
                  />
                  Cancel This Event and all next events
                </label>
              </div>
            </div>
            <div className="text-center mt-5">
              <PrimaryButtonWithLoading
                isLoading={loading}
                onClick={handleConfirmDelete}
              >
                Confirm
              </PrimaryButtonWithLoading>
            </div>
          </div>
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default RecurringLabelDeleteOptionsModal;
