import React, { useContext } from "react";
import _ from "lodash";
import {
  ActiveTabContext,
  AddItemModalContext,
  ItemsSearchContext,
  LibraryContext,
  ViewItemModalContext,
} from "../../common/libraryContext";
import {
  AttachmentsListContainer,
  AttachmentToolbarContainer,
  AttachmentToolbarItem,
  TextButton,
} from "../../../styled-components/teacherLibraryStyledComponents";
import TeacherLibrarySearchInput from "../../common/TeacherLibrarySearchInput";
import AttachmentsSingleItem from "./AttachmentsSingleItem";
import EmptyResults from "../../common/EmptyResults";
import SelectAllItems from "./SelectAllItems";
import AllActions from "./AllActions";
import { ADD_LABELS } from "../../common/constants";

const AttachmentsList = ({ user }) => {
  const { activeTab, isAdmin } = useContext(ActiveTabContext);
  const { open } = useContext(ViewItemModalContext);
  const { open: openAddItemModal } = useContext(AddItemModalContext);

  const { itemsSearchValue, setItemsSearchValue } =
    useContext(ItemsSearchContext);
  const { library, selected } = useContext(LibraryContext);
  const filteredList =
    itemsSearchValue === ""
      ? library[activeTab]
      : _.filter(library[activeTab], ({ title }) =>
          title.toLowerCase().includes(itemsSearchValue.toLowerCase())
        );
  const showItem = (ID) => {
    const item = library[activeTab].find(({ id }) => id === ID);
    open(item);
  };
  const renderEmptyComponent = () => {
    return (
      filteredList.length === 0 && (
        <div className={"py-5"}>
          {itemsSearchValue !== "" ? (
            <EmptyResults isSearch={true} />
          ) : (
            <div
              className={
                " d-flex flex-column align-items-center justify-content-center"
              }
            >
              <div className="mb-5">
                <EmptyResults />
              </div>
              <TextButton
                className="mb-2 text-start btn d-inline-block"
                onClick={openAddItemModal}
              >
                <i className="fas fa-plus mx-2" />
                <span>Add new {ADD_LABELS[activeTab]}</span>
              </TextButton>
            </div>
          )}
        </div>
      )
    );
  };
  const isAllSelected =
    selected.length === filteredList.length && filteredList.length;
  return (
    <AttachmentsListContainer>
      <AttachmentToolbarContainer>
        <AttachmentToolbarItem>
          {itemsSearchValue === "" && (
            <SelectAllItems isAllSelected={isAllSelected} />
          )}
        </AttachmentToolbarItem>
        <AttachmentToolbarItem style={{ flexGrow: "0.2" }}>
          {isAllSelected ? (
            <AllActions />
          ) : (
            <TeacherLibrarySearchInput
              query={itemsSearchValue}
              setQuery={setItemsSearchValue}
            />
          )}
        </AttachmentToolbarItem>
      </AttachmentToolbarContainer>
      {filteredList.map((item) => (
        <AttachmentsSingleItem
          key={item.id}
          item={item}
          showItem={showItem}
          checked={selected.includes(item.id)}
          showActions={!isAllSelected}
          user={user}
        />
      ))}
      {renderEmptyComponent()}
    </AttachmentsListContainer>
  );
};
export default AttachmentsList;
