import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const sendEmailConfirmation = async (user) => {
  try {
    await emailjs.send(
      "service_m7j255t",
      "template_wqufdj9",
      {
        to_name: user?.name,
        to_email: user?.email,
      },
      "l6-vMvPNxhUUxuyxh"
    );
  } catch (error) {
    console.error(error);
    toast.error("Error occurred");
  }
};

export default sendEmailConfirmation;
