import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { useApplications } from "../hooks";
import { setTableRowClassName } from "../utils";
import { formatTimestamp } from "src/utils/helpers";

import RouteContent from "src/components/common/RouteContent";
import { PrimaryButton } from "src/utils/shared/styled";
import { StyledTable } from "../styled";
import { ButtonsWrapper } from "../../Guest/GuestProfile/GuestProfileSecondPage/styled";
import archiveTableColumns from "./archiveTableColumns";
import DeleteModal from "../components/DeleteModal";
import ApplicantDetailsModal from "../components/ApplicantDetailsModal";

const ApplicationsArchive = () => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [applicantToAction, setApplicantToAction] = useState(null);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { getApplications } = useFirebaseFns();
  const navigate = useNavigate();
  const { archiveApplicationToggle } = useApplications();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getApplications(true);

      setApplications(response);
      setIsLoading(false);
    })();
  }, []);

  const handleDeleteModalOpen = (applicantId) => {
    setApplicantToAction(applicantId);
    setDeleteModalOpen(true);
  };

  const handleDetailsModalOpen = (applicantId) => {
    setApplicantToAction(applicantId);
    setDetailsModalOpen(true);
  };

  const handleApplicationDelete = (applicantId) => {
    setApplications((prevState) =>
      prevState?.filter((application) => application?.id !== applicantId)
    );
    setDeleteModalOpen(false);
  };

  const handleApplicationUnarchive = async (applicantId) => {
    setIsLoading(true);
    await archiveApplicationToggle(applicantId, false);
    setApplications((prevState) =>
      prevState?.filter((application) => application?.id !== applicantId)
    );
    setIsLoading(false);
  };

  const columns = archiveTableColumns({
    onNameClick: handleDetailsModalOpen,
    onDeleteClick: handleDeleteModalOpen,
    onUnarchiveClick: handleApplicationUnarchive,
  });

  return (
    <RouteContent title="Applications archive">
      <StyledTable
        loading={isLoading}
        columns={columns}
        dataSource={applications
          ?.map((application) => {
            const date = formatTimestamp(application?.createdAt);

            return {
              request_date: date,
              name: application?.name || "N/A",
              type: application?.role?.replace(/_/g, " "),
              instrument: application?.instruments?.join(", ") || "N/A",
              key: application?.id,
              withdrawal: application?.withdrawal?.requested,
              status: application?.status,
            };
          })
          .filter(Boolean)}
        rowClassName={(record) => setTableRowClassName(record?.status)}
        bordered
      />
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/applications")}
        >
          Back to applications
        </PrimaryButton>
      </ButtonsWrapper>
      <DeleteModal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen((prevState) => !prevState)}
        applicantId={applicantToAction}
        onConfirm={() => handleApplicationDelete(applicantToAction)}
      />
      <ApplicantDetailsModal
        isOpen={detailsModalOpen}
        toggle={() => setDetailsModalOpen((prevState) => !prevState)}
        applicantId={applicantToAction}
      />
    </RouteContent>
  );
};

export default ApplicationsArchive;
