import React, { useState, useEffect } from "react";

import {
  CustomInput,
  PrimaryButton,
  StyledCopyIcon,
} from "src/utils/shared/styled";

import useFirebaseFns from "src/hooks/useFirebaseFns";
import { toast } from "react-toastify";
import useModal from "src/hooks/useModal";
import styled from "styled-components";
import ChangeAuthInfoModal from "../../../ChangeAuthInfoModal";
import ParentActions from "./ParentActions";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import { haveSuperAdminPermission, injectUserStore } from "src/utils/helpers";

const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContactInfo = ({ closeModal, parentUser, UserStore }) => {
  const user = UserStore.user;
  const { updateUserInfo } = useFirebaseFns();
  const authInfoChangeModalData = useModal();

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const canAccessParentActions = haveSuperAdminPermission(
    user,
    adminsExtraFunctions.manageParentActions
  );

  const saveUserData = async () => {
    const { id, fullName } = userData;
    toast.info("Saving user data...");
    setIsLoading(true);
    await updateUserInfo(id, {
      fullName: fullName ?? "",
    });
    toast.success("Saved.");
  };

  useEffect(() => {
    if (parentUser) setUserData({ ...parentUser });
  }, []);

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between">
        <h4 className={"fw-bold"}>Parent Profile</h4>
        {canAccessParentActions && (
          <ParentActions
            parentId={parentUser?.id}
            refreshData={() => window.location.reload()}
            closeParentInfoModal={closeModal}
          />
        )}
      </div>
      <FieldsContainer className="mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">Parent Name</p>
          <CustomInput
            type="text"
            className="w-100 form-control"
            name={"name"}
            id="name"
            placeholder="Name"
            value={userData.fullName}
            onChange={(e) => {
              setUserData((previous) => ({
                ...previous,
                fullName: e.target.value,
              }));
            }}
          />
        </div>
      </FieldsContainer>

      <FieldsContainer className=" mb-2">
        <div className="p-2 w-100">
          <p className="mb-2 fw-medium">
            Primary Email (For Login)
            <PrimaryButton
              onClick={authInfoChangeModalData.openModal}
              className="ms-2"
              padding="6px"
              width="120px"
            >
              Change
            </PrimaryButton>
          </p>
          <CustomInput
            type="email"
            className="w-100 form-control"
            name={"primaryEmail"}
            placeholder="Primary Email"
            value={userData.primaryEmail}
            disabled={true}
          />
        </div>
      </FieldsContainer>

      <div className="px-3 d-flex justify-content-end">
        <button
          onClick={saveUserData}
          disabled={isLoading}
          className="submitbutton h5 px-4 py-1"
        >
          Save
        </button>
      </div>

      {/* Modals */}
      <ChangeAuthInfoModal
        title="Change Login Email"
        modalData={authInfoChangeModalData}
        userId={parentUser?.id}
        onSuccessCallback={() => window.location.reload()}
      />
    </div>
  );
};

export default injectUserStore(ContactInfo);
