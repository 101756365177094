export const LessonsLengthEnum = {
  15:"15 Minutes",
  30: "30 Minutes",
  45: "45 Minutes",
  60: "60 Minutes",
  80: "80 Minutes",
  90: "90 Minutes",
  120: "120 Minutes"
};
export const TimeDate  = {
  AM: "AM",
  PM : "PM"
}
export const LessonStartTime = "8:00 AM";
export const LessonEndTime = "9:00 PM";
