import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const CurrentStudentsListContainer = styled.div`
  width: 100%;
  box-shadow: 0 2px 10px #0000000a;
  border-radius: 30px;
  padding: 20px 14px;
  max-height: 490px;
  overflow-y: auto;
`;
export const SearchInputContainer = styled.div`
  position: relative;
  /* width: 530px; */
  width: 100%;
  margin-left: auto;
  input {
    color: #151515;
    font-weight: 100;
    font-size: 1rem;

    &:focus + i {
      color: #681e46;
    }
  }

  i {
    color: #979797;
    right: 14px;
    top: 8px;
    font-size: 1rem;
  }

  /* @media (max-width: ${ScreenSize.XXL}px) {
    width: 320px;
  }
  @media (max-width: ${ScreenSize.LG}px) {
    width: 250px;
  } */
  @media (max-width: ${ScreenSize.SM}px) {
    width: 100%;
  }
`;
export const RowContainer = styled.div`
  margin-bottom: 12px;
  cursor: pointer;

  input[type="checkbox"] {
    width: 24px;
    height: 22px;
  }
`;
export const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    width: 26px;
    height: 24px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    accent-color: #681e46;
  }

  label {
    margin-left: 12px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #151515;
  }
`;
