import React from "react";
import AbsenceDetailsBody from "./AbsenceDetailsBody";
import { CustomModal, CustomModalBody, CustomModalHeader } from "./styled";

const AbsenceDetailsModal = ({
  modalData,
  absencesData,
  refreshData,
  openAffectedLessonsModal,
  readOnly = false,
}) => {
  console.log({ absence: absencesData.currentAbsence });
  return (
    <CustomModal
      size="lg"
      centered
      isOpen={modalData.isModalOpen}
      toggle={modalData.toggleModal}
    >
      <CustomModalHeader
        // close={<CloseIcon onClick={modalData.closeModal} />}
        className="pb-0 border-bottom-0"
        toggle={modalData.toggleModal}
      >
        <h2 className="fs-3 fw-bold">Absence Request</h2>
      </CustomModalHeader>
      <CustomModalBody>
        <AbsenceDetailsBody
          readOnly={readOnly}
          absencesData={absencesData}
          modalData={modalData}
          refreshData={refreshData}
          openAffectedLessonsModal={openAffectedLessonsModal}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default AbsenceDetailsModal;
