import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  isRegularPrivateLesson,
  privateLessonTypes,
} from "src/constants/eventsEnum";
import LessonTypeSelection from "./components/LessonTypeSelection";
import PackagePrivateLessonCreationBody from "./components/PackagePrivateLessonCreationBody";
import RegularPrivateLessonCreationBody from "./components/RegularPrivateLessonCreationBody";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "./styled";

const CreatePrivateLessonModal = ({
  modalData,
  studentId,
  providedInitialData = {
    teachers: undefined,
    instruments: undefined,
    locations: undefined,
    programs: undefined,
    teachersAvailableDays: undefined,
    teachersActivities: undefined,
  },
  headerTitle = "Private Lesson",

  // for when returning from summer break (We use some values like the instrument in this case)(We create another timeline for the return from school break + set breakEnd for the break)
  isReturnFromSummerBreak = false,
  returnFromSummerBreakData = {
    privateLessonId: "",
    summerBreakId: "",
  },
}) => {
  const [selectedLessonType, setSelectedLessonType] = useState(
    privateLessonTypes.REGULAR
  );

  if (!studentId) {
    toast.error("Can't open private lesson creation modal (No student Id)");
    return <div></div>;
  }

  const showRegularPLCreationBody =
    isReturnFromSummerBreak || isRegularPrivateLesson(selectedLessonType);

  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
      >
        <CustomHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
        >
          <h2 className="fw-bold">{headerTitle}</h2>
        </CustomHeader>
        <CustomBody>
          {/* Selects the lesson Type */}
          {!isReturnFromSummerBreak && (
            <div className="mb-4">
              <LessonTypeSelection
                selectedLessonType={selectedLessonType}
                setSelectedLessonType={setSelectedLessonType}
              />
            </div>
          )}

          {/* The modal body based on the selectedLessonType */}
          {showRegularPLCreationBody ? (
            <RegularPrivateLessonCreationBody
              modalData={modalData}
              studentId={studentId}
              providedInitialData={providedInitialData}
              isReturnFromSummerBreak={isReturnFromSummerBreak}
              returnFromSummerBreakData={returnFromSummerBreakData}
            />
          ) : (
            <PackagePrivateLessonCreationBody
              modalData={modalData}
              studentId={studentId}
              providedInitialData={providedInitialData}
            />
          )}
        </CustomBody>
      </CustomModal>
    </div>
  );
};

export default CreatePrivateLessonModal;
