import React, { useContext } from "react";
import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalButton,
  LibraryModalHeader,
} from "../../../common/styled-components/teacherLibraryStyledComponents";
import { ShareItemModalContext } from "../../../common/Library/common/libraryContext";
import LibraryTeachersList from "../../../common/Library/tab-content/teachers-list/LibraryTeachersList";

const ShareItemModal = () => {
  const { isOpen, closeShareModal, submitShare } = useContext(
    ShareItemModalContext
  );

  return (
    <LibraryModal className={"p-0"} size={"lg"} centered isOpen={isOpen}>
      <LibraryModalHeader
        close={
          <button className="close" onClick={closeShareModal}>
            <i className="fas fa-times" />
          </button>
        }
      >
        Share
      </LibraryModalHeader>
      <LibraryModalBody className={"p-0"}>
        <h3 className="mb-4 text-black">Select Teacher</h3>
        <LibraryTeachersList fullWidth={true} />
        <LibraryModalButton
          type={"submit"}
          onClick={submitShare}
          className={
            "d-flex font-weight-bold mx-auto  text-white align-items-center justify-content-center"
          }
        >
          <div className={"d-flex align-items-center justify-content-center"}>
            <span>Share</span>
            <i className="ms-2 fas fa-share" />
          </div>
        </LibraryModalButton>
      </LibraryModalBody>
    </LibraryModal>
  );
};
export default ShareItemModal;
