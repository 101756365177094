import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
} from "reactstrap";

const CustomDropdown = ({
  dropdownToggleComponent: DropdownToggleComponent,
  dropdownContent: DropdownContent,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onMouseEnter = () => {
    setIsOpen(true);
  };

  const onMouseLeave = () => {
    setIsOpen(false);
  };

  const dropdownToggleMarkup = () => {
    return <DropdownToggleComponent />;
  };

  return (
    <Dropdown
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isOpen={isOpen}
      toggle={() => setIsOpen((previous) => !previous)}
    >
      <DropdownToggle tag="span">{dropdownToggleMarkup()}</DropdownToggle>
      <DropdownMenu>
        <DropdownContent />
      </DropdownMenu>
    </Dropdown>
  );
};

export default CustomDropdown;
