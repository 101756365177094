import React, { useContext } from "react";
import { useSidebarContext } from "src/contexts/SidebarContext";
import { Link, useNavigate } from "react-router-dom";
import {
  hasAdminCredentials,
  isAdmin,
  isParent,
  isStudent,
  isSuperAdmin,
} from "../../../../../constants/UserRoleEnum";
import styled from "styled-components";
import { adminMenuItems, menuItems, superAdminMenuItems } from "../constants";

import logo from "../../../../../assets/images/logo.png";
import logoMini from "../../../../../assets/images/logo_mini.png";
import { ArrowLeftShort } from "@styled-icons/bootstrap/ArrowLeftShort";
import { ArrowRightShort } from "@styled-icons/bootstrap/ArrowRightShort";
import {
  LogoContainer,
  SiderbarMainContainer,
  StyledLogo,
  StyledMenu,
  TeacherAbsenceNotification,
  ToggleArrowIconContainer,
} from "../styled";
import { FirebaseContext } from "../../../../Firebase";

const LeftArrow = styled(ArrowLeftShort)``;
const RightArrow = styled(ArrowRightShort)``;

const DesktopSidebar = ({
  sidebarContentType,
  location,
  user,
  submittedTAsLength,
  pendingMakeupRequestsLength,
  pendingConcertInvitationsLength,
  applicationsLength,
  feedbacksLength,
}) => {
  const { isSidebarOpen, toggleSideBar } = useSidebarContext();
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();

  const handleToggleClick = () => {
    toggleSideBar();
  };

  const handleMenuItemClick = ({ item }) => {
    if (item.props.link !== "/") {
      navigate(item.props.link);
    } else {
      firebase.signOut();
      navigate("/");
    }
  };

  const isStudentOrParent = isStudent(user.role) || isParent(user.role);
  const adminAndSuperAdminItems = isAdmin(user.role)
    ? adminMenuItems
    : superAdminMenuItems;

  const addNotificationBadge = (label, count) => (
    <>
      {label}
      {count > 0 && (
        <TeacherAbsenceNotification className="ms-1">
          {count}
        </TeacherAbsenceNotification>
      )}
    </>
  );

  return (
    <SiderbarMainContainer className={!isSidebarOpen ? "is-closed" : ""}>
      <ToggleArrowIconContainer onClick={handleToggleClick}>
        {isSidebarOpen ? <LeftArrow /> : <RightArrow />}
      </ToggleArrowIconContainer>
      <LogoContainer className="align-self-center ">
        <Link to="/">
          <StyledLogo
            src={!isSidebarOpen ? logoMini : logo}
            alt="Logo"
            height={isSidebarOpen ? "auto" : "50px"}
          />
        </Link>
      </LogoContainer>
      {!isAdmin(user.role) && !isSuperAdmin(user.role) ? (
        <ul className="list-unstyled components" style={{ overflow: "auto" }}>
          {menuItems(user, sidebarContentType).map((menuItem) => {
            const isAdminTeacherAbsencePage =
              menuItem.link === "/dashboard/teacher-absence";
            const isMakeupRequestsPage =
              menuItem.link === "/dashboard/makeup-requests";
            const isUpcomingConcertsPage =
              menuItem.link === "/dashboard/upcoming-concerts";
            const isApplicationsPage =
              menuItem.link === "/dashboard/applications";
            const isFeedbacksPage = menuItem.link === "/dashboard/feedbacks";

            // shows a notification for the Teacher Absence menu item if there is at least one "SUBMITTED" teacher absence
            const showTeacherAbsenceNotification =
              isAdminTeacherAbsencePage &&
              hasAdminCredentials(user?.role) &&
              submittedTAsLength;
            const showMakeupRequestsNotification =
              isMakeupRequestsPage &&
              hasAdminCredentials(user?.role) &&
              pendingMakeupRequestsLength;

            const showPendingConcertInvitations =
              isUpcomingConcertsPage &&
              isStudentOrParent &&
              pendingConcertInvitationsLength;

            const showApplicationsNotification =
              isApplicationsPage &&
              hasAdminCredentials(user?.role) &&
              applicationsLength;

            const showFeedbackNotification =
              isFeedbacksPage &&
              hasAdminCredentials(user?.role) &&
              feedbacksLength;

            const notificationMarkup =
              showTeacherAbsenceNotification ||
              showMakeupRequestsNotification ||
              showPendingConcertInvitations ||
              showApplicationsNotification ||
              showFeedbackNotification ? (
                <TeacherAbsenceNotification className="ms-1">
                  {showTeacherAbsenceNotification
                    ? submittedTAsLength
                    : showMakeupRequestsNotification
                    ? pendingMakeupRequestsLength
                    : showPendingConcertInvitations
                    ? pendingConcertInvitationsLength
                    : showApplicationsNotification
                    ? applicationsLength
                    : showFeedbackNotification
                    ? feedbacksLength
                    : ""}
                </TeacherAbsenceNotification>
              ) : null;

            // displayed if the sidebar is open
            const isOpenMarkup = (
              <>
                {menuItem.text}
                {notificationMarkup}
              </>
            );

            return (
              <li
                key={menuItem.text}
                className={menuItem.link === location.pathname ? "active" : ""}
              >
                <Link to={menuItem.link}>
                  {menuItem.Styledicon ? (
                    <menuItem.Styledicon />
                  ) : (
                    <i className={`fas fa-${menuItem.icon}`} />
                  )}
                  {isSidebarOpen ? isOpenMarkup : null}
                </Link>
              </li>
            );
          })}
          <li>
            <Link to={"/"} href="#" onClick={firebase.signOut}>
              <i className="fas fa-power-off" />
              <span>Sign Out</span>
            </Link>
          </li>
        </ul>
      ) : (
        <StyledMenu
          mode="inline"
          items={adminAndSuperAdminItems.map((menuItem) => {
            const updatedChildren = menuItem.children?.map((child) => {
              let notificationCount = 0;
              if (child.link === "/dashboard/teacher-absence") {
                notificationCount = submittedTAsLength;
              } else if (child.link === "/dashboard/makeup-requests") {
                notificationCount = pendingMakeupRequestsLength;
              }

              return {
                ...child,
                label: addNotificationBadge(child.label, notificationCount),
                className: child.link === location.pathname ? "active" : "",
              };
            });

            let notificationCount = 0;

            if (menuItem.link === "/dashboard/applications") {
              notificationCount = applicationsLength;
            } else if (menuItem.link === "/dashboard/feedbacks") {
              notificationCount = feedbacksLength;
            }

            return {
              ...menuItem,
              label: addNotificationBadge(menuItem.label, notificationCount),
              children: updatedChildren,
              className: menuItem.link === location.pathname ? "active" : "",
            };
          })}
          triggerSubMenuAction="click"
          selectable={false}
          onClick={handleMenuItemClick}
        />
      )}
    </SiderbarMainContainer>
  );
};

export default DesktopSidebar;
