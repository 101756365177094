import { useEffect, useState } from "react";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  isAdminApplicant,
  isGuestStudent,
  isPartnerApplicant,
  isTeacherApplicant,
} from "src/constants/UserRoleEnum";

import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import StudentApplicantInfo from "./StudentApplicantInfo";
import TeacherApplicantInfo from "./TeacherApplicantInfo";
import AdminApplicantInfo from "./AdminApplicantInfo";
import { StyledActionButton } from "../../styled";
import defaultProfile from "src/assets/images/default-profile.jpg";
import SendEmailModal from "../SendEmailModal";
import {
  ActionButtonsWrapper,
  InfoItem,
  InfoList,
  InfoWrapper,
  WithdrawnBadge,
} from "./styled";
import { Divider } from "antd";
import DefaultApplicantInfo from "./DefaultApplicantInfo";
import ApproveModal from "../ApproveModal";
import DeclineModal from "../DeclineModal";
import PartnerApplicantInfo from "./PartnerApplicantInfo";

const ApplicantDetailsModal = ({
  isOpen,
  toggle,
  applicantId,
  onArchive,
  onStatusUpdate,
}) => {
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);

  const [applicant, setApplicant] = useState(null);
  const { getUserById } = useFirebaseFns();

  useEffect(() => {
    (async () => {
      const response = await getUserById(applicantId);

      setApplicant(response);
    })();
  }, [applicantId]);

  const handleApproveModalConfirm = (archive, status) => {
    if (archive) onArchive(archive);
    onStatusUpdate(status);
    setApproveModalOpen(false);
  };

  const handleDeclineModalConfirm = (archive, status) => {
    if (archive) onArchive(archive);
    onStatusUpdate(status);
    setDeclineModalOpen(false);
  };

  return (
    <>
      <StyledModal isOpen={isOpen} centered toggle={toggle} size="lg">
        <StyledModalHeader toggle={toggle}>
          {`${applicant?.name || "Unnamed applicant"}'s information`}
        </StyledModalHeader>
        <ModalBody>
          <InfoWrapper>
            <img src={applicant?.imageUrl || defaultProfile} alt="applicant" />
            {applicant?.withdrawal?.requested && (
              <WithdrawnBadge>Withdrawn</WithdrawnBadge>
            )}
            <InfoList>
              <DefaultApplicantInfo applicant={applicant} />
              <InfoItem>
                <Divider />
              </InfoItem>
              {isGuestStudent(applicant?.role) && (
                <StudentApplicantInfo applicant={applicant} />
              )}
              {isTeacherApplicant(applicant?.role) && (
                <TeacherApplicantInfo applicant={applicant} />
              )}
              {isAdminApplicant(applicant?.role) && (
                <AdminApplicantInfo applicant={applicant} />
              )}
              {isPartnerApplicant(applicant?.role) && (
                <PartnerApplicantInfo applicant={applicant} />
              )}
            </InfoList>
            {!applicant?.archived && (
              <ActionButtonsWrapper>
                <StyledActionButton
                  actiontype="approve"
                  size="large"
                  onClick={() => setApproveModalOpen(true)}
                >
                  Approve
                </StyledActionButton>
                <StyledActionButton
                  actiontype="decline"
                  size="large"
                  onClick={() => setDeclineModalOpen(true)}
                >
                  Decline
                </StyledActionButton>
                <StyledActionButton
                  onClick={() => setSendEmailModalOpen(true)}
                  size="large"
                >
                  Send email
                </StyledActionButton>
              </ActionButtonsWrapper>
            )}
          </InfoWrapper>
        </ModalBody>
      </StyledModal>
      {!applicant?.archived && (
        <>
          <SendEmailModal
            isOpen={sendEmailModalOpen}
            toggle={() => setSendEmailModalOpen((prevState) => !prevState)}
            applicant={applicant}
            onConfirm={() => setSendEmailModalOpen(false)}
          />
          <ApproveModal
            isOpen={approveModalOpen}
            toggle={() => setApproveModalOpen((prevState) => !prevState)}
            applicant={applicant}
            onConfirm={(archive, status) =>
              handleApproveModalConfirm(archive, status)
            }
          />
          <DeclineModal
            isOpen={declineModalOpen}
            toggle={() => setDeclineModalOpen((prevState) => !prevState)}
            applicant={applicant}
            onConfirm={(archive, status) =>
              handleDeclineModalConfirm(archive, status)
            }
          />
        </>
      )}
    </>
  );
};

export default ApplicantDetailsModal;
