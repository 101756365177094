import React, { useEffect, useMemo, useState } from "react";

import useFirebaseFns from "./useFirebaseFns";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";

import moment from "moment";
import { toast } from "react-toastify";
import {
  getCurrentSchoolYear,
  getMakeupPeriodInRequestedDate,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
  isSameDayOrPassedDeadline,
  updatedMomentNow,
} from "src/utils/helpers";

import { eventsMap } from "src/constants/eventsEnum";
import { filterTeachersByInstrument } from "../helperFns";
import { logActionTypes, logTypes } from "src/constants/logTypes";
import { hasAdminCredentials, UserRole } from "src/constants/UserRoleEnum";
import { makeupViews } from "../constants";
import { schoolPolicies } from "src/constants/schoolPolicies";
import useTeachersAvailabilities from "src/hooks/useTeachersAvailabilities/useTeachersAvailabilities";
import {
  isPaidMakeupTypeMakeupLesson,
  makeupLessonTypes,
} from "src/constants/makeupLessonsEnum";
import useFirebaseHelpers from "src/hooks/useFirebaseHelpers";
import { makeupLimitAbsenceTypes } from "src/constants/absenceTypes";
import { isApprovedMakeupRequest } from "src/constants/makeupRequestEnum";

const MINS_IN_TWO_WEEK = 20160;
const twoWeeksFromNowDate = updatedMomentNow().add(14, "day").toDate();
const DEFAULT_PAID_MAKEUP_AMOUNT = 15;

const useMakeupData = ({
  lesson,
  lessonInfoOnAbsenceDate,
  requestedDate,
  currentAbsence,
  user,
  shortenTimeSlots,
}) => {
  const {
    createMakeupLessonDoc,
    addMakeupUserActivity,
    getStudentMakeupLessons,
  } = useFirebaseFns();
  const {
    getSchoolPolicy,
    getTeachers,
    getSchoolYears,
    getMakeupRequestsByAbsenceId,
  } = useGlobalFirebaseFns();
  const { hasStudentReachedMaxMakeUps } = useFirebaseHelpers();

  const absenceDate = currentAbsence.date || currentAbsence.startDate;

  const { locationId, duration: lessonDuration } =
    lessonInfoOnAbsenceDate || {};
  const { instrumentId } = lesson;

  // for the navigation tabs
  const [currentView, setCurrentView] = useState(makeupViews.DATE);
  // selected teacher from the select field (for the teacher view only)
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  // state for the policy checkbox
  // const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  // const handlePolicyAcceptanceChange = () => {
  //   setIsPolicyAccepted((oldVal) => !oldVal);
  // };
  //state for when the student is choosing whether the lsn is virtual or not (Based on whether the availability is marked as "isVirtualOnly" or not)
  const [isVirtualLesson, setIsVirtualLesson] = useState(false);
  const [makeupLessonType, setMakeupLessonType] = useState(
    makeupLessonTypes.MAKEUP
  );
  const [reachedMaxMakeups, setReachedMaxMakeups] = useState(false);

  const handleIsVirtualLessonChange = (val) => {
    setIsVirtualLesson(val);
  };
  const handleMakeupLessonTypeChange = (val) => {
    setMakeupLessonType(val);
  };

  const [initialData, setInitialData] = useState({
    teachers: [],
    studentMakeupLessons: [],
    makeupPolicy: null,
    currentSchoolYearInLocation: null,
    makeupPeriodDeadline: null,
    maxDateValue: new Date(),
    latestMakeupRequest: null,
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);

  const [selectedMakeupLesson, setSelectedMakeupLesson] = useState();
  const handleSelectMakeupLesson = (val) => {
    setSelectedMakeupLesson(val);
  };
  // state for when creating the makeup lesson
  const [loadingCreatingMakeup, setLoadingCreatingMakeup] = useState(false);

  const groupByObj =
    currentView === makeupViews.DATE
      ? { date: { requestedDate } }
      : {
          teacher: {
            maxDateValue: twoWeeksFromNowDate,
            teacherId: selectedTeacherId,
          },
        };
  // gets the teachers available slots
  const {
    selectedTeacherWithAvailableTimeSlots,
    teachersWithAvailableTimeSlots,
  } = useTeachersAvailabilities({
    instrumentId,
    lessonDuration,
    locationId,
    user,
    groupBy: groupByObj,
    shortenTimeSlots,
    includeMakeupOpeningsAvailabilities: true,
  });

  const handleSelectedTeacherChange = (teacherId) => {
    setSelectedTeacherId(teacherId);
  };
  const handleCurrentViewChange = (newView) => {
    setCurrentView(newView);
    handleSelectMakeupLesson(null);
  };

  const createMakeupLesson = async () => {
    if (!selectedMakeupLesson) {
      toast.warn("Please select a time slot and accept the school policy");
      return { success: false };
    }
    const { date, location, teacher, scheduleType } = selectedMakeupLesson;

    const isPassedDateLimit = !!initialData.maxDateValue
      ? moment(date).isAfter(initialData.maxDateValue)
      : false;

    if (isPassedDateLimit) {
      toast.warn(
        "Please select a date within the date limitations (2 weeks from now or before the make up deadline)"
      );
      return { success: false };
    }

    const passedDailyDeadline = isSameDayOrPassedDeadline(
      updatedMomentNow(),
      date
    );
    const isAdmin = hasAdminCredentials(user.role);
    if (!isAdmin && passedDailyDeadline) {
      toast.warn("Can't request a Make Up Lesson on the same day");
      return { success: false };
    }

    setLoadingCreatingMakeup(true);

    const endDate = moment(date)
      .add(parseInt(lessonDuration), "minutes")
      .toDate();

    const isPaidMakeup = isPaidMakeupTypeMakeupLesson(makeupLessonType);

    /* -- Creating The Makeup Lesson -- */
    // the makeup lesson document structure
    const makeupLessonObj = {
      createdAt: new Date(),
      type: makeupLessonType,
      ...(isPaidMakeup && {
        hasPaid: true,
        totalPaid: DEFAULT_PAID_MAKEUP_AMOUNT,
      }),
      forLessonType: eventsMap.privateLesson.code,
      forLessonId: lesson.id,
      forAbsenceId: currentAbsence.id,
      date: {
        startDate: date,
        endDate,
        lessonLength: parseInt(lessonDuration),
      },
      instrumentId: lesson.instrumentId,
      locationId: location.id,
      studentId: lesson.studentId,
      teacherId: teacher.id,
      isVirtual: isVirtualLesson,
      requestedBy: user.uid,
    };
    const makeupLessonResponse = await createMakeupLessonDoc(makeupLessonObj);

    // if the makeupLesson is created successfully, create the activity
    if (makeupLessonResponse?.id) {
      /* -- Creating The User Activity -- */
      // the user activity document structure
      const teacherUserActivityObj = {
        createdAt: new Date(),
        start_time: date,
        end_time: endDate,
        location: location.id,
        type: eventsMap.makeupLesson.code,
        userId: teacher.id,
        scheduleType,
        userRole: UserRole.TEACHER,
        isActive: true,
        isVirtual: isVirtualLesson,
      };
      await addMakeupUserActivity(
        makeupLessonResponse?.id,
        teacherUserActivityObj
      );

      toast.success("Makeup Lesson Created Successfully");
      setLoadingCreatingMakeup(false);
    }
    return { success: true };
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingInitialData(true);

        const maxMakeupsReq = makeupLimitAbsenceTypes.includes(
          currentAbsence.absenceType
        )
          ? hasStudentReachedMaxMakeUps(
              lesson.studentId,
              lesson?.id,
              absenceDate
            )
          : false;
        const requests = [
          getTeachers(),
          getStudentMakeupLessons(lesson.studentId),
          getSchoolPolicy(schoolPolicies.makeupPolicy.key),
          getSchoolYears(),
          maxMakeupsReq,
          getMakeupRequestsByAbsenceId(currentAbsence.id),
        ];

        const [
          teachers,
          studentMakeupLessons,
          makeupPolicy,
          schoolYears,
          maxMakeupsRes,
          makeupRequests,
        ] = await Promise.all(requests);

        const teachersFilteredByInstrument = filterTeachersByInstrument(
          teachers,
          instrumentId
        );

        const currentSchoolYearInLocation = getCurrentSchoolYear(
          schoolYears,
          locationId
        );
        const currentMakeupPeriod = getMakeupPeriodInRequestedDate(
          absenceDate,
          currentSchoolYearInLocation
        );
        const makeupPeriodDeadline = currentMakeupPeriod?.deadline?.toDate
          ? currentMakeupPeriod.deadline.toDate()
          : undefined;

        const twoWeeksFromNowDate = updatedMomentNow().add(14, "day").toDate();

        const isAdmin = hasAdminCredentials(user.role);
        let maxDateValue = makeupPeriodDeadline
          ? getTimeDiffInMins(updatedMomentNow(), makeupPeriodDeadline) >
            MINS_IN_TWO_WEEK
            ? twoWeeksFromNowDate
            : makeupPeriodDeadline
          : twoWeeksFromNowDate;

        // if the user is an admin, ignore the max date so we can choose any date
        if (isAdmin) {
          maxDateValue = null;
        }

        const latestMakeupRequest = makeupRequests[0];

        setInitialData((oldVal) => ({
          ...oldVal,
          teachers: teachersFilteredByInstrument,
          studentMakeupLessons,
          makeupPolicy,
          currentSchoolYearInLocation,
          makeupPeriodDeadline,
          maxDateValue: maxDateValue,
          latestMakeupRequest: latestMakeupRequest,
        }));

        setReachedMaxMakeups(maxMakeupsRes?.reachedMaxMakeups || false);
        if (isApprovedMakeupRequest(latestMakeupRequest?.status)) {
          setMakeupLessonType(makeupLessonTypes.PAID_MAKEUP);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchData();
  }, [instrumentId]);

  useEffect(() => {
    if (!selectedMakeupLesson) {
      handleIsVirtualLessonChange(false);
      return;
    }

    if (selectedMakeupLesson?.isVirtualOnly) {
      handleIsVirtualLessonChange(true);
    }
  }, [selectedMakeupLesson]);

  // reset the selected makeup if request date changes
  useEffect(() => {
    setSelectedMakeupLesson(null);
  }, [requestedDate]);

  const dateViewData = {
    teachersWithAvailableTimeSlots,
  };
  const teachersViewData = {
    selectedTeacherId,
    handleSelectedTeacherChange,
    selectedTeacherWithAvailableTimeSlots,
  };

  return {
    // isPolicyAccepted,
    // handlePolicyAcceptanceChange,
    initialData,
    loadingInitialData,
    selectedMakeupLesson,
    handleSelectMakeupLesson,
    createMakeupLesson,
    loadingCreatingMakeup,
    currentView,
    handleCurrentViewChange,
    dateViewData,
    teachersViewData,
    isVirtualLesson,
    handleIsVirtualLessonChange,
    makeupLessonType,
    handleMakeupLessonTypeChange,
    reachedMaxMakeups,
  };
};

export default useMakeupData;
