import styled from "styled-components";
import ReactPlayer from "react-player/lazy";

const PlayerWrapper = styled.div`
  width: 100%;
  height: 260px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 16px;
  }

  @media screen and (min-width: 992px) {
    height: auto;
  }

  @media screen and (min-width: 420px) {
    margin-top: 32px;
  }
`;

const StyledPlayer = styled(ReactPlayer)`
  width: 100% !important;
  height: 100% !important;
`;

export { PlayerWrapper, StyledPlayer };
