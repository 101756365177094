import React from "react";
import RouteContent from "../../common/RouteContent";
import StudentAttendanceList from "./StudentAttendanceList";
const StudentAttendanceListRoute = ({ user }) => {
  return (
    <RouteContent title={"Attendance List"}>
      <StudentAttendanceList user={user} />
    </RouteContent>
  );
};
export default StudentAttendanceListRoute;
