import { Close } from "styled-icons/evaicons-solid";
import styled from "styled-components";

export const LatestItemContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
  background-color: #ecf4ee;
  min-height: 50px;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
