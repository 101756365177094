import {
  emailValidationSchema,
  passwordConfirmValidationSchema,
  passwordValidationSchema,
} from "src/utils/validations";
import * as yup from "yup";

const signInSchema = yup.object().shape({
  email: emailValidationSchema,
  password: passwordValidationSchema,
});

export default signInSchema;
