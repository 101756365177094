export const UserRole = {
  TEACHER: "Teacher",
  ADMIN: "Admin",
  PARENT: "Parent",
  STUDENT: "Student",
  SUPER_ADMIN: "Super Admin",
  GROUP_CLASS_STUDENT: "Group_Class_Student",
  GUEST_STUDENT: "Guest_Student",
  TEACHER_APPLICANT: "Teacher_Applicant",
  ADMIN_APPLICANT: "Admin_Applicant",
  PARTNER_APPLICANT: "Partner_Applicant",
};
export const newsUserRoles = {
  ...UserRole,
  LANDING: "landing",
};
export const adminCredentialsRolesList = [UserRole.ADMIN, UserRole.SUPER_ADMIN];

export const hasAdminCredentials = (userRole) => {
  if (!userRole) return false;

  return adminCredentialsRolesList.includes(userRole);
};

export const isAdmin = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.ADMIN;
};
export const isSuperAdmin = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.SUPER_ADMIN;
};
export const isStudent = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.STUDENT;
};
export const isParent = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.PARENT;
};
export const isTeacher = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.TEACHER;
};
export const isGroupClassStudent = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.GROUP_CLASS_STUDENT;
};

export const isGuestStudent = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.GUEST_STUDENT;
};

export const isTeacherApplicant = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.TEACHER_APPLICANT;
};

export const isAdminApplicant = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.ADMIN_APPLICANT;
};

export const isPartnerApplicant = (userRole) => {
  if (!userRole) return false;

  return userRole === UserRole.PARTNER_APPLICANT;
};

export const isGuest = (userRole) => {
  if (
    userRole === UserRole.GUEST_STUDENT ||
    userRole === UserRole.TEACHER_APPLICANT ||
    userRole === UserRole.ADMIN_APPLICANT ||
    userRole === UserRole.PARTNER_APPLICANT
  ) {
    return true;
  }

  return false;
};
