import React from "react";
import RouteContent from "src/components/common/RouteContent";
import useMakeupRequests from "./hooks/useMakeupRequests";
import { SubmitSpinner } from "src/components/common";
import { pageTabs } from "./constants";
import { SearchBar, SearchIcon } from "./styled";
import PendingMakeupRequestsTable from "./PendingMakeupRequestsTable";
import ResolvedMakeupRequestsTable from "./ResolvedMakeupRequestsTable";
import NavigationBar from "./NavigationBar";

const MakeupRequests = () => {
  const makeupReqsData = useMakeupRequests();
  const { currentView, setCurrentView, loadingInitialData, initialData } =
    makeupReqsData;

  const displayCurrentTabComponent = () => {
    if (loadingInitialData) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <SubmitSpinner
            style={{ width: "3rem", height: "3rem", color: "#681e46" }}
          />
        </div>
      );
    } else if (currentView === pageTabs.PENDING) {
      return <PendingMakeupRequestsTable makeupReqsData={makeupReqsData} />;
    } else {
      return <ResolvedMakeupRequestsTable makeupReqsData={makeupReqsData} />;
    }
  };

  return (
    <RouteContent title="Make Up Requests">
      <div className="p-3">
        <div className="mb-3 d-flex align-items-center">
          <NavigationBar
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3 mb-4">
          <SearchBar
            value={makeupReqsData.searchTerm}
            onChange={(e) =>
              makeupReqsData.handleSearchTermChange(e.target.value)
            }
            placeholder="Search by Student Full Name"
          />
          <SearchIcon />
        </div>

        <div>{displayCurrentTabComponent()}</div>
      </div>
    </RouteContent>
  );
};

export default MakeupRequests;
