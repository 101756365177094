import React from "react";
import * as yup from "yup";
import { compose } from "recompose";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { emailValidationSchema } from "../../utils/validations";
import { Button, StyledForm } from "../../utils/styled";
import { LabelInput } from "../common";
import { withFirebase } from "../Firebase";
import logo from "../../assets/images/logo.png";
import ErrorFormFocus from "../../utils/ErrorFormFocus";

const loginFormValuesValidationSchema = yup.object().shape({
  email: emailValidationSchema,
});

const Logo = styled.img`
  max-width: 172px;
`;

const PasswordReset = ({ firebase, history }) => (
  <div className="flex-grow-1 d-flex flex-row justify-content-center align-items-center">
    <Formik
      initialValues={{ email: "" }}
      validationSchema={loginFormValuesValidationSchema}
      onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
        setSubmitting(true);
        firebase
          .passwordReset(values.email)
          .then(() => {
            toast.success(
              "Check your email for instructions on how to reset your password"
            );
            history.push("/");
          })
          .catch((error) => {
            toast.error(error.message);
            setSubmitting(false);
          });
      }}
    >
      {({ errors, touched, isSubmitting, submitForm }) => (
        <StyledForm>
          <div className="d-flex justify-content-center flex-column align-items-center">
            <Link to="/">
              <Logo src={logo} alt="Logo" />
            </Link>
            <p className="text-center mt-2 mb-4 font-weight-bold">
              Reset your password
            </p>
            <LabelInput
              name="email"
              id="email-input"
              placeholder="123@example.com"
              type="email"
              error={errors.email}
              touched={touched.email}
              label="Email:"
              icon="fa-user"
              size={12}
              noValidate
            />
            <Button
              type="submit"
              className="btn my-3"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Reset password
            </Button>

            <Button
              type="submit"
              className="btn mt-5"
              disabled={isSubmitting}
              onClick={history.goBack}
            >
              Back
            </Button>
          </div>
          <ErrorFormFocus />
        </StyledForm>
      )}
    </Formik>
  </div>
);
        
export default compose(withFirebase)(PasswordReset);
