import React from "react";
import Select from "react-select";

const DropdownFilter = ({
  options = [],
  placeholder,
  width,
  value,
  setValue,
  isSearchable,
  isMulti,
}) => {
  let handleChange = (option) => {
    setValue(option);
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f7f7f7",
      color: "#151515",
      width: width || "320px",
    }),

    control: (provided) => ({
      ...provided,
      backgroundColor: "#681E460D",
      color: "#505050",
      borderRadius: 30,
      border: "2px solid #681E46 !important",
      outline: "none",
      width: width || "320px",
    }),
  };
  return (
    <Select
      options={options}
      styles={customStyles}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder={placeholder || "Select..."}
      onChange={handleChange}
      value={value}
      isClearable
      isSearchable={isSearchable}
      isMulti={isMulti}
    />
  );
};
export default DropdownFilter;
