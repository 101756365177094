import React from "react";
import { useFeedSectionContext } from "../../../contexts/FeedSectionContext";
import {
  AttachmentContainer,
  DescriptionContainer,
  DescriptionContent,
  FeedItemContainer,
  InfoSpan1,
  InfoSpan2,
  ItemInfoContainer,
  MetaContainer,
  OptionsEllipsisContainer,
  ThreeDotsIcon,
} from "../../../styled";
import FeedItemDropdownMenu from "../../FeedItemDropdownMenu";
import ItemAttachment from "./ItemAttachment";
import ItemInfo from "./ItemInfo";

const DropdownContainer = ({ children }) => (
  <OptionsEllipsisContainer>{children}</OptionsEllipsisContainer>
);
const FeedItem = ({ feedItem }) => {
  const { setCurrentFeedItemId, viewFeedItemModalData } =
    useFeedSectionContext();

  const handleFeedItemClick = () => {
    setCurrentFeedItemId(feedItem.id);
    viewFeedItemModalData.openModal();
  };
  return (
    <FeedItemContainer style={{ margin: "1%" }}>
      <ItemInfoContainer onClick={handleFeedItemClick}>
        <ItemInfo feedItem={feedItem} />
      </ItemInfoContainer>
      <div className="d-flex justify-content-between" style={{ gap: 15 }}>
        <AttachmentContainer>
          <ItemAttachment attachment={feedItem?.attachments?.[0]} />
        </AttachmentContainer>
        <div>
          <FeedItemDropdownMenu
            dropdownToggleIcon={ThreeDotsIcon}
            dropdownToggleContainer={DropdownContainer}
            feedItem={feedItem}
          />
        </div>
      </div>
    </FeedItemContainer>
  );
};

export default FeedItem;
