import { LibraryStudentRowContainer } from "../../../styled-components/teacherLibraryStyledComponents";
import LibraryLabeledCheckbox from "../../common/LibraryLabeledCheckbox";

const LibrarySingleTeacherRow = ({ id, label, onChange, checked }) => {
  return (
    <LibraryStudentRowContainer className={"d-flex align-items-center"}>
      <LibraryLabeledCheckbox
        id={id}
        label={label}
        onChange={onChange}
        checked={checked}
      />
    </LibraryStudentRowContainer>
  );
};
export default LibrarySingleTeacherRow;
