import styled from "styled-components";

const DeleteStatusWrapper = styled.div`
  font-size: 18px;
  text-align: center;
`;

const DeleteButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const WithdrawalReason = styled.div``;

export { DeleteStatusWrapper, DeleteButtonWrapper, WithdrawalReason };
