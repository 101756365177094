import React from "react";
import useEditTimeline from "../hooks/useEditTimeline";
import EditForm from "./EditForm";

const EditTimelineBody = ({ regularPrivateLesson, currentTimeline }) => {
  const timelineData = useEditTimeline({
    regularPrivateLesson,
    currentTimeline,
  });
  const { instrumentId } = regularPrivateLesson;
  return (
    <div>
      <EditForm timelineData={timelineData} instrumentId={instrumentId} />
    </div>
  );
};

export default EditTimelineBody;
