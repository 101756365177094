import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import { PrimaryButton } from "src/utils/shared/styled";
import {
  CustomInput,
  CustomLabel,
  CustomSelectField,
  InputsContainer,
  SearchBar,
  SearchBarContainer,
  SearchIcon,
} from "../styled";

const TopSection = ({ logsData }) => {
  const {
    searchData,
    handleSearchTermChange,
    initialData,
    filtersData,
    handleApplyFilters,
    handleClearFilters,
  } = logsData || {};
  const { locations = [] } = initialData || {};
  const { searchTerm = "", isSearchEnabled = false } = searchData || {};

  const [dateField, setDateField] = useState("");
  const [locationField, setLocationField] = useState("");

  const handleSearchBarChange = (e) => {
    handleSearchTermChange(e.target.value);
  };

  const handleClearFiltersClick = () => {
    setDateField("");
    setLocationField("");
    handleClearFilters();
  };
  return (
    <div>
      <InputsContainer>
        <div style={{ width: "30%" }}>
          <CustomLabel>Created At Date:</CustomLabel>
          <CustomInput
            value={dateField}
            onChange={(e) => setDateField(e.target.value)}
            type="date"
            width="100%"
          />
        </div>
        <CustomSelectField
          value={locationField}
          onChange={(e) => setLocationField(e.target.value)}
          width="30%"
        >
          <option value="" disabled>
            Select Location
          </option>
          {locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </CustomSelectField>
        <PrimaryButton
          onClick={() =>
            handleApplyFilters({
              locationValue: locationField,
              dateValue: dateField,
            })
          }
        >
          Apply Filters
        </PrimaryButton>
        <PrimaryButton
          backgroundColor="#0012ac"
          onClick={handleClearFiltersClick}
        >
          Clear Filters
        </PrimaryButton>
      </InputsContainer>
      <div style={{ width: "50%", margin: "auto", marginTop: "20px" }}>
        <SearchBar
          value={searchTerm}
          onChange={handleSearchBarChange}
          placeholder="Search by Student Full Name"
        />
        <SearchIcon />
      </div>
    </div>
  );
};

export default TopSection;
