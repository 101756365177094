import { Button } from "reactstrap";
import styled from "styled-components";

const AttachmentOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;

  width: 100%;
  height: 100%;
  padding: 16px;

  background-color: #c1c1c1;
  transition-duration: 150ms;
  border-radius: 12px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};

  h4 {
    text-align: center;
    color: #f3f3f3;
  }
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
`;

const AttachmentButton = styled(Button)`
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: #e357a2;

  &:is(:hover, :focus) {
    border-color: #f3f3f3;
    background-color: #e357a2cc;
  }
`;

export { AttachmentOverlayWrapper, ButtonsColumn, AttachmentButton };
