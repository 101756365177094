import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getCurrentSchoolYear, updatedMomentNow } from "src/utils/helpers";
import useFirebaseFns from "./useFirebaseFns";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";

import { v4 as uuidv4 } from "uuid";

const useSummerBreakData = ({
  user,
  lessonWithCurrentTimeline,
  onSuccess,
  closeModal,
}) => {
  const { requestSummerBreakWithActivity } = useFirebaseFns();
  const { updatePrivateLesson, getSchoolYears } = useGlobalFirebaseFns();

  const lessonStartDate = lessonWithCurrentTimeline.currentTimeline?.startDate;
  const lessonLocationId =
    lessonWithCurrentTimeline.currentTimeline?.locationId;

  const [initialData, setInitialData] = useState({
    schoolYears: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  // last lesson date that will appear on the calendar, then the lesson will disappear after that date
  const [lastDate, setLastDate] = useState(null);
  const [note, setNote] = useState("");
  const [loadingSummerBreak, setLoadingSummerBreak] = useState(false);

  const submitSummerBreak = async () => {
    try {
      if (loadingSummerBreak) return;
      if (!note || !lastDate) {
        toast.warn("Please Enter All Required Fields");
        return;
      }

      setLoadingSummerBreak(true);

      const fullLastDate = moment(lastDate)
        .set({
          hours: moment(lessonStartDate).get("hours"),
          minutes: moment(lessonStartDate).get("minutes"),
        })
        .toDate();
      const breakStartDate = moment(fullLastDate).toDate();

      const requestDate = updatedMomentNow().toDate();
      const formattedRequestDate = moment(requestDate).format("MM-DD-YYYY");
      const formattedLastDate = moment(lastDate).format("MM-DD-YYYY");

      const summerBreakObj = {
        id: uuidv4(),
        requestDate,
        requestBy: user.uid,
        breakStart: breakStartDate,
      };

      const updatedMainLessonNote = `${lessonWithCurrentTimeline.note}\n${note}`;

      await requestSummerBreakWithActivity(
        summerBreakObj,
        lessonWithCurrentTimeline.id
      );

      await updatePrivateLesson(lessonWithCurrentTimeline.id, {
        note: updatedMainLessonNote,
      });

      toast.success("Withdrawal Request Submitted");
      onSuccess();
      closeModal();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoadingSummerBreak(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [schoolYears] = await Promise.all([getSchoolYears()]);

        setInitialData((oldVal) => ({
          ...oldVal,
          schoolYears,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(true);
      }
    };
    fetchInitialData();
  }, []);
  const currentSchoolYearInLocation = useMemo(() => {
    if (!initialData.schoolYears.length || !lessonLocationId) return;

    const currentSchoolYear = getCurrentSchoolYear(
      initialData.schoolYears,
      lessonLocationId
    );
    return currentSchoolYear;
  }, [initialData, lessonWithCurrentTimeline]);

  console.log({ currentSchoolYearInLocation });
  return {
    lastDate,
    setLastDate,
    loadingSummerBreak,
    submitSummerBreak,
    note,
    setNote,
    initialData,
    loadingInitialData,
    currentSchoolYearInLocation,
  };
};

export default useSummerBreakData;
