import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const CustomModal = ({
  centered,
  size = "sm",
  isOpen,
  toggle,
  body,
  title,
  buttons,
}) => (
  <Modal
    size={size}
    isOpen={isOpen}
    centered={centered}
    toggle={() => toggle(false)}
  >
    <ModalHeader toggle={() => toggle(false)}>{title}</ModalHeader>
    <ModalBody className={"p-0"}>{body}</ModalBody>
    <ModalFooter>
      <div
        className="d-flex align-items-center justify-content-center flex-grow-1 flex-wrap"
        style={{ gap: 20, whiteSpace: "nowrap" }}
      >
        <Button color="secondary" onClick={() => toggle(false)}>
          Cancel
        </Button>
        {/* <Button
          className={`ms-2 ${purpleButton ? "bg-purple text-white" : ""}`}
          color={actionButtonColor}
          onClick={okAction}
          disabled={disabled || isLoading}
        >
          {buttonText} {isLoading ? <Spinner size={"sm"} /> : null}
        </Button> */}
        {buttons}
      </div>
    </ModalFooter>
  </Modal>
);
export default CustomModal;
