import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useLessonsData = ({ lesson }) => {
  const { id: lessonId, currentTimeline, timeline } = lesson || {};
  const { teacherNote: initialTeacherNote } = currentTimeline || {};

  const { updatePrivateLesson } = useFirebaseFns();
  const [teacherNote, setTeacherNote] = useState("");
  const [loadingTeacherNote, setLoadingTeacherNote] = useState(false);

  const saveTeacherNote = async () => {
    try {
      setLoadingTeacherNote(true);

      const updatedTimeline = timeline.map((timelineObj) => {
        if (timelineObj.id !== currentTimeline?.id) return timelineObj;

        const updatedTimelineObj = { ...timelineObj, teacherNote };
        return updatedTimelineObj;
      });
      const updateObj = {
        timeline: updatedTimeline,
      };

      console.log({ lessonId, updateObj });
      await updatePrivateLesson(lessonId, updateObj);

      toast.success("Note Updated Successfully");
      window.location.reload();
      return;
    } catch (err) {
    } finally {
      setLoadingTeacherNote(false);
    }
  };

  useEffect(() => {
    setTeacherNote(initialTeacherNote);
  }, [initialTeacherNote]);

  return {
    teacherNote,
    setTeacherNote,
    loadingTeacherNote,
    loadingTeacherNote,
    saveTeacherNote,
  };
};

export default useLessonsData;
