import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useStudentActions = ({ studentId, onSuccess = () => {} }) => {
  const { updateUserAuth, getUserAuthInfo, updateUserInfo } = useFirebaseFns();

  const [loadingAction, setLoadingAction] = useState(false);
  const [userAuthInfo, setUserAuthInfo] = useState(null);

  async function toggleStudentLoginStatus(isCurrentlyDisabled) {
    if (!studentId) return;
    try {
      setLoadingAction(true);

      const updateObj = {
        disabled: isCurrentlyDisabled ? false : true,
      };
      await updateUserAuth(studentId, updateObj);
      toast.success("Done Successfully");
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingAction(true);
        const authInfo = await getUserAuthInfo(studentId);

        setUserAuthInfo(authInfo);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingAction(false);
      }
    };
    fetchInitialData();
  }, []);

  const isLoginDisabledUser = useMemo(() => {
    return !!userAuthInfo?.disabled;
  }, [userAuthInfo]);

  return { loadingAction, toggleStudentLoginStatus, isLoginDisabledUser };
};

export default useStudentActions;
