import React from "react";
import RouteContent from "src/components/common/RouteContent";
import {
  isAdmin,
  isStudent,
  isSuperAdmin,
  isTeacher,
} from "src/constants/UserRoleEnum";
import {
  AdminsNotifications,
  StudentNotifications,
  TeacherNotifications,
} from "./components";

const EmailNotifications = ({ user }) => {
  const userRole = user?.role;

  const renderSettingsComponent = () => {
    if (isSuperAdmin(userRole)) {
      return <AdminsNotifications />;
    } else if (isAdmin(userRole)) {
      return <AdminsNotifications />;
    } else if (isTeacher(userRole)) {
      return <TeacherNotifications />;
    } else if (isStudent(userRole)) {
      return <StudentNotifications />;
    } else {
      return <div></div>;
    }
  };

  return (
    <RouteContent title="Email Notifications Settings">
      {renderSettingsComponent()}
    </RouteContent>
  );
};

export default EmailNotifications;
