import React from "react";
import { CheckboxContainer, RowContainer } from "../styled";

const StudentRow = ({ id, label, onChange, checked }) => {
  return (
    <RowContainer className={"d-flex align-items-center"}>
      <CheckboxContainer className={"d-flex align-items-center"}>
        <input
          checked={checked}
          onChange={onChange}
          type="checkbox"
          id={id}
          name={id}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </CheckboxContainer>
    </RowContainer>
  );
};

export default StudentRow;
