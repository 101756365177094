import { useContext, useState } from "react";
import {
  addDoc,
  getDocs,
  query,
  Timestamp,
  where,
  writeBatch,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import { uploadFileWithRandomId } from "src/utils/helpers";
import { deleteObject, ref } from "firebase/storage";

const useFeatureNotifications = () => {
  const [screenshots, setScreenshots] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const firebase = useContext(FirebaseContext);
  const firestore = firebase.db;
  const storage = firebase.storage;

  const activateFeatureNotifications = async (roles) => {
    if (!Array.isArray(roles) || roles.length === 0) {
      return toast.error("No roles selected");
    }

    const q = query(firebase.users(), where("role", "in", roles));

    try {
      setIsLoading(true);

      await addDoc(firebase.featureNotifications(), {
        createdAt: Timestamp.now(),
        title: titleValue,
        description: descriptionValue,
        screenshots,
      });

      const snap = await getDocs(q);
      const batch = writeBatch(firestore);

      snap.forEach((doc) => {
        batch?.update(doc?.ref, {
          featureNotificationSeen: false,
        });
      });

      await batch.commit();
      toast.success("Notification activated");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const deactivateFeatureNotifications = async (roles) => {
    if (!Array.isArray(roles) || roles.length === 0) {
      return toast.error("No roles selected");
    }

    const q = query(firebase.users(), where("role", "in", roles));

    try {
      setIsLoading(true);
      const snap = await getDocs(q);
      const batch = writeBatch(firestore);

      snap.forEach((doc) => {
        batch?.update(doc?.ref, {
          featureNotificationSeen: true,
        });
      });

      await batch.commit();
      toast.success("Notifications deactivated");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleScreenshotUpload = async (info) => {
    setIsLoading(true);
    const response = await uploadFileWithRandomId(
      firebase,
      info.file,
      "featureScreenshots/"
    );

    setScreenshots((prevState) => [...prevState, response]);
    setIsLoading(false);
  };

  const handleScreenshotDelete = async (id) => {
    const screenshotRef = ref(storage, `featureScreenshots/${id}`);
    await deleteObject(screenshotRef);

    setScreenshots((prevState) => prevState?.filter((img) => img?.id !== id));
  };

  const handleDescriptionChange = ({ target }) => {
    setDescriptionValue(target?.value);
  };

  const handleTitleChange = ({ target }) => {
    setTitleValue(target?.value);
  };

  return {
    activateFeatureNotifications,
    handleScreenshotUpload,
    handleScreenshotDelete,
    handleDescriptionChange,
    handleTitleChange,
    deactivateFeatureNotifications,
    screenshots,
    descriptionValue,
    titleValue,
    isLoading,
  };
};

export default useFeatureNotifications;
