import { eventsMap } from "src/constants/eventsEnum";

export const defaultEventsColor = "#681e46";
export const defaultTrialLessonsColor = "#c9062d";
export const defaultMakeupLessonsColor = "#dd05d3";
export const defaultModifiedPLColor = "#ff7a00";
export const defaultOneTimeBreakColor = "#989898";
export const defaultRecurringBreakColor = "#989898";
export const defaultPackageLessonsColor = "#8e24aa";

// the events that has details that should appear in the EventDetailsModal
export const modalDetailsEventsCodes = [
  eventsMap.privateLesson.code,
  eventsMap.trialLesson.code,
  eventsMap.makeupLesson.code,
  eventsMap.calendarLabel.code,
  eventsMap.recurringCalendarLabel.code,
  eventsMap.appointment.code,
  eventsMap.groupClass.code,
];

export const studentTypes = {
  privateStudent: {
    name: "privateStudent",
  },
  trialStudent: {
    name: "trialStudent",
  },
};
