import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { CustomTextarea, InputWrapper, CustomLabel } from "../../styled";

const AdminNoteSection = ({ currentLesson }) => {
  const { updatePrivateLesson } = useFirebaseFns();

  const [lessonNote, setLessonNote] = useState("");
  const [loadingSavingNote, setLoadingSavingNote] = useState("");

  useEffect(() => {
    const note = currentLesson?.note;
    if (note) {
      setLessonNote(note);
    } else {
      setLessonNote("");
    }
  }, [currentLesson?.note]);

  const saveLessonNote = async () => {
    try {
      setLoadingSavingNote(true);

      await updatePrivateLesson(currentLesson?.id, {
        note: lessonNote,
      });

      toast.success("Note saved successfully");
      window.location.reload();
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoadingSavingNote(false);
    }
  };
  return (
    <InputWrapper className="mt-4">
      <div className="mb-3 d-flex align-items-center">
        <CustomLabel className="mb-0">Note (For Admins):</CustomLabel>
        <div className="ms-4 d-flex">
          <PrimaryButtonWithLoading
            backgroundColor="#e357a2"
            isLoading={loadingSavingNote}
            onClick={saveLessonNote}
            width="145px"
            className="me-2"
            padding="6px"
          >
            Save Note
          </PrimaryButtonWithLoading>
        </div>
      </div>
      <CustomTextarea
        width="650px"
        height="130px"
        className="form-control"
        name="note"
        value={lessonNote}
        onChange={(e) => setLessonNote(e.target.value)}
      />
    </InputWrapper>
  );
};

export default AdminNoteSection;
