import React from "react";
import { DeleteIcon, InputContainer, InputRow } from "../../../styled";
import { CustomLabel } from "src/utils/styled";
import { CustomSelectField, PrimaryButton } from "src/utils/shared/styled";
import { amountTypesWithOptions, paymentAmountTypes } from "../../../constants";
import { groupClassPaymentOptionsMap } from "src/constants/groupClassPaymentsConstants";
import NumberInput from "../shared/NumberInput";

const PaymentAmountsSelection = ({
  paymentFormInputs,
  amountType,
  handleDeletePaymentAmount,
  handleEditPaymentAmount,
}) => {
  const options = amountTypesWithOptions[amountType];
  const amounts = paymentFormInputs[amountType];
  return (
    <div>
      {amounts.map((amountObj, i) => {
        return (
          <InputRow flexWrap="wrap" key={amountObj.id}>
            <InputContainer>
              <CustomLabel>Type:</CustomLabel>
              <CustomSelectField
                minWidth="200px"
                value={
                  options.find(
                    ({ value }) => parseInt(value) === parseInt(amountObj.type)
                  )?.value || ""
                }
                onChange={(e) =>
                  handleEditPaymentAmount(
                    amountType,
                    "type",
                    parseInt(e.target.value),
                    i
                  )
                }
                width="100%"
              >
                <option value="" disabled>
                  Type
                </option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.title1}
                  </option>
                ))}
              </CustomSelectField>
            </InputContainer>

            <InputContainer flexGrow="0.7">
              <CustomLabel textWrap="nowrap">Amount in ($):</CustomLabel>
              <NumberInput
                value={paymentFormInputs[amountType][i]?.amount}
                onChange={(value) =>
                  handleEditPaymentAmount(amountType, "amount", value, i)
                }
              />
            </InputContainer>
            <InputContainer flexGrow="0" className="d-flex align-items-end">
              <PrimaryButton
                width="100px"
                padding="7px"
                onClick={() => handleDeletePaymentAmount(amountType, i)}
              >
                Delete
              </PrimaryButton>
            </InputContainer>
          </InputRow>
        );
      })}
    </div>
  );
};

export default PaymentAmountsSelection;
