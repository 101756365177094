import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import CreateClassBody from "./CreateClassBody";

const CreateGroupClassModal = ({ modalData }) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;
  return (
    <CustomModal
      size="lg"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding={"0px"}
    >
      <CustomModalHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        toggle={toggleModal}
        // className="pb-0 border-bottom-0"
        padding="25px"
      >
        Create Group Class
      </CustomModalHeader>
      <CustomModalBody padding={"0px"}>
        <CreateClassBody />
      </CustomModalBody>
    </CustomModal>
  );
};

export default CreateGroupClassModal;
