import moment from "moment";
import React from "react";
import { weekDays } from "src/constants/weekDays";
import { CustomLabel, CustomSelectField } from "../../../../styled";

const WeekDaySelect = ({
  selectedTeachers,
  formInputs,
  handleFormInputsChange,
}) => {
  // to get the selected teachers days weekdays, we consider both the startDate weekday and endDate weekday
  // the day has to be available for all selectedTeachers to be included in the list

  const selectedTeachersWeekDays = selectedTeachers.map(
    ({ teacherAvailableDays }) => {
      const allWeekdays = teacherAvailableDays
        ?.map((day) => {
          const { startDate, endDate } = day;
          const startDateWeekDay = moment(startDate).day();
          const endDateWeekDay = moment(endDate).day();

          return [startDateWeekDay, endDateWeekDay];
        })
        ?.flat();
      const uniqueWeekDays = [...new Set(allWeekdays || [])];
      return uniqueWeekDays;
    }
  );

  const copy = [...selectedTeachersWeekDays];
  const commonWeekdays = selectedTeachersWeekDays.length
    ? copy.shift().filter(function (v) {
        return copy.every(function (a) {
          return a.indexOf(v) !== -1;
        });
      })
    : [];

  return (
    <>
      <CustomLabel>Available Days:</CustomLabel>
      <CustomSelectField
        disabled={!formInputs.teachersIds?.length}
        value={formInputs.day}
        onChange={(e) => handleFormInputsChange("day", e.target.value)}
        width="100%"
      >
        <option value="" disabled>
          Day
        </option>
        {commonWeekdays.map((day, i) => {
          const dayName = weekDays.find(({ id }) => id === parseInt(day)).name;

          return (
            <option key={day} value={day}>
              {dayName || "N/A"}
            </option>
          );
        }) || []}
      </CustomSelectField>
    </>
  );
};

export default WeekDaySelect;
