import { hasAdminCredentials, isTeacher } from "src/constants/UserRoleEnum";

export function isPaymentInLocation({
  payment,
  selectedLocationId,
  users = [],
  usersAvailableDays = [],
}) {
  if (!payment || !users?.length) return false;

  const paymentUser = users.find(({ id }) => id === payment.userId);
  if (!paymentUser) return false;

  let pass = false;
  if (isTeacher(paymentUser.role)) {
    const paymentUserAvailableDays = usersAvailableDays?.find(
      ({ id }) => id === paymentUser?.id
    );
    const hasAvailableDaysInSelectedLocation =
      paymentUserAvailableDays?.availableDays?.some(
        ({ location }) => location === selectedLocationId
      );
    pass = !!hasAvailableDaysInSelectedLocation;
  } else if (hasAdminCredentials(paymentUser.role)) {
    const isSameLocation = selectedLocationId === paymentUser.locationId;
    pass = isSameLocation;
  }
  return pass;
}
