import styled from "styled-components";
import { Close } from "styled-icons/evaicons-solid";
import { DeleteOff } from "styled-icons/fluentui-system-filled";

export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
export const Container = styled.div`
  background: #f6f6f69e;
  display: flex;
`;

export const RedBarContainer = styled.div`
  background: #681e46;
  width: 50px;
  color: #fff;
  writing-mode: vertical-lr;
  text-orientation: revert;
  display: flex;
  align-items: center;
  padding: 40px 26px;
`;
export const RedBarText = styled.p`
  font-size: 1.3rem;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
`;

export const PackageItemsContainer = styled.div`
  width: 100%;
  /* display: flex; */
  /* justify-content: center; */
  height: 400px;
  overflow: auto;
`;
export const PackageItemContainer = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 15px;
`;
export const InfoContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const InfoText = styled.p`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : "auto")};
`;
export const DeleteIcon = styled(DeleteOff)`
  height: 25px;
  width: 25px;
  /* color: #db0000; */
  cursor: pointer;
`;
