import React from "react";
import { useState } from "react";
import { feedItems } from "src/constants/feedItemsEnum";
import { injectUserStore } from "src/utils/helpers";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";
import { CustomInput, CustomTextarea } from "src/utils/shared/styled";
import CustomRichTextEditor from "../../../../CustomRichTextEditor";
import { useFeedSectionContext } from "../../../contexts/FeedSectionContext";
import useFormData from "../hooks/useFormData";
import ForRolesSection from "./ForRolesSection";

const ModalBodyContent = ({ modalData, feedItem, UserStore }) => {
  const { onEditFeedItemSuccess } = useFeedSectionContext();

  const user = UserStore.user;
  const formData = useFormData(feedItem, user);
  const { formFields, onFormFieldChange, saveFormData, isLoading } = formData;
  const { title, body } = formFields;
  const isWorkUpdate = feedItem.type === feedItems.workUpdates.type;

  const handleSaveChanges = async () => {
    const updatedObj = await saveFormData();
    // updates the state
    onEditFeedItemSuccess(feedItem.id, updatedObj);
    modalData.closeModal();
  };

  return (
    <div>
      {!isWorkUpdate && (
        <div className="mb-4">
          <h2 className="mb-3">For:</h2>
          <ForRolesSection formData={formData} feedItem={feedItem} />
          <hr />
        </div>
      )}
      <div className="mb-4">
        <h2 className="mb-3 fs-4">Title:</h2>
        <div className="mb-3">
          <CustomInput
            width="100%"
            name="title"
            placeholder="Title"
            type="text"
            value={title}
            onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
          />
        </div>
      </div>

      <div className="mb-4">
        <h2 className="mb-3 fs-4">Description:</h2>
        <div>
          <CustomRichTextEditor
            editorValue={body}
            onEditorValueChange={(newValue) =>
              onFormFieldChange("body", newValue)
            }
          />
        </div>
      </div>
      <div className="mt-5 text-center">
        <PrimaryButtonWithLoading
          onClick={handleSaveChanges}
          isLoading={isLoading}
        >
          Save Changes
        </PrimaryButtonWithLoading>
      </div>
    </div>
  );
};

export default injectUserStore(ModalBodyContent);
