import { toJS } from "mobx";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { compose } from "recompose";
import { adminCredentialsRolesList } from "src/constants/UserRoleEnum";
import { NavButton } from "src/utils/shared/styled";
import { NavButtonsContainer } from "./styled";

const MobileAuthNav = ({ UserStore }) => {
  const { user, isLoggedIn } = UserStore;
  const location = useLocation();
  const navigate = useNavigate();

  const signinRoute = "/signin";
  const magicLinkRoute = "/passwordless-signin";
  const signupRoute = "/signup";
  const dashboardRoute = "/dashboard";

  const isSignInPage = location.pathname === signinRoute;
  const isMagicLinkPage = location.pathname === magicLinkRoute;
  const isSignUpPage = location.pathname === signupRoute;
  return (
    <NavButtonsContainer>
      {!isLoggedIn ? (
        <>
          <div>
            <NavButton
              paddingRight="0px"
              fontSize="0.9rem"
              withRedBar
              className={isSignInPage ? "active" : undefined}
              onClick={() =>
                navigate({
                  pathname: signinRoute,
                  state: { from: location },
                })
              }
            >
              COMMUNITY LOGIN
            </NavButton>
          </div>
          <div>
            <NavButton
              paddingRight="0px"
              fontSize="0.9rem"
              withRedBar
              className={isMagicLinkPage ? "active" : undefined}
              onClick={() =>
                navigate({
                  pathname: magicLinkRoute,
                  state: { from: location },
                })
              }
            >
              FIRST TIME LOGIN
            </NavButton>
          </div>
        </>
      ) : null}
      {adminCredentialsRolesList.includes(user?.role) ? (
        <>
          <div>
            <NavButton
              paddingRight="0px"
              fontSize="1.2rem"
              withRedBar
              className={isSignUpPage ? "active" : undefined}
              onClick={() =>
                navigate({
                  pathname: signupRoute,
                  state: { from: location },
                })
              }
            >
              SIGN UP
            </NavButton>
          </div>
          <div>
            <NavButton
              paddingRight="0px"
              fontSize="1.2rem"
              withRedBar
              onClick={() =>
                navigate({
                  pathname: dashboardRoute,
                  state: { from: location },
                })
              }
            >
              DASHBOARD
            </NavButton>
          </div>
        </>
      ) : null}
    </NavButtonsContainer>
  );
};

export default compose(inject("UserStore"), observer)(MobileAuthNav);
