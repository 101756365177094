import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { eventsMap, privateLessonTypes } from "src/constants/eventsEnum";
import { UserRole } from "src/constants/UserRoleEnum";
import { parseAbsenceObjDates } from "src/utils/firebaseDatesParserFns";
import {
  getMultiDayTeacherDaysAvailabilities,
  getTeacherDaysWithAvailableSlots,
} from "../helperFns";
import useFirebaseFns from "./useFirebaseFns";
import { v4 as uuidv4 } from "uuid";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import { isTeacherAbsence } from "src/constants/absenceTypes";
import {
  getCombinedSchoolBreaks,
  getLessonPackageItemsInfo,
  isActiveTeacher,
} from "src/utils/helpers";
import { MAIN_LOCATION_SYSTEM_ID } from "src/constants/constantVariables";
import { packageLessonSetTypes } from "src/constants/packageLessonsEnum";

const breakDurations = {
  100: { lessonDuration: 100, breakDuration: 5 },
  80: { lessonDuration: 80, breakDuration: 10 },
};
const usePrivateLessonData = ({
  providedInitialData = {},
  studentId,
  modalData,
  user,
  isReturnFromSummerBreak,
  returnFromSummerBreakData = {
    privateLessonId: "",
    summerBreakId: "",
  },
  is10RecurringPackageLessons,
  isRecurringPackageLessonRenewal,
  recurringPackageLessonRenewalData = {
    newSetNumber: undefined,
    packageLessonId: "",
    instrumentId: "",
  },
}) => {
  const {
    getTeachersActivities,
    getTeachers,
    getInstruments,
    getLocations,
    getPrograms,
    createRegularPrivateLesson,
    getTeachersTAs,
    createTeacherBreakAndActivity,
  } = useFirebaseFns();
  const {
    getTeachersAvailableDays,
    getPrivateLessonsByIds,
    returnFromSummerBreak,
    getAbsences,
    getSchoolYears,
    createPackagePrivateLessonAndActivity,
    updatePackageSetsAndActivity,
  } = useGlobalFirebaseFns();

  const [initialData, setInitialData] = useState({
    teachers: [],
    instruments: [],
    locations: [],
    programs: [],
    absences: [], // teacher absences
    schoolYears: [],
    privateLesson: undefined, // in case of returning from summer break or isRecurring10PackageLessonRenewal
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [formInputs, setFormInputs] = useState({
    instrumentId: "",
    teacherId: "",
    day: "",
    startDate: "",
    lessonLength: "",
    lessonTime: "",
    note: "",
    isVirtual: false,
    //extras
    locationId: "", // based on  lessonTime
    programId: "", //based on teacherId
  });

  // for 10 recurring only
  const [recurring10Dates, setRecurring10Dates] = useState([]);

  const handleFormInputsChange = (e) => {
    switch (e.target.name) {
      case "instrumentId": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          instrumentId: e.target.value,
          teacherId: "",
          day: "",
          startDate: "",
          lessonLength: "",
          lessonTime: "",
          note: "",
          locationId: "",
          programId: "",
        }));
        return;
      }

      case "day": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          day: e.target.value,
          startDate: "",
          lessonLength: "",
          lessonTime: "",
          note: "",
          locationId: "",
        }));
        return;
      }
      case "startDate": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          startDate: e.target.value,
          lessonLength: "",
          lessonTime: "",
          note: "",
          locationId: "",
        }));
        return;
      }
      case "lessonLength": {
        setFormInputs((oldVal) => ({
          ...oldVal,
          lessonLength: e.target.value,
          lessonTime: "",
          note: "",
          locationId: "",
        }));
        return;
      }
      case "isVirtual": {
        const value = e.target.value.toLowerCase() === "true" ? true : false;
        setFormInputs((oldVal) => ({
          ...oldVal,
          isVirtual: value,
        }));
        return;
      }
      default: {
        setFormInputs((oldVal) => ({
          ...oldVal,
          [e.target.name]: e.target.value,
        }));
        return;
      }
    }
  };

  const handleLessonTimeChange = (lessonTime, locationId) => {
    if ((lessonTime, locationId)) {
      setFormInputs((oldVal) => ({
        ...oldVal,
        lessonTime,
        locationId,
      }));
    }
  };

  const handleTeacherChange = (teacherId, programId) => {
    if (teacherId && programId) {
      setFormInputs((oldVal) => ({
        ...oldVal,
        teacherId,
        day: "",
        startDate: "",
        lessonLength: "",
        lessonTime: "",
        note: "",
        locationId: "",
        programId,
      }));
    }
  };

  const [loadingSavingLesson, setLoadingSavingLesson] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const reqs = [
          providedInitialData.teachers || getTeachers(),
          providedInitialData.teachersAvailableDays ||
            getTeachersAvailableDays(),
          providedInitialData.teachersActivities || getTeachersActivities(),
          providedInitialData.instruments || getInstruments(),
          providedInitialData.locations || getLocations(),
          providedInitialData.programs || getPrograms(),
          getAbsences(),
          getSchoolYears(),
          isReturnFromSummerBreak
            ? getPrivateLessonsByIds([
                returnFromSummerBreakData.privateLessonId,
              ])
            : isRecurringPackageLessonRenewal
            ? getPrivateLessonsByIds([
                recurringPackageLessonRenewalData.packageLessonId,
              ])
            : [],
        ];

        const [
          teachers = [],
          teachersAvailableDays = [],
          teachersActivities = [],
          instruments = [],
          locations = [],
          programs = [],
          absences = [],
          schoolYears,
          [privateLesson] = undefined,
        ] = await Promise.all(reqs);

        const teachersWithAdditionalData = teachers?.map((teacher) => {
          const teacherAvailableDays =
            teachersAvailableDays
              .find(({ id }) => id === teacher?.id)
              ?.availableDays?.filter(({ isPaused }) => !isPaused) || [];
          const teacherActivities =
            teachersActivities?.filter(
              ({ userId, usersIds }) =>
                userId === teacher?.id || usersIds?.includes(teacher?.id)
            ) || [];
          return {
            ...teacher,
            teacherAvailableDays,
            teacherActivities,
          };
        });

        setInitialData((oldVal) => ({
          ...oldVal,
          teachers: teachersWithAdditionalData,
          instruments,
          locations,
          programs,
          absences,
          privateLesson,
          schoolYears,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  // responsible of setting the initial form values in case of summer break return
  useEffect(() => {
    if (!isReturnFromSummerBreak) return;

    setFormInputs((oldVal) => ({
      ...oldVal,
      instrumentId: initialData.privateLesson?.instrumentId,
    }));
  }, [initialData, isReturnFromSummerBreak]);

  useEffect(() => {
    if (!isRecurringPackageLessonRenewal) return;

    setFormInputs((oldVal) => ({
      ...oldVal,
      instrumentId: recurringPackageLessonRenewalData?.instrumentId,
    }));
  }, [isRecurringPackageLessonRenewal]);

  // creates the pl
  const handleCreatePrivateLesson = async () => {
    try {
      //prevent saving the lsn if clicked twice on the btn
      if (loadingSavingLesson) return;

      setLoadingSavingLesson(true);
      //check if we are missing any form input (other than the optional note)
      let isCompleteFormInfo = true;
      Object.keys(formInputs).forEach((key) => {
        if (
          !formInputs[key] &&
          formInputs[key] !== 0 &&
          !key === "note" &&
          !key == "isVirtual"
        )
          isCompleteFormInfo = false;
      });

      if (isCompleteFormInfo) {
        const {
          instrumentId,
          teacherId,
          day,
          startDate,
          lessonLength,
          lessonTime,
          note,
          locationId,
          programId,
          isVirtual,
        } = formInputs;

        const lessonDuration = parseInt(lessonLength);

        const lessonStartHr = moment(lessonTime, "hh:mm A").hours();
        const lessonStartMin = moment(lessonTime, "hh:mm A").minutes();

        const lessonStartDate = moment(startDate)
          .set({
            hours: lessonStartHr,
            minutes: lessonStartMin,
            seconds: 0,
            milliseconds: 0,
          })
          .toDate();
        const lessonEndDate = moment(lessonStartDate)
          .add(lessonDuration, "minutes")
          .toDate();

        const timelineObj = {
          id: uuidv4(),
          startDate: lessonStartDate, // first lesson date
          duration: lessonDuration,
          teacherId,
          locationId,
          isVirtual,
          requestedAt: new Date(),
          requestedBy: user?.uid,
        };
        const lessonObj = {
          createdAt: new Date(),
          instrumentId,
          note,
          studentId,
          timeline: [timelineObj],
          type: privateLessonTypes.REGULAR,
        };

        console.log({ lessonObj });
        //creates the lsn with its activities
        await createRegularPrivateLesson(lessonObj);

        if (
          Object.keys(breakDurations).includes(JSON.stringify(lessonDuration))
        ) {
          const breakDuration = breakDurations[lessonDuration].breakDuration;
          const reason = `Break for ${lessonDuration} minutes private lesson`;
          console.log(`${lessonDuration} Minutes lsn, creating BREAK`);

          const breakTimelineObj = {
            id: uuidv4(),
            date: lessonEndDate, // first lesson date
            duration: breakDuration,
            requestedAt: new Date(),
            requestedBy: user?.uid,
          };
          const breakObj = {
            createdAt: new Date(),
            type: eventsMap.recurringBreak.code,
            reason,
            userRole: UserRole.TEACHER,
            userId: teacherId,
            timeline: [breakTimelineObj],
          };
          console.log({ breakObj });
          const res = await createTeacherBreakAndActivity(breakObj, user?.uid);
        }
        toast.success("Lesson Creates Successfully");
      } else {
        toast.warn("Form is incomplete");
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingSavingLesson(false);
    }
  };

  const handleReturnFromSummerBreak = async () => {
    try {
      //prevent saving the lsn if clicked twice on the btn
      if (loadingSavingLesson || !initialData.privateLesson) return;

      setLoadingSavingLesson(true);
      //check if we are missing any form input (other than the optional note)
      let isCompleteFormInfo = true;
      Object.keys(formInputs).forEach((key) => {
        if (
          !formInputs[key] &&
          formInputs[key] !== 0 &&
          !key === "note" &&
          !key == "isVirtual"
        )
          isCompleteFormInfo = false;
      });

      if (isCompleteFormInfo) {
        const {
          instrumentId,
          teacherId,
          day,
          startDate,
          lessonLength,
          lessonTime,
          note,
          locationId,
          programId,
          isVirtual,
        } = formInputs;

        const lessonDuration = parseInt(lessonLength);

        const lessonStartHr = moment(lessonTime, "hh:mm A").hours();
        const lessonStartMin = moment(lessonTime, "hh:mm A").minutes();

        const lessonStartDate = moment(startDate)
          .set({
            hours: lessonStartHr,
            minutes: lessonStartMin,
            seconds: 0,
            milliseconds: 0,
          })
          .toDate();

        const timelineObj = {
          id: uuidv4(),
          startDate: lessonStartDate, // first lesson date
          duration: lessonDuration,
          teacherId,
          locationId,
          isVirtual,
          requestedAt: new Date(),
          requestedBy: user?.uid,
        };

        //creates the lsn with its activities
        await returnFromSummerBreak(
          initialData.privateLesson.id,
          returnFromSummerBreakData.summerBreakId,
          timelineObj
        );
        toast.success("Returned from summer break Successfully");
      } else {
        toast.warn("Form is incomplete");
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingSavingLesson(false);
    }
  };
  const handleCreate10RecurringPackage = async () => {
    try {
      //prevent saving the lsn if clicked twice on the btn
      if (loadingSavingLesson) return;

      setLoadingSavingLesson(true);
      //check if we are missing any form input (other than the optional note)
      let isCompleteFormInfo = true;
      Object.keys(formInputs).forEach((key) => {
        if (
          !formInputs[key] &&
          formInputs[key] !== 0 &&
          !key === "note" &&
          !key == "isVirtual"
        )
          isCompleteFormInfo = false;
      });

      if (!isCompleteFormInfo) {
        toast.warn("Form is incomplete");
        return;
      }

      const {
        instrumentId,
        teacherId,
        day,
        startDate,
        lessonLength,
        lessonTime,
        note,
        locationId,
        programId,
        isVirtual,
      } = formInputs;

      const lessonDuration = parseInt(lessonLength);

      const lessonStartHr = moment(lessonTime, "hh:mm A").hours();
      const lessonStartMin = moment(lessonTime, "hh:mm A").minutes();

      const setPackageItems = recurring10Dates.map((scheduleDate) => {
        const startDate = moment(scheduleDate)
          .set({
            hours: lessonStartHr,
            minutes: lessonStartMin,
            seconds: 0,
            milliseconds: 0,
          })
          .toDate();
        const packageObj = {
          id: uuidv4(),
          startDate,
          duration: parseInt(lessonDuration),
          teacherId,
          locationId,
          isVirtual,
          requestedBy: user.uid,
          requestedAt: new Date(),
        };
        return packageObj;
      });

      const packageSets = [
        {
          setNumber: 1,
          setType: packageLessonSetTypes.RECURRING_10,
          setItems: setPackageItems,
        },
      ];

      //---------------------------------------------------------------------------------------
      /* -- Creating The Lesson -- */
      // the lesson document structure
      const lessonObj = {
        createdAt: new Date(),
        instrumentId: instrumentId,
        studentId: studentId,
        type: privateLessonTypes.PACKAGE,
        packageSets,
      };
      console.log({ lessonObj });
      const lessonCreatedRes = await createPackagePrivateLessonAndActivity(
        lessonObj
      );
      //-------------------------------------------------------------------------

      toast.success("Lesson Creates Successfully");
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingSavingLesson(false);
    }
  };

  const handleRenew10RecurringPackage = async () => {
    try {
      //prevent saving the lsn if clicked twice on the btn
      if (loadingSavingLesson) return;

      if (!initialData.privateLesson) {
        toast.warn("couldnt find packageLessonId");
        return;
      }
      const privateLesson = initialData.privateLesson;

      setLoadingSavingLesson(true);
      //check if we are missing any form input (other than the optional note)
      let isCompleteFormInfo = true;
      Object.keys(formInputs).forEach((key) => {
        if (
          !formInputs[key] &&
          formInputs[key] !== 0 &&
          !key === "note" &&
          !key == "isVirtual"
        )
          isCompleteFormInfo = false;
      });

      if (!isCompleteFormInfo) {
        toast.warn("Form is incomplete");
        return;
      }

      const {
        instrumentId,
        teacherId,
        day,
        startDate,
        lessonLength,
        lessonTime,
        note,
        locationId,
        programId,
        isVirtual,
      } = formInputs;

      const lessonDuration = parseInt(lessonLength);

      const lessonStartHr = moment(lessonTime, "hh:mm A").hours();
      const lessonStartMin = moment(lessonTime, "hh:mm A").minutes();

      const setPackageItems = recurring10Dates.map((scheduleDate) => {
        const startDate = moment(scheduleDate)
          .set({
            hours: lessonStartHr,
            minutes: lessonStartMin,
            seconds: 0,
            milliseconds: 0,
          })
          .toDate();

        const packageObj = {
          id: uuidv4(),
          startDate,
          duration: parseInt(lessonDuration),
          teacherId,
          locationId,
          isVirtual,
          requestedBy: user.uid,
          requestedAt: new Date(),
        };
        return packageObj;
      });

      const setObj = {
        setNumber: recurringPackageLessonRenewalData.newSetNumber,
        setType: packageLessonSetTypes.RECURRING_10,
        setItems: setPackageItems,
      };

      const updatedSets = [...(privateLesson.packageSets || []), setObj];

      console.log({ updatedSets });
      const lessonCreatedRes = await updatePackageSetsAndActivity(
        updatedSets,
        initialData.privateLesson.id
      );
      //-------------------------------------------------------------------------

      toast.success("Lesson Creates Successfully");
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setLoadingSavingLesson(false);
    }
  };

  const combinedSchoolBreaks = useMemo(() => {
    if (!initialData.schoolYears || !initialData.locations?.length) return [];
    const locationId = initialData.locations.find(
      ({ systemID }) => systemID === MAIN_LOCATION_SYSTEM_ID
    )?.id;

    const combinedBreaks = getCombinedSchoolBreaks(
      initialData.schoolYears,
      locationId
    );

    return combinedBreaks;
  }, [initialData]);

  const teachersBasedOnInstrument = useMemo(
    () =>
      initialData.teachers?.filter((teacher) => {
        const isActive = isActiveTeacher(teacher);
        const { instrumentsInfo = [] } = teacher || {};
        const teacherInstrumentsIds =
          instrumentsInfo?.map(({ instrument, program }) => instrument) || [];
        return (
          isActive && teacherInstrumentsIds.includes(formInputs.instrumentId)
        );
      }),
    [initialData.teachers, formInputs.instrumentId]
  );

  const selectedTeacherObj = teachersBasedOnInstrument.find(
    ({ id }) => id === formInputs.teacherId
  );

  const selectedTeacherDays = useMemo(() => {
    if (
      !selectedTeacherObj ||
      !formInputs.startDate ||
      !formInputs.day ||
      !formInputs.lessonLength
    )
      return [];
    let teacherDaysWithTimeSlots;
    if (is10RecurringPackageLessons) {
      const { mergedTeacherDays, recurringDates } =
        getMultiDayTeacherDaysAvailabilities({
          teacherId: selectedTeacherObj.id, // {}
          teacherActivities: selectedTeacherObj.teacherActivities,
          teacherAvailableDays: selectedTeacherObj.teacherAvailableDays,
          lessonDuration: parseInt(formInputs.lessonLength), // int
          requestedStartDate: formInputs.startDate, // date
          schoolBreaks: combinedSchoolBreaks, // []
          absences: [], // we dont want absences to block time slots, thats why this is empty arr [] so we dont consider teachers duration TAs
          frequency: "weekly",
          numberOfIterations: 10,
        });
      teacherDaysWithTimeSlots = mergedTeacherDays;
      setRecurring10Dates(recurringDates || []);
    } else {
      teacherDaysWithTimeSlots = getTeacherDaysWithAvailableSlots({
        teacherId: selectedTeacherObj.id,
        teacherActivities: selectedTeacherObj.teacherActivities,
        teacherAvailableDays: selectedTeacherObj.teacherAvailableDays,
        formInputs,
      });
    }

    return teacherDaysWithTimeSlots;
  }, [
    selectedTeacherObj,
    formInputs,
    initialData.absences,
    is10RecurringPackageLessons,
    combinedSchoolBreaks,
  ]);

  const showAvailabilities =
    !!formInputs.day &&
    !!formInputs.startDate &&
    !!selectedTeacherObj &&
    !!formInputs.lessonLength;

  const showSaveBtn =
    !!formInputs.instrumentId &&
    !!formInputs.teacherId &&
    !!formInputs.day &&
    !!formInputs.startDate &&
    !!formInputs.lessonLength &&
    !!formInputs.lessonTime;

  return {
    initialData,
    loadingInitialData,
    formInputs,
    handleFormInputsChange,
    teachersBasedOnInstrument,
    selectedTeacherObj,
    selectedTeacherDays,
    showAvailabilities,
    showSaveBtn,
    loadingSavingLesson,
    handleLessonTimeChange,
    handleTeacherChange,
    handleCreatePrivateLesson,
    handleReturnFromSummerBreak,
    handleCreate10RecurringPackage,
    handleRenew10RecurringPackage,
  };
};

export default usePrivateLessonData;
