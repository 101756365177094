import React from "react";
import RedbarModal from "../RedbarModal";
import { CustomButton, CustomButtonsWrapper } from "./styled";
import usePackageLessonSchedule from "./hooks/usePackageLessonSchedule";
import ChangePackageLessonScheduleBody from "./ChangePackageLessonScheduleBody";
import { SubmitSpinner } from "src/components/common";
import { injectUserStore } from "src/utils/helpers";

const ChangePackageLessonScheduleModal = ({
  packageLessonId,
  setNumber,
  modalData,
  UserStore,
}) => {
  const user = UserStore.user;
  const { isModalOpen, toggleModal, closeModal } = modalData;

  const hookData = usePackageLessonSchedule({
    user,
    packageLessonId,
    setNumber,
  });
  const { savingData, saveChanges } = hookData;
  const renderFooterContent = () => {
    return (
      <div>
        <CustomButtonsWrapper>
          <CustomButton onClick={closeModal}>Cancel</CustomButton>|
          <CustomButton primary onClick={saveChanges}>
            {savingData ? (
              <div>
                <SubmitSpinner style={{ width: "20px", height: "20px" }} />
              </div>
            ) : (
              "Confirm"
            )}
          </CustomButton>
        </CustomButtonsWrapper>
      </div>
    );
  };
  return (
    <RedbarModal
      modalSize="md"
      renderFooterSection={renderFooterContent}
      modalData={modalData}
      modalHeader={"Change Package Lesson Schedule"}
      redbarTitle={"Change Schedule"}
      overflowRedbarContent={true}
    >
      <ChangePackageLessonScheduleBody
        closeModal={closeModal}
        hookData={hookData}
      />
    </RedbarModal>
  );
};

export default injectUserStore(ChangePackageLessonScheduleModal);
