import React from "react";
import BasicInfoForm from "./BasicInfoForm";
import styled from "styled-components";
import UserProfile from "./UserProfile";

const SectionContainer = styled.div`
  min-height: 500px;
  margin-top: -20px;
`;

const SecondTeacherRegister = () => {
  return (
    <SectionContainer>
      <div className="d-flex justify-content-between w-100">
        <UserProfile />
      </div>
      {/* Bottom Form */}
      <BasicInfoForm />
    </SectionContainer>
  );
};

export default SecondTeacherRegister;
