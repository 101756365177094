import React from "react";
import { CustomInput, CustomLabel, CustomSelectField } from "./styled";

const InstrumentSelect = ({
  instruments,
  formInputs,
  handleFormInputsChange,
  isDisabled,
}) => {
  const insturmentName = instruments?.find(
    ({ id }) => id === formInputs.instrumentId
  )?.name;
  return (
    <>
      <CustomLabel className="mb-3">Instruments</CustomLabel>
      {isDisabled ? (
        <CustomInput
          disabled={isDisabled}
          name="instrumentId"
          value={insturmentName}
          width="100%"
        />
      ) : (
        <CustomSelectField
          disabled={isDisabled}
          name="instrumentId"
          value={formInputs.instrumentId}
          onChange={handleFormInputsChange}
          width="100%"
        >
          <option value="" disabled>
            Instrument
          </option>
          {instruments?.map(({ id, name }, i) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </CustomSelectField>
      )}
    </>
  );
};

export default InstrumentSelect;
