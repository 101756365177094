import React from "react";
import { DropdownOptionContainer } from "../../styled";

const DropdownOption = ({ option, toggleDropdown, feedItem }) => {
  const { title, value, icon: OptionIcon, onOptionClick } = option;
  const handleOptionClick = async () => {
    await onOptionClick(feedItem.id);
    toggleDropdown();
  };
  return (
    <DropdownOptionContainer onClick={handleOptionClick}>
      <span className="me-2">
        <OptionIcon />
      </span>
      <span>{title}</span>
    </DropdownOptionContainer>
  );
};

export default DropdownOption;
