const { CustomInput } = require("src/utils/shared/styled");
const { CustomLabel } = require("src/utils/styled");
const { RateItemContainer, CurrencyInputContainer } = require("../../styled");

const CustomInputWithLabel = ({
  label,
  title,
  type,
  rates,
  handleRatesValuesChange,
  disabled,
}) => {
  const rateValue = getRateObj({
    rates,
    title,
    type,
  })?.value;

  return (
    <RateItemContainer>
      <CustomLabel>{label}</CustomLabel>
      <CurrencyInputContainer>
        <CustomInput
          name={title}
          value={rateValue}
          onChange={(e) =>
            handleRatesValuesChange({
              title,
              type,
              value: e.target.value ? parseFloat(e.target.value) : null,
            })
          }
          disabledBgColor="#f6f6f6"
          disabled={disabled}
          type="number"
          width="100%"
        />
      </CurrencyInputContainer>
    </RateItemContainer>
  );
};
export default CustomInputWithLabel;

const getRateObj = ({ rates, title, type }) => {
  if (!rates?.length || !title || !type) return;

  const rateObj = rates.find(
    (rate) => rate.title === title && rate.type === type
  );
  return rateObj;
};
