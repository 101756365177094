import styled from "styled-components";

const FeedbacksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media screen and (min-width: 1300px) {
    flex-direction: row;
  }
`;

export { FeedbacksWrapper };
