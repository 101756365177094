import { useContext } from "react";
import emailjs from "@emailjs/browser";
import { deleteDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "src/components/Firebase";
import { toast } from "react-toastify";

const useApplications = () => {
  const firebase = useContext(FirebaseContext);

  const approveApplication = async (applicant, message) => {
    try {
      await emailjs.send(
        "service_m7j255t",
        "template_lb0du76",
        {
          subject: "Application Status Update",
          to_name: applicant?.name,
          from_name: "Artis Admin",
          to_email: applicant?.email,
          message:
            message ||
            `Your application status updated
            Current status: Approved`,
          attachmentSrc:
            "https://firebasestorage.googleapis.com/v0/b/artisconnect-a3c6e.appspot.com/o/image_2024-06-20_182658749.png?alt=media&token=3ef3653b-dd5e-4a98-8e28-feb19b137d24",
        },
        "l6-vMvPNxhUUxuyxh"
      );

      const applicantToUpdate = firebase.user(applicant?.id);

      await updateDoc(applicantToUpdate, {
        status: "Approved",
      });

      toast.success(`Application of ${applicant?.name} approved`);
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  const declineApplication = async (applicant, message) => {
    try {
      await emailjs.send(
        "service_m7j255t",
        "template_lb0du76",
        {
          subject: "Application Status Update",
          to_name: applicant?.name,
          from_name: "Artis School of Music",
          to_email: applicant?.email,
          message:
            message ||
            `Your application status updated
             Current status: Declined`,
          attachmentSrc:
            "https://firebasestorage.googleapis.com/v0/b/artisconnect-a3c6e.appspot.com/o/image_2024-06-20_182658749.png?alt=media&token=3ef3653b-dd5e-4a98-8e28-feb19b137d24",
        },
        "l6-vMvPNxhUUxuyxh"
      );

      const applicantToUpdate = firebase.user(applicant?.id);

      await updateDoc(applicantToUpdate, {
        status: "Declined",
      });

      toast.info(
        `Application of ${applicant?.name || "Unnamed applicant"} declined`
      );
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  const sendEmail = async (formSelector) => {
    try {
      await emailjs.sendForm(
        "service_m7j255t",
        "template_lb0du76",
        formSelector,
        "l6-vMvPNxhUUxuyxh"
      );

      toast.success("Email sent successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  const deleteApplication = async (applicant) => {
    try {
      await firebase.deleteUserWithAdminSDK({ userId: applicant?.id });
      await deleteDoc(firebase.user(applicant?.id));

      toast.info("Application deleted");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  const archiveApplicationToggle = async (applicantId, archived) => {
    const applicantToUpdate = firebase.user(applicantId);

    await updateDoc(applicantToUpdate, {
      archived,
    });
  };

  return {
    approveApplication,
    declineApplication,
    sendEmail,
    deleteApplication,
    archiveApplicationToggle,
  };
};

export default useApplications;
