import React, { useRef } from "react";
import "./style.css";
import homeImage1 from "../../../../assets/images/LandingPagePhotos/Build1.webp";
import homeImage1Big from "../../../../assets/images/LandingPagePhotos/Build1big.webp";

import homeImage2 from "../../../../assets/images/LandingPagePhotos/Building2.webp";
import homeImage2Big from "../../../../assets/images/LandingPagePhotos/Building2big.webp";
import homeImage3 from "../../../../assets/images/LandingPagePhotos/Building3.webp";
import homeImage3Big from "../../../../assets/images/LandingPagePhotos/Building3big.webp";
import homeImage4 from "../../../../assets/images/LandingPagePhotos/Building4.webp";
import homeImage4Big from "../../../../assets/images/LandingPagePhotos/Building4big.webp";
import flyer1 from "src/assets/images/LandingPagePhotos/Flyer_1.png";
import flyer2 from "src/assets/images/LandingPagePhotos/Flyer_2.png";
import styled from "styled-components";
import { PrimaryButtonWithBlink } from "src/utils/shared/shared";
import { Image } from "antd";

const ImageTitle = styled.p`
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: #fff;
  background: #00000042;
  font-size: 12px;
  z-index: 3;
  padding: 6px;

  @media screen and (min-width: 576px) {
    font-size: 14px;
  }

  @media screen and (min-width: 768px) {
    font-size: 16px;
    padding: 10px;
  }

  @media screen and (min-width: 1080px) {
    font-size: 24px;
    padding: 14px;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  max-height: 100vh;
`;

const StyledImage = styled(Image)`
  width: 100px !important;
  height: 100%;
  border-radius: 12px;

  @media screen and (min-width: 375px) {
    width: 120px !important;
  }

  @media screen and (min-width: 576px) {
    width: 160px !important;
  }

  @media screen and (min-width: 660px) {
    width: 220px !important;
  }

  @media screen and (min-width: 768px) {
    width: 280px !important;
  }

  @media screen and (min-width: 1080px) {
    width: 360px !important;
  }
`;

let imagesArray = [
  {
    small: homeImage1,
    big: homeImage1Big,
    title: "Sydney Opera House",
  },
  {
    small: homeImage2,
    big: homeImage2Big,
    title: "Kilden Performing Arts Centre",
  },
  {
    small: homeImage3,
    big: homeImage3Big,
    title: "Walt Disney Concert Hall (Los Angeles, California)",
  },
  {
    small: homeImage4,
    big: homeImage4Big,
    title: "Pilhahrmonie de Paris",
  },
];
imagesArray = imagesArray.sort(() => Math.random() - 0.5);
let render = 0;
console.log((render += 1));
function Gallery() {
  // references for the images and check boxes
  const galleryScroll = useRef();
  const image1Ref = useRef();
  const image2Ref = useRef();
  const image3Ref = useRef();
  const image4Ref = useRef();

  const checkRef1 = useRef();
  const checkRef2 = useRef();
  const checkRef3 = useRef();
  const checkRef4 = useRef();

  let scrollImage = (imageRef) => {
    const { offsetLeft } = imageRef.current;
    galleryScroll.current.scrollTo(offsetLeft, 0);
  };

  return (
    <>
      <figure className="home-gallery">
        <figure ref={galleryScroll} className="gallery-images">
          <ImageContainer ref={image1Ref}>
            <picture>
              <source media="(min-width: 1066px)" srcSet={imagesArray[0].big} />
              <img
                loading="lazy"
                alt="gallery image"
                src={imagesArray[0].small}
              />
              <ImageTitle>{imagesArray[0].title}</ImageTitle>
            </picture>
          </ImageContainer>
          <ImageContainer ref={image2Ref}>
            <picture alt="gallery image">
              <source media="(min-width: 1066px)" srcSet={imagesArray[1].big} />
              <img
                loading="lazy"
                alt="gallery image"
                src={imagesArray[1].small}
              />
              <ImageTitle>{imagesArray[1].title}</ImageTitle>
            </picture>
          </ImageContainer>

          <ImageContainer ref={image3Ref}>
            <picture alt="gallery image">
              <source media="(min-width: 1066px)" srcSet={imagesArray[2].big} />
              <img
                loading="lazy"
                alt="gallery image"
                src={imagesArray[2].small}
              />
              <ImageTitle>{imagesArray[2].title}</ImageTitle>
            </picture>
          </ImageContainer>

          <ImageContainer ref={image4Ref}>
            <picture alt="gallery image">
              <source media="(min-width: 1066px)" srcSet={imagesArray[3].big} />
              <img
                loading="lazy"
                alt="gallery image"
                src={imagesArray[3].small}
              />
              <ImageTitle>{imagesArray[3].title}</ImageTitle>
            </picture>
          </ImageContainer>
        </figure>

        <form>
          <input
            type="radio"
            defaultChecked
            name="mainImage"
            onClick={() => scrollImage(image1Ref)}
            ref={checkRef1}
          />
          <input
            type="radio"
            name="mainImage"
            onClick={() => scrollImage(image2Ref)}
            ref={checkRef2}
            value={image1Ref}
          />
          <input
            type="radio"
            name="mainImage"
            ref={checkRef3}
            onClick={() => scrollImage(image3Ref)}
          />
          <input
            type="radio"
            ref={checkRef4}
            name="mainImage"
            onClick={() => scrollImage(image4Ref)}
          />
        </form>
        {/* <div className="promotion">
          <div className="promotion-images">
            <Image.PreviewGroup>
              <StyledImage src={flyer1} alt="group class flyer" />
              <StyledImage src={flyer2} alt="group class flyer" />
            </Image.PreviewGroup>
          </div>
          <div className="promotion-contact">
            <h4>Book now</h4>
            <div className="promotion-buttons">
              <a href="tel:+16507320037">
                <PrimaryButtonWithBlink>650 732 0037</PrimaryButtonWithBlink>
              </a>
              <a href="info@artismymusic.com">
                <PrimaryButtonWithBlink>
                  info@artismymusic.com
                </PrimaryButtonWithBlink>
              </a>
            </div>
          </div>
        </div> */}
      </figure>
    </>
  );
}

export default Gallery;
