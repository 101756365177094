import React from "react";
import { absenceMakeUpOptions } from "src/constants/absenceTypes";
import { CustomInput, CustomSelectField } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import {
  CheckboxContainer,
  MakeUpOptionContainer,
  MakeUpTimeOptionsContainer,
} from "../../styled";

const MakeUpOptionsSection = ({ absenceRequestData }) => {
  const {
    initialData,
    selectedMakeUpOption,
    handleSelectedMakeUpOptionChange,
    makeUpOptionsData,
    handleMakeUpOptionsDataChange,
  } = absenceRequestData;
  const { locations } = initialData;

  const isMakeUpFieldDisabled =
    selectedMakeUpOption !== absenceMakeUpOptions.MAKEUP;
  return (
    <div>
      <h3 className="mb-4">Make Up Options</h3>
      <div>
        <MakeUpOptionContainer>
          <div className="d-flex justify-content-between mb-5">
            <h4>Make Up</h4>
            <CheckboxContainer>
              <input
                checked={selectedMakeUpOption === absenceMakeUpOptions.MAKEUP}
                onChange={() =>
                  handleSelectedMakeUpOptionChange(absenceMakeUpOptions.MAKEUP)
                }
                type="checkbox"
              />
            </CheckboxContainer>
          </div>
          <div>
            <div className="mb-4">
              <CustomLabel>Date:</CustomLabel>
              <CustomInput
                disabled={isMakeUpFieldDisabled}
                width="100%"
                type="date"
                name="date"
                value={makeUpOptionsData[absenceMakeUpOptions.MAKEUP].date}
                onChange={(e) =>
                  handleMakeUpOptionsDataChange(
                    absenceMakeUpOptions.MAKEUP,
                    e.target.name,
                    e.target.value
                  )
                }
              />
            </div>
            <div className="">
              <CustomLabel>Time:</CustomLabel>
              <MakeUpTimeOptionsContainer>
                <CustomInput
                  disabled={isMakeUpFieldDisabled}
                  type="time"
                  name="startTime"
                  value={
                    makeUpOptionsData[absenceMakeUpOptions.MAKEUP].startTime
                  }
                  onChange={(e) =>
                    handleMakeUpOptionsDataChange(
                      absenceMakeUpOptions.MAKEUP,
                      e.target.name,
                      e.target.value
                    )
                  }
                />
                <span className="text-center">TO:</span>
                <CustomInput
                  disabled={isMakeUpFieldDisabled}
                  type="time"
                  name="endTime"
                  value={makeUpOptionsData[absenceMakeUpOptions.MAKEUP].endTime}
                  onChange={(e) =>
                    handleMakeUpOptionsDataChange(
                      absenceMakeUpOptions.MAKEUP,
                      e.target.name,
                      e.target.value
                    )
                  }
                />
              </MakeUpTimeOptionsContainer>
            </div>
            <div style={{ marginTop: 20 }}>
              <CustomSelectField
                width="100%"
                disabled={isMakeUpFieldDisabled}
                name="location"
                value={makeUpOptionsData[absenceMakeUpOptions.MAKEUP].location}
                onChange={(e) =>
                  handleMakeUpOptionsDataChange(
                    absenceMakeUpOptions.MAKEUP,
                    e.target.name,
                    e.target.value
                  )
                }
              >
                <option disabled value="">
                  Location:
                </option>
                {locations?.map(({ name, id }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </CustomSelectField>
              <label className="mt-3">
                <input
                  disabled={isMakeUpFieldDisabled}
                  type="checkbox"
                  className="me-1"
                  name="isVirtualOnly"
                  checked={
                    makeUpOptionsData[absenceMakeUpOptions.MAKEUP].isVirtualOnly
                  }
                  onChange={(e) =>
                    handleMakeUpOptionsDataChange(
                      absenceMakeUpOptions.MAKEUP,
                      e.target.name,
                      e.target.checked
                    )
                  }
                />
                Virtual Only
              </label>
            </div>
          </div>
        </MakeUpOptionContainer>
        <MakeUpOptionContainer>
          <div className="d-flex justify-content-between mb-5">
            <h4>Substitute</h4>
            <CheckboxContainer>
              <input
                type="checkbox"
                checked={
                  selectedMakeUpOption === absenceMakeUpOptions.SUBSTITUTE
                }
                onChange={() =>
                  handleSelectedMakeUpOptionChange(
                    absenceMakeUpOptions.SUBSTITUTE
                  )
                }
              />
            </CheckboxContainer>
          </div>
        </MakeUpOptionContainer>
      </div>
    </div>
  );
};

export default MakeUpOptionsSection;
