import React from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { SecondaryText } from "src/components/common/NotificationsMenu/styled";
import { CustomTextarea } from "src/utils/shared/styled";

const EquipmentSection = ({
  handleProgramDataChange,
  programData,
  equipmentList = [],
}) => {
  const equipmentOptions = equipmentList
    .filter(({ isAvailable }) => isAvailable)
    .map((eq) => {
      const value = eq.id;
      const label = eq.name;
      return {
        label,
        value,
      };
    });

  const selectedOptionWithLabel = programData.equipmentIds.map((id) => {
    const equipment = equipmentList.find((eq) => eq.id === id);
    const label = equipment?.name;
    return { value: id, label };
  });
  const onOptionsChange = (selected = []) => {
    const idsValue = selected.map(({ value }) => value);
    handleProgramDataChange("equipmentIds", idsValue);
  };

  return (
    <div>
      <SecondaryText className="mb-2">Equipment (Optional)</SecondaryText>
      <CustomReactSelect
        disabledBackgroundColor="#f1f1f1"
        placeholder={"Select Equipment"}
        options={equipmentOptions}
        onChange={onOptionsChange}
        value={selectedOptionWithLabel}
        width="100%"
        wrapOptions={false}
        bgColor="#fff"
        margin="auto"
      />

      <div className="mt-5">
        <SecondaryText className="mb-2">
          Additional Equipment Request (Optional)
        </SecondaryText>
        <CustomTextarea
          width="100%"
          height="100px"
          value={programData.additionalEquipment}
          onChange={(e) =>
            handleProgramDataChange("additionalEquipment", e.target.value)
          }
        />
      </div>
    </div>
  );
};

export default EquipmentSection;
