import React from "react";
import {
  combineArrayToStr,
  getUpcomingGroupClassLesson,
} from "src/utils/helpers";
import moment from "moment";
import {
  InfoPair,
  InfoTitle,
  TextInfoContainer,
  InfoText,
  TeachersPicsContainer,
  ClassInfoContainer,
} from "../../../styled";

const ClassInfo = ({ groupClassObj, teachers }) => {
  const { classes, title } = groupClassObj;

  const upcomingClass = getUpcomingGroupClassLesson(classes);
  const { teachersIds, duration, startDate, locationId, isVirtual } =
    upcomingClass || {};

  const classTeachers =
    teachersIds?.map((teacherId) =>
      teachers?.find(({ id }) => id === teacherId)
    ) || [];
  const teachersNames = classTeachers.map(({ fullName }) => fullName);

  // if a teacher doesnt have img, it will be skipped
  const teachersImages = classTeachers
    .map(({ imageUrl }) => imageUrl)
    .filter((el) => el);

  const teachersNamesStr = combineArrayToStr(teachersNames);

  const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
  const formattedStartTime = moment(startDate).format("hh:mm A");

  return (
    <ClassInfoContainer>
      <TextInfoContainer>
        <InfoPair>
          <InfoTitle>Instructors:</InfoTitle>
          <InfoText>{teachersNamesStr}</InfoText>
        </InfoPair>
        <InfoPair>
          <InfoTitle>Upcoming Day:</InfoTitle>
          <InfoText>{formattedStartDate}</InfoText>
        </InfoPair>
        <InfoPair>
          <InfoTitle>Upcoming Time:</InfoTitle>
          <InfoText>{formattedStartTime}</InfoText>
        </InfoPair>
      </TextInfoContainer>
      <TeachersPicsContainer>
        {teachersImages.map((imageUrl, index) => (
          <img src={imageUrl} alt="teacherImage" key={index} />
        ))}
      </TeachersPicsContainer>
    </ClassInfoContainer>
  );
};

export default ClassInfo;
