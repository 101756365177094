import React from "react";
import { SectionContainer } from "../../styled";
import { groupClassPaymentStudentTypes } from "src/constants/groupClassPaymentsConstants";
import { getGroupClassPaymentOptionsTotal } from "src/utils/helpers";

const FreePayment = ({ freePaymentOptions }) => {
  return (
    <SectionContainer className="mt-3">
      <h6>Free</h6>
      <hr />
    </SectionContainer>
  );
};

export default FreePayment;
