import styled from "styled-components";
//width: 226px;
export const AttendanceBlockContainer = styled.div`
    
    .date-container {
        background-color: #681e460d;
        border: 1px solid #d5d5d5;
        padding: 7px 15px;
        border-radius: 15px;
    }
    &.student-block {
        width: unset;
        background-color:#AF2287;
        border-radius: 20px;
    }
    .text-container {
        width: 164px;
    }
    .button-container {
        width: 121px;
    }
`;
