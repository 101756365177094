import React from "react";
import { UserImage } from "../../styled";
import UnknownProfilePhoto from "src/assets/images/default-profile.jpg";
import { ModalButton } from "src/utils/shared/styled";
import { ModalButtonWithLoading } from "src/utils/shared/shared";

const AcceptanceSection = ({
  user,
  isInviteAccepted,
  setIsInviteAccepted,
  declineInvitation,
  acceptInvitation,
  loadingAction,
}) => {
  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ gap: 10 }}
      >
        <div>
          <UserImage src={user.imageUrl || UnknownProfilePhoto} />
        </div>
        <div>
          <h6>{user.fullName}</h6>
        </div>
      </div>
      <hr />
      <div className="mt-4 text-center">
        <label className="d-block">
          <input
            type="radio"
            className="me-2"
            checked={isInviteAccepted}
            onChange={(e) => setIsInviteAccepted(true)}
          />
          Yes, we will participate
        </label>
        <label className="mt-3 d-block">
          <input
            type="radio"
            className="me-2"
            checked={!isInviteAccepted}
            onChange={(e) => setIsInviteAccepted(false)}
          />
          No, we can't participate
        </label>
      </div>

      <div className="text-center mt-5">
        <ModalButtonWithLoading
          primary
          fontWeight="bold"
          onClick={isInviteAccepted ? acceptInvitation : declineInvitation}
          isLoading={loadingAction}
        >
          Submit
        </ModalButtonWithLoading>
      </div>
    </div>
  );
};

export default AcceptanceSection;
