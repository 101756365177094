import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { hasAdminCredentials, isTeacher } from "src/constants/UserRoleEnum";
import { concertProgramTypes } from "src/constants/concertEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { shouldTeacherSeeConcertSignup } from "src/utils/helpers";

const initialDataInitialValues = {
  concertSignups: [],
  signupsInvitations: [],
  users: [],
  signupUsersPLs: [],
  instruments: [],
  concert: undefined,
};
const useConcertSignupList = ({ user, concertId, onActionSuccess }) => {
  const {
    getConcertSignupsByConcertId,
    getUsersByIds,
    getPrivateLessonsByStudentsIds,
    getInstruments,
    getConcertInvitationsByIds,
    getConcertById,
    deleteConcertSignup,
    deleteConcertProgram,
    getConcertProgramsBySignupId,
  } = useFirebaseFns();
  const isTeacherUser = isTeacher(user.role);
  const isAdminUser = hasAdminCredentials(user.role);

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [currentSignupId, setCurrentSignupId] = useState("");
  const [loadingAction, setLoadingAction] = useState(false);

  async function deleteCurrentConcertSignup() {
    try {
      if (!currentSignupId) {
        throw new Error("Cant find current sign up id");
      }

      setLoadingAction(true);

      await deleteConcertSignup(currentSignupId);
      const signupPrograms = await getConcertProgramsBySignupId(
        currentSignupId
      );

      for (const program of signupPrograms) {
        await deleteConcertProgram(program.id);
      }

      toast.success("signup and program deleted successfully");
      onActionSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoadingInitialData(true);

        const [concertSignups, instruments] = await Promise.all([
          getConcertSignupsByConcertId(concertId),
          getInstruments(),
        ]);

        const signupsInvitationIds = [
          ...new Set(concertSignups.map(({ invitationId }) => invitationId)),
        ].filter((el) => el);
        const signupsUsersIds = [
          ...new Set(concertSignups.map(({ userId }) => userId)),
        ];
        const [signupsUsers, signupUsersPLs, signupsInvitations, concert] =
          await Promise.all([
            getUsersByIds(signupsUsersIds),
            getPrivateLessonsByStudentsIds(signupsUsersIds),
            getConcertInvitationsByIds(signupsInvitationIds),
            getConcertById(concertId),
          ]);

        const inviterUsersIds = [
          ...new Set(signupsInvitations.map(({ createdBy }) => createdBy)),
        ];
        const inviterUsers = await getUsersByIds(inviterUsersIds);

        setInitialData({
          concertSignups,
          users: [...signupsUsers, ...inviterUsers],
          signupsInvitations,
          signupUsersPLs,
          instruments,
          concert,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  const soloProgramSignups = useMemo(() => {
    return initialData.concertSignups.filter((signup) => {
      const isSolo = signup.programType === concertProgramTypes.SOLO_PROGRAM;
      if (isTeacherUser) {
        const isSameTeacher = shouldTeacherSeeConcertSignup({
          signup,
          pls: initialData.signupUsersPLs,
          teacherId: user.uid,
        });
        return isSolo && isSameTeacher;
      } else {
        return isSolo;
      }
    });
  }, [initialData]);

  const specialProgramSignups = useMemo(() => {
    return initialData.concertSignups.filter((signup) => {
      const isSpecial =
        signup.programType === concertProgramTypes.SPECIAL_PROGRAM;
      if (isTeacherUser) {
        const isSameTeacher = shouldTeacherSeeConcertSignup({
          signup,
          pls: initialData.signupUsersPLs,
          teacherId: user.uid,
        });
        return isSpecial && isSameTeacher;
      } else {
        return isSpecial;
      }
    });
  }, [initialData]);

  const currentSignup = useMemo(() => {
    return initialData.concertSignups.find(({ id }) => id === currentSignupId);
  }, [initialData, currentSignupId]);

  return {
    initialData,
    loadingInitialData,
    soloProgramSignups,
    specialProgramSignups,
    currentSignup,
    setCurrentSignupId,
    loadingAction,
    deleteCurrentConcertSignup,
  };
};

export default useConcertSignupList;
