import React, { useState } from "react";
import { toast } from "react-toastify";
import { UserRole } from "src/constants/UserRoleEnum";
import { eventsMap } from "src/constants/eventsEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { v4 as uuidv4 } from "uuid";

const usePauseTeacherDay = ({
  onSuccess = () => {},
  scheduleDayId,
  teacherId,
  user,
}) => {
  const {
    updateUserAvailableDay,
    getTeacherAvailableDays,
    createCalendarLabel,
  } = useFirebaseFns();

  const [pauseReason, setPauseReason] = useState("");
  const [loading, setLoading] = useState(false);

  async function pauseDay() {
    try {
      if (!scheduleDayId || !teacherId) {
        toast.warn("couldnt pause day");
        return;
      }
      if (!pauseReason) {
        toast.warn("please mention the pause reason");
        return;
      }

      setLoading(true);
      const teacherAvailableDays = await getTeacherAvailableDays(teacherId);
      const updatedTeacherDays = teacherAvailableDays?.availableDays?.map(
        (day) =>
          day.id === scheduleDayId
            ? {
                ...day,
                isPaused: true,
              }
            : day
      );

      const updateObj = {
        availableDays: updatedTeacherDays,
      };

      await updateUserAvailableDay(teacherId, updateObj);

      const labelDate = teacherAvailableDays?.availableDays.find(
        ({ id }) => id === scheduleDayId
      ).startDate;

      const labelObj = {
        createdAt: new Date(),
        userId: teacherId,
        userRole: UserRole.TEACHER,
        type: eventsMap.recurringCalendarLabel.code,

        timeline: [
          {
            id: uuidv4(),
            date: labelDate,
            title: `Pausing for new students`,
            note: pauseReason,
            requestedBy: user.uid,
            requestedAt: new Date(),
          },
        ],
      };
      await createCalendarLabel(labelObj);
      toast.success("Paused Successfully");
      onSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }
  return { pauseReason, setPauseReason, loading, pauseDay };
};

export default usePauseTeacherDay;
