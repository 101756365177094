import { useState } from "react";
import { useFeatureNotifications } from "./hooks";
import { toast } from "react-toastify";

import { Button, Divider, Input } from "antd";
import RouteContent from "src/components/common/RouteContent";
import ScreenshotsUpload from "./components/ScreenshotsUpload";
import RoleSelectModal from "./components/modals/RoleSelectModal";
import NotificationsHistory from "./components/NotificationsHistory";
import { ButtonsWrapper } from "../Guest/GuestProfile/GuestProfileSecondPage/styled";
import { RequiredInputSpan } from "src/utils/shared/styled";

const FeatureNotifications = () => {
  const {
    activateFeatureNotifications,
    handleScreenshotUpload,
    handleScreenshotDelete,
    handleDescriptionChange,
    handleTitleChange,
    descriptionValue,
    titleValue,
    isLoading,
    screenshots,
    deactivateFeatureNotifications,
  } = useFeatureNotifications();
  const [roleSelectModalOpen, setRoleSelectModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  const [rolesToUpdate, setRolesToUpdate] = useState([]);

  const handleRoleSelectModalOpen = (type) => {
    setCurrentModal(type);
    setRoleSelectModalOpen(true);
  };

  const handleConfirm = () => {
    if (currentModal === "activate" && (!titleValue || !descriptionValue))
      return toast.error("Please fill the Title and description fields");

    currentModal === "activate"
      ? activateFeatureNotifications(rolesToUpdate)
      : deactivateFeatureNotifications(rolesToUpdate);

    setRolesToUpdate([]);
  };

  return (
    <RouteContent title="Feature notifications">
      <h4>
        Title <RequiredInputSpan>*</RequiredInputSpan>
      </h4>
      <Input name="title" onChange={handleTitleChange} value={titleValue} />
      <Divider />
      <h4>
        Description <RequiredInputSpan>*</RequiredInputSpan>
      </h4>
      <Input.TextArea
        style={{ minHeight: 200 }}
        name="description"
        onChange={handleDescriptionChange}
        value={descriptionValue}
      />
      <Divider />
      <h4>Screenshots</h4>
      <ScreenshotsUpload
        request={handleScreenshotUpload}
        screenshots={screenshots}
        onDelete={handleScreenshotDelete}
      />
      <ButtonsWrapper>
        <Button
          onClick={() => handleRoleSelectModalOpen("activate")}
          type="primary"
          title="Confirm"
          loading={isLoading}
        >
          Confirm and activate
        </Button>
        <Button
          onClick={() => handleRoleSelectModalOpen("deactivate")}
          type="primary"
          title="Deactivate"
          loading={isLoading}
        >
          Deactivate notifications
        </Button>
      </ButtonsWrapper>
      <Divider />
      <h4 style={{ marginTop: 48 }}>Notifications history</h4>
      <NotificationsHistory />
      <RoleSelectModal
        isOpen={roleSelectModalOpen}
        toggle={() => setRoleSelectModalOpen((prevState) => !prevState)}
        value={rolesToUpdate}
        onChange={setRolesToUpdate}
        onConfirm={handleConfirm}
      />
    </RouteContent>
  );
};

export default FeatureNotifications;
