import { useState } from "react";
import { useNavigate } from "react-router";
import { useFeedbacks } from "./hooks";

import RouteContent from "src/components/common/RouteContent";
import FeedbacksList from "./components/FeedbacksList";
import ActiveFeedback from "./components/ActiveFeedback";
import { FeedbacksWrapper } from "./styled";
import FeedbackStatus from "./components/FeedbackStatus";
import { ButtonsWrapper } from "../Guest/GuestProfile/GuestProfileSecondPage/styled";
import { PrimaryButton } from "src/utils/shared/styled";

const Feedbacks = () => {
  const [sendEmailChecked, setSendEmailChecked] = useState(false);

  const {
    feedbacks,
    isLoading,
    archiveFeedbackToggle,
    deleteFeedbackFromList,
    changeFeedbackStatus,
    activeFeedback,
    setActiveFeedback,
  } = useFeedbacks();
  const navigate = useNavigate();

  const handleStatusChange = async (feedback, status) => {
    await changeFeedbackStatus(feedback, status, sendEmailChecked);
  };

  return (
    <RouteContent title="Feedbacks">
      <FeedbacksWrapper>
        <FeedbacksList
          feedbacks={feedbacks}
          onSelect={setActiveFeedback}
          activeFeedback={activeFeedback}
          isLoading={isLoading}
        />
        <ActiveFeedback
          feedback={activeFeedback}
          onArchive={archiveFeedbackToggle}
          onDelete={deleteFeedbackFromList}
          isLoading={isLoading}
        />
        <FeedbackStatus
          feedback={activeFeedback}
          onChange={handleStatusChange}
          isLoading={isLoading}
          onSendEmailChange={setSendEmailChecked}
        />
      </FeedbacksWrapper>
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/feedbacks/archive")}
        >
          Feedbacks archive
        </PrimaryButton>
      </ButtonsWrapper>
    </RouteContent>
  );
};

export default Feedbacks;
