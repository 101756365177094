import styled from "styled-components";

export const ActionIconContainer = styled.div`
  & svg {
    width: 30px;
    height: 30px;
    color: #681e46;
    cursor: pointer;
  }
`;
