import { setColorByRole, statusColorsEnum } from "../../utils";
import { Badge, List } from "antd";
import { ItemBadge, StyledList, StyledListItem } from "./styled";

const FeedbacksList = ({ feedbacks, onSelect, activeFeedback, isLoading }) => {
  return (
    <StyledList
      bordered
      loading={isLoading}
      dataSource={feedbacks}
      renderItem={(item, index) => (
        <Badge.Ribbon
          text={item?.status}
          placement="end"
          color={statusColorsEnum[item?.status]}
        >
          <StyledListItem
            onClick={() => onSelect(item)}
            active={activeFeedback?.id === item?.id ? "true" : undefined}
          >
            <List.Item.Meta
              avatar={
                <ItemBadge bgcolor={setColorByRole(item?.createdBy?.role)}>
                  <span>{item?.createdBy?.role[0]}</span>
                  <span>{index + 1}</span>
                </ItemBadge>
              }
              title={item?.createdBy?.name}
              description={item?.message}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            />
          </StyledListItem>
        </Badge.Ribbon>
      )}
    />
  );
};

export default FeedbacksList;
