/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Ellipse } from "@styled-icons/ionicons-outline/Ellipse";
import { SubmitSpinner } from "../../../common";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { compose } from "recompose";
import LessonsInfo from "./LessonsInfo";
import { GridItem } from "./styled";
import { WeatherIconMap } from "src/constants/weatherData";
import { Clouds } from "@styled-icons/bootstrap/Clouds";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { updatedMomentNow } from "src/utils/helpers";

const OuterContainer = styled.div`
  background-color: #681e46;
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
`;
const InnerContainer = styled.div`
  background-color: #832357;
  border-radius: 15px;
`;
const CalenderContainer = styled.div``;
const DateSection = styled.div`
  background-color: white;
  border-radius: 15px;
  height: 80px;
  width: 75px;
  position: relative;
`;
const ArrRight = styled.div`
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 24px solid white;
  position: absolute;
  right: -17px;
  border-radius: 9px;
`;
const WeatherSection = styled.div``;
const TimeSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 0;
`;
const Elipse = styled(Ellipse)`
  width: 8px;
  height: 10px;
  margin-top: -15px;
  margin-right: -5px;
`;
function RightStudentHomeItems({ user }) {
  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.SM;

  const [CalenderValue, setCalenderValue] = useState(new Date());
  const [loadingWeather, setLoadingWeather] = useState(false);
  const month = updatedMomentNow().format("MMM");
  const day = updatedMomentNow().format("DD");
  const hour = updatedMomentNow().format("HH:mm");
  const APiKey = process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY;
  const [Coords, setCoords] = useState({
    Getlatitude: "",
    Getlongitude: "",
  });
  const [Weather, setWeather] = useState({
    Temp: "",
    TempText: "",
  });
  const getLocation = () => {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition((position) => {
        setCoords({
          Getlatitude: position.coords.latitude,
          Getlongitude: position.coords.longitude,
        });
      });
    }
  };
  useEffect(() => getLocation(), []);
  useEffect(async () => {
    setLoadingWeather(true);
    try {
      if (Coords.Getlatitude && Coords.Getlongitude) {
        const WeatherFetch = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?&lat=${Coords.Getlatitude}&lon=${Coords.Getlongitude}&appid=${APiKey}&units=imperial`
        ).catch((err) => console.log(err));
        const WeatherData = await WeatherFetch.json();

        setWeather({
          Temp: WeatherData.main?.temp,
          TempText: WeatherData.weather[0]?.main,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingWeather(false);
    }
  }, [Coords]);

  const Temp = Math.floor(Weather.Temp);
  const WeathersSec = (
    <WeatherSection className="d-flex  align-items-center justify-content-center text-white">
      {loadingWeather ? (
        <SubmitSpinner
          style={{ width: "2rem", height: "2rem", color: "#fff" }}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center ViewWeather"
          style={{ gap: 10 }}
        >
          {isDesktopScreen
            ? WeatherIconMap[Weather.TempText] || (
                <Clouds width={40} height={40} />
              )
            : null}
          <h4 style={{ textTransform: "capitalize" }}>
            <p className="m-0" style={{ fontSize: 12, paddingBottom: 5 }}>
              {Weather.TempText}
            </p>
            {Temp} <Elipse /> F
          </h4>
        </div>
      )}
    </WeatherSection>
  );
  return (
    <>
      <GridItem gridArea="redbar">
        <OuterContainer>
          <InnerContainer className="d-flex justify-content-between">
            <DateSection className="d-flex align-items-center justify-content-center">
              <div className="ViewDate text-center">
                <h4
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    paddingBottom: 3,
                  }}
                >
                  {month}
                </h4>
                <h4 style={{ fontWeight: 500 }}>{day}</h4>
              </div>
              <ArrRight />
            </DateSection>
            <>
              {Coords.Getlatitude && Coords.Getlongitude ? (
                WeathersSec
              ) : (
                <span
                  className="w-75 text-center d-flex align-items-center justify-content-center text-white p-3"
                  style={{ fontSize: 14 }}
                >
                  Allow location access to see the weather
                </span>
              )}
            </>
            <TimeSection className="text-white ViewTime">
              <h4>{hour}</h4>
            </TimeSection>
          </InnerContainer>
        </OuterContainer>
      </GridItem>
      <GridItem gridArea="lessons">
        <LessonsInfo user={user} />
      </GridItem>
      {/* <GridItem gridArea="calendar">
        <CalenderContainer>
          <Calendar
            calendarType="US"
            onChange={setCalenderValue}
            value={CalenderValue}
          />
        </CalenderContainer>
      </GridItem> */}
    </>
  );
}

export default RightStudentHomeItems;
