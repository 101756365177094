import React, { useContext } from "react";
import SingleTab from "./SingleTab";
import { TABS } from "../common/constants";
import { ActiveTabContext } from "../common/libraryContext";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import MobileTab from "./MobileTab";
import styled from "styled-components";

const TabsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  overflow: auto;

  @media (max-width: ${ScreenSize.LG}px) {
    justify-content: space-between;
  }
`;

const TeacherLibraryTabs = () => {
  const { activeTab, setActiveTab, isTeacher } = useContext(ActiveTabContext);

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;

  return (
    <TabsContainer>
      {(isTeacher ? TABS : TABS.slice(0, TABS.length - 1)).map(
        ({ title, key, IconComponent }, index) =>
          isDesktopScreen ? (
            <SingleTab
              key={index}
              className={"btn"}
              isActive={key === activeTab}
              onClick={() => setActiveTab(key)}
            >
              {title}
            </SingleTab>
          ) : (
            <MobileTab
              key={index}
              title={title}
              onClick={() => setActiveTab(key)}
              isActive={key === activeTab}
              IconComponent={IconComponent}
            />
          )
      )}
    </TabsContainer>
  );
};
export default TeacherLibraryTabs;
