export const absenceModalMap = {
  absencePolicy: {
    name: "absencePolicy",
    title: "Cancelation Policy",
  },
  absenceConfirmation: {
    name: "absenceConfirmation",
    title: "Absence Is Confirmed",
  },
};
