import React from "react";
import { InputContainer, SectionContainer } from "../../styled";
import LabeledRadioInput from "../common/LabeledRadioInput";
import {
  concertPaymentOptions,
  concertPaymentRates,
} from "src/constants/concertPaymentConstants";
import { CustomCheckboxContainer, CustomLabel } from "src/utils/shared/styled";
import LabeledInput from "../common/LabeledInput";
import { CustomInputWithCurrency } from "src/utils/shared/shared";
import { PaymentGroupContainer } from "./styled";
import { Plus } from "styled-icons/evaicons-solid";
import { DeleteOff } from "styled-icons/fluentui-system-filled";
import RateItem from "./components/RateItem";
import CustomRateItem from "./components/CustomRateItem";

const PaymentPage = ({ hookData }) => {
  const {
    handlePaymentDataChange,
    paymentData,
    onPaymentRateChange,
    onPaymentRateOptionChange,
    addNewCustomPaymentRow,
    handleDeleteCustomPaymentRow,
    handleCustomPaymentChange,
  } = hookData;

  return (
    <div>
      <h5 className="mb-3">Payment</h5>
      <SectionContainer>
        <div>
          <LabeledRadioInput
            label="Option"
            options={[
              {
                title: "Free",
                value: concertPaymentOptions.FREE,
              },
              {
                title: "Fee Required",
                value: concertPaymentOptions.PAID,
              },
            ]}
            onChange={(currVal) =>
              handlePaymentDataChange("paymentOption", currVal)
            }
            value={paymentData.paymentOption}
          />
        </div>
        <hr />
        {paymentData.paymentOption === concertPaymentOptions.PAID && (
          <div className="mt-5 ">
            <div>
              {paymentData.paymentRates.map((rate) => {
                return (
                  <RateItem
                    key={rate.name}
                    rate={rate}
                    onPaymentRateChange={onPaymentRateChange}
                    onPaymentRateOptionChange={onPaymentRateOptionChange}
                  />
                );
              })}
            </div>
            <div className="mt-4">
              <h5 className="mb-3">
                Custom Rates
                <Plus
                  onClick={() => addNewCustomPaymentRow()}
                  style={{ cursor: "pointer", marginLeft: 20, color: "#000" }}
                  width="25px"
                  height="25px"
                />
              </h5>
              {paymentData.customRates.map((rate, i) => {
                return (
                  <CustomRateItem
                    key={rate.id}
                    i={i}
                    rate={rate}
                    handleCustomPaymentChange={handleCustomPaymentChange}
                    handleDeleteCustomPaymentRow={handleDeleteCustomPaymentRow}
                    customRates={paymentData.customRates}
                  />
                );
              })}
            </div>
          </div>
        )}
      </SectionContainer>
    </div>
  );
};

export default PaymentPage;
