import {
  addDoc,
  arrayUnion,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { parseFirebaseDoc } from "../../../../../../../utils/getFirebaseDoc";
import { FirebaseContext } from "../../../../../../Firebase";
import { toast } from "react-toastify";
import { eventsMap } from "../../../../../../../constants/eventsEnum";
import { scheduleTypes } from "../../../../../../../constants/scheduleTypes";
import { UserRole } from "../../../../../../../constants/UserRoleEnum";
import moment from "moment";
import useFirebaseFns from "./useFirebaseFns";

const useAppointmentData = (teacherId) => {
  const firebase = useContext(FirebaseContext);

  const { getUserActivities, getAbsences } = useFirebaseFns();

  const [initialData, setInitialData] = useState({
    userActivities: [],
    absences: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(true);

  const [loadingAppointmentCreation, setLoadingAppointmentCreation] =
    useState(false);

  // creates an appointment
  const createAppointmentAndActivity = async (appointmentObj) => {
    try {
      if (!appointmentObj) throw { message: "Appointment object is missing" };
      setLoadingAppointmentCreation(true);
      const appointmentRes = await addDoc(
        firebase.appointments(),
        appointmentObj
      );
      if (appointmentRes.id) {
        const teacherUserActivityObj = {
          createdAt: new Date(),
          start_time: appointmentObj.startDate,
          end_time: appointmentObj.endDate,
          location: appointmentObj.locationId,
          type: eventsMap.appointment.code,
          userId: appointmentObj.teacherId,
          scheduleType: scheduleTypes.other.code,
          userRole: UserRole.TEACHER,
          isActive: true,
        };

        const activityRes = setDoc(
          firebase.userActivity(appointmentRes.id),
          teacherUserActivityObj
        );
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAppointmentCreation(false);
    }
  };

  const createLabel = async (labelObj) => {
    try {
      if (!labelObj) throw { message: "Label object is missing" };

      setLoadingAppointmentCreation(true);
      const res = await addDoc(firebase.calendarLabels(), labelObj);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAppointmentCreation(false);
    }
  };

  // Fetching Initial Data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [userActivities, absences] = await Promise.all([
          getUserActivities(teacherId),
          getAbsences(),
        ]);

        setInitialData((oldVal) => ({ ...oldVal, userActivities, absences }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  return {
    initialData,
    loadingInitialData,
    loadingAppointmentCreation,
    createAppointmentAndActivity,
    createLabel,
  };
};

export default useAppointmentData;
