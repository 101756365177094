import moment from "moment";
import {
  isPerLessonAbsence,
  isTeacherAbsence,
} from "src/constants/absenceTypes";
import {
  getFullDateFromDateAndTimeInputs,
  getPrivateLessonInfoOnSpecificDate,
  getStartAndEndDatesFromTimeInputs,
  getTimeDiffInMins,
  isActivityOverlappingWithSingleTimeEvent,
  mapDurationOrAllDayAbsenceToActivity,
  prepareTeacherActivities,
  updatedMomentNow,
} from "src/utils/helpers";

export const getNewMakeupLessons = (editedMakeups) => {
  const newMakeupLessons = editedMakeups.filter((lsn) => lsn.isNew);
  if (newMakeupLessons.length) {
    return { areNewLessonsDetected: true, newMakeupLessons };
  } else {
    return { areNewLessonsDetected: false, newMakeupLessons: [] };
  }
};

export const getEditedMakeupLessons = (editedMakeups) => {
  const editedMakeupLessons = editedMakeups.filter((lsn) => lsn.isEdited);
  if (editedMakeupLessons.length) {
    return { areEditedLessonsDetected: true, editedMakeupLessons };
  } else {
    return { areEditedLessonsDetected: false, editedMakeupLessons: [] };
  }
};

export const getDeletedMakeupLessons = (initialMakeups, editedMakeups) => {
  const editedMakeupsIds = editedMakeups.map((lsn) => lsn.id);
  const deletedMakeupLessons = initialMakeups.filter(
    (lsn) => !editedMakeupsIds.includes(lsn.id)
  );
  if (deletedMakeupLessons.length) {
    return { areDeletedLessonsDetected: true, deletedMakeupLessons };
  } else {
    return { areDeletedLessonsDetected: false, deletedMakeupLessons: [] };
  }
};

export const getEditedAbsences = (editedAbsencesList) => {
  const editedAbsences = editedAbsencesList.filter(
    (absence) => absence.isEdited
  );
  if (editedAbsences.length) {
    return { areEditedAbsencesDetected: true, editedAbsences };
  } else {
    return { areEditedAbsencesDetected: false, editedAbsences: [] };
  }
};

export const checkIfMakeUpsAreShort = (
  absencesList,
  makeupsList,
  minDurationPerMakeup = 15
) => {
  const absencesWithMakeUps = absencesList.map((absence) => ({
    ...absence,
    absenceMakeUps: makeupsList.filter(
      (makeup) => makeup.forAbsenceId === absence.id
    ),
  }));

  let isShortMakeupDetected = false;
  loop1: for (let absence of absencesWithMakeUps) {
    const { absenceMakeUps } = absence;
    let absenceMakeUpDuration = 0;

    loop2: for (let makeUp of absenceMakeUps) {
      const { startDate: makeupStartDate, endDate: makeupEndDate } =
        getStartAndEndDatesFromTimeInputs(
          moment(makeUp.startDate).toDate(),
          makeUp.startTime,
          makeUp.endTime
        );
      const makeUpLength = getTimeDiffInMins(makeupStartDate, makeupEndDate);
      absenceMakeUpDuration += parseInt(makeUpLength);
    }
    if (
      absenceMakeUpDuration <= minDurationPerMakeup &&
      absenceMakeUps.length
    ) {
      isShortMakeupDetected = true;
      break loop1;
    }
  }

  return isShortMakeupDetected;
};

export const formatMakeupLessons = (makeupLessons) => {
  return makeupLessons?.map((lesson) => ({
    ...lesson,
    startDate: moment(lesson.date.startDate).format("YYYY-MM-DD"),
    startTime: moment(lesson.date.startDate).format("HH:mm"),
    endTime: moment(lesson.date.endDate).format("HH:mm"),
  }));
};
// Checks if the makeupLessons array is valid (no empty values)
export const checkIfCompleteMakeupLessons = (
  makeupLessons = [],
  validationKeys = []
) => {
  let isValid = true;
  makeupLessons.forEach((lsn) => {
    Object.keys(lsn).forEach((key) => {
      if (validationKeys.includes(key) && !lsn[key]) {
        isValid = false;
      }
    });
  });
  return isValid;
};
export const checkIfValidMakeUpDurations = (
  absencesList,
  makeupsList,
  privateLesson
) => {
  const absencesWithMakeUps = absencesList.map((absence) => ({
    ...absence,
    absenceMakeUps: makeupsList.filter(
      (makeup) => makeup.forAbsenceId === absence.id
    ),
  }));

  let validObj = { isValid: true };
  loop1: for (let absence of absencesWithMakeUps) {
    const absenceDate = absence.date || absence.startDate;
    const { duration: lessonLength } = getPrivateLessonInfoOnSpecificDate({
      privateLesson,
      date: absenceDate,
      withTimelineApproximation: true,
    });

    const { absenceMakeUps } = absence;
    let absenceMakeUpDuration = 0;

    loop2: for (let makeUp of absenceMakeUps) {
      const { startDate: makeupStartDate, endDate: makeupEndDate } =
        getStartAndEndDatesFromTimeInputs(
          moment(makeUp.startDate).toDate(),
          makeUp.startTime,
          makeUp.endTime
        );
      const makeUpLength = getTimeDiffInMins(makeupStartDate, makeupEndDate);
      absenceMakeUpDuration += parseInt(makeUpLength);
    }
    if (absenceMakeUpDuration > lessonLength) {
      validObj = {
        isValid: false,
      };
      break loop1;
    }
  }

  return validObj;
};
export const checkOverlappingActivities = (
  makeupTeachersIds = [],
  teachersActivities = [],
  absences = [],
  makeupLessonsList = []
) => {
  absences = absences || [];

  // adding the teacher's makeup lessons along with his activities
  const teachersActivitiesAndMakeUps = makeupTeachersIds.map((teacherId) => {
    const teacherAllDayAndDurationTAs = absences?.filter((absence) => {
      const isTA = isTeacherAbsence(absence.absenceType);
      const isSameTeacher = absence.teacherId === teacherId;
      const isPerLesson = isPerLessonAbsence(absence.absenceBehaviour);
      return isTA && isSameTeacher && !isPerLesson;
    });
    const absencesActivities = teacherAllDayAndDurationTAs?.map((absence) =>
      mapDurationOrAllDayAbsenceToActivity(absence)
    );
    return {
      teacherId,
      teacherActivities: teachersActivities.filter(
        ({ userId, usersIds }) =>
          userId === teacherId || usersIds?.includes(teacherId)
      ),
      absencesActivities,
      // adding the makeup list to the teacher
      teacherMakeUpsList: makeupLessonsList.filter(
        (makeupLesson) => makeupLesson.teacherId === teacherId
      ),
    };
  });
  let validationObj = { isOverlapping: false };
  loop1: for (let teacherInfo of teachersActivitiesAndMakeUps) {
    const {
      teacherMakeUpsList,
      teacherActivities,
      absencesActivities,
      teacherId,
    } = teacherInfo;
    const preparedTeacherActivities = prepareTeacherActivities(
      [...teacherActivities, ...absencesActivities],
      teacherId
    );

    loop2: for (let makeupLesson of teacherMakeUpsList) {
      const makeupStart = getFullDateFromDateAndTimeInputs(
        makeupLesson.startDate,
        makeupLesson.startTime
      );

      const makeupEnd = getFullDateFromDateAndTimeInputs(
        makeupLesson.startDate,
        makeupLesson.endTime
      );

      const isPastMakeUp = moment(makeupStart).isBefore(
        updatedMomentNow(),
        "minutes"
      );
      if (isPastMakeUp) {
        continue loop2;
      }
      loop3: for (let activity of preparedTeacherActivities) {
        const isOverlapping = isActivityOverlappingWithSingleTimeEvent({
          eventStartDate: makeupStart,
          eventEndDate: makeupEnd,
          eventId: makeupLesson.id,
          teacherId,
          activity,
          absences,
        });
        if (isOverlapping) {
          validationObj = { isOverlapping: true, teacherId };
          break loop1;
        }
      }
    }
  }

  return validationObj;
};
