const handlePhoneChange = (value, setInputValue) => {
  value = (value.charAt(0) !== "(" && value.length) > 1 ? "(" + value : value;
  let num = 4;
  value =
    value.charAt(num) !== ")" && value.length > num
      ? value.substring(0, num) + ")" + value.substring(num, value.length)
      : value;
  num = 5;
  value =
    value.charAt(num) !== " " && value.length > num
      ? value.substring(0, num) + " " + value.substring(num, value.length)
      : value;
  num = 9;
  value =
    value.charAt(num) !== "-" && value.length > num
      ? value.substring(0, num) + "-" + value.substring(num, value.length)
      : value;

  if (setInputValue) {
    setInputValue(value);
  }
  return value;
};
export default handlePhoneChange;
