import moment from "moment";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { absenceMakeUpStatuses } from "src/constants/absenceMakeUpStatuses";
import { absenceTypes, isSDCAbsence } from "src/constants/absenceTypes";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { UserRole } from "src/constants/UserRoleEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import {
  getAbsenceMakeUpStatusData,
  getCurrentSchoolYear,
  getMakeupPeriodInRequestedDate,
  getPrivateLessonInfoOnSpecificDate,
  getRecurringEventTimelineObj,
  getTimeDiffInMins,
  updatedMomentNow,
} from "src/utils/helpers";

const filteredStudentsDataInitialValues = {
  absences: [], // students absences that has teacherId === userId
  makeupLessons: [],
};

const initialDataInitialValues = {
  teacherStudents: [],
  teacherPrivateLessons: [], // all pls
  schoolYears: [],
};

const useStudentsMakeUp = ({ user }) => {
  const userId = user.uid || user.id;
  const {
    getUsersByIds,
    getSchoolYears,
    getPrivateLessonsByTeacherId,
    getPrivateLessonsAbsences,
    getMakeUpLessonsByAbsencesIds,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  // Search Bar value
  const [filteredStudentsIds, setFilteredStudentsIds] = useState([]);
  const handleFilteredStudentsChange = (newStudentsIds) => {
    setFilteredStudentsIds(newStudentsIds);
  };
  // data for the filtered students (appears on the table)
  const [filteredStudentsData, setFilteredStudentsData] = useState(
    filteredStudentsDataInitialValues
  );
  const [loadingFilteredStudentsData, setLoadingFilteredStudentsData] =
    useState(false);

  const [currentStudentId, setCurrentStudentId] = useState(null);

  // displayed students on the table
  const filteredStudents = useMemo(() => {
    const filtered = initialData.teacherStudents.filter(({ id }) =>
      filteredStudentsIds.includes(id)
    );

    return filtered;
  }, [initialData, filteredStudentsIds]);

  // fetches initialData
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [schoolYears, teacherPrivateLessons] = await Promise.all([
          getSchoolYears(),
          getPrivateLessonsByTeacherId(userId),
        ]);

        const currentTimelineTeacherPLs = teacherPrivateLessons.filter(
          (lesson) => {
            const isPackageLsn = isPackagePrivateLesson(lesson.type);

            let isSameTeacher = false;

            // if package lsn, then we consider the teacher if they teach at least in 1 package item
            if (isPackageLsn) {
              isSameTeacher = lesson.teachersIds?.includes(userId);
            } else {
              // if regular lsn, then we consider the teacher only if they teach in the current timeline
              const currentTimeline = getRecurringEventTimelineObj({
                requestDate: updatedMomentNow().toDate(),
                timeline: lesson.timeline,
                startDateAccessor: "startDate",
                lastDateAccessor: "lastDate",
                withApproximation: true,
              });

              isSameTeacher = currentTimeline?.teacherId === userId;
            }

            return isSameTeacher;
          }
        );

        const teacherStudentsIds = [
          ...new Set(
            currentTimelineTeacherPLs.map(({ studentId }) => studentId)
          ),
        ];

        const teacherStudents = await getUsersByIds(teacherStudentsIds);

        setInitialData((oldVal) => ({
          teacherStudents,
          schoolYears,
          teacherPrivateLessons: currentTimelineTeacherPLs,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  // fetches the filtered students data
  useEffect(() => {
    if (!filteredStudents?.length) {
      //   clearTimeout(timer);
      setFilteredStudentsData(filteredStudentsDataInitialValues);
      return;
    }

    const fetchFilteredStudentsData = async () => {
      try {
        setLoadingFilteredStudentsData(true);

        const filteredStudentsIds = filteredStudents.map(({ id }) => id);

        const filteredStudentsPLs = initialData.teacherPrivateLessons.filter(
          ({ studentId }) => filteredStudentsIds.includes(studentId)
        );

        const plsIds = filteredStudentsPLs.map(({ id }) => id);
        const studentsAbsences = await getPrivateLessonsAbsences(plsIds);
        const uniqueAbsences = studentsAbsences.filter(
          (absence, index, selfArr) =>
            selfArr.findIndex((v) => v.id === absence.id) === index
        );
        const filteredStudentsAbsences = uniqueAbsences.filter(
          ({ teacherId }) => teacherId === userId
        );

        const absencesIds = filteredStudentsAbsences.map(({ id }) => id);
        const absencesMakeupLessons = await getMakeUpLessonsByAbsencesIds(
          absencesIds
        );

        setFilteredStudentsData((oldVal) => ({
          ...oldVal,
          absences: filteredStudentsAbsences,
          makeupLessons: absencesMakeupLessons,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingFilteredStudentsData(false);
      }
    };

    fetchFilteredStudentsData();

    // if (currentDisplayOption === displayOptions.SEARCH) {
    //   clearTimeout(timer);
    //   timer = setTimeout(async () => {
    //     fetchFilteredStudentsData();
    //   }, waitTime);
    // } else {
    //   clearTimeout(timer);
    //   fetchFilteredStudentsData();
    // }
  }, [filteredStudents, initialData]);

  const currentStudent = useMemo(() => {
    if (!currentStudentId) return;

    return initialData.teacherStudents.find(
      ({ id }) => id === currentStudentId
    );
  }, [initialData.teacherStudents, currentStudentId]);

  const filteredAbsencesWithAdditionalData = useMemo(() => {
    const filteredStudentsIds = filteredStudents.map(({ id }) => id);

    // gets the filtered student's lessons (excluding the lessons that are with a different teacher from the one in the filters only if filters are enabled)
    const filteredStudentsLessons = initialData.teacherPrivateLessons.filter(
      ({ studentId }) => {
        const isSameStudent = filteredStudentsIds.includes(studentId);

        return isSameStudent;
      }
    );

    const lessonsWithAbsences = filteredStudentsLessons.map((pl) => {
      const isPackageLsn = isPackagePrivateLesson(pl.type);
      const lessonAbsences =
        filteredStudentsData.absences?.filter(
          (absence) =>
            absence.lessonId === pl.id ||
            absence.affectedPrivateLessonsIds?.includes(pl.id)
        ) || [];

      // filters out the completed absences + adds extra data for table display
      const absencesWithExtraData = lessonAbsences.map((absence, i) => {
        // we get the student name from the lesson (not the studentId of the absence as it might not be  available)
        const studentName = initialData.teacherStudents.find(
          (student) => student.id === pl.studentId
        )?.fullName;

        const absenceTypeStr = Object.values(absenceTypes).find(
          (type) => type.code === absence.absenceType
        )?.abbr;
        const absenceDate = absence.date || absence.startDate;
        const formattedAbsenceDate = moment(absenceDate).format("MM/DD/YYYY");

        // the makeup lessons for this absence (filtered by using the same student and lesson)
        const absenceMakeUps = filteredStudentsData.makeupLessons.filter(
          (makeup) =>
            makeup.forAbsenceId === absence.id &&
            makeup.studentId === pl.studentId &&
            makeup.forLessonId === pl.id
        );

        const { duration: lessonDuration, locationId } =
          getPrivateLessonInfoOnSpecificDate({
            privateLesson: pl,
            date: absenceDate,
            withTimelineApproximation: true,
          });

        let makeupStatus;

        if (lessonDuration && locationId) {
          let makeupPeriodDeadline;
          if (isPackageLsn) {
            makeupPeriodDeadline = updatedMomentNow().add(5, "years");
          } else {
            const currentSchoolYear = getCurrentSchoolYear(
              initialData.schoolYears,
              locationId
            );
            const currentMakeupPeriod = getMakeupPeriodInRequestedDate(
              absenceDate,
              currentSchoolYear
            );
            makeupPeriodDeadline = currentMakeupPeriod?.deadline?.toDate
              ? currentMakeupPeriod.deadline.toDate()
              : undefined;
          }
          const { absenceMakeUpStatus } = getAbsenceMakeUpStatusData(
            absence,
            absenceMakeUps,
            lessonDuration,
            makeupPeriodDeadline
          );
          makeupStatus = absenceMakeUpStatus;
        }

        const absenceStatusObj = Object.values(absenceMakeUpStatuses).find(
          ({ code }) => code === makeupStatus
        );
        const makeupStatusStr = absenceStatusObj?.description2;
        return {
          ...absence,
          makeupStatus,
          makeupStatusStr,
          studentName,
          formattedAbsenceDate,
          absenceTypeStr,
        };
      });
      // filter out the absences that we can't schedule a make up for anymore
      const filteredAbsences = absencesWithExtraData.filter(
        ({ makeupStatus, absenceType }) => {
          const isSDC = isSDCAbsence(absenceType);

          const { expired, fullyMadeUpFor, fullyScheduled, noMakeUpDeadline } =
            absenceMakeUpStatuses;
          return (
            ![
              fullyMadeUpFor.code,
              fullyScheduled.code,
              expired.code,
              noMakeUpDeadline.code,
            ].includes(makeupStatus) && !isSDC
          );
        }
      );

      return {
        ...pl,
        lessonAbsences: filteredAbsences,
      };
    });

    return lessonsWithAbsences
      .map(({ lessonAbsences }) => lessonAbsences)
      .flat()
      .sort((a, b) => {
        const firstAbsenceDate = a.date || a.startDate;
        const secondAbsenceDate = b.date || b.startDate;

        return getTimeDiffInMins(secondAbsenceDate, firstAbsenceDate);
      });
  }, [initialData, filteredStudentsData]);

  return {
    initialData,
    filteredStudentsIds,
    handleFilteredStudentsChange,
    filteredStudents,
    filteredStudentsData,
    loadingInitialData,
    loadingFilteredStudentsData,
    currentStudent,
    filteredAbsencesWithAdditionalData,
  };
};

export default useStudentsMakeUp;
