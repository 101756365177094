import {
  groupClassPaymentExcutedAt,
  groupClassPaymentOptionsMap,
  groupClassPaymentStudentTypes,
  groupClassPaymentTypes,
  groupClassScheduledPaymentIntervals,
  scheduledPaymentTimesToRun,
} from "src/constants/groupClassPaymentsConstants";
import FreeForAllConcertSignupModalBody from "../FreeForAllConcertSignupModal/FreeForAllConcertSignupModalBody";

export const createGroupClassViews = {
  CLASS_INFO: "class_info",
  PAYMENT: "payment",
};
export const groupClassPaymentTypesOptions = {
  oneTime: {
    value: groupClassPaymentTypes.ONE_TIME,
    title: "One Time",
  },
  scheduled: {
    value: groupClassPaymentTypes.SCHEDULED,
    title: "Installments",
  },
  both: {
    value: groupClassPaymentTypes.BOTH,
    title: "Both",
  },
  FreeForAllConcertSignupModalBody: {
    value: groupClassPaymentTypes.FREE,
    title: "Free",
  },
};
export const groupClassIntervalsOptions = {
  weekly: {
    value: groupClassScheduledPaymentIntervals.WEEK,
    title: "Week",
  },
  monthly: {
    value: groupClassScheduledPaymentIntervals.MONTH,
    title: "Month",
  },
  yearly: {
    value: groupClassScheduledPaymentIntervals.YEAR,
    title: "Year",
  },
};
export const groupClassPaymentExcutedAtOptions = {
  weekly: {
    saturday: {
      title: "Saturdays",
      value: groupClassPaymentExcutedAt.weekly.SATURDAY,
    },
    sunday: {
      title: "Sundays",
      value: groupClassPaymentExcutedAt.weekly.SUNDAY,
    },
    monday: {
      title: "Mondays",
      value: groupClassPaymentExcutedAt.weekly.MONDAY,
    },
    tuesday: {
      title: "Tuesdays",
      value: groupClassPaymentExcutedAt.weekly.TUESDAY,
    },
    wednesday: {
      title: "Wednesdays",
      value: groupClassPaymentExcutedAt.weekly.WEDNESDAY,
    },
    thursday: {
      title: "Thursdays",
      value: groupClassPaymentExcutedAt.weekly.THURSDAY,
    },
    friday: {
      title: "Fridays",
      value: groupClassPaymentExcutedAt.weekly.FRIDAY,
    },
  },
  monthly: {
    start: {
      title: "First Of",
      value: groupClassPaymentExcutedAt.monthly.START,
    },
    end: {
      title: "End Of",
      value: groupClassPaymentExcutedAt.monthly.END,
    },
  },
  yearly: {
    start: {
      title: "Start Of",
      value: groupClassPaymentExcutedAt.yearly.START,
    },
    end: {
      title: "End Of",
      value: groupClassPaymentExcutedAt.yearly.END,
    },
  },
};
export const timesToRunOptions = {
  continous: {
    title: "Continously",
    value: scheduledPaymentTimesToRun.CONTINOUS,
  },
  ends: {
    title: "Number Amount",
    value: scheduledPaymentTimesToRun.ENDS,
  },
};
export const paymentAmountTypes = {
  CURRENT_STUDENT_DIRECT_AMOUNTS: "currentStudentDirectAmounts",
  CURRENT_STUDENT_SCHEDULED_AMOUNTS: "currentStudentScheduledAmounts",
  PUBLIC_DIRECT_AMOUNTS: "publicDirectAmounts",
  PUBLIC_SCHEDULED_AMOUNTS: "publicScheduledAmounts",
};
export const amountTypesWithOptions = {
  [paymentAmountTypes.CURRENT_STUDENT_DIRECT_AMOUNTS]: Object.values(
    groupClassPaymentOptionsMap
  ).filter(
    ({ forPaymentTypes, forStudentTypes }) =>
      forPaymentTypes.includes(groupClassPaymentTypes.ONE_TIME) &&
      forStudentTypes.includes(groupClassPaymentStudentTypes.CURRENT_STUDENT)
  ),
  [paymentAmountTypes.CURRENT_STUDENT_SCHEDULED_AMOUNTS]: Object.values(
    groupClassPaymentOptionsMap
  ).filter(
    ({ forPaymentTypes, forStudentTypes }) =>
      forPaymentTypes.includes(groupClassPaymentTypes.SCHEDULED) &&
      forStudentTypes.includes(groupClassPaymentStudentTypes.CURRENT_STUDENT)
  ),
  [paymentAmountTypes.PUBLIC_DIRECT_AMOUNTS]: Object.values(
    groupClassPaymentOptionsMap
  ).filter(
    ({ forPaymentTypes, forStudentTypes }) =>
      forPaymentTypes.includes(groupClassPaymentTypes.ONE_TIME) &&
      forStudentTypes.includes(groupClassPaymentStudentTypes.NEW_STUDENT)
  ),
  [paymentAmountTypes.PUBLIC_SCHEDULED_AMOUNTS]: Object.values(
    groupClassPaymentOptionsMap
  ).filter(
    ({ forPaymentTypes, forStudentTypes }) =>
      forPaymentTypes.includes(groupClassPaymentTypes.SCHEDULED) &&
      forStudentTypes.includes(groupClassPaymentStudentTypes.NEW_STUDENT)
  ),
};
