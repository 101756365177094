import styled from "styled-components";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

export const CustomModal = styled(Modal)`
  min-width: 800px;
  max-width: 800px;

  & .modal-content {
    border: none;
    border-radius: 30px 30px;
  }

  @media (max-width: ${ScreenSize.LG}px) {
    min-width: 95%;
    max-width: 95%;
  }
`;
export const CustomHeader = styled(ModalHeader)`
  border-radius: 30px 30px 0 0;
`;
export const CustomBody = styled(ModalBody)`
  margin-top: 20px;
  min-height: 200px;
`;
export const CloseIcon = styled(Close)`
  color: #707070;
  background-color: #eee;
  border-radius: 50%;
  width: 23px;
  cursor: pointer;
`;
