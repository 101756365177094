import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 900px;
  /* height: 230px; */
  border-radius: 15px;
  background-color: #ff9ed2;
  color: #fff;
  text-align: start;
  padding: 20px 15px 0 15px;
  position: absolute;
  z-index: 2000;
  left: -400px;
  top: 25px;
  font-size: 0.9rem;
`;
const StyledHeading = styled.h4`
  font-size: 1.3rem;
  margin-bottom: 30px;
  padding: 0;
`;
const StyledList = styled.ul`
  padding-left: 1rem;
`;
const StyledListItem = styled.li`
  margin-bottom: 20px;
`;
const MakeupSectionsExplanationMenu = () => {
  return (
    <Container>
      <StyledHeading>
        Make Up Sections are used to manage makeup/absence requests during the
        school year:
      </StyledHeading>
      <StyledList>
        <StyledListItem className="fw-normal">
          <span className="fw-bold">First Section:</span> Should start a couple
          of months before the school year start date and end 1 day before the
          school year start so we can manage absences/make ups that were created
          a couple of months before the current schoolyear start
        </StyledListItem>
        <StyledListItem className="fw-normal">
          <span className="fw-bold">Last Section:</span> Should start 1 day
          after the school year end date and end a couple of months after the
          school year end date so we can manage absences/make ups that were
          created a couple of months after the current schoolyear end
        </StyledListItem>
      </StyledList>
    </Container>
  );
};

export default MakeupSectionsExplanationMenu;
