// Teacher Dashboard Home
import React from "react";
import RouteContent from "../../../common/RouteContent";
import { RightTeacherHomeItems } from "./TeacherdashboardItems";
import "../Style/Style.css";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { GridContainer, GridItem } from "./styled";
import FeedItemsSection from "../../common/components/FeedItemsSection";
import LatestFeedItemSection from "../../common/components/LatestFeedItemSection";
import ScheduleReminder from "../ScheduleReminder";

function TeacherDashboard({ UserStore }) {
  return (
    <RouteContent title="Teacher Dashboard">
      <div className="mb-3">
        <LatestFeedItemSection />
      </div>
      <ScheduleReminder />
      <GridContainer>
        <GridItem
          gridArea="feed"
          className="Container"
          style={{ overflow: "auto" }}
        >
          <FeedItemsSection />
        </GridItem>
        <RightTeacherHomeItems />
      </GridContainer>
    </RouteContent>
  );
}

export default compose(inject("UserStore"), observer)(TeacherDashboard);
