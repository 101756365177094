import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
} from "reactstrap";
import { CustomDropdownMenu } from "./styled";

const DropdownMenuComponent = ({
  dropdownToggleComponent: DropdownToggleComponent,
  dropdownContent: DropdownContent,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  dropdownMenuTopValue = "0px",
  openAndCloseOnHover = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onMouseEnterHandler = () => {
    if (openAndCloseOnHover) setIsOpen(true);
    onMouseEnter();
  };

  const onMouseLeaveHandler = () => {
    if (openAndCloseOnHover) setIsOpen(false);

    onMouseLeave();
  };

  const dropdownToggleMarkup = () => {
    return <DropdownToggleComponent />;
  };

  const toggleDropdown = () => setIsOpen((previous) => !previous);

  return (
    <Dropdown
      onMouseOver={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      isOpen={isOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle tag="span">{dropdownToggleMarkup()}</DropdownToggle>
      <CustomDropdownMenu onClick={toggleDropdown} top={dropdownMenuTopValue}>
        <DropdownContent />
      </CustomDropdownMenu>
    </Dropdown>
  );
};

export default DropdownMenuComponent;
