import React, { useState } from "react";

export const useModal = (props = { isOpenByDefault: false }) => {
  const { isOpenByDefault } = props || {};

  const [isModalOpen, setIsModalOpen] = useState(
    isOpenByDefault ? true : false
  );
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen((oldVal) => !oldVal);

  return {
    isModalOpen,
    openModal,
    closeModal,
    toggleModal,
  };
};

export default useModal;
