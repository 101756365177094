import styled from "styled-components";

export const MenuContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const OptionContainer = styled.li`
  padding: 11px;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) => borderBottom || ""};

  &:hover {
    background-color: #bb337b;
    color: #fff;
  }
`;
