import styled from "styled-components";
import { ArrowBack } from "@styled-icons/evaicons-solid/ArrowBack";

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BackIcon = styled(ArrowBack)`
  width: 30px;
  /* height: 30px; */
  color: #000;
  cursor: pointer;
`;
export const AttachmentImageContainer = styled.div`
  width: 100%;

  height: 350px;
  text-align: center;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
`;
export const VideoContainer = styled.div`
  & .video {
    border-radius: 15px;
  }
`;
export const PDFContainer = styled.div`
  & .small .react-pdf__Page__canvas {
    width: 100% !important;
    height: unset !important;
    object-fit: cover;
  }
`;

export const BodyContainer = styled.div`
  background-color: #f7f7f7bd;
  padding: 20px;
  border-radius: 10px;
`;
