import React, { useState, useRef, useContext } from "react";
import navMenuIcon from "../../../../../assets/icons/navMenuIcon.svg";
import phoneNavMenuIcon from "../../../../../assets/icons/icon-menu.svg";
import store from "../../../../../stores/UserStore.js";
import { FirebaseContext } from "../../../../Firebase";
import "./style.css";
import { PrimaryButton } from "src/utils/shared/styled";
import { FcConferenceCall } from "react-icons/fc";
import { useNavigate } from "react-router";
import { Person } from "styled-icons/material";
// import ShopIcon from "./ShopIcon";

function SecondaryList() {
  const [menuOpen, setMenuOpen] = useState(false);
  let menuClass = menuOpen ? "active" : "";
  const listFocus = useRef();
  if (menuClass) {
  }
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setMenuOpen(false);
    }
  };
  const firebase = useContext(FirebaseContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="nav-buttons">
        {!store.isLoggedIn && (
          <div className="login-btn-wrapper">
            <PrimaryButton
              onClick={() => navigate("/signin")}
              title="Go to login page"
              backgroundColor="#681e46DD"
              width="100%"
              height="100%"
            >
              Community login <Person size={24} style={{ marginLeft: 10 }} />
            </PrimaryButton>
          </div>
        )}
        {/* <ShopIcon /> */}
        <button
          onClick={async () => {
            await setMenuOpen(!menuOpen);
            listFocus.current.focus();
          }}
          type="button"
          className={"home-nav-button "}
        >
          <picture>
            <source media="(min-width:1066px)" srcSet={navMenuIcon} />
            <img src={phoneNavMenuIcon} alt="icon" />
          </picture>
        </button>
      </div>

      <div
        id="home-nav-secondary-list"
        ref={listFocus}
        tabIndex="3"
        onBlur={handleBlur}
        className={"home-nav-secondary-list " + menuClass}
      >
        <a href="/privateinstructions">Private Instructions</a>
        <a href="">Group Classes</a>
        <a href="/our-team">
          <FcConferenceCall className="menuIcon" /> Our Team
        </a>
        <a href="/news">News </a>
        <a href="#">Blog </a>
        <a href="/contactus">Contact us </a>
        {store.isLoggedIn && (
          <>
            <a href="/dashboard">Dashboard</a>
            <a
              onClick={async () => {
                await firebase.signOut().catch((error) => {
                  alert("error");
                });
              }}
              href=""
            >
              Logout
            </a>
          </>
        )}
      </div>
    </>
  );
}

export default SecondaryList;
