import React from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";

const StudentsSection = ({ studentsMakeupData }) => {
  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.SM;

  const {
    initialData,
    handleFilteredStudentsChange,
    filteredStudents,
    loadingInitialData,
    loadingFilteredStudentsData,
  } = studentsMakeupData;
  const { teacherStudents } = initialData;

  // selected students that appear on the select options as selected
  const selectedStudentsWithLabel = filteredStudents.map(({ id, fullName }) => {
    const label = fullName;
    const value = id;
    return { value, label };
  });

  // select options
  const options = [
    { label: "All", value: "all" },
    ...teacherStudents.map((student) => {
      const value = student.id;
      const label = student.fullName;
      return {
        label,
        value,
      };
    }),
  ];

  // if "All" is selected, we select all students, else we only select the selected students
  const onOptionsChange = (selected = []) => {
    const isSelectAll = selected.find((option) => option.value === "all");

    if (isSelectAll) {
      const allStudents = options.filter(({ value }) => value !== "all");
      handleFilteredStudentsChange(allStudents.map(({ value }) => value));
    } else {
      handleFilteredStudentsChange(selected.map(({ value }) => value));
    }
  };

  return (
    <div>
      <CustomReactSelect
        isDisabled={loadingInitialData || loadingFilteredStudentsData}
        placeholder={"Select Students"}
        options={options}
        onChange={onOptionsChange}
        value={selectedStudentsWithLabel}
        wrapOptions={false}
        bgColor="#fff"
        width={isDesktopScreen ? "60%" : "90%"}
        margin="auto"
      />
    </div>
  );
};

export default StudentsSection;
