import React, { useMemo } from "react";
import { useState } from "react";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import useModal from "src/hooks/useModal";
import { getLessonPackageItemsInfo, injectUserStore } from "src/utils/helpers";
import { PrimaryButton } from "src/utils/shared/styled";
import AbsenceRequestModal from "../../../AbsenceRequestModal";
import CreatePackageItemModal from "../../../CreatePackageItemModal";
import MakeupModal from "../../../MakeupModal";
import ViewPackageLessonInfoModal from "../../../ViewPackageLessonInfoModal";
import useMakeupData from "../hooks/useMakeupData";
import { Container, RedBarContainer, RedBarText } from "../styled";
import AbsencesSection from "./AbsencesSection";
import AdminNoteSection from "./AdminNoteSection";

const PackageLessonInfo = ({ currentLesson, currentStudent, UserStore }) => {
  const user = UserStore.user;

  const { instruments } = useStudentInfoContext();
  const instrument = instruments.find(
    ({ id }) => id === currentLesson?.instrumentId
  );
  const makeupModalData = useModal();
  const packageLessonInfoModalData = useModal();
  const absenceRequestModalData = useModal();
  const scheduleLessonModalData = useModal();

  const makeUpData = useMakeupData({
    studentId: currentStudent?.id,
    privateLesson: currentLesson,
    user,
  });

  const setCurrentAbsenceAndOpenMakeupModal = (absenceId) => {
    makeUpData.setCurrentAbsenceId(absenceId);
    makeupModalData.openModal();
  };
  const redbarName = `${instrument?.name} | PACKAGE`;

  const { isRenewAvailable, lastSetInfo } = getLessonPackageItemsInfo(
    currentLesson.packageSets
  );

  const lastSetNumber = lastSetInfo?.set;
  const scheduleNewLessonData = {
    modalTitle: isRenewAvailable ? "Renew Package Lesson" : "Schedule a Lesson",
    setNumber: isRenewAvailable ? lastSetNumber + 1 : lastSetNumber,
  };

  const showAbsenceRequestBtn = !lastSetInfo.isPassedSetDeadline;

  return (
    <>
      <Container>
        {/* Red Bar */}
        <RedBarContainer>
          <RedBarText>{redbarName || "N/A"}</RedBarText>
        </RedBarContainer>
        <div className="w-100 p-4">
          <AdminNoteSection currentLesson={currentLesson} />

          <div className="mt-4 m-auto d-flex w-100 justify-content-evenly">
            {showAbsenceRequestBtn && (
              <div>
                <PrimaryButton onClick={absenceRequestModalData.openModal}>
                  Absence Request
                </PrimaryButton>
              </div>
            )}
            <div>
              <PrimaryButton onClick={scheduleLessonModalData.openModal}>
                {isRenewAvailable ? "Renew Package" : "Schedule A Lesson"}
              </PrimaryButton>
            </div>
            <div>
              <PrimaryButton onClick={packageLessonInfoModalData.openModal}>
                View Lesson Info
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Container>
      {/* <hr className="mt-4 mb-4" /> */}
      <AbsencesSection
        makeUpData={makeUpData}
        student={currentStudent}
        currentLesson={currentLesson}
        isPassedLastSetDeadline={lastSetInfo.isPassedSetDeadline}
        setCurrentAbsenceId={makeUpData.setCurrentAbsenceId}
        makeupModalData={makeupModalData}
      />

      {/* MODALS */}
      {scheduleLessonModalData.isModalOpen && currentLesson && (
        <CreatePackageItemModal
          modalData={scheduleLessonModalData}
          packageLessonId={currentLesson.id}
          instrumentId={currentLesson.instrumentId}
          setNumber={scheduleNewLessonData.setNumber}
          modalTitle={scheduleNewLessonData.modalTitle}
          initialLessonDuration={
            currentLesson.packageSets[0]?.setItems?.[0]?.duration
          }
          initialLessonLocationId={
            currentLesson.packageSets[0]?.setItems?.[0]?.locationId
          }
          isRenewal={isRenewAvailable}
        />
      )}
      {packageLessonInfoModalData.isModalOpen && currentLesson && (
        <ViewPackageLessonInfoModal
          currentLesson={currentLesson}
          modalData={packageLessonInfoModalData}
        />
      )}
      {absenceRequestModalData.isModalOpen && (
        <AbsenceRequestModal
          absenceRequestModalData={absenceRequestModalData}
          privateLesson={currentLesson}
          onAbsenceRequestSuccess={makeUpData.refreshData}
        />
      )}
      {makeupModalData.isModalOpen && makeUpData.currentAbsence && (
        <MakeupModal
          currentAbsence={makeUpData.currentAbsence}
          makeupModalData={makeupModalData}
          lesson={currentLesson}
          onSuccess={makeUpData.refreshData}
          // shortenTimeSlots
        />
      )}
    </>
  );
};

export default injectUserStore(PackageLessonInfo);
