import { GiGrandPiano } from "react-icons/gi";
import { GiViolin } from "react-icons/gi";
import { MdKeyboardVoice } from "react-icons/md";
import { LiaGuitarSolid } from "react-icons/lia";
import { GiFlute } from "react-icons/gi";
import { GiClarinet } from "react-icons/gi";
import { GiDrum } from "react-icons/gi";
import { GiMusicalScore } from "react-icons/gi";
import { GiMeditation } from "react-icons/gi";
import { BsMusicNoteList } from "react-icons/bs";
import { GiSaxophone } from "react-icons/gi";
import { PiGuitarDuotone } from "react-icons/pi";
import { InstrumentSpan } from "./styled";

const teacherInstrumentOptions = [
  {
    label: (
      <InstrumentSpan>
        <GiGrandPiano size={30} />
        Piano
      </InstrumentSpan>
    ),
    value: "piano",
  },
  {
    label: (
      <InstrumentSpan>
        <GiViolin size={30} />
        Violin
      </InstrumentSpan>
    ),
    value: "violin",
  },
  {
    label: (
      <InstrumentSpan>
        <MdKeyboardVoice size={24} />
        Voice
      </InstrumentSpan>
    ),
    value: "voice",
  },
  {
    label: (
      <InstrumentSpan>
        <LiaGuitarSolid size={24} />
        Guitar
      </InstrumentSpan>
    ),
    value: "guitar",
  },
  {
    label: (
      <InstrumentSpan>
        <GiFlute size={24} />
        Flute
      </InstrumentSpan>
    ),
    value: "flute",
  },
  {
    label: (
      <InstrumentSpan>
        <GiClarinet size={24} />
        Clarinet
      </InstrumentSpan>
    ),
    value: "clarinet",
  },
  {
    label: (
      <InstrumentSpan>
        <GiDrum size={24} />
        Drum
      </InstrumentSpan>
    ),
    value: "drum",
  },
];

const studentInstrumentOptions = [
  {
    label: (
      <InstrumentSpan>
        <GiGrandPiano size={24} />
        Piano
      </InstrumentSpan>
    ),
    value: "piano",
  },
  {
    label: (
      <InstrumentSpan>
        <GiViolin size={24} />
        Violin
      </InstrumentSpan>
    ),
    value: "violin",
  },
  {
    label: (
      <InstrumentSpan>
        <MdKeyboardVoice size={24} />
        Voice
      </InstrumentSpan>
    ),
    value: "voice",
  },
  {
    label: (
      <InstrumentSpan>
        <LiaGuitarSolid size={24} />
        Guitar
      </InstrumentSpan>
    ),
    value: "guitar",
  },
  {
    label: (
      <InstrumentSpan>
        <GiFlute size={24} />
        Flute
      </InstrumentSpan>
    ),
    value: "flute",
  },
  {
    label: (
      <InstrumentSpan>
        <GiClarinet size={24} />
        Clarinet
      </InstrumentSpan>
    ),
    value: "clarinet",
  },
  {
    label: (
      <InstrumentSpan>
        <GiDrum size={24} />
        Drum
      </InstrumentSpan>
    ),
    value: "drum",
  },
  {
    label: (
      <InstrumentSpan>
        <GiViolin size={24} />
        Viola
      </InstrumentSpan>
    ),
    value: "viola",
  },
  {
    label: (
      <InstrumentSpan>
        <PiGuitarDuotone size={24} />
        Ukulele
      </InstrumentSpan>
    ),
    value: "ukulele",
  },
  {
    label: (
      <InstrumentSpan>
        <GiSaxophone size={24} />
        Saxophone
      </InstrumentSpan>
    ),
    value: "saxophone",
  },
  {
    label: (
      <InstrumentSpan>
        <GiMusicalScore size={24} />
        Music Composition
      </InstrumentSpan>
    ),
    value: "music_composition",
  },
  {
    label: (
      <InstrumentSpan>
        <BsMusicNoteList size={24} />
        Music theory
      </InstrumentSpan>
    ),
    value: "music_theory",
  },
  {
    label: (
      <InstrumentSpan>
        <GiMeditation size={24} />
        Meditation
      </InstrumentSpan>
    ),
    value: "meditation",
  },
];

export { teacherInstrumentOptions, studentInstrumentOptions };
