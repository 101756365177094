import { Divider, Image } from "antd";
import { ModalBody } from "reactstrap";
import { Attachments } from "src/components/Dashboard/Feedbacks/components/ActiveFeedback/AttachmentsList/styled";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { PrimaryButton } from "src/utils/shared/styled";
import { FeatureNotifyWrapper } from "./styled";

const FeatureNotifyModal = ({
  isOpen,
  toggle,
  user,
  title,
  description,
  images,
}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      centered
      toggle={toggle}
      size="lg"
      zIndex={999}
    >
      <StyledModalHeader toggle={toggle}>
        Welcome, {user?.name || user?.fullName}!
      </StyledModalHeader>
      <ModalBody>
        <FeatureNotifyWrapper>
          <h4>{title}</h4>
          <p>{description}</p>
          <Divider />
          <Attachments>
            <Image.PreviewGroup>
              {images?.map((image, index) => (
                <Image
                  width={250}
                  src={image}
                  placeholder
                  alt="new feature"
                  preview={{
                    destroyOnClose: true,
                  }}
                  loading="lazy"
                  key={index}
                />
              ))}
            </Image.PreviewGroup>
          </Attachments>
          <Divider />
          <PrimaryButton backgroundColor="#681e46DD" onClick={toggle}>
            Acknowledged
          </PrimaryButton>
        </FeatureNotifyWrapper>
      </ModalBody>
    </StyledModal>
  );
};

export default FeatureNotifyModal;
