import { Checkbox, InputNumber, Space } from "antd";

const SalaryInputs = ({
  onHourlyChange,
  hourlyValue,
  onQuarterlyChange,
  quarterlyValue,
  flexibleChecked,
  onFlexibleChange,
}) => {
  return (
    <Space direction="vertical" size="middle">
      <InputNumber
        addonBefore="Hourly"
        addonAfter="$"
        step={10}
        changeOnWheel
        onChange={onHourlyChange}
        value={hourlyValue}
        disabled={flexibleChecked}
      />
      <InputNumber
        addonBefore="Quarterly"
        addonAfter="$"
        step={100}
        changeOnWheel
        onChange={onQuarterlyChange}
        value={quarterlyValue}
        disabled={flexibleChecked}
      />
      <Checkbox checked={flexibleChecked} onChange={onFlexibleChange}>
        Flexible (open to discuss)
      </Checkbox>
    </Space>
  );
};

export default SalaryInputs;
