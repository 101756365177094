import { SearchInputContainer } from "../../styled-components/teacherLibraryStyledComponents";

const TeacherLibrarySearchInput = ({ query, setQuery }) => {
  return (
    <SearchInputContainer>
      <input
        type="text"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        placeholder={"Search"}
        className="searchInput"
      />
      <i className="fa fa-search position-absolute" />
    </SearchInputContainer>
  );
};
export default TeacherLibrarySearchInput;
