import { Table, Tag } from "antd";
import styled from "styled-components";

const StyledTable = styled(Table)`
  .ant-table {
    border: 1px solid #681e46 !important;
    border-radius: 1.3rem !important;
  }

  .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background-color: #681e4633 !important;
  }
`;

const AvailableDaysWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const TeacherImage = styled.img`
  width: 80px;
  height: 80px;

  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;

const ProgramTag = styled(Tag)`
  font-size: 14px;
  cursor: pointer;
`;

export { StyledTable, AvailableDaysWrapper, TeacherImage, ProgramTag };
