import React from "react";
import StyledBarContent from "src/components/common/StyledBarContent";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";

const RedbarModal = ({
  modalData,
  modalHeader,
  redbarTitle,
  overflowRedbarContent = false,
  modalSize = "lg",
  children,
  renderFooterSection = () => {},
  minWidthOnLargeScreen,
  ...props
}) => {
  const { isModalOpen, toggleModal, closeModal } = modalData;

  // passing ...props to children of this modal
  const childrenWithProps = React.cloneElement(children, { ...props });

  return (
    <CustomModal
      size={modalSize}
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
      padding={"0px"}
      minWidthOnLargeScreen={minWidthOnLargeScreen || null}
    >
      <CustomModalHeader
        // as={"div"}
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        toggle={toggleModal}
        // className="pb-0 border-bottom-0"
        padding="25px"
      >
        {modalHeader || ""}
      </CustomModalHeader>
      <CustomModalBody padding={"0px"}>
        <div>
          <StyledBarContent
            title={redbarTitle || ""}
            overFlowContent={overflowRedbarContent}
          >
            {childrenWithProps}
          </StyledBarContent>
          <div className="mt-4"></div>
          {renderFooterSection()}
        </div>
      </CustomModalBody>
    </CustomModal>
  );
};

export default RedbarModal;
