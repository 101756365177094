import moment from "moment";
import React from "react";
import { SubmitSpinner } from "src/components/common";
import RouteContent from "src/components/common/RouteContent";
import NavigationBar from "./components/NavigationBar";
import PendingAbsencesTable from "./components/PendingAbsencesTable";
import ResolvedAbsencesTable from "./components/ResolvedAbsencesTable";
import { IN_PROGRESS_COLOR, pageTabs, SUBMITTED_COLOR } from "./constants";
import useAbsenceData from "./hooks/useAbsenceData";
import { SearchBar, SearchIcon, StatusCircle } from "./styled";
import DateFilter from "./components/DateFilter";

const TeacherAbsence = () => {
  const absencesData = useAbsenceData();
  const {
    currentView,
    setCurrentView,
    loadingInitialData,
    initialData,
    dateFilter,
    handleDateFilterChange,
    customDateFilterValues,
    handleCustomDateFilterChange,
  } = absencesData;

  const displayCurrentTabComponent = () => {
    if (loadingInitialData) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <SubmitSpinner
            style={{ width: "3rem", height: "3rem", color: "#681e46" }}
          />
        </div>
      );
    } else if (currentView === pageTabs.PENDING) {
      return <PendingAbsencesTable absencesData={absencesData} />;
    } else {
      return <ResolvedAbsencesTable absencesData={absencesData} />;
    }
  };

  return (
    <RouteContent title="Teacher Absence">
      <div className="p-3">
        <div className="mb-3 d-flex align-items-center">
          <NavigationBar
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
        </div>
        <div className="d-flex align-items-center mt-3 mb-4">
          <SearchBar
            value={absencesData.searchTerm}
            onChange={(e) =>
              absencesData.handleSearchTermChange(e.target.value)
            }
            placeholder="Search by Teacher Full Name"
          />
          <SearchIcon />
          <div className="ms-3">
            <div>
              <StatusCircle bgColor={SUBMITTED_COLOR} />
              <span> Requested</span>
            </div>
            <div>
              <StatusCircle bgColor={IN_PROGRESS_COLOR} />
              <span> In Progress</span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-3 mb-4">
          <DateFilter
            dateFilter={dateFilter}
            handleDateFilterChange={handleDateFilterChange}
            customDateFilterValues={customDateFilterValues}
            handleCustomDateFilterChange={handleCustomDateFilterChange}
          />
        </div>

        <div>{displayCurrentTabComponent()}</div>
      </div>
    </RouteContent>
  );
};

export default TeacherAbsence;
