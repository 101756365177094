import React, { useContext, useEffect, useMemo, useState } from "react";
import { enrollmentComponents, studentViewOptions } from "../constants";
import { toast } from "react-toastify";
import { generateUserSystemID } from "src/utils/apiFns";
import { UserRole } from "src/constants/UserRoleEnum";
import { MAIN_LOCATION_SYSTEM_ID } from "src/constants/constantVariables";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { setDoc } from "firebase/firestore";
import { FirebaseContext } from "src/components/Firebase";
import useFirebaseFns from "src/hooks/useFirebaseFns";
const newStudentFormDataInitialValues = {
  //   createdAt: new Date(),

  fullName: "",
  systemID: "",
  birthday: "",
  enrollmentDate: "",
  primaryEmail: "",
  primaryContactEmail: "",
  primaryContactName: "",
  primaryPhone: "",
  secondaryContactEmail: "",
  secondaryContactName: "",
  secondPhone: "",
  homeAddress: "",
  city: "",
  state: "",
  zip: "",

  //   gender: "",
  //   imageUrl: "",
};

const currentStudentInitialData = {
  privateStudents: [],
};

const initialDataInitialValues = {
  locations: [],
};

const useAddStudent = () => {
  const firebase = useContext(FirebaseContext);
  const { secondaryApp } = firebase;

  const { getLocations, getStudents } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(
    initialDataInitialValues
  );

  const [selectedStudentView, setSelectedStudentView] = useState(
    studentViewOptions.NEW
  );
  const [currentComponent, setCurrentComponent] = useState(
    enrollmentComponents.NEW_STUDENT
  );

  const [newStudentformData, setNewStudentFormData] = useState(
    newStudentFormDataInitialValues
  );

  const [currentStudentData, setCurrentStudentData] = useState(
    currentStudentInitialData
  );

  const [currentStudentsSearchTerm, setCurrentStudentsSearchTerm] =
    useState("");

  // will hold the info of the newly created gc student
  const [paymentStudentId, setPaymentStudentId] = useState("");

  const isNewStudentView = useMemo(() => {
    return selectedStudentView === studentViewOptions.NEW;
  }, [selectedStudentView]);
  const isCurrentStudentView = useMemo(() => {
    return selectedStudentView === studentViewOptions.CURRENT;
  }, [selectedStudentView]);
  const isNewStudentCurrentComponent = useMemo(() => {
    return currentComponent === enrollmentComponents.NEW_STUDENT;
  }, [currentComponent]);
  const isCurrentStudentCurrentComponent = useMemo(() => {
    return currentComponent === enrollmentComponents.CURRENT_STUDENT;
  }, [currentComponent]);
  const isPaymentCurrentComponent = useMemo(() => {
    return currentComponent === enrollmentComponents.PAYMENT;
  }, [currentComponent]);

  const selectedCurrentStudent = useMemo(() => {
    const student = currentStudentData.privateStudents.find(
      ({ id }) => id === paymentStudentId
    );
    return student;
  }, [paymentStudentId, currentStudentData]);

  const filteredCurrentStudents = useMemo(() => {
    if (!currentStudentData.privateStudents?.length) return [];

    const filtered = currentStudentData.privateStudents?.filter(
      ({ fullName }) => {
        const matchSearchTerm = fullName
          .toLowerCase()
          .includes(currentStudentsSearchTerm.toLowerCase());
        return matchSearchTerm;
      }
    );

    return filtered;
  }, [currentStudentsSearchTerm, currentStudentData]);

  function handleNewStudentFormChange(name, value) {
    setNewStudentFormData((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  }

  function handleSelectedViewChange(newView) {
    setSelectedStudentView(newView);
    if (newView === studentViewOptions.NEW) {
      setCurrentComponent(enrollmentComponents.NEW_STUDENT);
    } else if (newView === studentViewOptions.CURRENT) {
      setCurrentComponent(enrollmentComponents.CURRENT_STUDENT);
    }
  }

  // navigates to the payment page
  async function handleNextStep() {
    if (isNewStudentCurrentComponent) {
      handleNewStudentFormSubmit();
    } else if (isCurrentStudentCurrentComponent) {
      setCurrentComponent(enrollmentComponents.PAYMENT);
    }
    //  else if (currentComponent === enrollmentComponents.PAYMENT) {
    // }
  }

  async function handleNewStudentFormSubmit() {
    let isCompleteFormInfo = true;
    Object.keys(newStudentformData).forEach((key) => {
      if (
        !newStudentformData[key] &&
        newStudentformData[key] !== 0 &&
        key !== "secondaryContactEmail" &&
        key !== "secondaryContactName" &&
        key !== "secondPhone"
      )
        isCompleteFormInfo = false;
    });
    console.log({ newStudentformData, isCompleteFormInfo });
    if (!isCompleteFormInfo) {
      toast.warn("Form Is Incomplete");
      return;
    }

    const auth = getAuth(secondaryApp);
    const lowerCaseEmail = newStudentformData.primaryEmail.toLowerCase();
    const authUser = await createUserWithEmailAndPassword(
      auth,
      lowerCaseEmail,
      process.env.REACT_APP_USERS_GLOBAL_PASSWORD
    );
    if (!authUser.user.uid) {
      toast.error("Something went wrong creating the user");
      return;
    }

    const studentObj = {
      ...newStudentformData,
      id: authUser.user.uid,
      createdAt: new Date(),
      role: UserRole.GROUP_CLASS_STUDENT,
      active: true,
      primaryEmail: lowerCaseEmail,
    };
    await setDoc(firebase.user(authUser.user.uid), studentObj);

    toast.success("Group Class Student Created Successfully");
    setPaymentStudentId(studentObj.id);
    setCurrentComponent(enrollmentComponents.PAYMENT);
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [locations] = await Promise.all([getLocations()]);

        setInitialData({
          locations,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (!isCurrentStudentCurrentComponent) {
      return;
    }
    async function fetchCurrentStudentData() {
      try {
        const [privateStudents] = await Promise.all([getStudents()]);
        setCurrentStudentData({
          privateStudents,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
    fetchCurrentStudentData();
  }, [isCurrentStudentCurrentComponent]);

  useEffect(() => {
    if (!initialData.locations.length) return;

    if (selectedStudentView === studentViewOptions.NEW) {
      const genUserId = async () => {
        try {
          setLoadingInitialData(true);
          const mainLocationId = initialData.locations.find(
            (location) => location.systemID === MAIN_LOCATION_SYSTEM_ID
          )?.id;
          if (!mainLocationId) {
            toast.error("Couldnt find mainLocationId");
            return;
          }
          const newUserId = await generateUserSystemID(
            UserRole.GROUP_CLASS_STUDENT,
            mainLocationId
          );
          if (newUserId) {
            setNewStudentFormData((oldVal) => ({
              ...oldVal,
              systemID: newUserId,
            }));
          }
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        } finally {
          setLoadingInitialData(false);
        }
      };
      genUserId();
    }
  }, [initialData, selectedStudentView]);
  return {
    selectedStudentView,
    handleSelectedViewChange,
    currentComponent,
    setCurrentComponent,
    handleNextStep,
    newStudentformData,
    handleNewStudentFormChange,
    handleNewStudentFormSubmit,
    isNewStudentView,
    isCurrentStudentView,
    isNewStudentCurrentComponent,
    isCurrentStudentCurrentComponent,
    isPaymentCurrentComponent,
    loadingInitialData,
    currentStudentData,
    selectedCurrentStudent,
    filteredCurrentStudents,
    currentStudentsSearchTerm,
    setCurrentStudentsSearchTerm,
    paymentStudentId,
    setPaymentStudentId,
  };
};

export default useAddStudent;
