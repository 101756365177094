import moment from "moment";
import React, { useMemo } from "react";
import CustomReactSelect from "src/components/common/CustomReactSelect";
import {
  isPerDurationAbsence,
  isPerLessonAbsence,
} from "src/constants/absenceTypes";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import {
  getLessonWithCurrentTimeline,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
} from "src/utils/helpers";
import { CustomInput } from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import {
  CustomRangeContainer,
  CustomRangeInput,
  FormContainer,
  OptionContainer,
  OptionsContainer,
} from "../styled";
import { isAllDayAbsence } from "../constants";

const AbsenceBehaviourSelect = ({ absenceRequestData }) => {
  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.SM;

  const {
    initialData,
    selectedDate,
    handleSelectedDateChange,
    selectedAbsenceBehaviour,
    handleSelectedAbsenceBehaviourChange,
    absenceBehaviourData,
    handleAbsenceBehaviourDataChange,
    teacherNote,
    handleTeacherNoteChange,
    affectedPrivateLessonsIds,
    affectedMakeupLessonsIds,
    affectedTrialLessonsIds,
    teacherPLsOnAbsenceDate,
  } = absenceRequestData;
  const {
    teacherTLs,
    teacherMakeUpLessons,
    teacherPLs,
    combinedStudents,
    instruments,
  } = initialData;

  const combinedLessonsIds = [
    ...affectedPrivateLessonsIds,
    ...affectedMakeupLessonsIds,
    ...affectedTrialLessonsIds,
  ];

  return (
    <div className="mt-4 mb-4">
      <CustomLabel>Time:</CustomLabel>
      <CustomRangeContainer>
        <CustomRangeInput
          value={selectedAbsenceBehaviour}
          onChange={(e) =>
            handleSelectedAbsenceBehaviourChange(parseInt(e.target.value))
          }
          min="1"
          max="3"
          type="range"
        />
        <OptionsContainer>
          <OptionContainer
            isActive={isAllDayAbsence(selectedAbsenceBehaviour)}
            className="d-flex align-items-center "
          >
            All Day
          </OptionContainer>
          <OptionContainer
            isActive={isPerDurationAbsence(selectedAbsenceBehaviour)}
            className="mt-2"
          >
            <div className="d-flex align-items-center" style={{ gap: 10 }}>
              <CustomInput
                disabled={!isPerDurationAbsence(selectedAbsenceBehaviour)}
                name="startTime"
                value={absenceBehaviourData.duration.startTime}
                onChange={(e) =>
                  handleAbsenceBehaviourDataChange(
                    "duration",
                    e.target.name,
                    e.target.value
                  )
                }
                type="time"
                fontSize={isDesktopScreen ? "1rem" : "0.6rem"}
                padding={isDesktopScreen ? "10px" : "0px"}
              />
              {isDesktopScreen && <span>TO:</span>}
              <CustomInput
                disabled={!isPerDurationAbsence(selectedAbsenceBehaviour)}
                name="endTime"
                value={absenceBehaviourData.duration.endTime}
                onChange={(e) =>
                  handleAbsenceBehaviourDataChange(
                    "duration",
                    e.target.name,
                    e.target.value
                  )
                }
                type="time"
                fontSize={isDesktopScreen ? "1rem" : "0.6rem"}
                padding={isDesktopScreen ? "10px" : "0px"}
              />
            </div>
          </OptionContainer>
          <OptionContainer
            isActive={isPerLessonAbsence(selectedAbsenceBehaviour)}
          >
            <div>
              <CustomReactSelect
                placeholder={"Select A Single Lesson"}
                isDisabled={!isPerLessonAbsence(selectedAbsenceBehaviour)}
                //disables selecting more than 1 option
                isOptionDisabled={() =>
                  absenceBehaviourData.perLessons.selectedLessons.length >= 1
                }
                options={teacherPLsOnAbsenceDate.map((lesson) => {
                  const { id, studentId, instrumentId } = lesson;
                  const {
                    startDate: lessonStart,
                    duration,
                    teacherId,
                  } = getPrivateLessonInfoOnSpecificDate({
                    privateLesson: lesson,
                    date: selectedDate,
                    withTimelineApproximation: true,
                  });

                  const lessonEnd = lessonStart
                    ? moment(lessonStart).add(parseInt(duration), "minutes")
                    : undefined;
                  const formattedStartTime =
                    moment(lessonStart).format("hh:mm A");
                  const formattedEndTime = moment(lessonEnd).format("hh:mm A");
                  const student = combinedStudents?.find(
                    ({ id }) => id === studentId
                  );
                  const studentName = student?.fullName || student?.name;

                  const instrument = instruments?.find(
                    ({ id }) => id === instrumentId
                  );

                  return {
                    label: `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime})`,
                    value: id,
                  };
                })}
                onChange={(selectedLessons) =>
                  handleAbsenceBehaviourDataChange(
                    "perLessons",
                    "selectedLessons",
                    selectedLessons
                  )
                }
                value={absenceBehaviourData.perLessons.selectedLessons}
                wrapOptions={false}
                bgColor="#fff"
                width="100%"
              />
            </div>
          </OptionContainer>
        </OptionsContainer>
      </CustomRangeContainer>
      {combinedLessonsIds?.length ? (
        <div style={{ marginTop: "70px" }}>
          <h4 className="mb-3">Affected Lessons</h4>
          <ul className="ps-4">
            {/* Affected Private Lessons */}
            {affectedPrivateLessonsIds?.map((lessonId) => {
              const lesson = teacherPLs?.find(({ id }) => id === lessonId);
              const { studentId, instrumentId } = lesson;
              const {
                startDate: lessonStartDate,
                duration,
                teacherId,
              } = getPrivateLessonInfoOnSpecificDate({
                privateLesson: lesson,
                date: selectedDate,
                withTimelineApproximation: true,
              });

              const lessonEndDate = lessonStartDate
                ? moment(lessonStartDate).add(parseInt(duration), "minutes")
                : undefined;

              const student = combinedStudents?.find(
                ({ id }) => id === studentId
              );
              const studentName = student?.fullName || student?.name;

              const instrument = instruments?.find(
                ({ id }) => id === instrumentId
              );
              const formattedStartTime =
                moment(lessonStartDate).format("hh:mm A");
              const formattedEndTime = moment(lessonEndDate).format("hh:mm A");
              const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Private Lesson*`;
              return <li className="mb-1">{title}</li>;
            })}

            {/* Affected Trial Lessons */}
            {affectedTrialLessonsIds?.map((lessonId) => {
              const {
                id,
                studentId,
                instrument: instrumentId,
                date,
                lessonLength,
              } = teacherTLs?.find(({ id }) => id === lessonId) || {};

              const student = combinedStudents?.find(
                ({ id }) => id === studentId
              );
              const studentName = student?.fullName || student?.name;
              const instrument = instruments?.find(
                ({ id }) => id === instrumentId
              );
              const formattedStartTime = moment(date).format("hh:mm A");
              const formattedEndTime = moment(date)
                .add(parseInt(lessonLength), "minutes")
                .format("hh:mm A");

              const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Trial Lesson*`;
              return <li className="mb-1">{title}</li>;
            })}

            {/* Affected Make Up Lessons */}
            {affectedMakeupLessonsIds?.map((lessonId) => {
              const { id, studentId, instrumentId, date } =
                teacherMakeUpLessons?.find(({ id }) => id === lessonId) || {};
              const { startDate, lessonLength } = date || {};

              const student = combinedStudents?.find(
                ({ id }) => id === studentId
              );
              const studentName = student?.fullName || student?.name;
              const instrument = instruments?.find(
                ({ id }) => id === instrumentId
              );
              const formattedStartTime = moment(startDate).format("hh:mm A");
              const formattedEndTime = moment(startDate)
                .add(parseInt(lessonLength), "minutes")
                .format("hh:mm A");

              const title = `${studentName} (${instrument?.name}) (${formattedStartTime} - ${formattedEndTime}) *Make Up Lesson*`;
              return <li className="mb-1">{title}</li>;
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default AbsenceBehaviourSelect;
