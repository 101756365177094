import React from "react";
import { CustomButton, CustomButtonsWrapper } from "../../../../styled";
import { CustomCheckboxContainer } from "src/utils/shared/styled";
import { changeOptionsList } from "../../../constants";
import styled from "styled-components";

const RadioWrapper = styled.label`
  display: inline-block;
  padding: 10px 0;
  margin-right: 25px;
  font-size: 1.1rem;
  display: flex;
  align-items: start;
`;
const RadioInput = styled.input`
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
`;
const Description = styled.span`
  margin: 0;
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
const FieldsSelection = ({ selectedGroup, handleSelectedGroupChange }) => {
  return (
    <div>
      <h4>Select the option that you want to change</h4>
      <div className="mt-4">
        {changeOptionsList.map((fieldObj) => {
          const { propName, title, description } = fieldObj;
          return (
            <div>
              <RadioWrapper>
                <RadioInput
                  type="radio"
                  value={propName}
                  checked={selectedGroup === propName}
                  onChange={() => {
                    handleSelectedGroupChange(propName);
                  }}
                />
                <div className="d-inline-block">
                  <Description bold fontSize="1.2rem">
                    {title}
                  </Description>{" "}
                  <Description fontSize="0.8rem">({description})</Description>
                </div>
              </RadioWrapper>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FieldsSelection;
