import React from "react";
import { LayoutContainer } from "./styled";
import ConcertsList from "./ConcertsList";
import ConcertDetails from "./ConcertDetails";
import useConcertsListWithDetails from "./hooks/useConcertsListWithDetails";
import { injectUserStore } from "src/utils/helpers";

const ConcertsListWithDetails = ({ UserStore }) => {
  const user = UserStore.user;
  const {
    currentConcert,
    currentConcertColor,
    setCurrentConcertColor,
    setCurrentConcertId,
    initialData,
    loadingInitialData,
    refreshData,
    userActiveInvitations,
    loadingCurrentConcertData,
    setCurrentInvitationId,
    currentInvitationId,
  } = useConcertsListWithDetails({ user });

  return (
    <LayoutContainer>
      <ConcertsList
        setCurrentConcertColor={setCurrentConcertColor}
        setCurrentConcertId={setCurrentConcertId}
        concertsList={initialData.concertsList}
        loadingInitialData={loadingInitialData}
      />
      {currentConcert ? (
        <ConcertDetails
          user={user}
          refreshData={refreshData}
          locations={initialData.locations}
          currentConcertColor={currentConcertColor}
          currentConcert={currentConcert}
          userActiveInvitations={userActiveInvitations}
          loadingCurrentConcertData={loadingCurrentConcertData}
          currentInvitationId={currentInvitationId}
          setCurrentInvitationId={setCurrentInvitationId}
        />
      ) : null}
    </LayoutContainer>
  );
};

export default injectUserStore(ConcertsListWithDetails);
