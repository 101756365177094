import React from "react";
import { CustomSelectField2, PrimaryButton } from "src/utils/shared/styled";
import { Navigate as navigate } from "react-big-calendar";

const MobileCustomToolbar = ({
  label,
  localizer: { messages },
  onNavigate,
  onView,
  view,
  views,
}) => {
  // console.log({
  //   label,
  //   localizer: { messages },
  //   onNavigate,
  //   onView,
  //   view,
  //   views,
  // });

  return (
    <div>
      <div className="mb-4 text-center">
        <CustomSelectField2
          value={view}
          onChange={(e) => onView(e.target.value)}
        >
          {views.map((view) => (
            <option value={view} key={view}>
              {view}
            </option>
          ))}
        </CustomSelectField2>
      </div>
      <div className="mb-4 d-flex justify-content-center ">
        <PrimaryButton
          width="125px"
          // backgroundColor="#d71d82"
          className="me-2"
          padding="7px"
          onClick={() => onNavigate(navigate.TODAY)}
        >
          Today
        </PrimaryButton>
        <PrimaryButton
          width="125px"
          backgroundColor="#d71d82"
          className="me-2"
          padding="7px"
          onClick={() => onNavigate(navigate.PREVIOUS)}
        >
          Back
        </PrimaryButton>
        <PrimaryButton
          width="125px"
          backgroundColor="#d71d82"
          className="me-2"
          padding="7px"
          onClick={() => onNavigate(navigate.NEXT)}
        >
          Next
        </PrimaryButton>
      </div>
      <div className="mb-3">
        <h4 className="text-center">{label}</h4>
      </div>
    </div>
  );
};

export default MobileCustomToolbar;
