import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const TimeSpan = styled.span`
  display: block;
  color: #fff;
  width: 31%;
  white-space: nowrap;
  height: 39px;
  text-align: center;
  margin-right: 5px;
  padding: 10px 20px;
  border-radius: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#681e46" : "#ff97d4")};

  @media (max-width: ${ScreenSize.MD}px) {
    & {
      width: 47%;
    }
  }
  @media (max-width: ${ScreenSize.SM}px) {
    & {
      width: 100%;
    }
  }
`;
