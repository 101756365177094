import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { isGroupClassInDate, isGroupClassInLocation } from "src/utils/helpers";
import { groupClassesListViews } from "../constants";
import { deleteDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "src/components/Firebase";

const groupClassFiltersInitialValues = {
  locationFilter: {
    isActive: false,
    locationId: "",
  },
  dateFilter: {
    isActive: false,
    requestDate: null, // date
  },
};
const initialDataInitialValues = {
  locations: [],
  teachers: [],
  groupClasses: [],
};
const useGroupClass = () => {
  const firebase = useContext(FirebaseContext);
  const { getGroupClasses, getLocations, getTeachers } = useFirebaseFns();

  const [groupClassFilters, setGroupClassFilters] = useState(
    groupClassFiltersInitialValues
  );
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [initialData, setInitialData] = useState(initialDataInitialValues);

  const [groupClassView, setGroupClassView] = useState(
    groupClassesListViews.active.value
  );

  const [currentGroupClassId, setCurrentGroupClassId] = useState("");

  function handleFiltersChange(filterName, property, value) {
    setGroupClassFilters((oldVal) => ({
      ...oldVal,
      [filterName]: {
        ...oldVal[filterName],
        isActive: true,
        [property]: value,
      },
    }));
  }

  function resetFilters(filterName) {
    if (filterName) {
      setGroupClassFilters((oldVal) => ({
        ...oldVal,
        [filterName]: {
          isActive: false,
        },
      }));
    } else {
      setGroupClassFilters(groupClassFiltersInitialValues);
    }
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);

        const [groupClasses, locations, teachers] = await Promise.all([
          getGroupClasses(),
          getLocations(),
          getTeachers(),
        ]);

        setInitialData({
          groupClasses,
          locations,
          teachers,
        });
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };

    fetchInitialData();
  }, []);

  const displayedGroupClasses = useMemo(() => {
    const filtered = initialData.groupClasses.filter((groupClass) => {
      let passLocationFilter = true;
      let passDateFilter = true;
      if (groupClassFilters.locationFilter.isActive) {
        const isInLocation = isGroupClassInLocation(
          groupClass,
          groupClassFilters.locationFilter.locationId
        );
        passLocationFilter = isInLocation;
      }
      if (groupClassFilters.dateFilter.isActive) {
        const isInDate = isGroupClassInDate(
          groupClass,
          groupClassFilters.dateFilter.requestDate,
          "date"
        );
        passDateFilter = isInDate;
      }
      const areActiveFilters =
        groupClassFilters.locationFilter.isActive &&
        groupClassFilters.dateFilter.isActive;

      return passLocationFilter && passDateFilter;
    });

    return filtered;
  }, [initialData, groupClassFilters]);

  const currentGroupClassObj = useMemo(() => {
    const groupClass = initialData.groupClasses.find(
      ({ id }) => id === currentGroupClassId
    );
    return groupClass;
  }, [initialData, currentGroupClassId]);

  const handleClassStatusChange = async (classId, status) => {
    try {
      if (
        !classId ||
        !status ||
        (status !== "active" && status !== "inactive")
      ) {
        return null;
      }
      setLoadingInitialData(true);

      const groupClassToUpdate = firebase.groupClass(classId);
      await updateDoc(groupClassToUpdate, {
        status,
      });

      const updatedGroupClasses = await getGroupClasses();
      setInitialData({
        ...initialData,
        groupClasses: updatedGroupClasses,
      });
      setLoadingInitialData(false);
      toast.success(
        `Group class ${
          status === groupClassesListViews.active.value
            ? "activated"
            : "deactivated"
        }`
      );
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  const deleteGroupClass = async (classId) => {
    try {
      setLoadingInitialData(true);

      const groupClassToDelete = firebase.groupClass(classId);
      await deleteDoc(groupClassToDelete);

      setInitialData({
        ...initialData,
        groupClasses: initialData?.groupClasses?.filter(
          (groupClass) => groupClass?.id !== classId
        ),
      });
      setLoadingInitialData(false);
      toast.success("Group class deleted");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred");
    }
  };

  return {
    groupClassFilters,
    loadingInitialData,
    initialData,
    handleFiltersChange,
    resetFilters,
    displayedGroupClasses,
    groupClassView,
    setGroupClassView,
    currentGroupClassObj,
    setCurrentGroupClassId,
    handleClassStatusChange,
    deleteGroupClass,
  };
};

export default useGroupClass;
