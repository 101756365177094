import React from "react";
import { OptionContainer, OptionsContainer } from "./styled";

const CustomNavButtons = ({
  options = [],
  currentOption,
  setCurrentOption,
  isDisabled = false,
  buttonsPadding,
}) => {
  return (
    <OptionsContainer>
      {options.map((option) => {
        return (
          <OptionContainer
            padding={buttonsPadding || ""}
            key={option.value}
            bgColor={isDisabled ? "#f1f1f1" : "#fff"}
            className={currentOption === option.value ? "active" : undefined}
            onClick={() =>
              isDisabled ? () => {} : setCurrentOption(option.value)
            }
          >
            {option.name}
          </OptionContainer>
        );
      })}
    </OptionsContainer>
  );
};

export default CustomNavButtons;
