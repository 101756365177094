import moment from "moment";

const filterPaymentsByDateRange = (paymentsList, dateRange) => {
  if (!dateRange || dateRange.length !== 2) return paymentsList;

  const [startDate, endDate] = dateRange?.map((date) =>
    moment(date, "MM/DD/YYYY")
  );

  return paymentsList?.filter((payment) => {
    const paymentStartDate = moment(payment?.startDate, "MM/DD/YYYY");
    return paymentStartDate?.isBetween(startDate, endDate, null, "[]");
  });
};

export { filterPaymentsByDateRange };
