import { DropdownMenu } from "reactstrap";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";
import { BookmarkFill, PinAngleFill } from "styled-icons/bootstrap";
import { Hide } from "styled-icons/boxicons-regular";
import { Edit } from "styled-icons/evaicons-solid";
import { PinOff } from "styled-icons/fluentui-system-filled";

export const CustomDropdownMenu = styled(DropdownMenu)`
  width: 220px;
  box-shadow: 0px 0px 7px 0px #e1e1e1;
  background-color: #fdfdfd;
  max-height: 500px;
  padding: 0px !important;
  overflow-y: auto;

  /* @media (max-width: ${ScreenSize.SM}px) {
    width: 350px;
  } */
`;
export const DropdownOptionsContainer = styled.ul`
  min-height: 50px;
  padding: 0;
  margin: 0;
  padding: 10px;
`;

export const DropdownOptionContainer = styled.li`
  list-style: none;
  margin-bottom: 8px;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
  }
  & > * {
    color: #832357;
  }
  &:hover > * {
    color: #c1709c;
  }
  &:hover {
    background: #a66d8b08;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;
export const UnPinIcon = styled(PinOff)`
  width: 22px;
  color: #681e46;
`;
export const PinIcon = styled(PinAngleFill)`
  width: 20px;
  color: #681e46;
`;
export const HideIcon = styled(Hide)`
  width: 20px;
  color: #681e46;
`;
export const BookmarkIcon = styled(BookmarkFill)`
  width: 20px;
  color: #681e46;
`;
export const EditIcon = styled(Edit)`
  width: 20px;
  color: #681e46;
`;
