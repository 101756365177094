/* eslint-disable default-case */
import React, { useContext } from "react";

import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalHeader,
} from "../../styled-components/teacherLibraryStyledComponents";
import {
  ActiveTabContext,
  ViewItemModalContext,
} from "../common/libraryContext";
import PDFViewer from "./PDFViewer";
import AudioViewer from "./AudioViewer";
import { MODAL_SIZE } from "../common/constants";
import VideoViewer from "./VideoViewer";
import LinkViewer from "./LinkViewer";
import ImageViewer from "./ImageViewer";

const ViewItemModal = ({hasClose = true}) => {
  const { activeTab } = useContext(ActiveTabContext);
  const { state, close } = useContext(ViewItemModalContext);
  const renderContent = () => {
    const { item } = state;
    if (item.attachmentType && item.downloadUrl) {
      switch (item.attachmentType) {
        case "musicSheets":
          if (item.type.includes("image")) {
            return <ImageViewer image={item.downloadUrl} title={item.title} />;
          } else if (item.type.includes("pdf")) {
            return <PDFViewer file={item.downloadUrl} title={item.title}/>;
          } else {
            return null;
          }
        case "recordings":
          return <AudioViewer audio={item.downloadUrl} title={item.title} />;
        case "videos":
          return <VideoViewer video={item.downloadUrl} title={item.title} />;
        case "recommendationLinks":
          return <LinkViewer link={item.downloadUrl} title={item.title} />;
      }
    }
    return null;
  };
  return (
    <LibraryModal
      className={"p-0"}
      centered
      isOpen={state.isOpen}
      size={activeTab ? MODAL_SIZE[activeTab] : 'lg'}
    >
      {state?.item?.attachmentType !== "recordings" && state?.item?.attachmentType !== "videos" && (
        <LibraryModalHeader
          close={
            <button className="close" onClick={close}>
              <i className="fas fa-times" />
            </button>
          }
        />
      )}
      <LibraryModalBody className={"p-0"}>
        {renderContent()}
      </LibraryModalBody>
    </LibraryModal>
  );
};
export default ViewItemModal;
