import React, { useContext } from "react";
import { Button } from "reactstrap";
import {
  ActiveTabContext,
  DeleteNoteAttachmentContext,
} from "../../../common/Library/common/libraryContext";
import { Modal } from "../../../common";

const DeleteNoteAttachmentConfirmModal = () => {
  const { isAdmin } = useContext(ActiveTabContext);
  const { onDelete, close, isOpen } = useContext(DeleteNoteAttachmentContext);

  const buttons = (
    <>
      <Button
        className={"ms-2"}
        color="danger"
        onClick={async () => {
          await onDelete();
        }}
      >
        Yes, Delete
      </Button>
      {isAdmin ? (
        <Button
          className={"ms-2"}
          color="danger"
          onClick={async () => {
            await onDelete(true);
          }}
        >
          Delete forever
        </Button>
      ) : null}
    </>
  );
  const modalBody = () => (
    <div className={"d-flex py-5 align-items-center justify-content-center"}>
      <p className="lead mb-0">
        Are you sure you want to delete this attachment?
      </p>
    </div>
  );
  return (
    <Modal
      centered={true}
      size={"lg"}
      body={modalBody()}
      title={"Confirm Deletion"}
      isOpen={isOpen}
      toggle={close}
      buttons={buttons}
    />
  );
};
export default DeleteNoteAttachmentConfirmModal;
