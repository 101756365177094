import React from "react";
import { CustomLabel } from "src/utils/styled";
import {
  concertSignupOptions,
  concertLimitedSignupOptionTypes,
  isSchoolRecommendationConcert,
  isStudentSignupConcert,
  isTeacherRecommendationConcert,
} from "src/constants/concertEnum";
import { CustomCheckboxContainer } from "src/utils/shared/styled";
import { SectionContainer } from "../../../styled";
import LabeledRadioInput from "../../common/LabeledRadioInput";

const SignUpTypeSection = ({ concertData, handleConcertDataChange }) => {
  return (
    <div>
      <h5 className="mb-3">Sign Up Type:</h5>
      <SectionContainer>
        <div>
          <LabeledRadioInput
            label="Type"
            options={[
              {
                title: "Available To All Students",
                value: concertSignupOptions.FREE_FOR_ALL_STUDENTS,
              },
              {
                title: "Selective Sign Up",
                value: concertSignupOptions.LIMITED,
              },
            ]}
            onChange={(currVal) =>
              handleConcertDataChange("signUpType.type", currVal)
            }
            value={concertData.signUpType.type}
          />
        </div>
        <hr />
        {concertData.signUpType.type === concertSignupOptions.LIMITED && (
          <div className="mt-3 ">
            <CustomCheckboxContainer className="mb-2">
              <input
                checked={isTeacherRecommendationConcert(
                  concertData.signUpType.value
                )}
                onChange={(e) => {
                  const newVal = e.target.checked
                    ? [
                        ...concertData.signUpType.value,
                        concertLimitedSignupOptionTypes.TEACHER_RECOMMENDATION,
                      ]
                    : concertData.signUpType.value.filter(
                        (v) =>
                          v !==
                          concertLimitedSignupOptionTypes.TEACHER_RECOMMENDATION
                      );
                  handleConcertDataChange("signUpType.value", newVal);
                }}
                type="checkbox"
                id={concertLimitedSignupOptionTypes.TEACHER_RECOMMENDATION}
                name="checkboxOption"
              />
              <label
                className="ms-2"
                for={concertLimitedSignupOptionTypes.TEACHER_RECOMMENDATION}
              >
                Teacher Recommendation
              </label>
            </CustomCheckboxContainer>
            <CustomCheckboxContainer className="mb-2">
              <input
                checked={isSchoolRecommendationConcert(
                  concertData.signUpType.value
                )}
                onChange={(e) => {
                  const newVal = e.target.checked
                    ? [
                        ...concertData.signUpType.value,
                        concertLimitedSignupOptionTypes.SCHOOL_RECOMMENDATION,
                      ]
                    : concertData.signUpType.value.filter(
                        (v) =>
                          v !==
                          concertLimitedSignupOptionTypes.SCHOOL_RECOMMENDATION
                      );
                  handleConcertDataChange("signUpType.value", newVal);
                }}
                type="checkbox"
                id={concertLimitedSignupOptionTypes.SCHOOL_RECOMMENDATION}
                name="checkboxOption"
              />
              <label
                className="ms-2"
                for={concertLimitedSignupOptionTypes.SCHOOL_RECOMMENDATION}
              >
                School Recommendation
              </label>
            </CustomCheckboxContainer>
            <CustomCheckboxContainer>
              <input
                checked={isStudentSignupConcert(concertData.signUpType.value)}
                onChange={(e) => {
                  const newVal = e.target.checked
                    ? [
                        ...concertData.signUpType.value,
                        concertLimitedSignupOptionTypes.STUDENT_SIGNUP,
                      ]
                    : concertData.signUpType.value.filter(
                        (v) =>
                          v !== concertLimitedSignupOptionTypes.STUDENT_SIGNUP
                      );
                  handleConcertDataChange("signUpType.value", newVal);
                }}
                type="checkbox"
                id={concertLimitedSignupOptionTypes.STUDENT_SIGNUP}
                name="checkboxOption"
              />
              <label
                className="ms-2"
                for={concertLimitedSignupOptionTypes.STUDENT_SIGNUP}
              >
                Student Sign Up
              </label>
            </CustomCheckboxContainer>
          </div>
        )}
      </SectionContainer>
    </div>
  );
};

export default SignUpTypeSection;
