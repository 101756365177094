export const groupClassEnrollmentTypes = {
  REGULAR: "regular",
  MAKEUP: "makeup",
};
export const isRegularGroupClassEnrollment = (enrollmentType) => {
  return enrollmentType === groupClassEnrollmentTypes.REGULAR;
};
export const isMakeUpGroupClassEnrollment = (enrollmentType) => {
  return enrollmentType === groupClassEnrollmentTypes.MAKEUP;
};
