export const feedItems = {
  workUpdates: {
    type: "workUpdates",
    title: "Work Updates",
  },
  news: {
    type: "news",
    title: "News",
  },
  announcements: {
    type: "announcements",
    title: "Announcements",
  },
  otherTopics: {
    type: "otherTopics",
    title: "Fun Things",
  },
};
