const statusEnum = {
  emailConfirm: "Need email confirmation",
  profileInfo: "Need profile information",
  onReview: "On review",
  approved: "Approved",
  declined: "Declined",
};

const stages = [
  statusEnum.emailConfirm,
  statusEnum.profileInfo,
  statusEnum.onReview,
  statusEnum.approved,
  statusEnum.declined,
];

const getStatusColor = (user, stage) => {
  if (user?.status === statusEnum.declined) {
    if (stage === statusEnum.approved || stage === statusEnum.declined) {
      return "red";
    }
  } else if (user?.status === statusEnum.approved) {
    if (stage === statusEnum.approved) {
      return "green";
    } else if (stage === statusEnum.declined) {
      return "#212529";
    }
  }

  if (user?.status === stage) {
    return "orange";
  } else if (stages.indexOf(stage) < stages.indexOf(user?.status)) {
    return "green";
  } else {
    return "#212529";
  }
};

export { statusEnum, stages, getStatusColor };
