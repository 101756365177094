import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { InputWithLabel } from "../../../../../../utils/shared/shared";
import { PrimaryButton } from "../../../../../../utils/shared/styled";
import * as yup from "yup";
import { requiredNumberSchema } from "../../../../../../utils/validations";
import { FirebaseContext } from "../../../../../Firebase";
import { updateDoc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { SubmitSpinner } from "../../../../../common";

const schoolYearValidationSchema = yup.object().shape({
  schoolYear: requiredNumberSchema,
});

const SchoolYearForm = ({ closeModal }) => {
  const firebase = useContext(FirebaseContext);
  const [currentSchoolYear, setCurrentSchoolYear] = useState(null);
  const [loadingSchoolYear, setloadingSchoolYear] = useState(true);

  useEffect(() => {
    const getSchoolYear = async () => {
      try {
        const schoolYearSnapshot = await getDoc(firebase.schoolYearDoc());
        const schoolYear = {
          id: schoolYearSnapshot.id,
          ...schoolYearSnapshot.data(),
        };
        setCurrentSchoolYear(schoolYear?.year);
      } catch (err) {
        console.log(err);
      } finally {
        setloadingSchoolYear(false);
      }
    };
    getSchoolYear();
  }, []);

  if (loadingSchoolYear) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "200px" }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <Formik
      initialValues={{ schoolYear: currentSchoolYear }}
      validationSchema={schoolYearValidationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          setSubmitting(true);
          await updateDoc(firebase.schoolYearDoc(), {
            year: parseInt(values.schoolYear),
          });
          closeModal();
          toast.success("New School Year Is Set Successfully");
        } catch (err) {
          toast.error(err.message);
          console.log(err);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className="mt-5">
          <InputWithLabel
            type="number"
            label="School Year"
            name="schoolYear"
            error={errors.schoolYear}
            touched={touched.schoolYear}
            placeholder="School Year"
          />
          <div className="mt-5">
            <PrimaryButton type="submit" width="100%">
              {isSubmitting ? (
                <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
              ) : (
                "Save"
              )}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SchoolYearForm;
