import { Container } from "reactstrap";
import { Spinner } from "../../index";
import React, { useContext, useEffect, useState } from "react";
import { ADD_LABELS, ALLOWED_EXTENSIONS } from "../common/constants";
import LibraryFileUploadInput from "./LibraryFileUploadInput";
import {
  LibraryModal,
  LibraryModalBody,
  LibraryModalButton,
  LibraryModalHeader,
  LibraryModalInput,
} from "../../styled-components/teacherLibraryStyledComponents";
import FilesPreview from "./FilesPreview";
import {
  ActiveTabContext,
  AddItemModalContext,
  LoadingContext,
} from "../common/libraryContext";

const AddNewItemModal = ({ onSave }) => {
  const [newItemTitle, setNewItemTitle] = useState("");
  const [recommendationLink, setRecommendationLink] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const { activeTab } = useContext(ActiveTabContext);
  const { loading } = useContext(LoadingContext);
  const { isOpen, close } = useContext(AddItemModalContext);
  const [error, setError] = useState("");
  useEffect(() => {
    setNewItemTitle("");
    setRecommendationLink("");
    setError("");
    setUploadFile(null);
  }, [isOpen]);
  const onUploadFile = (e) => {
    const file = e.target.files[0];
    const { type } = file;
    const extension = type.split("/")[1];
    if (ALLOWED_EXTENSIONS.includes(extension)) {
      setUploadFile(file);
      setError("");
    } else {
      setError("File extension not supported");
    }
  };
  const onSubmit = () => {
    const formData = {
      file: uploadFile,
      title: newItemTitle,
      recommendationLink,
    };
    onSave(formData);
  };
  const isDisabled = () => {
    let disabled = false;
    disabled = disabled || newItemTitle === "";
    if (activeTab === "recommendationLinks") {
      disabled = disabled || recommendationLink === "";
    } else {
      disabled = disabled || uploadFile === null;
    }
    return disabled;
  };
  return (
    <LibraryModal className={"p-0"} centered isOpen={isOpen} size={"lg"}>
      <LibraryModalHeader
        close={
          <button className="close" onClick={close}>
            <i className="fas fa-times" />
          </button>
        }
      >
        Add new {ADD_LABELS[activeTab]}
      </LibraryModalHeader>
      <LibraryModalBody className={"p-0"}>
        <Container>
          <LibraryModalInput
            formik={false}
            label={"Title"}
            name="Title"
            placeholder="Title"
            height={"40px"}
            size={12}
            value={newItemTitle}
            onChange={(e) => setNewItemTitle(e.target.value)}
          />
          <div className="text-muted mb-3">This field is required</div>
          {activeTab === "recommendationLinks" ? ( //Represents Recommendations/Links Tabs
            <>
              <LibraryModalInput
                formik={false}
                label={"Link"}
                name="Link"
                placeholder="Link"
                height={"40px"}
                size={12}
                value={recommendationLink}
                onChange={(e) => setRecommendationLink(e.target.value)}
              />
              <div className="text-muted mb-1">This field is required</div>
            </>
          ) : (
            <>
              <LibraryFileUploadInput
                files={uploadFile ? [uploadFile] : []}
                onUploadFile={onUploadFile}
                onRemove={() => setUploadFile(null)}
                label={"Choose File From Device"}
              />
              <div className="text-muted mb-1">
                Supported extensions: {ALLOWED_EXTENSIONS.join(", ")}
              </div>
              <FilesPreview
                files={uploadFile ? [uploadFile] : []}
                onRemove={() => setUploadFile(null)}
              />
            </>
          )}
          {error !== "" && <div className={"alert alert-danger"}>{error}</div>}
          <LibraryModalButton
            type={"submit"}
            onClick={onSubmit}
            disabled={loading || isDisabled()}
            className={`d-flex font-weight-bold mx-auto ${
              isDisabled() && "disabled"
            } text-white align-items-center justify-content-center`}
          >
            {loading ? (
              <Spinner style={{}} size="sm" />
            ) : (
              <div
                className={"d-flex align-items-center justify-content-center"}
              >
                <span>Save</span>
              </div>
            )}
          </LibraryModalButton>
        </Container>
      </LibraryModalBody>
    </LibraryModal>
  );
};
export default AddNewItemModal;
