import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import useModal from "src/hooks/useModal";

import {
  CustomTableContainer,
  CustomTable,
  TableRow,
  PrimaryButton,
} from "src/utils/shared/styled";
import {
  getPrivateLessonInfoOnSpecificDate,
  updatedMomentNow,
} from "src/utils/helpers";
import MakeupRequestDetailsModal from "../../common/components/MakeupRequestDetailsModal";
import { Delete } from "styled-icons/material";
import { ActionsWrapper, DeleteButton } from "../styled";
import { ConfirmDeleteModal } from "../../SuperAdmin/TeacherAbsence/components/ConfirmDeleteModal";

const PendingMakeupRequestsTable = ({ makeupReqsData }) => {
  const {
    initialData,
    setCurrentMakeupRequestId,
    currentMakeupRequest,
    refreshData,
    pendingMakeupRequests,
  } = makeupReqsData;
  const { users, instruments } = initialData;
  const requestDetailsModalData = useModal();
  const { deleteMakeupRequest } = useFirebaseFns();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedMakeupId, setSelectedMakeupId] = useState(null);
  const [pendingMakeupRequestsToDisplay, setPendingMakeupRequestsToDisplay] =
    useState(pendingMakeupRequests || []);

  const openMakeupRequestDetailsModal = (requestId) => {
    setCurrentMakeupRequestId(requestId);
    requestDetailsModalData.openModal();
  };

  // clears the req id when closing the modal
  useEffect(() => {
    if (!requestDetailsModalData.isModalOpen) {
      setCurrentMakeupRequestId("");
    }
  }, [requestDetailsModalData.isModalOpen]);

  const handleDeleteModalOpen = (makeupId) => {
    setSelectedMakeupId(makeupId);
    setConfirmModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedMakeupId(null);
    setConfirmModalOpen(false);
  };

  const handleMakeupDelete = async (makeupId) => {
    await deleteMakeupRequest(makeupId);
    setPendingMakeupRequestsToDisplay((prevState) =>
      prevState?.filter((item) => item?.id !== makeupId)
    );
    setSelectedMakeupId(null);
    setConfirmModalOpen(false);
  };

  return (
    <>
      <Container fluid className="p-0">
        <CustomTableContainer>
          <CustomTable className="w-100 text-dark">
            <thead>
              <TableRow>
                <th>
                  <div>Request Date</div>
                </th>
                <th>
                  <div>Student Name</div>
                </th>
                <th>
                  <div>Instrument</div>
                </th>
                <th>
                  <div>Teacher Name </div>
                </th>
                <th>
                  <div>Actions</div>
                </th>
              </TableRow>
            </thead>
            <tbody>
              {pendingMakeupRequestsToDisplay?.map((request) => {
                const { createdAt, privateLesson } = request;
                const createdAtStr = moment(createdAt).format("MM-DD-YYYY");

                const studentName = users.find(
                  ({ id }) => id === privateLesson?.studentId
                )?.fullName;

                const currentTimelineData = getPrivateLessonInfoOnSpecificDate({
                  date: updatedMomentNow(),
                  privateLesson,
                  withTimelineApproximation: true,
                });
                const teacherName = users.find(
                  ({ id }) => id === currentTimelineData?.teacherId
                )?.fullName;

                const instrumentName = instruments.find(
                  ({ id }) => id === privateLesson?.instrumentId
                )?.name;

                return (
                  <TableRow key={request.id}>
                    <td>{createdAtStr}</td>
                    <td>{studentName || "N/A"}</td>
                    <td>{instrumentName}</td>
                    <td>{teacherName || "N/A"}</td>
                    <td>
                      <ActionsWrapper>
                        <PrimaryButton
                          onClick={() =>
                            openMakeupRequestDetailsModal(request.id)
                          }
                          width="100px"
                          padding="6px"
                        >
                          Open
                        </PrimaryButton>
                        <DeleteButton
                          onClick={() => handleDeleteModalOpen(request?.id)}
                          type="button"
                          aria-label="Delete make up request"
                        >
                          <Delete size={20} />
                        </DeleteButton>
                      </ActionsWrapper>
                    </td>
                  </TableRow>
                );
              })}
            </tbody>
          </CustomTable>
        </CustomTableContainer>
      </Container>

      {/* MODALS */}
      {requestDetailsModalData.isModalOpen && currentMakeupRequest && (
        <MakeupRequestDetailsModal
          makeupRequest={currentMakeupRequest}
          modalData={requestDetailsModalData}
          onSuccess={refreshData}
        />
      )}
      <ConfirmDeleteModal
        open={confirmModalOpen}
        onConfirm={() => handleMakeupDelete(selectedMakeupId)}
        onCancel={handleDeleteModalClose}
        text="Are you sure you want to delete this make up request?"
      />
    </>
  );
};

export default PendingMakeupRequestsTable;
