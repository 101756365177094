import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const useAppointmentEvent = ({
  appointmentId,
  closeEventDetailsModal,
  refreshData,
}) => {
  const { deleteAppointmentAndActivity } = useFirebaseFns();
  const [loadingAction, setLoadingAction] = useState(false);

  const handleDeleteAppointment = async () => {
    try {
      if (!appointmentId) return;
      if (loadingAction) return;
      setLoadingAction(true);
      await deleteAppointmentAndActivity(appointmentId);
      closeEventDetailsModal();
      refreshData();
      toast.success("Deleted Successfully");
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAction(false);
    }
  };
  return { handleDeleteAppointment };
};

export default useAppointmentEvent;
