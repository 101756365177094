import { ScreenSize } from "src/constants/ScreenSizeEnum";

const { default: styled } = require("styled-components");

export const MainContentContainer = styled.div`
  width: 100%;
  transition: all 0.3s;
  background-color: #fff;
  border-top-left-radius: 54px;
  border-bottom-left-radius: 54px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;

  // removes the curves of the page on MD screens
  @media (max-width: ${ScreenSize.MD + "px"}) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
