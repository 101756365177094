import React, { useContext, useRef, useState } from "react";
import { FirebaseContext } from "src/components/Firebase";
import {
  injectUserStore,
  isFileValidated,
  uploadFileWithRandomId,
} from "src/utils/helpers";
import {
  UploadAttachmentContainer,
  UploadIcon,
  UploadPlaceholder,
} from "../../styled";
import { toast } from "react-toastify";
import { CustomLabel } from "src/utils/shared/styled";
import AttachmentsList from "./AttachmentsList";
import { v4 as uuidv4 } from "uuid";

const AttachmentsSection = ({
  attachments,
  onAttachmentsChange,
  UserStore,
}) => {
  const user = UserStore.user;
  const firebase = useContext(FirebaseContext);

  const uploadRef = useRef();
  const [uploading, setUploading] = useState(false);

  return (
    <div>
      <CustomLabel>Posters:</CustomLabel>
      <input
        ref={uploadRef}
        accept="image/png, image/gif, image/jpeg, application/pdf, video/mp4,video/x-m4v,video/*"
        hidden
        type="file"
        onChange={async (e) => {
          const file = e.target.files[0];
          if (isFileValidated(file)) {
            setUploading(true);

            const attachmentObj = {
              id: uuidv4(),
              file,
            };
            const updatedAttachments = [...attachments, attachmentObj];
            onAttachmentsChange(updatedAttachments);
            setUploading(false);
          } else {
            toast.error("File Not Supported");
          }
        }}
      />
      <UploadAttachmentContainer
        onClick={() => {
          uploadRef.current.click();
        }}
      >
        <UploadPlaceholder>Attachment</UploadPlaceholder>
        <UploadIcon width={30} height={30} />
      </UploadAttachmentContainer>
      <div className="mt-3">
        <AttachmentsList
          attachments={attachments}
          onAttachmentsChange={onAttachmentsChange}
        />
      </div>
    </div>
  );
};

export default injectUserStore(AttachmentsSection);
