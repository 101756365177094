import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  parseAbsenceObjDates,
  parseMakeupLessonDates,
  parsePrivateLessonDates,
} from "src/utils/firebaseDatesParserFns";
import {
  getCurrentSchoolYear,
  getLessonWithCurrentTimeline,
  getPrivateLessonInfoOnSpecificDate,
} from "src/utils/helpers";
import useFirebaseFns from "./useFirebaseFns";

const useAffectedLessonsData = ({ absence }) => {
  const {
    getPrivateLessonsByIds,
    getLessonAbsences,
    getMakeupLessonsByAbsenceId,
  } = useFirebaseFns();

  const absenceDate = absence.date || absence.startDate;

  const { lessonId, affectedPrivateLessonsIds } = absence;

  const [initialData, setInitialData] = useState({
    absencePrivateLessons: [],
    absenceMakeupLessons: [],
  });

  // the lesson that is used for opening the makeup modal
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentLessonAbsences, setCurrentLessonAbsences] = useState([]);
  const [loadingCurrentLessonData, setLoadingCurrentLessonData] =
    useState(false);

  const [refresh, setRefresh] = useState(0);
  const refreshData = () => {
    setRefresh((oldVal) => oldVal + 1);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const combinedPrivateLessonsIds = [
        ...(lessonId ? [lessonId] : []),
        ...(affectedPrivateLessonsIds ? affectedPrivateLessonsIds : []),
      ];
      const [absencePrivateLessons, absenceMakeupLessons] = await Promise.all([
        getPrivateLessonsByIds(combinedPrivateLessonsIds),
        getMakeupLessonsByAbsenceId(absence?.id),
      ]);

      const privateLessonsWithDates = absencePrivateLessons.map((lesson) =>
        parsePrivateLessonDates(lesson)
      );
      const makeupLessonsWithDates = absenceMakeupLessons.map((lesson) =>
        parseMakeupLessonDates(lesson)
      );
      setInitialData((oldVal) => ({
        ...oldVal,
        absencePrivateLessons: privateLessonsWithDates,
        absenceMakeupLessons: makeupLessonsWithDates,
      }));
    };

    fetchInitialData();
  }, [refresh]);

  useEffect(() => {
    if (!currentLesson) return;

    const getCurrentLessonAbsences = async () => {
      try {
        setLoadingCurrentLessonData(true);
        const absences = await getLessonAbsences(currentLesson.id);
        const absencesWithDates = absences.map((absence) =>
          parseAbsenceObjDates(absence)
        );

        setCurrentLessonAbsences(absencesWithDates);
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingCurrentLessonData(false);
      }
    };
    getCurrentLessonAbsences();
  }, [currentLesson]);

  return {
    initialData,
    currentLesson,
    setCurrentLesson,
    currentLessonAbsences,
    loadingCurrentLessonData,
    refreshData,
  };
};

export default useAffectedLessonsData;
