import roleOptions from "./roleOptions";
import { Select } from "antd";
import { ModalBody } from "reactstrap";
import {
  StyledModal,
  StyledModalHeader,
} from "src/components/pages/common/GuestMap/styled";
import { ActionSubmitButton } from "src/components/Dashboard/Applications/styled";

const RoleSelectModal = ({ isOpen, toggle, value, onChange, onConfirm }) => {
  return (
    <StyledModal isOpen={isOpen} centered toggle={toggle}>
      <StyledModalHeader toggle={toggle}>Select roles</StyledModalHeader>
      <ModalBody>
        <Select
          style={{ width: "100%", marginBottom: 48 }}
          virtual={false}
          mode="multiple"
          placeholder="Select roles"
          allowClear
          options={roleOptions}
          onChange={(value) => onChange(value)}
          value={value}
        />
        <ActionSubmitButton
          type="primary"
          htmlType="button"
          size="large"
          onClick={onConfirm}
        >
          Confirm
        </ActionSubmitButton>
      </ModalBody>
    </StyledModal>
  );
};

export default RoleSelectModal;
