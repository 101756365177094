import React from "react";
import { CaretLeftFill } from "@styled-icons/bootstrap/CaretLeftFill";
import { CaretRightFill } from "@styled-icons/bootstrap/CaretRightFill";
import styled from "styled-components";

const IconContainer = styled.div`
  width: 20px;
  height: 20px;

  & svg {
    cursor: pointer;
    width: 100%;
    height: 100%;
    color: #000;
  }
`;
const AttachmentsController = ({
  hasNext,
  hasPrev,
  currentItemNumber,
  handleNext,
  handlePrev,
}) => {
  return (
    <div className="d-flex justify-content-center gap-2">
      <div>
        <IconContainer>
          {hasPrev && <CaretLeftFill onClick={handlePrev} />}
        </IconContainer>
      </div>
      <div>{currentItemNumber}</div>
      <div>
        <IconContainer>
          {hasNext && <CaretRightFill onClick={handleNext} />}
        </IconContainer>
      </div>
    </div>
  );
};

export default AttachmentsController;
