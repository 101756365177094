import { getDocs, query, where } from "firebase/firestore";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { FirebaseContext } from "src/components/Firebase";
import { absenceTypes } from "src/constants/absenceTypes";
import {
  adminCredentialsRolesList,
  UserRole,
} from "src/constants/UserRoleEnum";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getTeacherAbsences = async () => {
    try {
      const q = query(
        firebase.absences(),
        where("absenceType", "==", absenceTypes.teacherAbsence.code)
      );
      const absencesSnap = await getDocs(q);
      const absences = parseFirebaseDoc(absencesSnap.docs);

      return absences;
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    }
  };

  const getTeachers = async () => {
    try {
      const teachersQuery = query(
        firebase.users(),
        where("role", "==", UserRole.TEACHER)
      );
      const teachersSnap = await getDocs(teachersQuery);
      const teachers = parseFirebaseDoc(teachersSnap.docs);
      return teachers;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const getInstruments = async () => {
    try {
      const instrumentsSnap = await getDocs(firebase.instruments());
      const instruments = parseFirebaseDoc(instrumentsSnap.docs);
      return instruments;
    } catch (err) {
      console.log(err);
    }
  };

  const getLocations = async () => {
    try {
      const locationsSnap = await getDocs(firebase.locations());
      const locations = parseFirebaseDoc(locationsSnap.docs);
      return locations;
    } catch (err) {
      console.log(err);
    }
  };
  const getStudios = async () => {
    try {
      const studiosSnap = await getDocs(firebase.studios());
      const studios = parseFirebaseDoc(studiosSnap.docs);
      return studios;
    } catch (err) {
      console.log(err);
    }
  };

  const getPrivateAndTrialStudents = async () => {
    try {
      const plStudentsQuery = query(
        firebase.users(),
        where("role", "==", UserRole.STUDENT)
      );
      const [plStudentsSnap, tlStudentsSnap] = await Promise.all([
        getDocs(plStudentsQuery),
        getDocs(firebase.trialStudents()),
      ]);
      const plStudents = parseFirebaseDoc(plStudentsSnap.docs);
      const tlStudents = parseFirebaseDoc(tlStudentsSnap.docs);
      const students = [...plStudents, ...tlStudents];
      return students;
    } catch (err) {
      console.log(err);
    }
  };

  const getAdminCredentialsUsers = async () => {
    try {
      const q = query(
        firebase.users(),
        where("role", "in", adminCredentialsRolesList)
      );
      const snap = await getDocs(q);
      const users = parseFirebaseDoc(snap.docs);
      return users;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    getTeacherAbsences,
    getTeachers,
    getStudios,
    getInstruments,
    getLocations,
    getPrivateAndTrialStudents,
    getAdminCredentialsUsers,
  };
};

export default useFirebaseFns;
