import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import store from "src/stores/UserStore";
import { toast } from "react-toastify";
import { updateDoc } from "firebase/firestore";

import { PrimaryButton } from "src/utils/shared/styled";
import { ButtonsWrapper } from "../styled";
import {
  BelmontLocation,
  CheckboxesWrapper,
  LocationSelectWrapper,
  MapWrapper,
  StyledCheckbox,
} from "./styled";
import { FirebaseContext } from "src/components/Firebase";

const PartnerSecondPage = () => {
  const navigate = useNavigate();
  const { user, setUser } = store;
  const firebase = useContext(FirebaseContext);
  const userToUpdate = firebase.user(user.uid);

  const [belmontChecked, setBelmontChecked] = useState(
    user?.selectedLocation?.includes("Belmont")
  );
  const [newLocationChecked, setNewLocationChecked] = useState(
    user?.selectedLocation?.includes("New location")
  );
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (belmontChecked && newLocationChecked) {
      setLocation(["Belmont", "New location"]);
    } else {
      setLocation(() =>
        belmontChecked ? "Belmont" : newLocationChecked ? "New location" : ""
      );
    }
  }, [belmontChecked, newLocationChecked]);

  const handleSave = async () => {
    try {
      if (!user.birthday || !user.name || !user.primaryPhone) {
        return toast.error("Please fill all the fields on both pages!");
      }

      if (!belmontChecked && !newLocationChecked) {
        return toast.error("Please fill all the fields!");
      }

      await updateDoc(userToUpdate, {
        selectedLocation: location,
      });

      setUser({
        ...user,
        selectedLocation: location,
      });
      navigate("/dashboard/guest-profile/summary");
    } catch (error) {
      console.error(error);
      toast.error("Please fill all the fields!");
    }
  };

  return (
    <>
      <LocationSelectWrapper>
        <h4>Select a location for partnership with Artis School of Music</h4>
        <BelmontLocation>
          <MapWrapper>
            <iframe
              src="https://www.google.com/maps/d/u/3/embed?mid=19czIDyuNGSBX2f6hTv0HvVFocr7iG7w&ehbc=2E312F&noprof=1"
              width="100%"
              height="100%"
              title="Location"
            />
          </MapWrapper>
          <CheckboxesWrapper>
            <StyledCheckbox
              onChange={({ target }) => setBelmontChecked(target.checked)}
              checked={belmontChecked}
            >
              Belmont location
            </StyledCheckbox>
            <StyledCheckbox
              onChange={({ target }) => setNewLocationChecked(target.checked)}
              checked={newLocationChecked}
            >
              New location
            </StyledCheckbox>
          </CheckboxesWrapper>
        </BelmontLocation>
      </LocationSelectWrapper>
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/guest-profile")}
        >
          Previous page
        </PrimaryButton>
        <PrimaryButton backgroundColor="#681e46DD" onClick={handleSave}>
          Next page
        </PrimaryButton>
      </ButtonsWrapper>
    </>
  );
};

export default PartnerSecondPage;
