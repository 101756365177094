import { addDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { arrayUnion } from "firebase/firestore";
import { UserRole } from "src/constants/UserRoleEnum";
import { parseFirebaseDoc, getFirebaseDoc } from "src/utils/getFirebaseDoc";
import { FirebaseContext } from "src/components/Firebase";
import { logTypes } from "src/constants/logTypes";
import {
  isDeclinedAbsence,
  isPendingAbsence,
  pendingAbsenceStatusesArr,
} from "src/constants/absenceTypes";
import {
  parseAbsenceObjDates,
  parseMakeupLessonDates,
  parseMakeupOpeningDates,
} from "src/utils/firebaseDatesParserFns";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const getLocations = async () => {
    try {
      const locationsSnap = await getDocs(firebase.locations());
      const locations = getFirebaseDoc(locationsSnap);
      return locations;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeachers = async (instrument) => {
    try {
      const teachersQuery = query(
        firebase.users(),
        where("role", "==", UserRole.TEACHER)
        // where("instrumentsInfo", "!=", false)
      );
      const teachersSnap = await getDocs(teachersQuery);
      const teachers = getFirebaseDoc(teachersSnap);
      return teachers;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const getTeachersActivities = async () => {
    try {
      const activitiesQuery = query(
        firebase.usersActivities(),
        where("isActive", "==", true)
      );
      const activitiesSnap = await getDocs(activitiesQuery);
      const activities = getFirebaseDoc(activitiesSnap);
      return activities;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const createMakeupLessonDoc = async (makeupLessonObj) => {
    try {
      if (!makeupLessonObj) {
        throw { message: "Cant create an empty makeupLesson" };
      }
      const res = await addDoc(firebase.makeupLessons(), makeupLessonObj);
      return res;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const addMakeupUserActivity = async (lessonId, userActivityObj) => {
    try {
      const { userId } = userActivityObj;
      if (!userActivityObj || !userId) {
        throw { message: "Couldnt create the makeup user activity" };
      }

      const activityRes = await setDoc(
        firebase.userActivity(lessonId),
        userActivityObj
      );

      return activityRes;
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const getStudentMakeupLessons = async (studentId) => {
    try {
      if (!studentId) {
        throw { message: "couldnt find studentId" };
      }
      const makeupsQuery = query(
        firebase.makeupLessons(),
        where("studentId", "==", studentId)
      );
      const makeupsSnapshot = await getDocs(makeupsQuery);
      const makeupLessons = parseFirebaseDoc(makeupsSnapshot.docs);
      const parsed = makeupLessons.map((lsn) => parseMakeupLessonDates(lsn));
      return parsed;
    } catch (err) {
      console.log(err);
    }
  };
  const getAbsences = async () => {
    try {
      const q = query(
        firebase.absences(),
        where("status", "not-in", pendingAbsenceStatusesArr)
      );
      const absencesSnap = await getDocs(q);
      const absences = parseFirebaseDoc(absencesSnap.docs);
      const filteredAbsences = absences.filter(
        ({ status }) => !isPendingAbsence(status) && !isDeclinedAbsence(status)
      );
      const absencesWithDates = filteredAbsences.map((absence) => {
        return parseAbsenceObjDates(absence);
      });
      return absencesWithDates;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeachersMakeUpOpenings = async () => {
    try {
      const makeupOpeningsQuery = query(
        firebase.makeupOpenings(),
        where("userRole", "==", UserRole.TEACHER)
      );
      const openingsSnapshot = await getDocs(makeupOpeningsQuery);
      const makeupOpenings = parseFirebaseDoc(openingsSnapshot.docs);

      // const makeupOpeningsWithDates = makeupOpenings.map((opening) => ({
      //   ...opening,
      //   startDate: opening.startDate.toDate(),
      //   endDate: opening.endDate.toDate(),
      // }));
      const parsedDates = makeupOpenings.map((opening) =>
        parseMakeupOpeningDates(opening)
      );
      return parsedDates;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  return {
    getLocations,
    getTeachers,
    getTeachersActivities,
    createMakeupLessonDoc,
    addMakeupUserActivity,
    getStudentMakeupLessons,
    getAbsences,
    getTeachersMakeUpOpenings,
  };
};

export default useFirebaseFns;
