import {
  isAppointmentEvent,
  isGroupClassEvent,
  isMakeupLessonEvent,
  isOneTimeBreakEvent,
  isPrivateLessonEvent,
  isRecurringBreakEvent,
  isRecurringCalendarLabelEvent,
  isTrialLessonEvent,
} from "src/constants/eventsEnum";

const setEventColorByCode = (code, isCanceled) => {
  if (isTrialLessonEvent(code)) return "#C9062D";
  if (isPrivateLessonEvent(code)) return "#CD4A2C";
  if (isRecurringBreakEvent(code) || isOneTimeBreakEvent(code) || isCanceled)
    return "#989898";
  if (isMakeupLessonEvent(code)) return "#DD05D3";
  if (
    isAppointmentEvent(code) ||
    isRecurringCalendarLabelEvent(code) ||
    isGroupClassEvent(code)
  )
    return "#3174AD";
};

export default setEventColorByCode;
