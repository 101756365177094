import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Document, Page, pdfjs } from "react-pdf";
import ContentLoader from "react-content-loader";
import {
  AttachmentImageContainer,
  PDFContainer,
  VideoContainer,
} from "../../styled";
import PDFViewer from "src/components/common/Library/view-item-modal/PDFViewer";
import AttachmentItem from "./AttachmentItem";
import AttachmentsController from "./AttachmentsController";
import useAttachments from "./hooks/useAttachments";

const AttachmentSection = ({ attachments }) => {
  const {
    currentAttachment,
    setCurrentAttachmentId,
    currentAttachmentIndex,
    hasNextAttachment,
    hasPrevAttachment,
    handleNextAttachment,
    handlePrevAttachment,
  } = useAttachments({ attachments });

  if (!attachments?.length) {
    return <div></div>;
  }

  return (
    <div>
      {attachments.length > 1 && (
        <div>
          <AttachmentsController
            hasNext={hasNextAttachment}
            hasPrev={hasPrevAttachment}
            currentItemNumber={currentAttachmentIndex + 1}
            handleNext={handleNextAttachment}
            handlePrev={handlePrevAttachment}
          />
        </div>
      )}
      <div className="mt-3">
        <AttachmentItem attachment={currentAttachment} />
      </div>
    </div>
  );
};

export default AttachmentSection;
