import React from "react";
import FormSection from "./components/FormSection";

const NewStudent = ({ newStudentformData, handleNewStudentFormChange }) => {
  return (
    <div>
      <FormSection
        formData={newStudentformData}
        handleFormChange={handleNewStudentFormChange}
      />
    </div>
  );
};

export default NewStudent;
