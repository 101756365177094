import { toJS } from "mobx";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import useModal from "src/hooks/useModal";
import {
  getCurrentSchoolYear,
  getLessonWithCurrentTimeline,
  injectUserStore,
} from "src/utils/helpers";
import MakeupModal from "../../../MakeupModal";
import AbsencesSection from "./AbsencesSection";
import { Container, RedBarContainer, RedBarText } from "../styled";
import LessonInfoForm from "./LessonInfoForm";
import useMakeupData from "../hooks/useMakeupData";

const RegularLessonInfo = ({ currentStudent, currentLesson, UserStore }) => {
  const { schoolYears, instruments } = useStudentInfoContext();

  const user = toJS(UserStore)?.user;

  const makeupModalData = useModal();

  const makeUpData = useMakeupData({
    studentId: currentStudent?.id,
    privateLesson: currentLesson,
    user,
  });

  const lessonWithCurrentTimeline = useMemo(() => {
    if (!currentLesson) {
      return;
    }

    return getLessonWithCurrentTimeline({
      lesson: currentLesson,
      withApproximation: true,
    });
  }, [currentLesson]);
  // current school year based on the lesson locationId
  const currentSchoolYearInLocation = useMemo(
    () =>
      getCurrentSchoolYear(
        schoolYears,
        lessonWithCurrentTimeline?.currentTimeline?.locationId
      ),
    [schoolYears, lessonWithCurrentTimeline]
  );

  const instrument = instruments.find(
    ({ id }) => id === currentLesson?.instrumentId
  );

  const redbarName = instrument?.name;
  return (
    <>
      <Container>
        {/* Red Bar */}
        <RedBarContainer>
          <RedBarText>{redbarName || "N/A"}</RedBarText>
        </RedBarContainer>

        <LessonInfoForm currentLesson={currentLesson} makeUpData={makeUpData} />
      </Container>
      <AbsencesSection
        makeUpData={makeUpData}
        student={currentStudent}
        currentLesson={currentLesson}
        currentSchoolYearInLocation={currentSchoolYearInLocation}
        schoolYears={schoolYears}
        setCurrentAbsenceId={makeUpData.setCurrentAbsenceId}
        makeupModalData={makeupModalData}
      />

      {makeupModalData.isModalOpen && makeUpData.currentAbsence && (
        <MakeupModal
          currentAbsence={makeUpData.currentAbsence}
          makeupModalData={makeupModalData}
          lesson={currentLesson}
          onSuccess={makeUpData.refreshData}
          // shortenTimeSlots
        />
      )}
    </>
  );
};

export default injectUserStore(RegularLessonInfo);
