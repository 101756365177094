import { Radio } from "antd";

const ClassTypeSelect = ({ onChange, value }) => {
  return (
    <Radio.Group onChange={onChange} value={value}>
      <Radio value="belmont"> Artis School in Belmont CA Location</Radio>
      <Radio value="online">
        Online (artismymusic.com) *online and Artis Location possible
      </Radio>
    </Radio.Group>
  );
};

export default ClassTypeSelect;
