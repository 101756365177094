import moment, { isDuration } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  absenceBehaviours,
  absenceMakeUpOptions,
  absenceStatuses,
  absenceTypes,
  isPerDurationAbsence,
  isPerLessonAbsence,
} from "src/constants/absenceTypes";
import { UserRole } from "src/constants/UserRoleEnum";
import {
  areTwoEventsOverlapping,
  checkIfActivityDuringSchoolBreak,
  checkIfCanceledRecurringActivity,
  getCombinedSchoolBreaks,
  getCombinedSchoolBreaksFromSchoolYears,
  getFullDateFromDateAndTimeInputs,
  getLessonWithCurrentTimeline,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
  isRecurringActivityAbsence,
  mapSummerBreakToCanceledRange,
} from "src/utils/helpers";
import { isAllDayAbsence, modalAbsenceBehaviours } from "../constants";
import {
  validateMakeUpOpening,
  validatePerDurationAbsence,
} from "../helperFns";
import useFirebaseFns from "./useFirebaseFns";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import { getMainLocation } from "src/constants/constantVariables";

const useAbsenceRequestData = ({
  user,
  modalData,
  onAbsenceRequestSuccess,
  teacher,
}) => {
  const {
    createAbsence,
    getTeacherMakeupLessons,
    getTeacherTLs,
    getTeacherAvailableDays,
    createMakeUpOpening,
  } = useFirebaseFns();
  const {
    getPrivateLessonsByTeacherId,
    getInstruments,
    getLocations,
    getSchoolYears,
    getPrivateAndTrialStudents,
    getAbsences,
  } = useGlobalFirebaseFns();

  const [initialData, setInitialData] = useState({
    teacherPLs: [],
    teacherTLs: [],
    teacherMakeUpLessons: [],
    teacherAvailableDays: [],
    instruments: [],
    schoolYears: [],
    locations: [],
    combinedStudents: [],
    absences: [],
  });
  const [lodaingInitialData, setLoadingInitialData] = useState(false);

  const [selectedDate, setSelectedDate] = useState("");

  const [selectedAbsenceBehaviour, setSelectedAbsenceBehaviour] = useState(
    modalAbsenceBehaviours.ALL_DAY
  );

  const [teacherNote, setTeacherNote] = useState("");

  const [absenceBehaviourData, setAbsenceBehaviourData] = useState({
    allDay: {},
    duration: {
      startTime: "",
      endTime: "",
    },
    perLessons: {
      selectedLessons: [],
    },
  });

  const [selectedMakeUpOption, setSelectedMakeUpOption] = useState(
    absenceMakeUpOptions.MAKEUP
  );

  const [makeUpOptionsData, setMakeUpOptionsData] = useState({
    [absenceMakeUpOptions.MAKEUP]: {
      date: "",
      startTime: "",
      endTime: "",
      isVirtualOnly: false,
      location: "",
    },
    [absenceMakeUpOptions.SUBSTITUTE]: {},
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelectedDateChange = (newDate) => {
    setSelectedDate(newDate);
  };
  const handleTeacherNoteChange = (value) => {
    setTeacherNote(value);
  };

  const handleSelectedAbsenceBehaviourChange = (value) => {
    setSelectedAbsenceBehaviour(value);
  };
  const handleAbsenceBehaviourDataChange = (behaviour, name, value) => {
    setAbsenceBehaviourData((oldVal) => ({
      ...oldVal,
      [behaviour]: {
        ...oldVal[behaviour],
        [name]: value,
      },
    }));
  };

  const handleSelectedMakeUpOptionChange = (value) => {
    setSelectedMakeUpOption(value);
  };

  const handleMakeUpOptionsDataChange = (option, name, value) => {
    setMakeUpOptionsData((oldVal) => ({
      ...oldVal,
      [option]: {
        ...oldVal[option],
        [name]: value,
      },
    }));
  };

  const submitAbsenceRequest = async () => {
    try {
      if (!selectedDate) {
        toast.warn("Please enter a valid date");
        return;
      }
      if (!teacherNote) {
        toast.warn("Please enter a note for the absence");
        return;
      }

      if (!selectedMakeUpOption) {
        toast.warn("Please select a make up option");
        return;
      }
      setIsSubmitting(true);

      let makeupOpeningObj = null;
      if (selectedMakeUpOption === absenceMakeUpOptions.MAKEUP) {
        let isCompleteMakeUpOpening = true;
        const makeUpOpening = makeUpOptionsData[absenceMakeUpOptions.MAKEUP];
        const { date, startTime, endTime, isVirtualOnly, location } =
          makeUpOpening;

        const passKeys = ["isVirtualOnly"];
        Object.keys(makeUpOpening).forEach((key) => {
          if (!makeUpOpening[key] && !passKeys.includes(key))
            isCompleteMakeUpOpening = false;
        });
        if (!isCompleteMakeUpOpening) {
          toast.warn("Incomplete makeUpOpening");
          return;
        }

        const validationObjRes = validateMakeUpOpening({
          makeUpOpening,
          schoolYears: initialData.schoolYears,
          teacherDays: initialData.teacherAvailableDays,
        });

        if (validationObjRes.isValid) {
          const startDate = getFullDateFromDateAndTimeInputs(date, startTime);
          const endDate = getFullDateFromDateAndTimeInputs(date, endTime);
          makeupOpeningObj = {
            startDate,
            endDate,
            location: makeUpOpening.location,
            isVirtualOnly: makeUpOpening.isVirtualOnly,
            userId: teacher.id,
            userRole: UserRole.TEACHER,
          };

          // const openingId = await createMakeUpOpening(makeupOpeningObj);
        } else {
          toast.error(validationObjRes.message);
          return;
        }
      }

      if (isAllDayAbsence(selectedAbsenceBehaviour)) {
        const startDate = getFullDateFromDateAndTimeInputs(
          selectedDate,
          "00:00"
        );
        const endDate = getFullDateFromDateAndTimeInputs(selectedDate, "23:59");
        const absenceObj = {
          createdAt: new Date(),
          absenceType: absenceTypes.teacherAbsence.code, // Number
          status: absenceStatuses.SUBMITTED, // Number
          userId: user.uid,
          userRole: user.role,
          teacherId: teacher.id,
          makeupOption: selectedMakeUpOption,
          ...(selectedMakeUpOption === absenceMakeUpOptions.MAKEUP && {
            makeupOpening: makeupOpeningObj,
          }),
          teacherNote,
          request: {
            startDate,
            endDate,
            absenceBehaviour: absenceBehaviours.PER_DURATION, // Number
            affectedPrivateLessonsIds,
            affectedMakeupLessonsIds,
            affectedTrialLessonsIds,
          },
        };
        console.log({ absenceObj });
        await createAbsence(absenceObj);
      } else if (isPerDurationAbsence(selectedAbsenceBehaviour)) {
        const { isValid: isValidDurationAbsence } = validatePerDurationAbsence(
          absenceBehaviourData.duration.startTime,
          absenceBehaviourData.duration.endTime
        );
        if (!isValidDurationAbsence) {
          toast.warn("Invalid absence duration");
          return;
        }
        const startDate = getFullDateFromDateAndTimeInputs(
          selectedDate,
          absenceBehaviourData.duration.startTime
        );
        const endDate = getFullDateFromDateAndTimeInputs(
          selectedDate,
          absenceBehaviourData.duration.endTime
        );
        const absenceObj = {
          createdAt: new Date(),

          absenceType: absenceTypes.teacherAbsence.code, // Number
          status: absenceStatuses.SUBMITTED, // Number
          userId: user.uid,
          userRole: UserRole.TEACHER,

          teacherId: teacher.id,
          makeupOption: selectedMakeUpOption,
          ...(selectedMakeUpOption === absenceMakeUpOptions.MAKEUP && {
            makeupOpening: makeupOpeningObj,
          }),
          teacherNote,
          request: {
            startDate,
            endDate,
            absenceBehaviour: absenceBehaviours.PER_DURATION, // Number
            affectedPrivateLessonsIds,
            affectedMakeupLessonsIds,
            affectedTrialLessonsIds,
          },
        };
        console.log({ absenceObj });
        await createAbsence(absenceObj);
      } else if (isPerLessonAbsence(selectedAbsenceBehaviour)) {
        const isValidPerLessonsAbsence =
          !!absenceBehaviourData.perLessons.selectedLessons.length;

        if (!isValidPerLessonsAbsence) {
          toast.warn("No lessons were selected for the absence");
          return;
        }

        const absenceObjsArr = [];
        for (const selectedLesson of absenceBehaviourData.perLessons
          .selectedLessons) {
          const lessonObj = initialData.teacherPLs?.find(
            ({ id }) => id === selectedLesson?.value
          );
          const studentId = lessonObj.studentId;
          const { startDate } = getPrivateLessonInfoOnSpecificDate({
            privateLesson: lessonObj,
            date: selectedDate,
            withTimelineApproximation: true,
          });

          const lessonStartHrs = moment(startDate).get("hours");
          const lessonStartMins = moment(startDate).get("minutes");

          const absenceDate = moment(selectedDate)
            .set({
              hours: lessonStartHrs,
              minutes: lessonStartMins,
            })
            .toDate();
          absenceObjsArr.push({
            createdAt: new Date(),
            absenceType: absenceTypes.teacherAbsence.code, // Number
            status: absenceStatuses.SUBMITTED, // Number
            userId: user.uid,
            userRole: UserRole.TEACHER,

            teacherId: teacher.id,
            studentId,
            teacherNote,

            makeupOption: selectedMakeUpOption,
            ...(selectedMakeUpOption === absenceMakeUpOptions.MAKEUP && {
              makeupOpening: makeupOpeningObj,
            }),
            request: {
              date: absenceDate,
              absenceBehaviour: absenceBehaviours.PER_LESSON, // Number
              lessonId: selectedLesson?.value,
            },
          });
        }
        console.log({ absenceObjsArr });
        await Promise.all(
          absenceObjsArr.map((absenceObj) => createAbsence(absenceObj))
        );
      } else {
        toast.error("Can't find selected absence behaviour");
        return;
      }
      toast.success("Absence Requested Successfully");
      onAbsenceRequestSuccess();
      modalData.closeModal();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // fetches initial modal data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);
        const [
          teacherPLs,
          teacherMakeUpLessons,
          teacherTLs,
          teacherAvailableDays,
          instruments,
          schoolYears,
          locations,
          combinedStudents,
          absences,
        ] = await Promise.all([
          getPrivateLessonsByTeacherId(teacher.id),
          getTeacherMakeupLessons(teacher.id),
          getTeacherTLs(teacher.id),
          getTeacherAvailableDays(teacher.id),
          getInstruments(),
          getSchoolYears(),
          getLocations(),
          getPrivateAndTrialStudents(),
          getAbsences(),
        ]);

        setInitialData((oldVal) => ({
          ...oldVal,
          teacherPLs,
          teacherMakeUpLessons,
          teacherTLs,
          teacherAvailableDays,
          instruments,
          schoolYears,
          locations,
          combinedStudents,
          absences,
        }));
      } catch (err) {
        console.log(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (isAllDayAbsence(selectedAbsenceBehaviour)) {
      setAbsenceBehaviourData((oldVal) => ({
        ...oldVal,
        allDay: {},
        duration: {
          startTime: "",
          endTime: "",
        },
        perLessons: {
          selectedLessons: [],
        },
      }));
    } else if (isPerDurationAbsence(selectedAbsenceBehaviour)) {
      setAbsenceBehaviourData((oldVal) => ({
        ...oldVal,
        allDay: {},
        duration: {
          startTime: "",
          endTime: "",
        },
        perLessons: {
          selectedLessons: [],
        },
      }));
    } else {
      // per lessons
      setAbsenceBehaviourData((oldVal) => ({
        ...oldVal,
        allDay: {},
        duration: {
          startTime: "",
          endTime: "",
        },
        perLessons: {
          // selectedLessons :[ {label: '', value: ''} ]
          selectedLessons: [],
        },
      }));
    }
  }, [selectedAbsenceBehaviour]);

  // AFFECTED PLs
  const affectedPrivateLessonsIds = useMemo(() => {
    if (isPerLessonAbsence(selectedAbsenceBehaviour)) {
      return [];
    }

    let absenceStartTime, absenceEndTime;
    if (isAllDayAbsence(selectedAbsenceBehaviour) && selectedDate) {
      absenceStartTime = "00:00";
      absenceEndTime = "23:59";
    } else {
      absenceStartTime = absenceBehaviourData.duration.startTime;
      absenceEndTime = absenceBehaviourData.duration.endTime;
    }

    let affectedLessons = [];
    if (selectedDate && absenceStartTime && absenceEndTime) {
      const absenceStartDate = getFullDateFromDateAndTimeInputs(
        selectedDate,
        absenceStartTime
      );
      const absenceEndDate = getFullDateFromDateAndTimeInputs(
        selectedDate,
        absenceEndTime
      );
      affectedLessons = initialData.teacherPLs?.filter((lsn) => {
        const currentPLInfo = getPrivateLessonInfoOnSpecificDate({
          privateLesson: lsn,
          date: selectedDate,
          withTimelineApproximation: true,
        });
        const {
          startDate: lessonStartDate,
          duration,
          teacherId,
        } = currentPLInfo;

        const lessonEndDate = lessonStartDate
          ? moment(lessonStartDate).add(parseInt(duration), "minutes").toDate()
          : undefined;

        const lessonStartOnAbsenceDate = lessonStartDate
          ? moment(absenceStartDate)
              .set({
                hours: moment(lessonStartDate).hours(),
                minutes: moment(lessonStartDate).minutes(),
              })
              .toDate()
          : undefined;
        const lessonEndOnAbsenceDate = lessonStartOnAbsenceDate
          ? moment(lessonStartOnAbsenceDate)
              .add(parseInt(duration), "minutes")
              .toDate()
          : undefined;

        const isSameTeacher = teacherId === teacher.id;
        if (!isSameTeacher || !lessonStartDate || !lessonEndDate) return false;

        const isAbsence = initialData.absences.some((absence) => {
          const absenceFound = isRecurringActivityAbsence(
            {
              start: lessonStartOnAbsenceDate,
              end: lessonEndOnAbsenceDate,
              id: lsn.id,
            },
            absence,
            { excludeTA: false, excludeGCAbsence: true }
          );

          return absenceFound;
        });

        const isDuringSummerBreak = checkIfCanceledRecurringActivity({
          occuranceDate: lessonStartOnAbsenceDate,
          canceledDateRanges: lsn.summerBreaks?.map((summerBreak) =>
            mapSummerBreakToCanceledRange(summerBreak)
          ),
          granularity: "[)",
        });
        const schoolBreaks = getCombinedSchoolBreaks(
          initialData.schoolYears,
          getMainLocation(initialData.locations)?.id
        );

        const isDuringSchoolBreak = schoolBreaks?.some((schoolBreak) =>
          checkIfActivityDuringSchoolBreak(
            { start: lessonStartOnAbsenceDate },
            schoolBreak
          )
        );

        const isWithdrawn = checkIfCanceledRecurringActivity({
          occuranceDate: lessonStartOnAbsenceDate,
          canceledAt: lsn?.withdrawal?.withdrawalDate,
        });

        if (
          isWithdrawn ||
          isAbsence ||
          isDuringSummerBreak ||
          isDuringSchoolBreak
        )
          return false;

        return areTwoEventsOverlapping(
          {
            start_time: absenceStartDate,
            end_time: absenceEndDate,
            isRecurring: false,
          },
          {
            start_time: lessonStartDate,
            end_time: lessonEndDate,
            isRecurring: true,
          }
        );
      });
    }
    return affectedLessons?.map(({ id }) => id) || [];
  }, [
    selectedDate,
    selectedAbsenceBehaviour,
    absenceBehaviourData,
    initialData,
  ]);
  // AFFECTED TLs
  const affectedTrialLessonsIds = useMemo(() => {
    if (isPerLessonAbsence(selectedAbsenceBehaviour)) {
      return [];
    }

    let absenceStartTime, absenceEndTime;
    if (isAllDayAbsence(selectedAbsenceBehaviour) && selectedDate) {
      absenceStartTime = "00:00";
      absenceEndTime = "23:59";
    } else {
      absenceStartTime = absenceBehaviourData.duration.startTime;
      absenceEndTime = absenceBehaviourData.duration.endTime;
    }

    let affectedLessons = [];
    if (selectedDate && absenceStartTime && absenceEndTime) {
      const absenceStartDate = getFullDateFromDateAndTimeInputs(
        selectedDate,
        absenceStartTime
      );
      const absenceEndDate = getFullDateFromDateAndTimeInputs(
        selectedDate,
        absenceEndTime
      );

      affectedLessons = initialData.teacherTLs?.filter((lsn) => {
        const lessonStartDate = lsn.date;
        const lessonEndDate = moment(lessonStartDate)
          .add(parseInt(lsn.lessonLength), "minutes")
          .toDate();

        if (!lessonStartDate || !lessonEndDate) return false;

        return areTwoEventsOverlapping(
          {
            start_time: absenceStartDate,
            end_time: absenceEndDate,
            isRecurring: false,
          },
          {
            start_time: lessonStartDate,
            end_time: lessonEndDate,
            isRecurring: false,
          }
        );
      });
    }
    return affectedLessons?.map(({ id }) => id) || [];
  }, [
    selectedDate,
    selectedAbsenceBehaviour,
    absenceBehaviourData,
    initialData.teacherTLs,
  ]);

  // AFFECTED MAKE UPS
  const affectedMakeupLessonsIds = useMemo(() => {
    if (isPerLessonAbsence(selectedAbsenceBehaviour)) {
      return [];
    }

    let absenceStartTime, absenceEndTime;
    if (isAllDayAbsence(selectedAbsenceBehaviour) && selectedDate) {
      absenceStartTime = "00:00";
      absenceEndTime = "23:59";
    } else {
      absenceStartTime = absenceBehaviourData.duration.startTime;
      absenceEndTime = absenceBehaviourData.duration.endTime;
    }

    let affectedLessons = [];
    if (selectedDate && absenceStartTime && absenceEndTime) {
      const absenceStartDate = getFullDateFromDateAndTimeInputs(
        selectedDate,
        absenceStartTime
      );
      const absenceEndDate = getFullDateFromDateAndTimeInputs(
        selectedDate,
        absenceEndTime
      );

      affectedLessons = initialData.teacherMakeUpLessons?.filter((lsn) => {
        const lessonStartDate = lsn.date.startDate;
        const lessonEndDate = moment(lessonStartDate)
          .add(parseInt(lsn.date?.lessonLength), "minutes")
          .toDate();

        if (!lessonStartDate || !lessonEndDate) return false;

        return areTwoEventsOverlapping(
          {
            start_time: absenceStartDate,
            end_time: absenceEndDate,
            isRecurring: false,
          },
          {
            start_time: lessonStartDate,
            end_time: lessonEndDate,
            isRecurring: false,
          }
        );
      });
    }
    return affectedLessons?.map(({ id }) => id) || [];
  }, [
    selectedDate,
    selectedAbsenceBehaviour,
    absenceBehaviourData,
    initialData.teacherMakeUpLessons,
  ]);

  // filters pls to show same weekday lsns as absence selectedDate + adds student and instrument obj to each lsn + sort ascendengly (earlier first)
  const teacherPLsOnAbsenceDate = useMemo(() => {
    if (!selectedDate) return [];

    return initialData.teacherPLs
      ?.filter((lesson) => {
        const {
          startDate: lessonStart,
          duration,
          teacherId,
        } = getPrivateLessonInfoOnSpecificDate({
          privateLesson: lesson,
          date: selectedDate,
          withTimelineApproximation: true,
        });

        if (!lessonStart) return false;

        const sameWeekDay =
          moment(selectedDate).get("weekday") ===
          moment(lessonStart).get("weekday");
        const sameTeacher = teacherId === teacher.id;
        return sameWeekDay && sameTeacher;
      })

      ?.sort((a, b) => {
        const { startDate: firstLessonStart } =
          getPrivateLessonInfoOnSpecificDate({
            privateLesson: a,
            date: selectedDate,
            withTimelineApproximation: true,
          });
        const { startDate: secondLessonStart } =
          getPrivateLessonInfoOnSpecificDate({
            privateLesson: b,
            date: selectedDate,
            withTimelineApproximation: true,
          });

        return getTimeDiffInMins(firstLessonStart, secondLessonStart);
      });
  }, [initialData, selectedDate]);

  return {
    initialData,
    lodaingInitialData,
    selectedDate,
    handleSelectedDateChange,
    selectedAbsenceBehaviour,
    handleSelectedAbsenceBehaviourChange,
    absenceBehaviourData,
    handleAbsenceBehaviourDataChange,
    makeUpOptionsData,
    handleMakeUpOptionsDataChange,
    teacherNote,
    handleTeacherNoteChange,
    selectedMakeUpOption,
    handleSelectedMakeUpOptionChange,
    makeUpOptionsData,
    handleMakeUpOptionsDataChange,
    submitAbsenceRequest,
    isSubmitting,
    affectedPrivateLessonsIds,
    affectedMakeupLessonsIds,
    affectedTrialLessonsIds,
    teacherPLsOnAbsenceDate,
  };
};

export default useAbsenceRequestData;
