import React from "react";

import {
  CustomInput,
  CustomTextarea,
  PrimaryButton,
} from "src/utils/shared/styled";
import { CustomLabel } from "src/utils/styled";
import styled from "styled-components";
import { InfoText } from "../../../styled";
import {
  isApprovedMakeupRequest,
  isDeclinedMakeupRequest,
  isPendingMakeupRequest,
  makeupRequestStatuses,
} from "src/constants/makeupRequestEnum";
import { PENDING_COLOR } from "src/components/Dashboard/MakeupRequests/constants";
import { APPROVED_COLOR } from "src/components/Dashboard/MakeupRequests/constants";
import { DECLINED_COLOR } from "src/components/Dashboard/MakeupRequests/constants";

const ButtonsContainer = styled.div`
  background: #f1f1f1;
  border-radius: 15px;
  padding: 10px;
`;
const ButtonGroupContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 15px;
`;
const ActionsSection = ({
  selectedAction,
  setSelectedAction,
  reason,
  setReason,
}) => {
  // statusses
  const isPendingAction = isPendingMakeupRequest(selectedAction);
  const isDeclinedAction = isDeclinedMakeupRequest(selectedAction);
  const isApprovedAction = isApprovedMakeupRequest(selectedAction);

  return (
    <div>
      <ButtonsContainer>
        <ButtonGroupContainer>
          {/* Shows either the submitted status btn or the in progress */}
          <PrimaryButton
            onClick={() => setSelectedAction(makeupRequestStatuses.PENDING)}
            backgroundColor={isPendingAction ? PENDING_COLOR : "transparent"}
            textColor={isPendingAction ? "#fff" : PENDING_COLOR}
            padding="5px"
            width="120px"
          >
            Pending
          </PrimaryButton>
          <PrimaryButton
            onClick={() => setSelectedAction(makeupRequestStatuses.APPROVED)}
            backgroundColor={isApprovedAction ? APPROVED_COLOR : "transparent"}
            textColor={isApprovedAction ? "#fff" : APPROVED_COLOR}
            padding="5px"
            width="120px"
          >
            Approve
          </PrimaryButton>
        </ButtonGroupContainer>
        <ButtonGroupContainer>
          <PrimaryButton
            onClick={() => setSelectedAction(makeupRequestStatuses.DECLINED)}
            backgroundColor={isDeclinedAction ? DECLINED_COLOR : "transparent"}
            textColor={isDeclinedAction ? "#fff" : DECLINED_COLOR}
            padding="5px"
            width="120px"
          >
            Decline
          </PrimaryButton>
        </ButtonGroupContainer>
      </ButtonsContainer>

      {isDeclinedAction ? (
        <div className="mt-4">
          <CustomLabel>Decline Reason:</CustomLabel>
          <CustomTextarea
            width="100%"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ActionsSection;
