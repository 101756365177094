import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  absenceBehaviours,
  absenceMakeUpOptions,
  isApprovedAbsence,
  isDeclinedAbsence,
  isInProgressAbsence,
  isPartiallyApprovedAbsence,
  isPerDurationAbsence,
  isPerLessonAbsence,
  isSubmittedAbsence,
} from "src/constants/absenceTypes";
import { eventsMap } from "src/constants/eventsEnum";
import { UserRole } from "src/constants/UserRoleEnum";
import useGlobalFirebaseFns from "src/hooks/useFirebaseFns";
import {
  parseMakeupLessonDates,
  parsePrivateLessonDates,
  parseTrialLessonDates,
} from "src/utils/firebaseDatesParserFns";
import {
  areTwoEventsOverlapping,
  getFullDateFromDateAndTimeInputs,
  getPrivateLessonInfoOnSpecificDate,
  getTimeDiffInMins,
} from "src/utils/helpers";
import useFirebaseFns from "./useFirebaseFns";
import {
  confirmAbsenceApproval,
  convertTimeStringToNumeric,
} from "../../../../helperFns";

const MINUTES_IN_DAY = 1440;
const useAbsenceDetailsData = ({
  user,
  absencesData,
  modalData,
  refreshData,
}) => {
  const absence = absencesData.currentAbsence;
  const {
    request,
    status: absenceStatus,
    makeupOpening,
    makeupOption,
  } = absence;
  const absenceDate =
    absence.date ||
    absence.startDate ||
    absence.request?.date ||
    absence.request?.startDate;

  const {
    affectedPrivateLessonsIds = [],
    affectedTrialLessonsIds = [],
    affectedMakeupLessonsIds = [],
    lessonId,
  } = request || {};
  const isPerLesson = isPerLessonAbsence(
    absence.absenceBehaviour || absence.request?.absenceBehaviour
  );

  const {
    getPrivateLessonsByIds,
    getMakeupLessonsByIds,
    getTrialLessonsByIds,
    getPrivateLessonById,
    updateAbsence,
    cancelTrialLesson,
    createMakeupOpening,
    deleteMakeupLessonAndAddAbsenceComment,
  } = useFirebaseFns();
  const {
    createCalendarLabel,
    getSchoolNotificationSettingsByType,
    getUserById,
  } = useGlobalFirebaseFns();

  const [initialData, setInitialData] = useState({
    //in case of per lsn absence
    absencePrivateLesson: undefined,

    // duration absences
    affectedPLs: [],
    affectedTLs: [],
    affectedMakeupLessons: [],
  });
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  // reflects the action the admin will take (Approve/decline/partiallyApprove)
  const [selectedAction, setSelectedAction] = useState(absenceStatus);

  // values if admin selected partially approve action
  const [partiallyApprovedValues, setPartiallyApprovedValues] = useState({
    startTime: "",
    endTime: "",
  });

  // adminNote => should be displayed for teachers
  // comment => should be displayed for other admins (on pl page)
  const [adminNote, setAdminNote] = useState("");
  const [comment, setComment] = useState("");

  const [loadingSubmitAbsence, setLoadingSubmitAbsence] = useState(false);
  const [emailConfirmationDisabled, setEmailConfirmationDisabled] =
    useState(false);
  const [currentTeacher, setCurrentTeacher] = useState(null);

  const handlePartiallyApprovedValuesChange = (name, value) => {
    setPartiallyApprovedValues((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  };

  const submitAbsenceRequest = async () => {
    try {
      setLoadingSubmitAbsence(true);

      const isSubmittedAction = isSubmittedAbsence(selectedAction);
      const isInProgressAction = isInProgressAbsence(selectedAction);
      const isDeclinedAction = isDeclinedAbsence(selectedAction);
      const isApprovedAction = isApprovedAbsence(selectedAction);
      const isPartiallyApprovedAction =
        isPartiallyApprovedAbsence(selectedAction);

      const resolveObj = {
        resolvedAt: new Date(),
        userId: user?.uid,
        userRole: user?.role,
      };

      let updateObj;
      if (isSubmittedAction || isInProgressAction || isDeclinedAction) {
        updateObj = {
          updatedAt: new Date(),
          status: selectedAction,
          adminNote,
          comment,
          resolve: resolveObj,
        };
      } else if (isApprovedAction) {
        updateObj = {
          ...request,
          status: selectedAction,
          updatedAt: new Date(),
          adminNote,
          comment,
          resolve: resolveObj,
        };
      } else if (isPartiallyApprovedAction) {
        const absenceStartDate = getFullDateFromDateAndTimeInputs(
          absenceDate,
          partiallyApprovedValues.startTime
        );
        const absenceEndDate = getFullDateFromDateAndTimeInputs(
          absenceDate,
          partiallyApprovedValues.endTime
        );
        updateObj = {
          status: selectedAction,
          startDate: absenceStartDate,
          endDate: absenceEndDate,
          absenceBehaviour: absenceBehaviours.PER_DURATION,
          affectedPrivateLessonsIds: partiallyApprovedPrivateLessonsIds,
          affectedTrialLessonsIds: partiallyApprovedTrialLessonsIds,
          affectedMakeupLessonsIds: partiallyApprovedMakeupLessonsIds,
          adminNote,
          comment,
          resolve: resolveObj,
          updatedBy: user.uid,
        };
      }

      await updateAbsence(absence.id, updateObj);

      let afterAbsenceReqs = [];
      if (isPartiallyApprovedAction || isApprovedAction) {
        if (makeupOption === absenceMakeUpOptions.MAKEUP) {
          afterAbsenceReqs.push(createMakeupOpening(makeupOpening));
        }

        // if absence partially approved , we use the partially approved affected lessons instead of the original absence's affected lessons
        const updatedAffectedTLs = isPartiallyApprovedAction
          ? partiallyApprovedTrialLessonsIds
          : affectedTrialLessonsIds;
        const updatedAffectedMakeupLessons = isPartiallyApprovedAction
          ? partiallyApprovedMakeupLessonsIds
          : affectedMakeupLessonsIds;

        // deleting affected makeup lessons
        updatedAffectedMakeupLessons.forEach((makeupLessonId) => {
          const makeupLesson = initialData.affectedMakeupLessons?.find(
            ({ id }) => id === makeupLessonId
          );
          const makeupStartDate = makeupLesson?.date?.startDate;
          const formattedDate = moment(makeupStartDate).format("MM-DD-YYYY");
          const formattedStartTime = moment(makeupStartDate).format("hh:mm A");

          const commentBody = `DELETED - Makeup lesson on: ${formattedDate} at ${formattedStartTime} due to Teacher Absence`;
          const req = deleteMakeupLessonAndAddAbsenceComment(
            makeupLessonId,
            makeupLesson?.forAbsenceId,
            commentBody
          );
          afterAbsenceReqs.push(req);
        });

        // disabling affected trial lessons
        updatedAffectedTLs.forEach((trialLessonId) => {
          const req = cancelTrialLesson(trialLessonId);
          afterAbsenceReqs.push(req);
        });

        // ADD Calendar label
        const labelTitleTime = moment(
          updateObj.date || updateObj.startDate
        ).format("hh:mm A");

        const labelNote = isPerLessonAbsence(updateObj.absenceBehaviour)
          ? getPerLessonAbsenceCalendarLabelNote()
          : isPerDurationAbsence(updateObj.absenceBehaviour)
          ? `TA- ${moment(updateObj.startDate).format("hh:mm A")} - ${moment(
              updateObj.endDate
            ).format("hh:mm A")}`
          : "TA- N/A";

        const labelObj = {
          createdAt: new Date(),
          userRole: UserRole.TEACHER,
          userId: absence.teacherId,
          date: updateObj.date || updateObj.startDate,
          note: labelNote,
          title: `TA - ${labelTitleTime}`,
          requestedBy: user.uid,
          type: eventsMap.calendarLabel.code,
        };

        const labelReq = createCalendarLabel(labelObj);
        afterAbsenceReqs.push(labelReq);
      }

      const [] = await Promise.all(afterAbsenceReqs);
      // opens the affected lessons modal if the absence is either approved or partially approved  + the SUBSTITUTE makeup option is selected
      if (
        (isPartiallyApprovedAction || isApprovedAction) &&
        absence.makeupOption === absenceMakeUpOptions.SUBSTITUTE
      ) {
        absencesData.setJustApprovedAbsenceId(absence.id);
      }

      if (
        !emailConfirmationDisabled &&
        (isApprovedAction || isPartiallyApprovedAction)
      ) {
        confirmAbsenceApproval({
          type: isPartiallyApprovedAction ? "PA" : "approved",
          to_email: currentTeacher?.primaryEmail,
          to_name: currentTeacher?.fullName,
          from_name: user?.fullName,
          time: `
          From: ${convertTimeStringToNumeric(
            partiallyApprovedValues?.startTime
          )}
          To: ${convertTimeStringToNumeric(partiallyApprovedValues?.endTime)}
          `,
          message: adminNote,
          date: absence?.request?.startDate,
        });
      }

      toast.success("Saved Successfully");
      refreshData();
      modalData.closeModal();
    } catch (err) {
      console.error(err);
      toast.error(err?.message);
    } finally {
      setLoadingSubmitAbsence(false);
    }
  };

  const getPerLessonAbsenceCalendarLabelNote = () => {
    if (!initialData.absencePrivateLesson) return "N/A";
    const lesson = initialData.absencePrivateLesson;

    const { startDate, duration } = getPrivateLessonInfoOnSpecificDate({
      privateLesson: lesson,
      date: absenceDate,
      withTimelineApproximation: true,
    });

    const endDate = startDate
      ? moment(startDate).add(parseInt(duration), "minutes")
      : undefined;

    const formattedLessonStartTime = moment(startDate).format("hh:mm A");
    const formattedLessonEndTime = moment(endDate).format("hh:mm A");

    const studentName = absencesData.initialData.combinedStudents?.find(
      ({ id }) => id === lesson.studentId
    )?.fullName;

    const instrumentName = absencesData.initialData.instruments?.find(
      ({ id }) => id === lesson.instrumentId
    )?.name;
    const note = `TA - ${studentName} (${instrumentName}) ${formattedLessonStartTime} - ${formattedLessonEndTime}`;
    return note;
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoadingInitialData(true);

        const [
          absencePrivateLesson,
          affectedPLs,
          affectedTLs,
          affectedMakeupLessons,
        ] = await Promise.all([
          isPerLesson ? getPrivateLessonById(lessonId) : undefined,
          !isPerLesson ? getPrivateLessonsByIds(affectedPrivateLessonsIds) : [],
          !isPerLesson ? getTrialLessonsByIds(affectedTrialLessonsIds) : [],
          !isPerLesson ? getMakeupLessonsByIds(affectedMakeupLessonsIds) : [],
        ]);

        const emailSettings = await getSchoolNotificationSettingsByType(
          "email"
        );

        if (emailSettings?.disabledList?.includes("absenceConfirmation")) {
          setEmailConfirmationDisabled(true);
        }

        const privateLessonWithDates = absencePrivateLesson
          ? parsePrivateLessonDates(absencePrivateLesson)
          : undefined;

        const privateLessonsWithDates = affectedPLs.map((lsn) => {
          const lessonWithDates = parsePrivateLessonDates(lsn);
          return lessonWithDates;
        });

        const trialLessonsWithDates = affectedTLs.map((lsn) => {
          const lessonWithDates = parseTrialLessonDates(lsn);
          return lessonWithDates;
        });
        const makeupLessonsWithDates = affectedMakeupLessons.map((lsn) => {
          const lessonWithDates = parseMakeupLessonDates(lsn);
          return lessonWithDates;
        });

        const teacher = await getUserById(absence?.teacherId);

        setCurrentTeacher(teacher);
        setInitialData((oldVal) => ({
          ...oldVal,
          absencePrivateLesson: privateLessonWithDates,
          affectedPLs: privateLessonsWithDates,
          affectedTLs: trialLessonsWithDates,
          affectedMakeupLessons: makeupLessonsWithDates,
        }));
      } catch (err) {
        console.error(err);
        toast.error(err?.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetchInitialData();
  }, []);

  // Partially approved PLs
  const partiallyApprovedPrivateLessonsIds = useMemo(() => {
    if (
      !initialData.affectedPLs?.length ||
      !partiallyApprovedValues.startTime ||
      !partiallyApprovedValues.endTime ||
      !isPartiallyApprovedAbsence(selectedAction)
    ) {
      return [];
    }
    const partiallyApprovedDiff = getTimeDiffInMins(
      moment(partiallyApprovedValues.startTime, "HH:mm"),
      moment(partiallyApprovedValues.endTime, "HH:mm")
    );

    // calculating the absence duration (supports if the end is after the start date (next day))
    const partiallyApprovedDuration =
      partiallyApprovedDiff >= 0
        ? partiallyApprovedDiff
        : partiallyApprovedDiff + MINUTES_IN_DAY;

    const absenceStartDate = getFullDateFromDateAndTimeInputs(
      absenceDate,
      partiallyApprovedValues.startTime
    );
    const absenceEndDate = moment(absenceStartDate).add(
      partiallyApprovedDuration,
      "minutes"
    );

    // filtered Affected lessons will filter existing affected lessons (the new affected lesson will be either the same list or a smaller list of lessons (because we decrease the start-end time))

    const filteredAffectedLessons = initialData.affectedPLs?.filter((pl) => {
      const { startDate: lessonStartDate, duration } =
        getPrivateLessonInfoOnSpecificDate({
          privateLesson: pl,
          date: absenceDate,
          withTimelineApproximation: true,
        });

      const lessonEndDate = lessonStartDate
        ? moment(lessonStartDate).add(parseInt(duration), "minutes")
        : undefined;

      const isAffectedLesson = areTwoEventsOverlapping(
        {
          start_time: absenceStartDate,
          end_time: absenceEndDate,
          isRecurring: false,
        },
        {
          start_time: lessonStartDate,
          end_time: lessonEndDate,
          isRecurring: true,
        }
      );

      return isAffectedLesson;
    });
    return filteredAffectedLessons?.map(({ id }) => id) || [];
  }, [initialData.affectedPLs, partiallyApprovedValues]);

  // Partially approved TLs
  const partiallyApprovedTrialLessonsIds = useMemo(() => {
    if (
      !initialData.affectedTLs?.length ||
      !partiallyApprovedValues.startTime ||
      !partiallyApprovedValues.endTime ||
      !isPartiallyApprovedAbsence(selectedAction)
    ) {
      return [];
    }

    const partiallyApprovedDiff = getTimeDiffInMins(
      moment(partiallyApprovedValues.startTime, "HH:mm"),
      moment(partiallyApprovedValues.endTime, "HH:mm")
    );

    // calculating the absence duration (supports if the end is after the start date (next day))
    const partiallyApprovedDuration =
      partiallyApprovedDiff >= 0
        ? partiallyApprovedDiff
        : partiallyApprovedDiff + MINUTES_IN_DAY;

    const absenceStartDate = getFullDateFromDateAndTimeInputs(
      absenceDate,
      partiallyApprovedValues.startTime
    );
    const absenceEndDate = moment(absenceStartDate).add(
      partiallyApprovedDuration,
      "minutes"
    );

    const filteredAffectedLessons = initialData.affectedTLs?.filter((lsn) => {
      const lessonStartDate = lsn.date;
      const lessonEndDate = moment(lessonStartDate)
        .add(parseInt(lsn.lessonLength), "minutes")
        .toDate();

      const isAffectedLesson = areTwoEventsOverlapping(
        {
          start_time: absenceStartDate,
          end_time: absenceEndDate,
          isRecurring: false,
        },
        {
          start_time: lessonStartDate,
          end_time: lessonEndDate,
          isRecurring: false,
        }
      );
      return isAffectedLesson;
    });

    return filteredAffectedLessons?.map(({ id }) => id) || [];
  }, [initialData.affectedTLs, partiallyApprovedValues]);

  // Partially approved Make Ups
  const partiallyApprovedMakeupLessonsIds = useMemo(() => {
    if (
      !initialData.affectedMakeupLessons?.length ||
      !partiallyApprovedValues.startTime ||
      !partiallyApprovedValues.endTime ||
      !isPartiallyApprovedAbsence(selectedAction)
    ) {
      return [];
    }

    const partiallyApprovedDiff = getTimeDiffInMins(
      moment(partiallyApprovedValues.startTime, "HH:mm"),
      moment(partiallyApprovedValues.endTime, "HH:mm")
    );

    // calculating the absence duration (supports if the end is after the start date (next day))
    const partiallyApprovedDuration =
      partiallyApprovedDiff >= 0
        ? partiallyApprovedDiff
        : partiallyApprovedDiff + MINUTES_IN_DAY;

    const absenceStartDate = getFullDateFromDateAndTimeInputs(
      absenceDate,
      partiallyApprovedValues.startTime
    );
    const absenceEndDate = moment(absenceStartDate).add(
      partiallyApprovedDuration,
      "minutes"
    );

    const filteredAffectedLessons = initialData.affectedMakeupLessons?.filter(
      (lsn) => {
        const lessonStartDate = lsn.date.startDate;
        const lessonEndDate = moment(lessonStartDate)
          .add(parseInt(lsn.date?.lessonLength), "minutes")
          .toDate();

        const isAffectedLesson = areTwoEventsOverlapping(
          {
            start_time: absenceStartDate,
            end_time: absenceEndDate,
            isRecurring: false,
          },
          {
            start_time: lessonStartDate,
            end_time: lessonEndDate,
            isRecurring: false,
          }
        );
        return isAffectedLesson;
      }
    );

    return filteredAffectedLessons?.map(({ id }) => id) || [];
  }, [initialData.affectedMakeupLessons, partiallyApprovedValues]);

  return {
    initialData,
    loadingInitialData,
    selectedAction,
    setSelectedAction,
    partiallyApprovedValues,
    handlePartiallyApprovedValuesChange,
    partiallyApprovedPrivateLessonsIds,
    partiallyApprovedTrialLessonsIds,
    partiallyApprovedMakeupLessonsIds,
    submitAbsenceRequest,
    loadingSubmitAbsence,
    adminNote,
    setAdminNote,
    comment,
    setComment,
  };
};

export default useAbsenceDetailsData;
