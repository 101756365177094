import { useEffect, useState } from "react";
import { teachers, admins } from "./staffInfo";
import "./style.css";

import NavMenu from "../common/NavMenu";
import Footer from "../common/Footer";
import BookClassToday from "../common/BookClassToday";
import TeamProfile from "./TeamProfile";
import Filters from "./Filters";

const OurFaculty = () => {
  const [staff, setStaff] = useState([...teachers, ...admins]);
  const [instrumentFilterValue, setInstrumentFilterValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleInstrumentFilterChange = (value) => {
    if (instrumentFilterValue?.includes(value)) {
      setInstrumentFilterValue((prevState) =>
        prevState?.filter((item) => item !== value)
      );
    } else {
      setInstrumentFilterValue((prevState) => [...prevState, value]);
    }
  };

  useEffect(() => {
    const filteredStaff = [...teachers, ...admins].filter((item) => {
      const matchesSearch =
        item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.specialization.some((spec) =>
          spec.name.toLowerCase().includes(searchValue.toLowerCase())
        );

      const matchesInstruments =
        instrumentFilterValue.length === 0 ||
        item.specialization.some((spec) =>
          instrumentFilterValue.includes(spec.name)
        );

      return matchesSearch && matchesInstruments;
    });

    setStaff(filteredStaff);
  }, [instrumentFilterValue, searchValue]);

  return (
    <main className="our-faculty">
      <NavMenu />
      <div className="our-faculty-container">
        <div className="faculty-container">
          <h2 className="alignCenter">Artis School Team</h2>
          <Filters
            instrumentsValue={instrumentFilterValue}
            onInstrumentsSelect={handleInstrumentFilterChange}
            searchValue={searchValue}
            onSearch={({ target }) => setSearchValue(target?.value)}
          />
          <div className="teamMemberProfiles">
            {staff.map((teamMember) => {
              return <TeamProfile {...teamMember} key={teamMember.name} />;
            })}
          </div>
        </div>
        <BookClassToday />
        <Footer />
      </div>
    </main>
  );
};

export default OurFaculty;
