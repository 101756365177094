const AdditionalFeedbackInfo = ({ feedback }) => {
  const milliseconds =
    feedback?.createdAt.seconds * 1000 +
    Math.round(feedback?.createdAt.nanoseconds / 1e6);
  const date = new Date(milliseconds).toLocaleDateString("en-US");

  return (
    <>
      <p>
        <strong>Created at:</strong> {feedback?.createdAt ? date : "N/A"}
      </p>
      <p>
        <strong>Email:</strong> {feedback?.createdBy?.email || "N/A"}
      </p>
      <p>
        <strong>Role:</strong> {feedback?.createdBy?.role || "N/A"}
      </p>
    </>
  );
};

export default AdditionalFeedbackInfo;
