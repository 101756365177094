import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
`;

export const DropdownContainer = styled.div`
  margin-left: auto;
  width: 400px;
  @media (max-width: ${ScreenSize.LG}px) {
    width: 300px;
  }

  @media (max-width: ${ScreenSize.MD}px) {
    width: 200px;
  }
  @media (max-width: ${ScreenSize.SM}px) {
    margin-left: 0px;
  }
`;
export const CustomSelectField = styled.select`
  display: block;
  width: ${({ width }) => (width ? width : "50%")};
  height: 40px;
  /* text-align: center; */
  padding: 5px;
  padding-left: 20px;
  border-radius: 10px;
  background-color: "#fff";
  color: ${({ color }) => color || "#000"};
  box-shadow: 0px 0px 4px 1px #c0c0c09e;
  outline: none;
  border: none;
`;

export const CustomTD = styled.td`
  position: relative;
  &:hover {
    z-index: 10000;
  }
`;
