import { updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";
import store from "src/stores/UserStore";
import { toast } from "react-toastify";
import { uploadFileWithRandomId } from "src/utils/helpers";
import { sendEmailConfirmation } from "../../../utils";

import { FirebaseContext } from "src/components/Firebase";
import { PrimaryButton, RequiredInputSpan } from "src/utils/shared/styled";
import { ButtonsWrapper, ProfileField, StyledProfileCard } from "../styled";
import { Divider, Radio } from "antd";
import SalaryInputs from "../../../components/career/SalaryInputs";
import { Input } from "reactstrap";
import ResumeUpload from "../../../components/career/ResumeUpload";
import WithdrawModal from "../../GuestProfileThirdPage/components/WithdrawModal";
import ActionConfirmModal from "../../../components/ActionConfirmModal";
import CustomTooltip from "src/components/Dashboard/common/components/CustomTooltip";

const AdminSecondPage = () => {
  const { user, setUser } = store;
  const firebase = useContext(FirebaseContext);
  const userToUpdate = firebase.user(user.uid);
  const navigate = useNavigate();

  const [employmentValue, setEmploymentValue] = useState(user?.employmentType);
  const [hourlySalaryValue, setHourlySalaryValue] = useState(
    user?.salaryExpectations?.hourly || 0
  );
  const [quarterlySalaryValue, setQuarterlySalaryValue] = useState(
    user?.salaryExpectations?.quarterly || 0
  );
  const [linkedinValue, setLinkedinValue] = useState(user?.linkedinURL || "");
  const [flexibleChecked, setFlexibleChecked] = useState(
    user?.salaryExpectations?.flexible
  );
  const [resumeDownloadUrl, setResumeDownloadUrl] = useState(
    user.resumeDownloadUrl || null
  );
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleResumeUpload = async (info) => {
    const res = await uploadFileWithRandomId(firebase, info.file, "/resumes");

    setResumeDownloadUrl(res.downloadUrl);
  };

  const handleSubmit = async () => {
    try {
      if (
        !user.birthday ||
        !user.name ||
        !user.primaryPhone ||
        !resumeDownloadUrl
      ) {
        return toast.error("Please fill all the fields on both pages!");
      }

      await updateDoc(userToUpdate, {
        employmentType: employmentValue,
        salaryExpectations: {
          hourly: hourlySalaryValue,
          quarterly: quarterlySalaryValue,
          flexible: flexibleChecked,
        },
        linkedinURL: linkedinValue,
        resumeDownloadUrl,
        status: "On review",
      });

      setUser({
        ...user,
        employmentType: employmentValue,
        salaryExpectations: {
          hourly: hourlySalaryValue,
          quarterly: quarterlySalaryValue,
          flexible: flexibleChecked,
        },
        linkedinURL: linkedinValue,
        resumeDownloadUrl,
        status: "On review",
      });

      setConfirmModalOpen(true);
      sendEmailConfirmation(user);
    } catch (error) {
      toast.error("Please fill all the fields!");
    }
  };

  return (
    <div>
      <StyledProfileCard>
        <ProfileField>
          <h5>
            Employment type <RequiredInputSpan>*</RequiredInputSpan>
          </h5>
          <Radio.Group
            onChange={({ target }) => setEmploymentValue(target.value)}
            value={employmentValue}
          >
            <Radio value="part-time">Part time</Radio>
            <Radio value="full-time">Full time</Radio>
          </Radio.Group>
        </ProfileField>
        <Divider />
        <ProfileField>
          <h5>
            Salary expectations <RequiredInputSpan>*</RequiredInputSpan>
          </h5>
          <SalaryInputs
            onHourlyChange={(value) => setHourlySalaryValue(value)}
            hourlyValue={hourlySalaryValue}
            onQuarterlyChange={(value) => setQuarterlySalaryValue(value)}
            quarterlyValue={quarterlySalaryValue}
            flexibleChecked={flexibleChecked}
            onFlexibleChange={() =>
              setFlexibleChecked((prevState) => !prevState)
            }
          />
        </ProfileField>
        <Divider />
      </StyledProfileCard>
      <StyledProfileCard>
        <ProfileField>
          <h5>LinkedIn profile URL</h5>
          <Input
            placeholder="linkedin.com"
            type="url"
            value={linkedinValue}
            onChange={({ target }) => setLinkedinValue(target.value)}
          />
        </ProfileField>
        <Divider />
        <ProfileField>
          <h5>
            Resume <RequiredInputSpan>*</RequiredInputSpan>
          </h5>
          <ResumeUpload
            request={handleResumeUpload}
            isUploaded={resumeDownloadUrl}
          />
        </ProfileField>
      </StyledProfileCard>
      <ButtonsWrapper>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => navigate("/dashboard/guest-profile")}
        >
          Previous page
        </PrimaryButton>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={() => setWithdrawModalOpen(true)}
        >
          Withdraw
        </PrimaryButton>
        <PrimaryButton
          backgroundColor="#681e46DD"
          onClick={handleSubmit}
          disabled={user?.status !== "Need profile information"}
          id="admin-submit"
        >
          Submit
        </PrimaryButton>
        {user?.status !== "Need profile information" && (
          <CustomTooltip anchorSelect="#admin-submit">
            You've already submitted your application
          </CustomTooltip>
        )}
      </ButtonsWrapper>
      <ActionConfirmModal
        isOpen={confirmModalOpen}
        toggle={() => setConfirmModalOpen((prevState) => !prevState)}
        text={`Thank you for completing the application! We will contact you soon`}
      />
      <WithdrawModal
        isOpen={withdrawModalOpen}
        toggle={() => setWithdrawModalOpen((prevState) => !prevState)}
      />
    </div>
  );
};

export default AdminSecondPage;
