import { Table } from "antd";
import styled from "styled-components";

const StyledFeedbacksTable = styled(Table)`
  .ant-table {
    border: 1px solid #681e46 !important;
    border-radius: 12px !important;
    text-transform: capitalize;
  }
`;

export { StyledFeedbacksTable };
