import { SubmitSpinner } from "src/components/common";
import {
  InputLabel,
  CustomFormikFieldInput,
  ErrorMessage,
  ShowPasswordButton,
  PrimaryButton,
  ModalButton,
  BlinkingButton,
  CurrencyInputContainer,
  CustomInput,
} from "./styled";
import { EyeOffOutline } from "styled-icons/evaicons-outline";
import { EyeOutline } from "styled-icons/evaicons-outline";

export const InputWithLabel = ({
  label = "",
  type = "text",
  placeholder = "",
  error = "",
  touched = false,
  withPasswordShowBtn,
  onShowClick,
  ...inputProps
}) => {
  return (
    <div style={{ position: "relative" }}>
      {label && <InputLabel>{label}</InputLabel>}
      <CustomFormikFieldInput
        {...inputProps}
        type={type}
        placeholder={placeholder}
      />
      {withPasswordShowBtn && (
        <ShowPasswordButton
          onClick={onShowClick}
          top={error && touched ? "45%" : "55%"}
          tabIndex={-1}
        >
          {type === "password" ? <EyeOffOutline /> : <EyeOutline />}
        </ShowPasswordButton>
      )}
      {error && touched && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};
export const PrimaryButtonWithLoading = ({ isLoading, children, ...props }) => {
  return (
    <PrimaryButton disabled={isLoading} type="submit" width="245px" {...props}>
      {isLoading ? (
        <SubmitSpinner style={{ paddingY: "5px" }} size="sm" />
      ) : (
        children
      )}
    </PrimaryButton>
  );
};
export const FullPageSpinner = ({ height }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: height || "100vh" }}
    >
      <SubmitSpinner
        style={{ width: "3rem", height: "3rem", color: "#681e46" }}
      />
    </div>
  );
};

export const ModalButtonWithLoading = ({ isLoading, children, ...props }) => {
  return (
    <ModalButton disabled={isLoading} type="submit" {...props}>
      {isLoading ? (
        <div>
          <SubmitSpinner style={{ width: "20px", height: "20px" }} />
        </div>
      ) : (
        children
      )}
    </ModalButton>
  );
};

export const PrimaryButtonWithBlink = ({
  isLoading = false,
  children,
  ...props
}) => {
  return (
    <PrimaryButtonWithLoading
      position="relative"
      isLoading={isLoading}
      {...props}
    >
      <BlinkingButton
        style={{ position: "absolute", top: "35%", left: "15px" }}
      ></BlinkingButton>
      {children}
    </PrimaryButtonWithLoading>
  );
};

export const CustomInputWithCurrency = ({ ...props }) => {
  return (
    <CurrencyInputContainer>
      <CustomInput
        onWheel={(e) => e.target.blur()} // prevents scroll behaviour
        type="number"
        {...props}
      />
    </CurrencyInputContainer>
  );
};
export const NumberInput = ({ ...props }) => {
  return (
    <CustomInput
      {...props}
      type={"number"}
      onWheel={(e) => e.target.blur()} // prevents scroll behaviour
    />
  );
};
