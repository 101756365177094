import React from "react";
import ConcertItem from "./ConcertItem";
import { ConcertsListContainer } from "./styled";
import { SubmitSpinner } from "src/components/common";

const ConcertsList = ({
  concertsList,
  loadingInitialData,
  setCurrentConcertColor,
  setCurrentConcertId,
}) => {
  if (loadingInitialData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center vh-70 "
        style={{ flex: 1 }}
      >
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </div>
    );
  }
  return (
    <ConcertsListContainer>
      {concertsList.map((concert, i) => {
        const { id } = concert;
        return (
          <ConcertItem
            setCurrentConcertId={setCurrentConcertId}
            setCurrentConcertColor={setCurrentConcertColor}
            key={id}
            index={i}
            concert={concert}
          />
        );
      })}
    </ConcertsListContainer>
  );
};

export default ConcertsList;
