import React from "react";
import { CloseIcon, CustomBody, CustomHeader, CustomModal } from "../styled";
import WithdrawalRequestBody from "./WithdrawalRequestBody";

const WithdrawalModal = ({
  modalData,
  lessonWithCurrentTimeline,
  onSuccess,
}) => {
  const { isModalOpen, toggleModal, closeModal, openModal } = modalData;
  return (
    <CustomModal
      size="md"
      // style={{ maxWidth: "950px" }}
      // className="min-w-50 text-dark"
      centered
      isOpen={isModalOpen}
      toggle={() => {
        toggleModal();
      }}
    >
      <CustomHeader
        close={
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        }
        className="pb-0 border-bottom-0"
        toggle={toggleModal}
      >
        <h2>Withdrawal Request</h2>
      </CustomHeader>
      <CustomBody>
        <WithdrawalRequestBody
          modalData={modalData}
          lessonWithCurrentTimeline={lessonWithCurrentTimeline}
          onSuccess={onSuccess}
        />
      </CustomBody>
    </CustomModal>
  );
};

export default WithdrawalModal;
