import { packageOptions } from "../../../utils";
import { CardsWrapper, StyledCard } from "../ProgramSelect/styled";

const PackageSelect = ({ onSelect, currentSelect }) => {
  return (
    <CardsWrapper>
      {packageOptions.map(({ title, text, price, value }, index) => (
        <StyledCard
          onClick={() => onSelect(value)}
          title={title}
          hoverable
          key={index}
          current={currentSelect === value ? "true" : undefined}
        >
          <p>${price}</p>
          {text}
        </StyledCard>
      ))}
    </CardsWrapper>
  );
};

export default PackageSelect;
