import React from "react";
import CreatePrivateLessonModal from "src/components/Dashboard/common/components/CreatePrivateLessonModal";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import { useStudentInfoContext } from "src/contexts/StudentInfoContext";
import useModal from "src/hooks/useModal";
import { PrimaryButton } from "src/utils/shared/styled";
import LessonsNavSection from "./LessonsNavSection";
import PackageLessonInfo from "./PackageLessonInfo";
import RegularLessonInfo from "./RegularLessonInfo";
import {
  HeaderContainer,
  LessonsInfoSectionContainer,
  NavButtonsContainer,
} from "./styled";

const LessonsInfoSection = ({ student, targetLessons, handleIndex, index }) => {
  const { instruments, programs, teachers, locations } =
    useStudentInfoContext();

  const createPLModalData = useModal();

  const currentLesson = targetLessons[index];
  const isPackageLsn = isPackagePrivateLesson(currentLesson?.type);

  return (
    <>
      <LessonsInfoSectionContainer>
        {/* Header */}
        <HeaderContainer>
          <div className="d-flex justify-content-between align-items-start">
            <h3 style={{ marginBottom: "20px" }}>Lessons Info</h3>
            <PrimaryButton onClick={createPLModalData.openModal} width="105px">
              Add New
            </PrimaryButton>
          </div>
          <div>
            <LessonsNavSection
              handleIndex={handleIndex}
              targetLessons={targetLessons}
              index={index}
            />
          </div>
        </HeaderContainer>
        {/* Body */}

        {/* Lesson Form Container */}
        {isPackageLsn ? (
          <PackageLessonInfo
            currentLesson={currentLesson}
            currentStudent={student}
          />
        ) : (
          <RegularLessonInfo
            currentLesson={currentLesson}
            currentStudent={student}
          />
        )}
      </LessonsInfoSectionContainer>

      {createPLModalData.isModalOpen && (
        <CreatePrivateLessonModal
          modalData={createPLModalData}
          studentId={student?.id}
          providedInitialData={{
            instruments,
            locations,
            programs,
            teachers,
          }}
          headerTitle="Add New Instrument"
        />
      )}
    </>
  );
};

export default LessonsInfoSection;
