import styled from "styled-components";

export const InputRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;
export const InputContainer = styled.div`
  width: ${({ width }) => width || "auto"};
  flex-grow: 1;
`;
