import TeacherLibrarySearchInput from "../../common/TeacherLibrarySearchInput";
import { LibraryTeachersListContainer } from "../../../styled-components/teacherLibraryStyledComponents";
import LibrarySingleTeacherRow from "./LibrarySingleTeacherRow";
import React, { useContext, useState } from "react";
import {
  TeachersSelectContext,
  SearchContext,
  TeachersContext,
} from "../../common/libraryContext";
import EmptyResults from "../../common/EmptyResults";
import SelectAllTeachers from "./SelectAllTeachers";
import { isActiveTeacher } from "src/utils/helpers";

const LibraryTeachersList = ({ fullWidth }) => {
  const { select, selected } = useContext(TeachersSelectContext);
  const { teachers } = useContext(TeachersContext);
  const [searchValue, setSearchValue] = useState("");

  const teachersSearchContextValue = {
    searchValue,
    setSearchValue,
  };
  const filteredTeachers = teachers.filter((teacher) => {
    if (!isActiveTeacher(teacher)) return false;

    return teacher.fullName.toLowerCase().includes(searchValue);
  });

  return (
    <SearchContext.Provider value={teachersSearchContextValue}>
      <LibraryTeachersListContainer fullWidth={fullWidth}>
        <div className="mb-5">
          <TeacherLibrarySearchInput
            query={searchValue}
            setQuery={setSearchValue}
          />
        </div>
        {searchValue === "" && <SelectAllTeachers />}
        {filteredTeachers.map(({ id, fullName }) => (
          <LibrarySingleTeacherRow
            id={id}
            key={id}
            checked={selected.includes(id)}
            onChange={() => {
              select(id);
            }}
            label={fullName}
          />
        ))}
        {filteredTeachers.length === 0 && searchValue !== "" && (
          <EmptyResults />
        )}
      </LibraryTeachersListContainer>
    </SearchContext.Provider>
  );
};
export default LibraryTeachersList;
