import React from "react";
import { CustomModal, CustomModalBody, CustomModalHeader } from "./styled";
import AbsenceRequestBody from "./AbsenceRequestBody";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TeacherAbsenceRequestModal = ({
  teacher,
  modalData,
  onAbsenceRequestSuccess = () => {},
  limitDatePicker,
  submitButtonText = "Request Absence",
}) => {
  return (
    <CustomModal
      size="lg"
      centered
      isOpen={modalData.isModalOpen}
      toggle={modalData.toggleModal}
    >
      <CustomModalHeader
        // close={<CloseIcon onClick={modalData.closeModal} />}
        className="pb-0 border-bottom-0"
        toggle={modalData.toggleModal}
      >
        <h2 className="fs-3 fw-bold">Absence Request</h2>
      </CustomModalHeader>
      <CustomModalBody>
        <AbsenceRequestBody
          teacher={teacher}
          modalData={modalData}
          onAbsenceRequestSuccess={onAbsenceRequestSuccess}
          limitDatePicker={limitDatePicker}
          submitButtonText={submitButtonText}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default TeacherAbsenceRequestModal;
