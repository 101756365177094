import React from "react";
import {
  getLessonPackageItemsInfo,
  getPackageLessonSetExpirationDate,
} from "src/utils/helpers";
import PackageSetInfo from "./PackageSetInfo";
import {
  Container,
  PackageItemsContainer,
  RedBarContainer,
  RedBarText,
} from "../../styled";
import { usePackageLessonContext } from "../../contexts/PackageLessonContext";
import { PrimaryButton } from "src/utils/shared/styled";
import useModal from "src/hooks/useModal";
import ConfirmationModal from "../../../ConfirmationModal";
import CreatePackageItemModal from "../../../CreatePackageItemModal";
import PackageLessonExpirationModal from "../../../PackageLessonExpirationModal";
import moment from "moment";
import { isRecurring10PackageSet } from "src/constants/packageLessonsEnum";
import ChangePackageLessonScheduleModal from "../../../ChangePackageLessonScheduleModal";

const PackageInfo = ({}) => {
  const {
    packageLesson,
    currentSet,
    setCurrentSet,
    deleteCurrentPackageItem,
    deletePackageItemModalData,
    isSubmitting,
    currentPackageItemId,
    onDeletePackagSetClick,
    deleteCurrentPackageSet,
    deletePackageSetModalData,
    is10Recurring,
  } = usePackageLessonContext();

  const addNewSetItemModalData = useModal();
  const expirationModalData = useModal();
  const changeScheduleModalData = useModal();

  console.log({ packageLesson });

  const onDeletePackageItemApprove = async () => {
    await deleteCurrentPackageItem();
    window.location.reload();
  };
  const onDeletePackageItemDecline = () => {
    deletePackageItemModalData.closeModal();
  };

  const handleScheduleSetItemClick = (set) => {
    setCurrentSet(set);
    addNewSetItemModalData.openModal();
  };

  const handleExpiredClick = (set) => {
    setCurrentSet(set);
    expirationModalData.openModal();
  };

  const handleChangeScheduleClick = (set) => {
    setCurrentSet(set);
    changeScheduleModalData.openModal();
  };

  const onDeletePackageSetApprove = async () => {
    await deleteCurrentPackageSet();
    window.location.reload();
  };
  const onDeletePackageSetDecline = () => {
    deletePackageSetModalData.closeModal();
  };

  return (
    <>
      {packageLesson.packageSets.map((set) => {
        const setNumber = set.setNumber;
        const setObj = packageLesson.packageSets?.find(
          (set) => set.setNumber === setNumber
        );
        const is10Recurring = isRecurring10PackageSet(setObj?.setType);
        const showDeleteSetButton = is10Recurring;
        const showChangeScheduleButton = is10Recurring;

        const { requiredSetInfo } = getLessonPackageItemsInfo(
          packageLesson.packageSets,
          setNumber
        );

        const expirationDate = getPackageLessonSetExpirationDate(set);

        const formattedExpirationDate =
          moment(expirationDate).format("MM-DD-YYYY");

        const isPassedSetDeadline = requiredSetInfo?.isPassedSetDeadline;
        const isFull = requiredSetInfo?.isFullSet;
        return (
          <div key={setNumber}>
            <Container>
              {/* Red Bar */}
              <RedBarContainer>
                <RedBarText>Set {setNumber}</RedBarText>
              </RedBarContainer>
              <PackageItemsContainer>
                <div className="p-4">
                  <div className="mt-3 mb-3 d-flex justify-content-between">
                    <div>
                      <h4>Expires In: {formattedExpirationDate}</h4>
                    </div>
                    <div className="d-flex" style={{ gap: 10 }}>
                      {showChangeScheduleButton && (
                        <PrimaryButton
                          onClick={() => handleChangeScheduleClick(setNumber)}
                          // backgroundColor="#d33434"
                          width="120px"
                          padding="6px"
                        >
                          Change Schedule
                        </PrimaryButton>
                      )}
                      {showDeleteSetButton && (
                        <PrimaryButton
                          onClick={() => onDeletePackagSetClick(setNumber)}
                          backgroundColor="#d33434"
                          width="120px"
                          padding="6px"
                        >
                          Delete Set
                        </PrimaryButton>
                      )}
                      {isFull ? (
                        <PrimaryButton
                          backgroundColor="#e357a2"
                          width="120px"
                          padding="6px"
                        >
                          Completed
                        </PrimaryButton>
                      ) : isPassedSetDeadline ? (
                        <PrimaryButton
                          onClick={() => handleExpiredClick(setNumber)}
                          backgroundColor="#e357a2"
                          width="120px"
                          padding="6px"
                        >
                          Expired
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton
                          onClick={() => handleScheduleSetItemClick(setNumber)}
                          width="120px"
                          padding="6px"
                        >
                          Schedule A Lesson
                        </PrimaryButton>
                      )}
                    </div>
                  </div>
                  <PackageSetInfo setNumber={setNumber} />
                </div>
              </PackageItemsContainer>
            </Container>
            <hr className="mt-5 mb-5" />
          </div>
        );
      })}
      {changeScheduleModalData.isModalOpen && currentSet && (
        <ChangePackageLessonScheduleModal
          modalData={changeScheduleModalData}
          packageLessonId={packageLesson.id}
          setNumber={currentSet}
        />
      )}
      {addNewSetItemModalData.isModalOpen && currentSet && (
        <CreatePackageItemModal
          modalData={addNewSetItemModalData}
          packageLessonId={packageLesson.id}
          instrumentId={packageLesson.instrumentId}
          setNumber={currentSet}
          modalTitle="Schedule a Lesson"
          initialLessonDuration={
            packageLesson.packageSets[0]?.setItems?.[0]?.duration
          }
          initialLessonLocationId={
            packageLesson.packageSets[0]?.setItems?.[0]?.locationId
          }
        />
      )}
      {deletePackageItemModalData.isModalOpen && currentPackageItemId && (
        <ConfirmationModal
          modalData={deletePackageItemModalData}
          isSubmitting={isSubmitting}
          onApprove={onDeletePackageItemApprove}
          onCancel={onDeletePackageItemDecline}
          approveBtnText="Delete"
          cancelBtnText="Cancel"
          title="Delete Lesson?"
        />
      )}
      {deletePackageSetModalData.isModalOpen && currentSet && (
        <ConfirmationModal
          modalData={deletePackageSetModalData}
          isSubmitting={isSubmitting}
          onApprove={onDeletePackageSetApprove}
          onCancel={onDeletePackageSetDecline}
          approveBtnText="Delete"
          cancelBtnText="Cancel"
          title={`Delete Set ${currentSet} ?`}
        />
      )}
      {expirationModalData.isModalOpen && (
        <PackageLessonExpirationModal
          modalData={expirationModalData}
          lessonId={packageLesson.id}
          setNumber={currentSet}
          onSuccess={() => window.location.reload()}
        />
      )}
    </>
  );
};

export default PackageInfo;
