import React from "react";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { PrimaryH3 } from "src/utils/styled";
import MobileNavbar from "./MobileNavbar";
import UserInfoBar from "./UserInfoBar";
import { PageTitleContainer } from "./styled";

const MobileRouteHeader = ({ title }) => {
  return (
    <div>
      <MobileNavbar />
      <UserInfoBar />
      <PageTitleContainer>
        <PrimaryH3>{title}</PrimaryH3>
      </PageTitleContainer>
    </div>
  );
};

export default compose(inject("UserStore"), observer)(MobileRouteHeader);
