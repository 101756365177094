import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  concertProgramSoloPerformanceTypes,
  concertProgramTypes,
  concertSignupOptions,
} from "src/constants/concertEnum";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { v4 as uuidv4 } from "uuid";
import { validateProgramData } from "../helperFns";

const initialDataInitialValues = {
  concertSignup: undefined,
  signupUser: undefined,
  concert: undefined,
};
const programDataInitialValues = {
  performanceType: {
    type: concertProgramSoloPerformanceTypes.SOLO,
    value: "",
  },
  repertoires: [
    {
      id: uuidv4(),
      description: "",
      duration: 0,
    },
  ],
  equipmentIds: [],
  additionalEquipment: "",
  note: "",
};

// this hooks used to create solo program for either existing sign ups or no existing sign up
const useCreateConcertSoloProgram = ({
  onActionSuccess,
  user,
  hasAlreadySignedUp,
  signupId,
  isEditMode = false,
  programId, // if isEditMode
  // if hasAlreadySignedUp === false
  concertId,
}) => {
  const {
    getConcertSignupById,
    getUsersByIds,
    getConcertById,
    createConcertProgram,
    createConcertSignup,
    getProgramById,
    updateConcertProgram,
  } = useFirebaseFns();

  const [initialData, setInitialData] = useState(initialDataInitialValues);
  const [loadingInitialData, setLoadingInitialData] = useState(false);

  const [programData, setProgramData] = useState(programDataInitialValues);
  const [loadingAction, setLoadingAction] = useState(false);

  function addNewRepertoireRow() {
    const newRep = {
      id: uuidv4(),
      description: "",
      duration: 0,
    };
    setProgramData((oldVal) => ({
      ...oldVal,
      repertoires: [...oldVal.repertoires, newRep],
    }));
  }
  function handleDeleteRepertoire(id) {
    let repCopy = [...programData.repertoires];
    repCopy = repCopy.filter((rep) => rep.id !== id);
    setProgramData((oldVal) => ({
      ...oldVal,
      repertoires: repCopy,
    }));
  }

  function handleRepertoireChange(name, value, i) {
    let repCopy = [...programData.repertoires];
    repCopy[i][name] = value;
    setProgramData((oldVal) => ({
      ...oldVal,
      repertoires: repCopy,
    }));
  }

  const handleProgramDataChange = useCallback(
    (name, value) => {
      /* Path of nested field to update, in array notation */
      const path = name.split(".");

      const stateClone = { ...programData };

      /* Aquire the parent object of the target field */
      const nestedObject = path
        .slice(0, -1)
        .reduce(
          (object, part) => (object === undefined ? undefined : object[part]),
          stateClone
        );

      if (nestedObject !== undefined) {
        /* Obtain last key in path */
        const [pathTail] = path.slice(-1);

        /* Update value of last key on target object to new value */
        nestedObject[pathTail] = value;
      }

      setProgramData(stateClone);
    },
    [programData]
  );

  async function handleCreateProgram() {
    try {
      setLoadingAction(true);
      const { isValid, errorMsg } = validateProgramData(
        programData,
        initialData.concert.minsPerProgram
      );

      if (!isValid) {
        throw new Error(errorMsg);
      }

      const parsedRepertoires = programData.repertoires.map((rep) => ({
        ...rep,
        ...(rep.duration && {
          duration: parseInt(rep.duration),
        }),
      }));

      const programObj = {
        createdAt: new Date(),
        createdBy: user.uid,
        programType: concertProgramTypes.SOLO_PROGRAM,
        performanceType: {
          type: programData.performanceType.type,
          ...(programData.performanceType.type ===
            concertProgramSoloPerformanceTypes.OTHER && {
            value: programData.performanceType.value,
          }),
        },
        repertoires: parsedRepertoires,
        equipmentIds: programData.equipmentIds, // list of ids of equipment added to the concert
        additionalEquipment: programData.additionalEquipment, // custom text input
        note: programData.note, // custom text input

        concertId: initialData.concert.id,
        signupId: undefined,
        usersIds: undefined,
      };
      if (hasAlreadySignedUp) {
        programObj.signupId = signupId;
        programObj.usersIds = [initialData.concertSignup?.userId];
      } else {
        const signupObj = {
          createdAt: new Date(),
          userId: user.uid,
          concertId: initialData.concert.id,
          programGroupId: uuidv4(),
          signupOption: {
            type: concertSignupOptions.FREE_FOR_ALL_STUDENTS,
          },
          programType: concertProgramTypes.SOLO_PROGRAM,
        };

        const signupResponse = await createConcertSignup(signupObj);
        if (!signupResponse.id) {
          throw new Error("Failed to create a concert signup");
        }
        programObj.signupId = signupResponse.id;
        programObj.usersIds = [user.uid];
      }

      console.log({ programObj });
      await createConcertProgram(programObj);

      toast.success("Program Created Successfully");
      onActionSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }
  async function handleEditProgram() {
    try {
      setLoadingAction(true);
      const { isValid, errorMsg } = validateProgramData(
        programData,
        initialData.concert.minsPerProgram
      );

      if (!isValid) {
        throw new Error(errorMsg);
      }

      const parsedRepertoires = programData.repertoires.map((rep) => ({
        ...rep,
        ...(rep.duration && {
          duration: parseInt(rep.duration),
        }),
      }));

      const updateObj = {
        updatedBy: user.uid,
        performanceType: {
          type: programData.performanceType.type,
          ...(programData.performanceType.type ===
            concertProgramSoloPerformanceTypes.OTHER && {
            value: programData.performanceType.value,
          }),
        },
        repertoires: parsedRepertoires,
        equipmentIds: programData.equipmentIds, // list of ids of equipment added to the concert
        additionalEquipment: programData.additionalEquipment, // custom text input
        note: programData.note, // custom text input
      };

      console.log({ updateObj });
      await updateConcertProgram(programId, updateObj);

      toast.success("Program updated Successfully");
      onActionSuccess();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoadingAction(false);
    }
  }
  useEffect(() => {
    const fetch = async () => {
      try {
        if (hasAlreadySignedUp && !signupId) {
          throw new Error("couldnt find concert signup id");
        }
        setLoadingInitialData(true);

        let concertSignup, signupUser, concert;

        if (hasAlreadySignedUp) {
          const [signup] = await Promise.all([getConcertSignupById(signupId)]);
          if (!signup) {
            throw new Error("couldnt find concert signup");
          }

          const [[signedUpUser], signupConcert] = await Promise.all([
            getUsersByIds([signup.userId]),
            getConcertById(signup.concertId),
          ]);
          concertSignup = signup;
          signupUser = signedUpUser;
          concert = signupConcert;
        } else {
          const [[signedUpUser], signupConcert] = await Promise.all([
            getUsersByIds([user.uid]),
            getConcertById(concertId),
          ]);

          signupUser = signedUpUser;
          concert = signupConcert;
        }

        setInitialData({ concertSignup, signupUser, concert });

        // set initial form values in edit mode
        if (!isEditMode) return;

        const concertProgram = await getProgramById(programId);
        const performanceType = concertProgram.performanceType;
        const repertoires = concertProgram.repertoires || [];
        const equipmentIds = concertProgram.equipmentIds || [];
        const additionalEquipment = concertProgram.additionalEquipment || "";
        const note = concertProgram.note || "";

        setProgramData({
          performanceType,
          repertoires,
          equipmentIds,
          additionalEquipment,
          note,
        });
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      } finally {
        setLoadingInitialData(false);
      }
    };
    fetch();
  }, []);

  return {
    initialData,
    loadingInitialData,
    programData,
    handleProgramDataChange,
    addNewRepertoireRow,
    handleDeleteRepertoire,
    handleRepertoireChange,
    handleCreateProgram,
    loadingAction,
    handleEditProgram,
  };
};

export default useCreateConcertSoloProgram;
