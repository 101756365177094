export const groupClassAvailableForMap = {
  ALL: 1,
  PUBLIC: 2,
  CURRENT_STUDENTS: 3,
};

export const groupClassFrequency = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  ONCE: "once",
};

export const groupClassLessonType = {
  NORMAL: "normal",
  ADDITIONAL: "additional",
};

export const groupClassStatuses = {
  ACTIVE: "active",
};
export const groupClassLocationStatuses = {
  VIRTUAL: 1,
  IN_PERSON: 2,
  BOTH: 3,
};
