import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import useFirebaseFns from "src/hooks/useFirebaseFns";
import { getTimeDiffInMins } from "src/utils/helpers";

const useLessonHistory = ({ regularPrivateLesson }) => {
  const {
    editRegularLessonAndActivityTimeline,
    deleteSummerBreakAndActivity,
    getUserActivityById,
    updatePrivateLesson,
    updateUserActivity,
    getPrivateLessonsByIds,
  } = useFirebaseFns();

  const [currentTimelineId, setCurrentTimelineId] = useState("");
  const [currentSummerBreakId, setCurrentSummerBreakId] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRestorePrevTimeline = async (currentHistoryItemId) => {
    if (!currentHistoryItemId) {
      return;
    }

    const [refreshedPL] = await getPrivateLessonsByIds([
      regularPrivateLesson?.id,
    ]);
    if (!refreshedPL) {
      toast.warn("couldnt restore prev timeline");
      return;
    }

    const currentHistoryItemIndex = historyList.findIndex(
      ({ id }) => id === currentHistoryItemId
    );

    const prevTimeline =
      currentHistoryItemIndex > 0
        ? historyList[currentHistoryItemIndex - 1]
        : undefined;

    console.log({ prevTimeline });
    if (!prevTimeline) {
      return;
    } else if (prevTimeline.isSummerBreak) {
      const resumedSummerBreak = prevTimeline;
      const updatedSummerBreaks = refreshedPL.summerBreaks?.map(
        (summerBreak) => {
          if (summerBreak.id === resumedSummerBreak?.id) {
            const { breakEnd, ...summerBreakWithoutEndDate } = summerBreak;
            return summerBreakWithoutEndDate;
          } else {
            return summerBreak;
          }
        }
      );
      const updateObj = {
        summerBreaks: updatedSummerBreaks,
      };
      const activity = await getUserActivityById(refreshedPL);
      const currentCanceledRanges = activity.canceledRanges || [];

      const updatedCanceledRanges = currentCanceledRanges.map((range) => {
        if (
          moment(resumedSummerBreak.breakStart).isSame(range.start, "minutes")
        ) {
          const { end, ...rangeWithoutEnd } = range;
          return rangeWithoutEnd;
        } else {
          return range;
        }
      });

      const activityUpdateObj = {
        canceledRanges: updatedCanceledRanges,
      };
      console.log({ activityUpdateObj, updateObj });
      await Promise.all([
        updatePrivateLesson(refreshedPL?.id, updateObj),
        updateUserActivity(refreshedPL?.id, activityUpdateObj),
      ]);
    } else if (prevTimeline.isTimeLine) {
      const resumedTimeline = prevTimeline;
      const updatedTimeline = refreshedPL?.timeline?.map((timelineObj) => {
        if (timelineObj?.id === resumedTimeline?.id) {
          const { lastDate, ...timelineWithoutLastDate } = timelineObj;
          return timelineWithoutLastDate;
        } else {
          return timelineObj;
        }
      });
      console.log({ updatedTimeline });
      await editRegularLessonAndActivityTimeline({
        lessonId: refreshedPL.id,
        updatedTimeline,
      });
    }

    toast.success("restored successfully");
  };

  const deleteTimeline = async ({ restorePrevTimeline = false }) => {
    try {
      if (!currentTimelineId) {
        return;
      }
      setIsSubmitting(true);
      const updatedTimeline = regularPrivateLesson.timeline.filter(
        ({ id }) => id !== currentTimelineId
      );

      await editRegularLessonAndActivityTimeline({
        lessonId: regularPrivateLesson.id,
        updatedTimeline,
      });

      if (restorePrevTimeline) {
        await handleRestorePrevTimeline(currentTimelineId);
      }

      toast.success("deleted successfully");
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const deleteSummerBreak = async ({ restorePrevTimeline = false }) => {
    try {
      if (!currentSummerBreakId) {
        return;
      }
      setIsSubmitting(true);

      await deleteSummerBreakAndActivity(
        regularPrivateLesson.id,
        currentSummerBreakId
      );

      if (restorePrevTimeline) {
        await handleRestorePrevTimeline(currentSummerBreakId);
      }

      toast.success("deleted successfully");
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const currentTimeline = useMemo(() => {
    if (!regularPrivateLesson) return;

    return regularPrivateLesson.timeline?.find(
      ({ id }) => id === currentTimelineId
    );
  }, [regularPrivateLesson, currentTimelineId]);

  const currentSummerBreak = useMemo(() => {
    if (!regularPrivateLesson) return;

    return regularPrivateLesson.summerBreaks?.find(
      ({ id }) => id === currentSummerBreakId
    );
  }, [regularPrivateLesson, currentSummerBreakId]);

  const historyList = useMemo(() => {
    if (!regularPrivateLesson) return;

    const timelineList =
      regularPrivateLesson.timeline?.map((timelineObj) => ({
        ...timelineObj,
        isTimeLine: true,
      })) || [];
    const summerBreaksList =
      regularPrivateLesson.summerBreaks?.map((summerBreak) => ({
        ...summerBreak,
        isSummerBreak: true,
      })) || [];

    const combinedList = [...timelineList, ...summerBreaksList].sort((a, b) => {
      const firstDate = a.startDate || a.breakStart;
      const secondDate = b.startDate || b.breakStart;

      return getTimeDiffInMins(secondDate, firstDate);
    });

    return combinedList;
  }, [regularPrivateLesson]);

  return {
    setCurrentTimelineId,
    currentTimeline,
    deleteTimeline,
    isSubmitting,
    currentSummerBreakId,
    currentSummerBreak,
    setCurrentSummerBreakId,
    deleteSummerBreak,
    historyList,
  };
};

export default useLessonHistory;
