import React, { useContext, useEffect, useState } from "react";
import {
  AdminFilterContext,
  DeleteNoteAttachmentContext,
  DeleteNoteContext,
  LessonNotesContext,
  NoteAttachmentsContext,
  ShareItemModalContext,
  StudentsContext,
  StudentsSelectContext,
  TeacherLessonNotesModalContext,
  TeachersContext,
  TeachersSelectContext,
} from "../../common/Library/common/libraryContext";
import {
  getCombinedPackageSetItems,
  getCurrentTimeline,
  getStudents,
  uploadFileWithRandomId,
} from "../../../utils/helpers";
import { FirebaseContext } from "../../Firebase";
import LessonNotes from "./LessonNotes";
import Spinner from "../../common/Spinner";
import {
  addDoc,
  arrayRemove,
  deleteDoc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import DeleteNoteConfirmModal from "./delete-confirm-modal/DeleteNoteConfirmModal";
import DeleteNoteAttachmentConfirmModal from "./delete-confirm-modal/DeleteNoteAttachmentConfirmModal";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { toast } from "react-toastify";
import AddLessonNoteAttachmentsModal from "./AddLessonNoteAttachmentsModal";
import ShareItemModal from "./share-item-modal/ShareItemModal";
import _ from "lodash";
import "react-calendar/dist/Calendar.css";
import AdminFilter from "./admin-filter/AdminFilter";
import AddLessonNoteModal from "./AddLessonNoteModal";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";

const AdminLessonNotes = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const [students, setStudents] = useState([]);
  const [notesList, setNotesList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [noteModal, setNoteModal] = useState({
    isOpen: false,
    note: {},
    isEditingMode: false,
  });
  const [deleteAttachmentModal, setDeleteAttachmentModal] = useState({
    isOpen: false,
    attachmentIndex: -1,
    noteId: "",
  });
  const [deleteNoteModal, setDeleteNoteModal] = useState({
    isOpen: false,
    noteId: "",
  });
  const [showShareItemModal, setShowShareItemModal] = useState({
    isOpen: false,
    noteId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // const [notesList, setNotesList] = useState(DUMMY_NOTES);
  const [attachmentsModal, setAttachmentsModal] = useState({
    noteId: "",
    isOpen: false,
  });
  const [teachersList, setTeachersList] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    label: "",
    value: "",
  });
  const [selectedTeacher, setSelectedTeacher] = useState({
    label: "",
    value: "",
  });
  const [selectedStudent, setSelectedStudent] = useState({
    label: "",
    value: "",
  });
  const [filteredNotes, setFilteredNotes] = useState([]);
  const storage = getStorage();
  const getStudentsList = async () => {
    const studentsArray = await getStudents(true, false, firebase, user.uid);
    setSelectedStudents(studentsArray.map(({ id }) => id));
    setStudents(studentsArray);
  };
  const getLocations = async () => {
    const locationsDocs = await getDocs(firebase.locations());
    const locationsArray = [];
    locationsDocs.forEach((location) =>
      locationsArray.push({ ...location.data(), id: location.id })
    );
    setLocations(locationsArray);
  };
  const getTeachersList = async () => {
    const teachersDocs = await getDocs(firebase.filterUsersByRole("Teacher"));
    const lessonsDocs = await getDocs(firebase.PrivateLessons());
    let teachersArray = [];
    teachersDocs.forEach((teacher) => {
      teachersArray.push({
        id: teacher.id,
        ...teacher.data(),
      });
    });
    lessonsDocs.forEach((lessonDoc) => {
      const lesson = lessonDoc.data();
      const isPackageLsn = isPackagePrivateLesson(lesson.type);
      teachersArray = teachersArray.map((teacher) => {
        const timeline = lesson?.timeline || [];
        let hasTeacher = false;
        let currentTimeline = {};
        if (isPackageLsn) {
          // if package lesson, then we consider the teacher if he teaches in at least 1 package item
          hasTeacher = lesson.teachersIds?.includes(teacher.id);
        } else {
          currentTimeline = getCurrentTimeline(timeline);
          hasTeacher = currentTimeline?.teacherId === teacher.id;
        }

        if (hasTeacher) {
          const studentsIds = teacher?.studentsIds || [];
          const locationsIds = teacher?.locationsIds || [];
          if (!studentsIds.includes(lesson.studentId)) {
            studentsIds.push(lesson.studentId);
          }
          if (!locationsIds.includes(currentTimeline.locationId)) {
            // considers all locations of the package lesson if the teacher teaches in multiple locations in the package items or a single location for regular lessons if found in current timeline
            const teacherLessonLocations = isPackageLsn
              ? getCombinedPackageSetItems(lesson.packageSets)
                  ?.filter(({ teacherId }) => teacherId === teacher.id)
                  ?.map(({ locationId }) => locationId) || []
              : [currentTimeline.locationId];
            locationsIds.push(...teacherLessonLocations);
          }
          return {
            ...teacher,
            locationsIds,
            studentsIds,
          };
        }
        return {
          ...teacher,
          locationsIds: teacher.locationsIds || [],
          studentsIds: teacher.studentsIds || [],
        };
      });
    });

    setTeachersList(teachersArray);
  };
  const onTeacherSelect = (id) => {
    const index = selectedTeachers.findIndex((ID) => id === ID);
    if (index === -1) {
      setSelectedTeachers((prev) => [...prev, id]);
    } else {
      setSelectedTeachers((prev) => prev.filter((ID) => id !== ID));
    }
  };
  const onToggleTeachersClick = () => {
    if (selectedTeachers.length === teachersList.length) {
      setSelectedTeachers([]);
    } else {
      setSelectedTeachers(teachersList.map(({ id }) => id));
    }
  };

  const onCloseShareModal = () => {
    setShowShareItemModal({
      isOpen: false,
      noteId: "",
    });
  };
  const onSubmitShare = async () => {
    toast.info("Sharing item(s)...");
    toast.info("Running in the background, please don't refresh the page.");
    onCloseShareModal();
    await setDoc(
      firebase.getLessonNote(showShareItemModal.noteId),
      {
        sharedWith: selectedTeachers,
      },
      {
        merge: true,
      }
    );
    setNotesList((previous) =>
      previous.map((note) => {
        if (note.id === showShareItemModal.noteId) {
          return {
            ...note,
            sharedWith: [...selectedTeachers],
          };
        }
        return note;
      })
    );
    setSelectedTeachers([]);
    toast.success("Successfully shared.");
  };
  const openShareItemModal = (noteId) => {
    const note = notesList.find(({ id }) => id === noteId);
    setSelectedTeachers(note?.sharedWith || []);
    setShowShareItemModal({
      isOpen: true,
      noteId,
    });
  };
  const getLessonsNotes = async () => {
    const notes = await getDocs(firebase.lessonsNotes());
    const notesArray = [];
    notes.forEach((note) => {
      notesArray.push({
        ...note.data(),
        id: note.id,
      });
    });
    setNotesList(notesArray);
  };
  useEffect(async () => {
    setIsLoading(true);
    await Promise.all([
      getStudentsList(),
      getLessonsNotes(),
      getTeachersList(),
      getLocations(),
    ]);
    setIsLoading(false);
  }, []);
  let teacherStudents = [];
  const currentTeacher = teachersList.find(
    ({ id }) => selectedTeacher.value === id
  );
  if (currentTeacher) {
    teacherStudents = students.filter(({ id }) =>
      currentTeacher.studentsIds.includes(id)
    );
  }
  const onStudentSelect = (id) => {
    const index = selectedStudents.findIndex((ID) => id === ID);
    if (index === -1) {
      setSelectedStudents((prev) => [...prev, id]);
    } else {
      setSelectedStudents((prev) => prev.filter((ID) => id !== ID));
    }
  };
  const onToggleStudentsClick = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map(({ id }) => id));
    }
  };

  const showNoteModal = (note, isEditingMode) => {
    setNoteModal({
      isOpen: true,
      note,
      isEditingMode,
    });
  };
  const createNote = async (newNote) => {
    const added = await addDoc(firebase.lessonsNotes(), newNote);
    newNote.id = added.id;
    setNotesList((previous) => [...previous, newNote]);
  };
  const updateNote = async (noteId, newNote) => {
    await updateDoc(firebase.getLessonNote(noteId), newNote);
    setNotesList((previous) =>
      previous.map((note) => {
        if (note.id === noteId) {
          return {
            ...note,
            ...newNote,
            attachments: [...note.attachments, ...newNote.attachments],
          };
        }
        return note;
      })
    );
    setFilteredNotes((previous) =>
      previous.map((note) => {
        if (note.id === noteId) {
          return {
            ...note,
            ...newNote,
            attachments: [...note.attachments, ...newNote.attachments],
          };
        }
        return note;
      })
    );
  };
  const saveNewAttachment = async (fileObject) => {
    const { file, attachmentType, recommendationLink } = fileObject;
    const newAttachment = {
      attachmentType,
      isDeleted: false,
    };
    if (attachmentType !== "recommendationLinks") {
      const { downloadUrl, id } = await uploadFileWithRandomId(
        firebase,
        file,
        `lessonNotes/attachments/${user.uid}/`
      );
      if (file.type) {
        newAttachment.type = file.type;
      }
      newAttachment.downloadUrl = downloadUrl;
      newAttachment.fileId = id;
      newAttachment.name = file.name;
    } else {
      newAttachment.downloadUrl = recommendationLink;
      newAttachment.type = "link";
    }
    return newAttachment;
  };
  const saveAttachments = async (files) => {
    const attachments = [];
    for (const fileObject of files) {
      const newAttachment = await saveNewAttachment(fileObject);
      attachments.push(newAttachment);
    }
    return attachments;
  };
  const onFilter = () => {
    const isStudentsFilterEnabled = selectedStudent?.value;
    const isTeachersFilterEnabled = selectedTeacher?.value;

    let filteredNotes = [];

    // if both filters enabled, show lesson notes for the selected students with the selected teacher
    if (isStudentsFilterEnabled && isTeachersFilterEnabled) {
      filteredNotes = notesList.filter(
        ({ studentsIds, teacherId }) =>
          studentsIds?.includes(selectedStudent.value) &&
          selectedTeacher.value === teacherId
      );

      // if only the teachers filter is enabled, show all of this selected teacher's notes
    } else if (isTeachersFilterEnabled && !isStudentsFilterEnabled) {
      filteredNotes = notesList.filter(
        ({ teacherId }) => selectedTeacher.value === teacherId
      );
    }

    setFilteredNotes(filteredNotes);
  };
  const onSubmit = async (formData) => {
    const { isEditingMode } = noteModal;
    setNoteModal({
      isOpen: false,
      note: {},
      isEditingMode: false,
    });
    const { noteId, files, recommendationLink, ...rest } = formData;
    const newNote = {
      ...rest,
      // date: Timestamp.fromDate(moment(date).toDate()),
      // teacherId: user.uid,
      createdAt: Timestamp.now(),
      isEdited: isEditingMode,
      updatedAt: Timestamp.now(),
    };
    if (!isEditingMode) {
      newNote.createdBy = user.uid;
      newNote.sharedWith = [];
      newNote.homework = {};
      newNote.type = "students";
    }

    newNote.attachments = await saveAttachments(files);
    if (noteId) {
      await updateNote(noteId, newNote);
    } else {
      await createNote(newNote);
    }
    toast.success(
      isEditingMode ? "Updated successfully." : "Created successfully."
    );
  };
  const deleteAttachment = async (attachmentIndex, noteId) => {
    toast.info("Deleting attachment...");
    const note = notesList.find(({ id }) => id === noteId);
    const attachment = note.attachments[attachmentIndex];
    await updateDoc(firebase.getLessonNote(noteId), {
      attachments: arrayRemove(attachment),
    });
    if (attachment.attachmentType !== "recommendationLinks") {
      const itemRef = ref(storage, attachment.downloadUrl);
      await deleteObject(itemRef);
    }
    toast.success("Attachment deleted successfully.");
    note.attachments.splice(attachmentIndex, 1);
    setNotesList((previous) =>
      previous.map((item) => {
        if (item.id === noteId) {
          return {
            ...note,
            attachments: [...note.attachments],
          };
        }
        return item;
      })
    );
  };
  const deleteNote = async (noteId) => {
    toast.info("Deleting note...");
    toast.info("Please don't close the page until finished.");
    const note = notesList.find(({ id }) => id === noteId);
    await deleteDoc(firebase.getLessonNote(noteId));

    const { attachments } = note;
    for (const attachment of attachments) {
      const itemRef = ref(storage, attachment.downloadUrl);
      await deleteObject(itemRef);
    }
    setNotesList((previous) => previous.filter(({ id }) => id !== noteId));
    toast.success("Successfully deleted note.");
  };
  const publishNote = async (noteId) => {
    toast.info("Publishing note...");
    await updateDoc(firebase.getLessonNote(noteId), {
      isDraft: false,
    });
    setNotesList((previous) =>
      previous.map((item) => {
        if (item.id === noteId) {
          return {
            ...item,
            isDraft: false,
          };
        }
        return item;
      })
    );
    toast.success("Note published successfully.");
  };
  const deleteNoteContextValue = {
    isOpen: deleteNoteModal.isOpen,
    close: () => {
      setDeleteNoteModal({
        isOpen: false,
        noteId: "",
      });
    },
    onDelete: async () => {
      const noteId = deleteNoteModal.noteId;
      setDeleteNoteModal({
        isOpen: false,
        noteId: "",
      });
      await deleteNote(noteId);
    },
  };
  const deleteNoteAttachmentContextValue = {
    isOpen: deleteAttachmentModal.isOpen,
    close: () => {
      setDeleteAttachmentModal({
        isOpen: false,
        attachmentId: "",
      });
    },
    onDelete: async () => {
      const attachmentIndex = deleteAttachmentModal.attachmentIndex;
      const noteId = deleteAttachmentModal.noteId;
      setDeleteAttachmentModal({
        isOpen: false,
        attachmentIndex: -1,
        noteId: "",
      });
      await deleteAttachment(attachmentIndex, noteId);
    },
  };
  const studentsSelectContextValue = {
    selected: selectedStudents,
    select: onStudentSelect,
    toggle: onToggleStudentsClick,
  };
  const studentsContextValue = {
    students,
  };

  const teacherLessonNotesModalContextValue = {
    isOpen: noteModal.isOpen,
    note: Object.assign({}, noteModal.note),
    close: () => {
      setNoteModal({
        isOpen: false,
        note: {},
        isEditingMode: false,
      });
    },
    isEditingMode: noteModal.isEditingMode,
  };
  const lessonNotesContextValue = {
    isEditable: true,
    showAttachmentsModal: (noteId) => {
      setAttachmentsModal({
        isOpen: true,
        noteId,
      });
    },
    editNote: (note) => {
      showNoteModal(note, true);
    },
    publishNote,
    deleteNote: (noteId) => {
      setDeleteNoteModal({
        isOpen: true,
        noteId,
      });
    },
    deleteAttachment: (attachmentIndex, noteId) => {
      setDeleteAttachmentModal({
        isOpen: true,
        attachmentIndex,
        noteId,
      });
    },
    notes: filteredNotes,
    students: students,
  };
  const noteAttachmentsContextValue = {
    isOpen: attachmentsModal.isOpen,
    close: () => {
      setAttachmentsModal({
        isOpen: false,
        noteId: "",
      });
    },
    onSubmit: async (files) => {
      const { noteId } = attachmentsModal;
      setAttachmentsModal({
        isOpen: false,
        noteId: "",
      });
      const attachments = await saveAttachments(files);
      const note = notesList.find(({ id }) => id === noteId);
      await updateDoc(firebase.getLessonNote(noteId), {
        attachments: [...note.attachments, ...attachments],
      });
      setNotesList((previous) =>
        previous.map((note) => {
          if (note.id === noteId) {
            return {
              ...note,
              attachments: [...note.attachments, ...attachments],
            };
          }
          return note;
        })
      );
      toast.success("Attachments added successfully");
    },
  };

  const teachersSelectContextValue = {
    selected: selectedTeachers,
    select: onTeacherSelect,
    toggle: onToggleTeachersClick,
  };
  const shareItemModalContextValue = {
    isOpen: showShareItemModal.isOpen,
    openShareModal: openShareItemModal,
    closeShareModal: onCloseShareModal,
    submitShare: onSubmitShare,
  };

  const teachersContextValue = {
    teachers: teachersList,
  };
  const adminFilterContextValue = {
    setSelectedLocation,
    setSelectedStudent,
    setSelectedTeacher,
    selectedLocation,
    selectedTeacher,
    selectedStudent,
    onFilter,
    locations,
  };
  return (
    <div className={"h-100"}>
      {isLoading ? (
        <Spinner />
      ) : (
        <StudentsContext.Provider value={studentsContextValue}>
          <TeachersContext.Provider value={teachersContextValue}>
            <StudentsSelectContext.Provider value={studentsSelectContextValue}>
              <div className={"d-flex justify-content-between"}>
                <TeachersSelectContext.Provider
                  value={teachersSelectContextValue}
                >
                  <StudentsSelectContext.Provider
                    value={studentsSelectContextValue}
                  >
                    <AdminFilterContext.Provider
                      value={adminFilterContextValue}
                    >
                      <AdminFilter />
                    </AdminFilterContext.Provider>
                  </StudentsSelectContext.Provider>
                </TeachersSelectContext.Provider>
                <TeacherLessonNotesModalContext.Provider
                  value={teacherLessonNotesModalContextValue}
                >
                  {noteModal.isOpen ? (
                    <AddLessonNoteModal onSubmit={onSubmit} />
                  ) : null}
                </TeacherLessonNotesModalContext.Provider>
                <DeleteNoteContext.Provider value={deleteNoteContextValue}>
                  <DeleteNoteConfirmModal userId={user.uid} />
                </DeleteNoteContext.Provider>
                <DeleteNoteAttachmentContext.Provider
                  value={deleteNoteAttachmentContextValue}
                >
                  <DeleteNoteAttachmentConfirmModal userId={user.uid} />
                </DeleteNoteAttachmentContext.Provider>
                <NoteAttachmentsContext.Provider
                  value={noteAttachmentsContextValue}
                >
                  {attachmentsModal.isOpen ? (
                    <AddLessonNoteAttachmentsModal />
                  ) : null}
                </NoteAttachmentsContext.Provider>
                <ShareItemModalContext.Provider
                  value={shareItemModalContextValue}
                >
                  <TeachersSelectContext.Provider
                    value={teachersSelectContextValue}
                  >
                    <ShareItemModal />
                  </TeachersSelectContext.Provider>
                  <LessonNotesContext.Provider value={lessonNotesContextValue}>
                    <div className={"flex-grow-1"}>
                      <LessonNotes />
                    </div>
                  </LessonNotesContext.Provider>
                </ShareItemModalContext.Provider>
              </div>
            </StudentsSelectContext.Provider>
          </TeachersContext.Provider>
        </StudentsContext.Provider>
      )}
    </div>
  );
};
export default AdminLessonNotes;
