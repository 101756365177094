const downloadAttachment = async (src) => {
  const response = await fetch(src);
  const blob = await response.blob();
  const contentType = response.headers.get("Content-Type");
  const extension = contentType.split("/")[1];
  const fileName = `feedback-attachment.${extension}`;
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};

export default downloadAttachment;
