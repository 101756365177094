import { Modal } from "reactstrap";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 1.3rem;
    height: 200px;
    padding: 0;

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 375px) {
        padding: 0 !important;
      }
    }
  }

  .modal-footer {
    border: none;
    display: flex;
    justify-content: center;
  }
`;

const ModalText = styled.p`
  font-size: 18px;
  text-align: center;
  margin: 0;

  @media screen and (min-width: 576px) {
    font-size: 20px;
  }

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export { ModalText, ButtonsWrapper, StyledModal };
