import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import { injectUserStore } from "src/utils/helpers";
import useStudentConcertInvitation from "./hooks/useStudentConcertInvitation";
import StudentConcertInvitationBody from "./StudentConcertInvitationBody";

const StudentConcertInvitationModal = ({
  onSubmit,
  modalData,
  concertId,
  invitationId,
  UserStore,
}) => {
  const user = UserStore.user;

  const hookData = useStudentConcertInvitation({
    invitationId,
    user,
    concertId,
    onSubmit,
  });
  return (
    <div>
      <CustomModal
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
        // padding={"50px"}
        size="md"
      >
        <CustomModalHeader
          tag="div"
          close={
            <CloseIcon
              onClick={() => {
                modalData.closeModal();
              }}
            />
          }
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
          padding="25px"
        >
          <h2>Concert Invitation</h2>
        </CustomModalHeader>
        <CustomModalBody>
          <StudentConcertInvitationBody
            closeModal={modalData.closeModal}
            hookData={hookData}
            invitationId={invitationId}
          />
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default injectUserStore(StudentConcertInvitationModal);
