import {
  emailValidationSchema,
  passwordConfirmValidationSchema,
  passwordValidationSchema,
} from "src/utils/validations";
import * as yup from "yup";

const signUpSchema = yup.object().shape({
  email: emailValidationSchema,
  password: passwordValidationSchema,
  confirmPassword: passwordConfirmValidationSchema,
});

export default signUpSchema;
