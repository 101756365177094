import React from "react";
import { CustomModalBody } from "src/utils/styled";
import usePLModificationData from "./hooks/usePLModificationData";
import ModificationBody from "./ModificationBody";
import {
  CloseIcon,
  CustomHeader,
  CustomModal,
  HeaderContainer,
  ModalTitle,
} from "./styled";

const PrivateLessonModificationModal = ({
  modalData,
  initialData,
  event,
  closeDetailsModal,
  refreshData,
}) => {
  const modificationData = usePLModificationData({
    event,
    modalData,
    closeDetailsModal,
    refreshData,
  });
  return (
    <div>
      <CustomModal
        size="xs"
        centered
        isOpen={modalData.isModalOpen}
        toggle={modalData.toggleModal}
      >
        <CustomHeader
          close={<CloseIcon onClick={modalData.closeModal} />}
          className="pb-0 border-bottom-0"
          toggle={modalData.toggleModal}
        >
          <h2 className="fs-3 fw-bold">Edit Event</h2>
        </CustomHeader>
        <CustomModalBody>
          <ModificationBody
            modificationData={modificationData}
            initialData={initialData}
          />
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default PrivateLessonModificationModal;
