import { Button, Image, Upload } from "antd";
import {
  AttachmentItem,
  Attachments,
} from "src/components/Dashboard/Feedbacks/components/ActiveFeedback/AttachmentsList/styled";
import { PlusOutlined } from "@ant-design/icons";
import { ScreenshotsWrapper } from "./styled";
import { MdDelete } from "react-icons/md";

const ScreenshotsUpload = ({ request, screenshots, onDelete }) => {
  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <ScreenshotsWrapper>
      <Upload
        name="screenshots"
        listType="picture-card"
        accept="image/*"
        maxCount={5}
        customRequest={request}
        showUploadList={false}
      >
        {screenshots?.length >= 5 ? null : uploadButton}
      </Upload>
      {screenshots?.length > 0 && (
        <Attachments>
          <Image.PreviewGroup>
            {screenshots?.map(({ downloadUrl, id }, index) => (
              <AttachmentItem key={index}>
                <Image
                  width={200}
                  src={downloadUrl}
                  placeholder
                  alt="feature screenshot"
                  preview={{
                    destroyOnClose: true,
                  }}
                  loading="lazy"
                />
                <div className="delete-btn-wrapper">
                  <Button
                    onClick={() => onDelete(id)}
                    title="Delete screenshot"
                    aria-label="Delete screenshot"
                    type="dashed"
                  >
                    <MdDelete size={18} />
                  </Button>
                </div>
              </AttachmentItem>
            ))}
          </Image.PreviewGroup>
        </Attachments>
      )}
    </ScreenshotsWrapper>
  );
};

export default ScreenshotsUpload;
