import "./style.css";
import mapImage from "../../../../assets/images/mapImage.png";
import React from "react";

function Location() {
  return (
    <figure className="artis-location">
      <iframe
        src="https://www.google.com/maps/d/u/3/embed?mid=19czIDyuNGSBX2f6hTv0HvVFocr7iG7w&ehbc=2E312F&noprof=1"
        width="640"
        height="480"
        className="locationIFrame"
      />
      <div className="opening-hours">
        <h2>School Hours:</h2>
        <p>
          Monday, Tuesday, Thursday, Friday 2 - 8 pm
          <br /> Wednesday 12:30 - 8 pm
          <br /> Saturday 9:30 am - 3:30 pm
        </p>
        <h2>Call for Class Information</h2>
        <p>Monday - Friday: 10am - 2pm</p>
      </div>
    </figure>
  );
}

export default Location;
