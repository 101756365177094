import React, { useState } from "react";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import AbsenceTypesInfoMenu from "./AbsenceTypesInfoMenu";

const AbsencesManualDropdown = () => {
  const [showInfoMenu, setShowInfoMenu] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setShowInfoMenu(true);
      }}
      onMouseLeave={() => {
        setShowInfoMenu(false);
      }}
      style={{ display: "inline-block", position: "relative" }}
    >
      <InfoCircle
        style={{
          width: "20px",
          marginLeft: "6px",
          color: "#ff9ed2",
          cursor: "pointer",
        }}
      />
      {showInfoMenu && <AbsenceTypesInfoMenu />}
    </div>
  );
};

export default AbsencesManualDropdown;
