import React from "react";
import { PrimaryButtonWithLoading } from "src/utils/shared/shared";

const FreePaymentSection = ({ enrollStudent, isSaving }) => {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="mt-3 text-center" style={{ width: "80%" }}>
        <PrimaryButtonWithLoading
          width="100%"
          onClick={enrollStudent}
          isLoading={isSaving}
        >
          Enroll Now For Free
        </PrimaryButtonWithLoading>
      </div>
    </div>
  );
};

export default FreePaymentSection;
