import React, { useState } from "react";
import styled from "styled-components";
import { SubmitSpinner } from "../../../../common";
import useLessonsData from "../hooks/useLessonsData";
import CustomDropDown from "../../../../common/DropDown";
import PrivateLessonInfo from "./PrivateLessonInfo";
import { isPackagePrivateLesson } from "src/constants/eventsEnum";
import moment from "moment";
import MakeupLessonInfo from "./MakeupLessonInfo";

const LessonContainer = styled.section`
  background-color: #681e460d;
  min-height: 350px;
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #dddddd;
  font-weight: 900;
`;

const LessonsInfo = ({ user }) => {
  const lessonsData = useLessonsData(user);
  const { allLessons, loadingLessons, currentLesson, setCurrentLessonId } =
    lessonsData;

  if (loadingLessons) {
    return (
      <LessonContainer className="d-flex justify-content-center align-items-center">
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </LessonContainer>
    );
  }
  if (!allLessons.length) {
    return <h1>No Lessons Available</h1>;
  }

  const Lessons = allLessons.map((lesson) => ({
    value: lesson.id,
    key: lesson.id,
    label: isPackagePrivateLesson(lesson.type)
      ? `${lesson.instrument?.name} | PACKAGE`
      : `${lesson.instrument?.name} | ${
          lesson.currentTimeline?.teacher?.fullName || "Unassigned"
        }`,
  }));

  let isNextLessonMakeup = false;
  if (isPackagePrivateLesson(currentLesson?.type)) {
    const { packageItemsWithExtraData, nextPackageItem, nextMakeupLesson } =
      currentLesson || {};

    const nextPackageItemDate = nextPackageItem?.startDate;
    const nextMakeupLessonDate = nextMakeupLesson?.date?.startDate;
    if (nextPackageItemDate && nextMakeupLessonDate) {
      const isMakeupSooner = moment(nextMakeupLessonDate).isBefore(
        nextPackageItemDate,
        "minutes"
      );

      isNextLessonMakeup = isMakeupSooner;
    }
  } else {
    const { currentTimeline, nextMakeupLesson } = currentLesson || {};

    const nextLessonDate = currentTimeline?.nextDate;
    const nextMakeupLessonDate = nextMakeupLesson?.date?.startDate;
    if (nextLessonDate && nextMakeupLessonDate) {
      const isMakeupSooner = moment(nextMakeupLessonDate).isBefore(
        nextLessonDate,
        "minutes"
      );

      isNextLessonMakeup = isMakeupSooner;
    }
  }

  return (
    <LessonContainer>
      <div className="mb-4">
        <div className="mb-3">
          <h2 className="fw-bolder">Lessons</h2>
        </div>
        <div>
          <CustomDropDown
            label={
              Lessons.find(({ value }) => value === currentLesson?.id)?.label
            }
            keyName="label"
            options={Lessons}
            headerClassName="toggleBtn"
            className={"col-lg-3 col-4"}
            onOptionClick={(option) => {
              setCurrentLessonId(option.value);
            }}
          />
        </div>
      </div>

      {isNextLessonMakeup ? (
        <MakeupLessonInfo makeupLesson={currentLesson?.nextMakeupLesson} />
      ) : (
        <PrivateLessonInfo privateLesson={currentLesson} />
      )}
    </LessonContainer>
  );
};
export default LessonsInfo;
