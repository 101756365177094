import { List, Timeline } from "antd";
import styled from "styled-components";

const ProcessWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
  border: 1px solid #681e46;
  padding: 24px;

  border-radius: 1.3rem;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  p {
    font-size: 18px;
    text-align: center;
  }
`;

const StyledTimeLine = styled(Timeline)`
  margin-top: 32px;

  .ant-timeline-item-content {
    font-size: 18px;
  }

  .ant-timeline-item {
    padding-bottom: 32px;
  }
`;

const StyledList = styled(List)`
  max-width: 640px;
  margin: 32px auto 0 auto;
  font-size: 18px;
`;

export { ProcessWrapper, StyledTimeLine, StyledList };
