const statusEnum = {
  ON_REVIEW: "On review",
  IN_PROGRESS: "In progress",
  COMPLETED: "Completed",
};

const statusColorsEnum = {
  [statusEnum.ON_REVIEW]: "#e357a2",
  [statusEnum.IN_PROGRESS]: "#2196F3",
  [statusEnum.COMPLETED]: "#4CAF50",
};

export { statusEnum, statusColorsEnum };
