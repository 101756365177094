import { createContext } from "react";

export const AttendanceContext = createContext({
    selectedStudent: '',
    instruments: [],
    schoolYears: [],
    locations: [],
    isReadOnly: false,
    isStudent: false,
    addExtra: () => {},
});
export const AttendanceModalContext = createContext({
    isOpen: false,
    open: attendance => {},
    close: () => {},
    submit: data => {},
    isEdit: false,
    isReadOnly: false,
    setIsReadOnly: val => {},
    attendance: {
        attendanceDate: new Date(),
        lessonDate: new Date(),
    },
    deleteAttendance: () => {},
});
export const CheckInModalContext = createContext({
    isOpen: false,
    close: () => {},
    submit: date => {},
});