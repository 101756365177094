import React from "react";
import {
  ALLOWED_EXTENSIONS,
  isValidFileType,
  typesOptions,
} from "src/components/common/Library/common/constants";
import FilesPreview from "src/components/common/Library/new-item-modal/FilesPreview";
import { LibraryModalInput } from "src/components/common/styled-components/teacherLibraryStyledComponents";
import CustomDropDown from "src/components/common/DropDown";
import LibraryFileUploadInput from "src/components/common/Library/new-item-modal/LibraryFileUploadInput";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ScreenSize } from "src/constants/ScreenSizeEnum";
import { CustomLabel } from "src/utils/styled";

const FieldContainer = styled.div`
  width: 47%;
  @media (max-width: ${ScreenSize.SM}px) {
    width: 100%;
  }
`;

const UploadFromDeviceSection = ({
  uploadFiles,
  setUploadFiles,
  recommendationLink,
  setRecommendationLink,
}) => {
  const [fileType, setFileType] = useState(typesOptions[0]);
  const [error, setError] = useState("");

  const onUploadFile = (e) => {
    const file = e.target.files[0];
    const { type } = file;
    const isValid = isValidFileType(type, fileType?.value);
    console.log({ isValid, type, fileType: fileType?.value });
    if (!isValid) {
      toast.error(
        "Please upload a file that corresponds to the selected file category"
      );
      return;
    }
    const extension = type.split("/")[1];
    if (ALLOWED_EXTENSIONS.includes(extension)) {
      const fileObject = {
        file,
        name: `${fileType.label} | ${file.name}`,
        attachmentType: fileType.value,
        isNew: true,
      };
      setUploadFiles((previous) => [...previous, fileObject]);
      setFileType({ ...typesOptions[0] });
      setError("");
    } else {
      setError("File extension not supported");
    }
  };

  const onAddClick = () => {
    const fileObject = {
      name: `${fileType.label} | ${recommendationLink}`,
      recommendationLink,
      attachmentType: fileType.value,
    };
    setUploadFiles((previous) => [...previous, fileObject]);
    setFileType(typesOptions[0]);
    setError("");
    setRecommendationLink("");
  };

  const onFileOptionChange = (option) => {
    setFileType(option);
  };
  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap"
        style={{ gap: 10 }}
      >
        <FieldContainer>
          <CustomLabel displayBlock>Step 1. select the category</CustomLabel>
          <CustomDropDown
            label={fileType.label}
            value={fileType.label}
            // header={"Attachment Type"}
            keyName={"label"}
            options={typesOptions}
            onOptionClick={onFileOptionChange}
          />
        </FieldContainer>
        <FieldContainer>
          <CustomLabel displayBlock>Step 2. upload the file</CustomLabel>

          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              {fileType.id === "recommendationLinks" ? (
                <div className={"d-flex align-items-center"}>
                  <div>
                    <LibraryModalInput
                      formik={false}
                      // label={"Recommendation Link"}
                      name="Recommendation Link"
                      placeholder="Recommendation Link"
                      height={"40px"}
                      size={12}
                      value={recommendationLink}
                      customStyle={{
                        marginBottom: 0,
                      }}
                      onChange={(e) => setRecommendationLink(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={onAddClick}
                    className={"btn btn-light d-flex align-items-center ms-2"}
                  >
                    <i className="fa fa-plus me-2" />
                    <span>Add</span>
                  </button>
                </div>
              ) : (
                <LibraryFileUploadInput
                  onUploadFile={onUploadFile}
                  customStyle={{
                    height: 40,
                    marginBottom: 0,
                  }}
                  label={"Upload"}
                />
              )}
            </div>
          </div>
        </FieldContainer>
      </div>
      <div className="text-muted mt-2">
        Supported extensions: {ALLOWED_EXTENSIONS.join(", ")}
      </div>

      {error !== "" && <div className={"alert alert-danger"}>{error}</div>}
    </div>
  );
};

export default UploadFromDeviceSection;
