import styled from "styled-components";
import { Calendar } from "react-big-calendar";
import { ScreenSize } from "src/constants/ScreenSizeEnum";

export const CustomCalendar = styled(Calendar)`
  color: black;
  .rbc-row-segment a {
    color: black;
  }
  .rbc-toolbar {
    gap: 10px;
  }
  // a selector for the "today" button
  .rbc-toolbar > .rbc-btn-group:first-child > button:first-child {
    /* background-color: #ff37371a; */
  }
  // selector for the current date label header
  .rbc-toolbar > .rbc-toolbar-label {
    font-size: 1.3rem;
  }

  // the row under the header that shows the all day events (without the showMultiDayTimes prop)
  .rbc-allday-cell {
    /* display: none; */
    height: 35px;
  }

  // for setting the current time indicator UI (line indicator)
  .rbc-current-time-indicator {
    background-color: #ea4335;
    height: 0.1rem;
    z-index: 10;
  }

  .rbc-allday-cell {
    height: fit-content;
  }
  ${({ showCurrentTimeIndicatorDot }) =>
    showCurrentTimeIndicatorDot &&
    `.rbc-current-time-indicator:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #ea4335;
    border-radius: 50%;
    left: -10px;
    bottom: -4px;
  }`}
  .rbc-event {
    border-color: #fff;
    padding: 0;
  }

  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    min-height: 7px;
  }
  .rbc-time-slot {
    min-height: 20px;
  }

  @media (max-width: ${ScreenSize.LG}px) {
    /* & .rbc-toolbar {
      display: none;
    } */
    & .rbc-time-view,
    & .rbc-month-view {
      background-color: #f6f2f4;
      overflow: auto;
    }
    & .rbc-time-view > *,
    & .rbc-month-view > * {
      width: 1000px;
    }
  }
`;
