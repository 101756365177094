import React, { useContext, useEffect, useState } from "react";
import Library from "../../../common/Library/Library";

const StudentLibrary = ({ user }) => {
  return (
    <Library user={user} />
  );
};

export default StudentLibrary;
