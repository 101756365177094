import {
  addDoc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import React, { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import { trialLessonStatuses } from "src/constants/trialLessonStatuses";
import {
  parseUserActivityDates,
  parseUserAvailableDayDates,
} from "src/utils/firebaseDatesParserFns";
import { parseFirebaseDoc } from "src/utils/getFirebaseDoc";
import { chunkArrayInGroups } from "src/utils/helpers";

const useFirebaseFns = () => {
  const firebase = useContext(FirebaseContext);

  const createAbsence = async (absenceObj) => {
    if (!absenceObj) return;

    try {
      const res = await addDoc(firebase.absences(), absenceObj);
      return res;
    } catch (err) {
      console.log(err);
    }
  };
  const createMakeUpOpening = async (makeupOpeningObj) => {
    if (!makeupOpeningObj) return;

    try {
      const res = await addDoc(firebase.makeupOpenings(), makeupOpeningObj);
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeacherTLs = async (teacherId) => {
    try {
      const trialLessonsQuery = query(
        firebase.trialLessons(),
        where("teacherId", "==", teacherId),
        where("status", "!=", trialLessonStatuses.canceled.code)
      );
      const trialLessonsSnapshot = await getDocs(trialLessonsQuery);
      const trialLessons = parseFirebaseDoc(trialLessonsSnapshot.docs);
      const trialLessonsWithDates = trialLessons.map((lsn) => {
        const date = lsn.date?.toDate();
        return {
          ...lsn,
          date,
        };
      });
      return trialLessonsWithDates;
    } catch (err) {
      console.log(err);
    }
  };
  const getTeacherMakeupLessons = async (teacherId) => {
    try {
      const makeupLessonsQuery = query(
        firebase.makeupLessons(),
        where("teacherId", "==", teacherId)
      );
      const makeupLessonsSnapshot = await getDocs(makeupLessonsQuery);
      const makeupLessons = parseFirebaseDoc(makeupLessonsSnapshot.docs);

      const makeupLessonsWithDates = makeupLessons.map((lsn) => {
        const date = {
          ...lsn.date,
          startDate: lsn.date?.startDate?.toDate(),
          endDate: lsn.date?.endDate?.toDate(),
        };
        return {
          ...lsn,
          date,
        };
      });
      return makeupLessonsWithDates;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeacherAvailableDays = async (teacherId) => {
    try {
      let teacherDays = await getDoc(firebase.userAvailableDays(teacherId));
      teacherDays = teacherDays.data();
      const parsedDates = parseUserAvailableDayDates(teacherDays);

      return parsedDates?.availableDays || [];
    } catch (err) {
      console.log(err);
    }
  };

  return {
    createAbsence,
    getTeacherTLs,
    getTeacherMakeupLessons,
    getTeacherAvailableDays,
    createMakeUpOpening,
  };
};

export default useFirebaseFns;
