import { toJS } from "mobx";
import React from "react";
import { useState } from "react";
import { TABS } from "src/components/common/Library/common/constants";
import { injectUserStore } from "src/utils/helpers";
import { PrimaryButton } from "src/utils/shared/styled";
import useLibraryData from "../hooks/useLibraryData";
import LibraryItems from "./LibraryItems";
import NavigationBar from "./NavigationBar";

const ChooseFromLibraryBody = ({
  modalData,
  addChosenLibraryItems,
  libraryChosenItems,
  UserStore,
}) => {
  const user = toJS(UserStore?.user);
  const libraryData = useLibraryData({ user, libraryChosenItems });
  const { selectedItems, initialData } = libraryData;
  const { libraryItems } = initialData;
  const [currentTab, setCurrentTab] = useState(TABS[0].key);

  const handleAddChosenLibraryItems = () => {
    const items = selectedItems.map((itemId) => {
      const item = libraryItems.find(({ id }) => id === itemId);
      return item
        ? {
            ...item,
            isNew: true,
          }
        : undefined;
    });
    addChosenLibraryItems(items);
    modalData.closeModal();
  };
  return (
    <div style={{ height: "70vh" }}>
      <div className="mb-5">
        <NavigationBar currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </div>
      <div style={{ height: "48vh", overflow: "auto" }}>
        <LibraryItems libraryData={libraryData} currentTab={currentTab} />
      </div>
      <div className="text-center">
        <PrimaryButton onClick={handleAddChosenLibraryItems}>
          Add Seleted Items
        </PrimaryButton>
      </div>
    </div>
  );
};

export default injectUserStore(ChooseFromLibraryBody);
