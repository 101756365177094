import React from "react";
import { MenuContainer, OptionContainer } from "./styled";
import { SubmitSpinner } from "src/components/common";

const ActionsDropdownContent = ({ optionsList, loadingAction = false }) => {
  return (
    <MenuContainer>
      {loadingAction ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100px" }}
        >
          <SubmitSpinner
            style={{
              width: "2rem",
              height: "2rem",
              color: "#681e46",
              margin: "20px",
            }}
          />
        </div>
      ) : (
        optionsList.map((item, i) => {
          const { title, action } = item;
          const isLastItem = i === optionsList.length - 1;
          return (
            <OptionContainer
              borderBottom={isLastItem ? "" : "1px solid #e1e1e1"}
              onClick={action}
            >
              {title}
            </OptionContainer>
          );
        })
      )}
    </MenuContainer>
  );
};

export default ActionsDropdownContent;
