import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";

const ResumeUpload = ({ request, isUploaded }) => {
  return (
    <div>
      <Upload
        name="resume"
        accept="application/pdf"
        maxCount={1}
        customRequest={request}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      {isUploaded && <span style={{ marginLeft: 12 }}>File attached</span>}
    </div>
  );
};

export default ResumeUpload;
