import TeamMemberCardIconsWrapper from "../TeamMemberCardIconsWrapper";
import "./style.css";
import DeleteTeacherLibraryItemsModal from "src/components/Dashboard/SuperAdmin/TeachersPage/components/TableLayout/modals/DeleteTeacherLibraryItemsModal";
import { useEffect, useState } from "react";

const TeamMemberHeaderWrapper = ({
  image,
  name,
  profession,
  specialization,
  raisingTalent,
  social,
  isModal,
}) => {
  const [socialUrlSelected, setSocialUrlSelected] = useState(null);
  useEffect(() => {
    if (socialUrlSelected) {
      window.location.href = socialUrlSelected;
    }
  }, [socialUrlSelected]);
  return (
    <div
      className={
        "teamMemberCardHeaderWrapper" +
        (isModal ? " teamMemberCardHeaderWrapperModal" : "")
      }
    >
      <div
        className={
          "teamMemberCardHeaderPicture" +
          (isModal ? " teamMemberCardHeaderPictureModal" : "")
        }
      >
        <img
          className={
            "teamMemberProfileImage" +
            (isModal ? " teamMemberProfileImageModal" : "")
          }
          loading="lazy"
          src={image}
          alt={name}
        />
      </div>
      <div
        className={
          "teamMemberCardTitleWrapper" +
          (DeleteTeacherLibraryItemsModal
            ? " teamMemberCardTitleWrapperModal"
            : "")
        }
      >
        <h2 style={raisingTalent ? { marginTop: "35px" } : {}}>{name}</h2>
        <p>{profession}</p>
        <div>
          {social &&
            social.map((socialObg) => {
              const { Icon, url, name } = socialObg;
              return (
                <Icon
                  key={name}
                  className="socialIcon"
                  onClick={() => setSocialUrlSelected(url)}
                  title={name}
                />
              );
            })}
        </div>

        {isModal && specialization && specialization[0] && (
          <TeamMemberCardIconsWrapper
            specialization={specialization}
            isModal={true}
          />
        )}
      </div>
    </div>
  );
};

export default TeamMemberHeaderWrapper;
