import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import {
  requiredStringSchema,
  requiredIDStringSchema,
} from "../../../../../../../../../utils/validations";
import { Form, Formik } from "formik";
import * as yup from "yup";

import {
  CustomSelectField,
  QrContainer,
  RightIcon,
  SubmitButton,
} from "./styled";
import InputWithLabel from "../../../../../../../../Auth/shared/InputWithLabel";
import styled from "styled-components";
import { useRegistrationContext } from "../../../../../../../../../contexts/RegisterationContext";
import axios from "axios";
import { GENERATE_NEW_USER_ID } from "../../../../../../../../../constants/ApiEndpoints";
import { UserRole } from "../../../../../../../../../constants/UserRoleEnum";
import { SubmitSpinner } from "../../../../../../../../common";
import { useInitialPageDataContext } from "src/contexts/InitialPageDataContext";
import { toast } from "react-toastify";

const FirstAddTeacherFormValuesValidationSchema = yup.object().shape({
  fullName: requiredStringSchema,
  systemID: requiredStringSchema,
});

const InputWrapper = styled.div`
  width: ${({ width }) => (width ? width : "50%")};
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;
// const InputWrapper = ({ children }) => {
//   return <Wrapper>{children}</Wrapper>;
// };
const FirstTeacherRegister = () => {
  const { nextStep, formValues, setFormValues } = useRegistrationContext();
  const [loadingID, setLoadingID] = useState(true);
  const [selectedSystemIDLocation, setSelectedSystemIDLocation] = useState("");
  const { locations } = useInitialPageDataContext();

  useEffect(() => {
    setSelectedSystemIDLocation(locations[0]?.id);
  }, []);

  useEffect(() => {
    if (selectedSystemIDLocation) {
      const getNewTeacherID = async () => {
        try {
          setLoadingID(true);
          const { data } = await axios.post(GENERATE_NEW_USER_ID, {
            userRole: UserRole.TEACHER,
            locationId: selectedSystemIDLocation,
          });
          if (data?.nextUserFullID) {
            setFormValues((oldVal) => ({
              ...oldVal,
              firstStepValues: {
                ...oldVal.firstStepValues,
                systemID: data.nextUserFullID,
              },
            }));
          }
        } catch (err) {
          setFormValues((oldVal) => ({
            ...oldVal,
            firstStepValues: {
              ...oldVal.firstStepValues,
              systemID: "",
            },
          }));
          toast.error("Couldn't generate user ID");
          toast.error(err.message);
          console.log(err);
        } finally {
          setLoadingID(false);
        }
      };
      getNewTeacherID();
    }
  }, [selectedSystemIDLocation]);

  const formikInitialValues = {
    fullName: "",
    systemID: formValues.firstStepValues.systemID || "",
  };
  if (loadingID)
    return (
      <SpinnerWrapper>
        <SubmitSpinner
          style={{ width: "3rem", height: "3rem", color: "#681e46" }}
        />
      </SpinnerWrapper>
    );
  return (
    <Formik
      initialValues={formikInitialValues}
      enableReinitialize
      validationSchema={FirstAddTeacherFormValuesValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setFormValues((oldVal) => ({
          ...oldVal,
          firstStepValues: {
            ...values,
            systemID: oldVal.firstStepValues.systemID,
          },
        }));
        nextStep();
        setSubmitting(false);
      }}
    >
      {({ touched, errors, isSubmitting, values }) => (
        <Form>
          {console.log({ values })}
          <div className="d-flex justify-content-center w-50 m-auto mt-4">
            <div>
              <p>Location</p>
              <CustomSelectField
                width="300px"
                value={selectedSystemIDLocation}
                onChange={(e) => setSelectedSystemIDLocation(e.target.value)}
              >
                <option value="" disabled>
                  Select A Location
                </option>
                {locations.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </CustomSelectField>
            </div>
          </div>
          <div className="d-flex">
            <div className="w-100 d-flex justify-content-between">
              <InputWrapper width="45%">
                <InputWithLabel
                  name="fullName"
                  error={errors.fullName}
                  touched={touched.fullName}
                  label="Name Of Teacher"
                  placeholder="Name Of Teacher"
                  type="text"
                />
              </InputWrapper>

              <InputWrapper width="45%">
                <InputWithLabel
                  readOnly
                  value={formValues.firstStepValues.systemID}
                  name="systemID"
                  error={errors.systemID}
                  touched={touched.systemID}
                  label="Teacher ID"
                  placeholder="Teacher ID"
                  type="text"
                />
              </InputWrapper>
            </div>
          </div>

          <div className="text-center mt-4">
            <SubmitButton
              className="mt-3 mb-3"
              disabled={isSubmitting}
              type="submit"
              style={{ width: "50%", margin: "auto" }}
            >
              <div className="w-100 position-relative">
                <span>Next Page</span>
                <RightIcon />
              </div>
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FirstTeacherRegister;
