import React from "react";
import { LabeledRadioContainer } from "../../styled-components/teacherLibraryStyledComponents";

const LabeledRadioButton = ({
  label,
  id,
  onChange,
  checked = false,
}) => {
  return (
    <LabeledRadioContainer className={"d-flex align-items-center"}>
      <input
        className={"me-1"}
        type="radio"
        name={"view"}
        value={label}
        id={id}
        checked={checked}
        onChange={() => onChange(label)}
      />
      <label htmlFor={id}>{label}</label>
    </LabeledRadioContainer>
  );
};
export default LabeledRadioButton;
