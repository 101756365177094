import "./style.css";
import React, { useState } from "react";
import { postRequest } from "../../common/postRequest.js";
import { CONTACT_US_ENDPOINT } from "src/constants/ApiEndpoints";

function Form() {
  const [formData, setFormData] = useState({});
  const [isPending, setIsPending] = useState(false);

  // submit button different versions
  const submitElement = () => {
    if (isPending === null) {
      return (
        <input type="submit" name="submit" value="Try again" id="submit" />
      );
    } else if (isPending === true) {
      return (
        <input
          type="submit"
          disabled
          name="submit"
          value="Sending..."
          id="submit"
        />
      );
    } else if (isPending === "success") {
      return (
        <input
          disabled
          type="submit"
          name="submit"
          value="Success"
          id="submit"
        />
      );
    } else {
      return (
        <input type="submit" name="submit" value="Send Message" id="submit" />
      );
    }
  };
  // post request for the apiUrl

  const handleSubmit = (e) => {
    setIsPending(true);

    postRequest(CONTACT_US_ENDPOINT, formData)
      .then((res) => {
        if (res) {
          setIsPending("success");
        } else if (res) {
          setIsPending(null);
        }
      })
      .catch((err) => {
        setIsPending(null);
      });
  };

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <div className="form-header">
        <p>Address:</p>
        <a
          target="_blank"
          href="https://www.google.com/maps/place/Artis+School+of+Music/@37.5125167,-122.2939621,18z/data=!4m5!3m4!1s0x808f9f668f4b7f8d:0x4b79c20f4351cf22!8m2!3d37.5121557!4d-122.2931868"
        >
          2033 Ralston Ave, Belmont, CA 94002 (Next to Bank of America)
        </a>
        <p>Email:</p>
        <a href="mailto:info@artismymusic.com"> info@artismymusic.com</a>
        <p>Phone:</p>
        <a href="tel:0016507320037">(650) 732-0037</a>
      </div>
      <form
        className="contact-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <input
          onChange={(e) => {
            e.preventDefault();
            handleOnChange(e);
          }}
          required
          type="text"
          name="name"
          id="name"
          placeholder="Name"
        />
        <input
          onChange={(e) => {
            e.preventDefault();
            handleOnChange(e);
          }}
          required
          type="email"
          name="email"
          id="email"
          placeholder="Email"
        />
        <textarea
          onChange={(e) => {
            e.preventDefault();
            handleOnChange(e);
          }}
          required
          id="message"
          name="message"
          placeholder="Message"
        ></textarea>
        {submitElement()}
      </form>
    </>
  );
}

export default Form;
