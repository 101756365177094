import React from "react";
import { isFreeForAllStudentsConcert } from "src/constants/concertEnum";
import { PrimaryButtonWithBlink } from "src/utils/shared/shared";
import { PrimaryButton } from "src/utils/shared/styled";

const StudentButtons = ({
  concert,
  handleInvitationClick,
  userActiveInvitations,
  openStudentSignupModal,
}) => {
  const { availableSignupOptions } = concert;

  const isFreeForAllStudentsOptionAvailable = isFreeForAllStudentsConcert(
    availableSignupOptions?.type
  );

  return (
    <>
      {!!userActiveInvitations.length && (
        <div className="d-flex flex-column" style={{ gap: 20 }}>
          {userActiveInvitations.map((invitation) => (
            <PrimaryButtonWithBlink
              onClick={() => handleInvitationClick(invitation.id)}
              width="auto"
              style={{ flex: 1 }}
            >
              Teacher Recommendation for signup - Time Sensitive!
            </PrimaryButtonWithBlink>
          ))}
        </div>
      )}
      {isFreeForAllStudentsOptionAvailable && (
        <div className=" text-center">
          <PrimaryButton
            onClick={() => openStudentSignupModal()}
            width="100%"
            maxWidth="400px"
            style={{ flex: 1 }}
          >
            I want to sign up
          </PrimaryButton>
        </div>
      )}
    </>
  );
};

export default StudentButtons;
