import React from "react";
import {
  CloseIcon,
  CustomModal,
  CustomModalBody,
  CustomModalHeader,
} from "src/utils/styled";
import EditBody from "./EditBody";
import useTrialLessonEditData from "./hooks/useTrialLessonEditData";

const TrialLessonEditModal = ({
  modalData,
  event,
  refreshData,
  closeDetailsModal,
}) => {
  const editData = useTrialLessonEditData({
    trialLesson: event,
    refreshData,
    closeDetailsModal,
  });
  return (
    <CustomModal
      size="xs"
      centered
      isOpen={modalData.isModalOpen}
      toggle={modalData.toggleModal}
    >
      <CustomModalHeader
        close={<CloseIcon onClick={modalData.closeModal} />}
        className="pb-0 border-bottom-0"
        toggle={modalData.toggleModal}
      >
        <h2 className="fs-3 fw-bold">Edit Trial Lesson</h2>
      </CustomModalHeader>
      <CustomModalBody>
        <EditBody
          event={event}
          refreshData={refreshData}
          closeDetailsModal={closeDetailsModal}
          editData={editData}
        />
      </CustomModalBody>
    </CustomModal>
  );
};

export default TrialLessonEditModal;
