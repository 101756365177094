import React, {useContext} from "react";
import {ActiveTabContext} from "./libraryContext";

const EmptyResults = ({ isSearch }) => {
  return (
    <div className={"alert alert-warning"}>
      {isSearch? "No results found" : "You don't have any items here yet"}
    </div>
  );
};
export default EmptyResults;
