const setActionBgColor = (actiontype) => {
  switch (actiontype) {
    case "requestedDelete":
      return "yellow";
    case "delete":
      return "red";
    case "approve":
      return "green";
    case "decline":
      return "orange";
    default:
      return "";
  }
};

const setActionColor = (actiontype) => {
  switch (actiontype) {
    case "delete":
    case "approve":
    case "decline":
      return "#F0F0F0";
    case "requestedDelete":
    case "archive":
      return "#000000e0";
    default:
      return "";
  }
};

const setTableRowClassName = (status) => {
  switch (status) {
    case "Approved":
      return "approved";
    case "Declined":
      return "declined";
    default:
      return "";
  }
};

export { setActionBgColor, setActionColor, setTableRowClassName };
