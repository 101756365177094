import React from "react";
import NavButtons from "./components/NavButtons";
import useAddStudent from "./hooks/useAddStudent";
import { studentViewOptions } from "./constants";
import NewStudent from "./components/NewStudent";
import CurrentStudent from "./components/CurrentStudent";
import { InnerContainer } from "./styled";
import PaymentSection from "./components/PaymentSection";
import { groupClassPaymentStudentTypes } from "src/constants/groupClassPaymentsConstants";

const AddStudentBody = ({ addStudentData, groupClassId }) => {
  const {
    selectedStudentView,
    handleSelectedViewChange,
    newStudentformData,
    handleNewStudentFormChange,
    isNewStudentView,
    isCurrentStudentView,
    isNewStudentCurrentComponent,
    isCurrentStudentCurrentComponent,
    isPaymentCurrentComponent,
    currentStudentData,
    selectedCurrentStudent,
    setPaymentStudentId,
    filteredCurrentStudents,
    currentStudentsSearchTerm,
    setCurrentStudentsSearchTerm,
    paymentStudentId,
  } = addStudentData;

  const studentType =
    selectedStudentView === studentViewOptions.NEW
      ? groupClassPaymentStudentTypes.NEW_STUDENT
      : selectedStudentView === studentViewOptions.CURRENT
      ? groupClassPaymentStudentTypes.CURRENT_STUDENT
      : "";

  const renderCurrentViewComponent = () => {
    if (isPaymentCurrentComponent) {
      return (
        <PaymentSection
          studentId={paymentStudentId}
          // studentId="0Lr2qf3rFdgw5YYhZgMUBbAHG8g1"
          studentType={studentType}
          groupClassId={groupClassId}
        />
      );
    } else if (isCurrentStudentCurrentComponent) {
      return (
        <CurrentStudent
          currentStudentsSearchTerm={currentStudentsSearchTerm}
          setCurrentStudentsSearchTerm={setCurrentStudentsSearchTerm}
          students={filteredCurrentStudents}
          selectedCurrentStudent={selectedCurrentStudent}
          setPaymentStudentId={setPaymentStudentId}
        />
      );
    } else if (isNewStudentCurrentComponent) {
      return (
        <NewStudent
          newStudentformData={newStudentformData}
          handleNewStudentFormChange={handleNewStudentFormChange}
        />
      );
    }
  };
  return (
    <div className="p-4">
      {!isPaymentCurrentComponent && (
        <>
          <NavButtons
            selectedStudentView={selectedStudentView}
            handleSelectedViewChange={handleSelectedViewChange}
          />
          <hr />
        </>
      )}
      <InnerContainer>{renderCurrentViewComponent()}</InnerContainer>
    </div>
  );
};

export default AddStudentBody;
