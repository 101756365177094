import { getDocs } from "firebase/firestore";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FirebaseContext } from "../components/Firebase";
import { parseFirebaseDoc } from "../utils/getFirebaseDoc";

const InitialPageDataContext = createContext();

export const InitialPageDataContextProvider = ({ children, ...props }) => {
  const firebase = useContext(FirebaseContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    instruments: [],
    locations: [],
    studios: [],
    programs: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const instrumentsReq = getDocs(firebase.instruments());
        const locationsReq = getDocs(firebase.getLocations());
        const studiosReq = getDocs(firebase.studios());
        const programsReq = getDocs(firebase.programs());

        const [
          instrumentsSnapshot,
          locationsSnapshot,
          studiosSnapshot,
          programsSnapshot,
        ] = await Promise.all([
          instrumentsReq,
          locationsReq,
          studiosReq,
          programsReq,
        ]);

        const instruments = parseFirebaseDoc(instrumentsSnapshot.docs);
        instruments.sort((a, b) => (a.name > b.name ? 1 : -1));
        const locations = parseFirebaseDoc(locationsSnapshot.docs);
        const studios = parseFirebaseDoc(studiosSnapshot.docs);
        const programs = parseFirebaseDoc(programsSnapshot.docs);

        setData((oldVal) => ({
          ...oldVal,
          instruments,
          locations,
          studios,
          programs,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const value = useMemo(
    () => ({
      loading,
      locations: data.locations,
      instruments: data.instruments,
      studios: data.studios,
      programs: data.programs,
    }),
    [loading, data]
  );
  return (
    <InitialPageDataContext.Provider value={value} {...props}>
      {children}
    </InitialPageDataContext.Provider>
  );
};

export default InitialPageDataContext;

export const useInitialPageDataContext = () => {
  return useContext(InitialPageDataContext);
};
