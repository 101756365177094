import React from "react";
import { Input } from "reactstrap";
import { Field } from "formik";
import styled from "styled-components";
import { RequiredInputSpan } from "src/utils/shared/styled";

const StyledIconSpan = styled.span`
  display: block !important;
  margin: auto;
  min-width: 48px;
  text-align: center;
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  background-color: #f27281 !important;
  font-size: 16px !important;
  padding: 6px 12px !important;
`;

const Label = styled.label`
  margin-left: 1rem;
  color: ${({ color }) => color && color};
`;

export const NewRadiusInput = styled(({ hasIcon, ...rest }) => (
  <Input {...rest} />
))`
  font-size: 13px !important;
  height: 38px !important;
  ${(props) =>
    !props.hasIcon
      ? "border-radius: 1rem !important;"
      : "border-top-right-radius: 1rem !important;\n  border-bottom-right-radius: 1rem !important;"};
  background-color: ${(props) =>
    !props.backgroundColor ? "#fffde3" : props.backgroundColor};
  ${({ withFocus = true }) =>
    !withFocus
      ? ""
      : `:focus {
    border-color: #ffffff !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.2) !important;
  }`};
`;

const LabelInput = ({
  error,
  touched,
  icon,
  label,
  size,
  name,
  className,
  labelColor,
  formik = true,
  customStyle = {},
  isRequired,
  ...rest
}) => (
  <div
    style={customStyle}
    className={
      "form-group col-sm-" + (size || 6) + (className ? " " + className : "")
    }
  >
    {label && (
      <Label color={labelColor}>
        {label} {isRequired && <RequiredInputSpan>*</RequiredInputSpan>}
      </Label>
    )}
    <div className="input-group">
      {icon && (
        <div className="input-group-prepend">
          <StyledIconSpan className="input-group-text">
            <i className={"fas " + icon} />
          </StyledIconSpan>
        </div>
      )}
      {!formik ? (
        <NewRadiusInput hasIcon={!!icon} className="form-control" {...rest} />
      ) : (
        <Field
          name={name}
          render={({ field }) => (
            <NewRadiusInput
              invalid={error && touched}
              hasIcon={!!icon}
              className="form-control"
              {...rest}
              {...field}
            />
          )}
        />
      )}
      {error && touched && (
        <div className="invalid-feedback text-center">{error}</div>
      )}
    </div>
  </div>
);

const input = ({ error, touched, icon, name, size, ...rest }) => (
  <div className={"input-group col-sm-" + (size || 12)}>
    {icon && (
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className={"fas " + icon} />
        </span>
      </div>
    )}
    <Field
      name={name}
      render={({ field }) => (
        <Input invalid={error && touched} {...rest} {...field} />
      )}
    />

    {error && touched && <div className="invalid-feedback">{error}</div>}
  </div>
);

export default input;
export { LabelInput };
