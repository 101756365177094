import styled from "styled-components";
import { InfoText } from "../../../../utils/styled";
import React from "react";

const Border = styled.div`
  height: 3px;
  background: #681e46;
  width: 80%;
`;
const ActiveTab = ({ children, ...props }) => (
  <div {...props}>
    <InfoText className="mb-0">{children}</InfoText>
    <Border />
  </div>
);

export default ActiveTab;
