import { ScreenSize } from "src/constants/ScreenSizeEnum";
import styled from "styled-components";

export const ConcertImage = styled.img`
  width: auto;
  max-width: 100%;
  height: 230px;
  object-fit: cover;

  @media (max-width: ${ScreenSize.LG}px) {
    height: 150px;
  }
`;
export const PrimaryText = styled.p`
  margin: 0;
`;
export const SecondaryText = styled.p`
  margin: 0;
  color: #a0a0a0;
`;
