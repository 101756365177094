import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { defaultTrialLessonsColor } from "src/components/Dashboard/Schedule/constants";
import useFirebaseFns from "src/hooks/useFirebaseFns";

const formDataInitialValues = {
  title: "",
  color: "",
};
const useTrialLessonEditData = ({
  trialLesson,
  refreshData,
  closeDetailsModal,
}) => {
  const { updateTrialLesson } = useFirebaseFns();
  const [formData, setFormData] = useState(formDataInitialValues);
  const [savingChanges, setSavingChanges] = useState(false);

  function handleFormDataChange(name, value) {
    setFormData((oldVal) => ({
      ...oldVal,
      [name]: value,
    }));
  }

  async function saveChanges() {
    try {
      if (!trialLesson) {
        toast.error("Couldnt save trial lesson changes");
        return;
      }
      setSavingChanges(true);

      const updateObj = {
        calendarTitle: formData.title,
        eventColor: formData.color,
      };

      await updateTrialLesson(trialLesson.id, updateObj);
      closeDetailsModal();
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setSavingChanges(false);
    }
  }

  async function resetChanges() {
    try {
      if (!trialLesson) {
        toast.error("Couldnt reset trial lesson changes");
        return;
      }
      setSavingChanges(true);

      const updateObj = {
        calendarTitle: "",
        eventColor: "",
      };

      await updateTrialLesson(trialLesson.id, updateObj);
      closeDetailsModal();
      refreshData();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setSavingChanges(false);
    }
  }

  useEffect(() => {
    setFormData((oldVal) => ({
      ...oldVal,
      ...(trialLesson.calendarTitle && {
        title: trialLesson.calendarTitle,
      }),

      color: trialLesson.eventColor || defaultTrialLessonsColor,
    }));
  }, []);

  return {
    formData,
    handleFormDataChange,
    saveChanges,
    savingChanges,
    resetChanges,
  };
};

export default useTrialLessonEditData;
