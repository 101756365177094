import moment from "moment";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import { LessonsLengthEnum } from "src/constants/LessonsLength";
import {
  checkIfActivityDuringSchoolBreak,
  isActiveTeacher,
} from "src/utils/helpers";
import {
  CalendarPickerWrapper,
  CustomInput,
  CustomLabel,
  CustomSelectField,
  RequiredInputSpan,
} from "src/utils/shared/styled";
import { CustomButton, CustomButtonsWrapper } from "../styled";

const ChangePackageLessonScheduleBody = ({ hookData, closeModal }) => {
  const {
    handleFormChange,
    formData,
    initialData,
    availableTeachers,
    availableClassDays,
    combinedSchoolBreaks,
  } = hookData;

  const [isLastLessonDatePickerOpen, setIsLastLessonDatePickerOpen] =
    useState(false);
  const [isNewLessonDatePickerOpen, setIsNewLessonDatePickerOpen] =
    useState(false);

  const isExcludedSchoolBreakDate = (date) => {
    return combinedSchoolBreaks?.some((schoolBreak) =>
      checkIfActivityDuringSchoolBreak({ start: date }, schoolBreak)
    );
  };

  return (
    <div className="p-4">
      <div className="mt-3">
        <CustomLabel>
          Lesson Time:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomInput
          name="time"
          value={formData.time}
          onChange={(e) => handleFormChange(e.target.name, e.target.value)}
          type="time"
          width="100%"
        />
      </div>
      <div className="mt-3">
        <CustomLabel>
          Lesson Length:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomSelectField
          width="100%"
          name="duration"
          value={formData.duration}
          onChange={(e) =>
            handleFormChange(e.target.name, parseInt(e.target.value))
          }
        >
          <option value="" disabled>
            Select Length
          </option>

          {Object.keys(LessonsLengthEnum).map((duration) => (
            <option key={duration} value={duration}>
              {`${duration} Minutes`}
            </option>
          ))}
        </CustomSelectField>
      </div>
      <div className="mt-3">
        <CustomLabel>
          Location:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomSelectField
          width="100%"
          name="locationId"
          value={formData.locationId}
          onChange={(e) => handleFormChange(e.target.name, e.target.value)}
        >
          <option value="" disabled>
            Select Location
          </option>
          {initialData.locations.map(({ id, name }) => {
            return (
              <option key={id} value={id}>
                {name}
              </option>
            );
          })}
        </CustomSelectField>
      </div>
      <div className="mt-3">
        <CustomLabel>
          Teacher/Program:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomSelectField
          hideDisabledOptions
          width="100%"
          name="teacherId"
          value={formData.teacherId}
          onChange={(e) => handleFormChange(e.target.name, e.target.value)}
        >
          <option value="" disabled>
            Select Teacher
          </option>
          {availableTeachers.map((teacher) => {
            const programId = teacher.instrumentsInfo?.find(
              ({ instrument, program }) =>
                instrument === initialData.packageLesson?.instrumentId
            )?.program;

            const programName = initialData.programs.find(
              ({ id }) => id === programId
            )?.name;
            return (
              <option
                disabled={!isActiveTeacher(teacher)}
                key={teacher.id}
                value={teacher.id}
              >
                {`${teacher.fullName} | ${programName}`}
              </option>
            );
          })}
        </CustomSelectField>
      </div>
      <div className="mt-3">
        <CustomLabel>
          Class Day:<RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CustomSelectField
          width="100%"
          name="weekDay"
          value={formData.weekDay}
          onChange={(e) =>
            handleFormChange(e.target.name, parseInt(e.target.value))
          }
        >
          <option value="" disabled>
            Select Day
          </option>
          {availableClassDays.map((day) => (
            <option key={day.id} value={day.id}>
              {day.name}
            </option>
          ))}
        </CustomSelectField>
      </div>
      <div className="mt-3">
        <label className="me-3">
          <input
            type="radio"
            className="me-2"
            checked={!formData.isVirtual}
            onChange={(e) => handleFormChange("isVirtual", false)}
          />
          In Person
        </label>
        <label>
          <input
            type="radio"
            className="me-2"
            name="isVirtual"
            checked={formData.isVirtual}
            onChange={(e) => handleFormChange("isVirtual", true)}
          />
          Virtual
        </label>
      </div>
      <div className="mt-3">
        <CustomLabel>
          Last Lesson Date (Original Schedule):
          <RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CalendarPickerWrapper>
          <SingleDatePicker
            id="lastLessonDatePicker"
            date={formData.lastLessonDate} // momentPropTypes.momentObj or null
            onDateChange={(date) => {
              handleFormChange("lastLessonDate", date);
            }}
            focused={isLastLessonDatePickerOpen} // PropTypes.bool
            onFocusChange={({ focused }) => {
              setIsLastLessonDatePickerOpen(focused);
            }}
            // numberOfMonths={1}
            // showClearDate={true}
            isOutsideRange={(date) => {
              if (isExcludedSchoolBreakDate(date)) {
                return true;
              }
              // //disables dates before the lesson start
              // if (moment(date).isBefore(lessonStartDate, "date"))
              //   return true;

              // // disables dates that are not on the same weekday as the lsn
              // if (moment(date).day() !== moment(lessonStartDate).day())
              //   return true;

              // if (isExcludedModificationDate(date)) return true;

              return false;
            }}
          />
        </CalendarPickerWrapper>
      </div>
      <div className="mt-3">
        <CustomLabel>
          New Lesson Date (New Schedule):
          <RequiredInputSpan>*</RequiredInputSpan>
        </CustomLabel>
        <CalendarPickerWrapper>
          <SingleDatePicker
            id="newLessonDatePicker"
            date={formData.newLessonDate} // momentPropTypes.momentObj or null
            onDateChange={(date) => {
              handleFormChange("newLessonDate", date);
            }}
            focused={isNewLessonDatePickerOpen} // PropTypes.bool
            onFocusChange={({ focused }) => {
              setIsNewLessonDatePickerOpen(focused);
            }}
            // numberOfMonths={1}
            // showClearDate={true}
            isOutsideRange={(date) => {
              if (isExcludedSchoolBreakDate(date)) {
                return true;
              }
              // disables dates that are not on the same weekday as the lsn
              if (moment(date).day() !== parseInt(formData.weekDay))
                return true;

              return false;
            }}
          />
        </CalendarPickerWrapper>
      </div>
    </div>
  );
};

export default ChangePackageLessonScheduleBody;
