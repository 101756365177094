import React from "react";
import RouteContent from "src/components/common/RouteContent";
import HeaderSection from "./HeaderSection";
import useMakeupNeededData from "./hooks/useMakeupNeededData";
import TableLayout from "./TableLayout";

const MakeUpNeeded = () => {
  const makeupNeededData = useMakeupNeededData();
  return (
    <RouteContent title="Make Up Needed">
      <div className="p-3">
        <div className="mb-4">
          <HeaderSection makeupNeededData={makeupNeededData} />
        </div>
        <div>
          <TableLayout makeupNeededData={makeupNeededData} />
        </div>
      </div>
    </RouteContent>
  );
};

export default MakeUpNeeded;
