const postRequest = async (url = "", data = {}) => {
  const fetchOptions = {
    method: "post",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(url, fetchOptions)
    .then((res) => res.json())
    .then((res) => res);
};

export { postRequest };
